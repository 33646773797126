import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactSupportService {

  constructor(private http: HttpClient) { }

  askTheAuthor(reportCode: string, authorName: string, comments: string): Observable<boolean> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const body = {
      reportCode,
      authorName,
      comments,
    };

    return this.http.post<boolean>(`${environment.metaServiceBaseUrl}/askauthor`, body, { headers });
  }
}