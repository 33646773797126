import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-non-entitled-prompt',
  templateUrl: './non-entitled-prompt.component.html',
  styleUrls: ['./non-entitled-prompt.component.scss']
})
export class NonEntitledPromptComponent {
  @Input() channelNames: string;

  @Output() clickPVO = new EventEmitter<any>();
  @Output() clickRequest = new EventEmitter<any>();

  onClickPVO() {
    this.clickPVO.emit();
  }
  onClickRequest() {
    this.clickRequest.emit();
  }
}
