import { NavigationHint } from '@app/@shared/models/navigation/navigation.models';
import {SaChannelCard} from '@app/strategy-analytics/models/sa-channel-card.model';

export class SaAnalyticsDashboardCardModel extends SaChannelCard {
  public channelSfId: string;
  public dashboardUrl: string;
  public dashboardId: string;
  public content: NavigationHint

  constructor(
    id: string,
    title: string,
    entitled: boolean,
    marketDataCount: number,
    reportsCount: number,
    complimentaryCount: number,
    dashboardCount: number,
    isCustom: boolean,
    content: NavigationHint,
    channelSfId: string,
    dashboardUrl: string,
    dashboardId: string,
  ) {
    super(
      id, title, entitled,
      marketDataCount, reportsCount, complimentaryCount,
      dashboardCount, isCustom
    );

    this.content = content;
    this.channelSfId = channelSfId;
    this.dashboardUrl = dashboardUrl;
    this.dashboardId = dashboardId;
  }

  public buildSubtitle(): string {
    return '';
  }
}
