import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent {
  @Input() message = 'Oops... Something went wrong';
  public readonly currentYear = new Date().getFullYear();
}
