import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-pdf-viewer-notes-panel-header',
  templateUrl: './pdf-viewer-notes-panel-header.component.html',
  styleUrls: ['./pdf-viewer-notes-panel-header.component.scss']
})
export class PdfViewerNotesPanelHeaderComponent implements AfterViewInit {
  @Output() filterClick: EventEmitter<void> = new EventEmitter<void>();
  @Input() filtersCount = 0;
  @Input() commentsCount = 0;
  @ViewChild('headerElement') headerElement: ElementRef;
  @ViewChild('searchContainerElement') searchContainerElement: ElementRef;
  @ViewChild('commentsCounterElement') commentsCounterElement: ElementRef;
  @ViewChild('sortContainerElement') sortContainerElement: ElementRef;

  ngAfterViewInit(): void {
    // Remove from view
    this.headerElement.nativeElement.remove();
  }

  init(
    notesPanelHeader: HTMLDivElement, searchContainer: HTMLDivElement, commentsCounter: HTMLDivElement,
    sortContainer: HTMLDivElement
  ): void {
    this.searchContainerElement.nativeElement.replaceWith(searchContainer);
    this.commentsCounterElement.nativeElement.replaceWith(commentsCounter);
    this.sortContainerElement.nativeElement.replaceWith(sortContainer);

    // Replace old header with custom one
    notesPanelHeader.replaceWith(this.headerElement.nativeElement);
  }

  onFilterClicked(): void {
    this.filterClick.emit();
  }
}
