import {OnboardingModalComponent} from '@app/shell/components/onboarding-modal/onboarding-modal.component';
import {PopupComponent} from 'src/app/shell/components/popup/popup.component';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AuthModule} from '@app/auth';
import {ShellComponent} from './shell.component';
import {NavComponent} from 'src/app/shell/components/nav-bar/nav.component';

import {SharedModule} from '@shared/shared.module';
import {NotFoundPageComponent} from 'src/app/shell/components/not-found-page/not-found-page.component';
import {NavMoreContainerComponent} from 'src/app/shell/components/nav-more-container/nav-more-container.component';
import {NotificationBarComponent} from 'src/app/shell/components/notification-bar/notification-bar.component';
import {NavMoreButtonComponent} from 'src/app/shell/components/nav-bar/nav-more-button/nav-more-button.component';
import {UnauthorizedPageComponent} from './components/unauthorized-page/unauthorized-page.component';
import {HeaderBarComponent} from './components/header-bar/header-bar.component'
import { HelpAndResourcesComponent } from './components/header-bar/help-and-resources/help-and-resources.component';
import {NavButtonComponent} from '@app/shell/components/nav-bar/nav-button/nav-button.component';
import {
  NonEntitledUserLandingPageComponent
} from '@app/shell/components/non-entitled-user-landing-page/non-entitled-user-landing-page.component';
import { NavMegaMenuComponent } from './components/nav-mega-menu/nav-mega-menu.component';
import { NavMegaMenuCompactComponent } from './components/nav-mega-menu-compact/nav-mega-menu-compact.component';

@NgModule({
    imports: [NgbModule, AuthModule, RouterModule, SharedModule],
    declarations: [
        NavComponent,
        NavMoreContainerComponent,
        ShellComponent,
        NavButtonComponent,
        PopupComponent,
        OnboardingModalComponent,
        NotFoundPageComponent,
        NotificationBarComponent,
        NavMoreButtonComponent,
        UnauthorizedPageComponent,
        HeaderBarComponent,
        HelpAndResourcesComponent,
        NonEntitledUserLandingPageComponent,
        NavMegaMenuComponent,
        NavMegaMenuCompactComponent,
    ],
    exports: [
      NavMegaMenuComponent,
      NavMegaMenuCompactComponent,
      HeaderBarComponent,
      NavComponent
    ]
})
export class ShellModule {}
