<div class="wrapper">
  <ng-container *ngIf="itemList.length > 1; else singleHeader">
    <div
      class="dropdown-header"
      [class.collapsed]="!isOpened"
      (click)="toggleDropdown()"
      (clickOutside)="onClickCloseDropdown()"
      appClickOutside
    >
      <span *ngIf="!hideIconOnDropdown">
        <em class="icon" [ngClass]="selectedItem.icon ? selectedItem.icon : 'icon-default'"></em>
      </span>
      <h1 class="header-text" [ngStyle]="{'font-size': titleSize + 'px' }">{{ selectedItem.name }}</h1>
      <em class="icon-down" ></em>
    </div>
    <div class="dropdown-list" [class.collapsed]="!isOpened">
      <div *ngFor="let item of itemList" class="dropdown-item" [class.selected]= "selectedItem.name === item.name" (click)="onClickItem(item)">
        <span *ngIf="!hideIconOnDropdown">
          <em class="item-icon" [ngClass]="item.icon ? item.icon : 'icon-default'"></em>
        </span>
        <h2 class="item-text">{{ item.name }}</h2>
      </div>
    </div>
  </ng-container>
  <ng-template #singleHeader>
    <div class="dropdown-header single">
      <span *ngIf="!hideIconOnDropdown">
        <em class="icon" [ngClass]="selectedItem.icon ? selectedItem.icon : 'icon-default'"></em>
      </span>
      <h1 class="header-text" [ngStyle]="{'font-size': titleSize + 'px' }">{{ selectedItem.name }}</h1>
    </div>
  </ng-template>
</div>
