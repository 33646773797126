import {AnalysisTabInterface} from '@shared/interfaces/analysis-tab.interface';

export enum AnalysisViewTabsPath {
  DOCS = 'documents',
  IMAGES = 'images',
  CV = 'circuitvision'
}

export const getAnalysisContainerTabList = (): AnalysisTabInterface[] => [
  {
    icon: 'icon-log',
    title: 'Documents',
    path: AnalysisViewTabsPath.DOCS,
    sidebarTitle: 'Summary',
    isActive: true,
    hide: false,
    isDisabled: false
  },
  {
    icon: 'icon-image',
    title: 'Images',
    path: AnalysisViewTabsPath.IMAGES,
    sidebarTitle: 'Image Hierarchy',
    isActive: false,
    hide: false,
    isDisabled: false
  },
  {
    icon: 'icon-PCB',
    title: 'CircuitVision Viewer',
    path: AnalysisViewTabsPath.CV,
    sidebarTitle: 'Navigation',
    isActive: false,
    hide: false,
    isDisabled: false
  }
];
