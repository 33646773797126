import { Icon, IconName } from '@shared/components/generic-toolbar/icons';

/**
 * Abstraction of Option State behavior. Implementations will have its own state calculation
 */
export interface StateBehavior {

  /** onChange hook. Implementations reacts different like doing nothing, toggling, setting active state etc */
  onChange(): void;

  /** Hook for when something changes within option group. This could be used to change the option status */
  onGroupChange(): void;

  /** Returns the current icon name depending on internal state */
  getIconName(): string;

  /** Tells if the current state is active or not */
  isActive(): boolean;

  /** Tells if the current component is pressed or not */
  isPressed(): boolean;

  /** Returns current tooltip text depending on internal state */
  getTooltipText(): string;

  /** resets internal state to the initial value */
  reset(): void;
}

/**
 * Behavior for non-state options. Use this class when your icon has no state changes
 */
export class StatelessBehavior implements StateBehavior {

  private readonly iconName: string;
  private readonly tooltip: string;

  constructor(iconName: IconName, tooltip: string) {
    this.iconName = iconName;
    this.tooltip = tooltip;
  }

  onChange(): void {
    /*remains the same*/
  }

  onGroupChange(): void {
    /*remains the same*/
  }

  /** Always return false */
  isActive(): boolean {
    return false;
  }

  /** Always return false */
  isPressed = () => false;

  getIconName(): string {
    return this.iconName;
  }

  getTooltipText(): string {
    return this.tooltip;
  }

  reset(): void {
    /*remains the same*/
  }
}

/**
 * Behavior for state full options. Use this class when your icon has turn into 'active' state when clicked
 */
export class StatefullBehavior implements StateBehavior {

  private active = false;
  private readonly iconName: string;
  private readonly tooltip: string;
  private readonly secondaryIconName: string;
  private readonly secondaryTooltip: string;
  private readonly initialState: boolean;

  constructor(
    iconName: IconName,
    tooltip: string,
    secondaryIconName?: string,
    secondaryTooltip?: string,
    initialState?: boolean,
  ) {
    this.iconName = iconName;
    this.tooltip = tooltip;
    this.initialState = initialState;
    this.active = initialState;
    this.secondaryIconName = secondaryIconName;
    this.secondaryTooltip = secondaryTooltip;
  }

  onChange(): void {
    this.active = true;
  }

  onGroupChange(): void {
    this.active = false;
  }

  isActive(): boolean {
    return this.active;
  }

  isPressed(): boolean {
    return false;
  }

  getIconName(): string {
    if (this.secondaryIconName && this.active) {
      return this.secondaryIconName;
    }
    return this.iconName;
  }

  getTooltipText(): string {
    if (this.secondaryTooltip && this.active) {
      return this.secondaryTooltip;
    }
    return this.tooltip;
  }

  reset(): void {
    this.active = this.initialState;
  }
}

/**
 * Behavior for toggling options. Use this class when your option toggles between two different icons
 */
export class ToggleBehavior implements StateBehavior {

  private pressed = false;
  private readonly iconName: string;
  private readonly tooltip: string;
  private readonly secondaryIconName: string;
  private readonly secondaryTooltip: string;
  private readonly initialState: boolean;

  constructor(
    iconName: IconName,
    tooltip: string,
    secondaryIconName: string,
    secondaryTooltip: string,
    initialState?: boolean,
  ) {
    this.iconName = iconName;
    this.tooltip = tooltip;
    this.initialState = initialState;
    this.pressed = initialState;
    this.secondaryIconName = secondaryIconName;
    this.secondaryTooltip = secondaryTooltip;
  }

  setPressed(pressed: boolean): void {
    this.pressed = pressed;
  }

  onChange(): void {
    this.pressed = !this.pressed;
  }

  onGroupChange(): void {
  }

  isActive = () => true;

  isPressed(): boolean {
    return this.iconName === this.secondaryIconName && this.pressed;
  }

  getIconName(): string {
    if (this.pressed) {
      return this.secondaryIconName;
    } else {
      return this.iconName;
    }
  }

  getTooltipText(): string {
    if (this.pressed) {
      return this.secondaryTooltip;
    } else {
      return this.tooltip;
    }
  }

  reset(): void {
    this.pressed = this.initialState;
  }
}
