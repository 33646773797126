import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import {FeaturedContent, SelectOption, FeaturedDataContainer} from '@app/@shared/models/shared.models';
import { SALUTES } from '@shared/services/user/config/salutes-mock';
import { UserInfoInterface } from '@shared/interfaces/user-info.interface';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { CTISearchResult } from '@app/@shared/models/search/cti-search.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private entitlementUrl = environment.entitlementServiceUrl;
  private authUrl = environment.authServiceBaseUrl;
  private applicationsServiceUrl = environment.applicationsServiceUrl;

  constructor(
    private ffService: FeatureFlagService,
    private http: HttpClient,
  ) {}

  public getUserInfo(): Observable<UserInfoInterface> {
    const url = `${this.authUrl}/user/preferences`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.get<UserInfoInterface>(url, { headers });
  }

  getCountries(): Observable<Array<SelectOption>> {
    const url = `${this.entitlementUrl}/countries`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get<any>(url, {headers}).pipe(
      map( (response: any) => this.dictToSelectOptions(response))
    )
  }

  getStates(): Observable<Array<SelectOption>> {
    const url = `${this.entitlementUrl}/USAStates`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get<any>(url, {headers}).pipe(
      map( (response: any) => this.dictToSelectOptions(response))
    )
  }

  getSalutes():  Observable<Array<SelectOption>> {
    return of(SALUTES);
  }

  getJobFunctions(): Observable<Array<string>> {
    const url = `${this.entitlementUrl}/jobFunctions`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.get<any>(url, { headers });
  }

  getDepartments(): Observable<Array<string>> {
    const url = `${this.entitlementUrl}/user/departments`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.get<any>(url, { headers });
  }

  getTitleLevels(): Observable<Array<string>> {
    const url = `${this.entitlementUrl}/user/titleLevels`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.get<any>(url, { headers });
  }

  getFeaturedContent(persona: string, promotionType: string): Observable<FeaturedContent[]> {
    const url = `${this.applicationsServiceUrl}/featured_content`;

    return this.http.get<CTISearchResult[]>(url, {
      params: {
        'user_persona_id': persona,
        'promotion_type': promotionType
      }
    }).pipe(map((ctiResults) => this.transformResults(ctiResults)));
  }


  getFeaturedDataContainer(persona: string): Observable<FeaturedDataContainer[]> {
    return this.http.get<FeaturedDataContainer[]>(`${this.applicationsServiceUrl}/featured_data_container`, {
        params: {
          'user_persona_id': persona
        }
      });
    }

  private dictToSelectOptions(dict: any): SelectOption[] {
    const selectOptionbs: SelectOption[] = [];
    for (const id in dict) {
      if (id !== null && dict[id] !== null) {
        selectOptionbs.push({label: dict[id], value: id});
      }
    }
    return selectOptionbs;
  }

  private transformResults(ctiResults: CTISearchResult[]): FeaturedContent[] {
    return ctiResults.map(response => ({
      id: response.id,
      name: response.name,
      description: response.description,
      code: response.code,
      channelId: response.channelId,
      channelName: response.channelName,
      subscriptionId: response.subscriptionId,
      subscriptionName: response.subscriptionName,
      analysisEndDate: response.analysisEndDate,
      publishDate: response.publishDate,
      contentType: response.contentType,
      entitled: response.entitled
    }));
  }

}
