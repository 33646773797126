import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TileSourceLayerTableGui } from '@app/@shared/models/kimera/webCVJSON';

@Component({
  selector: 'app-kimera-grid-metadata-sidebar',
  templateUrl: './kimera-grid-metadata-sidebar.component.html',
  styleUrls: ['./kimera-grid-metadata-sidebar.component.scss']
})
export class KimeraGridMetadataSidebarComponent {

  @Input() sidebarOpen = false;
  @Input() metaDataItemHeight: number;
  @Input() metaDataItemWidth: number;
  @Input() tileSourceLayers: Array<TileSourceLayerTableGui>;
  @Output() toggleVisibility = new EventEmitter<TileSourceLayerTableGui>();
  @Output() toggleSelection = new EventEmitter<TileSourceLayerTableGui>();
  @Output() selectColourPicker = new EventEmitter<{tileSourceLayerTableGui:
    TileSourceLayerTableGui, scrollDiff: number}>();


  iconClassEyeShow = 'icon-eye_show';
  iconClassEyeHide = 'icon-eye_hide';
  iconEyeTooltip = 'Show or hide layer';
  iconClassSelectColour = 'icon-select_colour';
  iconSelectColourTooltip = 'Set colour and opacity';
  scrollDiff = 0;

  onToggleVisibility(tileSourceLayer: TileSourceLayerTableGui){
    this.toggleVisibility.emit(tileSourceLayer);
  }

  onToggleSelection(tileSourceLayer: TileSourceLayerTableGui){
    this.toggleSelection.emit(tileSourceLayer);
  }

  onSelectColourPicker(tileSourceLayer: TileSourceLayerTableGui){
    this.selectColourPicker.emit({tileSourceLayerTableGui: tileSourceLayer,scrollDiff: this.scrollDiff});
  }

  onScroll(event: any){
    this.scrollDiff = event.target.scrollTop;
  }

  isDefaultColourAndDefaultOpacity(tileSourceLayer: TileSourceLayerTableGui){
    return tileSourceLayer.colour === '' && tileSourceLayer.opacity===1;
  }
}
