import { Component, Input } from '@angular/core';
import { IMetadataItem } from '@shared/models/reverse-engineering/analysis.models';

@Component({
  selector: 'app-sidebar-metadata-content',
  templateUrl: './sidebar-metadata-content.component.html',
  styleUrls: [ './sidebar-metadata-content.component.scss' ],
})
export class SidebarMetadataContentComponent {

  @Input() metadataList: Array<IMetadataItem>;

}
