import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { catchError, concatMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WindowService } from '@shared/services/window-location/window.service';
import { VendorIntegrationService } from '@shared/services/vendor-integration.service';
import { Subscription } from 'rxjs/Subscription';

/**
 * Component that receives a temp token from the IDP as a query param,
 * and fetches the JWT access token for the user
 */
@Component(
  {
    selector: 'app-token',
    template: ''
  }
)
export class TokenComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthenticationService,
    private externalLocationService: WindowService,
    private vendorIntegrationService: VendorIntegrationService
  ) {
  }


  ngOnInit() {
    this.auth.tokenBeingExchanged = true;
    // If the route has the temp token has a query param, ask for the JWT Access token,
    // and do all the initial processing needed before going to the home page
    this.subscription.add(
      this.route.paramMap
        .pipe(
          concatMap(params => {
            return this.getExchangeToken(params);
          }),
          catchError(() => {
            return of(false);
          })
        )
        .subscribe((success) => {
            if (success) {
              this.onSuccess();
            } else {
              this.onError();
            }
          }
        )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSuccess() {
    this.auth.tokenBeingExchanged = false;

    this.vendorIntegrationService.initHeapImageProxy();
    this.vendorIntegrationService.heapTrack('Login_Event_Success', {})
      .pipe(catchError(() => of(true)))
      .subscribe(() => {
        this.vendorIntegrationService.rollbackHeapImageProxy();
        this.router.navigate(['/']).then();
      });
  }

  /**
   * If any step of the authentication goes wrong, user should be routed to the login url
   */
  onError() {
    this.externalLocationService.assignLocation(environment.loginUrl);
    return of(null);
  }

  /**
   * Gets the user JWT Token
   * @param params    route query params
   *
   */
  private getExchangeToken(params: ParamMap): Observable<boolean> {
    const token = params.get('token');
    return this.auth.exchangeTempToken(token);
  }
}
