<app-generic-toolbar
  [toolbarType]="toolbarType"
  [toolbarTitle]="toolbarTitle"
  [currPage]="currentPage"
  [totalPages]="totalPages"
  [toolbarOptions]="toolbarOptions"
  [isLeftPanelOpen]="isLeftPanelOpen"
  (toggleLeftPanel)="toggleLeftPanel()"
  (propagateEvent)="executeToolbarOption($event)">
</app-generic-toolbar>

<app-pdf-viewer-filters-modal
  (closeModal)="onFiltersModalClose()" #filtersModal
></app-pdf-viewer-filters-modal>
<app-pdf-viewer-notes-panel-header
  [filtersCount]="annotationsFiltersCount"
  (filterClick)="onNotesPanelFilter()"
  #notesPanelHeader
></app-pdf-viewer-notes-panel-header>

<div class="viewer-container">
  <app-metadata-panel
    *ngIf="metadata"
    [metadata]="metadata">
  </app-metadata-panel>
  <div
    #docViewer
    class="viewer"
  ></div>
</div>

<div *ngIf="downloadingPdf" class="spinner-container">
  <app-progress-bar-circular></app-progress-bar-circular>
</div>
