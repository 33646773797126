import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthenticationService } from '@app/auth';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.scss']
})
export class IframeModalComponent implements OnInit {

  @Input() formUrl: string;
  @Input() appendEmail = true;
  url: SafeResourceUrl;

  constructor(
    public modal: NgbActiveModal,
    private authService: AuthenticationService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.authService.me().subscribe((userData) => {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.appendEmail ? `${this.formUrl}?email=${userData.email}` : this.formUrl
      );
    });
  }
}
