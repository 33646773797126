import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IframeModalComponent } from '@shared/components/iframe-modal/iframe-modal.component';
import { ModuleHeaderTab, TipContent } from '@app/@shared/models/shared.models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-module-header',
  templateUrl: './module-header.component.html',
  styleUrls: ['./module-header.component.scss'],
})
export class ModuleHeaderComponent {
  @Input() icon = 'icon-warning';
  @Input() title = '';
  @Input() subtitle = '';
  @Input() subheader = '';
  @Input() showContactCem = false;
  @Input() tabs: ModuleHeaderTab[];
  @Input() tipContent: TipContent;

  constructor(private modalService: NgbModal, private router: Router) {}

  public onClickContactCem(): void {
    const modalInstance = this.modalService.open(IframeModalComponent, {
      scrollable: true,
      backdrop: true,
    });
    modalInstance.componentInstance.formUrl = environment.contactYourCEMForm;
  }
}
