<div
  #AssetViewerContainer
  class="asset-viewer-container"
>
  <app-generic-toolbar
    [toolbarOptions]="toolbarOptions"
    (propagateEvent)="executeToolbarOption($event)"
  >
    <div _ti_embed-menu-container="embed-menu-container" class="embed-menu-container" #ShareDropdownContainer>
      <button (click)="share()">Share data workbook</button>
      <button (click)="shareWithParameters()">Share active tab (with filters)</button>
    </div>
  </app-generic-toolbar>
  <div
    #QuicksightContainer
    _ti_quicksight-container="quicksight-container"
    class="quicksight-container"
  ></div>
</div>

<div *ngIf="isLoading" class="loading-indicator">
    <app-progress-bar-circular></app-progress-bar-circular>
</div>
