import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router, UrlTree } from '@angular/router';
import { TABLET_MAX_WIDTH } from '@app/@shared/consts';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { ShareLinkType } from '@app/@shared/models/share-link/share-link.model';
import { TelemetryEvent } from '@app/@shared/models/telemetry-event';
import { Thumbnail } from '@app/@shared/models/thumbnail';
import { AnnotationService } from '@app/@shared/services/annotation/annotation.service';
import { DeepLinkingService } from '@app/@shared/services/deep-linking.service';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { MarketAnalysisService } from '@app/@shared/services/market-analysis/market-analysis.service';
import { MetaService } from '@app/@shared/services/meta.service';
import { MyLibraryService } from '@app/@shared/services/my-library/my-library.service';
import { ThumbnailsService } from '@app/@shared/services/thumbnails/thumbnails.service';
import { moduleNameFromUrl } from '@app/@shared/utils/common';
import { FilterHelper } from '@app/@shared/utils/filter-helper';
import { PaginationHelper } from '@app/@shared/utils/pagination-helper';
import { RouteHelper } from '@app/@shared/utils/route-helper';
import { FavoriteContentType } from '@app/my-library/models/my-library.models';
import { environment } from '@env/environment';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalysisViewTabsPath, getAnalysisContainerTabList } from '@shared/consts/analysis-container.consts';
import { TelemetryEventName } from '@shared/enums/telemetry-event-name';
import { AnalysisTabInterface } from '@shared/interfaces/analysis-tab.interface';
import { BlogAuthorModel } from '@shared/models/blog-author.model';
import { Image } from '@shared/models/image';
import { WebCVJSONContent } from '@shared/models/kimera/webCVJSON';
import {
  Analysis,
  AnalysisAsset,
  AnalysisImage,
  AnalysisMetadataResponse,
  AnalysisReportMimeType,
  AnalysisReportPage,
  AnalysisResponseObjects,
  AnalysisResponseThumbnails,
  AnalysisTelemetryPayload,
  AssetGroup,
  IImageFilter,
  RelatedContent,
} from '@shared/models/reverse-engineering/analysis.models';
import { DialogService } from '@shared/services/dialog/dialog.service';
import { IndividualAsset } from '@shared/services/meta.service';
import { NotificationSettingsService } from '@shared/services/notification-settings/notification-settings.service';
import { PreviousPageService } from '@shared/services/previous-page.service';
import FileSaver from 'file-saver';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import 'rxjs/add/operator/filter';
import {
  catchError,
  concatMap,
  debounceTime,
  delayWhen,
  filter,
  finalize,
  map,
  share,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  DOWNLOAD_ERROR_MODAL_OPTIONS,
  DownloadErrorModalComponent,
} from '../../download-error-modal/download-error-modal.component';
import { DownloadProgressModalComponent } from '../../download-progress-modal/download-progress-modal.component';
import { ShareItemModalComponent } from '../../share-item-modal/share-item-modal.component';
import { WarningModalComponent } from '../../warning-modal/warning-modal.component';
import {formatDate} from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-analysis-view',
  templateUrl: './analysis-view.component.html',
  styleUrls: [ './analysis-view.component.scss' ],
})
export class AnalysisViewComponent implements OnInit {

  @Input() showTagNameList = true;
  @Input() showInProgressMessage = false;
  // fix this on  https://techinsights.atlassian.net/browse/SWDEV-15144
  unknowCondition:boolean;

  public tabList: AnalysisTabInterface[] = getAnalysisContainerTabList();
  public analysisCode: string;
  public analysis: Analysis;
  public fragment: URLSearchParams = new URLSearchParams();
  public sidebarToggleTitle = 'Summary';
  public prevPageUrl: string;
  public sidebarOpen = {
    documents: window.innerWidth > TABLET_MAX_WIDTH,
    images: window.innerWidth > TABLET_MAX_WIDTH,
    circuitvision: window.innerWidth > TABLET_MAX_WIDTH,
  };
  public sidebarViewerPanel = {
    documents: false,
    images: true,
  };
  public activeTab: string;
  public documentReportsPage: AnalysisReportPage;
  public imagesReportsPage: AnalysisResponseThumbnails;
  public imageFilters: IImageFilter[];
  public paginationParameters: PaginationParameters = new PaginationParameters(50, 1);
  public assetGroupId: string;
  public ignoreTerm: string;
  public selectedImage: { objectId: string; url: string };
  public selectedReportId: string;
  public selectedAssetId: string;
  public showImageViewer = false;
  public documentAssetviewer = 'report-list-view';
  public loading = false;
  public webCVJSON: WebCVJSONContent = null;
  public webCVContentLakeId: string;
  public modalRef: NgbModalRef;
  public initialLoadComplete = false;
  public annotatedDocuments: Array<string> = [];
  public subscriptionId: string;
  public channelId: string;
  public assetGroups: AssetGroup[];
  public imageDownloadEnabled = false;
  public enableUnwatermarkedImageRequest = false;
  public authors: BlogAuthorModel[] = [];
  public isInProgress: boolean;
  public isFollowing: boolean;
  public dontShowAgain = false;
  public channelNames$: Observable<string>;
  public relatedContentList: RelatedContent[];
  public enableHygraph = false;

  private tabsUrlParams = new Map<string, any>();
  private readonly THUMBNAIL_SIZE = 120;
  private readonly THUMBNAIL_HEIGHT = 800;
  private readonly THUMBNAIL_WIDTH = 1200;
  private readonly FRAGMENT_IMGOBJECT = 'imgId';
  private readonly FRAGMENT_PDFOBJECT = 'pdfId';
  private readonly FRAGMENT_QSOBJECT = 'assetId';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private marketAnalysisService: MarketAnalysisService,
    private prevPage: PreviousPageService,
    private thumbsService: ThumbnailsService,
    private metaService: MetaService,
    private annotationService: AnnotationService,
    private entitlementService: EntitlementService,
    private ffService: FeatureFlagService,
    private notificationSettingsService: NotificationSettingsService,
    private myLibraryService: MyLibraryService,
    private deepLinkingService: DeepLinkingService
  ) {}

  public ngOnInit(): void {
    this.ffService.getFlag(environment.enableRequestUnwatermarkedImage).pipe(
      untilDestroyed(this),
    ).subscribe((flag) => {
      this.enableUnwatermarkedImageRequest = flag;
    });
    this.tabList.forEach((tab) => {
      this.tabsUrlParams.set(tab.path, {
        page: '1',
        size: '50',
      });
    });

    this.prevPageUrl = this.prevPage.getPreviousUrl();

    const analysisMetadata$ = this.route.paramMap.pipe(
      tap(() => (this.loading = true)),
      concatMap((routeParams) => {
        this.analysisCode = routeParams.get('analysisCode');
        this.channelId = routeParams.get('channelId');
        this.subscriptionId = routeParams.get('subscriptionId');
        this.getRelatedContentList(this.analysisCode);
        return this.marketAnalysisService.getAnalysisMetadata(this.analysisCode);
      }),
      tap(({ report }) => {
        this.tabList = this.getDisabledTabsForUnentitledUsers(this.tabList, report.entitled);
      }),
      delayWhen(({ report }) =>
        this.marketAnalysisService.getImageFilters(report.id).pipe(
          tap((imageFilters) =>
            this.tabList = this.appendImagesAmountOnTabs(this.tabList, imageFilters)
          )
        )
      ),
      share(),
      untilDestroyed(this),
    );

    // Monitor query parameters
    const params$ = combineLatest([
      analysisMetadata$,
      this.route.queryParamMap,
    ]).pipe(
      debounceTime(300),
      map(([analysisMetadata, queryParams]) => {
        if (queryParams.has('size')) {
          this.paginationParameters = PaginationHelper.fromParams(queryParams);
        }
        this.assetGroupId = queryParams.get('q') ?? null;
        this.ignoreTerm = queryParams.get('ignore') ?? null;
        this.analysis = analysisMetadata.report;
        this.isInProgress = this.analysis.analysisState === 'IN_PROGRESS';
        this.channelNames$ = this.getChannelNames(this.analysis.channelSfIds);
        this.authors = analysisMetadata.authors;
        return { analysisMetadata, queryParams };
      }),
      // So initFiles and initImages can subscribe to it
      share(),
      untilDestroyed(this),
    );

    // Monitor fragments
    this.route.fragment.pipe(
      untilDestroyed(this),
    ).subscribe((fragment) => {
      this.initFragment(fragment);
    });
    this.ffService.getFlag(environment.hygraphReportDisplayedPlatform).pipe(
      untilDestroyed(this),
    ).subscribe((hygraphEnabled) => {
      this.enableHygraph = hygraphEnabled;
    });

    this.initImages(params$).subscribe(([ reportsPage, filters, { type: _customerType }, { download_enabled } ]) => {
      if (this.tabList.find((tab) => tab.path === AnalysisViewTabsPath.IMAGES).isDisabled) {
        return;
      }
      this.imageDownloadEnabled = download_enabled.image;
      this.imagesReportsPage = reportsPage;
      if (this.isInProgress && this.imagesReportsPage.count === 0) {
        this.tabList.find((tab) => tab.path === AnalysisViewTabsPath.IMAGES).isDisabled = true;
        this.disableTabs(AnalysisViewTabsPath.DOCS);
      }
      this.imageFilters = filters;
      if (this.fragment.has(this.FRAGMENT_IMGOBJECT)) {
        const objectId = this.fragment.get(this.FRAGMENT_IMGOBJECT);
        const image = reportsPage.reports.find((x) => x.clObjectId === objectId);
        if (image) {
          this.setSelectedImage(objectId);
        } else if (reportsPage.reports[0]) {
          this.navigateToSelectedImage(reportsPage.reports[0].clObjectId);
        }
      }
      this.loading = false;
    }, (error: any) => {
      if (error.status === 403) {
        this.deepLinkingService.redirectTo403();
      }
    });

    this.initCV(analysisMetadata$, this.route.fragment).subscribe(
      (webCVJSONVisibility: { hideTab: boolean; contentLakeId: string; json: WebCVJSONContent }) => {
        this.modifyTabVisibility(AnalysisViewTabsPath.CV, webCVJSONVisibility.hideTab);
        this.webCVContentLakeId = webCVJSONVisibility.contentLakeId;
        this.webCVJSON = webCVJSONVisibility.json;
        this.initialLoadComplete = true;
      }
    );

    this.initFiles(params$).pipe(
      concatMap((analysisReportPage: AnalysisReportPage) => {
        if (this.tabList.find((tab) => tab.path === AnalysisViewTabsPath.DOCS).isDisabled) {
          return;
        }
        this.documentReportsPage = analysisReportPage;
        if(!this.enableHygraph) {
          this.documentReportsPage.reports = this.documentReportsPage.reports.filter(
            (report) => report.fileType !== AnalysisReportMimeType.HYGRAPH
          );
        }
        if (this.isInProgress && this.documentReportsPage.count === 0) {
          this.tabList.find((tab) => tab.path === AnalysisViewTabsPath.DOCS).isDisabled = true;
          this.disableTabs(AnalysisViewTabsPath.IMAGES);
        }
        return this.annotationService.getDocumentAnnotationsForTable(analysisReportPage);
      }),
      untilDestroyed(this),
    ).subscribe((assetIds: Array<string>) => {
      this.annotatedDocuments = assetIds;
      this.loading = false;
    });

    this.initImageViewer().subscribe();
    this.initPdfViewer().subscribe();
    this.initAssetViewer();
  }

  /**
   * Navigates changing the fragment to toggle the sidebar
   */
  public onSidebarToggleClick(): void {
    switch (this.activeTab) {
      case AnalysisViewTabsPath.DOCS:
        if (this.documentAssetviewer !== 'report-list-view') {
          this.sidebarViewerPanel.documents = !this.sidebarViewerPanel.documents;
        } else {
          this.sidebarOpen.documents = !this.sidebarOpen.documents;
        }
        break;
      case AnalysisViewTabsPath.IMAGES:
        if (this.showImageViewer) {
          this.sidebarViewerPanel.images = !this.sidebarViewerPanel.images;
        } else {
          this.sidebarOpen.images = !this.sidebarOpen.images;
        }
        break;
      case AnalysisViewTabsPath.CV:
        this.sidebarOpen.circuitvision = !this.sidebarOpen.circuitvision;
        break;
    }
  }

  /**
   * Navigates back
   */
  public onClickBack() {
    this.prevPage.navigateBack();
  }

  /**
   * When another tab is clicked triggers the navigation to change the tab
   * Also saves the query parameters of the current tab to be reused
   * if the user navigates back to the current tab
   * @param path the new tab path
   */
  public changeTab(path: string): void {
    this.imageFilters = null;
    this.tabsUrlParams.set(this.activeTab, {
      ...this.paginationParameters,
      q: this.assetGroupId,
      ignore: this.ignoreTerm,
    });
    this.router.navigate([], {
      relativeTo: this.route,
      fragment: this.getFragment(path),
      queryParams: this.tabsUrlParams.get(path),
      replaceUrl: true,
    });
  }

  /**
   * Changes the pagination parameters
   * @param paginationParams the new pagination parmeters
   */
  public onChangePaginationParameters(paginationParams: PaginationParameters) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { ...paginationParams },
      queryParamsHandling: 'merge',
      replaceUrl: true,
      preserveFragment: true,
    });
  }

  /**
   * When a document it's clicked decides wether to show it in a viewer or download it
   * download must be prevented for some reports (https://techinsights.atlassian.net/browse/SWSD-4274)
   * @param report the document/report clicked
   * @param analysis the current analysis
   */
  public onClickDocument(report: AnalysisAsset, analysis: Analysis): void {
    if (!analysis.entitled) {
      this.entitlementService.openRequestAccessFormDialog(`${analysis.channel}:${analysis.code}`);
    } else if (this.isReport(report)) {
      this.router.navigateByUrl(this.createDocumentUrl(report));
    } else if (!environment.reportCodesPreventedForDownload.includes(this.analysisCode)) {
      this.downloadReport(report);
    }
  }

  /**
   * When a document (report) it's clicked, this will be opened in a new tab
   * @param report the document/report clicked
   * @param analysis the current analysis
   */
  public onDocumentNewTab(report: AnalysisAsset, analysis: Analysis): void {
    if (!analysis.entitled) {
      this.entitlementService.openRequestAccessFormDialog(`${analysis.channel}:${analysis.code}`);
    } else {
      window.open(this.router.serializeUrl(this.createDocumentUrl(report)), '_blank', 'noopener=true');
    }
  }

  /**
   * When a document (report) it's clicked, this will open a share modal
   * @param report the document/report clicked
   * @param analysis the current analysis
   */
  public onDocumentShare(report: AnalysisAsset, analysis: Analysis): void {
    if (analysis.entitled) {
      this.openShareModalWindow(this.createDocumentUrl(report));
    } else {
      this.entitlementService.openRequestAccessFormDialog(`${analysis.channel}:${analysis.code}`);
    }
  }

  /**
   * When a image is clicked, this will open a share modal
   * @param image the image clicked
   * @param entitled is the entitlement of the current analysis
   */
  public onShareImage(image: AnalysisImage, entitled: boolean): void {
    if (entitled) {
      this.openShareModalWindow(this.createImageUrl(image));
    } else {
      this.entitlementService.openRequestAccessFormDialog(`${image.title}:${image.reportCode}`);
    }
  }

  /**
   * When the star is clicked, the image is saved as favorite.
   * @param image the image to be saved as favorite
   */
  public onAddImagesToFavorites(image: Image) {
    this.myLibraryService.addToFavorites([image.assetId], FavoriteContentType.IMAGE).subscribe();
  }

  /**
   * when an image is changed navigates to select a new one
   * @param image the new image to be displayed
   */
  public onChangeSelectedImage(image: Image) {
    this.navigateToSelectedImage(image.objectId);
  }

  /**
   * Closes the image viewer
   */
  public onCloseImageViewer() {
    this.sidebarViewerPanel.images = true;
    const params = FilterHelper.rejectUTMParams();
    this.fragment.delete(this.FRAGMENT_IMGOBJECT);
    this.router.navigate([], {
      relativeTo: this.route,
      fragment: this.fragment.toString(),
      queryParams: params,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  public onClosePdfViewer(): void {
    this.sidebarViewerPanel.documents = false;
    this.documentAssetviewer = 'report-list-view';
    const params = FilterHelper.rejectUTMParams();
    this.fragment.delete(this.FRAGMENT_PDFOBJECT);
    this.router.navigate([], {
      relativeTo: this.route,
      fragment: this.fragment.toString(),
      queryParams: params,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  public onCloseAssetViewer(): void {
    this.sidebarViewerPanel.documents = false;
    const params = FilterHelper.rejectUTMParams();
    this.documentAssetviewer = 'report-list-view';
    this.fragment.delete(this.FRAGMENT_QSOBJECT);
    this.router.navigate([], {
      relativeTo: this.route,
      fragment: this.fragment.toString(),
      queryParams: params,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  /**
   * Navigates to change the current image filter
   * @param filterEvent an event that contains the new filter and what it should ignore
   */
  public onChangeImageFilter(filterEvent: { selectedFilter: IImageFilter; ignoreFilter: string }) {
    this.router.navigate([], {
      queryParams: {
        q: filterEvent.selectedFilter.key,
        ignore: filterEvent.ignoreFilter ? filterEvent.ignoreFilter : 'none',
        page: this.assetGroupId === filterEvent.selectedFilter.key ? this.paginationParameters.page : 1,
        size: this.paginationParameters.size,
      },
      preserveFragment: true,
    });
  }

  public onDownloadImage($image: AnalysisImage): void {
    const imageAsset = this.imagesReportsPage.reports.find((report) => report.clObjectId === $image.id);
    this.metaService
      .getAssetBinariesUrl(imageAsset.assetId)
      .pipe(
        switchMap((url: string) => this.metaService.downloadBlob(url, imageAsset.fileType)),
        untilDestroyed(this)
      )
      .subscribe(
        (image: Blob) => {
          const IMAGE_PAYLOAD = {
            assetId: $image.reportId,
            assetName: imageAsset.assetName,
            fileType: imageAsset.fileType,
          };
          this.postEventForSubscription(IMAGE_PAYLOAD, TelemetryEventName.EVENT_DOWNLOAD_JOB, image.size);
          return FileSaver.saveAs(image, `TI-${imageAsset.assetName}`);
        },
        (_error: Error) => this.dialogService.open(DownloadErrorModalComponent, DOWNLOAD_ERROR_MODAL_OPTIONS)
      );
  }

  public onDownloadImages(selectedIds: any) {
    const assetGroupIds: string[] = selectedIds.allSelected ? [this.assetGroupId] : [];
    this.download(assetGroupIds, selectedIds.assetIds, selectedIds.allSelected);
  }

  public onDownloadAssetGroup(report: AssetGroup, analysis: Analysis) {
    if (!analysis.entitled) {
      this.entitlementService.openRequestAccessFormDialog(`${analysis.channel}:${analysis.code}`);
    } else {
      this.download([report.assetGroupId], [], false);
    }
  }

  public onClickPVO(): void {
    const modalInstance = this.dialogService.open(WarningModalComponent, {
      size: 'sm',
      centered: true,
      windowClass: 'uui-modal',
    });
    modalInstance.componentInstance.props = {
      title: 'Access this report as part of a subscription',
      bodyTitle: 'Benefits of a subscription:',
      bodyList: [
        'Access to industry leading analysts',
        'Both current and historical repository of research',
        'Timely updates to forecasts, market share, market sizing, trends and more',
      ],
      closeButtonLabel: 'Close',
      actionButtonLabel: 'View Product Overview',
    };
    modalInstance.result.then(() => {
      let url = 'https://www.techinsights.com/technology-professional';
      if (this.subscriptionId) {
        url = this.router.serializeUrl(
          this.router.createUrlTree([`${this.subscriptionId}`, 'product-overview'], { relativeTo: this.route.parent })
        );
      }
      window.open(url);
    });
  }

  public onClickRequest(): void {
    if (!this.analysis.entitled) {
      this.entitlementService.openRequestAccessFormDialog(`${this.analysis.channel}:${this.analysis.code}`);
    }
  }

  getChannelNames(channelIds: string[]): Observable<string> {
    return this.marketAnalysisService.getAllChannels().pipe(
      map((channels) => channels.filter((channel) => channelIds?.includes(channel.id))),
      map((channels) => channels.map((channel) => channel.name)),
      map((channels) => channels.join(', ')),
    );
  }

  onClickOpenShareModal() {
    if (this.analysis.entitled) {
      this.dialogService.open(
        ShareItemModalComponent,
        { windowClass: 'shareModalClass' },
        {
          isDocumentShareable: false,
          shareItemEntityId: this.analysis.id,
          shareItemEntityTitle: this.analysis.name,
          shareEntityType: ShareLinkType.REPORT,
        }
      );
    } else {
      this.entitlementService.openRequestAccessFormDialog(`${this.analysis.channel}:${this.analysis.code}`);
    }
  }

  onClickAddToFavorites() {
    this.myLibraryService.addToFavorites([this.analysis.id], FavoriteContentType.ANALYSIS).subscribe();
  }

  private isReport(report: AnalysisAsset): boolean {
    return (
      report.fileType === AnalysisReportMimeType.PDF ||
      report.fileType === AnalysisReportMimeType.BLOG ||
      report.fileType === AnalysisReportMimeType.QUICKSIGHT ||
      report.fileType === AnalysisReportMimeType.HYGRAPH
    );
  }

  private openShareModalWindow(urlTree: UrlTree) {
    this.dialogService.open(
      ShareItemModalComponent,
      { windowClass: 'shareModalClass' },
      {
        shareEntityType: ShareLinkType.ASSET,
        isDocumentShareable: true,
        itemDeepLinkUrl: environment.basePath + this.router.serializeUrl(urlTree),
      }
    );
  }

  private download(assetGroupIds: string[], selectedIds: IndividualAsset[], allSelected: boolean) {
    this.metaService
      .postAssetZipJob(assetGroupIds, selectedIds, this.analysisCode, allSelected)
      .pipe(untilDestroyed(this))
      .subscribe(
        (jobId) => {
          this.modalRef = this.dialogService.open(DownloadProgressModalComponent, {
            windowClass: 'file-download-modal',
            backdrop: 'static',
            centered: true,
            keyboard: false,
          });
          this.modalRef.componentInstance.jobId = jobId;
          this.modalRef.result.then((result) => {
            if (result === 'error') {
              this.dialogService.open(DownloadErrorModalComponent, DOWNLOAD_ERROR_MODAL_OPTIONS);
            }
          });
        },
        (_error: Error) => this.dialogService.open(DownloadErrorModalComponent, DOWNLOAD_ERROR_MODAL_OPTIONS)
      );
  }

  /**
   * Parses the fragment and sets the sidebar status,
   * and shows/hides the report list or appropriate viewer.
   * @param fragment the current fragment as a string
   * @returns a boolean representing if the tab has changed from the previous one
   */
  private initFragment(fragment: string): boolean {
    const defaultPath = this.tabList.find((item) => item.isActive).path;
    this.fragment = new URLSearchParams(fragment ?? '');
    const newActiveTab = this.fragment.get('activeTab') ?? defaultPath;
    const tabChanged = this.fragment.has('activeTab') ? this.activeTab !== newActiveTab : true;
    this.showImageViewer = this.fragment.has(this.FRAGMENT_IMGOBJECT);
    if (this.fragment.has(this.FRAGMENT_PDFOBJECT)) {
      this.documentAssetviewer = 'pdf-viewer';
    } else if (this.fragment.has(this.FRAGMENT_QSOBJECT)) {
      this.documentAssetviewer = 'qs-viewer';
    } else if (this.fragment.entries.length === 0) {
      this.documentAssetviewer = 'report-list-view';
    }
    this.setTabActive(newActiveTab);
    return tabChanged;
  }

  /**
   * Initializes the images tab, gets the reports, thumbnails and image filters
   * @param params the observable that triggers the change
   * @returns observable with the reports and image filters
   */
  private initImages(params: Observable<{ analysisMetadata: AnalysisMetadataResponse; queryParams: ParamMap }>) {
    return params.pipe(
      filter(() => this.activeTab === AnalysisViewTabsPath.IMAGES),
      tap(() => (this.loading = true)),
      concatMap(() => this.getReportsPage(['image'], this.assetGroupId, this.ignoreTerm)),
      concatMap((response: AnalysisReportPage) => {
        const analysisObjects = {
          reports: response.reports,
          count: response.count,
          objects: response.reports.map((v) => ({ objectId: v.clObjectId })),
        };
        return forkJoin([
          this.getThumbnails(analysisObjects),
          this.marketAnalysisService.getImageFilters(this.analysis.id),
          this.entitlementService.getCustomerType(),
          this.entitlementService.getReportEntitlements(this.analysis.id),
        ]);
      })
    );
  }

  /**
   * initializes the files tab and gets the reports
   * @param params the observable that triggers the change
   * @returns observable with the document reports
   */
  private initFiles(params: Observable<{ analysisMetadata: AnalysisMetadataResponse; queryParams: ParamMap }>) {
    return params.pipe(
      tap(() => (this.loading = true)),
      concatMap(() => this.getReportsPage(['pdf', 'datagrid']))
    );
  }

  private initCV(
    analysisMetadata$: Observable<AnalysisMetadataResponse>,
    fragment: Observable<string>
  ): Observable<{ hideTab: boolean; contentLakeId: string; json: WebCVJSONContent }> {
    const emptyWebCVJSONVisibility = {
      hideTab: true,
      contentLakeId: null as string,
      json: null as WebCVJSONContent,
    };

    return analysisMetadata$.pipe(
      concatMap((analysisMetadata) => {
        return this.marketAnalysisService.getAnalysisWebCVJSON(analysisMetadata.report.id).pipe(
          map((webCJSONObject) => {
            if (webCJSONObject) {
              fragment
                .pipe(
                  filter(() => this.activeTab === AnalysisViewTabsPath.CV),
                  tap(() => this.postEventForCircuitSubscription(analysisMetadata.report))
                )
                .subscribe();
              return {
                hideTab: false,
                contentLakeId: webCJSONObject.contentLakeObjectId,
                json: webCJSONObject.webCVJSON,
              };
            } else {
              return emptyWebCVJSONVisibility;
            }
          })
        );
      }),
      catchError((_error) => of(emptyWebCVJSONVisibility))
    );
  }

  /**
   * Selects the image to be shown in the image viewer every time the appropiate fragment parameter changes
   * @returns observable
   */
  private initImageViewer() {
    return this.route.fragment.pipe(
      filter(() => this.activeTab === AnalysisViewTabsPath.IMAGES && this.fragment.has(this.FRAGMENT_IMGOBJECT)),
      tap(() => this.setSelectedImage(this.fragment.get(this.FRAGMENT_IMGOBJECT)))
    );
  }

  private initPdfViewer() {
    return this.route.fragment.pipe(
      filter(() => this.activeTab === AnalysisViewTabsPath.DOCS && this.fragment.has(this.FRAGMENT_PDFOBJECT)),
      tap(() => {
        this.selectedReportId = this.fragment.get(this.FRAGMENT_PDFOBJECT);
      })
    );
  }

  private initAssetViewer() {
    this.route.fragment.pipe(
      filter(() => this.activeTab === AnalysisViewTabsPath.DOCS && this.fragment.has(this.FRAGMENT_QSOBJECT)),
    ).subscribe(() => {
      this.selectedAssetId = this.fragment.get(this.FRAGMENT_QSOBJECT);
    });
  }

  /**
   * Sets the new active tab
   * @param path the tab path
   */
  private setTabActive(path: string) {
    this.sidebarToggleTitle = this.tabList.find((item) => item.path === path).sidebarTitle;
    this.tabList.find((item) => item.isActive).isActive = false;
    this.tabList.find((item) => item.path === path).isActive = true;
    this.activeTab = path;
  }

  /**
   * Gets the apropiate fragment when changing the tab
   * @param path the tab path
   * @returns the fragment for the path
   */
  private getFragment(path: string): string {
    this.fragment.set('activeTab', path);
    return this.fragment.toString();
  }

  /**
   * Gets the reports
   * @param reportTypes the types (images, datagrid, pdf, etc)
   * @param searchTerm the term the queried for
   * @param ignoreTerm the term to ignore
   * @returns observable that gets the list of reports from the backend
   */
  private getReportsPage(
    reportTypes: string[],
    searchTerm?: string,
    ignoreTerm?: string
  ): Observable<AnalysisReportPage> {
    return this.marketAnalysisService.getAnalysisReports(
      searchTerm ?? this.analysis.baseAssetGroup,
      this.analysis.id,
      this.paginationParameters,
      reportTypes,
      ignoreTerm ?? null
    );
  }

  /**
   * Navigates to the selected image
   * @param objectId the object id of the image
   */
  private navigateToSelectedImage(objectId: string) {
    this.fragment.set(this.FRAGMENT_IMGOBJECT, objectId);
    const params = FilterHelper.rejectUTMParams();
    this.router.navigate([], {
      relativeTo: this.route,
      fragment: this.fragment.toString(),
      queryParams: params,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  /**
   * Downloads report
   * @param report the report to be downloaded
   */
  private downloadReport(report: AnalysisAsset): void {
    report.downloading = true;
    this.marketAnalysisService
      .downloadXLSXReport(report.clObjectId)
      .pipe(
        catchError(() => {
          this.dialogService.openError();
          return of(null);
        }),
        finalize(() => (report.downloading = false))
      )
      .subscribe((blob: Blob) => {
        FileSaver.saveAs(blob, `TI ${report.assetName}`);
        this.postEventForSubscription(report, TelemetryEventName.EVENT_DOWNLOAD_JOB, blob.size);
      });
  }

  /**
   * getThumbnails gets thumbnails from the Thumbnail Service for a list of reports.
   * @param analysis AnalysisResponseObjects returned by getReports.
   */
  private getThumbnails(analysis: AnalysisResponseObjects): Observable<AnalysisResponseThumbnails> {
    const thumbSize = this.THUMBNAIL_SIZE;
    return this.thumbsService
      .buildThumbnailServiceBulkRequestForObjectIds(analysis.objects, thumbSize, thumbSize)
      .pipe(
        // Retry once:
        catchError((_error) =>
          this.thumbsService.buildThumbnailServiceBulkRequestForObjectIds(analysis.objects, thumbSize, thumbSize)
        ),
        catchError((_error) => of([] as Array<Thumbnail>))
      )
      .pipe(
        map((thumbnails) => ({
          count: analysis.count,
          reports: analysis.reports,
          thumbnails,
        }))
      );
  }

  /**
   * Gets the full size image
   * @param clId object id
   */
  private setSelectedImage(clId: string) {
    this.thumbsService
      .getThumbnailForSize(clId, this.THUMBNAIL_HEIGHT, this.THUMBNAIL_WIDTH)
      .subscribe((thumbnail: Thumbnail) => {
        this.selectedImage = { objectId: clId, url: thumbnail.signedUrl };
      });
  }

  /**
   * Sends telemetry event
   * @param report The AnalysisAsset object
   * @param eventType One of the consts that are in telemetry-events.consts
   * @param size The amount of bytes to download
   */
  private postEventForSubscription(
    report: AnalysisTelemetryPayload,
    eventType: TelemetryEventName,
    size: number
  ): void {
    const payload = {
      artifactName: report.assetName,
      artifactType: report.fileType,
      channelId: this.channelId,
      downloadSizeBytes: size,
      reportCode: this.analysisCode,
      reportId: report.assetId,
      reportTitle: this.analysis.name,
      subscriptionId: this.subscriptionId,
    };

    const applicationModule = moduleNameFromUrl(this.router.url);
    const event = new TelemetryEvent(eventType, payload, applicationModule);
    this.metaService.postTelemetryEvent(event).subscribe();
  }

  /**
   * Sends telemetry event when circuit vision tab is opened
   * @param reportTitle
   * @param reportId
   */
  private postEventForCircuitSubscription({ name: reportTitle, id: reportId }: Analysis) {
    const payload = {
      artifactName: `${reportTitle}.cv`,
      artifactType: 'cv',
      reportCode: this.analysisCode,
      channelId: this.channelId,
      subscriptionId: this.subscriptionId,
      reportId,
      reportTitle,
    };
    const event = new TelemetryEvent(TelemetryEventName.EVENT_CIRCUITVISION_REPORT_OPEN, payload);
    this.metaService.postTelemetryEvent(event).subscribe();
  }

  /**
   * Hides or shows a particular tab
   * @param analysisViewTabsPath the tab path that we want to modify visibility of
   * @param hide value representing the visibility of the tab. For e.g If hide == true, hide the tab
   */
  private modifyTabVisibility(analysisViewTabsPath: AnalysisViewTabsPath, hide: boolean) {
    this.tabList = this.tabList.map((tab) => {
      if (tab.path === analysisViewTabsPath) {
        return { ...tab, hide };
      } else {
        return tab;
      }
    });
  }

  private appendImagesAmountOnTabs(tabs: Array<any>, imageFilters: Array<IImageFilter>) {
    const allSourceImageAmount = imageFilters.reduce((n, { amount }) => n + amount, 0);
    return tabs.map((tab) => {
      const isImageTab = tab.path === AnalysisViewTabsPath.IMAGES;
      const isImageTabHidden = isImageTab && !allSourceImageAmount;

      return {
        ...tab,
        ...(isImageTab && { title: `Images (${allSourceImageAmount})` }),
        ...(isImageTabHidden && { hide: isImageTabHidden }),
      };
    });
  }

  private getDisabledTabsForUnentitledUsers(tabs: Array<any>, isEntitled: boolean): Array<any> {
    return tabs.map((tab) => {
      const isDisabled = tab.path !== AnalysisViewTabsPath.DOCS && !isEntitled;
      return {
        ...tab,
        isDisabled,
        icon: isDisabled ? 'icon-locked' : tab.icon,
      };
    });
  }

  private disableTabs(path: string) {
    if (!this.tabList.find((tab) => tab.path === path).isDisabled) {
      this.showInProgressMessage = false;
      this.changeTab(path);
    } else {
      this.showInProgressMessage = this.analysis.entitled && this.analysis.actualFirstWipDate != null;
      // fix this on  https://techinsights.atlassian.net/browse/SWDEV-15144
      this.unknowCondition = this.showInProgressMessage;
    }
  }

  private createDocumentUrl(report: AnalysisAsset): UrlTree {
    switch (report.fileType) {
      case AnalysisReportMimeType.PDF: {
        const fragment = new URLSearchParams();
        fragment.set(this.FRAGMENT_PDFOBJECT, report.clObjectId);
        const params = FilterHelper.rejectUTMParams();
        return this.router.createUrlTree([], {
          relativeTo: this.route,
          fragment: fragment.toString(),
          queryParams: params,
          queryParamsHandling: 'merge',
        });
      }
      case AnalysisReportMimeType.BLOG: {
        return this.router.createUrlTree(['wp-asset', report.postId], {
          fragment: `code=${this.analysisCode}&subscriptionId=${this.subscriptionId}&channelId=${this.channelId}`,
          relativeTo: RouteHelper.getModuleRouteOrShell(this.route),
        });
      }
      case AnalysisReportMimeType.QUICKSIGHT: {
        const fragment = new URLSearchParams();
        fragment.set(this.FRAGMENT_QSOBJECT, report.assetId);
        fragment.set('subscriptionId', this.subscriptionId);
        fragment.set('channelId', this.channelId);
        fragment.set('reportName', report.assetName);
        const params = FilterHelper.rejectUTMParams();
        return this.router.createUrlTree([], {
          relativeTo: this.route,
          fragment: fragment.toString(),
          queryParams: params,
          queryParamsHandling: 'merge',
        });
      }
      case AnalysisReportMimeType.HYGRAPH: {
        const fragment = new URLSearchParams();
        fragment.set('moduleName', moduleNameFromUrl(this.router.url));
        fragment.set('reportCode', this.analysisCode);
        fragment.set('subscriptionId', this.subscriptionId);
        fragment.set('channelId', this.channelId);
        return this.router.createUrlTree(['hg-asset', report.assetId], {
          fragment: fragment.toString(),
        })
      }
      default: {
        throw new Error('Asset is not a document');
      }
    }
  }

  private createImageUrl(image: AnalysisImage): UrlTree {
    const fragment = new URLSearchParams(this.fragment);
    fragment.set(this.FRAGMENT_IMGOBJECT, image.objectId);
    return this.router.createUrlTree([], {
      relativeTo: this.route,
      fragment: fragment.toString(),
      queryParamsHandling: 'preserve',
    });
  }

  /**
   * This method makes a call to the service and obtains a related
   * content list. For this, the analysis code of the report is
   * needed to obtain the list.
   * @param analysisCode
   * @returns a list with the content related to the report
   */
  private getRelatedContentList(analysisCode: string) {
    this.marketAnalysisService.getRelatedContent(analysisCode).subscribe((data: RelatedContent[]) => {
      this.relatedContentList = data;
    });
  }

  get visibleTabList(): AnalysisTabInterface[] {
    return this.tabList.filter((tab) => !tab.hide);
  }

}
