import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ReportDigest} from '../models/report-digest';
import {ContentLakeImage} from '../models/content-lake-image';
import {DomSanitizer} from '@angular/platform-browser';
import {CategoryMapper} from '@shared/models/category-mapper';
import {switchMap, map, concatMap} from 'rxjs/operators';
import {PdfAssetInfo} from '../models/pdf-asset-info';
import {CLObjectExcerpt, ClObjects} from '../models/content-lake-object'
@Injectable({
  providedIn: 'root'
})
export class ContentLakeService {
  private baseUrl = environment.contentLakeBaseUrl;

  constructor(private httpClient: HttpClient,
              private sanitizer: DomSanitizer) {
  }

  getReportsForInventoryId(inventoryId: string): Observable<Array<ReportDigest>> {
    const url = `${this.baseUrl}/objects/search`;
    const headers = this.buildHttpHeaders();
    const body = {
      properties: [
        { property: 'InventoryID', value: inventoryId }
      ],
      asset_classification: 'REPORT'
    };

    return this.httpClient.post<any>(url, body, { headers }).pipe(
      map(this.extracObjectsProps),
      map(this.mapToCLObjectExcertp)
    ) as Observable<Array<ReportDigest>>;
  }

  getReportCategory(report: ReportDigest): Observable<string> {
    return of(CategoryMapper.sanitizeCategory(report.deviceCategory));
  }

  getReportByReportCode(reportCode: string): Observable<Array<ReportDigest>> {
    const url = `${this.baseUrl}/objects/search`;
    const headers = this.buildHttpHeaders();
    const properties = [{ property: 'SF_PRODUCT_CODE', value: reportCode }];

    const body = {
      properties,
      asset_classification: 'REPORT'
    };

    return this.fetchReports(url, body, headers);
  }

  getReportsByReportCodeCollection(reportCodes: string[]): Observable<Array<ReportDigest>> {
    const url = `${this.baseUrl}/objects/search`;
    const headers = this.buildHttpHeaders();
    const properties = reportCodes.map(element => ({property: 'SF_PRODUCT_CODE', value: element }));

    const body = {
      properties,
      asset_classification: 'REPORT'
    };

    return this.fetchReports(url, body, headers);
  }

  fetchReports(url: string, body: any, headers: HttpHeaders): Observable<Array<ReportDigest>> {
    return this.httpClient.post<any>(url, body, { headers }).pipe(
      map(this.extracObjectsProps),
      map((objects) => {
        const listOfReportCodes: any[] = [];
        const listObjectsToReturn: any[] = [];
        const listOfMetaData: any[] = [];
        objects.forEach((obj:any) => {
          obj.metadata.created = obj.created;
          const existingSFPCIdx = listOfReportCodes.indexOf(obj.metadata.SF_PRODUCT_CODE);
          if(existingSFPCIdx === -1) {
            listOfReportCodes.push(obj.metadata.SF_PRODUCT_CODE);
            listOfMetaData.push(obj.metadata);
            listObjectsToReturn.push({
              id: obj.object_id,
              reportName: obj.metadata.original_filename,
              reportCode: obj.metadata.SF_PRODUCT_CODE,
              artifactName: obj.metadata.original_filename,
              artifactType: obj.mimetype
            });
          } else {
            const existingObj = listObjectsToReturn[existingSFPCIdx];
            existingObj.metadata = listOfMetaData[existingSFPCIdx];
            existingObj.mimetype = existingObj.artifactType;
            const mostRecentObject = this.selectMostRecent(existingObj, obj);
            listObjectsToReturn[existingSFPCIdx] = {
              id: (mostRecentObject.object_id || mostRecentObject.id),
              reportName: mostRecentObject.metadata.original_filename,
              reportCode: mostRecentObject.metadata.SF_PRODUCT_CODE,
              artifactName: mostRecentObject.metadata.original_filename,
              artifactType: mostRecentObject.mimetype
            };
            listOfMetaData[existingSFPCIdx] = mostRecentObject.metadata;
          }
        })
        return listObjectsToReturn;
      })
    )
  }

  getSignedUrlForXodReport(report: ReportDigest): Observable<PdfAssetInfo> {
    const url = `${this.baseUrl}/objects/search`;
    const headers = this.buildHttpHeaders();
    const body = {
      properties: [{property: 'SF_PRODUCT_CODE', value: report.reportCode}],
      limit: 1,
      asset_classification: 'REPORT'
    };

    return this.httpClient.post<any>(url, body, {headers}).pipe(
      switchMap((clObject) => {
        const xodBody = {
          limit: 1,
          properties: [{property: 'DERIVED_FROM', value: clObject.objects[0].object_id}]
        };
        return this.httpClient.post(url, xodBody, {headers});
      }),
      map((clObject: any) => {
        return {url: clObject.objects[0].url, clObjectId: clObject.objects[0].metadata.DERIVED_FROM};
      })
    );
  }

  selectMostRecent(obj1:any, obj2:any) {
    const timeStamp1 = (obj1.metadata.BRNA_MODIFIED || obj1.metadata.BRNA_CREATED || obj1.metadata.created);
    const timeStamp2 = (obj2.metadata.BRNA_MODIFIED || obj2.metadata.BRNA_CREATED || obj2.metadata.created);

    return Date.parse(timeStamp1) > Date.parse(timeStamp2) ? obj1 : obj2;
  }

  getSignedUrlForReport(report: ReportDigest): Observable<Array<string>> {
    const url = `${this.baseUrl}/objects/search`;
    const headers = this.buildHttpHeaders();
    const body = {
      properties: [
        {
          limit:1,
          property: 'SF_PRODUCT_CODE', value: report.reportCode
        }
      ],
      asset_classification: 'REPORT'
    };

    return this.httpClient.post<any>(url, body, { headers }).pipe(
      map(this.extracObjectsProps),
      map(this.loopThroughObjectAndExtractUrlProp)
    );
  }

  getSchematicsForMatCode(code: string): Observable<Array<string>> {
    const url = `${this.baseUrl}/objects/search`;
    const headers = this.buildHttpHeaders();
    const body = {
      properties: [
        { property: 'MATCODE', value: code },
        { property: 'IS_LEGACY_SCHEMATIC', value: 'true' }
      ]
    };

    return this.httpClient.post<any>(url, body, { headers }).pipe(
      map(this.extracObjectsProps),
      map(this.loopThroughObjectAndExtractUrlProp)
    );
  }

  getImageObjectByContentLakeObjectID(objId : string): Observable<any>{
    const url = `${this.baseUrl}/objects/${objId}`;
    const headers =  new HttpHeaders().set('X-TI-AGENT-ID', environment.entitlementAgentId)
      .set('only-latest-created', 'true')
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')

    return this.httpClient.get<any>(url, { headers })
      .pipe(
        map((obj) =>{ return {
          id: obj.object_id,
            url: obj.url,
            metadata: obj.metadata,
            url_expires: obj.url_expires
        }}
        )
      );
  }

  /**
   * Returns CL Image Objects associated with inventories
   *
   * @param inventories array of inventory ids
   */
  getImageObjectsForInventories(inventories: string[]): Observable<Array<ContentLakeImage>> {
    const url = `${this.baseUrl}/objects/search`;
    const headers = this.buildHttpHeaders();
    const properties = inventories.map( (element) => { return { property: 'InventoryID', value: element } });

    const body = {
      properties,
      asset_classification: 'IMAGE',
      limit: 5000
    };

    return this.httpClient.post<any>(url, body, { headers }).pipe(
      map(this.extracObjectsProps),
      map( (objects) => {
        return objects.map( (obj: any)=> {
          return {
            objectId: obj.object_id,
            signedUrl: obj.url,
            metadata: obj.metadata,
            status: obj.status,
            url_expires: obj.url_expires
          }
        })
      })
    );
  }

  /**
   * Returns ReportDigest objects associated with inventories
   * @param inventories array of inventory ids
   */
  getReportsForInventories(inventories: string[]): Observable<Array<ReportDigest>> {
    const url = `${this.baseUrl}/objects/search`;
    const headers = this.buildHttpHeaders();
    const properties = inventories.map( (element) => { return { property: 'InventoryID', value: element } });
    const body = {
      properties,
      asset_classification: 'REPORT'
    };

    return this.httpClient.post<any>(url, body, { headers }).pipe(
      map(this.extracObjectsProps),
      map(this.mapToCLObjectExcertp)
    ) as Observable<Array<ReportDigest>>;
  }

  getCLObjectsForInventoryIdX(id: string): Observable<Array<any>> {
    const url = `${this.baseUrl}/objects/search`;
    const headers = this.buildHttpHeaders();
    const body = {
      properties: [
        { property: 'InventoryID', value: id }
      ],
      asset_classification: 'IMAGE'
    };

    return this.httpClient.post<any>(url, body, { headers }).pipe(
      map(this.extracObjectsProps)
    );
  }

  getCLObjectsForInventoryId(): Observable<Array<any>> {
    const clObject = {
      objects: [
        {
          object_id: '4f6f2c881b3d4068bd0018ebc52ae24d',
          file_id: '910ca65a933e4f889ade910d58682c58',
          version: '1607011345306000001',
          size: 44227,
          mimetype: 'image/jpeg',
          source_client_id: 'e742ebb2-683d-4f1f-a865-9b7a160f2750',
          created_by_user_id: 'fd1e86e0-b1d4-438c-9425-b06d8009359f',
          hashes: {
            'sha256': '2b70539eada8212f17a5d837355395992bd327452f3529b3734cb4d8c4853b53',
            etag: 'b21b1e3dad99a4d87f71158d00cb7c85',
            md5: 'b21b1e3dad99a4d87f71158d00cb7c85'
          },
          metadata: {
            INSTRUMENT: 'instrumentOriginal',
            ASSET_TYPE: 'IMAGE',
            EDataApproved: 'true',
            HASH: 'b21b1e3dad99a4d87f71158d00cb7c85',
            InventoryID: '375976'
          },
          url: 'https://ti-cl-content-useast2-int1.s3.us-east-2.amazonaws.com/1',
          status: 'ACTIVE',
          assetType: 'IMAGE',
          created: '2020-12-03T16:02:25.311+0000',
          url_expires: '2020-12-11T20:32:36.297+0000'
        },
        {
          object_id: '4e51aefcb4a74721b366695eefca7058',
          file_id: '739001446c224ddca2e69deea5e85f73',
          version: '1607011228017000001',
          size: 44227,
          mimetype: 'image/jpeg',
          source_client_id: 'e742ebb2-683d-4f1f-a865-9b7a160f2750',
          created_by_user_id: 'fd1e86e0-b1d4-438c-9425-b06d8009359f',
          hashes: {
            'sha256': '2b70539eada8212f17a5d837355395992bd327452f3529b3734cb4d8c4853b53',
            etag: 'b21b1e3dad99a4d87f71158d00cb7c85',
            md5: 'b21b1e3dad99a4d87f71158d00cb7c85'
          },
          metadata: {
            INSTRUMENT: 'instrumentOriginal',
            ASSET_TYPE: 'IMAGE',
            eDataApproved: 'true',
            HASH: 'b21b1e3dad99a4d87f71158d00cb7c85',
            InventoryID: '375976'
          },
          url: 'https://ti-cl-content-useast2-int1.s3.us-east-2.amazonaws.com/3',
          status: 'ACTIVE',
          assetType: 'IMAGE',
          created: '2020-12-03T16:00:28.020+0000',
          url_expires: '2020-12-11T20:32:36.300+0000'
        }
      ]
    };
    return of(clObject).pipe(map((internalCLObjects) => {
        return internalCLObjects.objects;
      })
    );
  }

  downloadContentLakeObjectBlob(clObjectId: string): Observable<Blob> {
    return this.httpClient
    .get<any>(`${this.baseUrl}/objects/${clObjectId}`, { headers: this.buildHttpHeaders().set('Content-Type', 'application/json') })
    .pipe(concatMap(({ url }) => this.httpClient.get(url, { responseType: 'blob' })));
  }

  private buildHttpHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('X-TI-AGENT-ID', environment.entitlementAgentId)
      .set('only-latest-created', 'true')
      .set('Accept', 'application/json');
  }

  private extracObjectsProps(clObject: CLObjectExcerpt) {
    const { objects } = clObject;
    return objects;
  }

  private mapToCLObjectExcertp(clObjects: ClObjects[]) {
    return clObjects.map((item: ClObjects) => {
      return {
        id: item.object_id,
        reportCode: item.metadata.SF_PRODUCT_CODE,
        reportName: item.metadata.original_filename
      };
    });
  }

  private loopThroughObjectAndExtractUrlProp = (clObjects: ClObjects[]) => {
    return clObjects.map((item: ClObjects) => {
      return item.url;
    });
  }

}
