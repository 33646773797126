import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectOption } from '@app/@shared/models/shared.models';
import { PaginationParameters } from '@shared/models/pagination';


@Component({
  selector: 'app-content-list-view',
  templateUrl: './content-list-view.component.html',
  styleUrls: ['./content-list-view.component.scss']
})
export class ContentListViewComponent {

  @Input() paginationOptions: PaginationParameters;
  @Input() content: any;
  @Input() contentCount: number;
  @Input() loading: boolean;
  @Input() showSort = false;
  @Input() sortOptions: SelectOption[];
  @Input() selectedSort: SelectOption;
  @Input() searchTerm = '';
  @Input() showHighlights = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  /**
   * Requests content(blogs/videos) from the service when the pagination
   * options change
   * @param options the new pagination options
   */
  onChangePaginationOptions(options: PaginationParameters) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { ...options },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }
}
