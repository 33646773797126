import {Component, OnInit} from '@angular/core';
import {ResourcesService} from '@shared/services/resources.service';
import {selectUserProfile} from '@app/store/Selectors/workspace/workspace.selectors';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-cookie-link',
  templateUrl: './cookie-link.component.html',
  styleUrl: './cookie-link.component.scss'
})
export class CookieLinkComponent implements OnInit {
  public resourcesURL: string;
  private userProfile$ = this.store.select(selectUserProfile);

  constructor(private staticResources: ResourcesService, private store: Store) {}

  ngOnInit() {
    this.userProfile$.subscribe((profile) => {
      const { cookiePolicy = '' } = this.staticResources.getResource(profile.type);
      this.resourcesURL = cookiePolicy;
    });
  }
}
