import { NavigationModules } from '@app/@shared/models/navigation/navigation.models';

export const iconMap = {
  'Mobile RF': 'icon-mobileRF',
  'Automotive': 'icon-automotive',
  'Logic': 'icon-logic',
  'Strategic Technologies': 'icon-component',
  'Teardown': 'icon-consumer-electronics',
  'User Experience Innovation': 'icon-ux-innovation',
  'Consumer Electronics': 'icon-consumer-electronics',
  'Wireless': 'icon-mobileRF',
  'User Experience': 'icon-ux-innovation',
  'Custom Library': 'icon-custom-library',
  'TechInsights - Custom Library (W)': 'icon-custom-library',
  'Battery': 'icon-battery',
  'Component Price Landscape': 'icon-cpl',
  'Image Sensor': 'icon-image_sensor',
  'IoT Connectivity SoC': 'icon-iot',
  'Memory - Embedded & Emerging': 'icon-memory2',
  'Memory - NAND & DRAM': 'icon-memory2',
  'Power Semiconductor': 'icon-power',
  'Packaging': 'icon-packaging',
  'Costing': 'icon-costing',
  'Special Content': 'icon-special-content',
  'Sustainability': 'icon-sustainability-content',
  'General': 'icon-mcclean-report',
};

export const defaultIcon = 'icon-default';
export const STRATEGY_ANALYTICS = NavigationModules.STRATEGY_ANALYTICS;
export const SIMPLE_FILTERS_FIELD_NAME = ['entitlementState'];
export const FILTERS_NOT_SHOWN_IN_SIDEBAR = ['flavor'];
export const SUBSCRIPTION_FIELD = 'subscriptionName';
export const CHANNEL_FIELD = 'channel';
