// common.ts
//
// General purpose utility functions that are not tied to a specific class or component.

/**
 * sanitizeNumber checks to see if a number value is actually a number or not.
 *  If not (e.g. null or undefined), a replacement of '?' is returned.
 *  Useful for displaying a value in the UI and avoid having non-numeric values
 *  display their name as in default string conversion, e.g. 'undefined' or 'null', etc.
 *  Originally created for https://techinsights.atlassian.net/browse/CP-3406
 * @param value The number value to check.
 */
export const sanitizeNumber = (value: number): string => {
  // NaN and Infinity are valid number values, so separate checks for them are done,
  //  because they convert to 'Nan' and 'Infinity'.
  //  Note that NaN === NaN is false and Infinity === Infinity is true.
  return typeof value === 'number' && !isNaN(value) && value !== Infinity ? value.toString() : '?';
};

/**
 * Converts an integer value to a string with the appropriate measurement units representing bytes.
 * @param bytes Value to convert.
 * @param decimals Decimal precision.
 * @returns A string with measurement units representing bytes.
 */
export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (bytes > 0) {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  } else {
    return '0 Bytes';
  }
}

/**
 * Gets the module name with camel case from the router url
 * @param routerUrl the router.url from the component
 * @returns A string with the module name
 */
export const moduleNameFromUrl = (routerUrl: string): string => {
  const moduleName = routerUrl.split('/')[1].replace('-', ' ');
  return moduleName.replace(/(?:^|\s)\S/g, (res) => { return res.toUpperCase(); });
}
