import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BlogAuthorModel} from '@shared/models/blog-author.model';

@Component({
  selector: 'app-ask-the-author',
  templateUrl: './ask-the-author.component.html',
  styleUrls: ['./ask-the-author.component.scss']
})
export class AskTheAuthorComponent {
  @Input() authors: BlogAuthorModel[] = [];
  @Input() isAskAuthorEnabled = true;
  @Output() clickAskAuthor: EventEmitter<any> = new EventEmitter<any>();

  onAsk() {
    this.clickAskAuthor.emit();
  }
}
