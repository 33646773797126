import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { defaultSort } from '@app/@shared/consts/select-options';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { ContentNavigatorTab, TabGroup } from '../../models/content-navigator/content-navigator.model';
import { PaginationParameters } from '../../models/pagination';
import { AnalysisFilter, Filters, FiltersChangedEvent } from '../../models/reverse-engineering/analysis.models';
import { SearchResultsEvent, SelectOption } from '../../models/shared.models';
import { FilterHelper } from '../../utils/filter-helper';
import { PaginationHelper } from '../../utils/pagination-helper';
import { TABLET_MAX_WIDTH } from '@app/@shared/consts/breakpoints.consts';
import { TelemetryService } from '@app/@shared/services/telemetry-service';

@UntilDestroy()
@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit {
  @Input() tabGroups: Array<TabGroup>;
  @Input() set filters(analysisFilters: AnalysisFilter[]) {
    this._filters = analysisFilters;
  }
  get filters() {
    return this._filters;
  }
  @Input() selectedFilters: Filters;
  @Input() showFilters = true;
  @Input() moduleName: string;
  @Input() count: number;
  @Input() loadingTable = false;
  @Input() sortOptions: SelectOption[];
  @Input() defaultSort: SelectOption;
  @Input() showHighlightToggle = true;
  @Input() recentSearchTerms: string[];
  @Input() searchModule: string;
  @Input() set activeTab(tab: ContentNavigatorTab) {
    this._activeTab = tab;
  }
  get activeTab() {
    return this._activeTab;
  }
  @Input() set activeTabGroup(group: TabGroup) {
    this._activeTabGroup = group;
  }
  get activeTabGroup() {
    return this._activeTabGroup;
  }

  @Output() changeParametersForSearch = new EventEmitter<SearchResultsEvent>();

  public paginationOptions = new PaginationParameters(50, 1, '');
  public searchTerm = '';
  public raiseTelemetry = false;
  public hiddenSearchLabel = false;
  public hiddenSearchButton = false;
  public isLoading = true;
  public pageSizes = [50, 100, 500];
  public filtersCollapsed = false;
  public fragment: URLSearchParams = new URLSearchParams();
  public sizeOption = { height: 50, width: 50 }
  public selectedSortOption: SelectOption;
  public ifMainSearchTriggred: boolean;

  private _activeTab: ContentNavigatorTab;
  private _activeTabGroup: TabGroup;
  private _filters: AnalysisFilter[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private telemetryService: TelemetryService
  ) { }

  public ngOnInit(): void {
    this.selectedSortOption = this.defaultSort;
    this.paginationOptions.sort = this.defaultSort.value;
    // emit new parameters for analysis table based on search term, pagination, and flavor
    combineLatest([
      this.route.queryParamMap,
    ]).pipe(
      debounceTime(0),
      tap(([queryParams]) => {
        this.searchTerm = queryParams.get('q') ?? '';
        this.ifMainSearchTriggred = queryParams.get('searchKey') === 'main-search';
        this.raiseTelemetry = this.telemetryService.getRaiseTelemetryEvent();
        this.paginationOptions = PaginationHelper.fromParamsOrDefault(
          queryParams, { page: 1, size: 50, sort: this.defaultSort.value });
        this.selectedSortOption = this.sortOptions.find(
          x => x.value === this.paginationOptions.sort) ?? this.defaultSort;
        const activeTabFlavor = queryParams.get('activeTab');
        this.activeTabGroup = this.tabGroups[3];
        this.activeTab = activeTabFlavor
          ? this.activeTabGroup.tabs.find((tab: ContentNavigatorTab) => tab.filterValue === activeTabFlavor)
          : this.activeTabGroup.tabs[0];
        this.mapFilters(queryParams.get('filters'));
      }),
      untilDestroyed(this),
    ).subscribe(() => {
      this.changeParametersForSearch.emit({
        filters: this.selectedFilters,
        filterValue: this.activeTab.filterValue,
        filterCriteria: this.activeTab.filterCriteria,
        activeTabGroupId: this.activeTabGroup.id,
        pagination: this.paginationOptions,
        searchTerm: this.searchTerm,
        raiseTelemetry: this.raiseTelemetry,
      });
      this.isLoading = false;
    });
    this.listenToSidebarState();
  }

  /**
   * Map analisis filters and selected filters
   * @param filters AnalysisFilter[]
   * @param paramFilters string
   */
  public mapFilters( paramFilters: string): void {
    this.selectedFilters = FilterHelper.parseFiltersFromURL(paramFilters);
    if (this.selectedFilters) {
      this.filters = FilterHelper.getSelectedFiltersFromURLParams(this.filters, this.selectedFilters);
    }
  }

  /**
   * Changes the search terms
   * @param event PaginationParameters
   */
  public onChangeSearchTerms(term: string[]): void {
    this.searchTerm = term.join(',');
    this.telemetryService.setRaiseTelemetryEvent(true);
    this.router.navigate([], {
      queryParams: { q: this.searchTerm, page: 1, filters: '', searchKey: 'main-search' },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  /**
   * Changes the pagination options
   * @param event PaginationParameters
   */
  public onChangePaginationOptions(paginationParameters: PaginationParameters): void {
    this.router.navigate([], {
      queryParams: { ...paginationParameters },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  /**
   * Changes the filters
   * @param event FiltersChangedEvent
   */
  public onChangeFilters(event: FiltersChangedEvent): void {
    const filters = FilterHelper.analysisFiltersToURLParams(event.applied);
    this.router.navigate([], {
      queryParams: { page: 1, filters: JSON.stringify(filters) },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  /**
   * Changes the fragment with the updated status of the sidebar
   * @param event sidebar status
   */
  public onToggleFilters(event: boolean) {
    if (this.fragment.get('sidebar')) {
      this.fragment.set('sidebar', String(!event));
    } else {
      this.fragment = new URLSearchParams(`${this.fragment}&sidebar=${!event}`);
    }
    this.router.navigate([], {
      queryParamsHandling: 'preserve',
      fragment: this.fragment.toString(),
    });
  }

  public onClickTab({ tab, tabGroupId }: { tab: ContentNavigatorTab, tabGroupId: string }): void {
    if (this.paginationOptions.sort === 'deviceManufacturer,ASC' && tab.filterValue === 'blog') {
      this.paginationOptions.sort = defaultSort.value;
    }
    this.router.navigate([], {
      queryParams: {
        q: this.searchTerm.toString(),
        page: 1,
        size: this.paginationOptions.size,
        sort: this.paginationOptions.sort,
        activeTab: tab.filterValue,
        tabGroup: tabGroupId,
        filters:  JSON.stringify(this.activeTabGroup.id === tabGroupId ?this.selectedFilters : {}),
      },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  public getBreadcrumbString() {
    return this.moduleName;
  }

  /**
   *  Loads the sidebar state from the url
   */
  private listenToSidebarState() {
    this.route.fragment.subscribe((fragment) => {
      const params = new URLSearchParams(fragment);
      this.filtersCollapsed = params.has('sidebar')
        ? params.get('sidebar').toLowerCase() === 'false'
        : window.innerWidth <= TABLET_MAX_WIDTH;
      this.fragment = params;
    });
  }
}
