import { ChannelCard } from '@app/@shared/models/shared.models';
import { sanitizeNumber } from '@app/@shared/utils/common';
export class SaChannelCard extends ChannelCard {
  public marketDataCount: number;
  public reportsCount: number;
  public complimentaryCount: number;
  public dashboardCount: number;
  public analysisCount: number;
  public isCustom: boolean;

  constructor(
    id: string,
    title: string,
    entitled: boolean,
    marketDataCount: number,
    reportsCount: number,
    complimentaryCount: number,
    dashboardCount: number,
    isCustom: boolean,
  ) {
    super(id, title, entitled);
    this.marketDataCount = marketDataCount;
    this.reportsCount = reportsCount;
    this.complimentaryCount = complimentaryCount;
    this.dashboardCount = dashboardCount;
    this.analysisCount = marketDataCount + reportsCount + complimentaryCount + dashboardCount;
    this.isCustom = isCustom;
  }

  public buildSubtitle(): string {
    if (this.isCustom) {
      return `${sanitizeNumber(this.analysisCount)} Analysis`;
    } else {
      return `${sanitizeNumber(this.marketDataCount)} Market Data | ${sanitizeNumber(this.reportsCount)} Reports |
          ${sanitizeNumber(this.complimentaryCount)} Complimentary | ${sanitizeNumber(this.dashboardCount)} Dashboards`;
    }
  }

  public getSubtitleSegments(): Array<{label: string}> {
    let sourceContentCounting = [];
    if (this.isCustom) {
      sourceContentCounting = [
        { category: 'Analysis', count: this.analysisCount },
      ];
    } else {
      sourceContentCounting = [
        { category: 'Market Data', count: this.marketDataCount },
        { category: 'Reports', count: this.reportsCount },
        { category: 'Complimentary', count: this.complimentaryCount },
        { category: 'Dashboards', count: this.dashboardCount },
      ];
    }
    return sourceContentCounting
      .filter(({ count }) => count === undefined || count > 0)
      .map(({ count, category }) => ({
        label: `${sanitizeNumber(count)} ${category}`,
      }));
  }
}
