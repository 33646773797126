import { Component, Input } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BlogExcerpt } from '@app/@shared/models/market-analysis/market-analysis';
import { AnalysisFilter } from '@app/@shared/models/reverse-engineering/analysis.models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SearchService } from '@shared/services/search/search.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractSubscriptionContentsComponent, SubscriptionContentContextModel } from '../../abstract/abstract-subscription-contents/abstract-subscription-contents.component';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';
import { FilterHelper } from '@app/@shared/utils/filter-helper';
import { TelemetryService } from '@app/@shared/services/telemetry-service';

@UntilDestroy()
@Component({
  selector: 'app-blog-search',
  templateUrl: './blog-search.component.html',
  styleUrls: ['./blog-search.component.scss']
})
export class BlogSearchComponent extends AbstractSubscriptionContentsComponent {
  @Input() getContext: () => Observable<SubscriptionContentContextModel>;
  blogs: Array<BlogExcerpt>;
  blogCount: number;

  constructor(
    route: ActivatedRoute,
    router: Router,
    navigationService: NavigationService,
    private searchService: SearchService,
    private entitlementService: EntitlementService,
    telemetryService: TelemetryService
  ) {
    super(route, router, navigationService, telemetryService);
  }

  @Input() onClickBlog = (blog: BlogExcerpt): void => {
    if (!blog.entitled) {
      const modalTitle = `TechStream: ${blog.category}: ${blog.title}`;
      this.entitlementService.openRequestAccessConfirmDialog(modalTitle);
    } else {
      const fragment = 'name=' + encodeURIComponent(blog.category);
      this.router.navigate(['blog-viewer', blog.id], { fragment, relativeTo: this.route.parent.parent });
    }
  };

  protected requestContent(queryParams: ParamMap): Observable<AnalysisFilter[]> {
    const filters = {
      ...FilterHelper.parseFiltersFromParams(queryParams),
      subscriptionId: this.subscriptionIds,
      channelId: this.channelIds,
    };

    return this.searchService.getBlogs(this.searchTerm, filters, this.paginationOptions).pipe(
      map((result) => {
        this.blogs = result.blogs;
        this.blogCount = result.count;
        return result.filters;
      }),
    );
  }
}
