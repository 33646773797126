import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { SearchService } from '@shared/services/search/search.service';

export interface PowerSearchTab {
  category: string;
  name: string;
  entitled: boolean;
  icon: string;
  inputValue: string;
}

@Component({
  selector: 'app-power-search-tabs',
  templateUrl: './power-search-tabs.component.html',
  styleUrls: ['./power-search-tabs.component.scss']
})

export class PowerSearchTabsComponent implements OnInit {
  @Input() tabs: Array<PowerSearchTab>;
  @Input() activeTab: PowerSearchTab;
  @Input() isSearchBannerActive: boolean;
  @Output() genericSearchButtonClicked: EventEmitter<string[]> = new EventEmitter<string[]>()

  recentSearchTerms: string[];

  constructor(
    private searchService: SearchService
    ) {}

  ngOnInit() {
    this.setSearchTerms('ALL');
  }

  onSearchClicked(searchTerm: string[]){
    this.genericSearchButtonClicked.emit(searchTerm)
  }

  private setSearchTerms(context: string){
    this.recentSearchTerms = this.searchService.getContextSearchTerms(context, 'main-search');
  }
}
