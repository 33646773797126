import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export interface IRadioItemProps{
  class?: string,
  placeholder?: string,
  disabled?: boolean
  label?: string,
  key: string,
}

export interface IRadioProps {
  groupName: string;
  class?:string;
  items: IRadioItemProps[]
}

/**
 * A general radio-button component with implementation to accept ng-model and formcontrol
 */
@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true
    }
  ]
})
export class RadioComponent implements ControlValueAccessor {


  get props(): IRadioProps {
    return this._props;
  }
  @Input()
  set props(value: IRadioProps) {
    this._props = value;
  }

  public disabled: boolean;

  public currentValue: any = [];

  private _props: IRadioProps;

  onTouched = () => {
  };

  onChange = (value: any) => {
  };


  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.currentValue = obj;
    this.onChange(obj);
  }

  handleChange(element: IRadioItemProps) {
    this.writeValue(element.key);
  }
}


