<div class="player-container">
  <div class="wistia_container">
    <div class="wistia_responsive_padding player-responsive-padding">
      <div class="wistia_responsive_wrapper player-wrapper">
        <div
          [class]="'wistia_embed wistia_async_' + videoId + ' ' + embedOptions"
          class="player-embed"
        >
          <div
            class="wistia_swatch player-swatch"
            [class.player-swatch-show]="thumbnailLoaded"
          >
            <img
              class="player-thumbnail"
              [src]="'https://fast.wistia.com/embed/medias/' + videoId + '/swatch'"
              alt=""
              aria-hidden="true"
              (load)="onLoad()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
