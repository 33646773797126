<div class="recent-searches">
  <header class="recent-title">
    Recent Searches
  </header>
  <div *ngFor="let item of recentSearches; let i=index" n>
    <a (click)="search.emit(item.searchTerms)"
       [class.active]="focusedIndex === i"
       (mouseenter)="onMouseEnter(i)"
       (mouseleave)="onMouseLeave()"
       class="recent-item">
      {{item.label}}
    </a>
  </div>
</div>
