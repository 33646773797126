import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TableViewerOptions } from '@app/@shared/models/table-viewer';
import { InventoryService } from '@app/@shared/services/inventory.service';
import { PreviousPageService } from '@app/@shared/services/previous-page.service';
import { AutomativeIcDesignWins } from '@shared/models/automative-ic-design-wins';

@Component({
  selector: 'app-automative-ic-design-wins-table-viewer-page',
  templateUrl: './automative-ic-design-wins-table-viewer-page.component.html',
  styleUrls: ['./automative-ic-design-wins-table-viewer-page.component.scss']
})
@UntilDestroy()
export class AutomativeIcDesignWinsTableViewerPageComponent implements OnInit {
  pinnedColumns = ['Product Manufacturer', 'Product Name', 'Product Model No'];
  tableViewerOptions: TableViewerOptions;
  loadingTableViewer = false;
  columnsOrdered: any = [
    'Product Manufacturer', 'Product Name', 'Product Model No', 'Channel Name', 'Vehicle Brand', 'Vehicle Model',
    'Report Code', 'Report Completion Date', 'Package Ref', 'IC Manufacturer', 'IC Part Number', 'IC Description',
    'Package Qty', 'Die Count', 'IC Cost', 'Package Markings', 'Pkg Length (mm)', 'Pkg Width (mm)', 'Package Type',
    'IO Count', 'Die Manufacturer', 'Die Part Number', 'Die Description', 'Die Markings', 'Die Qty', 'Die Length (mm)',
    'Die Width (mm)', 'Die Area (mm)2', 'Die Cost (Each)'
  ];
  private propertiesToRemove = ['Channel Name', 'Vehicle Brand', 'Vehicle Model', 'Report Code', 'Die Count'];

  constructor(
    public prevPage: PreviousPageService,
    public inventoryService: InventoryService,
  ) {}

  ngOnInit(): void {
    this.initializeTableViewer();
  }

  onClickBack() {
    this.prevPage.navigateBack();
  }

  private initializeTableViewer() {
    this.loadingTableViewer = true;
    this.inventoryService.getAutomativeIcDesignWins()
      .pipe(untilDestroyed(this))
      .subscribe((entities) => {
        this.tableViewerOptions = this.buildTableViewOptions2(entities);
        this.loadingTableViewer = false;
      });
  }

  private buildTableViewOptions2(entities: Array<AutomativeIcDesignWins>): TableViewerOptions {
    const [firstEntity] =  entities;
    this.propertiesToRemove.forEach(item => {
      delete firstEntity[item];
    })

    const columns = Object.keys(firstEntity).map((key, index) => ({
      colId: index.toString(),
      headerName: key,
      field: key,
      pinned: this.pinnedColumns.includes(key),
      filterParams: {
        caseSensitive: true,
      }
    }));

    columns.sort((a, b) => {
      const indexA = this.columnsOrdered.indexOf(a.headerName);
      const indexB = this.columnsOrdered.indexOf(b.headerName);
      return indexA - indexB;
    });
    const rows = entities.map((entity) => {
      const row = {};
      columns.forEach(({ headerName }) => {
        row[headerName] = {
          value: entity[headerName] ? `${entity[headerName]}` : '',
          hyperlink: null,
        };
      });
      return row;
    });
    return { columns, rows };
  }
}
