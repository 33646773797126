import {ReportProgressSubscription} from './reportsProgressSubcription';
import {Analysis} from '@shared/models/reverse-engineering/analysis.models';

export class ReportProgressWithDataModel {
  analysis: Analysis;
  reportProgress: ReportProgressSubscription;

  constructor(report: Analysis, reportProgress: ReportProgressSubscription) {
    this.analysis = report;
    this.reportProgress = reportProgress;
  }
}
