import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalysisFilterFacet } from '@app/@shared/models/reverse-engineering/analysis.models';

@Component({
  template: '',
})
export class BaseFilterComponent {
  @Input() facets: AnalysisFilterFacet[];
  @Output() changeFilter = new EventEmitter<AnalysisFilterFacet>();

  onChangeFilter(facet: AnalysisFilterFacet) {
    this.changeFilter.emit(facet);
  }
}
