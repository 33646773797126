import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AnimationOptions, BMCompleteEvent} from 'ngx-lottie';
import {Observable} from 'rxjs';
import {untilDestroyed} from '@core';
import {AnimationItem} from 'lottie-web';
import {MenuModel} from '@app/store/Models/workspace/workspaceModel';
import {Store} from '@ngrx/store';
import {selectMoreButtonClass} from '@app/store/Selectors/workspace/workspace.selectors';
import {toggleMoreContainer} from '@app/store/Actions/workspace/workspace.actions';
import {DEFAULT_MORE_ICON} from '@app/store/Reducers/workspace/workspace.reducer';
import {animate, style, transition, trigger} from '@angular/animations';

/**
 * Nav button that opens a dialog with more buttons that do not fit on the nav bar
 */
@Component({
  selector: 'app-nav-more-button',
  templateUrl: './nav-more-button.component.html',
  styleUrls: ['./nav-more-button.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ width: 0, opacity: 0 }),
        animate('.15s ease-out', style({ width: 240, opacity: 1 }))
      ]),
      transition(':leave', [
        style({ width: 240, opacity: 1 }),
        animate('.2s ease', style({ width: 0, opacity: 0 }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavMoreButtonComponent implements OnDestroy {
  @Input()
  external = false;
  @Input()
  isCollapsed = true;
  options: AnimationOptions;
  active = false;
  moreMenuButtonClass: Observable<string> = this.store.select(selectMoreButtonClass).pipe(untilDestroyed(this));

  animationPlaying = false;

  private animation: AnimationItem;

  private _menuItem: MenuModel;

  constructor(private _cdRef: ChangeDetectorRef,
              private readonly store: Store) {
  }

  get menuItem(): MenuModel {
    return this._menuItem;
  }

  @Input()
  set menuItem(value: MenuModel) {
    this._menuItem = value;
    this._cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    // Needed for untilDestroyed
  }

  onClick($event: any) {
    this.store.dispatch(toggleMoreContainer());
  }

  getIconClass(isActive: boolean) {
    return this.menuItem ? this.menuItem.icon_class : isActive;
  }

  getIsActive() {
    return this.active
      && this.menuItem?.class === 'more'
      && this.menuItem.icon_class !== DEFAULT_MORE_ICON;
  }

  logoAnimationCreated($event: AnimationItem) {
    this.animation = $event;
  }

  completedAnimation($event: BMCompleteEvent) {
    this.animationPlaying = false;
    this._cdRef.detectChanges();
  }
}
