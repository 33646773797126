<div [class]="label ? 'sort-select-label' : 'sort-select'" appClickOutside (clickOutside)="closeDropdown()">
  <div class="input-wrapper">
    <input
      class="selected-item"
      [class.invalid]="invalid || error"
      [class.disabled]="disabled"
      autocomplete="off"
      [class.active]="dropdownToggle"
      (blur)="onBlur()"
      (keydown)="onKeyDown($event)"
      (click)="openDropdown()"
      type="text"
      [placeholder]="placeholder"
      [(ngModel)]="inputText"
      (ngModelChange)="onNgModelChange($event)"
      name="search-select"
      [readonly]="!editable"
      [disabled]="disabled"
      #searchInput
    />
    <label class="label" *ngIf="label">{{ label }}</label>
    <div class="icon-down_small" [class.invalid]="invalid || error" (click)="toggleDropdown()"></div>
  </div>
  <ul class="dropdown collapsed scrollbar"
    [ngClass]="{ 'collapsed': !dropdownToggle, 'downward':!upward, 'upward': upward }">
    <li class="item clickable" [class.active]="selectedOption === option"
      *ngFor="let option of filteredOptions let index = index" (click)="optionClick(option)" #dropdownElement>
      {{ option.label }}
    </li>
    <li class="item" *ngIf="filteredOptions.length === 0">No results found.</li>
  </ul>
</div>
