import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Core } from '@pdftron/webviewer';
import { AnnotationRequest, AnnotatedReportCode, AnnotationResponse, AnnotatedDocument } from '@shared/models/annotation/annotation.models';
import {
  ContentNavigatorTable,
  ContentNavigatorTableRow,
} from '@shared/models/content-navigator/content-navigator.model';
import Annotation = Core.Annotations.Annotation;
import {
  SaContentNavigatorTable,
  SaContentNavigatorTableRow,
} from '@app/strategy-analytics/models/sa-content-navigator.model';
import { AnalysisAsset, AnalysisReportPage } from '@shared/models/reverse-engineering/analysis.models';
import { AlertService } from '../alert/alert-service';
import { Calibration } from '@app/@shared/models/calibration/calibration.model';
import { AlertMessages } from '@app/@shared/models/alert';

@Injectable({
  providedIn: 'root',
})
export class AnnotationService {
  private readonly _ANNOTATION_ADD = 'add';
  private readonly _ANNOTATION_EDIT = 'edit';
  private readonly _ANNOTATION_DELETE = 'delete';
  private readonly _ANNOTATION_REPORTS = 'reports/annotations';
  private readonly _ANNOTATION_GET = 'annotations';
  private readonly _ANNOTATION_DOCUMENTS = 'documents/annotations';
  private readonly IMAGE_CALIBRATION = 'IMAGE_CALIBRATION'

  constructor(private http: HttpClient, private alertService: AlertService) {}

  getAnnotations(documentId: string) {
    return this.http
      .get<AnnotationResponse[]>(`${environment.annotationServiceBaseUrl}/${this._ANNOTATION_GET}/${documentId}`)
      .pipe(
        map((data) =>
          data.map((a) => {
            return {
              ...a,
              content: a.content ? a.content.replace('/&lt;/gi', '').replace(/src\s*=/gi, '') : '',
            };
          })
        )
      );
  }

  getCalibration(imageId: string): Observable<Calibration | null> {
    return this.http
      .get<AnnotationResponse[]>(`${environment.annotationServiceBaseUrl}/${this._ANNOTATION_GET}/${imageId}`)
      .pipe(
        map((data) => {
          if (data[0]?.type === this.IMAGE_CALIBRATION) {
            const calibration: Calibration = JSON.parse(data[0].content)
            calibration.id = data[0].id;
            return calibration
          } else {
            return null;
          }
        })
      );
  }

  postGetDocumentsAnnotations(documentsIds: Array<string>): Observable<Array<AnnotatedDocument>> {
    return this.http.post<any>(
      `${environment.annotationServiceBaseUrl}/${this._ANNOTATION_DOCUMENTS}`,
      documentsIds.filter((documentId: string) => !!documentId)
    );
  }

  getDocumentAnnotationsForTable(table: AnalysisReportPage): Observable<Array<string>> {
    return this.postGetDocumentsAnnotations(table.reports.map((report: AnalysisAsset) => report.assetId)).pipe(
      map((reports: Array<AnnotatedDocument>) => reports
        .filter((report: AnnotatedDocument) => report.annotated)
        .map((report: AnnotatedDocument) => report.documentId)
      ),
    );
  }

  postGetReportAnnotations(reportCodes: Array<string>): Observable<Array<AnnotatedReportCode>> {
    return this.http.post<any>(`${environment.annotationServiceBaseUrl}/${this._ANNOTATION_REPORTS}`, reportCodes);
  }

  getReportAnnotationsForTable(table: ContentNavigatorTable): Observable<Array<string>> {
    return this.postGetReportAnnotations(table.rows.map((report: ContentNavigatorTableRow) => report.productCode)).pipe(
      map((reports: Array<AnnotatedReportCode>) => reports
        .filter((report: AnnotatedReportCode) => report.annotatedPdfExists)
        .map((report: AnnotatedReportCode) => report.reportCode)
      ),
    );
  }

  getReportAnnotationsForSaTable(table: SaContentNavigatorTable): Observable<Array<string>> {
    return this.postGetReportAnnotations(
      table.rows.map((report: SaContentNavigatorTableRow) => report.productCode)
    ).pipe(
      map((reports: Array<AnnotatedReportCode>) => reports
        .filter((report: AnnotatedReportCode) => report.annotatedPdfExists)
        .map((report: AnnotatedReportCode) => report.reportCode)
      ),
    );
  }

  postSaveCalibration(imageId: string, calibration: Calibration, calibrationId?: string): Observable<void> {
    const annotationRequest = {
      id: calibrationId ?? this.generateRandomUUID(),
      documentId: imageId,
      content: JSON.stringify(calibration),
      type: this.IMAGE_CALIBRATION,
      pageNo: 1,
      collectionType: 'PRIVATE',
    };
    return this.http.post<any>(`${environment.annotationServiceBaseUrl}/${calibrationId? this._ANNOTATION_EDIT : this._ANNOTATION_ADD}`, annotationRequest)
      .pipe(
        tap(
          () => this.alertService.success(AlertMessages.CALIBRATION_READY),
          (_) => this.alertService.error(AlertMessages.CALIBRATION_FAILED),
        ),
      );
  }

  postAddAnnotation(annotationRequest: AnnotationRequest) {
    return this.http.post<any>(`${environment.annotationServiceBaseUrl}/${this._ANNOTATION_ADD}`, annotationRequest);
  }

  postEditAnnotation(annotationRequest: AnnotationRequest) {
    return this.http.post<any>(`${environment.annotationServiceBaseUrl}/${this._ANNOTATION_EDIT}`, annotationRequest);
  }

  deleteAnnotationById(id: string) {
    return this.http.delete<any>(`${environment.annotationServiceBaseUrl}/${this._ANNOTATION_DELETE}/${id}`);
  }

  deleteAnnotations(annotations: Core.Annotations.Annotation[]): Observable<any> {
    return from(annotations).pipe(mergeMap((annotation: Annotation) => this.deleteAnnotationById(annotation.Id)));
  }

  private generateRandomUUID(){
    return crypto.randomUUID();
  }
}
