<div *ngIf="isLoading; else loaded" class="progress">
  <app-progress-bar-circular></app-progress-bar-circular>
</div>
<ng-template #loaded>
  <app-blog-viewer
    [blog]="blog"
    [subscription]="subscriptionDigestSearch ? '' : subscriptionName"
    [isTechstreamView]="isTechstreamView"
  ></app-blog-viewer>
</ng-template>
