import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Video } from '@app/@shared/models/market-analysis/market-analysis';
import { NavigationModules } from '@app/@shared/models/navigation/navigation.models';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';

@Component({
  selector: 'app-recent-video-section',
  templateUrl: './recent-video-section.component.html',
  styleUrls: ['./recent-video-section.component.scss']
})
export class RecentVideoSectionComponent {
  @Input() videos: Array<Video>;
  @Input() subscriptionName: string;
  @Input() moduleName: NavigationModules;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private entitlementService: EntitlementService,
  ) {}

  /**
   * Emit event when video is clicked
   * @param video the video clicked
   */
  onVideoClick(video: Video) {
    if (video.entitled) {
      const fragment = `subscription=${encodeURIComponent(this.subscriptionName)}&name=${encodeURIComponent(this.moduleName)}`;
      this.router.navigate(['video-viewer', video.id], { fragment, relativeTo: this.activatedRoute.parent.parent });
    } else {
      this.entitlementService.openRequestAccessConfirmDialog(`Video: ${video.channelName}:${video.title}`);
    }
  }

  onViewAllClick() {
    this.router.navigate(['videos'], { relativeTo: this.activatedRoute.parent });
  }

}
