<section class="hide-on-mobile hide-on-tablet-custom">
  <div class="logo-stacked" (click)="onClickLogo()"></div>
</section>
<section class="buttons">
  <div class="search-bar">
    <app-generic-search-bar
      [class]="hideSearchButton ? 'shrinked-search-bar' : 'expanded-search-bar'"
      [chipList]="searchTerm"
      [hiddenSearchButton]="hideSearchButton"
      [searchPlaceholder]="searchContext"
      [setRecentSearches]="recentSearchTerms"
      (searchTermsChanged)="onChangeSearchTerms($event)"
      (focusSearchbar)="onFocusSearchbar()"
      (blurSearchbar)="onBlurSearchbar($event)"
    ></app-generic-search-bar>
  </div>
  <button
    #helpButton
    class="icon icon-help"
    (mouseover)="helpButton.classList.add('icon-help_filled')"
    (mouseleave)="helpButton.classList.remove('icon-help_filled')"
    (click)="openMenu('help')"
  ></button>
  <button
    #notificationsButton
    id="notifications_button"
    class="icon icon-notification"
    (mouseover)="notificationsButton.classList.add('icon-notification_filled')"
    (mouseleave)="notificationsButton.classList.remove('icon-notification_filled')"
  ></button>
  <button
    #settingsButton
    class="icon icon-settings"
    (mouseover)="settingsButton.classList.add('icon-settings_filled')"
    (mouseleave)="settingsButton.classList.remove('icon-settings_filled')"
    (click)="onClickSettings()"
  ></button>
</section>
<section class="overlay" [class.hidden]="!isOpened" (click)="closeMenu()"></section>
<section class="menu-container" [class.opened]="isOpened">
  <header>
    <span class="title">{{ title }}</span>
    <button class="close-button" (click)="closeMenu()">
      <span class="close-text">Close</span>
      <span class="close-icon icon-close_small"></span>
    </button>
  </header>
  <section class="menu-content">
    <app-help-and-resources [class.hidden]="activeMenu !== 'help'"></app-help-and-resources>
  </section>
  <section></section>
</section>
