import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/auth';
import { saveUserProfileSuccess } from '@app/store/Actions/settings/settings.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest } from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {
  loadWorkspaceFailure,
  loadWorkspaceSuccess,
  WorkspaceActionTypes,
} from 'src/app/store/Actions/workspace/workspace.actions';
import { NavigationService } from '@shared/services/navigation/navigation.service';
import { MenuHelper } from '@shared/utils/menu-helper';

/**
 * Side effects for menu actions
 */
@Injectable()
export class WorkspaceEffects {

  /**
   * When load workspace action is dispatched this effect loads the user profile
   * and corresponding menu to show.
   */
  GetWorkspace$ = createEffect(() => this.actions$.pipe(
    ofType(WorkspaceActionTypes.loadWorkspace),
    exhaustMap(() => combineLatest([
      this.authService.getProfile(),
      this._navigationService.getNavigationMenu(),
      this.authService.getChannelAuthentication(),
    ]).pipe(
      map(([ profile, menu , entitledChannels]) => loadWorkspaceSuccess({
        menu: menu.map((item) => MenuHelper.sidebarItemtoMenuModel(item)),
        userProfile: {
          ...profile,
          isSubscribedUser: !!entitledChannels.channels.length,
        },
      })),
      catchError(async (err) => loadWorkspaceFailure({ error: err })),
    )),
  ));

  UserProfileUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActionTypes.setUserProfile),
      map(() => saveUserProfileSuccess())
    )
  );

  constructor(
    private actions$: Actions,
    private _navigationService: NavigationService,
    private authService: AuthenticationService,
  ) {}

}
