import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join_strings',
})
export class JoinStringsPipe implements PipeTransform {
  transform(value: string[]): string {
    return value ? value.join(', ') : '';
  }
}
