import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { SelectOption } from '@app/@shared/models/shared.models';

@Component({
  selector: 'app-pagination-top',
  templateUrl: './pagination-top.component.html',
  styleUrls: ['./pagination-top.component.scss'],
})
export class PaginationTopComponent {
  @Input() currentPage: number;
  @Input() selectedSort: SelectOption<string>;
  @Input() showSort = true;
  @Input() sortOptions: SelectOption<string>[];
  @Input() showHighlights: boolean;
  @Input() set count(qty: number) {
    this._count = qty;
    this.totalPages = Math.ceil((qty > 10000 ? 10000 : qty) / this.pageSize);
  }
  get count(): number {
    return this._count;
  }
  @Input() pageSizes = [50, 100, 500];
  @Input() set pageSize(size: number) {
    this._pageSize = size;
    this.totalPages = Math.ceil((this.count > 10000 ? 10000 : this.count) / size);
  }
  get pageSize(): number {
    return this._pageSize;
  }
  @Input() fromUnifiedSearch = false;
  @Output() changePaginationOptions = new EventEmitter<PaginationParameters>();

  totalPages: number;
  private _pageSize: number;
  private _count: number;
  /**
   * Changes the item order
   * @param order the new order
   */
  changeSort(order: any) {
    this.selectedSort = order;
    this.currentPage = 1;
    this.paginationOptionsChanged({ sort: order.value, page: 1 });
  }

  /**
   * Changes the amount of items per page
   * @param size the new amount of items per page
   */
  changePageSize(size: number) {
    this.pageSize = size;
    this.currentPage = 1;
    this.paginationOptionsChanged({ size, page: 1 });
  }

  /**
   * Goes to the next page
   */
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.paginationOptionsChanged({ page: this.currentPage + 1 });
    }
  }

  /**
   * Goes to the previous page
   */
  prevPage() {
    if (this.currentPage > 1) {
      this.paginationOptionsChanged({ page: this.currentPage - 1 });
    }
  }

  /**
   * Generates the title for the toolbar
   * @returns the toolbar title
   */
  generateTitle() {
    const start = 1 + (this.currentPage - 1) * this.pageSize;
    const end = start + (this.pageSize - 1);
    const total = this.count > 10000 ? 10000 : this.count;
    return `${this.count === 0 ? 0 : start}-${end > this.count ? this.count : end} of ${total}`;
  }

  private paginationOptionsChanged(parameters: Partial<PaginationParameters>) {
    this.changePaginationOptions.emit(new PaginationParameters(
      parameters.size ?? this.pageSize,
      parameters.page ?? this.currentPage,
      parameters.sort ?? this.selectedSort?.value,
      { showHighlights: this.showHighlights }
    ));
  }
}
