import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogOptions } from '@shared/services/dialog/dialog.service';

@Component({
  selector: 'app-dialog-modal',
  templateUrl: './generic-dialog.component.html',
  styleUrls: [ './generic-dialog.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class GenericDialogComponent {

  @Input() dialogOptions: DialogOptions = {};

  constructor(
    public modal: NgbActiveModal,
  ) {}

}
