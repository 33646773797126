<div class="header">
  <h4 class="header4-text">{{title}}</h4>
  <a class="body-text" (click)="onClickViewAll()" *ngIf="showViewAll">View All</a>
</div>

<app-channels-card-list
  [channels]="channels"
  [showSubtitle]="showSubtitle"
  (clickChannel)="onClickChannel($event)"
></app-channels-card-list>
