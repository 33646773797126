<button
  (click)="onClick($event)"
  [class.active]="getIsActive()"
  [class.external]="external"
  [class]="menuItem?.class"
  [routerLink]="menuItem?.route"
  appClickOutside
  [routerLinkActive]="'active'"
  [id]="menuItem?.class"
  #routeActive="routerLinkActive"
>
  <div class="button-icon-container">
    <div class="button-icon">
      <div
        class="nav-icon"
        [ngClass]="routeActive.isActive ? menuItem.icon_class + '_filled' : menuItem.icon_class"
      ></div>
      <div *ngIf="menuItem.class === 'notifications'">
        <ng-lottie
          [style.visibility]="animationPlaying ? 'visible' : 'collapse'"
          width="36px"
          height="{{ animationPlaying ? 36 : 0 }}px"
          [options]="options"
          (animationCreated)="logoAnimationCreated($event)"
          (complete)="completedAnimation($event)"
        ></ng-lottie>
      </div>
      <div *ngIf="menuItem?.hasNewInfo" class="marker"></div>
    </div>
  </div>
  <span *ngIf="!isCollapsed" [@inOutAnimation]>{{ menuItem.label }}</span>
</button>
