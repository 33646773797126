import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExcelTable } from '@app/@shared/models/excel-table';
import { TableViewerOptions } from '@app/@shared/models/table-viewer';
import { MetaService } from '@app/@shared/services/meta.service';
import { PreviousPageService } from '@app/@shared/services/previous-page.service';
import { TableViewerPageConfig } from './table-viewer-page.mock';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-table-viewer-page',
  templateUrl: './table-viewer-page.component.html',
  styleUrls: ['./table-viewer-page.component.scss']
})
@UntilDestroy()
export class TableViewerPageComponent implements OnInit {
  title: string;
  subtitle: string;
  tableViewerOptions: TableViewerOptions;
  loadingTableViewer = false;
  showError = false;
  errorMessage = 'An unexpected error occurred retrieving data needed for the Content Navigator'

  constructor(
    public prevPage: PreviousPageService,
    private metaService: MetaService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const { title, subtitle, reportCode, excludedColumns } = this.route.snapshot.data as TableViewerPageConfig;
    this.title = title;
    this.subtitle = subtitle;
    this.initializeTableViewer(reportCode, excludedColumns);
  }

  onClickBack() {
    this.prevPage.navigateBack();
  }

  private initializeTableViewer(reportCode: string, excludedColumns: Array<string>) {
    this.loadingTableViewer = true;
    this.metaService.getExcelTableByReportCode(reportCode)
      .pipe(
        map((excelTable) => {
          this.tableViewerOptions = this.buildTableViewOptions(excelTable, excludedColumns);
          this.loadingTableViewer = false
        }),
        catchError((_err) => {
          this.loadingTableViewer = false
          this.showError = true;
          return _err
        }),
        untilDestroyed(this))
      .subscribe();
  }

  private buildTableViewOptions(excelTable: ExcelTable, excludedColumns: Array<string>): TableViewerOptions {
    const { freezePane, verticalSplitPosition } = excelTable.paneInformation;
    const [excelColumnRow, ...excelDataRows] = excelTable.rows;
    const columns = excelColumnRow.cells.map(({ colIdx, value }) => ({
      colId: colIdx.toString(),
      headerName: value,
      field: value,
      pinned: freezePane && verticalSplitPosition > colIdx,
      filterParams: {
        caseSensitive: true,
      }
    }))
    .filter(({ field }) => !excludedColumns.includes(field));

    const rows = excelDataRows.map(({ cells }) => {
      const row = {};
      columns.forEach(({ headerName, colId }) => {
        row[headerName] = {
          value: cells[colId].value,
          hyperlink: cells[colId].hyperlink,
        };
      });
      return row;
    });
    return { columns, rows }
  }

}
