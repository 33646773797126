import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import {
  DBToolsSubscriptionCard,
  Entitlement,
  SubscriptionCard,
  TableauConfig
} from '@app/@shared/models/shared.models';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { environment } from '@env/environment';
import { DatabaseToolsIds, DATABASE_TOOLS_MAP, ENTITLEMENT_MAP_DATABASE_TOOL, DATABASE_TOOL_ICON_MAP } from './database-tools';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import { DatabaseTools, NavigationHint, NavigationModules } from '@app/@shared/models/navigation/navigation.models';

export interface DBToolsCardConfig extends TableauConfig {
  cardName: string
}

@Injectable({
  providedIn: 'root'
})
export class DatabaseToolsService {

  constructor(
    private entitlementService: EntitlementService,
    private featureFlagService: FeatureFlagService,
    private navigationService: NavigationService,
  ) {}

  getDBToolsConfig (paramSubscriptionId: string, ticket: string): TableauConfig {
    const subscriptionId: DatabaseToolsIds = paramSubscriptionId as DatabaseToolsIds;
    const config: TableauConfig = DATABASE_TOOLS_MAP.get(subscriptionId);
    const finalUrl = config?.url ? config?.url.replace('<ticket>', ticket) : '';
    return {
      url: finalUrl,
      height: config?.height,
      width: config?.width,
    };
  }

  getDatabaseToolsBySubscriptionId(module: string, subscriptionId: string): Observable<Array<DBToolsSubscriptionCard>> {
    return combineLatest([
      this.navigationService.getSubscriptionDatabaseTools(module, subscriptionId),
      this.entitlementService.getEntitlementList(),
    ]).pipe(
      concatMap(([databaseTools, entitlements]) => {
        return this.getDatabaseToolsCard$(databaseTools, entitlements);
      }),
      map(databaseTools => databaseTools.map(d => ({...d, icon: 'icon-database-tools'})))
    );
  }

  getAllDatabaseTools(): Observable<Array<DBToolsSubscriptionCard>> {
    return combineLatest([
      this.navigationService.getModuleDatabaseTools(NavigationModules.DATABASE_TOOLS),
      this.entitlementService.getEntitlementList(),
    ]).pipe(
      concatMap(([databaseTools, entitlements]) => {
        return this.getDatabaseToolsCard$(databaseTools, entitlements);
      }),
    );
  }

  private getDatabaseToolsCard$(
    databaseTools: Array<DatabaseTools>, entitlements: Array<Entitlement>
  ): Observable<Array<DBToolsSubscriptionCard>> {
    if (databaseTools.length === 0) { return of([]) }
    const entitledChannelIds = entitlements.map(({ channelId }) => channelId);
    const databaseTools$ = databaseTools
      .filter(databaseTool => {
        const isTableauDatabase = databaseTool.content === NavigationHint.TABLEAU;
        return isTableauDatabase ? this.isTableauDatabaseHidden(databaseTool, entitledChannelIds): true;
      })
      .map(databaseTool => {
        const databaseToolCard = {
          id: databaseTool.database_tool_id,
          name: databaseTool.name,
          navigationHint: databaseTool.content,
          icon: DATABASE_TOOL_ICON_MAP.get(databaseTool.database_tool_id as DatabaseToolsIds),
          entitled: true,
          bypassEntitlement: false,
          availableChannels: -1,
          totalChannels: 0
        };
        return databaseTool.content === NavigationHint.AG_GRID
          ? this.addReportDatabaseToolEntitlement$(databaseToolCard)
          : of(this.addBOMDatabaseToolEntitlement(databaseToolCard, entitledChannelIds));
      });
    return combineLatest([...databaseTools$]);
  }

  private isTableauDatabaseHidden(database: DatabaseTools, entitledChannelIds: Array<string>): boolean {
    const entitlementFlagId = ENTITLEMENT_MAP_DATABASE_TOOL.get(database.database_tool_id as DatabaseToolsIds);
    if (entitlementFlagId === environment.entitlementExtraId) {
      if (entitledChannelIds.indexOf(entitlementFlagId) !== -1) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }

  private addBOMDatabaseToolEntitlement(
    card: DBToolsSubscriptionCard, entitledChannelIds: Array<string>
  ): DBToolsSubscriptionCard {
    let entitled = false;
    const entitlementFlagId = ENTITLEMENT_MAP_DATABASE_TOOL.get(card.id as DatabaseToolsIds);
    if(entitlementFlagId === environment.principalEntitlementId){
      if(entitledChannelIds.indexOf(entitlementFlagId) === -1){
        entitled = entitledChannelIds.indexOf(environment.entitlementSubsystemId) !== -1;
      } else {
        entitled = entitledChannelIds.indexOf(entitlementFlagId) !== -1;
      }
    } else {
      entitled = entitledChannelIds.indexOf(entitlementFlagId) !== -1;
    }
    return { ...card, entitled };
  }

  private addReportDatabaseToolEntitlement$(card: DBToolsSubscriptionCard): Observable<DBToolsSubscriptionCard> {
    const reportCode = ENTITLEMENT_MAP_DATABASE_TOOL.get(card.id as DatabaseToolsIds);
    return this.entitlementService.getReportAccess(reportCode).pipe(
      catchError(() => of({
        entitled: false,
      })),
      map(({ entitled }) => ({ ...card, entitled }))
    );
  }

}
