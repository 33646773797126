<section class="content">
    <div *ngIf="loadingTable; else loaded" class="progress">
        <app-progress-bar-circular></app-progress-bar-circular>
    </div>
    <ng-template #loaded>
        <div class="content-wrapper">
            <app-title-bar [tagNameList]="tagName" [title]="'Platform Activity'" (clickBack)="onClickBack()"
                [showBackButton]="prevPage.hasHistory()"></app-title-bar>
            <app-tab-bar [tabs]="tabs"></app-tab-bar>
            <app-pagination-top [showSort]="false" [count]="table?.count" [currentPage]="currentPage" [pageSize]="pageSize"
                (changePaginationOptions)="onChangePaginationOptions($event)"></app-pagination-top>
            <div class="table-container scrollbar">
                <app-recent-view-table *ngIf="table && table.rows.length > 0;else noResultsText" [table]="table"
                    (clickRow)="onClickRow($event)" class="recent-view-table"></app-recent-view-table>
                <ng-template #noResultsText>
                    <div class="no-content">No content yet. Content you view will be listed here for quick access.</div>
                </ng-template>
            </div>
            <app-pagination-bottom [count]="table?.count" [startingPage]="currentPage" [startingPageSize]="pageSize"
                (changePaginationOptions)="onChangePaginationOptions($event)"></app-pagination-bottom>
        </div>
    </ng-template>
</section>