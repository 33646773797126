import {IFilterType, ISearchSort, ISearchTerms} from '@app/store/Models/search/searchModels';

/**
 * Class to map the search terms to a search terms request, with all the necessary
 * fields that the request body should have
 */
export class SearchTermsRequestModel {
  searchText?: string[];
  selectedFilters?: IFilterType[];
  selectedContentType?: string[];
  sortMethod?: ISearchSort;
  indexStart?: number;
  indexEnd?: number;

  constructor(terms: ISearchTerms) {
    this.searchText = terms.searchText;
    this.selectedFilters = [];
    if (Array.isArray(terms.selectedFilters)) {
      terms.selectedFilters.forEach(filter => {
        const subtypes = filter.subTypes && filter.subTypes.length > 0
          ? filter.subTypes.filter(subtype => subtype.value === true)
          : [];
        if (subtypes.length > 0) {
          this.selectedFilters = [
            ...this.selectedFilters,
            ...subtypes.map(subtype => {
              return {
                key: filter.key,
                label: filter.label,
                value: subtype.key
              };
            })
          ];
        }
      });
    }
    this.selectedContentType = Array.isArray(terms.selectedContentType)
      ? terms.selectedContentType.map(v => v.key)
      : [terms.selectedContentType.key];
    this.selectedContentType = this.selectedContentType.filter(v => v != null && v.length > 0);
    this.sortMethod = terms.sortMethod;
    this.indexStart = terms.indexStart;
    this.indexEnd = terms.indexEnd;
  }
}
