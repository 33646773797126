<div class="checkbox">
  <input
    type="checkbox"
    [checked]="selectedImages.allSelected"
    [indeterminate]="selectedImages.selectedImagesQty > 0 && selectedImages.allSelected === false"
    (click)="selectAll()"
  />
</div>
<div class="selected-images">{{ selectedImages.selectedImagesQty }} Selected</div>
<button
  class="download-button"
  *ngIf="downloadEnabled"
  placement="top"
  container="body"
  [ngbTooltip]="('Total Download Size: ' + selectedImages.totalSize)"
  (click)="downloadEnabled && onClickDownload()"
>
  <em class="icon-button_download"></em> Download
</button>
