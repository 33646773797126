<ng-container *ngIf="tableauConfig">
  <app-title-bar
    [dbToolCard]="cFootprintCard"
    [cFootprintFF]="cFootprintFFlag"
    [tagNameList]="[ titleName ]"
    [title]="subscriptionName"
    [showBackButton]="prevPage.hasHistory()"
    (clickBack)="onClickBack()"
    (requestAccess)="onClickRequestAccess()"
  ></app-title-bar>
  <app-tableau-iframe
    class="tableau-iframe"
    [config]="tableauConfig"
  ></app-tableau-iframe>
</ng-container>
