<div class="measurement-dialog-container">
    <div class="measurement-content-container">
        <div class="measurement-type-icon" [class]="iconClass"></div>
        <div class="measurement-text-container">
            <div class="measurement-text-content">{{measurementType}}: {{measurementText}}
            </div>
            <div class="measurement-text-footer">
            {{calibrationText}}
            </div>
            <div class="measurement-text-footer">
            {{footerText}}
            </div>
        </div>
    </div>
    <div class="measurement-clear-button-container">
        <span (click)="recalibrate()">Recalibrate</span>
        <button (click)="modal.close()">Clear</button>
    </div>
</div>