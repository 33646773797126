<div *ngIf="loading; else loaded" class="spinner">
  <app-progress-bar-circular ></app-progress-bar-circular>
</div>
<ng-template #loaded>
  <section class="view-options-bar">
    <app-pagination-top
    [count]="contentCount"
    [currentPage]="paginationOptions.page"
    [pageSize]="paginationOptions.size"
    [pageSizes]="[10,50,100]"
    [sortOptions]="sortOptions"
    [selectedSort]="selectedSort"
    [showSort]="showSort"
    [showHighlights]="showHighlights"
    (changePaginationOptions)="onChangePaginationOptions($event)"
    ></app-pagination-top>
  </section>

  <ng-content></ng-content>

  <section class="bottom-pagination">
    <app-pagination-bottom
    *ngIf="contentCount > paginationOptions.size"
    [count]="contentCount"
    [startingPage]="paginationOptions.page"
    [startingPageSize]="paginationOptions.size"
    [showHighlights]="showHighlights"
    (changePaginationOptions)="onChangePaginationOptions($event)"
    ></app-pagination-bottom>
  </section>
</ng-template>
