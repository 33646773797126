/* eslint-disable no-console */
import {Injectable} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {from, timer} from 'rxjs';
import {Store} from '@ngrx/store';
import {notifyNewVersion} from '@app/store/Actions/application-health/application-health.actions';
import {environment} from '@env/environment';

/**
 * Service to configure PWA app update frequency and behaviour
 */
@Injectable({
  providedIn: 'root'
})
export class PwaConfigService {
  constructor(private swUpdateService: SwUpdate, private store: Store) {}
  runUpdate() {
    if(this.swUpdateService.isEnabled) {
      // Dispatch a popup if a new versionis ready to be installed
      this.swUpdateService.versionUpdates.subscribe(event => {
        if ('currentVersion' in event && 'latestVersion' in event) {
          const versionReadyEvent = event as VersionReadyEvent;
          this.swUpdateService.activateUpdate().then(() => {
            this.store.dispatch(notifyNewVersion());
          });
          // Show a message in the console if a new version was recently activated
          console.info('old version was', versionReadyEvent.currentVersion);
          console.info('new version is', versionReadyEvent.latestVersion);
        }
      });
      // Check for updates every three hours
      from(timer(0, environment.pwaUpdatePeriod)).subscribe(() => {
        console.info('checking for app updates');
        this.swUpdateService.checkForUpdate().then(r => console.info('finished checking for updates'));
      });
    }
  }
}
