import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Calibration, CalibrationUnits } from '@app/@shared/models/calibration/calibration.model';
import { SelectOption } from '@app/@shared/models/shared.models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IInputProps } from '../../input/input.component';

@Component({
  selector: 'app-image-calibration-modal',
  templateUrl: './image-calibration-modal.component.html',
  styleUrls: ['./image-calibration-modal.component.scss']
})
export class ImageCalibrationModalComponent {
  // scale reference
  scaleRefProps: IInputProps = {
    class: 'form-control',
    label: '',
    placeholder: 'Type in a number',
    disabled: false,
  };

  // units
  unitsOptions: Array<SelectOption<CalibrationUnits>> = [
    { label: 'cm', value: CalibrationUnits.CENTIMETERS },
    { label: 'mm', value: CalibrationUnits.MILLIMETERS },
    { label: 'µm', value: CalibrationUnits.MICROMETERS },
    { label: 'nm', value: CalibrationUnits.NANOMETERS },
  ];
  initialUnitsOption: SelectOption = this.unitsOptions[2];

  // form
  calibrationForm = this.fb.nonNullable.group({
    scaleRef: new FormControl<Calibration['scaleRef']>(null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0)]),
    units: new FormControl<Calibration['units']>(this.initialUnitsOption.value, [Validators.required]),
  });

  constructor(
    public modal: NgbActiveModal,
    public fb: FormBuilder,
  ) { }

  onBlurInput(controlName: string) {
    this.calibrationForm.controls[controlName].markAsTouched();
  }

  onChangeUnits(units: SelectOption) {
    this.calibrationForm.controls.units.setValue(units.value);
  }

  onApply() {
    this.modal.close(this.calibrationForm.value);
  }
}
