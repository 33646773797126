import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupModel } from './../../../store/Models/notification/notificationModel'

/**
 * A general propose popup component
 */
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent {
  @Input() props: PopupModel;
  constructor(public modal: NgbActiveModal) {}

  onPrimaryButtonClick() {
    if(this.props.primaryButtonBehaviour) {
      this.props.primaryButtonBehaviour();
    } else {
      this.modal.close('primary click')
    }
  }
}
