<app-table-viewer-controls
  [columns]="columns"
  [isTableSorted]="isTableSorted"
  [isColumnFilterActive]="isColumnFilterActive"
  (resetSort)="handleResetSort()"
  (clearAllFilters)="handleClearAllFilters()"
  (hideColumnToggle)="handleHideColumnToggle($event)"
  (searchValueChange)="handleSearchValueChange($event)"
></app-table-viewer-controls>

<ag-grid-angular
  class="ag-theme-material table-viewer-ag-grid"
  [gridOptions]="gridOptions"
></ag-grid-angular>
