export enum UserViewHistoryItemType {
  PDF = 'PDF',
  IMAGE = 'IMAGE',
  EXCEL = 'EXCEL',
  BLOG = 'BLOG',
  VIDEO = 'VIDEO',
  BOM_DB = 'BOM_DB',
  WEBINAR = 'WEBINAR',
  VIC_REPORT = 'VIC_REPORT',
}

export enum UserViewHistoryItemSource {
  META = 'META',
  CONTENT_LAKE = 'CONTENT_LAKE',
  IP_SEARCH = 'IP_SEARCH',
}
