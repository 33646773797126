import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PreviousPageService } from '@shared/services/previous-page.service';
import { DBToolsSubscriptionCard } from '@shared/models/shared.models';

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: [ './title-bar.component.scss' ],
})
export class TitleBarComponent {

  @Input() tagNameList: string[];
  @Input() tagColor: string;
  @Input() title: string;
  @Input() showBackButton: boolean;
  @Input() backButtonTitle = 'Back';
  @Input() dbToolCard: DBToolsSubscriptionCard;
  @Input() cFootprintFF: boolean;
  @Output() clickBack = new EventEmitter();
  @Output() requestAccess = new EventEmitter();

  public readonly upgradeBtnTitle = 'Upgrade to Display Carbon Footprint';

  constructor(
    public prevPage: PreviousPageService,
  ) {}

  onClickBack() {
    this.clickBack.emit();
  }

  onClickRequestAccess() {
    this.requestAccess.emit();
  }

}
