import { Component, Input } from '@angular/core';
import { Video } from '@shared/models/market-analysis/market-analysis';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
})
export class VideoCardComponent {
  @Input() video: Video;
}
