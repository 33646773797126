import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenealogyTree } from '@shared/models/genealogy/genealogy-tree';
import { ChildrenParentCount } from '../models/genealogy/childrenParentCount';

@Injectable({
  providedIn: 'root',
})
export class JanetService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Given a code, it returns summary details for
   * @param genealogyCode code to look for
   */
  getDeviceSummaryDetails(genealogyCode: string, includeDieInventory: boolean = false ): Observable<any> {
    const queryParams = new HttpParams().set('genealogyCode', genealogyCode)
                                        .set('includeDieInventory', `${includeDieInventory}`);
    const url = `${environment.janetUrl}/products/genealogyCode`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.get<any>(url, { headers, params: queryParams });
  }

  /**
   * Gets the entire tree for the code
   * @param genealogyCode genealogy code
   */
  getGenealogyTree(genealogyCode: string): Observable<GenealogyTree> {
    const queryParams = new HttpParams().set('genealogyCode', genealogyCode);
    const url = `${environment.janetUrl}/genealogy/genealogyCode`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.get<GenealogyTree>(url, { headers, params: queryParams });
  }

  /**
   * Gets the tree for the code
   * @param genealogyCode genealogy code
   * @param deviceTypeList list of device types split by coma
   */
  getGenealogyTreeFiltered(
    genealogyCode: string,
    deviceTypeList: string,
    manufacturerList: string
  ): Observable<GenealogyTree> {
    const queryParams = new HttpParams()
      .set('genealogyCode', genealogyCode)
      .set('deviceTypes', deviceTypeList)
      .set('manufacturers', manufacturerList);
    const url = `${environment.janetUrl}/genealogy/genealogyCode`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.get<GenealogyTree>(url, { headers, params: queryParams });
  }

  /**
   * Gets the number of parent and childrens
   * @param genealogyCode genealogy code
   */
  getChildrenParentsCount(genealogyCode: string): Observable<ChildrenParentCount> {
    const queryParams = new HttpParams().set('genealogyCode', genealogyCode);
    const url = `${environment.janetUrl}/genealogy/childrenParentsCount`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.get<ChildrenParentCount>(url, { headers, params: queryParams });
  }

  /**
   * Gets the Associated Products of multiple genealogyCodes
   * @param genealogyCodes Array of GenealogyCodes
   */
  getAssociatedProducts(genealogyCodes: any): Observable<any>{
    const queryParams = new HttpParams().set('genealogyCodes', genealogyCodes);
    const url = `${environment.janetUrl}/products/associatedProducts`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.get(url, { headers, params: queryParams });
  }

  /**
   * Gets the product info of multiple genealogyCodes
   * @param genealogyCodes Array of genealogyCodes
   */
  getProducts(genealogyCodes: any): Observable<any>{
    const queryParams = new HttpParams().set('genealogyCodes', genealogyCodes);
    const url = `${environment.janetUrl}/products/products`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.get(url, { headers, params: queryParams });
  }
}
