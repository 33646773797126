<div class="modal-close-button" (click)="modal.dismiss()"><em class="icon-close_medium"></em></div>
<div class="content">
  <div class="title" *ngIf="props.title">
    <em *ngIf="props.titleIconClass" class="icon {{props.titleIconClass}}"></em>
    {{ props.title }}
  </div>
  <div *ngIf="props.body" class="body">{{ props.body }}</div>
  <div  *ngIf="props.isSubscription" class="body">
    <span>
        Explore our <a class="link" [href]="complimentaryContent">complimentary content</a>
        or have sales contact you about your <a class="link" [href]="mySubscriptions">subscription.</a>
      </span>
  </div>
  <div>
    <span *ngIf="props.bodyTitle" class="bodyTitle">{{ props.bodyTitle }}</span>
    <ul *ngIf="props.bodyList" class="bodyList">
      <li *ngFor="let list of props.bodyList; let i=index">
        <span>{{ list }}</span>
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button *ngIf="props.closeButtonLabel" class="btn-modal-secondary" (click)="modal.dismiss()">
      <em *ngIf="props.closeButtonIconClass" class="icon {{props.closeButtonIconClass}}"></em>
      {{ props.closeButtonLabel }}
    </button>
    <button *ngIf="props.actionButtonLabel" class="btn-modal-primary" (click)="modal.close()">
      <em *ngIf="props.actionButtonIconClass" class="icon {{props.actionButtonIconClass}}"></em>
      {{ props.actionButtonLabel }}
    </button>
  </div>
</div>
