import { Component, OnInit } from '@angular/core';
import { DialogOptions, DialogService } from '@app/@shared/services/dialog/dialog.service';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';
import { ContentExportService, DownloadResponse} from '@app/@shared/services/content-export/content-export.service';

@Component({
  selector: 'app-content-export',
  templateUrl: './content-export.component.html',
  styleUrls: ['./content-export.component.scss']
})
export class ContentExportComponent implements OnInit {

  loading = true;
  downloadComplete = false;
  constructor(private entitlementService: EntitlementService,
    private dialogService: DialogService,
    private contentExportService: ContentExportService) { }

  ngOnInit(): void {

    this.contentExportService.downloadXML().subscribe((downloadResponse: DownloadResponse)=>{
      switch(downloadResponse){
        case DownloadResponse.SUCCESS:
          this.downloadComplete = true;
          break;
        case DownloadResponse.ENTITLEMENT_ERROR:
          this.entitlementService.openRequestAccessConfirmDialog('Xml content summary extract');
          break;
        case DownloadResponse.SERVER_ERROR:
          const DEFAULT_ERROR_OPTIONS: DialogOptions = {
            titleIconClass: 'icon-warning',
            title: 'Oops... Something went wrong',
            body: 'Sorry, we could not download your file due to a technical problem. ' +
              'Please try again in a few minutes, however if this problem persists contact customer success.',
            closeButtonLabel: 'Close',
          };
          this.dialogService.openError(DEFAULT_ERROR_OPTIONS);
          break;
      }
      this.loading = false;
    });
  }
}
