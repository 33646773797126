<nav *ngIf="includeTableOfContent">
    <h3>Table of Contents</h3>
    <ng-container *ngFor="let section of sections">
        <ul *ngIf="section.title">
            <li>
                <em class="icon-right_small"></em>
                <a (click)="onClick(section.title)">{{ section.title }}</a>
            </li>
        </ul>
    </ng-container>
</nav>
