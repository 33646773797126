/* eslint-disable no-console */
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MarketAnalysisService } from '@app/@shared/services/market-analysis/market-analysis.service';
import { AnalysisMetadataResponse, Analysis } from '@app/@shared/models/reverse-engineering/analysis.models';
import { TelemetryEventName } from '@shared/enums/telemetry-event-name';
import { TelemetryEvent } from '@app/@shared/models/telemetry-event';
import { MetaService } from '@app/@shared/services/meta.service';
import { combineLatest } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-cross-linking',
  template: ''
})
@UntilDestroy()
export class CrossLinkingComponent implements OnInit {

  public analysis: Analysis;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private marketAnalysisService: MarketAnalysisService,
    private metaService: MetaService,
  ) { }

  ngOnInit(): void {
   combineLatest([
      this.route.queryParamMap,
    ]).pipe(untilDestroyed(this))
      .subscribe(([queryParams]) => {
        const reportCode = queryParams.get('report-code');
        this.marketAnalysisService.getAnalysisMetadata(reportCode)
          .subscribe((analysisMetadata: AnalysisMetadataResponse) => {
            this.analysis = analysisMetadata.report;
            this.postTelemetryEvent(this.analysis.code, this.analysis.name);
          },
          (error) => {
            console.log(error);
          })
          this.router.navigate([ 'strategy-analytics', 'analysis-view', reportCode ]);
      });
  }

  public postTelemetryEvent(code: string, title: string): void {
    const payload = {
      productCode: code,
      productTitle: title
    }
    const event = new TelemetryEvent(TelemetryEventName.EVENT_DESTINATION_CROSSLINKING, payload);
    this.metaService.postTelemetryEvent(event).subscribe();
  }

}
