import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {combineLatest, forkJoin, Observable} from 'rxjs';
import {map, shareReplay } from 'rxjs/operators';
import {environment} from '@env/environment';
import {
  Channel,
  Dashboard,
  DatabaseTools,
  MegaMenu,
  NavigationElement,
  NavigationHint,
  NavigationModules,
  NavigationObjectType,
  NavigationTreeNode,
  ReportModule,
  SidebarItem,
  SideBarResponse,
  Subscription,
} from '@app/@shared/models/navigation/navigation.models';
import {NavigationElementFactory} from './navigation-element-factory';
import {FeatureFlagService} from '../featureflag.service';
import {DatabaseToolsIds} from '@app/database-tools/service/database-tools';
import {EntitlementService} from '../entitlement/entitlement.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly MODULES_ENDPOINT = 'assets/navigation/modules.config.json';
  private readonly navigation$: Observable<NavigationTreeNode>;
  private readonly enableSemiGptModule$ = this._featureFlagService.getFlag(environment.enableSemiGptModule);
  private readonly enableMarketSegments$ = this._featureFlagService.getFlag(environment.enableMarketSegments);

  private mobileMegaMenu$: Observable<MegaMenu[]>;
  private publicMobileMegaMenu$: Observable<MegaMenu[]>;

  constructor (
    private http: HttpClient,
    private entitlementService: EntitlementService,
    private _featureFlagService: FeatureFlagService,
  ) {
    this.navigation$ = this.http
      .get(`${environment.navigationServiceUrl}/navigation_content?app_context=${environment.entitlementAgentId}`)
      .pipe(map(this.mergeResponseTreesAndRemoveDuplicates), shareReplay(1));
  }

  getMegaMenu(): Observable<MegaMenu[]> {
    return this.http.get<any>(`${environment.navigationServiceUrl}/megamenu_navigation_content`).pipe(
      map(response => this.buildMegamenu(response))
    );
  }

  getPublicMegaMenu(): Observable<MegaMenu[]> {
    return this.http.get<any>(`${environment.navigationServiceUrl}/megamenu_navigation_content_public`).pipe(
      map(response => this.buildMegamenu(response))
    );
  }

  getMobileMegaMenu(): Observable<MegaMenu[]> {
    // This creates a cache mechanism so that the user doesn't have a delay when opening the menu
    if(!this.mobileMegaMenu$) {
      this.mobileMegaMenu$ = this.http.get<any>(`${environment.navigationServiceUrl}/mobile_megamenu_navigation_content`).pipe(
        map(response => this.buildMegamenu(response)),
        shareReplay(1)
      );
    }
    return this.mobileMegaMenu$;
  }

  getPublicMobileMegaMenu(): Observable<MegaMenu[]> {
    // This creates a cache mechanism so that the user doesn't have a delay when opening the menu
    if(!this.publicMobileMegaMenu$) {
      this.publicMobileMegaMenu$ = this.http.get<any>(`${environment.navigationServiceUrl}/mobile_megamenu_navigation_public`).pipe(
        map(response => this.buildMegamenu(response)),
        shareReplay(1)
      );
    }
    return this.publicMobileMegaMenu$;
  }

  /**
   * Merges menu config with MODULEs coming from the NavigationService in order to build the sidebar menu
   * @returns an array of sidebar items which is the menu for a certain user type
   */
  getNavigationMenu (): Observable<SidebarItem[]> {
    // get MODULEs from the backend & MODULE definitions from the FE config file
    return combineLatest([
      this.getSidebarModules(),
      this.getModulesConfig(),
      this.entitlementService.getTechLibraryEntitlement(),
      this.enableSemiGptModule$,
      this.enableMarketSegments$,
      this._featureFlagService.getFlag(environment.enableMyTechInsights),
    ]).pipe(
      // Progress Tracker is simulated until the tracker progress is tested in the service (MOCK DATA)
      map((result: [NavigationTreeNode[], any, boolean, boolean, boolean, boolean]) => {
        const [availableModules] = result;
        if (!availableModules.find((am: NavigationTreeNode) => am.name === NavigationModules.PROGRESS_TRACKER)) {
          availableModules.push({
            name: NavigationModules.PROGRESS_TRACKER,
            type: NavigationObjectType.MODULE,
            isCustom: false,
            container: NavigationHint.SIDEBAR,
            content: null,
            children: [],
          });
        }
        return result;
      }),
      map(([
             availableModules, allModules, isTechLibraryEntitled,
             isSemiGptModuleEnabled, isMarketSegmentsEnabled, isMyTechInsightsEnabled,
           ]) => {
        availableModules = availableModules.filter(module =>
          ![
            NavigationModules.MARKET_SEGMENTS,
            NavigationModules.THE_MCCLEAN_REPORT,
            NavigationModules.TECH_LIBRARY,
          ].includes(module.name) ||
          (module.name === NavigationModules.MARKET_SEGMENTS && isMarketSegmentsEnabled) ||
          (module.name === NavigationModules.TECH_LIBRARY && isTechLibraryEntitled),
        );
        availableModules.push({
          name: NavigationModules.COMPANY_OVERVIEW,
          type: NavigationObjectType.MODULE,
          isCustom: false,
          container: NavigationHint.SIDEBAR,
          content: null,
          children: [],
        });
        if (!isSemiGptModuleEnabled) {
          availableModules = availableModules.filter(({name}) => name !== NavigationModules.SEMI_GPT);
        }
        if (!isMyTechInsightsEnabled) {
          availableModules = availableModules.filter(({name}) => name !== NavigationModules.MY_TECHINSIGHTS);
        }
        // for each MODULE the user can see, we need to get its definitions (icon, route, etc)
        return availableModules
          .map((module: any) => {
            const moduleInfo: any = allModules.find((mInfo: any) => mInfo.name === module.name);
            return NavigationElementFactory.buildSidebarItem({...module, ...moduleInfo});
          })
          .sort(this.sortMenuItems);
      }),
    );
  }

  getSidebar(): Observable<SideBarResponse> {
    return this.http.get<any>(`${environment.navigationServiceUrl}/left_sidebar_navigation_content`);
  }

  /**
   * When a module needs to display cards this method builds them and returns them
   * @param name The name of the module
   * @returns list of navigation elements which could be channels or subscriptions
   */
  getModuleCardsByName (name: string): Observable<NavigationElement[]> {
    return this.getNavigation().pipe(
      map((response) => response.children),
      map((firstLevelChildren) =>
        firstLevelChildren.filter((child: any) => child.type === NavigationObjectType.MODULE),
      ),
      map((modules) => modules.find((module: any) => module.name === name)),
      map((module: any) => module.children.map((child: any) => NavigationElementFactory.build(child, name))),
    );
  }

  /**
   * When a module needs to get all the channels
   * @param name The name of the module
   * @returns list of channels
   */
  getModuleChannelsByName (name: string): Observable<Channel[]> {
    return this.getNavigation().pipe(
      map((response) => {
        const modules = response.children.filter(c => c.type === NavigationObjectType.MODULE);
        const module = modules.find(m => m.name === name);
        return module ? module.children : [];
      }),
      map(subscriptions => subscriptions.map((subscription) => {
        return subscription.children
          .filter((child) => child.type === NavigationObjectType.CHANNEL)
          .map(channel => ({...channel, subscriptionId: subscription.sfId}))
      })),
      map(arrays => [].concat(...arrays)),
      map(channels => {
        return channels.map((channel: any) => NavigationElementFactory.buildChannel(channel))
      }),
    )
  }

  /**
   * Gets all module channels when the module has no subscriptions
   * @param name The name of the module
   * @returns list of channels
   */
  getNonSubscriptionModuleChannels (name: string): Observable<Channel[]> {
    return this.getNavigation().pipe(
      map((response) => {
        const modules = response.children.filter(c => c.type === NavigationObjectType.MODULE);
        const module = modules.find(m => m.name === name);
        return module ? module.children.filter((child) => child.type === NavigationObjectType.CHANNEL) : [];
      }),
      map(arrays => [].concat.apply([], arrays)), // Flattening the array of arrays.
      map(channels => {
        return channels.map((channel: any) => NavigationElementFactory.buildChannel(channel))
      }),
    )
  }

  /**
   * Given a subscription id returns the subscription object for that id
   * @param subscriptionId the subscription id
   * @param moduleName the name of the module the subscription is in
   * @returns the subscription object
   * @throws a mapping exception if the id does not belong to a subscription
   */
  getSubscription (subscriptionId: string, moduleName: string): Observable<Subscription> {
    return this.getNavigationElementById(subscriptionId, moduleName).pipe(
      map((subscriptionOb) => NavigationElementFactory.buildSubscription(subscriptionOb, moduleName)),
    );
  }

  getAbstractSubscription (subscriptionId: string, moduleName: string): Observable<Subscription> {
    return this.getNavigationElementById(subscriptionId, moduleName).pipe(
      map((subscriptionOb) => NavigationElementFactory.buildSubscription(subscriptionOb, moduleName)),
      map((subscription) => ({
          ...subscription,
          icon: this.getSubscriptionIcon(subscription.name),
          getContentNavigatorTabs: subscription.getContentNavigatorTabs,
          getLandingPageTabs: subscription.getLandingPageTabs,
        }),
      ),
    );
  }

  /**
   * Given a subscription id returns the subscription object for that id
   * WHEN POSSIBLE USE THE getChannel METHOD INSTEAD !!!!
   * @param subscriptionId the subscription id
   * @returns the subscription object
   * @throws a mapping exception if the id does not belong to a subscription
   */
  getSubscriptionById (subscriptionId: string): Observable<Subscription> {
    return this.getNavigationElementByIdOnly(subscriptionId).pipe(
      map(({result, module}) => NavigationElementFactory.buildSubscription(result, module.name)),
    );
  }

  /**
   * Given a channel id returns the channel object for that id
   * @param channelId the channel id
   * @param moduleName the name of the module the channel is in
   * @returns a channel object
   * @throws a mapping exception if the id does not belong to a channel
   */
  getChannel (channelId: string, moduleName: string): Observable<Channel> {
    return this.getNavigationElementById(channelId, moduleName).pipe(
      map((channelObj) => NavigationElementFactory.buildChannel(channelObj)),
    );
  }

  getDashboard (dashboardId: string, moduleName: string): Observable<Dashboard> {
    return this.getNavigationElementById(dashboardId, moduleName).pipe(
      map((dashboardObj) => NavigationElementFactory.buildDashboard(dashboardObj)),
    );
  }

  /**
   * Given a channel id returns the channel object for that id
   * WHEN POSSIBLE USE THE getChannel METHOD INSTEAD !!!
   * @param channelId the channel id
   * @returns a channel object
   * @throws a mapping exception if the id does not belong to a channel
   */
  getChannelById (channelId: string): Observable<Channel> {
    return this.getNavigationElementByIdOnly(channelId).pipe(
      map(({result}) => NavigationElementFactory.buildChannel(result)),
    );
  }

  getAllChannels (): Observable<Channel[]> {
    return this.getNavigation().pipe(
      map((response) => {
        const modules = response.children.filter(c => c.type === NavigationObjectType.MODULE);
        return modules.map(module => {
          return module.children
        })
      }),
      map(arrays => [].concat.apply([], arrays)),
      map(modules => modules.map((module: any) => {
        if (module.type === NavigationObjectType.SUBSCRIPTION) {
          return module.children.map((channel: any) => ({...channel, subscriptionId: module.sfId}))
        }
        return module
      })),
      map(arrays => [].concat.apply([], arrays)),
      map(channels => {
        return channels
          .filter((child: any) => child.type === NavigationObjectType.CHANNEL)
          .map((channel: any) => NavigationElementFactory.buildChannel(channel))
      }),
    )
  }

  /**
   * Given a report module id returns the report module object for that id
   * @param reportModuleId the report module id
   * @param moduleName the name of the module the report is in
   * @returns a channel object
   * @throws a mapping exception if the id does not belong to a channel
   */
  getReportModule (reportModuleId: string, moduleName: string): Observable<ReportModule> {
    return this.getNavigationElementById(reportModuleId, moduleName).pipe(
      map((reportModuleObj) => NavigationElementFactory.buildReportModule(reportModuleObj)),
    );
  }

  getModuleByName (moduleName: string): Observable<SidebarItem> {
    return this.getNavigation().pipe(
      map((response) => {
        return response.children.filter(c => c.type === NavigationObjectType.MODULE);
      }),
      map(modules => NavigationElementFactory.buildSidebarItem(modules.find((mod) => mod.name === moduleName))),
    )
  }

  /**
   * Given a navigation element id, returns its navigation tree
   * @param id the navigation element id
   * @returns the navigation tree from the root to the element that matches the given id
   */
  getNavigationTree (id: string): Observable<NavigationElement[]> {
    return forkJoin([this.getNavigation(), this.getModulesConfig()]).pipe(
      map((navigation) => {
        // get the route config for modules and add it to the module in the navigation tree
        const path = this.getPath(id, navigation[0]);
        const moduleIndex = path.findIndex((m: any) => m.type === NavigationObjectType.MODULE);
        const moduleInfo = navigation[1].find((m: any) => m.name === path[moduleIndex].name);
        path[moduleIndex].route = moduleInfo.route;
        return path;
      }),
      map((navigation) => navigation.map((nav: any) => NavigationElementFactory.build(nav))),
    );
  }

  getAllSubscriptions (): Observable<Subscription[]> {
    return this.getNavigation().pipe(
      map((response) => {
        const modules = response.children.filter(c => c.type === NavigationObjectType.MODULE);
        return modules.map(module => {
          return module.children
        })
      }),
      map(arrays => [].concat.apply([], arrays)),
      map(modules => modules.map((module: any) => {
        if (module.type === NavigationObjectType.SUBSCRIPTION) {
          return module
        }
      })),
      map(arrays => [].concat.apply([], arrays)),
      map(subscriptions => {
        return subscriptions.filter((sub: any) => sub !== undefined)
          .map((sub: any) => NavigationElementFactory.buildSubscription(sub))
      }),
    )
  }

  /**
   * Gets navagation module ny channel id
   * @id - channel id
   * @returns - module of the channel
   * */
  getNavigationModuleById (id: string): Observable<NavigationTreeNode> {
    return this.getNavigation().pipe(map((navigation: NavigationTreeNode) => {
      if (navigation?.children) {
        for (const module of navigation.children) {
          if (this.findNavigationElement(id, module)) {
            return module;
          }
        }
      }

      return null;
    }));
  }

  /**
   * Gets Subscription Database Tools
   * @param moduleName The name of the module
   * @param subscrptionId id for a given subscription
   * @returns list of Database for that subscription
   */
  getSubscriptionDatabaseTools (moduleName: string, subscriptionId: string): Observable<DatabaseTools[]> {
    return combineLatest([
      this.getNavigation(),
      this._featureFlagService.getFlag(environment.enableAutomotiveIcDesignWins),
    ]).pipe(
      map(([{children}, isAutomotiveIcDesignWinsEnabled]) => {
        const module = children.find(c => c.type === NavigationObjectType.MODULE && c.name === moduleName);
        const subscription = module?.children.find(({sfId}) => sfId === subscriptionId);
        const databaseTools = subscription?.children.filter(c => c.type === NavigationObjectType.DATABASE_TOOLS) ?? [];
        return databaseTools.map(database => NavigationElementFactory.buildDatabaseTool(database))
          .filter(({database_tool_id}) => (
            database_tool_id === DatabaseToolsIds.AUTOMOTIVE_IC_DESIGN_WINS ? isAutomotiveIcDesignWinsEnabled : true
          ))
      }),
    );
  }

  /**
   * Gets BOM Database Tools
   * @param moduleName The name of the module
   * @returns list of Database
   */
  getModuleDatabaseTools (moduleName: string): Observable<DatabaseTools[]> {
    return combineLatest([
      this.getNavigation(),
      this._featureFlagService.getFlag(environment.enableAutomotiveIcDesignWins),
      this._featureFlagService.getFlag(environment.enableCarbonFootprint),
    ]).pipe(
      map(([{children}, isAutomotiveIcDesignWinsEnabled, isProductSearchCFEnabled]) => {
        const module = children.find(c => c.type === NavigationObjectType.MODULE && c.name === moduleName);
        const databaseTools = module.children.filter(c => c.type === NavigationObjectType.DATABASE_TOOLS) ?? [];
        return databaseTools.map(database => NavigationElementFactory.buildDatabaseTool(database))
          .filter(({database_tool_id}) => (
            database_tool_id === DatabaseToolsIds.AUTOMOTIVE_IC_DESIGN_WINS ? isAutomotiveIcDesignWinsEnabled : true
          ))
          .filter(({database_tool_id}) => (
            database_tool_id === DatabaseToolsIds.PRODUCT_SEARCH_CARBON_FOOTPRINT ? isProductSearchCFEnabled : true
          ))
          .filter(({database_tool_id}) => (
            database_tool_id === DatabaseToolsIds.PRODUCT_SEARCH_SUBSYSTEM_CARBON_FOOTPRINT
              ? isProductSearchCFEnabled : true
          ));
      }),
    );
  }

  isRequestAccessForRoadmapRequired (moduleName: string): Observable<boolean> {
    return this.getModuleCardsByName(moduleName).pipe(
      map((suscriptions) => {
        let channels: Array<Channel> = [];
        suscriptions.forEach(({children}) => {
          channels = [...channels, ...(children as Array<Channel>)];
        });
        const isAtLeastOneChannelEntitled = channels.some(({entitled}) => entitled);
        return !isAtLeastOneChannelEntitled;
      }),
    );
  }

  /**
   * Returns a navigation element by id
   * @param id of the desired element
   * @param moduleName the name of the module the element is in
   * @returns observable which on completion returns the navigation item, or null if not exists
   */
  getNavigationElementById (id: string, moduleName: string): Observable<NavigationTreeNode> {
    return this.getNavigation().pipe(map((navigation: NavigationTreeNode) => {
      let moduleNode = navigation;
      if (moduleName && navigation && navigation.children) {
        const modules = navigation.children.filter(c => c.type === NavigationObjectType.MODULE);
        const module = modules.find(m => m.name === moduleName);
        if (module) {
          moduleNode = module;
        }
      }
      return this.findNavigationElement(id, moduleNode)
    }));
  }

  /**
   * Gets the navigation from the BE NavigationService
   * @returns Tree of navigation elements
   */
  private getNavigation (): Observable<NavigationTreeNode> {
    return this.navigation$;
  }

  /**
   * Finds an element in the navigation
   * @param id the id of the element to be found
   * @param rootElement the navigation level where to start the serach
   * @returns the element with the given id, or null if not found
   */
  private findNavigationElement (id: string, rootElement: NavigationTreeNode): NavigationTreeNode {
    if (rootElement.id === id || rootElement.sfId === id || rootElement.dashboardId === id
      || rootElement.database_tool_id === id) {
      return rootElement;
    }
    let result = null;
    for (const child of rootElement.children ?? []) {
      result = this.findNavigationElement(id, child);
      if (result !== null) {
        break;
      }
    }
    return result;
  }

  /**
   * Gets navigation element by ID Only, this method searches every module
   * @param id of the desired element
   * @returns observable which on completion returns the navigation item, or null if not exists
   */
  private getNavigationElementByIdOnly (id: string)
    : Observable<{ result: NavigationTreeNode, module: NavigationTreeNode }> {
    return this.getNavigation().pipe(map((navigation: NavigationTreeNode) => {
      if (navigation?.children) {
        const modules = [].concat.apply([], navigation.children.map((root: NavigationTreeNode) => root.children));
        for (const module of modules) {
          const result = this.findNavigationElement(id, module);
          if (result) {
            return {result, module};
          }
        }
      }
      return null;
    }))
  }

  /**
   * Returns the items from the navigation that should be placed within the sidebar
   * @returns sidebar modules
   */
  private getSidebarModules (): Observable<NavigationTreeNode[]> {
    return this.getNavigation().pipe(
      map((response) => response.children),
      map((children) => children.filter((child: any) => child.container === NavigationHint.SIDEBAR)),
    );
  }

  /**
   * Returns the tree from an element id and the root from which we want to start
   * @param id the id of the element we want to reach
   * @param rootElement the id of the root element
   * @returns an array containing a path from the specified root to the element with the id provided
   */
  private getPath (id: string, rootElement: NavigationTreeNode): any {
    // should be only id
    if (rootElement.id === id || rootElement.sfId === id) {
      return [rootElement];
    } else if (rootElement.children) {
      for (const child of rootElement.children) {
        const element = this.getPath(id, child);
        if (element !== null) {
          element.unshift(rootElement);
          return element;
        }
      }
    }
    return null;
  }

  /**
   * FE module configuration, like routes, css classes, etc
   * @returns observable containing the modules config array
   */
  private getModulesConfig (): Observable<any> {
    return this.http.get(this.MODULES_ENDPOINT);
  }

  /**
   * Removes duplicates and merges response trees from multiple useCases
   * @param response the navigation service response
   * @returns a single node which contains every entry for the useCases the user has
   */
  private mergeResponseTreesAndRemoveDuplicates (response: any) {
    return response.reduce((accumulator: any, current: any) => {
      if (!accumulator.children) {
        return {...current};
      } else {
        accumulator.useCases = [...new Set([...accumulator.useCases, ...current.useCases])];
        // Remove repeated entries
        const itemsToAdd = [...current.children];
        for (const child of accumulator.children) {
          const repeatedItem = itemsToAdd.findIndex((x) => x.name === child.name);
          if (repeatedItem > 0) {
            itemsToAdd.splice(repeatedItem, 1);
          }
        }
        accumulator.children = accumulator.children.concat(itemsToAdd);

        return accumulator;
      }
    }, {});
  }

  private getSubscriptionIcon (subscriptionName: string): string {
    const icons = {
      'Battery': 'icon-battery',
      'Automotive': 'icon-automotive',
      'Component Price': 'icon-cpl',

      'Logic': 'icon-logic',
      'Image Sensor': 'icon-image_sensor',
      'IoT': 'icon-iot',

      'Memory - Embedded & Emerging': 'icon-memory2',
      'NAND & DRAM': 'icon-memory2',
      'Mobile RF': 'icon-mobileRF',

      'Power Semiconductor': 'icon-power',
      'Teardown': 'icon-teardown',
      'Packaging': 'icon-packaging',

      'Custom Library': 'icon-custom-library',
      'Costing': 'icon-costing',
      'Special Content': 'icon-special-content',

      'Sustainability': 'icon-sustainability',
    };

    return icons[subscriptionName] ?? 'icon-default';
  }

  private sortMenuItems (itemA: SidebarItem, itemB: SidebarItem) {
    const idA = Number(itemA.id);
    const idB = Number(itemB.id);
    if (idA > idB || isNaN(idA)) {
      return 1;
    }
    if (idA < idB || isNaN(idB)) {
      return -1;
    }
    if (idA === idB) {
      return 0;
    }
  }

  private buildMegamenu(response:  { menuItems: Partial<MegaMenu>[] }): MegaMenu[] {
    const menuItems = response.menuItems.map((menuItem) => {
      return { ...menuItem, active: false };
    });
    return menuItems as MegaMenu[];
  }
}
