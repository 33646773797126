import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RelatedContent } from '@app/@shared/models/reverse-engineering/analysis.models';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';

/**
 * Use the showFilters input as false if the filter sidebar is not required
 */
@Component({
  selector: 'app-related-content',
  templateUrl: './related-content.component.html',
  styleUrls: ['./related-content.component.scss'],
})
export class RelatedContentComponent {
  @Input() relatedContentList: RelatedContent[];
  @Input() sidebarOpen = false;

  constructor(
    private router: Router,
    public entitlementService: EntitlementService,
  ) {}

  public onClickRelatedContent(relatedContent: RelatedContent): void {
    let commands: string[] = [];

    if(relatedContent.type === 'REPORT') {
      commands = ['search', 'analysis-view', relatedContent.reportCode]
    } else if(relatedContent.entitled) {
      commands = ['search', `${relatedContent.type.toLowerCase()}-viewer`, relatedContent.id]
    } else {
      const titleRequest = `${relatedContent.type.toLowerCase()}: ${relatedContent.name}`;
      this.entitlementService.openRequestAccessConfirmDialog(titleRequest);
    }

    if(commands.length > 0) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(commands)
      );
      window.open(url, '_blank', 'noopener=true');
    }
  }
}
