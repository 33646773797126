import { UserType } from '@app/auth';
import { IUserProfile } from '@app/store/Models/settings/settingsModel';
import {
  createReducer,
  on
} from '@ngrx/store';
import { stat } from 'fs';
import {
  closeHelpAndResourcesDrawer,
  closeProfileDrawer,
  setContainerMorePosition,
  setMoreContainerIconByRoute,
  setMoreContainerShownState,
  setSeenMenuItem,
  setVisibleAmount,
  toggleHelpAndResourcesDrawer,
  openMenu,
  closeMenu,
  toggleMoreContainer,
  toggleProfileDrawer,
  loadWorkspace,
  loadWorkspaceSuccess,
  loadWorkspaceFailure,
  setUserProfile,
} from '../../Actions/workspace/workspace.actions';
import { MenuModel } from '../../Models/workspace/workspaceModel';
/* eslint-disable @typescript-eslint/no-shadow */

export const DEFAULT_MORE_ICON = 'icon-more';

export interface WorkspaceState {
  userProfile: IUserProfile;
  menuItems: MenuModel[];
  isLoading: boolean;
  isCollapsed: boolean;
  visibleButtonsAmount: number;
  showProfileDrawer: boolean;
  showHelpAndResourcesDrawer: boolean;
  showMoreMenuOptions: {
    shown: boolean,
    containerPosition: {
      posX: number,
      posY: number
    },
    buttonClass: string,
  },
  workspaceLoaded: boolean
}

export const initialWorkspaceState: WorkspaceState = {
  userProfile: {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    jobTitle: null,
    type: UserType.IP,
    department: null,
    titleLevel: null,
  },
  menuItems: [],
  isLoading: false,
  visibleButtonsAmount: 0,
  isCollapsed: true,
  showProfileDrawer: false,
  showHelpAndResourcesDrawer: false,
  showMoreMenuOptions: {
    shown: false,
    containerPosition: {
      posX: 0,
      posY: 0
    },
    buttonClass: DEFAULT_MORE_ICON
  },
  workspaceLoaded: false
};

export const workspaceReducer = createReducer(
  initialWorkspaceState,
  on(setSeenMenuItem, (state, action) => {

    return {
      ...state,
      menuItems: state.menuItems.map(item => ({ ...item })) // 2
        .map(item => { // 3
          if (item.id === action.id) {
            return {
              ...item,
              hasNewInfo: false
            };
          } else {
            return item;
          }
        })
    };
  }),
  on(setMoreContainerIconByRoute, (state, action) => {
    const menuButtonIdx = state.menuItems.findIndex(v => v.route === action.route);
    return {
      ...state,
      showMoreMenuOptions: {
        ...state.showMoreMenuOptions,
        buttonClass: menuButtonIdx >= state.visibleButtonsAmount ?
          state.menuItems[menuButtonIdx].icon_class + '_filled active'
          : DEFAULT_MORE_ICON
      }
    };
  }),
  on(toggleMoreContainer, state => {
    return {
      ...state,
      showMoreMenuOptions: {
        ...state.showMoreMenuOptions,
        shown: !state.showMoreMenuOptions.shown
      }
    };
  }),
  on(openMenu, (state, action) => {
    return {
      ...state,
      isCollapsed: false
    };
  }),
  on(closeMenu, (state, action) => {
    return {
      ...state,
      isCollapsed: true
    };
  }),
  on(toggleProfileDrawer, (state, action) => {
    return {
      ...state,
      showProfileDrawer: !state.showProfileDrawer
    };
  }),
  on(closeProfileDrawer, (state, action) => {
    return {
      ...state,
      showProfileDrawer: false
    };
  }),
  on(toggleHelpAndResourcesDrawer, (state, action) => {
    return {
      ...state,
      showHelpAndResourcesDrawer: !state.showHelpAndResourcesDrawer
    };
  }),
  on(closeHelpAndResourcesDrawer, (state, action) => {
    return {
      ...state,
      showHelpAndResourcesDrawer: false
    };
  }),
  on(setContainerMorePosition, (state, action) => {
    return {
      ...state,
      showMoreMenuOptions: {
        ...state.showMoreMenuOptions,
        containerPosition: action.position
      }
    };
  }),
  on(setMoreContainerShownState, (state, action) => {
    return {
      ...state,
      showMoreMenuOptions: {
        ...state.showMoreMenuOptions,
        shown: action.shown
      }
    };
  }),
  on(setVisibleAmount, (state, action) => {
    return {
      ...state,
      visibleButtonsAmount: action.amount
    };
  }),
  on(loadWorkspace, (state, action) => {
    return {
      ...state,
      workspaceLoaded: false
    }
  }),
  on(loadWorkspaceSuccess, (state, action) => {
    return {
      ...state,
      workspaceLoaded: true,
      userProfile: action.userProfile,
      menuItems: action.menu
    }
  }),
  on(loadWorkspaceFailure, (state, action) => {
    return {
      ...state,
      workspaceLoaded: false
    }
  }),
  on(setUserProfile, (state, action) => {
    return {
      ...state,
      userProfile: action.userProfile
    }
  }),
);
