import { BrowserModule, REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '@env/environment';
import { CoreModule } from '@core';
import { AuthModule } from '@app/auth';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CustomToastComponent } from '@shared/components/custom-toast/custom-toast.component';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '@shared/shared.module';
import { WorkspaceEffects } from '@app/store/Effects/workspace/workspace.effects';
import { NotificationEffects } from '@app/store/Effects/notification/notification.effects';
import { metaReducers, sharedReducers } from '@app/store';
import { QuicklinkModule } from 'ngx-quicklink';
import { SearchEffects } from '@app/store/Effects/search/search.effects';
import { SettingsEffects } from 'src/app/store/Effects/settings/settings.effects';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { PreviousPageService } from '@app/@shared/services/previous-page.service';
import { ApplicationHealthEffects } from '@app/store/Effects/application-health/application-health.effects';
import { NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { windowProvider, WindowToken } from '@shared/utils/window';
import { provideLottieOptions } from 'ngx-lottie';

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [
        AppComponent,
    ], imports: [BrowserModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('./ngsw-worker.js', { enabled: true }),
        CoreModule,
        SharedModule,
        ShellModule,
        AuthModule,
        ToastrModule.forRoot({
            toastComponent: CustomToastComponent,
            timeOut: environment.toastConfig.timeout,
            extendedTimeOut: environment.toastConfig.extendedTimeout,
            positionClass: 'toast-bottom-left',
            preventDuplicates: false,
        }),
        QuicklinkModule,
        AppRoutingModule,
        StoreModule.forRoot(sharedReducers, {
            metaReducers,
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
        StoreRouterConnectingModule.forRoot(),
        /* must be imported as the last module as it contains the fallback route */
        EffectsModule.forRoot([
            WorkspaceEffects,
            NotificationEffects,
            SearchEffects,
            SettingsEffects,
            ApplicationHealthEffects,
        ]),
        NgxUiLoaderHttpModule.forRoot({ showForeground: false }),
    ],
    providers: [
        {
            provide: Window,
            useValue: window,
        },
        FeatureFlagService,
        PreviousPageService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            deps: [PreviousPageService],
            multi: true,
        },
        {
            provide: WindowToken,
            useFactory: windowProvider,
        },
        {
            provide: REMOVE_STYLES_ON_COMPONENT_DESTROY,
            useValue: false,
        },
        provideLottieOptions({
            player: () => import('lottie-web'),
        }),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}

/**
 * App module initializer. You can add here whatever needs to be executed at app start
 * @param previousPageService the service to be initialized
 */
export function initializer(previousPageService: PreviousPageService) {
  return () => previousPageService.init();
}
