import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output
} from '@angular/core';
import {AnimationOptions, BMCompleteEvent} from 'ngx-lottie';
import {MenuModel} from '@app/store/Models/workspace/workspaceModel';
import {animate, style, transition, trigger} from '@angular/animations';
import {Store} from '@ngrx/store';
import {setSeenMenuItem} from '@app/store/Actions/workspace/workspace.actions';
import {AnimationItem} from 'lottie-web';

/**
 * Component for the nav button, ready to use Lottie animations
 */
@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ width: 0, opacity: 0 }),
        animate('.15s ease-out', style({ width: 240, opacity: 1 }))
      ]),
      transition(':leave', [
        style({ width: 240, opacity: 1 }),
        animate('.2s ease', style({ width: 0, opacity: 0 }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavButtonComponent implements OnDestroy {
  @Input()
  external = false;
  @Input()
  isCollapsed = true;
  @Output()
  public activeClicked = new EventEmitter();
  showNotifications = false;
  options: AnimationOptions;
  active = false;

  animationPlaying = false;

  private animation: AnimationItem;

  private _menuItem: MenuModel;

  constructor(private _cdRef: ChangeDetectorRef,
              private ngZone: NgZone,
              private readonly store: Store) {
  }

  get menuItem(): MenuModel {
    return this._menuItem;
  }

  @Input()
  set menuItem(value: MenuModel) {
    this._menuItem = value;
    this._cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    // Needed for until destroyed
  }

  /**
   * If module referring to the button had new content, this clears it.
   * Routing is handled by the template. Stops the propagation to the
   * nav-bar to avoid expand or collapse it
   * @param $event  event to stop propagation to the nav bar
   */
  onClick($event: any) {
    this.store.dispatch(setSeenMenuItem({ id: this.menuItem.id }));
  }

  /**
   * Returns if the button is active
   */
  getIsActive() {
    return this.active &&
      (this.menuItem?.class === 'notifications');
  }

  /**
   * Sets the animation for the button
   * @param $event  animation item
   */
  logoAnimationCreated($event: AnimationItem) {
    this.animation = $event;
  }

  /**
   * Notifies the animation completion and triggers a change detection
   * @param $event event for the animation completion
   */
  completedAnimation($event: BMCompleteEvent) {
    this.animationPlaying = false;
    this._cdRef.detectChanges();
  }
}


