export const RegularExpressions = {
    // eslint-disable-next-line max-len
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phone: /^[\+\(\) +\d+-]+$/,
    splitChips: /[,;:¤|" "\t\n]/,
    number: /^\d+$/,
    crossLinkingUrl: /\/(cross\-linking)\/(SA\-|RE\-)(ASSET\-LINK)/,
    extraSpaces: /\s+/g,
    startsWithYear: /^\d{4}-\d{2}-\d{2}/,
    startsWithPinned: /^Pinned\d+ - /,
};
