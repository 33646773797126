import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PdfAssetInfo } from '@app/@shared/models/pdf-asset-info';
import { PreviousPageService } from '@app/@shared/services/previous-page.service';

@Component({
  selector: 'app-market-pdf-viewer',
  templateUrl: './market-pdf-viewer.component.html',
  styleUrls: [ './market-pdf-viewer.component.scss' ]
})
export class MarketPdfViewerComponent {

  @Input() pdfAssetInfo: PdfAssetInfo;
  @Input() filename = '';
  @Input() subscriptionName = '';
  @Input() title = '';
  @Input() showDownloadButton: boolean;
  @Input() wordpressUrl = '';
  @Output() showDownloadButtonChange = new EventEmitter();
  @Output() downloadPdf = new EventEmitter();

  constructor(
    public prevPage: PreviousPageService,
  ) {}

  public onClickBack() {
    this.prevPage.navigateBack();
  }

  onDownloadPdf(): void {
    this.downloadPdf.emit();
  }
}
