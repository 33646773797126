<app-document-metadata
  [sidebarOpen]="sidebarOpen"
  [currentAnalysis]="currentAnalysis"
  [authors]="authors"
></app-document-metadata>
<div *ngIf="showInProgressMessage; else showContent" class="not-content">
  <section class="area-container content in-progress-view">
      <app-progress-tracker-inform-content
        [title]="contentTitle"
        [descripLine1]="contentDescriptionTitle1"
        [descripLine2]="contentDescriptionTitle2">
      </app-progress-tracker-inform-content>
  </section>
</div>

<ng-template #showContent>
  <div class="content-container">
    <app-document-files
      [subscriptionName]="currentAnalysis?.subscriptionName"
      [channelNames]="channelNames"
      [currentAnalysis]="currentAnalysis"
      [analysisReports]="analysisReports"
      [annotatedDocuments]="annotatedDocuments"
      [paginationParameters]="paginationParameters"
      [isLoading]="isLoading"
      [documentAssetviewer]="documentAssetviewer"
      [analysisCode]="analysisCode"
      [selectedReportId]="selectedReportId"
      [selectedAssetId]="selectedAssetId"
      [channelId]="channelId"
      [subscriptionId]="subscriptionId"
      [relatedContentList] = "relatedContentList"
      [sidebarOpen] = "sidebarOpen"
      (changePaginationParameters)="onChangePaginationParameters($event)"
      (clickDocument)="onClickDocument($event)"
      (downloadAssetGroup)="onDownloadAssetGroup($event)"
      (documentNewTab)="onDocumentNewTab($event)"
      (documentShare)="onDocumentShare($event)"
      (clickPVO)="onClickPVO()"
      (clickRequest)="onClickRequest()"
      (closeDocument)="onClosePdfViewer()"
      (closeAssetViewer)="onCloseAssetViewer()"
    ></app-document-files>
  </div>
</ng-template>
