import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, concatMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Report, HTMLReport, ReportResponse, ContentHeaderBlock, RelatedContent, Section, Image, SectionContent } from '@shared/services/hygraph/hygraph.models';
import { CTIContentType } from '@app/@shared/models/search/cti-search.model';
import { ThumbnailsService } from '../thumbnails/thumbnails.service';

@Injectable({
  providedIn: 'root'
})
export class HygraphService {
  private readonly THUMBNAIL_HEIGHT = 800;
  private readonly THUMBANAIL_WIDTH = 1200;

  constructor(
    private http: HttpClient,
    private thumbnailService: ThumbnailsService
  ) { }

  getReport(id: string): Observable<HTMLReport> {
    const url = `${environment.metaServiceBaseUrl}/asset_content/${id}`;

    return this.http.get<ReportResponse>(url).pipe(
      concatMap(response => {
        return this.getSections(response.content.contentBodyBlock?.section || [])
          .pipe(map(
            sections => ({
              publishDate: new Date(response.content.publishDate),
              header: this.getHeader(response.content),
              sections: sections,
              name: response.name,
              relatedContent: this.getRelatedContent(response.content.relatedContent),
              includeRelatedContent: response.content.includeRelatedContent,
              includeTableOfContent: response.content.includeTableOfContent,
            })
          ))
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  private getRelatedContent(relatedContent: RelatedContent[]): RelatedContent[] {
    const response = relatedContent.map(data => {
      const contentTypeLowerCase = data.contentType.toLowerCase();
      const contentType = contentTypeLowerCase === 'post' ? 'blog' : contentTypeLowerCase;
      return {
        ...data,
        publishDate: contentType === CTIContentType.BLOG ? data.blogPublishDate : data.publishDate,
        contentType
      }
    }).filter(related => related.contentType === CTIContentType.BLOG ||
      related.contentType === CTIContentType.REPORT || related.contentType === CTIContentType.VIDEO);

    return response;
  }

  private getHeader(data: Report) {
    const contentHeaderBlock: ContentHeaderBlock = {
      id: data.id,
      title: data.title,
      subTitle: data.subTitle,
      summary: data.summary,
      authors: data.authors,
      customerSegment: data.customerSegment,
      technologyVertical: data.technologyVertical,
      themes: data.themes,
      featuredImage: data.featuredImage,
    }
    return contentHeaderBlock;
  }

  private getSections(sections: Section[]): Observable<Section[]> {
    return forkJoin(sections.map(section => this.mapSectionImages(section)))
  }

  private mapSectionImages(section: Section): Observable<Section> {
    return forkJoin(
      section.sectionContent.map((content: any) => {
        if (!content.techInsightsImages || content.techInsightsImages.length === 0) {
          return of(content);
        }
        return forkJoin(content.techInsightsImages.map(
          (image: any) => this.thumbnailService
            .buildThumbnailUrlForObjectId(image.cl_object_id, this.THUMBNAIL_HEIGHT, this.THUMBANAIL_WIDTH)
            .pipe(map(response => ({
              fileName: image.filename,
              altText: image.altText,
              url: response,
              sourceUrl: `/search/analysis-view/${image.reportCode}?#activeTab=images&imgId=${image.cl_object_id}`

            } as Image))))).pipe(map(techInsightImages => ({
              ...content,
              image: [...content.image, ...techInsightImages]

            } as SectionContent)))
      })
    ).pipe(
      map(content => ({
        ...section,
        sectionContent: content
      }))
    );
  }
}
