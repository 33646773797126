import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DBToolsSubscriptionCard } from '@shared/models/shared.models';

@Component({
  selector: 'app-database-tool-area',
  templateUrl: './database-tool-area.component.html',
  styleUrls: ['./database-tool-area.component.scss']
})
export class DatabaseToolAreaComponent {
  @Input() dataBaseTools: Array<DBToolsSubscriptionCard> = [];
  @Output() databaseToolClicked: EventEmitter<DBToolsSubscriptionCard> = new EventEmitter<DBToolsSubscriptionCard>();

  onDataBaseToolsClick(databaseTool: DBToolsSubscriptionCard) {
    this.databaseToolClicked.emit(databaseTool);
  }
}
