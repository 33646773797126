import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';

import {CredentialsService} from './credentials.service';
import {environment} from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {ISettingUserProfile, IUserProfile} from 'src/app/store/Models/settings/settingsModel';
import {WindowService} from '@shared/services/window-location/window.service';
import {PopupModel} from '@app/store/Models/notification/notificationModel';
import {showOnboardingPopup} from '@app/store/Actions/notification/notification.actions';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import * as CryptoJS from 'crypto-js';
import {Channel} from '@shared/models/navigation/navigation.models';

export interface ChannelEntitlement {
  channels: Channel[],
  flexEntitlements: [],
}

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}
export enum UserType {
  IP = 'IP',
  CTI = 'CTI',
  MRG = 'MRG',
  INTERNAL = 'INTERNAL'
}
/**
 * Profile class containing user data and constructor with interface mapping
 */
export class Profile {
  id: string;
  first_name: string;
  last_name: string;
  salutation: string;
  position: string;
  country: string;
  phone_number: string;
  mobile_phone: string;
  email: string;
  notification_frequency: string;
  notification_medium: string;
  userEnable: boolean;
  primary_use_case?: string;
  state?: string;
  company?:string;
  job_function?:string;
  free_user_subscriber?: boolean;
  isSubscribedUser: boolean;
  department?: string;
  title_level?: string;
  other_department: string;

  private readonly DEFAULT_USER_TYPE = 'ip';
  private readonly MAP_USER_TYPE = {
    ip: UserType.IP,
    cti: UserType.CTI,
    'marketresearchgroup(mrg)': UserType.MRG,
    internal: UserType.INTERNAL,
  };

  constructor(profile?: IUserProfile) {
    this.country = profile ? profile.country: null;
    this.email = profile ? profile.email : null;
    this.first_name = profile ? profile.firstName : null;
    this.id = profile ? profile.id : null;
    this.last_name = profile ? profile.lastName : null;
    this.mobile_phone = null;
    this.notification_frequency = null;
    this.notification_medium = null;
    this.phone_number = profile ? profile.phone : null;
    this.position = profile ? profile.jobTitle : null;
    this.salutation = profile ? profile.salute : null;
    this.state = profile ? profile?.state: null;
    this.userEnable = null;
    this.company =  profile ? profile?.company: null;
    this.job_function =  profile ? profile?.jobFunction: null;
    this.free_user_subscriber = profile ? profile?.freeUserSubscriber : null;
    this.isSubscribedUser = profile?.isSubscribedUser || null;
    this.department = profile?.department || null;
    this.title_level = profile?.titleLevel || null;
    this.other_department = profile?.otherDepartment || null;
  }

  toIUserProfile(): IUserProfile {
    const parsedPrimaryUseCase = this.primary_use_case?.toLowerCase().replace(/\s/g, '') ?? this.DEFAULT_USER_TYPE;
    const selectedUserType = this.MAP_USER_TYPE[parsedPrimaryUseCase];

    return {
      id: this.id,
      firstName: this.first_name,
      lastName: this.last_name,
      email: this.email,
      phone: this.phone_number,
      jobTitle: this.position,
      type: selectedUserType,
      country: this.country,
      state: this.state,
      company: this.company,
      jobFunction: this.job_function,
      salute: this.salutation,
      freeUserSubscriber: this.free_user_subscriber,
      department: this.department,
      titleLevel: this.title_level,
      otherDepartment: this.other_department,
    };
  }

  toSettingUserProfile(): ISettingUserProfile {
    return {
      first_name: this.first_name,
      last_name: this.last_name,
      company: this.company,
      country: this.country,
      state: this.state,
      job_function: this.job_function,
      phone_number: this.phone_number,
      position: this.position,
      salutation: this.salutation,
      department: this.department,
      title_level: this.title_level,
      other_department: this.other_department,
    };
  }
}

export class User {
  id: string;
  username: string;
  enabled: boolean;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  authorities: any[];
  createDateTime: string;
  updateDateTime: string;
  salesForceUserId: string;
  salesForceContactId?: string;
  firstName: string;
  lastName: string;
  email: string;
  lastLogin?: string;
  persona: string;
  personaId: string;
}

/**
 * Provides the authentication workflow
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  tokenBeingExchanged = false;
  baseUrl = environment.authServiceBaseUrl;
  public user$: Observable<User>;

  private userSubject = new BehaviorSubject<User>(null);

  constructor(
    private credentialsService: CredentialsService,
    private externalLocationService: WindowService,
    private readonly store: Store,
    private router: Router,
    private http: HttpClient,
  ) {
    this.user$ = this.userSubject.asObservable();
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<any> {
    // Customize credentials invalidation here
    return this.logoutUrl().pipe(catchError((_error) => {
      this.clearTokenAndReroute();
      return of(null);
    }), tap((v) => {
      this.clearTokenAndReroute(v);
    }));
  }

  /**
   * Provides logout url in case the user has an IDP with a specific logout url
   */
  logoutUrl(): Observable<any> {
    const url = `${this.baseUrl}/users/logout`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.get<any>(url, {headers});
  }

  /**
   * Gets the JWT token from a temp token provided by the IDP
   * @param token   temp token provided by IDP
   */
  exchangeTempToken(token: string) {
    const url = `${this.baseUrl}/saml/exchange/${token}`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.get<any>(url, {headers})
      .pipe(map(oauth => {
        this.credentialsService.storeToken(oauth);
        return true;
      }));
  }

  /**
   * Gets the user data according to the JWT token identity
   */
  me(): Observable<User> {
    const url = `${this.baseUrl}/me`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.get<User>(url, {headers}).pipe(map((user) => {
      this.userSubject.next(user);
      return user;
    }));
  }

  /**
   * Gets the user profile according to the JWT token identity and maps it to the profile interface
   */
  getProfile(): Observable<IUserProfile> {
    const url = `${environment.entitlementServiceUrl}/my/user`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.get<Profile>(url, {headers}).pipe(map(unmappedProfile => {
      const prof = new Profile();
      Object.keys(unmappedProfile).forEach(key => {
        prof[key] = unmappedProfile[key]
      })
      return prof.toIUserProfile();
    }));
  }

  getChannelAuthentication() {
    const url = `${environment.entitlementServiceUrl}/channel/entitlement`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.get<ChannelEntitlement>(url, {headers});
  }

  updateProfile(body: Profile) {
    body.id = this.credentialsService.getUserId();
    const url = `${environment.entitlementServiceUrl}/my/user`;
    const filteredProfileData: any = {};
    //remove any value which is null or undefined

    Object.entries(body.toSettingUserProfile()).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        filteredProfileData[key] = value;
      }
    });

    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.patch<Profile>(url, filteredProfileData, {headers});
  }

  /**
   * Clears the token and reroutes to either the IDP logout URL or the login URL
   * @param logoutInfo   logout info to define which action should the method take
   */
  clearTokenAndReroute(logoutInfo?: any) {
    if(!this.tokenBeingExchanged){
      this.credentialsService.removeToken();
      if (logoutInfo && logoutInfo.logoutRequired && logoutInfo.idpUrl) {
        this.externalLocationService.assignLocation(logoutInfo.idpUrl);
      } else {
        this.router.navigate(['logout'])
      }
    }
  }

  /**
   * Gets the EULA text for the current user
   */
  getEULA(): Observable<any> {
    const url = `${this.baseUrl}/eula/agreement/` + this.credentialsService.eulaId;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.get<any>(url, {headers});
  }

  /**
   * Signs the EULA text
   * @param html  text to be signed
   */
  signEULA(html: string): Observable<boolean> {
    const url = `${this.baseUrl}/eula/signature`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.post<any>(
      url,
      {
        Eula_ID: this.credentialsService.eulaId,
        Eula_Hash: this.buildEulaHash(html)},
      {headers}
    )
      .pipe(tap(() => {
        const oauth = this.credentialsService.getOauth();
        oauth.eula_id = null;
        this.credentialsService.storeToken(oauth);
        return true;
      }));
  }

  /**
   * Checks if EULA was previously signed
   */
  checkEULASignature(): Observable<void> {
    if (this.credentialsService.needsEULASignature()) {
      return this.getEULA().pipe(
        tap((eula) => {
          const popup: Partial<PopupModel> = {
            body: eula.HTML
          };
          this.store.dispatch(
            showOnboardingPopup({
              popup,
            })
          );
        })
      );
    } else {
      return of(null);
    }
  }

  /**
   * Turns the eula html into a hash
   * @param html incoming html
   * @returns hashed version of it
   */
  private buildEulaHash(html: string): string{
    const oauth = this.credentialsService.getOauth();
    return CryptoJS.SHA512(html + oauth.user_id).toString(CryptoJS.enc.Base64)
  }

}
