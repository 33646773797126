<form class="{{props?.class}}">
  <div (click)="handleChange(element)" *ngFor="let element of props.items; let i = index;"
       class="form-group">
    <input [disabled]="element.disabled || disabled"
           [name]="props.groupName"
           [value]="element.key"
           [(ngModel)]="currentValue"
           class="{{element?.class}}"
           type="radio"
    />
    <label [for]="element.key"> <span></span> {{element?.label}} </label>
  </div>
</form>

