export enum FavoriteMeatballActions {
  VIEW = 'view',
  EXTERNAL = 'external',
  SHARE = 'share',
  REMOVE = 'remove',
}

export enum FavoriteContentType {
  ANALYSIS = 'analysis',
  IMAGE = 'image'
}

export interface FavoriteCount {
  analysis: number;
}
