import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export const DOWNLOAD_ERROR_MODAL_OPTIONS = {
  size: 'md',
  centered: true,
  windowClass: 'modal-notification',
};

@Component({
  selector: 'app-download-error-modal',
  templateUrl: './download-error-modal.component.html',
  styleUrls: ['./download-error-modal.component.scss'],
})
export class DownloadErrorModalComponent {
  constructor(public modal: NgbActiveModal) {}
}
