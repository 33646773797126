import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import FileSaver from 'file-saver';
import { environment } from '@env/environment';
import { DialogService } from '@shared/services/dialog/dialog.service';
import { ReportInfo, ReportInfoResponseDTOS } from '@shared/models/report-info';
import { PdfAssetInfo } from '../models/pdf-asset-info';

@Injectable({
  providedIn: 'root',
})
export class ReportService{
  private baseUrl = environment.reportService;
  private clUrl = environment.contentLakeBaseUrl;
  private readonly CL_HEADERS = new HttpHeaders()
    .set('X-TI-AGENT-ID', environment.entitlementAgentId)
    .set('only-latest-created', 'true')
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  constructor(
    private httpClient: HttpClient,
    private dialogService: DialogService,
  ) {}

    /**
     * Gets a list of reportCodes associated with a matCode
     *
     * @param matCode the matCode to look for
     */
    getReportCodesByMatCode(matCode: string): Observable<Array<string>> {
      const queryParams = new HttpParams().set('matCode', matCode);
      const url = `${this.baseUrl}/reports/by/matCode`;
      const headers = new HttpHeaders().set('Accept', 'application/json');

      return this.httpClient.get<any>(url, { headers, params: queryParams }).pipe(
        map( (response)=> {

          return response.reportInfoResponseDTOS.map( (element:any) => { return element.productCode })
        })
      );
    }

  /**
   * Gets report information
   *
   * @param reportCode the reportCode to look for
   */
  getReportByCode(reportCode: string): Observable<ReportInfo> {
    const url = `${this.baseUrl}/report/info/${reportCode}`;
    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.httpClient.get<ReportInfo>(url, { headers })
  }

  /**
   * for a given matCode it gets list of ReportInfo for this matCode and its children.
   *
   * @param matCode a matcode
   * @returns array of ReportInfo
   */
  getReportsRecursivelyForMatCode(matCode: string): Observable<ReportInfo[]> {
    const queryParams = new HttpParams().set('matCode', matCode)
    const url = `${this.baseUrl}/reports/expanded/matCode`
    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.httpClient.get<ReportInfoResponseDTOS>(url, { headers, params: queryParams }).pipe(
      map( response => response.reportInfoResponseDTOS)
    );
  }

  /**
   * Gets the url for a given PDF report
   * @param objectId The content lake object id for the report
   * @returns url of the PDF to be shown
   */
  getPDFReportURL(objectId: string): Observable<PdfAssetInfo> {
    const requestBody = {
      limit: 1,
      properties: [
        {
          property: 'DERIVED_FROM',
          value: objectId,
        },
      ],
    };

    return forkJoin([
      this.httpClient
        .post<any>(`${this.clUrl}/objects/search/`, requestBody, { headers: this.CL_HEADERS }),
      this.httpClient.get(`${this.clUrl}/objects/${objectId}`, { headers: this.CL_HEADERS })
    ])
      .pipe(
        map(([clObject, pdfObject]: any) => {
          return { url: clObject.objects[0].url, clObjectId: clObject.objects[0].metadata.DERIVED_FROM,
                   downloadUrl: pdfObject.url, filename: pdfObject.metadata.original_filename };
        })
      );
  }

  /**
   * Example of how to call downloadPdf:
   *
   * yourFunctionToDownloadPdf() {
   *    this.downloadingPdf = true; // variable to handle the spinner
   *    this.reportService.getPDFReportURL(this.pdfAssetInfo.clObjectId)
   *      .subscribe(pdfAssetInfo => {
   *        this.reportService.downloadPdf(pdfAssetInfo)
   *        .subscribe(() => this.downloadingPdf = false);
   *      })
   *  }
   */
  downloadPdf(pdfAssetInfo: PdfAssetInfo): Observable<void> {
    return this.httpClient.get(pdfAssetInfo.downloadUrl, { responseType: 'blob' })
      .pipe(
        tap(blob => FileSaver.saveAs(blob, pdfAssetInfo.filename)),
        mapTo(void 0),
        catchError((error) => {
          this.dialogService.openError();
          return throwError(error);
        }),
      )
  }
}
