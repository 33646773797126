import {Component, OnDestroy, OnInit} from '@angular/core';
import {Logger, PwaConfigService} from '@core';
import {environment} from '@env/environment';
import {NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';
import {UntilDestroy} from '@ngneat/until-destroy';
import { PardotTrackerService } from './@shared/services/pardot-tracker/pardot-tracker.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [NgbTooltipConfig],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private tooltipConfig: NgbTooltipConfig,
    private pwaConfigService: PwaConfigService,
    private pardotTrackerService: PardotTrackerService
  ) {
    this.tooltipConfig.openDelay = 300;
    this.tooltipConfig.closeDelay = 50;
  }

  public ngOnInit(): void {
    this.pardotTrackerService.pageViewTrack();
    if (environment.production) {
      Logger.enableProductionMode();
    }

    this.pwaConfigService.runUpdate();
  }

  public ngOnDestroy(): void {
    return;
  }
}
