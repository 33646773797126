import { selectMenuCollapsed } from '../../../store/Selectors/workspace/workspace.selectors';
import {Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {animate, style, transition, trigger} from '@angular/animations';
import {Store} from '@ngrx/store';
import {
  selectMenuItems,
  selectMoreButtonClass,
  selectVisibleButtonsAmount
} from '@app/store/Selectors/workspace/workspace.selectors';
import {MenuModel} from '@app/store/Models/workspace/workspaceModel';
import {
  setContainerMorePosition,
  setVisibleAmount,
  openMenu,
  closeMenu
} from '@app/store/Actions/workspace/workspace.actions';
import {debounceTime, map} from 'rxjs/operators';
import {untilDestroyed} from '@core';
import {AnimationItem} from 'lottie-web';
import {AnimationOptions} from 'ngx-lottie';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { UntilDestroy } from '@ngneat/until-destroy';

export interface IPosition {
  posY: number;
  posX: number;
}

/**
 * Nav bar component. Contains the buttons and the nav-more container
 */
@Component(
  {
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    animations: [
      trigger('inOutAnimation', [
        transition(':enter', [
          style({
            width: 0,
            opacity: 0
          }),
          animate(
            '150ms ease-out', style({
              width: 240,
              opacity: 1
            }))
        ]),
        transition(':leave', [
          style({
            width: 240,
            opacity: 1
          }),
          animate(
            '150ms ease-in', style({
              width: 0,
              opacity: 0
            }))
        ])
      ])
    ]
  }
)
@UntilDestroy()
export class NavComponent implements OnInit, OnDestroy {
  @ViewChild('navMoreButton', { read: ElementRef }) navMoreButton: ElementRef;
  @Input() pinnedMenuByDefault: boolean;
  isCollapsed: Observable<boolean> = this.store.select(selectMenuCollapsed).pipe(untilDestroyed(this));
  enableNotificationBtn = false;
  menuHidden = true;
  expandedButtonActive = false;
  menuSize: Observable<number> = this.store.select(selectVisibleButtonsAmount).pipe(untilDestroyed(this));
  menu: Observable<MenuModel[]> = this.store.select(selectMenuItems).pipe(untilDestroyed(this));
  menuItemsAmount: number;
  moreButton: Observable<MenuModel> = this.store.select(selectMoreButtonClass).pipe(
    map(v => {
      return {
        id: null,
        label: 'More',
        class: 'more',
        icon_class: v,
        route: null,
        animation_type: '',
        animation_asset: '',
        hasNewInfo: true
      };
    }),
    untilDestroyed(this)
  );
  resize$ = new Subject<void>();
  logoOptions: AnimationOptions = {
    path: 'assets/animations/nav-bar/techinsights-logo.json',
    autoplay: false,
    loop: false
  };

  private logoAnimation: AnimationItem;


  constructor(
    private ngZone: NgZone,
    private readonly store: Store,
    private ffService: FeatureFlagService,
  ) {}

  /**
   * Listens for window resize events
   * @param event   resize event
   */
   @HostListener('window:resize', ['$event'])
   onResize(event: any) {
     this.resize$.next();
   }

  ngOnInit() {
    this.menuSize.subscribe(size => this.menuItemsAmount = size);
    // Observe height of the window, to add or remove buttons from the nav-bar and the nav-more-container
    this.resize$.pipe(
      debounceTime(300),
      untilDestroyed(this)
    )
      .subscribe(() => {
        this.store.dispatch(setVisibleAmount({ amount: this.getCalculatedButtonsAmount() }));
        this.onMoreToggle();
      });
    // Sets the default visible amount of buttons
    this.store.dispatch(setVisibleAmount({ amount: this.getCalculatedButtonsAmount() }));

    if(this.pinnedMenuByDefault) {
      this.openMenu();
      this.expandedButtonActive = true;
    }
    // Observe the nav-bar state from the store. Triggers the Techinsights icon animation when expanded/collapsed
    this.isCollapsed.pipe(
      untilDestroyed(this)
    ).subscribe(
      (val) => {
        // toggling logo animation
        if (this.logoAnimation) {
          this.ngZone.runOutsideAngular(() => {
            if (this.menuHidden) {
              this.logoAnimation.playSegments([
                0,
                31
              ], true);
            } else {
              this.logoAnimation.playSegments([
                31,
                0
              ], true);
            }
          });
        }
        this.menuHidden = val;
        this.onMoreToggle();
      }
    );
    this.moreButton.pipe(
      untilDestroyed(this)
    ).subscribe();
  }

  /**
   * Toggles nav-bar state
   */
  toggleMenu() {
    this.expandedButtonActive = !this.expandedButtonActive;
    if(this.expandedButtonActive) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  openMenu() {
    this.store.dispatch(openMenu());
  }

  closeMenu() {
    if(!this.expandedButtonActive) {
      this.store.dispatch(closeMenu());
    }
  }

  /**
   * Draws the nav-more-container with buttons and position relative to the nav-more button
   */
  onMoreToggle() {
    let position: IPosition;
    if (!this.navMoreButton?.nativeElement) {
      return;
    }
    const target = this.navMoreButton.nativeElement.firstChild;
    // eslint-disable-next-line prefer-const
    position = {
      posX: this.menuHidden
        ? 80
        : 240,
      posY: +target.getBoundingClientRect().top - (+target.offsetHeight * 3.5)
    };
    this.store.dispatch(setContainerMorePosition({ position }));
  }

  ngOnDestroy(): void {
    // Needed for untilDestroyed
  }

  /**
   * Sets the animation for the Techinsights logo
   * @param $event  animation item
   */
  logoAnimationCreated($event: AnimationItem) {
    this.logoAnimation = $event;
  }

  /**
   * Gets the calculated buttons amount. Each button has the size of 80px,
   * and the minimum height of the nav-more button is 400px
   */
  getCalculatedButtonsAmount(): number {
    // workaround for this will be removed soon
    const buttonsAmount = 100;
    return buttonsAmount > 0
      ? buttonsAmount
      : 1;
  }

  expandedButtonClass(): any {
    return window.innerHeight < (64 * this.menuItemsAmount) ? 'expanded-button-scrollbar' : 'expanded-button';
  }

}
