// Breadcrumb is used for Reverse Engineering

import { FilterCriteria } from './content-navigator/content-navigator.model';
import { PaginationParameters } from './pagination';
import { Filters } from './reverse-engineering/analysis.models';

//  and BreadcrumbType is used elsewhere.
export interface Breadcrumb {
  type: string;
  name: string;
  route: string;
  visible: boolean;
  fragment?: string;
}

export interface BreadcrumbType {
  name: string;
  route: string;
  fragment?: string;
}

export interface DropdownItem {
  id?: string;
  name: string;
  icon?: string;
  isCustom?: boolean;
}

export interface FeaturedContent{
  id: string;
  name: string;
  code?: string;
  description: string;
  channelId: string;
  channelName: string;
  subscriptionId: string;
  subscriptionName: string;
  analysisEndDate: string;
  publishDate: string;
  contentType: string;
  entitled: boolean;
}

export interface FeaturedDataContainer {
  imageUrl: string;
  title: string;
  linkText: string;
  linkUrl: string;
  position: number;
}

export interface ITextAreaProps {
  name: string,
  class: string,
  rows: number,
  cols: number,
  placeholder?: string,
  label?: string,
  error?: string,
  maxLength?: number,
  minLength?: number,
}

export interface SelectOption<T = any> {
  label: string;
  value: T;
}

export interface SubscriptionCard {
  id: string;
  entitled: boolean;
  icon: string;
  name: string;
  // This is to prevent lock from showing refer to CP-4074/SWDEV-10768
  // should be removed
  bypassEntitlement?: boolean;
  availableChannels: number;
  totalChannels: number;
  isCustom?: boolean;
  module?: string;
}

export interface DBToolsSubscriptionCard extends SubscriptionCard {
  navigationHint?: string;
  subscriptionId?: string;
  entitlementFlagId?: string;
}

export interface ModuleHeaderTab {
  icon: string,
  label: string,
  routerLink: any,
  fragment: string,
}

export interface SearchResultsEvent {
  pagination: PaginationParameters;
  searchTerm: string;
  filterValue?: string;
  filterCriteria?: FilterCriteria;
  filters?: Filters;
  raiseTelemetry?: boolean;
  activeTabGroupId?: string;
}

export interface SearchCategoryResultsEvent extends SearchResultsEvent {
  category: DropdownItem;
}

export interface TabItem {
  icon: string;
  name: string;
  routerLink: string;
  index?: number;
  disabled: boolean;
  count?:number;
}

export interface TableauConfig {
  url: string,
  width: number | string,
  height: number | string,
}

export interface TipContent {
  lineOne: string;
  lineTwo: string;
  lineThree: string;
}

export abstract class ChannelCard {
  public id: string;
  public title: string;
  public entitled: boolean;
  public accessible: boolean;

  protected constructor(id: string, title: string, entitled: boolean, accessible?: boolean) {
    this.id = id;
    this.title = title;
    this.entitled = entitled;
    this.accessible = accessible ?? false;
  }

  public abstract buildSubtitle(): string;

  public abstract getSubtitleSegments(): Array<{label: string}>;
}

export interface Entitlement {
  channelId: string;
  endPeriodDate: string;
  id: string;
  name: string;
  seminarContentAccess: boolean;
  showChannel: boolean;
  startPeriodDate: string;
}

export class AnalyticsMenu {
  siteName: string;
  channels: string[];
}
