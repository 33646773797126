import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { combineLatest, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { NavigationService } from '../navigation/navigation.service';
import {
  NavigationElement,
  Subscription,
  Channel,
  NavigationModules,
  NavigationObjectType,
  Dashboard,
} from '@app/@shared/models/navigation/navigation.models';
import {
  ContentNavigatorTable,
  ContentNavigatorTablePage,
  ContentNavigatorTableRow,
  FlavourCounts,
  ReportFlavor,
} from '@shared/models/content-navigator/content-navigator.model';
import { SubscriptionCard, TableauConfig } from '@app/@shared/models/shared.models';
import { AnalysisFilter, AnalysisParametersEvent, Filters } from '@shared/models/reverse-engineering/analysis.models';
import { FilterHelper } from '@shared/utils/filter-helper';
import { ReverseEngineeringAnalyticsChannelCard, ReverseEngineeringChannelCard } from '@app/reverse-engineering/models';
import {
  REAnalyticsChildrenIds,
  REAnalyticsIds,
  RE_ANALYTICS_CHANNEL,
  RE_ANALYTICS_CHILDREN_CONFIG_MAP,
  RE_ANALYTICS_CHILDREN_MAP,
} from '@app/reverse-engineering/config/reverse-engineering.mock';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { EntitlementService } from '../entitlement/entitlement.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { SearchService } from '../search/search.service';
import { FeatureFlagService } from '../featureflag.service';
import { CTISearchResult } from '@app/@shared/models/search/cti-search.model';
import { BlogExcerptPaginatedList } from '@app/@shared/models/market-analysis/market-analysis';

@Injectable({
  providedIn: 'root',
})
export class ReverseEngineeringService {
  private metaUrl = environment.metaServiceBaseUrl;
  private readonly SIMPLE_FILTERS_FIELD_NAME = ['entitlementState'];
  private readonly FILTERS_SHOW_IN_SIDEBAR = [
    'subscriptionName',
    'channel',
    'type',
    'deviceManufacturer',
    'deviceType',
    'analysisYear',
  ];

  constructor(
    private navigationService: NavigationService,
    private entitlementService: EntitlementService,
    private searchService: SearchService,
    private httpClient: HttpClient,
    private featureFlagService: FeatureFlagService
  ) {}

  getReverseEngineeringConfig(paramChannelId: string, ticket: string, site: string): TableauConfig {
    const channelId: REAnalyticsChildrenIds = paramChannelId as REAnalyticsChildrenIds;
    const config: TableauConfig = RE_ANALYTICS_CHILDREN_CONFIG_MAP.get(channelId);
    const finalUrl = config?.url ? config?.url.replace('<ticket>', ticket).replace('<site>', site) : '';
    return {
      url: finalUrl,
      height: config?.height,
      width: config?.width,
    };
  }

  getSubscription(subscriptionId: string): Observable<Subscription> {
    return this.navigationService.getSubscription(subscriptionId, NavigationModules.REVERSE_ENGINEERING).pipe(
      map((subscription) => ({
        ...subscription,
        getContentNavigatorTabs: subscription.getContentNavigatorTabs,
        getLandingPageTabs: subscription.getLandingPageTabs,
        icon: this.getSubscriptionIcon(subscription.name),
        isCustom: subscription.isCustom,
      }))
    );
  }

  getTabCounts(ids: string): Observable<FlavourCounts> {
    return this.searchService.getTabCounts(ids);
  }

  getChannels(): Observable<Array<Channel>> {
    return this.navigationService.getModuleChannelsByName(NavigationModules.REVERSE_ENGINEERING);
  }

  getSubscriptionExcerpts(): Observable<Array<SubscriptionCard>> {
    return this.navigationService.getModuleCardsByName(NavigationModules.REVERSE_ENGINEERING).pipe(
      map((response) => {
        const subscriptions = response as Subscription[];
        return subscriptions.map((subscription) => ({
          id: subscription.id,
          entitled: true,
          icon: this.getSubscriptionIcon(subscription.name),
          name: subscription.name,
          isCustom: subscription.isCustom,
          availableChannels: subscription.availableChannels,
          totalChannels: subscription.totalChannels,
        }));
      })
    );
  }

  getAllAnalysis(
    searchTerm: string,
    filters: Filters,
    paginationParameters: PaginationParameters,
    flavor?: string,
    channelId?: string,
    subscriptionId?: string,
  ): Observable<ContentNavigatorTablePage> {
    if(channelId){
      const currentFilters = {
        channel: [channelId],
        flavor: [flavor],
        ...filters,
      };
      return this.searchService.getTable(searchTerm, currentFilters, paginationParameters);
    }
    return this.resolveFilters(filters, subscriptionId).pipe(
      concatMap((resolvedFilters) => {
        return this.searchService.getTable(searchTerm, resolvedFilters, paginationParameters);
      })
    );
  }

  getAllBlogs(
    searchTerm: string,
    filters: Filters,
    paginationParameters: PaginationParameters,
    subscriptionIds?: string[]
  ): Observable<BlogExcerptPaginatedList> {
    return this.searchService.getBlogs(searchTerm, filters, paginationParameters, subscriptionIds);
  }

  /**
   * Get a list of channel id for an array of subscriptions
   *
   * @param subscriptions: an array of subscriptions with children channels
   * @returns an array of channel id
   */
  getChannelIdsBySubscriptions(subscriptions: Array<NavigationElement>): Array<string> {
    return [].concat(...subscriptions.map((subscription) => subscription.children.map((channel) => channel.id)));
  }

  /**
   * Gets a limit number of latest reports of the provided subscription, flavor or list of channels
   *
   * @param flavor: any value of ReportFlavor
   * @param limit: max number of reports to retrieve
   * @param channelIds: a lsit of channel ids
   * @param subscriptionId: a subscription id
   * @returns Observable<ContentNavigatorTable>
   */
  public getLatestAnalysis(
    flavor: ReportFlavor,
    limit: number,
    channelIds?: Array<string> | null,
    subscriptionId?: string | null,
  ): Observable<ContentNavigatorTable> {
    if (subscriptionId) {
      return this.navigationService.getSubscription(subscriptionId, NavigationModules.REVERSE_ENGINEERING).pipe(
        concatMap((subscription: Subscription) => {
          const channels = subscription.children.map((channel) => channel.id);
          return this.getLatestAnalysis(flavor, limit, channels);
        })
      );
    } else if (channelIds) {
      return this.ctiSearchAnalysis(channelIds, flavor, limit);
    } else {
      return this.navigationService.getModuleChannelsByName(NavigationModules.REVERSE_ENGINEERING).pipe(
        concatMap((allChannels: Channel[]) => {
          const channels = allChannels.map((channel) => channel.id);
          return this.getLatestAnalysis(flavor, limit, channels);
        })
      );
    }
  }

  public ctiSearchAnalysis(
    channelIds: Array<string>,
    flavor: ReportFlavor,
    limit: number
  ): Observable<ContentNavigatorTable> {
    return this.searchService
      .cTISearch<CTISearchResult>([], new PaginationParameters(limit, 0, 'releaseDate,DESC', { from: 0 }), {
        channelId: channelIds,
        flavor: [flavor],
      })
      .pipe(
        map((response) => ({
          rows: response.results.map((result) => ({
            id: result.id,
            title: result.name,
            analysisType: result.reportType,
            productCode: result.code,
            description: result.description,
            deviceName: result.deviceName,
            manufacturer: result.deviceManufacturer,
            deviceType: result.deviceType,
            subscription: this.getSubscriptionIcon(result.subscriptionName),
            channel: result.channelName,
            completionDate: result.analysisEndDate ? new Date(result.analysisEndDate) : null,
            documentsCount: result.documentsCount,
            imagesCount: result.imagesCount,
            entitled: result.entitled,
            subscriptionName: result.subscriptionName,
          })),
        }))
      );
  }

  /**
   * Get recent reports by channel ID and flavor
   * @param channelIds - an array of channel SF IDs
   * @param flavors - an array of flavor strings i.e. ['FINAL', 'IN_PROGRESS', 'ANALYST', 'SAMPLE_ANALYSIS']
   * @param subscriptionName - the string name of this subscription
   * @param limit - a record count limit,
   * @returns - a single contentNavigatorTable containing all rows, ordered by channel, then by flavor, then by
   *            completion date descending.
   */
  getReportsTable(
    channelIds: Array<string>,
    flavors: Array<string>,
    subscriptionName: string,
    limit?: number
  ): Observable<ContentNavigatorTable> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const params = new HttpParams()
      .set('channels', channelIds.join(','))
      .set('flavors', flavors.join(','))
      .set('record_count', limit ? limit.toString() : '0');
    return this.httpClient.get(`${environment.metaServiceBaseUrl}/dashboard/reports`, { headers, params }).pipe(
      // Map reports to a content navigator table:
      map((reports: any) => {
        const tableRows = [].concat(
          ...reports.results.map((result: any) =>
            result.reports.map((report: any) =>
              this.dashboardReportToTableRow(result.channel_id, report, subscriptionName)
            )
          )
        );
        return { rows: tableRows };
      }),
      // Convert channel IDs to names:
      map((table: ContentNavigatorTable) => {
        table.rows.forEach((row: ContentNavigatorTableRow) =>
          this.navigationService
            .getChannel(row.channel, NavigationModules.REVERSE_ENGINEERING)
            .subscribe((channel: Channel) => (row.channel = channel.name))
        );
        return table;
      })
    );
  }

  /**
   * Get a list of channel card information for a given subscription.
   * @param subscriptionId Salesforce ID of the subscription to get channel information for.
   */
  getChannelCardInfo(subscriptionId: string): Observable<Array<ReverseEngineeringChannelCard>> {
    return this.getSubscription(subscriptionId).pipe(
      map((subscrption) => {
        const subscriptionChannels = subscrption;
        subscriptionChannels.children = subscrption.children.filter(
          (child) => child.type === NavigationObjectType.CHANNEL
        );
        return subscriptionChannels;
      }),
      concatMap((subscription: Subscription) => {
        const mergedIds = subscription.children.reduce(
          (previousValue: string, currentValue: NavigationElement) => `${previousValue},${currentValue.id}`,
          ''
        );
        const countingUrl = `${this.metaUrl}/products/counting`;
        const headers = new HttpHeaders().set('Accept', 'application/json');
        const params = new HttpParams().set('channels', mergedIds);
        return this.httpClient.get<any>(countingUrl, { headers, params }).pipe(
          map((channelCounts) => {
            const channelCardInfo: Array<ReverseEngineeringChannelCard> = [];
            subscription.children.forEach((channel: Channel) => {
              const channelInfo = channelCounts.products.find(
                (channelCount: { channel: string }) => channel.id === channelCount.channel
              );

              if(channelInfo?.count > 0){
                channelCardInfo.push(
                  new ReverseEngineeringChannelCard(
                    channel.id,
                    channel.name,
                    channel.entitled,
                    // Use undefined in case the counts are
                    // missing so channel cards can use sanitizeNumber to display '?':
                    channelInfo ? channelInfo.final_count : undefined,
                    channelInfo ? channelInfo.analysis_count : undefined,
                    channel.isCustom ?? false,
                    channelInfo ? channelInfo.sample_count : undefined,
                    channelInfo ? channelInfo.in_progress_count : undefined
                  )
                );
              }
            });
            return channelCardInfo;
          })
        );
      })
    );
  }

  /**
   * Get a mocked list of channel card information for Logic analytics.
   * @returns a list of the channel cards
   */
  getAnalyticsChannelCardInfo(): Observable<Array<ReverseEngineeringChannelCard>> {
    return this.buildAnalyticsCard(RE_ANALYTICS_CHANNEL);
  }

  /**
   * Get a mocked list of channel card information for Logic analytics.
   * @returns a list of the channel cards
   */
  getAnalyticsSubChannelCardInfo(paramChannelID: string): Observable<Array<ReverseEngineeringChannelCard>> {
    const channelID: REAnalyticsIds = paramChannelID as REAnalyticsIds;
    const analyticChannel: Array<Dashboard> = RE_ANALYTICS_CHILDREN_MAP.get(channelID);
    return this.buildAnalyticsCard(analyticChannel);
  }

  /**
   * Gets the analysis filters for the sidebar in the all analysis page
   * @returns a list of the filters
   */
  getAnalysisFiltersForSidebar(): Observable<AnalysisFilter[]> {
    return combineLatest([
      this.navigationService.getModuleCardsByName(NavigationModules.REVERSE_ENGINEERING),
      this.getFacets(),
    ]).pipe(
      map(([subscriptions, analysis]) => {
        return analysis
          .map((f) => FilterHelper.buildFilter(f, subscriptions))
          .sort((a, b) => {
            if (this.SIMPLE_FILTERS_FIELD_NAME.includes(a.field)) {
              return -1;
            } else if (this.SIMPLE_FILTERS_FIELD_NAME.includes(b.field)) {
              return 1;
            }
            return 0;
          });
      })
    );
  }

  getNoResultsInProgress(tab: string, inProgressCount: number, terms?: AnalysisParametersEvent): boolean {
    let noResultsInProgress = tab === ReportFlavor.IN_PROGRESS && inProgressCount === 0;
    if (terms && terms.filters) {
      noResultsInProgress =
        noResultsInProgress && Object.keys(terms.filters).length === 0 && terms.searchTerm.length === 0;
    } else if (terms) {
      noResultsInProgress = noResultsInProgress && terms.searchTerm.length === 0;
    }
    return noResultsInProgress;
  }

  private resolveFilters(filters: Filters, subscriptionId?: string): Observable<Filters> {
    if (subscriptionId && !filters.hasOwnProperty('channel')) {
      return this.navigationService.getSubscription(subscriptionId, NavigationModules.REVERSE_ENGINEERING).pipe(
        concatMap((subscription: Subscription) => {
          const idChannels = subscription.children.map((channel) => channel.id);
          return of({ ...filters, channel: idChannels });
        })
      );
    } else if (filters.hasOwnProperty('channel')) {
      return of(filters);
    }

    return this.navigationService.getModuleChannelsByName(NavigationModules.REVERSE_ENGINEERING).pipe(
      concatMap((allChannels) => {
        const idChannels = allChannels.map((channel) => channel.id);
        return of({ ...filters, channel: idChannels });
      })
    );
  }

  private dashboardReportToTableRow(
    channelId: string,
    report: any,
    subscriptionName: string
  ): ContentNavigatorTableRow {
    return {
      id: report.id,
      title: report.handle,
      analysisType: report.code.substring(0, 3), // How long will this hold up?
      productCode: report.code,
      deviceName: report.devicePortNumber, // deviceName is not available at this endpoint, fill name
      // with Part Number for now. I think Port is typo for Part
      manufacturer: report.deviceManufacturer,
      deviceType: report.deviceType,
      subscription: this.getSubscriptionIcon(subscriptionName),
      channel: channelId,
      completionDate: report.completionDate,
      documentsCount: report.documentsCount,
      imagesCount: report.imagesCount,
      entitled: report.entitled,
    };
  }

  /**
   * Gets the raw facets from meta service
   * @returns a list of facets
   */
  private getFacets(): Observable<any[]> {
    return this.httpClient.get(`${environment.metaServiceBaseUrl}/facets`).pipe(
      map((filters: Array<any>) => {
        return filters.filter((f) => this.FILTERS_SHOW_IN_SIDEBAR.includes(f.fieldName));
      })
    );
  }

  private buildAnalyticsCard(analyticChannels: Array<Dashboard>): Observable<Array<ReverseEngineeringChannelCard>> {
    return this.entitlementService.getAnalyticsEntitlements().pipe(
      concatMap((response) => {
        const channelCardInfo: Array<ReverseEngineeringChannelCard> = [];
        const channelEntitled = response.channels;
        analyticChannels.forEach((channel: Dashboard) => {
          const channelEntitlement = !!channelEntitled.find((entitled: string) => entitled === channel.channelSfId);
          channelCardInfo.push(
            new ReverseEngineeringAnalyticsChannelCard(
              channel.id,
              channel.name,
              channelEntitlement,
              0,
              0,
              channel.isCustom ?? false,
              0,
              0,
              channel.dashboardUrl
            )
          );
        });

        return of(channelCardInfo);
      }),
      catchError(() => {
        const channelCardInfo: Array<ReverseEngineeringChannelCard> = [];

        analyticChannels.forEach((channel: Dashboard) => {
          channelCardInfo.push(
            new ReverseEngineeringAnalyticsChannelCard(
              channel.id,
              channel.name,
              false,
              0,
              0,
              channel.isCustom ?? false,
              0,
              0,
              channel.dashboardUrl
            )
          );
        });
        return of(channelCardInfo);
      })
    );
  }

  private getSubscriptionIcon(subscriptionName: string): string {
    const icons = {
      Battery: 'icon-battery',
      Automotive: 'icon-automotive',
      'Component Price': 'icon-cpl',

      Logic: 'icon-logic',
      'Image Sensor': 'icon-image_sensor',
      IoT: 'icon-iot',

      'Memory - Embedded & Emerging': 'icon-memory2',
      'NAND & DRAM': 'icon-memory2',
      'Mobile RF': 'icon-mobileRF',

      'Power Semiconductor': 'icon-power',
      Teardown: 'icon-teardown',
      Packaging: 'icon-packaging',

      'Custom Library': 'icon-custom-library',
      Costing: 'icon-costing',
      'Special Content': 'icon-special-content',

      Sustainability: 'icon-sustainability',
    };

    return icons[subscriptionName] ?? 'icon-default';
  }
  // @sonar.issue.ignore.block.end
}

