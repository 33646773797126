import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { PaginationParameters } from '@shared/models/pagination';
import { AnalysisImage, SelectedImages } from '@shared/models/reverse-engineering/analysis.models';
import { formatBytes } from '@shared/utils/common';

@Directive({
  selector: '[appBaseAnalysisImagesView]',
})
export class BaseAnalysisImagesViewDirective {
  @Input() images: Array<AnalysisImage> = [];
  @Input() imageCount = 0;
  @Input() pageSize = 10;
  @Input() currentPage = 1;
  @Input() isLoading = false;
  @Input() title: string;
  @Input() addToFavoriteEnabled = false;

  @Output() clickImage: EventEmitter<AnalysisImage> = new EventEmitter<AnalysisImage>();
  @Output() changePaginationOptions = new EventEmitter<PaginationParameters>();
  @Output() downloadImages = new EventEmitter<any>();
  @Output() shareImage = new EventEmitter<AnalysisImage>();
  @Output() addToFavorites = new EventEmitter<AnalysisImage>();

  pageSizes = [10, 50, 100];
  selectedImages: SelectedImages = {
    selectedImagesQty: 0,
    allSelected: false,
    totalSize: '',
    allImages: [],
  };

  /**
   * Emits an event when a card is clicked
   * @param image the image clicked
   */
  onClickImage(image: AnalysisImage): void {
    image.selected = !image.selected;
    this.clickImage.emit(image);
  }

  /**
   * Emits an event if the pagination options change
   * @param event the event from the pagination controls
   */
  onChangePaginationOptions(event: PaginationParameters): void {
    this.pageSize = event.size;
    this.currentPage = event.page;
    this.changePaginationOptions.emit(event);
  }

  onClickDownloadImages() {
    const assetIds: any[] = [];

    if(!this.selectedImages.allSelected) {
      this.selectedImages.allImages.forEach((someImage) => {
        if (someImage.selected) {
          assetIds.push({
            asset_id: someImage.assetId,
            asset_group_id: someImage.assetGroupId
          });
        }
      });
    }

    this.downloadImages.emit({assetIds, allSelected: this.selectedImages.allSelected });
  }

  onClickShare(event: Event, image: AnalysisImage) {
    event.preventDefault();
    event.stopPropagation();
    this.shareImage.emit(image);
  }

  onClickAddToFavorites(image: AnalysisImage) {
    this.addToFavorites.emit(image);
  }

  protected updateSelectedImages(): void {
    if (this.images && !this.isSubset(this.selectedImages.allImages, this.images)) {
      this.selectedImages.allImages = this.selectedImages.allImages.concat(this.images);
    }

    if (this.selectedImages.allSelected) {
      this.selectedImages.selectedImagesQty = this.imageCount;

      this.selectedImages.allImages.forEach((someSelected) => {
        someSelected.selected = this.selectedImages.allSelected;
      });

      this.images.forEach((image) => {
        image.selected = this.selectedImages.allSelected;
      });
    }

    this.selectedImages.allSelected = this.selectedImages.selectedImagesQty === this.imageCount;
    this.setDownloadSize();
  }

  private setDownloadSize(): void {
    const totalSize = this.selectedImages.allImages
      .filter((image) => image.selected)
      .map((image) => {
        const imageSize = parseFloat(image.size);

        if (image.selected && image.size.endsWith('MB')) {
          return imageSize * 1048576;
        }
        return imageSize * 1024;
      })
      .reduce((result, item) => result + item, 0);

    this.selectedImages.totalSize = totalSize === 0 ? '' : formatBytes(totalSize, 2);
  }

  private isSubset(selectedImages: AnalysisImage[], images: AnalysisImage[]) {
    let j = 0;

    for (const image of images) {
      for (j = 0; j < selectedImages.length; j++) {
        if (image.id === selectedImages[j].id) {
          break;
        }
      }

      if (j === selectedImages.length) {
        return false;
      }
    }

    return true;
  }
}
