<ng-container [ngSwitch]="shareState">
  <ng-container *ngSwitchCase="'share'">
    <div class="share-modal scrollbar">
      <div class="overlay-spinner" *ngIf="isSpinnerShown">
        <div class="overlay-spinner" *ngIf="isSpinnerShown">
          <progress class="pure-material-progress-circular"></progress>
        </div>
      </div>
      <div class="loaded-content" [attr.disabled]="isSpinnerShown">
        <div class="modal-header">
          <h4 class="modal-title">{{newTitle}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="modalDismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body link-share-body">
          <h4 class="modal-sub-title">Share using a Link</h4>
          <div>
            <form [formGroup]="shareModalFormGroup">
              <app-input [props]="inputPropsLink" formControlName="link"></app-input>
            </form>
            <div class="modal-notice show-info">
              {{copyToClipboardNotice}}
            </div>
          </div>
        </div>
        <div class="modal-footer share-button social-share-footer">
          <div class="social-share-container">
            <div class="ms-teams-wrapper" (click)="openShareToTeamsPopup()">
              <img [src]="msTeamLogo" alt=""/>
              <span>Share to Teams</span>
            </div>
            <div class="email-wrapper" (click)="openMailto()">
              <em class="icon-mail"></em>
              <span>Share to Email</span>
            </div>
          </div>
          <button type="button" _ti_copy_to_clipboard="copy_to_clipboard"
            class="btn-modal-primary share-btn" (click)="onCopyItemUrl()">{{this.copyButtonText}}</button>
        </div>
      </div>
    </div>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
      <div class="modal-header modal-error">
        <button type="button" class="close-dialog" aria-label="Close" (click)="modalDismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
        <i class="icon-warning"></i>
        <h4 class="modal-title">Unable to complete sharing document</h4>
      </div>
      <div class="modal-body modal-container modal-error">
        <div class="body-column">
          <div *ngIf="modalBody.emailsNotWhitelisted && modalBody.emailsNotWhitelisted.length !== 0">
            <p>
              Document may only be shared within your organization. Accepted email domains include:
              <label><strong> {{this.modalBody.whitelistedDomain | join_strings}} </strong></label>
            </p>
            <p>Unable to share document with the following email accounts:
              <label><strong>{{ this.emailsWithErrors | join_strings }}</strong></label>
            </p>
          </div>
          <p>Please contact <a href="mailto: support@techinsights.com">support&#64;techinsights.com</a> for assistance.</p>
        </div>
      </div>
      <div class="modal-footer modal-error">
        <button type="button" class="btn-modal-secondary" (click)="modalClose('Cancel click')">Close</button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'success'">
      <div class="modal-header modal-error">
        <button type="button" class="close-dialog" aria-label="Close" (click)="modalDismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
        <i class="custom-icon success-custom-icon"></i>
        <h4 class="modal-title">The document has been shared</h4>
      </div>
      <div class="modal-footer modal-error">
        <button type="button" class="btn-modal-secondary" (click)="modalClose('Cancel click')">Close</button>
      </div>
    </ng-container>
</ng-container>
