import { NumberSymbol } from '@angular/common';
import { ChannelCard } from '@app/@shared/models/shared.models';
import { sanitizeNumber } from '@shared/utils/common';
import { AnalysisFilter } from '../reverse-engineering/analysis.models';

/**
 * Subscriptions with channels
 */
export interface MarketAnalysisSubscription {
  id?: string;
  name: string;
  entitled: boolean;
  briefingFilename: string;
  channels?: Array<MarketAnalysisChannelExcerpt>;
}

export interface MarketAnalysisCompositeChannel {
  id: string;
  name: string;
  channels: Array<MarketAnalysisChannelExcerpt>;
}

/**
 * Represents small portion of information about a channel
 * to be used like a card
 */
export class MarketAnalysisChannelExcerpt extends ChannelCard {
  hasSubChannels: boolean;
  subChannelsCount: number;
  entitledSubChannelsCount: number;
  reportsCount: number;

  constructor(
    id: string,
    title: string,
    entitled: boolean,
    hasSubChannels: boolean,
    subChannelsCount: number,
    entitledSubChannelsCount: number,
    reportsCount: NumberSymbol,
    accessible?: boolean
  ) {
    super(id, title, entitled, accessible);
    this.hasSubChannels = hasSubChannels;
    this.subChannelsCount = subChannelsCount;
    this.entitledSubChannelsCount = entitledSubChannelsCount;
    this.reportsCount = reportsCount;
  }

  public buildSubtitle(): string {
    if (this.hasSubChannels) {
      return (
        `Sub-channels ${sanitizeNumber(this.entitledSubChannelsCount)} ` + `of ${sanitizeNumber(this.subChannelsCount)}`
      );
    } else {
      return `${sanitizeNumber(this.reportsCount)} Reports`;
    }
  }

  public getSubtitleSegments(): Array<{label: string}> {
    if (this.hasSubChannels) {
      return [
        { label: `Sub-channels ${sanitizeNumber(this.entitledSubChannelsCount)} of ${sanitizeNumber(this.subChannelsCount)}`}
      ];
    } else {
      return [
        { label: `${sanitizeNumber(this.reportsCount)} Reports`}
      ];
    }
  }
}

export interface MarketAnalysisReportModule {
  id: string;
  title: string;
  reports: Array<Report>;
}

export interface MarketAnalysisNameIds {
  name: string;
  channelId: string;
  subscriptionId?: string;
}

export interface Report {
  contentCategory?: string;
  description: string;
  downloadEnabled: boolean;
  downloading: boolean;
  entitled: boolean;
  fileType: ReportFileType;
  id: string;
  internalId: string;
  objectId: string;
  publishDate: string;
  reportCode: string;
  reportModuleId?: string;
  title: string;
  vicCode: string;
  authorName?: string;
  authorAvatarUrl?: string;
  viewCount?: number;
  assetName?: string;
}

export interface ReportsPage {
  reports: Array<Report>;
  count: number;
}

export interface BlogExcerpt {
  id: string;
  category: string;
  title: string;
  description: string;
  publicationDate: Date;
  authorName: string;
  authorAvatarUrl: string;
  entitled: boolean;
}

export interface BlogExcerptPaginatedList {
  blogs: Array<BlogExcerpt>;
  count: number;
  filters?: AnalysisFilter[];
}

export interface Blog extends BlogExcerpt {
  content: string;
  link: string;
  tags: Array<string>;
}
// **************************************
// Media section
// **************************************

export interface Video {
  id: string;
  title: string;
  description?: string;
  hashedId: string;
  channelId?: string;
  channelName: string;
  subscriptionId?: string;
  subscriptionName?: string;
  thumbnailUrl: string;
  entitled: boolean;
  createDate: string;
  updateDate: string;
  duration: number;
  published: string;
  wistiaUrl?: string;
  wistiaCreateDate?: string;
  wistiaUpdateDate?: string;
  viewCount?: number;
  free?: boolean;
}

export interface VideoPaginatedList {
  videos: Array<Video>;
  count: number;
  filters?: AnalysisFilter[];
}

// **************************************
// Dynamic content section
// **************************************

export interface DynamicContentTable {
  name: string;
  vic: string;
  copyright: string;
  versionName: string;
  frequencies: DynamicHierarchyFrequencies;
  columns: Array<DynamicContentColumn>;
  rows: Array<DynamicContentRow>;
  totalRow: DynamicContentRow;
  annualGrowth?: DynamicContentRow;
}

export interface DynamicContentColumn {
  field: string;
}

export interface DynamicContentRow {
  [columnName: string]: string | number;
}

export interface DynamicDataTreeNode {
  name: string;
  vic: string;
  disabled?: boolean;
  children: Array<DynamicDataTreeNode>;
}

export interface DynamicDataHierarchy {
  yearly: DynamicDataTreeNode;
  quarterly: DynamicDataTreeNode;
}

export interface DynamicDataHierarchyList {
  yearly: Array<DynamicDataTreeNode>;
  quarterly: Array<DynamicDataTreeNode>;
}

// **************************************
// Constants and Enums
// **************************************

export enum ReportFileType {
  PDF = 'PDF',
  XLSX = 'XLSX',
  XML = 'XML',
  XLS = 'XLS',
  XLSM = 'XLSM'
}

export const ReportFileTypeMap = {
  PDF: ReportFileType.PDF,
  XLSX: ReportFileType.XLSX,
  'application/xml': ReportFileType.XML,
  'application/pdf': ReportFileType.PDF,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ReportFileType.XLSX,
  'application/vnd.ms-excel': ReportFileType.XLS,
  'application/vnd.ms-excel.sheet.macroenabled.12': ReportFileType.XLSM
};

export enum DynamicHierarchyFrequencies {
  YEARLY = 'YEARLY',
  QUARTERLY = 'QUARTERLY',
  CRITICAL_SUBSYSTEMS = 'CRITICAL_SUBSYSTEMS',
}

// Customer satisfaction survey dynamic content type
export enum SurveyContentType {
  TEN_BEST = '10_BEST', // TEN_BEST_SUPPLIERS_ALL_SEGMENTS
  CONTENDERS = '10_BEST_CONTENDERS', // RANK_FIRST_SEGMENT
  THE_BEST = 'THE_BEST', // BEST_SPECIFIC_SEGMENT
  RANKED_FIRST = 'RANKED_FIRST', // TEN_BEST_CONTENDERS
  RAW_DATA = 'RAW_DATA',
}
