import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Measurement, MeasurementType } from '@app/@shared/models/kimera/measurement';
import { Calibration } from '@app/@shared/models/calibration/calibration.model';

@Component({
  selector: 'app-image-measurement-dialog',
  templateUrl: './image-measurement-dialog.component.html',
  styleUrls: ['./image-measurement-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export  class ImageMeasurementDialogComponent {

  @Input() set measurement(measurement: Measurement){
    this.measurements = measurement.measurements;
    this.measurementType = measurement.measurementType;
    if(measurement.measurementType === MeasurementType.Length){
      this.measurements[0] = this.convertToTwoDecimalPlaces(this.measurements[0]);
      this.measurementText = `${this.measurements[0]} ${this.calibration.units}`;
      this.iconClass = 'icon-ruler2';
      this.calibrationText = `Calibration: ${this.calibration.scaleRef}${this.calibration.units} = ${this.calibration.scale}px`;
    }
  }
  @Input() calibration: Calibration;
  @Output() clickRecalibrate: EventEmitter<void> = new EventEmitter();

  measurements: number[];
  measurementType: MeasurementType;
  iconClass: string;
  measurementText: string;
  calibrationText: string;
  footerText = 'Note: Layout objects are reconstructed. Sizes are approximate.';

  constructor(public modal: NgbActiveModal) { }

  recalibrate() {
    this.clickRecalibrate.emit();
    this.modal.close();
  }

  private convertToTwoDecimalPlaces(number: number): number{
    return (parseFloat(number.toFixed(2)));
  }
}
