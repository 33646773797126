import { TipContent } from '../models/shared.models';

export const InfoNotifications = {
  digestFrequency: {
    message: 'The Platform Subscription Digest email contains links to new analyses based on your ' +
      'Digest Subscription settings below, as well as TechStream and other content updates. ' +
      'It will be sent to your email (%email%).'
  },
  progressTracker: {
    message: 'Analysis in Progress Notification Emails are sent when the analysis is updated and are separate from Subscription Digest Emails.'
  }
};

/**
 * DialogConfirmations defines flags for "Don't show this again" checkboxes in various dialogs.
 */
export const DialogConfirmations = {
  digestSubscriptions: {
    message: 'This content will be removed from your Subscription Digest email. You can enable it again at any time.'
  },
  progressTracker: {
    message: 'Are you sure you want to remove this progress tracker?'
  }
};

export const ProgressTrackerMessage: TipContent = {
  lineOne: 'This tool provides you with an up-to-date, filterable view into our content creation schedule.',
  lineTwo: 'Click a Report Name or Report Code to see available in-progress or finalized content in a new browser tab.',
  lineThree: 'Select and adjust filters to refine your list.',
};

export enum AlertMessages {
  SETTING_SAVED = 'Setting saved.',
  SETTING_NOT_SAVED = 'Setting was not saved. Please refresh the page or try again later.',
  SETTINGS_SAVED = 'Settings saved.',
  SETTINGS_NOT_SAVED = 'Settings was not saved. Please refresh the page or try again later.',
  SETTINGS_FIELDS_INVALID  = 'Please fill in the required fields.',
  PROGRESS_TRACKER_DELETED = 'Report progress tracker deleted.',
  SOMETHING_WENT_WRONG = 'Something went wrong. Please refresh the page or try again later.'
}

export enum NotificationsPreferenceMessages {
  DISABLE_LAST_SUBSCRIPTION = 'You must have at least one digest subscription selected.',
  NO_PROGRESS_TRACKERS = 'You are not current following to any analysis progress updates.',
}

export const NoAvailableContentMessage = {
  inProgressMessage: {
    title: 'No content at this time.',
    subTitle: 'Preliminary analysis content is accessible here when available. Use the <a href="/progress-tracker">Progress Tracker</a> tool to ' +
      'follow and subscribe to updates for specific analyses.'
  },
  defaultMessage: {
    title: 'Your search query did not find any matches.',
    subTitle: 'Try adjusting your search terms and filter settings.'
  }
};
