import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectedImages } from '@app/@shared/models/reverse-engineering/analysis.models';
@Component({
  selector: 'app-download-panel',
  templateUrl: './download-panel.component.html',
  styleUrls: ['./download-panel.component.scss'],
})
export class DownloadPanelComponent {
  @Input() downloadEnabled = false;
  @Input() selectedImages: SelectedImages = {
    selectedImagesQty: 0,
    allSelected: false,
    totalSize: '',
  };

  @Output() allImagesSelected = new EventEmitter<boolean>();
  @Output() downloadImages = new EventEmitter();

  selectAll() {
    this.allImagesSelected.emit(!this.selectedImages.allSelected);
  }

  onClickDownload(): void {
    this.downloadImages.emit();
  }
}
