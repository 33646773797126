import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@app/@shared/services/dialog/dialog.service';
import { RouteHelper } from '@app/@shared/utils/route-helper';
import { ShareLinkType } from '@app/@shared/models/share-link/share-link.model';
import { environment } from '@env/environment';
import { Blog } from '@shared/models/market-analysis/market-analysis';
import { PreviousPageService } from '@shared/services/previous-page.service';
import { Icon } from '../../generic-toolbar/icons';
import { OptionGroup, PropagationEvent, SocialOption } from '../../generic-toolbar/option-model';
import { StatelessBehavior } from '../../generic-toolbar/state-behavior-model';
import { ShareItemModalComponent } from '../../share-item-modal/share-item-modal.component';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';

@Component({
  selector: 'app-blog-viewer',
  templateUrl: './blog-viewer.component.html',
  styleUrls: [ './blog-viewer.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class BlogViewerComponent implements  OnInit {

  @Input() blog: Blog;
  @Input() subscription: string;
  @Input() isTechstreamView: boolean;

  public toolbarOptions: OptionGroup[];

  private eventToMethod = {
    share: 'share',
  };

  constructor(
    public prevPage: PreviousPageService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.toolbarOptions = this.createToolbarOptions();
  }

  public onClickBack() {
    this.prevPage.navigateBack();
  }

  public executeToolbarOption(event: PropagationEvent): void {
    if (event.key in this.eventToMethod) {
      const methodName = this.eventToMethod[event.key];
      this[methodName](event);
    }
  }
  /**
   * createToolbarOptions sets up the GenericToolbarComponent.
   */
  private createToolbarOptions(): OptionGroup[] {
    const optionGroups: OptionGroup[] = [];
    const socialOptions: SocialOption[] = [];

    socialOptions.push(
      new SocialOption('share', [{ name: 'click', key: 'share' }], new StatelessBehavior(Icon.IMAGE_SHARE, 'Share'))
    );
    optionGroups.push(new OptionGroup(socialOptions));

    return optionGroups;
  }

  private share(): void {
    this.dialogService.open(ShareItemModalComponent, { windowClass: 'shareModalClass' }, {
      isDocumentShareable: false,
      shareItemEntityId: this.blog.id,
      shareItemEntityTitle: this.blog.title,
      itemDeepLinkUrl: environment.basePath + this.createBlogUrl(),
      shareEntityType: ShareLinkType.BLOG,
    });
  }

  private createBlogUrl(): string {
    return this.router.serializeUrl(
      this.router.createUrlTree(['blog-viewer', this.blog.id], {
        // if we are not in a module (which shouldn't happen)
        // we go to the shell which has an analysis-view route
        relativeTo: RouteHelper.getModuleRouteOrShell(this.route),
      })
    );
  }
}
