<h4 class="modal-title">{{ title }}</h4>
<p *ngIf="downloadEnabled">{{ message }}</p>
<ngb-progressbar
  *ngIf="!downloadEnabled"
  type="success"
  [value]="progress"
  height="16px"
  [animated]="true"
></ngb-progressbar>
<ng-container *ngIf="skippedFiles && skippedFiles.length">
  <p class="warning-container">
    <em class="icon-warning"></em>
    <span>
      Some files were unable to be added to the zip file.
      Review the list below and contact support if you need assistance.
    </span>
  </p>
  <div class="skipped-files-container">
    <div class="skipped-files-list">
      <div
        *ngFor="let file of skippedFiles"
        class="skipped-file-item"
      >
        {{ file }}
      </div>
    </div>
  </div>
</ng-container>
<div class="button-container">
  <button
    type="button"
    class="secundary"
    (click)="onClickCancel()"
  >Cancel</button>
  <button
    [disabled]="!downloadEnabled"
    [class.primary]="downloadEnabled"
    type="button"
    (click)="onClickDownload()"
  >Download</button>
</div>
