import {createAction, props} from '@ngrx/store';
import {IError} from '../../Models/application-health/models';

/**
 * Application health actions definitions
 *
 * Here goes all the actions that affects the application health state, like connection state, errors, app version,
 * logging and such
 */

export enum ApplicationHealthActionTypes {
  setOnlineState = '[App-health] Set online state',
  notifyRecoverableError = '[App-health] Notify recoverable error',
  notifyUnrecoverableError = '[App-health] Notify unrecoverable error',
  notifyNewVersion = '[App-health] Notify new version',
  dismissNewVersion = '[App-health] Dismiss new version'
}

export const setOnlineState = createAction(ApplicationHealthActionTypes.setOnlineState, props<{online: boolean}>());
export const notifyRecoverableError = createAction(
  ApplicationHealthActionTypes.notifyRecoverableError,
  props<{error: IError}>()
);
export const notifyUnrecoverableError = createAction(
  ApplicationHealthActionTypes.notifyUnrecoverableError,
  props<{error: IError}>()
);
export const notifyNewVersion = createAction(ApplicationHealthActionTypes.notifyNewVersion);
export const dismissNewVersion = createAction(ApplicationHealthActionTypes.dismissNewVersion);



