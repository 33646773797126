import { environment } from '@env/environment';
import {
  Channel,
  Dashboard,
  DatabaseTools,
  DefaultSubscription,
  MarketSegmentSubscription,
  NavigationElement,
  NavigationElementMappingError,
  NavigationModules,
  NavigationObjectType,
  NavigationTreeNode,
  RECustomSubscription,
  ReportModule,
  RESubscriberSubscription,
  SACustomSubscription,
  SASubscriberSubscription,
  SidebarItem,
  Subscription,
} from '@shared/models/navigation/navigation.models';

export class NavigationElementFactory {
  public static build(obj: NavigationTreeNode, moduleName?: string): NavigationElement | NavigationTreeNode {
    if (!obj || !obj.type) {
      return obj;
    }

    switch (obj.type) {
      case NavigationObjectType.MODULE:
        return this.buildSidebarItem(obj);
      case NavigationObjectType.SUBSCRIPTION:
        return this.buildSubscription(obj, moduleName);
      case NavigationObjectType.DASHBOARD:
        return this.buildDashboard(obj);
      case NavigationObjectType.CHANNEL:
        return this.buildChannel(obj);
      case NavigationObjectType.REPORT_MODULE:
        return this.buildReportModule(obj);
      default:
        return obj;
    }
  }

  static buildSidebarItem(navigationTreeNode: NavigationTreeNode): SidebarItem {
    if (!navigationTreeNode || navigationTreeNode.type !== NavigationObjectType.MODULE) {
      throw new NavigationElementMappingError('The selected node is not a sidebar item');
    }

    return {
      id: navigationTreeNode.id || navigationTreeNode.sfId,
      name: navigationTreeNode.name,
      class: navigationTreeNode.class,
      tooltip: navigationTreeNode.name,
      route: navigationTreeNode.route,
      icon_class: navigationTreeNode.icon_class,
      animation_type: navigationTreeNode.animation_type,
      animation_asset: navigationTreeNode.animation_asset,
      hasNewInfo: false,
      children: [],
      type: NavigationObjectType.MODULE,
      content: navigationTreeNode.content,
      container: navigationTreeNode.container,
      dashboardId: navigationTreeNode.dashboardId,
    };
  }

  static buildSubscription(navigationTreeNode: NavigationTreeNode, moduleName?: string): Subscription {
    if (!navigationTreeNode || navigationTreeNode.type !== NavigationObjectType.SUBSCRIPTION) {
      throw new NavigationElementMappingError('The selected node is not a Subscription');
    }

    const children = navigationTreeNode.children || [];
    const subscriptionId = navigationTreeNode.id || navigationTreeNode.sfId;
    let subscription;
    switch (moduleName) {
      case NavigationModules.REVERSE_ENGINEERING:
        const isLogicSubscription = environment.analyticsEnabledSubscriptions.indexOf(subscriptionId) !== -1;
        subscription = !navigationTreeNode.isCustom
          ? new RESubscriberSubscription(isLogicSubscription)
          : new RECustomSubscription();
        break;
      case NavigationModules.STRATEGY_ANALYTICS:
        const hasAnalytics = environment.dashboardEnabledSubscriptions.indexOf(subscriptionId) !== -1;
        subscription = !navigationTreeNode.isCustom
          ? new SASubscriberSubscription(hasAnalytics)
          : new SACustomSubscription();
        break;
      case NavigationModules.MARKET_SEGMENTS:
        subscription = new MarketSegmentSubscription();
        break;
      default:
        subscription = new DefaultSubscription();
        break;
    }

    subscription.name = navigationTreeNode.name;
    subscription.id = subscriptionId;
    subscription.totalChannels = navigationTreeNode.children.length;
    subscription.availableChannels = navigationTreeNode.children.filter((child: any) => child.entitled).length;
    subscription.icon = 'icon-manufacturing_analysis';
    subscription.briefingFilename = this.buildProductOverview(navigationTreeNode.briefingFilename, moduleName);
    subscription.productOverviewUrl = navigationTreeNode.productOverviewUrl;
    subscription.roadmaps = navigationTreeNode.roadmaps ?? [];
    subscription.entitled = navigationTreeNode.entitled;
    subscription.children = children.map((c: NavigationTreeNode) =>
      NavigationElementFactory.build(c)
    ) as NavigationElement[];
    subscription.type = navigationTreeNode.type;
    subscription.content = navigationTreeNode.content;
    subscription.container = navigationTreeNode.container;
    subscription.isCustom = navigationTreeNode.isCustom;
    return subscription;
  }

  static buildChannel(navigationTreeNode: NavigationTreeNode): Channel {
    if (!navigationTreeNode || navigationTreeNode.type !== NavigationObjectType.CHANNEL) {
      throw new NavigationElementMappingError('The selected node is not a Channel');
    }

    const children = navigationTreeNode.children || [];
    const subscriptionId = navigationTreeNode.subscriptionId;
    return {
      entitled: navigationTreeNode.entitled,
      id: navigationTreeNode.id || navigationTreeNode.sfId,
      name: navigationTreeNode.name,
      type: navigationTreeNode.type,
      children: children.map((c: NavigationTreeNode) => NavigationElementFactory.build(c)) as NavigationElement[],
      reports: navigationTreeNode.reports || [],
      content: navigationTreeNode.content,
      container: navigationTreeNode.container,
      reportCount: navigationTreeNode.reportCount || 0,
      isCustom: navigationTreeNode.isCustom || false,
      ...(subscriptionId && { subscriptionId }), // Set in NavigationService.getModuleChannelsByName
    };
  }

  static buildDashboard(navigationTreeNode: NavigationTreeNode): Dashboard {
    if (!navigationTreeNode || navigationTreeNode.type !== NavigationObjectType.DASHBOARD) {
      throw new NavigationElementMappingError('The selected node is not a Dashboard');
    }

    const children = navigationTreeNode.children || [];
    const subscriptionId = navigationTreeNode.subscriptionId;
    return {
      entitled: navigationTreeNode.entitled,
      channelSfId: navigationTreeNode.channelSfId,
      name: navigationTreeNode.name,
      type: navigationTreeNode.type,
      children: children.map((c: NavigationTreeNode) => NavigationElementFactory.build(c)) as NavigationElement[],
      reports: navigationTreeNode.reports || [],
      content: navigationTreeNode.content,
      container: navigationTreeNode.container,
      reportCount: navigationTreeNode.reportCount || 0,
      isCustom: navigationTreeNode.isCustom || false,
      ...(subscriptionId && { subscriptionId }), // Set in NavigationService.getModuleChannelsByName
      dashboardUrl: navigationTreeNode.dashboardEmbedString,
      dashboardId: navigationTreeNode.dashboardId,
    };
  }

  static buildReportModule(navigationTreeNode: NavigationTreeNode): ReportModule {
    if (!navigationTreeNode || navigationTreeNode.type !== NavigationObjectType.REPORT_MODULE) {
      throw new NavigationElementMappingError('The selected node is not a Report Module');
    }

    const children = navigationTreeNode.children || [];
    return {
      entitled: navigationTreeNode.entitled,
      id: navigationTreeNode.id || navigationTreeNode.sfId,
      name: navigationTreeNode.name,
      type: NavigationObjectType.REPORT_MODULE,
      children: children.map((c: NavigationTreeNode) => NavigationElementFactory.build(c)) as NavigationElement[],
      reports: navigationTreeNode.reports || [],
      content: navigationTreeNode.content,
      container: navigationTreeNode.container,
      reportCount: navigationTreeNode.reportCount || 0,
    };
  }

  static buildDatabaseTool(navigationTreeNode: NavigationTreeNode): DatabaseTools {
    if (!navigationTreeNode || navigationTreeNode.type !== NavigationObjectType.DATABASE_TOOLS) {
      throw new NavigationElementMappingError('The selected node is not a DATABASE_TOOLS');
    }
    return {
      database_tool_id: navigationTreeNode.database_tool_id,
      name: navigationTreeNode.name,
      type: navigationTreeNode.type,
      content: navigationTreeNode.content,
    };
  }

  static buildProductOverview(fileName: string, moduleName: string){
    const modulesWithSharedVerticals: Map<string, string> = new Map([
      [NavigationModules.REVERSE_ENGINEERING, 'REVERSE_ENGINEERING'],
      [NavigationModules.STRATEGY_ANALYTICS, 'STRATEGY_ANALYTICS']
    ]);
    const moduleId = modulesWithSharedVerticals.get(moduleName);
    return fileName?.split(',').find((fileNameDecoupled) => fileNameDecoupled.includes(moduleId)) ?? fileName;
  }

}
