<div class="related-content" *ngIf="includeRelatedContent && relatedContentItems && relatedContentItems.length">
    <h3>Related Content</h3>
    <div class="related-content-container">
        <ng-container *ngFor="let item of relatedContentItems">
            <div (click)="navigateTo(item)">
                <div class="related-content-body">
                    <div class="content-title">{{ item.reportTitle || item.blogTitle }}</div>
                    <div class="content-meta">
                        <span class="content-date" *ngIf="item.publishDate">{{ item.publishDate | date: 'MMM dd, YYYY'}}</span>
                        <span class="content-type" *ngIf="item.contentType">{{ item.contentType }}</span>
                    </div>
                </div>
                <em class="icon-external"></em>
            </div>
        </ng-container>
    </div>
</div>

