<div class="search-term-highlight">
  <input
    type="checkbox"
    id="search-term-highlight"
    name="search-term-highlight"
    (change)="onToggleHighlight($event)"
    [checked]="showHighlights"
  />
  <label for="search-term-highlight">Search Term Highlight</label>
</div>
