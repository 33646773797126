import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { environment } from '@env/environment';
import { AuthenticationService, User } from '@app/auth';
import { map } from 'rxjs/operators';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {

  private flagChange: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _ldClient: LDClient;

  constructor(private authService: AuthenticationService) {
    this._ldClient = this.initializeLdClient();
    this.flagChange.next({});
    this.authService.user$.subscribe((user) => {
      this.identifyUser(user);
    });
    this._ldClient.on('ready', () => {
      this.updateFlags();
    });
    this._ldClient.on('change', () => {
      this.updateFlags();
    });
    this._ldClient.on('failed', () => {
      const error: Error = new Error('Unable to connect to LaunchDarkly')
      datadogRum.addError(error);
    });
  }

  /**
   * Gets a single flag if the flag is not present it returns false
   * @param flagName name of the flag to be retrieved
   * @returns observable that emits the value of the flag
   */
  getFlag(flagName: string): Observable<boolean> {
    return this.flagChange.asObservable().pipe(map((flags) => flags[flagName] ?? false));
  }

  /**
   * Gets the map of flags asynchronously
   * @returns observable that emits the map of flags
   */
  getFlags(): Observable<LDFlagSet> {
    return this.flagChange.asObservable();
  }

  /**
   * Identifies the user to featureflag and sets up the first flags,
   * then keeps listening for changes.
   * @returns observable which fails if user could not be identified
   */
  identifyUser(user: User) {
    if (user) {
      this._ldClient.identify({
        key: btoa(user.username),
        email: user.email,
        name: user.username,
        anonymous: false,
      });
    }
  }

  /**
   * Updates the flag map with new flag values
   */
  private updateFlags() {
    this.flagChange.next(this._ldClient.allFlags());
  }

  /**
   * Initializes the ld client with the required settings
   * @returns the instance of the LDClient created
   */
  private initializeLdClient(): LDClient {
    return initialize(environment.featureFlagClientId, {
      kind: 'user',
      key: environment.featureFlagSDKKey,
      anonymous: true,
    },
    {
      streamUrl: environment.relayProxyBaseUrl,
      baseUrl: environment.relayProxyBaseUrl,
      eventsUrl: environment.relayProxyBaseUrl,
    });
  }

}
