import {
  ActionReducerMap,
  createAction,
  createFeatureSelector,
  MetaReducer
} from '@ngrx/store';
import {
  initialWorkspaceState,
  workspaceReducer,
  WorkspaceState
} from '@app/store/Reducers/workspace/workspace.reducer';
import {
  initialNotificationsState,
  notificationsReducer,
  NotificationsState
} from '@app/store/Reducers/notification/notifications.reducer';
import * as fromRouter from '@ngrx/router-store';
import {
  applicationHealthReducer,
  ApplicationHealthState,
  initialApplicationHealthState
} from '@app/store/Reducers/application-health/application-health.reducer';
import {
  initialSearchState,
  searchReducer,
  SearchState
} from '@app/store/Reducers/search/search.reducer';
import {
  initialSettingsState,
  settingsReducer,
  SettingsState
} from 'src/app/store/Reducers/settings/settings.reducer';

export const empty_action = createAction('[Application] Empty action - Ignore');

export interface AppState {
  router: fromRouter.RouterReducerState<any>;
  workspaceState: WorkspaceState;
  notificationsState: NotificationsState;
  applicationHealthState: ApplicationHealthState;
  searchState: SearchState;
  settingsState: SettingsState;
}

export const selectShellState = (state: AppState) => state;

export const sharedReducers: ActionReducerMap<AppState> = {
  router: fromRouter.routerReducer,
  workspaceState: workspaceReducer,
  notificationsState: notificationsReducer,
  applicationHealthState: applicationHealthReducer,
  searchState: searchReducer,
  settingsState: settingsReducer
};

export const initialAppState: AppState = {
  router: null,
  workspaceState: initialWorkspaceState,
  notificationsState: initialNotificationsState,
  applicationHealthState: initialApplicationHealthState,
  searchState: initialSearchState,
  settingsState: initialSettingsState
}

export const selectRouter = createFeatureSelector<AppState, fromRouter.RouterReducerState<any>>('router');

export const {
  selectCurrentRoute, // select the current route
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getRouterSelectors(selectRouter);

export const metaReducers: MetaReducer<AppState>[] = [];
