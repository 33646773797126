import { Component, HostBinding, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Video } from '@app/@shared/models/market-analysis/market-analysis';
import { NavigationModules } from '@app/@shared/models/navigation/navigation.models';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';
import { RouteHelper } from '@app/@shared/utils/route-helper';

@Component({
  selector: 'app-tabbed-videos-list',
  templateUrl: './tabbed-videos-list.component.html',
  styleUrls: ['./tabbed-videos-list.component.scss']
})

export class TabbedVideosListComponent {
  @HostBinding('class') class = 'scrollbar';
  @Input() videos: Video[];
  @Input() moduleName: NavigationModules;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public entitlementService: EntitlementService,
  ) { }

  onClickVideo(video: Video) {
    if (video.entitled) {
      const fragment = `subscription=${encodeURIComponent(video.subscriptionName || video.channelName)}&name=${encodeURIComponent(this.moduleName)}`;
      this.router.navigateByUrl(this.router.createUrlTree(['video-viewer', video.id], {
        fragment: fragment.toString(),
        // if we are not in a module (which shouldn't happen)
        // we go to search module which has a video-viewer route
        relativeTo: RouteHelper.getModuleRouteOrDefault(this.route, 'search'),
      }));
    } else {
      const videoTitle = `Video: ${video.channelName}:${video.title}`;
      this.entitlementService.openRequestAccessConfirmDialog(videoTitle);
    }
  }
}
