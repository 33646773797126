import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-calibration-instructions-modal',
  templateUrl: './calibration-instructions-modal.component.html',
  styleUrls: ['./calibration-instructions-modal.component.scss']
})
export class CalibrationInstructionsModalComponent {
  constructor(public modal: NgbActiveModal) {}
}
