<ng-container *ngFor="let item of menuItems">
  <div class="menu" (mouseenter)="currentlyOpenMenu = item.name" (mouseleave)="closeMenu()">
    <a
      class="main-menu-header"
      [attr.href]="item.link? basePath + item.link : null"
      [class.active]="currentlyOpenMenu? currentlyOpenMenu === item.name : item.active"
      [class.disabled]="!item.link"
      [attr._ti_menu_item]="item.id"
      (click)="$event.preventDefault(); onClickMenuItem(item.link, item.name, true)"
    >
      <span *ngIf="item.name !== '...'" class="body-text">{{ item.name }}</span>
      <em *ngIf="item.name === '...'" class="icon-more1"></em>
      <em *ngIf="hasMenu(item)" class="icon icon-down_small"></em>
    </a>
    <section class="dropdown" [class.dropdown-active]="currentlyOpenMenu === item.name && hasMenu(item)">
      <div *ngIf="item.primaryMenu?.length > 0" class="menu-list">
        <ng-container *ngFor="let primaryMenu of item.primaryMenu">
          <div class="secondary-header pretitle-text">{{ primaryMenu.header }}</div>
          <a
            *ngFor="let primaryItem of primaryMenu.items"
            class="menu-item"
            [href]="basePath + primaryItem.link"
            [attr._ti_menu_item]="primaryItem.id"
            (click)="$event.preventDefault(); onClickMenuItem(primaryItem.link, item.name); closeMenu()"
          >
            <span class="item-text body-text">{{ primaryItem.name }}</span>
          </a>
        </ng-container>
      </div>
      <div *ngIf="item.secondaryMenu?.length > 0" class="menu-list secondary-menu-list">
        <ng-container *ngFor="let secondaryMenu of item.secondaryMenu">
          <div class="secondary-header pretitle-text">{{ secondaryMenu.header }}</div>
          <a
            *ngFor="let secondaryItem of secondaryMenu.items"
            class="menu-item"
            [href]="basePath + secondaryItem.link"
            [attr._ti_menu_item]="secondaryItem.id"
            (click)="$event.preventDefault(); onClickMenuItem(secondaryItem.link, item.name); closeMenu();"
          >
            <span class="item-text body-text">{{ secondaryItem.name }}</span>
          </a>
        </ng-container>
      </div>
    </section>
  </div>
</ng-container>
