<div class="not-found-container">
  <div class="logo">
    <img src="../../../../assets/images/logo-stacked-color.svg" alt="TechInsights"/>
  </div>
  <ng-lottie
    (animationCreated)="animationCreated($event)"
    [options]="options"
    height="calc(100vh - 308px)"
    width="calc(100vw - 308px)"
  ></ng-lottie>
  <h1>Something went wrong.</h1>
  <button class="button button-primary" (click)="goBack()">
    <em class="icon-left_large"></em>
    Take me Home
  </button>
</div>
