<div [ngClass]="amount > 0 ? 'generic-panel-visible' : 'generic-panel-hidden'">
  <label class="it-checkbox">
    <input
      type="checkbox"
      [disabled]="!actions?.length || !totalSize"
      [checked]="amount === totalSize && totalSize > 0"
      [indeterminate]="0 < amount && amount < totalSize"
      (change)="selectAll($event)"
    />
    <div></div>
  </label>
  <ng-container *ngIf="amount > 0">
    <div class="selected-items">{{ amount }} Selected</div>
    <button
      *ngFor="let action of actions"
      class="action-button"
      (click)="onClickAction(action)"
    >
      <em [class]="action.icon"></em> {{action.name}}
    </button>
  </ng-container>
</div>
