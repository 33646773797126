/* eslint-disable no-console */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, defaultIfEmpty, filter, map, scan, switchMap } from 'rxjs/operators';
import { MetaService } from '../meta.service';
import { AnnotationService } from '../annotation/annotation.service';
import { RecentViewTable, RecentViewTableRow } from '@app/@shared/models/user-view-history/recent-view-table.model';
import { UserViewHistoryEvent } from '@app/@shared/models/user-view-history/user-view-history-event';
import { UserViewHistoryItemType } from '@app/@shared/models/user-view-history/user-view-history-items';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  ContentNavigatorTable,
  ContentNavigatorTablePage,
  ContentNavigatorTableRow,
} from '@app/@shared/models/content-navigator/content-navigator.model';

@Injectable({
  providedIn: 'root',
})
export class RecentViewHistoryService {
  private baseUrl = environment.userListServiceBaseUrl;

  constructor(
    private httpClient: HttpClient,
    private metaService: MetaService,
    private annotationService: AnnotationService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  getRecentViewHistory(currentPage?: number, pageSize?: number): Observable<RecentViewTable> {
    const url = `${this.baseUrl}/history/search`;
    const headers = new HttpHeaders();
    const body = { size: pageSize, from: (currentPage - 1) * pageSize };

    return this.httpClient.post<any>(url, body, { headers }).pipe(
      switchMap((response: any) =>
        forkJoin(
          response.items.map((item: any) =>
            this.mapHistoryData(item).pipe(
              catchError(() => EMPTY),
              scan((acc, row) => [...acc, row], [])
            )
          )
        ).pipe(
          defaultIfEmpty([]),
          map((result: RecentViewTableRow[][]) => ({
            rows: [].concat(...result),
            count: response.totalItems,
          }))
        )
      )
    );
  }

  getRecommendations(currentPage?: number, pageSize?: number): Observable<ContentNavigatorTablePage> {
    const url = `${this.baseUrl}/recommendations/search`;
    const headers = new HttpHeaders();
    const body = { size: pageSize, from: (currentPage - 1) * pageSize };

    return this.httpClient.post<any>(url, body, { headers }).pipe(
      defaultIfEmpty([]),
      map((result: any) => ({
        table: {  rows: [].concat(...result.items.map((item: any) => ({
          title: item.name,
          productCode: item.code,
          completionDate: item.publishDate,
          ...item
        }))) },
        count: result.totalItems,
        filters: [],
      }))
    );
  }

  postRecentViewHistory(payload: UserViewHistoryEvent): Observable<void> {
    const url = `${this.baseUrl}/history`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.post<any>(url, payload, { headers });
  }

  private mapHistoryData(item: any): Observable<RecentViewTableRow> {
    if (!item.entitled) {
      return of({
        id: item.payload?.reportCode ?? item.id,
        title: item.itemName,
        type: item.itemType,
        icon: this.defineIcon(item.itemType),
        publishDate: item.itemPublicationDate,
        viewDate: item.lastViewTimestamp,
        url: '/404',
        entitled: item.entitled,
        annotated: false,
      });
    }

    switch (item.itemType) {
      case UserViewHistoryItemType.PDF:
        return this.mapPdfHistoryData(item);
      case UserViewHistoryItemType.BLOG:
        return of({
          id: item.payload.reportCode,
          title: item.itemName,
          type: item.itemType,
          icon: this.defineIcon(item.itemType),
          publishDate: item.itemPublicationDate,
          viewDate: item.lastViewTimestamp,
          url: this.sanitizer.sanitize(SecurityContext.URL, item.payload.url),
          entitled: item.entitled,
          annotated: item.payload.isAnnotated,
        });
    }
  }

  private mapPdfHistoryData(item: any): Observable<RecentViewTableRow> {
    switch (item.payload.container) {
      case 'device-details':
        return this.mapDeviceDetailsItemToRow(item);
      case 'pdf-viewer':
        return this.mapPdfViewerItemToRow(item);
      case 'analysis-view':
        return this.mapAnalysisViewItemToRow(item);
      default:
        return EMPTY;
    }
  }

  private mapDeviceDetailsItemToRow(item: any): Observable<RecentViewTableRow> {
    if (!item.payload.reportCode) {
      return EMPTY;
    }

    return of({
      id: item.payload.reportCode,
      title: item.itemName,
      type: item.itemType,
      icon: this.defineIcon(item.itemType),
      publishDate: item.itemPublicationDate,
      viewDate: item.lastViewTimestamp,
      url: this.router.serializeUrl(
        this.router.createUrlTree([item.moduleId, 'device-details'], {
          queryParams: {
            tab: 'reports',
            id: item.payload.reportCode,
            genealogyCode: item.payload.genealogyCode,
          },
        })
      ),
      entitled: item.entitled,
      annotated: false,
    });
  }

  private mapPdfViewerItemToRow(item: any): Observable<RecentViewTableRow> {
    return forkJoin([this.annotationService.getAnnotations(item.itemId), this.metaService.getAsset(item.itemId)]).pipe(
      filter(([_, asset]) => Boolean(asset.contentLakeObjectId)),
      map(([annotations, asset]) => {
        return {
          id: item.payload.reportCode,
          title: item.itemName,
          type: item.itemType,
          icon: this.defineIcon(item.itemType),
          publishDate: item.itemPublicationDate,
          viewDate: item.lastViewTimestamp,
          url: this.router.serializeUrl(
            this.router.createUrlTree(
              [item.moduleId, item.payload.reportCode, 'pdf-viewer', asset.contentLakeObjectId],
              {
                fragment: `report=${encodeURIComponent(item.itemName)}`,
              }
            )
          ),
          entitled: item.entitled,
          annotated: annotations?.length > 0,
        };
      })
    );
  }

  private mapAnalysisViewItemToRow(item: any): Observable<RecentViewTableRow> {
    return forkJoin([this.annotationService.getAnnotations(item.itemId), this.metaService.getAsset(item.itemId)]).pipe(
      filter(([_, asset]) => Boolean(asset.contentLakeObjectId)),
      map(([annotations, asset]) => {
        return {
          id: item.payload.reportCode,
          title: item.itemName,
          type: item.itemType,
          icon: this.defineIcon(item.itemType),
          publishDate: item.itemPublicationDate,
          viewDate: item.lastViewTimestamp,
          url: this.router.serializeUrl(
            this.router.createUrlTree(
              [
                item.moduleId,
                ...(item.moduleId === 'mcclean' ? [item.channelSfId] : []),
                'analysis-view',
                item.payload.reportCode,
              ],
              {
                fragment: `sidebar=false&pdfId=${encodeURIComponent(asset.contentLakeObjectId)}`,
              }
            )
          ),
          entitled: item.entitled,
          annotated: annotations?.length > 0,
        };
      })
    );
  }

  private defineIcon(type: UserViewHistoryItemType) {
    switch (type) {
      case UserViewHistoryItemType.PDF:
      case UserViewHistoryItemType.BLOG:
      default:
        return {
          style: 'icon-document',
          label: 'Report',
        };
    }
  }
}
