import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import {Observable, of, throwError} from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {map, tap} from 'rxjs/operators';
import {CONTENT_TYPE_ENUM} from '@shared/models/search';
import {Thumbnail} from '@shared/models/thumbnail';

@Injectable({
  providedIn: 'root'
})
export class ThumbnailsService {

  constructor(private http: HttpClient,
              private sanitizer: DomSanitizer) {
  }

  buildQueryParamsUrlForGenealogyCode(genealogyCode: string, height: number, width: number): Observable<SafeUrl> {
    if(!genealogyCode) {
      return throwError('Empty genealogyCode id')
    }
    const queryParams = new HttpParams()
      .set('genealogyCode', genealogyCode)
      .set('height', `${height}`)
      .set('width', `${width}`);
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const url = `${environment.thumbnailService}/thumbnail/featured-image?genealogy`;

    return this.http.get(url, { headers, params: queryParams })
      .pipe(map((response: any) => this.sanitizer.bypassSecurityTrustUrl(response.url)));
  }

  buildThumbnailServiceBulkRequestForObjectIds(CLImageList: any[], height: number, width: number)
    : Observable<Thumbnail[]>
  {
    const url = `${environment.thumbnailService}/thumbnail/objects/bulk`;
    const objects_ids = CLImageList.map( (image) => image.objectId);

    const body = {
      objects_ids,
      height,
      width
    };

    return this.http.post<any>(url, body);
  }

  /**
   * Builds thumbnail safe url for CL object
   * @param objectId content lake object id
   * @param height desired height
   * @param width desired width
   */
  buildThumbnailUrlForObjectId(objectId: string, height: number, width: number): Observable<SafeUrl> {
    return this.http
      .get(`${environment.thumbnailService}/thumbnail/${objectId}/${height}x${width}`
      ).pipe(map((v: any) => this.sanitizer.bypassSecurityTrustUrl(v.url)));
  }

  getThumbnailForSize(objectId: string, height: number, width: number): Observable<Thumbnail> {
    const url = `${environment.thumbnailService}/thumbnail/${objectId}/${height}x${width}`
    return this.http.get<Thumbnail>(url)
  }

  /**
   * Request a tiff image to be converted by thumbnail service to a png image
   * @param objectId the image to convert
   */
  getConvertedImageUrlForObjectId(objectId: string): Observable<any> {
    return this.http.get(`${environment.thumbnailService}/convert/png/${objectId}`)
      .pipe(
        map((v: any) => { return {
          url: v.url,
          url_expires: v.expiration
        } })
      );
  }

  getDefaultReportThumbnail(height: number, width: number): Observable<SafeUrl> {
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl('../../assets/images/placeholder/report.svg');
    return of(safeUrl);
  }

  getDefaultImage(type: string): string {
    switch (type) {
      case CONTENT_TYPE_ENUM.IMAGE.toString():
        return 'assets/images/placeholder/image.svg';
      case CONTENT_TYPE_ENUM.SCHEMATIC.toString():
        return 'assets/images/placeholder/schematic-light.svg';
      case CONTENT_TYPE_ENUM.PRODUCT.toString():
        return 'assets/images/placeholder/product.svg';
      case CONTENT_TYPE_ENUM.COMPONENT.toString():
        return 'assets/images/placeholder/component.svg';
      case CONTENT_TYPE_ENUM.DIE.toString():
        return 'assets/images/placeholder/die.svg';
      case CONTENT_TYPE_ENUM.REPORT.toString():
        return 'assets/images/placeholder/report.svg';
      default:
        return 'assets/images/placeholder/component.svg';
    }
  }
  getDeviceDetailsImage(type: string): SafeUrl{
    switch (type) {
      case 'MT_PRODUCT':
        return this.sanitizer.bypassSecurityTrustUrl('assets/images/placeholder/product.svg');
      case 'MT_COMPONENT':
        return this.sanitizer.bypassSecurityTrustUrl('assets/images/placeholder/component.svg');
      case 'MT_SCHEMATIC':
        return this.sanitizer.bypassSecurityTrustUrl('assets/images/placeholder/schematic-light.svg');
      case 'MT_DIE2':
        return this.sanitizer.bypassSecurityTrustUrl('assets/images/placeholder/die-alt.svg');
      case 'MT_IMAGE':
        return this.sanitizer.bypassSecurityTrustUrl('assets/images/placeholder/image.svg');
      case 'MT_REPORT':
        return this.sanitizer.bypassSecurityTrustUrl('assets/images/placeholder/report.svg');
      default:
        return this.sanitizer.bypassSecurityTrustUrl('assets/images/placeholder/component.svg');
    }
  }
}
