<app-title-bar
  *ngIf="analysis"
  [title]="analysis.name"
  [backButtonTitle]="'Exit Analysis'"
  [showBackButton]="!!prevPageUrl"
  (clickBack)="onClickBack()"
>
  <div class="meatball-menu-container">
    <app-meatball-menu>
      <button (click)="onClickOpenShareModal()">
        <em class="icon-share"></em>Share
      </button>
      <button (click)="onClickAddToFavorites()">
        <em class="icon-star"></em> Add to Favorites
      </button>
    </app-meatball-menu>
  </div>
</app-title-bar>

<app-notification-banner *ngIf="isInProgress"
  [analysis]="analysis"
  [unknowCondition]="unknowCondition"
></app-notification-banner>

<div class="tab-bar" [class.hideArea] = "!initialLoadComplete">
  <div class="tab-list-container">
    <a *ngFor="let tab of visibleTabList"
        class="tab-item"
        (click)="!tab.isActive && changeTab(tab.path)"
        [class.active]="tab.isActive && !showInProgressMessage"
        [class.disabled-tab]="showInProgressMessage || tab.isDisabled"
    >
      <em class="icon {{ tab.icon }}"></em>
      <div class="tab-title">{{ tab.title }}</div>
    </a>
  </div>
</div>
<div class="tab-bar" [class.hideArea] = "!initialLoadComplete">
  <button
    class="sidebar-toggle"
    (click)="onSidebarToggleClick()"
    [class.sidebar-opened]="sidebarOpen[activeTab]"
  >
    <em
      class="icon"
      [class.icon-sidebar_open]="!sidebarOpen[activeTab]"
      [class.icon-sidebar_close]="sidebarOpen[activeTab]"
    ></em>
        <div>{{ sidebarToggleTitle }}</div>
  </button>
</div>
<div class="area-container" [class.hideArea] = "!initialLoadComplete">
    <app-document-content
      [hidden]="activeTab !== 'documents'"
      [sidebarOpen]="documentAssetviewer !== 'report-list-view'
        ? this.sidebarViewerPanel.documents : sidebarOpen.documents"
      [currentAnalysis]="analysis"
      [authors]="authors"
      [analysisReports]="documentReportsPage"
      [annotatedDocuments]="annotatedDocuments"
      [paginationParameters]="paginationParameters"
      [searchTerm]="assetGroupId"
      [showInProgressMessage]="showInProgressMessage"
      [isLoading]="loading"
      [channelNames]="(channelNames$ | async)"
      [documentAssetviewer]="documentAssetviewer"
      [analysisCode]="analysisCode"
      [selectedReportId]="selectedReportId"
      [selectedAssetId]="selectedAssetId"
      [channelId]="channelId"
      [subscriptionId]="subscriptionId"
      [relatedContentList]="relatedContentList"
      (changePaginationParameters)="onChangePaginationParameters($event)"
      (clickDocument)="onClickDocument($event, analysis)"
      (downloadAssetGroup)="onDownloadAssetGroup($event, analysis)"
      (documentNewTab)="onDocumentNewTab($event, analysis)"
      (documentShare)="onDocumentShare($event, analysis)"
      (clickPVO)="onClickPVO()"
      (clickRequest)="onClickRequest()"
      (closePdfViewer)="onClosePdfViewer()"
      (closeAssetViewer)="onCloseAssetViewer()"
    ></app-document-content>
    <app-image-content
      [hidden]="activeTab !== 'images'"
      [sidebarOpen]="showImageViewer ? this.sidebarViewerPanel.images : sidebarOpen.images"
      [currentAnalysis]="analysis"
      [analysisReports]="imagesReportsPage"
      [imageFilters]="imageFilters"
      [paginationParameters]="paginationParameters"
      [searchTerm]="assetGroupId"
      [showImageViewer]="showImageViewer"
      [selectedImage]="selectedImage"
      [isLoading]="loading"
      [channelId]="channelId"
      [subscriptionId]="subscriptionId"
      [downloadEnabled]="imageDownloadEnabled"
      [addToFavoritesEnabled]="true"
      [enableUnwatermarkedImageRequest]="enableUnwatermarkedImageRequest"
      (changeSelectedImage)="onChangeSelectedImage($event)"
      (closeImageViewer)="onCloseImageViewer()"
      (changePaginationParameters)="onChangePaginationParameters($event)"
      (changeFilter)="onChangeImageFilter($event)"
      (downloadImage)="onDownloadImage($event)"
      (downloadImages)="onDownloadImages($event)"
      (addToFavorites)="onAddImagesToFavorites($event)"
      (shareImage)="onShareImage($event, analysis.entitled)"
    ></app-image-content>
    <app-kimera-grid-cv-content
      [hidden]="activeTab !== 'circuitvision'"
      [analysisCode] = "analysisCode"
      [webCVContentLakeId] = "webCVContentLakeId"
      [webCVJSON] = "webCVJSON"
      [sidebarOpen]="sidebarOpen.circuitvision"
    ></app-kimera-grid-cv-content>
</div>
