import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { ChannelCard } from '../../models/shared.models';

// Duplicated in subscription-card-list.component.ts
const MAX_CARD_COLUMNS = 3;

@Component({
  selector: 'app-channels-card-list',
  templateUrl: './channels-card-list.component.html',
  styleUrls: ['./channels-card-list.component.scss'],
})
export class ChannelsCardListComponent {
  @Input() channels: Array<ChannelCard>;
  @Input() showSubtitle = true;

  @Output() clickChannel: EventEmitter<ChannelCard> = new EventEmitter<ChannelCard>();

  public readonly maxCardColumns = MAX_CARD_COLUMNS;

  constructor(private ffService: FeatureFlagService) { }

  public onClickChannel(channel: ChannelCard): void {
    this.clickChannel.emit(channel);
  }

  public channelEntitled(channel: ChannelCard) {
    return this.ffService
      .getFlag(environment.flexEntitlementFlag)
      .pipe(map((flag) => (flag ? channel.entitled || (channel.accessible ?? false) : channel.entitled)));
  }

}
