import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { APIEnum, getAffectedAPI } from '@app/store/Models/application-health/models';
import { notifyRecoverableError } from '@app/store/Actions/application-health/application-health.actions';
import { environment } from '@env/environment';
import { Logger} from '../logger.service';
import { AuthenticationService } from 'src/app/auth';
import { DeepLinkingService } from '@shared/services/deep-linking.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private deepLinkingService: DeepLinkingService,
    private store: Store,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    const status: number = (response as any).status;
    const affectedAPI = getAffectedAPI((response as any).url);
    const url = (response as any).url;
    if (!environment.production && affectedAPI !== APIEnum.THUMBNAILSERVICE) {
      // Do something with the error
      log.error('Request error', response);
    }
    if (
      (affectedAPI !== APIEnum.AUTHENTICATION || (affectedAPI === APIEnum.AUTHENTICATION && !url.includes('/me')))
      && status === 401
    ) {
      this.deepLinkingService.setRedirectionUrl(this.router.url);
      this.authenticationService.clearTokenAndReroute();
      throw response;
    }
    // Thumbnail service and any other unlisted api, or a client side error that's not a 404 will be ignored
    if (
      (affectedAPI !== APIEnum.UNLISTED && affectedAPI !== APIEnum.THUMBNAILSERVICE)
      && (status >= 500 || status === 404)
    ) {
      this.store.dispatch(notifyRecoverableError({
        error: {
          httpResponseCode: (response as any).status,
          affectedAPI: getAffectedAPI((response as any).url),
          error: JSON.stringify(response),
        }
      }))
    }
    throw response;
  }

}
