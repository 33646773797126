import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractSubscriptionContentsComponent } from '@app/@shared/components/abstract/abstract-subscription-contents/abstract-subscription-contents.component';
import { MegaMenu, MenuItem } from '@app/@shared/models/navigation/navigation.models';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import { TelemetryService } from '@app/@shared/services/telemetry-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-nav-mega-menu-compact',
  templateUrl: './nav-mega-menu-compact.component.html',
  styleUrls: ['./nav-mega-menu-compact.component.scss']
})
export class NavMegaMenuCompactComponent extends AbstractSubscriptionContentsComponent implements OnInit {
  @Input() getMegaMenu: () => Observable<MegaMenu[]>;
  @Output() menuItemClicked = new EventEmitter<any>();
  @Output() searchExecuted = new EventEmitter<any>();
  megaMenu: MegaMenu[] = [];
  activeMenu: string;

  constructor(
    protected navigationService: NavigationService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected telemetryService: TelemetryService
  ) {
    super(route, router, navigationService, telemetryService);
    this.getMegaMenu = this.getMegaMenu ?? this.navigationService.getMobileMegaMenu.bind(this.navigationService);
  }

  ngOnInit(): void {
    this.getMegaMenu().subscribe((megaMenu) => {
      this.megaMenu = megaMenu;
    });
  }

  onClickMenu(menu: MenuItem): void {
   if(menu.link) {
    this.router.navigateByUrl(menu.link);
    this.menuItemClicked.emit();
   } else {
    this.toggleActiveMenu(menu.name);
   }
  }

  onClickMenuItem(subMenu: MenuItem): void {
    this.menuItemClicked.emit();
    this.router.navigateByUrl(subMenu.link);
  }

  override onChangeSearchTerms(term: string) {
    if(term.length) {
      this.router.navigate(['search/result/ALL'], {
        queryParams: { q: term, searchKey: 'main-search' },
        preserveFragment: true,
        queryParamsHandling: 'merge',
      });
      this.searchExecuted.emit();
    }
  }

  protected requestContent(): any {};
  protected getContext(): any {};

  private toggleActiveMenu(menu: string): void {
    if (this.activeMenu === menu) {
      this.activeMenu = '';
    } else {
      this.activeMenu = menu;
    }
  }
}
