import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {Dashboard} from '@shared/models/navigation/navigation.models';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsChannelsService {
  getAnalyticsChannel(channelId: string, channels: Dashboard[]): Observable<Dashboard> {
    return of(channels.find((channel) => channel.id === channelId));
  }
}
