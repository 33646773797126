import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ThumbnailsService } from '@app/@shared/services/thumbnails/thumbnails.service';
import { Observable, of } from 'rxjs';

/**
 * This is a pipe that builds the thumbnailUrl for a given objectId and size in pixels.
 * It uses the ThumbnailsService to build the url and must be followed by async pipe.
 *
 * @example
 * <img [src]="objectId | thumbnail : 40 : 40 | async">
 */
@Pipe({
  name: 'thumbnail',
})
export class ThumbnailPipe implements PipeTransform {
  constructor(private thumbnailsService: ThumbnailsService) {}

  transform(objectId: string, height: number = 40, width: number = 40): Observable<SafeUrl> {
    return objectId ? this.thumbnailsService.buildThumbnailUrlForObjectId(objectId, height, width) : of();
  }
}
