<ng-container [ngSwitch]="requestImageState">
  <ng-container *ngSwitchCase="'request'">
    <div class="main-container scrollbar">
      <div class="modal-header">
        <h3>Request an Unwatermarked Image</h3>
        <button
          type="button"
          class="close icon-close_medium"
          aria-label="Close"
          (click)="modalDismiss('Cross click')">
        </button>
      </div>
      <div class="body">
        <div class="description">
          This request will be submitted to our Customer Engagement team. If approved, they will contact you and deliver
          a copy of this image with watermarks removed, that you can employ for internal use only. Please allow a few
          business days for this process to complete.
        </div>
        <form [formGroup]="requestAccessFormModal">
          <div>
            <app-input [props]="emailProps" formControlName="email"></app-input>
            <app-input [props]="imageTitleInputProps" formControlName="title"></app-input>
            <app-text-area [props]="optionalMessageProps" formControlName="message"></app-text-area>
          </div>
          <div class="modal-footer" [style]="{ 'padding-right': 0 }">
            <button type="button" class="btn-modal-secondary" (click)="modalClose('Request is cancelled')">Cancel</button>
            <button type="submit" class="btn-modal-primary" (click)="onModalRequest()">Submit Image Request</button>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'success'">
    <div class="main-container">
      <div class="modal-header">
        <h3>Unwatermarked Image Request Sent!</h3>
        <button
          type="button"
          class="close icon-close_medium"
          aria-label="Close"
          (click)="modalDismiss('Cross click')">
        </button>
      </div>
      <div class="body success">
        Our team has received your request. If approved, they will contact you within a few business days and deliver
        a copy of this image with watermarks removed.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-modal-primary" (click)="modalClose('Request is cancelled')">Done</button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'error'">
      <div class="main-container">
        <div class="modal-header">
          <h3><em class="icon-warning"></em>Oops... Something went wrong</h3>
          <button
            type="button"
            class="close icon-close_medium"
            aria-label="Close"
            (click)="modalDismiss('Cross click')">
          </button>
        </div>
        <div class="body error">
          An error has occurred while creating your download package.<br/>
          Please close this window and try again. If this issue persists, please<br/> <a class="link" routerLink="/">contact support</a>.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn-modal-secondary" (click)="modalClose('Request is cancelled')">Close</button>
        </div>
      </div>
  </ng-container>
</ng-container>
