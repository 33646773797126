<div
  class="row-container header-container img-doc-columns"
  [class.custom-columns]="subscription.isCustom"
>
  <app-generic-panel
    [actions]="panelActions"
    [amount]="selectedRows.length"
    [totalSize]="table?.rows?.length"
    (allSelected)="onAllRowsSelected($event)"
    (clickAction)="onClickPanelAction($event)"
  ></app-generic-panel>
  <div class="header"></div>
  <div class="header">Title</div>
  <div class="header center" *ngIf="!subscription.isCustom">Subscription</div>
  <div class="header" *ngIf="!subscription.isCustom">Channel</div>
  <div class="header" *ngIf="subscription.isCustom">Product Code</div>
  <div class="header">Date</div>
  <div
    class="header center"
    *ngIf="showDocumentsCount"
  >Documents</div>
  <div class="header center" *ngIf="!showImagesCount">Images</div>
  <div class="header center"></div>
</div>
<ng-container *ngIf="!showNoResults && table">
  <a
    *ngFor="let row of table.rows"
    class="row-container img-doc-columns"
    [href]="createRowUrl(row)"
    [class.heap-non-entitled-report]="!row.entitled"
    [class.custom-columns]="subscription.isCustom"
    [attr._ti_content_navigator]="row.id"
    (click)="onClickRow(row, $event)"

  >
    <div class="it-checkbox-wrapper">
      <label class="it-checkbox" (click)="$event.stopPropagation()">
        <input
          type="checkbox"
          [checked]="!!row.selected"
          [disabled]="panelActions.length === 0"
          (change)="onSelectRow($event, row)"
        />
        <div></div>
      </label>
    </div>
    <div class="it-lock-wrapper">
      <ng-container *ngIf="!row.entitled; else entitled">
        <em class="icon small icon-locked"></em>
      </ng-container>
      <ng-template #entitled>
        <em
          *ngIf="isAnnotationReport(row.productCode)"
          class="icon small icon-edit1"
          ngbTooltip="Document contains annotations"
        ></em>
        </ng-template>
    </div>
    <div>
      <div
        class="emphasis truncate-paragraph-ellipsis line-clamp-2"
        [innerHTML]="row.title"
      ></div>
      <div
        class="description truncate-paragraph-ellipsis line-clamp-2"
        [innerHTML]="row.description"
      ></div>
    </div>
    <div class="center" *ngIf="!subscription.isCustom"><span [class]="row.subscription" class="icon" [ngbTooltip]="row.subscriptionName" container="body"></span></div>
    <div *ngIf="!subscription.isCustom">{{ row.channel}}</div>
    <div *ngIf="subscription.isCustom">
      {{ row.productCode }}
    </div>
    <div>
      {{ row.completionDate? (row.completionDate |date: 'MMM d, yyyy' : '+0000') : 'In progress'}}
    </div>
    <div
      class="asset-count center indicator-content"
      [class.disabled]="!row.entitled || row.documentsCount === 0"
      *ngIf="showDocumentsCount"
      (click)="onClickFileCounter($event, row)"
    >
      <span
        class="icon-document icon small indicator"
        [ngbTooltip]="(row.entitled && !(parseInt(row.documentsCount) >= 0)) ? 'Item count unavailable' : ''"
      ></span>
      <span class="indicator">{{ (parseInt(row.documentsCount)&gt;=0) ? row.documentsCount : '!' }}</span>
    </div>
    <div class="asset-count center indicator-content" *ngIf="!showImagesCount">
      <span class="icon-image icon small" ></span>
      <span>{{ row.imagesCount }}</span>
    </div>
    <div class="center meatball-wrapper">
      <app-meatball-menu>
        <button (click)="onClickOpenNewTab($event, row)">
          <em class="icon-external"></em> Open in new tab
        </button>
        <button (click)="onClickShare($event, row)">
          <em class="icon-share"></em> Share
        </button>
        <button (click)="onClickAddToFavorites($event, row)">
          <em class="icon-star"></em> Add to Favorites
        </button>
      </app-meatball-menu>
    </div>
  </a>
</ng-container>
<div *ngIf="showNoResults" class="no-results">
  <img src="assets/images/icons/Icon_Null-Search.svg" alt="No results."/>
  <div class="text">
    <span class="main-title">
      Your search query did not find any matches.
    </span>
    <span class="sub-title">
      Try adjusting your search terms and filter settings.
    </span>
  </div>
</div>
