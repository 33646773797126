import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { DeepLinkingService } from '@app/@shared/services/deep-linking.service';

@Injectable({
  providedIn: 'root'
})
export class NonEntitledInterceptor implements HttpInterceptor {

  constructor(private router: Router, private deepLinkingService: DeepLinkingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((error) => this.handleError(error)));
  }


  private handleError(error: HttpEvent<any>): Observable<HttpEvent<any>> {
    const {url, status} = error as any;
    const redirectedUrls = environment.nonEntitledRedirect;
    if(redirectedUrls.some((value) => url.includes(value)) && status === 403) {
      this.deepLinkingService.redirectTo403()
    }
    throw error;
  }
}
