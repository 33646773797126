<div class="modal-container"  *ngIf="onboardingForm">
  <form [formGroup]="onboardingForm">
    <div class="modal-header">
      <span class="title">Welcome to TechInsights</span>
      <span class="subtitle">Complete this form to continue</span>
    </div>
    <div class="modal-body">
      <div class="input-container">
        <app-input [props]="firstNameProps" formControlName="firstName" (blurInput)="onBlurControls('firstName', false)"></app-input>
        <app-input [props]="lastNameProps" formControlName="lastName" (blurInput)="onBlurControls('lastName', false)"></app-input>
        <app-input [props]="companyProps" formControlName="company" (blurInput)="onBlurControls('company', false)"></app-input>
        <it-select
          *ngIf="departments"
          [placeholder]="departmentsProps.placeholder"
          [label]="departmentsProps.label"
          [options]="departments"
          [initialOption]="initialSelectionOption"
          [editable]="true"
          [upward]="false"
          (changeOption)="onChangeDepartmentFunction($event)"
          (blurInput)="onBlurSelects('departments', true)"></it-select>
        <app-input *ngIf="onboardingForm.controls.departments?.value === OTHER"
                   [props]="otherDepartmentsProps"
                   formControlName="otherDepartment"
        ></app-input>
        <it-select
          *ngIf="titleLevels"
          [placeholder]="titleLevelsProps.placeholder"
          [label]="titleLevelsProps.label"
          [options]="titleLevels"
          [initialOption]="initialSelectionOption"
          [editable]="true"
          [upward]="false"
          (changeOption)="onChangeTitleLevelFunction($event)"
          (blurInput)="onBlurSelects('titleLevels', true)"></it-select>
      </div>
    </div>
    <div class="modal-footer">
      <section class="checkbox-style">
        <div class="user-info-form-container">
          <it-select
            *ngIf="countries"
            [placeholder]="initialSelectionOption.value"
            [label]="countriesProps.label"
            [options]="countries"
            [initialOption]="initialSelectionOption"
            [editable]="true"
            [upward]="true"
            (changeOption)="onChangeOptionCountry($event)"
            (blurInput)="onBlurSelects('countries', true)"
          ></it-select>
          <it-select
            *ngIf="(onboardingForm.controls.countries.value === US_CODE && states)"
            [placeholder]="stateProps.placeholder"
            [label]="stateProps.label"
            [options]="states"
            [initialOption]="initialSelectionOption"
            [editable]="true"
            [upward]="true"
            (changeOption)="onChangeOptionState($event)"
            (blurInput)="onBlurSelects('state', true)"
          ></it-select>
        </div>
        <label class="it-checkbox checkbox-font">
          <input type="checkbox" formControlName="eulaChecked"/>
          <div></div>
          <span>I HAVE READ AND AGREE TO THE
            <a href="https://w2.techinsights.com/l/4202/2021-06-14/2l5ps2" target="_blank" rel="noopener">END USER LICENSE AGREEMENT</a>
          </span>
        </label>
        <label class="it-checkbox checkbox-font">
          <input type="checkbox" formControlName="ppChecked"/>
          <div></div>
          <span>I HAVE READ AND AGREE TO THE
            <a href="https://w2.techinsights.com/l/4202/2021-06-14/2l5ps4" target="_blank" rel="noopener">PRIVACY POLICY</a>
          </span>
        </label>
      </section>
      <section class="button-section">
        <button type="button" class="onboarding-btn-secondary" (click)="onDeclineEULA()">
          Decline and Exit
        </button>
        <button
          type="button"
          class="onboarding-btn-primary"
          (click)="onAcceptEULA()"
          [disabled]="!onboardingForm.valid"
        >
          Agree and Continue
        </button>
      </section>
    </div>
  </form>
</div>
