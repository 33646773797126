import {Component, OnInit} from '@angular/core';
import {VendorIntegrationService} from '@app/@shared/services/vendor-integration.service';
import {environment} from '@env/environment';
import {WindowService} from '@shared/services/window-location/window.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  public readonly showSurvey = false;
  public readonly currentYear = new Date().getFullYear();

  constructor(
    private windowService: WindowService,
    private vendorIntegrationService: VendorIntegrationService,
  ) {
  }

  ngOnInit(): void {
    // User could be redirected to logout page with PAGE RELOAD,
    // And even on log out page is expected to track user interaction,
    // so we need to load heap here (user identity remains from last session)
    this.vendorIntegrationService.initHeap().subscribe();

    // User could be redirected to logout page with ROUTER NAVIGATION,
    // so we need to destroy third party tools when user log outs
    this.vendorIntegrationService.destroyChameleon();
    this.vendorIntegrationService.destroyBeamer();
  }

  redirectToLoginUrl() {
    this.windowService.assignLocation(environment.loginUrl);
  }
}
