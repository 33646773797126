import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlogExcerpt } from '@app/@shared/models/market-analysis/market-analysis';

@Component({
  selector: 'app-techstream-area',
  templateUrl: './techstream-area.component.html',
  styleUrls: [ './techstream-area.component.scss' ],
})
export class TechstreamAreaComponent {

  @Input() blogs: Array<BlogExcerpt>;

  @Output() clickBlog = new EventEmitter<BlogExcerpt>();
  @Output() clickViewAll = new EventEmitter<void>();

  public onClickBlog(blog: BlogExcerpt): void {
    this.clickBlog.emit(blog);
  }

  public onClickViewAll(): void {
    this.clickViewAll.emit();
  }
}
