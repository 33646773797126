import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

declare const window: any;

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @Input() videoId: string;
  @Output() firstPlay = new EventEmitter();
  readonly embedOptions =
    'vdeoFoam=false fitStrategy=contain playerColor=000000 autoPlay=false seo=false googleAnalytics=false';

  thumbnailLoaded = false;

  ngOnInit(): void {
    this.addFirstPlayHandler();
  }

  onLoad() {
    this.thumbnailLoaded = true;
  }

  private addFirstPlayHandler() {
    window._wq = window._wq || [];
    window._wq.push({
      id: this.videoId,
      onHasData: (videoPlayer: any) => {
        videoPlayer.bind('play', (e: any) => {
          this.firstPlay.emit();
          return videoPlayer.unbind;
        });
      }
    })
  }

}
