import {Component, Input} from '@angular/core';
import {RelatedContent} from '@shared/services/hygraph/hygraph.models';
import {CTIContentType} from '@shared/models/search/cti-search.model';
import {Router, UrlTree} from '@angular/router';

@Component({
  selector: 'app-hygraph-related-content',
  templateUrl: './related-content.component.html',
  styleUrls: ['./related-content.component.scss']
})
export class HygraphRelatedContentComponent {
  @Input() relatedContentItems: RelatedContent[];
  @Input() includeRelatedContent: boolean;

  constructor(private router: Router) {
  }

  public navigateTo(content: RelatedContent) {
    let navigateTo: UrlTree;
    switch(content.contentType) {
      case CTIContentType.REPORT:
        navigateTo = this.router.createUrlTree(['search', 'analysis-view', content.analysisCode]);
        break;
      case CTIContentType.VIDEO:
        navigateTo = this.router.createUrlTree(['search', 'video-viewer', content.blogId]);
        break;
      case CTIContentType.BLOG:
        navigateTo = this.router.createUrlTree(['search', 'blog-viewer', content.blogId]);
        break;
    }
    window.open(this.router.serializeUrl(navigateTo), '_blank');
  }
}
