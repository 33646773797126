import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { TableViewerComponent } from './table-viewer.component';
import { TableViewerColumnsHideMenuComponent } from './table-viewer-controls/table-viewer-columns-hide-menu/table-viewer-columns-hide-menu.component';
import { TableViewerControlsComponent } from './table-viewer-controls/table-viewer-controls.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule(
  {
    imports: [
      AgGridModule,
      CommonModule,
      FormsModule,
      OverlayModule,
      NgbTooltipModule,
    ],
    declarations: [
      TableViewerComponent,
      TableViewerColumnsHideMenuComponent,
      TableViewerControlsComponent,
    ],
    exports: [
      TableViewerComponent,
    ]
  }
)
export class TableViewerModule { }
