<section class="tab-bar" [class.no-filters]="!showFilters"
  [class.empty-section-unified]="searchModule === search">
  <div class="filter-sidebar-header" [class.collapsed]="filtersCollapsed" *ngIf="showFilters">
    <em class="icon-sidebar_close" (click)="onToggleFilters()"></em>
    <span>Filters</span>
    <span class="clear-all" (click)="clearFilters()" *ngIf="filtersApplied">
      <em class="icon-close_small"></em>
      Clear All
    </span>
  </div>
  <div class="tab-bar horizontal-scrollbar" *ngIf="searchModule !== search">
    <div *ngFor="let group of tabGroups; let first = first" class="tab-group">
      <div *ngIf="!first && (getShowedTabs(group.tabs) | async).length > 0" class="divider"></div>
      <ng-container *ngFor="let tab of (getFilteredTabs(group.tabs) | async)">
        <div
          *ngIf="!tab.hide"
          class="tab"
          [class.active]="tab === activeTab"
          (click)="onClickTab(tab, group.id)">
          <ng-container>
            {{tab.name}} {{tab.count &gt;= 0 ? ' (' + tab.count + ')' : ''}}
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="tab-bar pagination-top-container" *ngIf="searchModule === search">
    <app-pagination-top
      *ngIf="showPagination"
      [sortOptions]="sortOptions"
      [selectedSort]="defaultSort"
      [showSort]="showSort"
      [count]="count"
      [currentPage]="page"
      [pageSize]="pageSize"
      [pageSizes]="pageSizes"
      [showHighlights]="showHighlights"
      [fromUnifiedSearch]="true"
      (changePaginationOptions)="onChangePaginationOptions($event)"
    ></app-pagination-top>
  </div>
</section>
<section class="mobile-tab-bar" [class.no-filters]="!showFilters">
  <div class="mobile-filter-sidebar-header" [class.collapsed]="filtersCollapsed" *ngIf="showFilters">
    <em class="icon-sidebar_close" (click)="onToggleFilters()"></em>
    <span>Filters</span>
    <span class="clear-all" (click)="clearFilters()" *ngIf="filtersApplied">
      <em class="icon-close_small"></em>
      Clear All
    </span>
  </div>
  <div class="mobile-sort-bar" *ngIf="searchModule === search">
    <app-pagination-top
      *ngIf="showPagination"
      [sortOptions]="sortOptions"
      [selectedSort]="defaultSort"
      [showSort]="showSort"
      [count]="count"
      [currentPage]="page"
      [pageSize]="pageSize"
      [pageSizes]="pageSizes"
      [showHighlights]="showHighlights"
      [fromUnifiedSearch]="true"
      (changePaginationOptions)="onChangePaginationOptions($event)"
    ></app-pagination-top>
  </div>
</section>
<section class="analysis-view">
  <aside class="sidebar-wrapper" [class.collapsed]="filtersCollapsed" *ngIf="showFilters">
    <app-filter-sidebar
      class="scrollbar"
      [filters]="filters"
      (changeFilters)="onFiltersChanged($event)"
      #filtersBar
    ></app-filter-sidebar>
  </aside>
  <div class="analysis-view-container" [class.collapsed]="filtersCollapsed">
    <ng-container *ngIf="searchModule !== search">
      <app-pagination-top
      *ngIf="showPagination"
      [sortOptions]="sortOptions"
      [selectedSort]="defaultSort"
      [showSort]="showSort"
      [count]="count"
      [currentPage]="page"
      [pageSize]="pageSize"
      [pageSizes]="pageSizes"
      [showHighlights]="showHighlights"
      (changePaginationOptions)="onChangePaginationOptions($event)"
    ></app-pagination-top>
    </ng-container>
      <div *ngIf="!loading" class="search-string-highlight-wrapper">
        <div class="results-text" *ngIf="showResultsText;else noResultsText">
          <strong>{{count &gt; 10000 ? 10000 : count }} {{activeTab?.name === 'Videos' ? 'Video' : activeTab?.name === 'unifiedContent' ? '' : activeTab?.name}} results </strong>
          <strong *ngIf="_searchTerm && count !== undefined">for </strong>
          <span *ngIf="_searchTerm && count !== undefined" class="results-text-normal"> "{{_searchTerm}}"</span>
          <span *ngIf="breadcrumbString && breadcrumbString != 'Search All'" class="breadcrum-message-container results-text-normal">in {{breadcrumbString}}</span>
          <span *ngIf="count > 10000" [ngClass]="iconClassInfo" class="tooltip-span-container results-text-normal" [ngbTooltip]="maxAmountResultsExceededMessage" placement="top" container="body"></span>
        </div>
        <ng-template #noResultsText>
            <div class="results-text-hidden"></div>
        </ng-template>
        <app-pagination-highlight
          *ngIf="showHighlightToggle"
          [currentPage]="page"
          [currentPageSize]="pageSize"
          [selectedSort]="defaultSort"
          [showHighlights]="showHighlights"
          (changePaginationOptions)="onChangePaginationOptions($event)"
        ></app-pagination-highlight>
      </div>
      <div class="loading-indicator" *ngIf="loading; else loaded">
        <app-progress-bar-circular></app-progress-bar-circular>
      </div>
      <ng-template #loaded>
        <ng-container
          [ngTemplateOutlet]="activeTabGroup.template"
          [ngTemplateOutletContext]=""
        ></ng-container>
      </ng-template>
    <app-pagination-bottom
      *ngIf="showPagination"
      [count]="count"
      [startingPage]="page"
      [startingPageSize]="pageSize"
      [selectedSort]="defaultSort"
      [showHighlights]="showHighlights"
      (changePaginationOptions)="onChangePaginationOptions($event)"
    ></app-pagination-bottom>
  </div>
</section>
