<ng-container *ngIf="table">
  <div
    class="row-container header-container"
    *ngIf="table.rows.length"
  >
    <div class="header"></div>
    <div class="header">Title</div>
    <div class="header">Publication Date</div>
    <div class="header center"></div>
  </div>
  <a
    *ngFor="let row of table.rows"
    class="row-container"
    [href]="createRowUrl(row)"
    [class.heap-non-entitled-report]="!row.entitled"
    [attr._ti_content_navigator]="row.id"
    (click)="onClickRow(row, $event)"
  >
    <div>
      <ng-container *ngIf="!row.entitled ;else entitled">
        <em class="icon small icon-locked"></em>
      </ng-container>
      <ng-template #entitled>
        <em
          *ngIf="row.annotated"
          class="icon small icon-edit1"
          ngbTooltip="Document contains annotations"
        ></em>
      </ng-template>
    </div>
    <div>
      <div
        class="emphasis truncate-paragraph-ellipsis line-clamp-2"
        [innerHTML]="row.title"
      ></div>
      <div
        class="description truncate-paragraph-ellipsis line-clamp-2"
        [innerHTML]="row.description"
      ></div>
    </div>
    <div>{{row.completionDate | date: 'MMM d, yyyy' : '+0000' }}</div>
    <div class="center meatball-wrapper" *ngIf="activeTab !== 'recently-viewed' || (activeTab === 'recently-viewed' && row.entitled)">
        <app-meatball-menu>
          <button (click)="onClickOpenNewTab($event, row)">
            <em class="icon-external"></em> Open in new tab
          </button>
          <button *ngIf="activeTab === 'my-latest' || activeTab ==='recommendations'" (click)="onClickShare($event, row)">
            <em class="icon-share"></em> Share
          </button>
          <button *ngIf="activeTab === 'my-latest' || activeTab ==='recommendations'" (click)="onClickAddToFavorites($event, row)">
            <em class="icon-star"></em> Add to Favorites
          </button>
        </app-meatball-menu>
      </div>
  </a>
</ng-container>
<div *ngIf="showNoResults" class="no-results">
  <div class="text">
    <span class="main-title">
      No content yet. This section will populate with new, recently viewed, and recommended content as you explore our platform.
    </span>
  </div>
</div>
