<div class="main-container">
  <div class="body">
    <div class="title">
      <img src="../../../assets/images/StackedLogo.svg" class="logo" alt="TechInsights" />
    </div>
    <p class="info">Your subscription does not include access to this content</p>
    <div class="contact-info">
      <span>
        Explore our <a class="link" [href]="complimentaryLink">complimentary content</a> or have sales contact you about your <a class="link" href="/settings/my-entitlements">subscription.</a>
      </span>
    </div>
    <div class="contact-me">
      <button type="button" class="button button-primary" (click)="openRequestModal()">Contact Me</button>
    </div>
  </div>
  <div class="footer">
    <p class="copyright">©TechInsights Inc. {{currentYear}} all rights reserved.</p>
    <div class="links-container">
      <a href="https://w2.techinsights.com/l/4202/2021-06-14/2l5ps2" class="link">Terms of Service</a>
       |
      <a href="https://w2.techinsights.com/l/4202/2021-06-14/2l5ps4" class="link">Privacy Policy</a>
       |
      <a href="https://w2.techinsights.com/l/4202/2021-03-27/2l36bq" class="link">Contact Support</a>
    </div>
  </div>
</div>
