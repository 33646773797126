/* eslint-disable @typescript-eslint/ban-types */
import { StateBehavior, ToggleBehavior } from './state-behavior-model';
import { IconName } from './icons';

export interface PropagationEvent {
  key: string;
  value: Option;
  element?: HTMLElement
}

export interface OptionEvent {
  name: string;
  key: string;
}

/**
 * Encloses option that are related each other. Tools that works together, should be group together
 */
export class OptionGroup {

  private readonly options: Option[];

  constructor(options?: Option[]) {
    if (options) {
      this.options = options;
    } else {
      this.options = [];
    }
  }

  /**
   * Hook when a option is clicked
   */
  onClickOption(otherOption: Option, element: HTMLElement): void {
    for (const option of this.options) {
      option.onGroupChange();
    }
    otherOption.onClick(element);
  }

  /**
   * Returns all the options within this group
   */
  getOptions(): Option[] {
    return this.options;
  }

  /**
   * This method resets all options to its initial state
   */
  resetAllStates(): void {
    for (const option of this.options) {
      option.resetState();
    }
  }
}

/**
 * Abstraction of Icon Option
 */
export abstract class Option {

  identifier: string;
  events: OptionEvent[];
  visibleName?: string;
  disabled?: boolean;
  stateBehavior: StateBehavior;
  protected internalActiveState: object;

  constructor(identifier: string, events: OptionEvent[], stateBehavior: StateBehavior,
              visibleName?: string, disabled?: boolean) {
    this.identifier = identifier;
    this.events = events;
    this.stateBehavior = stateBehavior;
    this.visibleName = visibleName;
    this.disabled = disabled;
  }

  /**
   * Tells this option has been clicked. This option may react to that click
   */
  onClick(element: HTMLElement): void {
    this.stateBehavior.onChange();
  }

  /**
   * Reacts on container group change
   */
  onGroupChange(): void {
    this.stateBehavior.onGroupChange();
  }

  /**
   * Returns the desired tooltip
   */
  getTooltip(): string {
    return this.stateBehavior.getTooltipText();
  }

  /**
   * Returns css configuration object to be used with ngClass directive
   */
  getCssConfig(): object {
    const configurationObject = {};
    configurationObject[this.stateBehavior.getIconName()] = true;

    return {
      ...configurationObject,
      ...this.getInternalCssConfig(),
      'active': this.stateBehavior.isActive(),
      'pressed': this.stateBehavior.isPressed()
    };
  }

  /**
   * Reset state to initial value
   */
  resetState(): void {
    this.stateBehavior.reset();
  }

  /**
   * Depending on option subclass, internal css config may change.
   * Subclasses must override this method with its custom css rules
   */
  protected abstract getInternalCssConfig(): object;
}

/**
 * Option subclass for Icon Material options
 */
export class MaterialIconOption extends Option {

  protected getInternalCssConfig(): object {
    return {
      'toolbar-icon': true,
      'material-icons': true
    };
  }
}

/**
 * Option Subclass for custom svg option icons
 */
export class CustomOption extends Option {

  protected getInternalCssConfig(): object {
    return {
      'custom-icon': true
    };
  }
}

/**
 * This class is used to represent dropdown buttons
 */
export class DropDownOption extends CustomOption {
  dropdownWrapper: HTMLElement;

  constructor(identifier: string, iconName: IconName, tooltip: string, private embeedMenu: HTMLElement) {
      super(identifier, [], new ToggleBehavior(iconName, tooltip, iconName, '', false));
      this.dropdownWrapper = document.createElement('div');
      this.applyStyles(this.dropdownWrapper);
  }

  public onClick(element: HTMLElement): void {
    if (this.stateBehavior.isPressed()) {
      element.classList.remove('show-dropdown');
      element.removeChild(this.dropdownWrapper);
    } else {
      element.classList.add('show-dropdown');
      this.dropdownWrapper.appendChild(this.embeedMenu);
      element.appendChild(this.dropdownWrapper);
    }
    super.onClick(element);
  }

  protected getInternalCssConfig(): object {
    return {
      'social-icon': true
    };
  }

  private applyStyles(element: HTMLElement): void {
    element.style.position = 'absolute';
    element.style.top = '49px';
  }
}

/**
 * Option Subclass for custom svg option icons
 */
export class CustomLargeOption extends Option {

  protected getInternalCssConfig(): object {
    return {
      'custom-large-icon': true
    };
  }
}

/**
 * Option for social icons
 */
export class SocialOption extends Option {

  protected getInternalCssConfig(): object {
    return {
      'social-icon': true
    };
  }
}

