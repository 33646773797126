<div id="login">
  <!-- The background should swap between background-login background-logout-->
  <div class="left-side background-logout">
    <div *ngIf="showSurvey" class="logout-content left-side-text">
      <h2>What do you think about the TechInsights Platform?</h2>
      <p>
        We value your feedback. Let us know if there are any changes you would
        like to see in the Platform, our services, or our content so we can help
        you achieve your goals.
      </p>
       <!-- The survey this button previously linked to was https://w2.techinsights.com/l/4202/2020-10-13/2kv7ds
           It is now closed however, so the feedback button will need to be revisited if enabling the survey again -->
      <a href="" class="button">
        Send Feedback<img src="../../assets/images/External.svg" alt=""/>
      </a>
    </div>
  </div>

  <div class="right-side">
    <div class="logout-content">
      <a href="https://w2.techinsights.com/l/4202/2020-10-13/2kv7cn">
        <img src="../../../assets/images/StackedLogo.svg" class="logo" alt="TechInsights" />
      </a>

      <div class="logout-message">
        <p>You have signed out of TechInsights Platform.</p>
        <a class="form-button" (click)="redirectToLoginUrl()"
        >Sign In
          <img class="icon-arrow" src="../../../assets/images/Forward.svg" alt="Enter"
          /></a>
      </div>

      <footer class="footer-login">
        <a href="mailto:support@techinsights.com?subject=Help%20with%20the%20TechInsights%20Application&body=We%20are%20here%20to%20help!%20Please%20provide%20the%20following%20information%20and%20a%20support%20representative%20will%20contact%20you%20shortly.%0D%0A%0D%0A%0D%0A1.%20Summary%20of%20your%20problem%3A%0D%0A%0D%0A%0D%0A2.%20Detailed%20Description%3A%0D%0A%0D%0A%0D%0A3.%20The%20steps%20to%20replicate%20the%20problem%20(if%20available)%3A" class="btn btn-link btn-block"> Contact Support </a>
        <p class="copyright">©TechInsights Inc. {{currentYear}} all rights reserved.</p>
      </footer>
    </div>
  </div>
</div>
