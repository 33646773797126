<div class="filter-facet" *ngFor="let facet of facets">
  <label [for]="facet.id" class="it-checkbox">
    <input
      [id]="facet.id"
      type="checkbox"
      [(ngModel)]="facet.selected"
      (change)="onChangeFilter(facet)"
    />
    <div></div>
    {{ facet.label }}
  </label>
</div>
