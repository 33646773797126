import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Alert, AlertType } from '../../models/alert';
import { AlertService } from '../../services/alert/alert-service';
import { ALERT_SHOW_TIMEOUT, ALERT_ANIMATE_TIMEOUT } from '../../consts/alert';

@Component({
  selector: 'app-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(ALERT_ANIMATE_TIMEOUT)),
    ]),
  ]
})

export class AlertBarComponent implements OnInit, OnDestroy {
  alerts: Alert[] = [];
  alertSubscription: any;

  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.alertSubscription = this.alertService.getAlert().subscribe((alert: Alert) => {
      if (alert) {
        this.alerts.push(alert);
        setTimeout(() => this.removeAlert(alert), ALERT_SHOW_TIMEOUT);
      } else {
        this.alerts = [];
      }
    });
  }

  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  cssClass(alert: Alert): string {
    switch (alert.type) {
      case AlertType.Success:
        return 'alert-success';
      case AlertType.Error:
        return 'alert-danger';
    }
  }

  iconAlert(alert: Alert): string {
    switch (alert.type) {
      case AlertType.Success:
        return 'icon-tick_large';
      case AlertType.Error:
        return 'icon-close_large';
    }
  }

  ngOnDestroy(): void {
    this.alertSubscription.unsubscribe();
  }
}
