import {Dashboard, NavigationHint, NavigationObjectType} from '@app/@shared/models/navigation/navigation.models';
import { TableauConfig } from '@app/@shared/models/shared.models';
import { environment } from '@env/environment';

export enum Channels {
  DIGITAL = 'Compute - Digital Floorplan Analytics',
  PROCESS = 'Compute - Process Flow Analytics',
  PROCESS_ANALYTICS = 'Compute - Process Analytics',
  PACKAGING = 'Compute - Packaging Analytics',
  SOC = 'Compute - SoC Design Analytics',
  TRANSISTOR = 'Compute - Transistor Characterization Analytics',
  PROCESS_TRANSISTOR_CHARACTERISTICS = 'Compute - Process and Transistor Characteristics Analytics'
}

export enum REAnalyticsIds {
  DIGITAL_FLOORPLAN = 'digital-floorplan',
  PROCCESS_FLOW_ANALYTICS = 'proccess-flow-analytics',
  PROCCESS_FLOW_SCATTERPLOT = 'proccess-flow-scatterplot',
  PACKAGING_ANALYTICS = 'pachaging-analytics',
  SOC_DESIGN_ANALYTICS = 'soc-design-analytics',
  TRANSISTOR_CHARACTERIZATION = 'transistor-characterization',
  PROCESS = 'process',
  PROCESS_TRANSISTOR_CHARACTERISTICS_ANALYTICS = 'process-transistor-characteristics-analytics'
}

export enum REAnalyticsChildrenIds {
  DIGITAL_FLOORPLAN_REPORT_DATE = 'digital-floorplan-report-date',
  DIGITAL_FLOORPLAN_DUAL_PLOT_ANALYTICS = 'digital-floorplan-dual-plot-analytics',
  PROCCESS_FLOW_ANALYTICS_STACKED_BAR = 'proccess-flow-analytics-stacked-bar',
  PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_DATE = 'proccess-flow-scatterplot-feol-by-date',
  PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_TECH_NODE = 'proccess-flow-scatterplot-feol-by-tech-node',
  PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_DATE = 'proccess-flow-scatterplot-beol-by-date',
  PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_TECH_NODE = 'proccess-flow-scatterplot-beol-by-tech-node',
  PACKAGING_ANALYTICS_SCATTERPLOT_BY_DATE = 'pachaging-analytics-scatterplot-by-date',
  PACKAGING_ANALYTICS_SCATTERPLOT_BY_SOC_TECH_NODE = 'pachaging-analytics-scatterplot-by-soc-tech-node',
  SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_DATE = 'soc-design-analytics-scatterplot-by-date',
  SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_TECH_NODE = 'soc-design-analytics-scatterplot-by-tech-node',
  TRANSISTOR_CHARACTERIZATION_SCATTERPLOT = 'transistor-characterization-scatterplot',
  ADVANCED_CMOS_PROCESS_DUAL_SCATTERPLOT  = 'advanced-cmos-process-dual-scatterplot',
  PROCESS_TRANSISTOR_CHARACTERISTICS_ANALYTICS_SCATTERPLOT = 'process-transistor-characteristics-analytics-scatterplot'
}

const DIGITAL_FLOORPLAN_REPORT_DATE_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.DIGITAL_FLOORPLAN_REPORT_DATE,
  'name': 'Digital Floorplan - By Report Date',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.DIGITAL,
  'dashboardUrl':
    `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/LogicDigitalFloorplanAnalyticsbyEarliestReceivedDate/LogicDigitalFloorplanAnalyticsbyEarliestReceivedDate?:toolbar=top&:showShareOptions=no`
};

const DIGITAL_FLOORPLAN_DUAL_PLOT_ANALYTICS_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.DIGITAL_FLOORPLAN_DUAL_PLOT_ANALYTICS,
  'name': 'Digital Floorplan - Dual Plot Analytics',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.DIGITAL,
  'dashboardUrl':
    `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/LogicDigitalFloorplanAnalyticsDualPlot/DUALAnalyticsONtrendline?:toolbar=top&:showShareOptions=no`
};

const PROCCESS_FLOW_ANALYTICS_STACKED_BAR_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.PROCCESS_FLOW_ANALYTICS_STACKED_BAR,
  'name': 'Process Flow Analytics - Stacked Bar',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.PROCESS,
  'dashboardUrl':
    `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/LogicProcessFlowAnalyticsStackedBar/StackedBarChart?:toolbar=top&:showShareOptions=no`
};

const PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_DATE_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_DATE,
  'name': 'Process Flow Scatterplot - FEOL, By Date',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.PROCESS,
  'dashboardUrl':
    `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/ProcessFlowAnalyticsScatterplot/PFAAnalyticsbyTimeFEOLOFFtrendline?:toolbar=top&:showShareOptions=no`
};

const PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_TECHNODE_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_TECH_NODE,
  'name': 'Process Flow Scatterplot - FEOL, By Tech Node',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.PROCESS,
  'dashboardUrl':
    `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/ProcessFlowAnalyticsScatterplot/PFAAnalyticsFEOLOFFtrendline?:toolbar=top&:showShareOptions=no`
};

const PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_DATE_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_DATE,
  'name': 'Process Flow Scatterplot - BEOL, By Date',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.PROCESS,
  'dashboardUrl':
    `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/ProcessFlowAnalyticsScatterplot/PFAAnalyticsbyTimeBEOLOFFtrendline?:toolbar=top&:showShareOptions=no`
};

const PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_TECHNODE_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_TECH_NODE,
  'name': 'Process Flow Scatterplot - BEOL, By Tech Node',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.PROCESS,
  'dashboardUrl':
    `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/ProcessFlowAnalyticsScatterplot/PFAAnalyticsBEOLOFFtrendline?:toolbar=top&:showShareOptions=no`
};

const PACKAGING_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.PACKAGING_ANALYTICS_SCATTERPLOT_BY_DATE,
  'name': 'Packaging Analytics - Scatterplot By Date',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.PACKAGING,
  'dashboardUrl':
      `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/LogicPackagingAnalyticsDualScatterplot/18_DateOFFTrendline?:toolbar=top&:showShareOptions=no`
};

const PACKAGING_ANALYTICS_SCATTERPLOT_BY_SOC_TECH_NODE_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.PACKAGING_ANALYTICS_SCATTERPLOT_BY_SOC_TECH_NODE,
  'name': 'Packaging Analytics - Scatterplot By SoC Tech Node',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.PACKAGING,
  'dashboardUrl':
  `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/LogicPackagingAnalyticsDualScatterplot/2_DuallogxandyDUALAnalyticsOFFtrendline?:toolbar=top&:showShareOptions=no`
};

const SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_DATE,
  'name': 'SOC Design Analytics - Scatterplot By Date',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.SOC,
  'dashboardUrl':
    `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/SoCAnalyticsScatterplot/SoCAnalyticsbyTimeONtrendline?:toolbar=top&:showShareOptions=no`
};

const SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_TECH_NODE_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_TECH_NODE,
  'name': 'SOC Design Analytics - Scatterplot By Tech Node',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.SOC,
  'dashboardUrl':
    `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/SoCAnalyticsScatterplot/SoCAnalyticsONtrendline?:toolbar=top&:showShareOptions=no`
};

const TRANSISTOR_CHARACTERIZATION_SCATTERPLOT_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.TRANSISTOR_CHARACTERIZATION_SCATTERPLOT,
  'name': 'Transistor characterization - Scatterplot',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.TRANSISTOR,
  'dashboardUrl':
    `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/TCRScatterplot/TCRScatterplotONtrendline?:toolbar=top&:showShareOptions=no`
};

const PROCESS_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.ADVANCED_CMOS_PROCESS_DUAL_SCATTERPLOT,
  'name': 'Advanced CMOS Process Dual Scatterplot',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.PROCESS_ANALYTICS,
  'dashboardUrl':
    `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/Logic-AdvancedCMOSProcessScatterplotbyTechNode/DUALAnalyticsOFFtrendline?:toolbar=top&:showShareOptions=no`
};

const PROCESS_TRANSISTOR_CHARACTERISTICS_ANALYTICS_SUBCHANNEL: Dashboard = {
  'id': REAnalyticsChildrenIds.PROCESS_TRANSISTOR_CHARACTERISTICS_ANALYTICS_SCATTERPLOT,
  'name': 'Process and Transistor Characteristics Analytics',
  'entitled': false,
  'type': NavigationObjectType.CHANNEL,
  'children': [],
  'reports': [],
  'content': NavigationHint.PRODUCT_LIST,
  'container': NavigationHint.CHANNEL_CARD,
  'reportCount': 0,
  'isCustom': false,
  'channelSfId': Channels.PROCESS_TRANSISTOR_CHARACTERISTICS,
  'dashboardUrl':
    `${environment.tableauBaseUrl}/trusted/<ticket>/t/<site>/views/ProcessandTransistorcharacteristicsScatterplot/logx2DUALAnalyticsONtrendline?:toolbar=top&:showShareOptions=no`
};

export const RE_ANALYTICS_CHANNEL: Array<Dashboard> = [
  {
    'id': REAnalyticsIds.DIGITAL_FLOORPLAN,
    'name': 'Digital Floorplan',
    'entitled': false,
    'type': NavigationObjectType.CHANNEL,
    'children': [
      DIGITAL_FLOORPLAN_REPORT_DATE_SUBCHANNEL,
      DIGITAL_FLOORPLAN_DUAL_PLOT_ANALYTICS_SUBCHANNEL
    ],
    'reports': [],
    'content': NavigationHint.PRODUCT_LIST,
    'container': NavigationHint.CHANNEL_CARD,
    'reportCount': 0,
    'isCustom': false,
    'channelSfId': Channels.DIGITAL,
  },
  {
    'id': REAnalyticsIds.PROCCESS_FLOW_ANALYTICS,
    'name': 'Process Flow Analytics',
    'entitled': false,
    'type': NavigationObjectType.CHANNEL,
    'children': [PROCCESS_FLOW_ANALYTICS_STACKED_BAR_SUBCHANNEL],
    'reports': [],
    'content': NavigationHint.PRODUCT_LIST,
    'container': NavigationHint.CHANNEL_CARD,
    'reportCount': 0,
    'isCustom': false,
    'channelSfId': Channels.PROCESS,
  },
  {
    'id': REAnalyticsIds.PROCCESS_FLOW_SCATTERPLOT,
    'name': 'Process Flow Scatterplot',
    'entitled': false,
    'type': NavigationObjectType.CHANNEL,
    'children': [
      PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_DATE_SUBCHANNEL,
      PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_TECHNODE_SUBCHANNEL,
      PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_DATE_SUBCHANNEL,
      PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_TECHNODE_SUBCHANNEL,
    ],
    'reports': [],
    'content': NavigationHint.PRODUCT_LIST,
    'container': NavigationHint.CHANNEL_CARD,
    'reportCount': 0,
    'isCustom': false,
    'channelSfId': Channels.PROCESS,
  },
  {
    'id': REAnalyticsIds.PACKAGING_ANALYTICS,
    'name': 'Packaging Analytics',
    'entitled': false,
    'type': NavigationObjectType.CHANNEL,
    'children': [
      PACKAGING_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL,
      PACKAGING_ANALYTICS_SCATTERPLOT_BY_SOC_TECH_NODE_SUBCHANNEL,
    ],
    'reports': [],
    'content': NavigationHint.PRODUCT_LIST,
    'container': NavigationHint.CHANNEL_CARD,
    'reportCount': 0,
    'isCustom': false,
    'channelSfId': Channels.PACKAGING,
  },
  {
    'id': REAnalyticsIds.SOC_DESIGN_ANALYTICS,
    'name': 'SOC Design Analytics',
    'entitled': false,
    'type': NavigationObjectType.CHANNEL,
    'children': [
      SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL,
      SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_TECH_NODE_SUBCHANNEL,
    ],
    'reports': [],
    'content': NavigationHint.PRODUCT_LIST,
    'container': NavigationHint.CHANNEL_CARD,
    'reportCount': 0,
    'isCustom': false,
    'channelSfId': Channels.SOC,
  },
  {
    'id': REAnalyticsIds.TRANSISTOR_CHARACTERIZATION,
    'name': 'Transistor characterization',
    'entitled': false,
    'type': NavigationObjectType.CHANNEL,
    'children': [
      TRANSISTOR_CHARACTERIZATION_SCATTERPLOT_SUBCHANNEL,
    ],
    'reports': [],
    'content': NavigationHint.PRODUCT_LIST,
    'container': NavigationHint.CHANNEL_CARD,
    'reportCount': 0,
    'isCustom': false,
    'channelSfId': Channels.SOC,
  },
  {
    'id': REAnalyticsIds.PROCESS,
    'name': 'Process Analytics',
    'entitled': false,
    'type': NavigationObjectType.CHANNEL,
    'children': [
      PROCESS_SUBCHANNEL,
    ],
    'reports': [],
    'content': NavigationHint.PRODUCT_LIST,
    'container': NavigationHint.CHANNEL_CARD,
    'reportCount': 0,
    'isCustom': false,
    'channelSfId': Channels.PROCESS_ANALYTICS,
  },
  {
    'id': REAnalyticsIds.PROCESS_TRANSISTOR_CHARACTERISTICS_ANALYTICS,
    'name': 'Process and Transistor Characteristics Analytics',
    'entitled': false,
    'type': NavigationObjectType.CHANNEL,
    'children': [
      PROCESS_TRANSISTOR_CHARACTERISTICS_ANALYTICS_SUBCHANNEL
    ],
    'reports': [],
    'content': NavigationHint.PRODUCT_LIST,
    'container': NavigationHint.CHANNEL_CARD,
    'reportCount': 0,
    'isCustom': false,
    'channelSfId': Channels.PROCESS_TRANSISTOR_CHARACTERISTICS,
  },
];

export const RE_ANALYTICS_CHILDREN_MAP: Map<REAnalyticsIds, Array<Dashboard>> = new Map([
  [
    REAnalyticsIds.DIGITAL_FLOORPLAN,
    [
      DIGITAL_FLOORPLAN_REPORT_DATE_SUBCHANNEL,
      DIGITAL_FLOORPLAN_DUAL_PLOT_ANALYTICS_SUBCHANNEL,
    ]
  ],
  [
    REAnalyticsIds.PROCCESS_FLOW_ANALYTICS,
    [
      PROCCESS_FLOW_ANALYTICS_STACKED_BAR_SUBCHANNEL,
    ]
  ],
  [
    REAnalyticsIds.PROCCESS_FLOW_SCATTERPLOT,
    [
      PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_DATE_SUBCHANNEL,
      PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_TECHNODE_SUBCHANNEL,
      PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_DATE_SUBCHANNEL,
      PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_TECHNODE_SUBCHANNEL,
    ]
  ],
  [
    REAnalyticsIds.PACKAGING_ANALYTICS,
    [
      PACKAGING_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL,
      PACKAGING_ANALYTICS_SCATTERPLOT_BY_SOC_TECH_NODE_SUBCHANNEL,
    ]
  ],
  [
    REAnalyticsIds.SOC_DESIGN_ANALYTICS,
    [
      SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL,
      SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_TECH_NODE_SUBCHANNEL,
    ]
  ],
  [
    REAnalyticsIds.TRANSISTOR_CHARACTERIZATION,
    [
      TRANSISTOR_CHARACTERIZATION_SCATTERPLOT_SUBCHANNEL,
    ]
  ],
  [
    REAnalyticsIds.PROCESS,
    [
      PROCESS_SUBCHANNEL,
    ]
  ],
  [
    REAnalyticsIds.PROCESS_TRANSISTOR_CHARACTERISTICS_ANALYTICS,
    [
      PROCESS_TRANSISTOR_CHARACTERISTICS_ANALYTICS_SUBCHANNEL
    ]
  ],
]);

export const RE_ANALYTICS_SUB_CHILDREN_MAP: Map<string, Dashboard> = new Map([
  [ DIGITAL_FLOORPLAN_REPORT_DATE_SUBCHANNEL.id, DIGITAL_FLOORPLAN_REPORT_DATE_SUBCHANNEL ],
  [ DIGITAL_FLOORPLAN_DUAL_PLOT_ANALYTICS_SUBCHANNEL.id, DIGITAL_FLOORPLAN_DUAL_PLOT_ANALYTICS_SUBCHANNEL ],
  [ PROCCESS_FLOW_ANALYTICS_STACKED_BAR_SUBCHANNEL.id, PROCCESS_FLOW_ANALYTICS_STACKED_BAR_SUBCHANNEL ],
  [ PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_DATE_SUBCHANNEL.id, PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_DATE_SUBCHANNEL ],
  [ PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_TECHNODE_SUBCHANNEL.id, PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_TECHNODE_SUBCHANNEL ],
  [ PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_DATE_SUBCHANNEL.id, PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_DATE_SUBCHANNEL ],
  [ PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_TECHNODE_SUBCHANNEL.id, PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_TECHNODE_SUBCHANNEL ],
  [ PACKAGING_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL.id, PACKAGING_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL ],
  [ PACKAGING_ANALYTICS_SCATTERPLOT_BY_SOC_TECH_NODE_SUBCHANNEL.id,
    PACKAGING_ANALYTICS_SCATTERPLOT_BY_SOC_TECH_NODE_SUBCHANNEL ],
  [ SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL.id, SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL ],
  [ SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_TECH_NODE_SUBCHANNEL.id,
    SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_TECH_NODE_SUBCHANNEL ],
  [ TRANSISTOR_CHARACTERIZATION_SCATTERPLOT_SUBCHANNEL.id, TRANSISTOR_CHARACTERIZATION_SCATTERPLOT_SUBCHANNEL ],
  [ PROCESS_SUBCHANNEL.id, PROCESS_SUBCHANNEL ],
  [
    PROCESS_TRANSISTOR_CHARACTERISTICS_ANALYTICS_SUBCHANNEL.id,
    PROCESS_TRANSISTOR_CHARACTERISTICS_ANALYTICS_SUBCHANNEL
  ],
]);

export const RE_ANALYTICS_CHILDREN_CONFIG_MAP: Map<string, TableauConfig> = new Map([
  [
    DIGITAL_FLOORPLAN_REPORT_DATE_SUBCHANNEL.id,
    {
      url: DIGITAL_FLOORPLAN_REPORT_DATE_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    DIGITAL_FLOORPLAN_DUAL_PLOT_ANALYTICS_SUBCHANNEL.id,
    {
      url: DIGITAL_FLOORPLAN_DUAL_PLOT_ANALYTICS_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    PROCCESS_FLOW_ANALYTICS_STACKED_BAR_SUBCHANNEL.id,
    {
      url: PROCCESS_FLOW_ANALYTICS_STACKED_BAR_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_DATE_SUBCHANNEL.id,
    {
      url: PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_DATE_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_TECHNODE_SUBCHANNEL.id,
    {
      url: PROCCESS_FLOW_SCATTERPLOT_FEOL_BY_TECHNODE_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_DATE_SUBCHANNEL.id,
    {
      url: PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_DATE_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_TECHNODE_SUBCHANNEL.id,
    {
      url: PROCCESS_FLOW_SCATTERPLOT_BEOL_BY_TECHNODE_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    PACKAGING_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL.id,
    {
      url: PACKAGING_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    PACKAGING_ANALYTICS_SCATTERPLOT_BY_SOC_TECH_NODE_SUBCHANNEL.id,
    {
      url: PACKAGING_ANALYTICS_SCATTERPLOT_BY_SOC_TECH_NODE_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL.id,
    {
      url: SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_DATE_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_TECH_NODE_SUBCHANNEL.id,
    {
      url: SOC_DESIGN_ANALYTICS_SCATTERPLOT_BY_TECH_NODE_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    TRANSISTOR_CHARACTERIZATION_SCATTERPLOT_SUBCHANNEL.id,
    {
      url: TRANSISTOR_CHARACTERIZATION_SCATTERPLOT_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    PROCESS_SUBCHANNEL.id,
    {
      url: PROCESS_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
  [
    PROCESS_TRANSISTOR_CHARACTERISTICS_ANALYTICS_SUBCHANNEL.id,
    {
      url: PROCESS_TRANSISTOR_CHARACTERISTICS_ANALYTICS_SUBCHANNEL.dashboardUrl,
      width: environment.defaultVizWidth,
      height: environment.defaultREVizHeight,
    }
  ],
]);
