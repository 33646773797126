import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExcelTable } from '@shared/models/excel-table';
import { TelemetryEvent } from '@shared/models/telemetry-event';
import { TelemetrySearchEvent } from '../models/telemetry-search-event';
import { AssetLookupResponse, AssetModel } from '@shared/models/asset.model';
import {Analysis} from '@shared/models/reverse-engineering/analysis.models';
import { ShareLinkRequest } from '../models/share-link/share-link.model';

export interface Subscription {
  id: string;
  name: string;
  nameMobile: string;
  nameUwa: string;
  category: string;
  homeIcon: string;
  code: string;
  internalId: string;
  briefingFilename: string;
  plaLink: string;
  roadmapLink: any;
  digestOn?: boolean;
}

export interface SignedFile {
  id: string;
  url: string;
  file: string;
}

export interface AssetZipJob {
  id: string;
  state: FileDownloadStatus;
  percentage: number;
  signed_url: string;
  details: {
    skippedFiles: string[]
  };
}

export enum FileDownloadStatus {
  PENDING    = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETE   = 'COMPLETE'
}

export class IndividualAsset {
  public asset_id: string;
  public asset_group_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  private baseUrl = environment.metaServiceBaseUrl;
  private bestFriends: BehaviorSubject<string[]>;
  private bestFriendsCache: string[] = [];

  constructor(
    private httpClient: HttpClient
  ) {}
  /**
   * Posts a telemetry event with no route and dateTime 'now'.
   * Event name is sent uppercase and prefixed with Event
   *
   * Event MUST have name
   *
   * @param event event you want to post
   */
  postTelemetryEvent(event: TelemetryEvent): Observable<void> {
    if (!event.name) {
      throw new Error('Telemetry event must have a name')
    }

    const url = `${this.baseUrl}/telemetry`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const date = new Date().toJSON();
    const body = {
      events: [
        {
          name: event.name,
          payload: event.payload,
          application: 'UNIFIEDUI-PROFILE',
          applicationModule: event.applicationModule,
          datetime: date
        }
      ]
    };

    return this.httpClient.post<any>(url, body, { headers });
  }

  /**
   * Gets report information
   *
   * @param reportCode the reportCode to look for
   */
  getFullReportByCode(reportCode: string): Observable<Analysis> {
    const url = `${this.baseUrl}/fullReportByReportCode/${reportCode}`;
    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.httpClient.get<Analysis>(url, { headers }).pipe(
      catchError(() => of({} as Analysis))
    )
  }

  postTelemetrySearchEvent(event: TelemetrySearchEvent): Observable<void> {
    const url = `${this.baseUrl}/telemetrySearchEvent`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.post<any>(url, event, { headers });
  }

  /**
   * Posts a telemetry event with no route and dateTime 'now'.
   * Event name is sent uppercase and prefixed with UNIFIED_UI_
   *
   * Event MUST have name
   *
   * @param event event you want to post
   */
  postUnifiedUITelemetryEvent(event: TelemetryEvent): Observable<void> {
    if (!event.name) {
      throw new Error('Telemetry event must have a name')
    }

    const url = `${this.baseUrl}/telemetry`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const date = new Date().toJSON();
    const body = {
      events: [
        {
          name: event.name,
          payload: event.payload,
          datetime: date
        }
      ]
    };

    return this.httpClient.post<any>(url, body, { headers });
  }

  signFiles(request: Array<SignedFile>): Observable<Array<SignedFile>> {
    const url = `${this.baseUrl}/sign`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.httpClient.post<Array<SignedFile>>(url, request, { headers });
  }

  getSignedFiles(subscriptions: Array<Subscription>, hover: boolean = false): Observable<SignedFile[]> {
    const request: any = subscriptions.map((subscription: any) => this.getSignFileRequest(subscription, hover));
    return this.signFiles(request);
  }

  /**
   * Return an asset from its id
   * We might want to create a new class for this asset json.
   * Format notes of Json asset returned from backend:
   * { id: string;
   *   name: string;
   *   description: string;
   *   assetClassification: string;
   *   fileId: string;
   *   fileType: string;
   *   fileSize: string;
   *   fileCreated: string; }
   * other keys: 'created', 'updated',
   * 'secondaryFileId', 'assetHash', 'reportId',
   * 'contentLakeObjectId', 'display_file_name'
   * @param assetId asset GUID identifier
   */
   getAsset(assetId: string): Observable<any> {
    const url = `${this.baseUrl}/asset/${assetId}`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.get<any>(url, { headers });
  }

  getAssetFromClId(clId: string): Observable<AssetModel> {
    const url = `${this.baseUrl}/asset/clobjectid/${clId}`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.get<AssetModel>(url, { headers });
  }

  postShareDocument(request: any): Observable<any> {
    const url = `${this.baseUrl}/share-files`;
    const headers = new HttpHeaders()
    .set('Accept' , 'application/json');
    if (request.emails) {
      this.addToMyFriends(request.emails);
    }
    return this.httpClient.post<any>(url, request, { headers });
  }

  postShareLink(request: ShareLinkRequest): Observable<any> {
    const url = `${this.baseUrl}/share-links`;
    const headers = new HttpHeaders()
      .set('Accept' , 'application/json');
    if (request.emails) {
      this.addToMyFriends(request.emails);
    }
    return this.httpClient.post<any>(url, request, { headers });
  }

  getBestFriends(): Observable<string[]> {
    // Lazily make web request and afterward return the same BehaviorSubject
    if (this.bestFriends === undefined) {
      this.bestFriends = new BehaviorSubject<string[]>(this.bestFriendsCache);
      const url = `${this.baseUrl}/recent-share-email?numberOfEmails=100`;
      const headers = new HttpHeaders()
        .set('Accept' , 'application/json');
      this.httpClient.get<any>(url, { headers }).subscribe( friendsArray => {
      this.addToMyFriends(friendsArray);
      });
    }
    return this.bestFriends.asObservable();
  }

  getReportAndTopAssetGroupByCode(reportCode: string): Observable<any> {
    const url = `${this.baseUrl}/report/assetGroup/${reportCode}`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.get<any>(url, { headers });
  }

  getAssetBinariesUrl(assetId: string): Observable<string> {
    const assetUrl = `${this.baseUrl}/asset/${assetId}/binary/300`;
    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.httpClient.get<string>(assetUrl, { headers });
  }

  downloadBlob(binariesUrl: string, contentType: string): Observable<Blob> {
    const headers = new HttpHeaders().set('Content-Type', contentType);

    return this.httpClient.get(binariesUrl, {headers, responseType: 'blob'});
  }

  getAssetZipJobsById(jobId: string): Observable<AssetZipJob> {
    const url = `${this.baseUrl}/filezip/${jobId}`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.httpClient.get<AssetZipJob>(url, { headers });
  }

  postAssetZipJob(
    assetGroupIds: string[],
    assetIds: IndividualAsset[],
    reportCode: string,
    allSelected: boolean
    ): Observable<any> {
    const url = `${this.baseUrl}/filezip`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    const body = {
      asset_group_ids: assetGroupIds,
      individual_assets: assetIds,
      report_code: reportCode,
      download_all: allSelected
    };
    return this.httpClient.post<any>(url, body, { headers });
  }

  getExcelTableByReportCode(reportCode: string): Observable<ExcelTable> {
    const url = `${this.baseUrl}/asset/tableViewerByReportCode/${reportCode}/binary/3600`;
    return this.httpClient.get<string>(url).pipe(
      switchMap(urlReport => this.httpClient.get<ExcelTable>(urlReport))
    )
  }

  getAssetByMd5AndReportCode(md5Hash: string, reportCode: string)
  : Observable<Array<AssetLookupResponse>> {
    const reportCodeUrl = reportCode ? `/${reportCode}` : '';
    return this.httpClient.get<Array<AssetLookupResponse>>(
      `${this.baseUrl}/asset/lookup/${md5Hash}${reportCodeUrl}`
    )
  }

  private addToMyFriends( newFriends: string[] ) {
    [...newFriends].reverse().forEach(friend =>
      {
        if (!this.bestFriendsCache.includes(friend)) {
          this.bestFriendsCache.unshift(friend);
        }
      });
    if (this.bestFriends) {
      // Update subscribers
      this.bestFriends.next(this.bestFriendsCache);
    }
  }

  private getSignFileRequest(subscription: Subscription, hover: boolean = false): SignedFile {
    return {
      id: subscription.id,
      file: !hover ? `Icons/${subscription.homeIcon}` : `Icons/${this.getHoverUrl(subscription.homeIcon)}`,
      url: '',
    };
  }

  private getHoverUrl(iconFilename: string): string {
    const [ filename, extension ] = iconFilename.split('.');
    return `${filename}_hover.${extension}`;
  }
}
