<div class="container">
    <div class="not-content-box">
        <em *ngIf="icon" [ngClass]="[icon, 'image']"></em>
        <h1 class="title">{{title}}</h1>
        <p class="message">
            <span *ngIf="descripLine1">{{descripLine1}}</span>
            <span *ngIf="descripLine2">{{descripLine2}}</span>
            <span *ngIf="descripLine3">{{descripLine3}}</span>
        </p>
    </div>
</div>