import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AbstractSubscriptionContentsComponent, SubscriptionContentContextModel } from '@app/@shared/components/abstract/abstract-subscription-contents/abstract-subscription-contents.component';
import { Video } from '@app/@shared/models/market-analysis/market-analysis';
import { NavigationModules, isChannel } from '@app/@shared/models/navigation/navigation.models';
import { AnalysisFilter } from '@app/@shared/models/reverse-engineering/analysis.models';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import { SearchService } from '@app/@shared/services/search/search.service';
import { TelemetryService } from '@app/@shared/services/telemetry-service';
import { FilterHelper } from '@app/@shared/utils/filter-helper';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-subscription-videos-page',
  templateUrl: './subscription-videos-page.component.html',
  styleUrls: ['./subscription-videos-page.component.scss']
})
@UntilDestroy()
export class SubscriptionVideosPageComponent extends AbstractSubscriptionContentsComponent implements OnInit {
  videos: Array<Video>;
  videoCount: number;
  module: NavigationModules = this.route.snapshot.data.module;

  get subscriptionId(): string {
    return this.subscriptionIds[0];
  }

  constructor(
    protected route: ActivatedRoute,
    protected searchService: SearchService,
    protected navigationService: NavigationService,
    protected entitlementService: EntitlementService,
    protected router: Router,
    protected ffService: FeatureFlagService,
    protected telemetryService: TelemetryService,
  ) {
    super(
      route,
      router,
      navigationService,
      telemetryService
    );
  }

  protected getContext(): Observable<SubscriptionContentContextModel> {
    return this.route.parent.paramMap.pipe(
      filter((params) => params.has('subscriptionId')),
      concatMap((params) => this.navigationService.getSubscription(params.get('subscriptionId'), this.module)),
      map((subscription) => ({
        subscriptionIds: [subscription.id],
        channelIds: subscription.children.filter(isChannel).map((channel) => channel.id),
        searchContext: subscription.name,
      })),
    );
  }

  protected requestContent(queryParams: ParamMap): Observable<AnalysisFilter[]> {
    if(this.channelIds.length > 0){
      return this.searchService.getVideos(
        this.searchTerm,
        FilterHelper.parseFiltersFromParams(queryParams),
        this.paginationOptions,
        this.channelIds,
      ).pipe(
        map((result) => {
          this.videos = result.videos;
          this.videoCount = result.count;
          return result.filters ?? [];
        }),
      );
    }

    this.videos = [];
    this.videoCount = 0;
    return of([]);
  }
}
