export class ToolbarType {

  readonly showHierarchyButton: boolean;
  readonly showMetadataButton: boolean;
  readonly showPagination: boolean;
  readonly showAnnotationOptions: boolean;
  readonly showLeftPanel: boolean;

  constructor(
    showHierarchyButton: boolean,
    showMetadataButton: boolean,
    showPagination: boolean,
    showAnnotationOptions: boolean,
    showLeftPanel: boolean = false
  ) {
    this.showHierarchyButton = showHierarchyButton;
    this.showMetadataButton = showMetadataButton;
    this.showPagination = showPagination;
    this.showAnnotationOptions = showAnnotationOptions;
    this.showLeftPanel = showLeftPanel;
  }

}

export class ToolbarTypes {

  static readonly UNDEFINED: ToolbarType = new ToolbarType(false, false, false, false);
  static readonly PDF_GRID: ToolbarType = new ToolbarType(false, false, false, false);
  static readonly PDF_VIEWER: ToolbarType = new ToolbarType(false, true, true, false, true);
  static readonly PDF_VIEWER_NO_METADATA: ToolbarType = new ToolbarType(false, false, false, true, true);
  static readonly IMAGE_VIEWER: ToolbarType = new ToolbarType(true, true, true, false);
  static readonly KIMERA_GRID_CV_VIEWER: ToolbarType = new ToolbarType(false, false, false, false);
  static readonly OTHER: ToolbarType = new ToolbarType(false, false, false, false);

}
