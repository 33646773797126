import { ChannelCard } from '@shared/models/shared.models';
import { sanitizeNumber } from '@shared/utils/common';

export class ReverseEngineeringChannelCard extends ChannelCard {

  public finalReports: number;
  public analysisCounts: number;
  public complimentaryCounts: number;
  public inProgressCounts: number;
  public isCustom: boolean;

  constructor(id: string, title: string, entitled: boolean,
    finalReports: number, analysisCount: number, isCustom: boolean, complimentaryCount: number, inProgressCount: number
    ) {
    super(id, title, entitled);
    this.finalReports = finalReports;
    this.analysisCounts = analysisCount;
    this.complimentaryCounts = complimentaryCount;
    this.isCustom = isCustom;
    this.inProgressCounts = inProgressCount;
  }

  public buildSubtitle(): string {
    return `${sanitizeNumber(this.finalReports)} ${this.isCustom ? 'Analysis' : 'Final'} | ${sanitizeNumber(this.inProgressCounts)} In Progress | ${sanitizeNumber(this.analysisCounts)} Analyst | ${sanitizeNumber(this.complimentaryCounts)} Complimentary`;
  }

  public getSubtitleSegments(): Array<{label: string}> {
    const analysisCategory = this.isCustom ? 'Analysis' : 'Final';
    const sourceContentCounting = [
      { category: analysisCategory, count: this.finalReports },
      { category: 'In Progress', count: this.inProgressCounts },
      { category: 'Analyst', count: this.analysisCounts },
      { category: 'Complimentary', count: this.complimentaryCounts },
    ];
    return sourceContentCounting
      .filter(({ category, count }) => count === undefined || count > 0 || category === 'In Progress')
      .map(({ count, category }) => ({
        label: `${sanitizeNumber(count)} ${category}`,
      }));
  }

}

export class ReverseEngineeringAnalyticsChannelCard extends ReverseEngineeringChannelCard {

  public analyticsUrl: string;

  constructor(id: string, title: string, entitled: boolean,
    finalReports: number, analysisCount: number, isCustom: boolean,
    complimentaryCount: number, inProgressCount: number, analyticsUrl: string = ''
    ) {
    super(id, title, entitled, finalReports, analysisCount, isCustom, complimentaryCount, inProgressCount);
    this.analyticsUrl = analyticsUrl;
  }

  public buildSubtitle(): string {
    return '';
  }

}

export interface SubscriptionWithRoadmapResponse {
  subscriptions: {
    [key: string]: string | null | boolean,
    id: string,
    roadmapLink: string | null,
  }[]
}
