import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-checkbox-bulk-actions',
  templateUrl: './checkbox-bulk-actions.component.html',
  styleUrls: ['./checkbox-bulk-actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxBulkActionsComponent {
  @Input() checked = false;
  @Input() indeterminate = false;
  @Input() count = 0;

  @Output() allSelectionChange = new EventEmitter<boolean>();

  onAllSelectionChange(event: any) {
    this.allSelectionChange.emit(event.target.checked);
  }

}
