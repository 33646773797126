import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ContentNavigatorTab } from '@app/@shared/models/content-navigator/content-navigator.model';

@Component({
  selector: 'app-content-navigator-multi-tab',
  templateUrl: './content-navigator-multi-tab.component.html',
  styleUrls: [ './content-navigator-multi-tab.component.scss' ],
})
export class ContentNavigatorMultiTabComponent {

  @Input() tabs: Array<ContentNavigatorTab>;
  @Input() activeTab: ContentNavigatorTab;
  @Input() isContentLoading: boolean;

  @Output() clickTab = new EventEmitter<ContentNavigatorTab>();

  public onClickTab(tab: ContentNavigatorTab): void {
    this.clickTab.emit(tab);
  }

}
