import {DefaultUrlSerializer, UrlSerializer, UrlTree} from '@angular/router';

/**
 * Serializes URLs for routing on parameters which have characters that
 * can't be written as is in the URL
 */
export class CustomUrlSerializer implements UrlSerializer {
  private defaultUrlSerializer: DefaultUrlSerializer;
  constructor() {
    this.defaultUrlSerializer = new DefaultUrlSerializer();
  }
  parse(url: string): UrlTree {
    if(url.includes('device-details')){
      url = url.replace(/\+/g, '%2B');
    }
    return this.defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
    if(this.defaultUrlSerializer.serialize(tree).includes('device-details')
      && tree.queryParamMap.has('genealogyCode')){
      return this.defaultUrlSerializer.serialize(tree).replace(/%2B/g, '+');
    }
    return this.defaultUrlSerializer.serialize(tree);
  }
}
