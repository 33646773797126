<div class="app-dropdown-container">
  <app-dropdown
    *ngIf="items?.length > 0 && currentItem"
    [itemList]="items"
    [selectedItem]="currentItem"
    [hideIconOnDropdown]="hideIconOnDropdown"
    [titleSize]="'16'"
    (changeDropdownItem)="onChange($event)">
  </app-dropdown>
</div>
