export enum GenericPanelActionType {
  ADD_TO_FAVORITES = 'add-to-favorites',
  REMOVE_FROM_FAVORITES = 'remove-from-favorites',
}

export interface GenericPanelAction {
  name: string;
  type: GenericPanelActionType;
  icon: string;
}

export const GENERIC_PANEL_ACTION_ADD_TO_FAVORITES: GenericPanelAction = {
  name: 'Add to Favorites',
  type: GenericPanelActionType.ADD_TO_FAVORITES,
  icon: 'icon-star',
};

export const GENERIC_PANEL_ACTION_REMOVE_FROM_FAVORITES: GenericPanelAction = {
  name: 'Remove',
  type: GenericPanelActionType.REMOVE_FROM_FAVORITES,
  icon: 'icon-trash',
};

export const CONTENT_NAVIGATOR_TABLE_ACTIONS: Array<GenericPanelAction> = [];
export const MY_LIBRARY_TABLE_ACTIONS: Array<GenericPanelAction> = [];
