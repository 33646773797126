import { Parameter } from 'amazon-quicksight-embedding-sdk/dist/index';
import { PaginationParameters } from '../pagination';
import { Thumbnail } from '../thumbnail';
import { BlogAuthorModel } from '@shared/models/blog-author.model';

export enum AnalysisReportFileType {
  PDF = 'PDF',
  XLSX = 'XLSX',
  XLS = 'XLS',
  XXLS = 'XXLS',
  XLSM = 'XLSM',
  XLSXO = 'XLSXO',
  BLOG = 'BLOG',
}

export enum AnalysisReportMimeType {
  PDF = 'application/pdf',
  XLS = 'application/xls',
  XXLS = 'application/x-xls',
  XLSX = 'application/vnd.ms-excel',
  XLSM = 'application/vnd.ms-excel.sheet.macroenabled.12',
  XLSXO = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  BLOG = 'text/html',
  QUICKSIGHT = 'application/quicksight',
  HYGRAPH = 'application/json'
}

export enum ReportImageType {
  JPG = 'JPG',
  JPEG = 'JPEG',
  PNG = 'PNG',
  SVG = 'SVG',
}

export interface Analysis {
  id: string;
  name: string;
  code: string;
  analysisEndDate: string;
  analysisState?: string;
  baseAssetGroup: string;
  deviceName: string;
  devicePartNumber: string;
  deviceManufacturer: string;
  deviceType: string;
  downstreamProduct: string;
  description: string;
  channel: string;
  channelSfIds: string[];
  subscriptionName: string;
  productBenefitStatement: string;
  actualFirstWipDate?: string;
  entitled: boolean;
  analysisStartDate?: string;
  eccn: string;
  exportRestricted: boolean;
}

export interface AnalysisMetadataResponse {
  assetGroup: any;
  report: Analysis
  authors: BlogAuthorModel[];
}

export interface AssetGroup {
  assetGroupId: string;
  assetName: string;
  fileSize: number;
}

export interface AnalysisReportBase {
  contentCategory?: string;
  description: string;
  name: string;
  entitled: boolean;
  id: string;
  objectId: string;
  publishDate: string;
  reportCode: string;
  title: string;
  updated?: string;
  size?: string;
  selected?: boolean;
}

export interface QuicksighDashboard {
  sheetId: string;
  parameters: Parameter[];
}

// Use this interface for both files and images
export interface AnalysisAsset {
  assetId: string;
  assetName: string;
  fileType: string;
  assetGroupId: string;
  assetGroupLevel: number;
  clObjectId: string;
  fileSize: number;
  downloading?: boolean;
  orderSequence?: string;
  reportId?: string;
  postId?: string;
}

export interface AnalysisReport extends AnalysisReportBase {
  assetID: string;
  assetName: string;
  fileType: AnalysisReportMimeType;
  assetGroupId: string;
  assetGroupLevel: number;
  clObjectId: string;
  orderSequence: string;
}

export interface AnalysisImage extends AnalysisReportBase {
  fileType: string;
  name: string;
  thumbnailUrl?: string;
  assetId?: string;
  assetGroupId?: string;
  reportId?: string;
}

export interface AnalysisTelemetryPayload {
  assetName: string;
  fileType: string;
  assetId: string;
}

export interface AnalysisReportPage {
  reports: Array<AnalysisAsset>;
  count: number;
  assetGroups?: AssetGroup[];
}

export interface AnalysisResponseObjects extends AnalysisReportPage {
  objects: AnalysisObject[];
}

export interface AnalysisResponseThumbnails extends AnalysisReportPage {
  thumbnails: Thumbnail[];
}

export interface AnalysisObject {
  objectId: string;
}

export interface AnalysisImagePage {
  images: Array<AnalysisImage>;
  count: number;
}

export interface IImageFilter {
  label?: string;
  key: string;
  value?: any;
  amount?: number;
  children?: Array<IImageFilter>;
  parentKey?: string; // refers to parent level before, levels 1's refer to base node level 0
  level?: number; // refers to level in tree, endpoint returing level one's as before
  expanded?: boolean;
}

export interface IMetadataItem {
  key: string;
  value: string;
}

export interface IMetadataListItem {
  key: string;
  value: string;
  selected: boolean
  selectionEditable: boolean
}

export interface Filters {
  [x: string]: string | string[];
}

export enum AnalysisState {
  COMPLETED = '1',
  IN_PROGRESS = '2',
}

export enum AnalysisFilterType {
  Simple = 'simple',
  List = 'list',
  Group = 'group',
}

export interface FiltersChangedEvent {
  changed: AnalysisFilterFacet;
  applied: AnalysisFilter[];
}

export interface AnalysisFilter {
  id: string;
  label: string;
  field: string;
  expanded: boolean;
  type: AnalysisFilterType;
  options?: AnalysisFilterFacet[];
  groups?: AnalysisFilterGroup[];
}

export interface AnalysisFilterGroup {
  label: string;
  options: AnalysisFilterFacet[];
}

export interface Facet {
  displayName: string,
  id: string,
  value: string,
}

export interface AnalysisFilterFacet {
  label: string;
  selected: boolean;
  id: string;
  value: string;
}

export interface AnalysisParametersEvent {
  channelId?: string;
  flavor?: string;
  pagination: PaginationParameters;
  searchTerm: string;
  subscriptionId?: string;
  filters?: Filters;
  raiseTelemetry?: boolean;
  channelName?: string;
}

export interface SelectedImages {
  selectedImagesQty: number;
  allSelected: boolean;
  totalSize: string;
  allImages?: AnalysisImage[];
}

export type CombinedAssets = AnalysisAsset[] | AssetGroup[];

export enum AnalysisFilterKey {
  Subscription = 'subscription',
  Channel = 'channel',
  AnalysisType = 'analysis_type',
  Manufacturer = 'manufacturer',
  DeviceType = 'device_type',
  YearOfAnalysis = 'year_of_analysis',
  AuthorName = 'author_name',
  PublishedYear = 'published_year',
  InMySubscription = 'inMySubscription',
  ContentStatus = 'content_status',
  ContentCategory = 'content_category',
}

export enum UnifiedFilterKey {
  Subscription = 'subscription',
  Channel = 'channel',
  AnalysisType = 'analysis_type',
  Manufacturer = 'manufacturer',
  DeviceType = 'device_type',
  YearOfAnalysis = 'year_of_analysis',
  AuthorName = 'author_name',
  PublishedYear = 'published_year',
  InMySubscription = 'inMySubscription',
  ContentStatus = 'content_status',
  ContentCategory = 'content_category',
  ContentType = 'content_type',
}

export interface AnalysisFilterField {
  id: string;
  label: string;
  field: string;
};

export interface RelatedContent {
  id: string,
  name: string,
  entitled: boolean,
  type: string,
  description?: string,
  publishedDate?: string,
  reportCode?: string,
  fileId?: string,
  fileType?: string,
  clObjectId?: string
}
