import { Component, NgZone } from '@angular/core';
import { CustomOption } from '@app/@shared/components/generic-toolbar/option-model';
import { Measurement, MeasurementType, MeasuringPoints } from '@app/@shared/models/kimera/measurement';
import { AnnotationService } from '@app/@shared/services/annotation/annotation.service';
import { DialogService } from '@app/@shared/services/dialog/dialog.service';
import { MetaService } from '@app/@shared/services/meta.service';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Image } from '@shared/models/image';
import OpenSeaDragon, { Point } from 'openseadragon';

export const SVG_STROKE_WIDTH = 2.5;

@Component({ template: '', })
export abstract class AbstractImageViewerComponent {
  isFullScreen = false;
  viewer: OpenSeaDragon.Viewer | any;
  _selectedImage: Image;
  measuringToolsDisabled = true;
  measuringMode: MeasurementType = null; // Off
  lengthSelectionToggleOption: CustomOption;
  areaSelectionToggleOption: CustomOption;
  fullscreenToggleOption: CustomOption;
  hasRenderedOnce = false;
  hasCalibration = true;
  protected measuringPoints: MeasuringPoints = {
    initialized: false,
    firstViewPortPoint: null,
    lastViewPortPoint: null,
  }
  protected svgShape: any;
  protected DEFAULT_MODAL_OPTIONS: NgbModalOptions = {
    windowClass: 'kimera-generic-dialog',
    size: 'lg',
    container: '.openseadragon-viewer',
  };

  constructor(protected _ngZone: NgZone, protected metaService: MetaService,
    protected dialogService: DialogService, protected annotationService: AnnotationService) { }

  public onToggleLengthSelection(): void {
    if (this.lengthSelectionToggleOption.stateBehavior.isPressed()) {
      this.measuringMode = null;
    } else {
      this.measuringMode = MeasurementType.Length;
      this.prepareForMeasuring();
      if (this.areaSelectionToggleOption) {
        this.areaSelectionToggleOption.stateBehavior.reset();
      }
    }
  }

  protected addCanvasDragHandlers() {
    this.viewer.addHandler('canvas-drag', this.handleCanvasDrag.bind(this));
    this.viewer.addHandler('canvas-drag-end', this.handleCanvasDragEnd.bind(this));
  }

  private handleCanvasDrag(event: OpenSeaDragon.ViewerEvent) {
    if (this.isMeasuringModeOn()) {
      event.preventDefaultAction = true;
      const viewportPoint = this.getViewportPointFromEvent(event);

      if (!this.measuringPoints.initialized) {
        this.initializeMeasuringPoints(viewportPoint);
      } else {
        this.updateSvgShape(viewportPoint);
      }
    }
  }

  private handleCanvasDragEnd(event: any) {
    if (this.isMeasuringModeOn()) {
      event.preventDefaultAction = true;
      const viewportPoint = this.getViewportPointFromEvent(event);
      this.measuringPoints.lastViewPortPoint = viewportPoint;
      this.updateSvgShape(this.measuringPoints.lastViewPortPoint);

      if (this.hasCalibration) {
        this.handleCalibrationMeasurement().then(this.resetSvgShapeDrawing.bind(this));
      } else {
        this.handleLineDistanceMeasurement().then(this.resetSvgShapeDrawing.bind(this));
      }
    }
  }

  private getViewportPointFromEvent(event: OpenSeaDragon.ViewerEvent) {
    const webPoint = event.position;
    return this.viewer.viewport.pointFromPixel(webPoint);
  }

  private initializeMeasuringPoints(viewportPoint: Point) {
    this.measuringPoints.initialized = true;
    this.measuringPoints.firstViewPortPoint = viewportPoint;
    this.initializeSvgShape();
  }

  private handleCalibrationMeasurement() {
    const measurement = this.measuringMode === MeasurementType.Length ? this.measureLength() : this.measureArea();
    return this.displayMeasurement(measurement);
  }

  private handleLineDistanceMeasurement() {
    const lineDistance = this.getLineDistance(
      this.measuringPoints.firstViewPortPoint, this.measuringPoints.lastViewPortPoint);
    return this.calibrateMeasurement(lineDistance);
  }

  private measureLength(): Measurement{
    const lineDistance = this.getLineDistance(
      this.measuringPoints.firstViewPortPoint,
      this.measuringPoints.lastViewPortPoint,
    );
    const scaledLineDistance = lineDistance * this.getScalingFactor();
    return {
      measurements: [scaledLineDistance],
      measurementType: this.measuringMode,
    };
  };


  private measureArea(): Measurement{
    const xDeltaMicroMeters = this.getDelta(
      this.measuringPoints.firstViewPortPoint,
      this.measuringPoints.lastViewPortPoint,
      'x',
    ) * this.getScalingFactor();
    const yDeltaMicroMeters = this.getDelta(
      this.measuringPoints.firstViewPortPoint,
      this.measuringPoints.lastViewPortPoint,
      'y',
    ) * this.getScalingFactor();
    return {
      measurements: [xDeltaMicroMeters, yDeltaMicroMeters],
      measurementType: this.measuringMode,
    };
  };


  private getDelta(firstPoint: Point, lastPoint: Point, axis: string): number{
    return Math.abs(firstPoint[axis] - lastPoint[axis])
  }

  private getLineDistance(firstPoint: Point, lastPoint: Point){
    const y = lastPoint.y - firstPoint.y;
    const x = lastPoint.x - firstPoint.x;
    return Math.sqrt(x * x + y * y);
  }

  private isMeasuringModeOn(){
    return this.measuringMode !== null;
  }

  private resetSvgShapeDrawing(){
    this.svgShape.remove();
    this.svgShape = null;
    this.measuringPoints.firstViewPortPoint = null;
    this.measuringPoints.lastViewPortPoint = null;
    this.measuringPoints.initialized = false;
  }

  private getSvgNodeScale(html: HTMLElement): number{
    const transformAttr = html.getAttribute('transform');
    const scalePair = transformAttr.split(' ')[1];
    return Number(scalePair.substring('scale('.length, scalePair.length - 1))
  }

  private initializeSvgShape(){
    const openSeaDragonSvgOverlayNode = (this.viewer as any).svgOverlay().node();
    const scale = this.getSvgNodeScale(openSeaDragonSvgOverlayNode);
    const svgNS = 'http://www.w3.org/2000/svg';
    if (this.measuringMode === MeasurementType.Length) {
      this.svgShape = document.createElementNS(svgNS, 'line');
    }
    else { // measuringMode is Area
      this.svgShape = document.createElementNS(svgNS, 'rect');
    }
    this.svgShape.setAttribute('stroke-width', (SVG_STROKE_WIDTH / scale).toString())
    openSeaDragonSvgOverlayNode.appendChild(this.svgShape);
  }

  private updateSvgShape(viewportPoint: Point) {
    if (this.measuringMode === MeasurementType.Length) {
      this.updateSvgShapeLength(viewportPoint);
    } else {
      this.updateSvgShapeArea(viewportPoint);
    }
  }

  private updateSvgShapeLength(viewportPoint: Point){
    const x1 = this.measuringPoints.firstViewPortPoint.x;
    const y1 = this.measuringPoints.firstViewPortPoint.y;
    let x2 = viewportPoint.x;
    let y2 = viewportPoint.y;
    if(!this.hasCalibration) {
      if(Math.abs(x2-x1) > Math.abs(y2-y1)) {
        y2 = y1;
      } else {
        x2 = x1;
      }
    }
    this.svgShape.setAttribute('x1', x1);
    this.svgShape.setAttribute('y1', y1);
    this.svgShape.setAttribute('x2', x2);
    this.svgShape.setAttribute('y2', y2);
    this.svgShape.setAttribute('stroke', 'white')
  }

  private updateSvgShapeArea(viewportPoint: Point){
      const x = viewportPoint.x < this.measuringPoints.firstViewPortPoint.x ? viewportPoint.x :
      this.measuringPoints.firstViewPortPoint.x;
    const y = viewportPoint.y < this.measuringPoints.firstViewPortPoint.y ? viewportPoint.y :
      this.measuringPoints.firstViewPortPoint.y;

    this.svgShape
      .setAttribute('x', x);
    this.svgShape.setAttribute('y', y)
    this.svgShape.setAttribute('width', Math.abs(viewportPoint.x - this.measuringPoints.firstViewPortPoint.x))
    this.svgShape.setAttribute('height', Math.abs(viewportPoint.y - this.measuringPoints.firstViewPortPoint.y));
    this.svgShape.setAttribute('stroke', 'white');
    this.svgShape.setAttribute('fill', 'none');
  }

  protected abstract displayMeasurement(measurement: Measurement): Promise<any>;

  protected abstract calibrateMeasurement(lineDistance: number): Promise<void>;

  protected abstract prepareForMeasuring(): void;

  protected abstract getScalingFactor(): number;
}
