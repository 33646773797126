import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-table-viewer-controls',
  templateUrl: './table-viewer-controls.component.html',
  styleUrls: ['./table-viewer-controls.component.scss']
})
export class TableViewerControlsComponent {
  @Output() searchValueChange = new EventEmitter<string>();
  @Output() hideColumnToggle = new EventEmitter<ColDef>();
  @Output() clearAllFilters = new EventEmitter();
  @Output() resetSort = new EventEmitter();
  isSomeColumnHidden = false;
  searchInputValue = '';
  _columns: Array<ColDef>;
  _isTableSorted = false;
  _isColumnFilterActive = false;

  @Input() set columns(value: Array<ColDef>) {
    this._columns = value;
    this.isSomeColumnHidden = value.some(column => column.hide);
  }

  @Input() set isTableSorted(value: boolean) {
    this._isTableSorted = value;
  }

  @Input() set isColumnFilterActive(value: boolean) {
    this._isColumnFilterActive = value;
  }

  onSearchInputValueChange(keyword: string) {
    this.searchInputValue = keyword;
    this.searchValueChange.emit(keyword);
  }

  onClearAllFilters(): void {
    this.onSearchInputValueChange('');
    this.clearAllFilters.emit();
  }

  onResetSort(): void {
    this.resetSort.emit();
  }

  handleHideColumnToggle(column: ColDef) {
    this.hideColumnToggle.emit(column);
  }

}
