import { Component, Input, Output, EventEmitter } from '@angular/core';
import { sanitizeNumber } from '@shared/utils/common';
import { SubscriptionCard } from '@app/@shared/models/shared.models';

// Duplicated in channels-card-list.component.ts
const MAX_CARD_COLUMNS = 3;

@Component({
  selector: 'app-subscription-card-list',
  templateUrl: './subscription-card-list.component.html',
  styleUrls: [ './subscription-card-list.component.scss' ],
})
export class SubscriptionCardListComponent {
  @Input() subscriptions: Array<SubscriptionCard>;
  @Input() customSubtitle: string;

  @Output() clickSubscriptionCard = new EventEmitter<SubscriptionCard>();

  public readonly maxCardColumns = MAX_CARD_COLUMNS;

  public buildSubtitle(subscription: SubscriptionCard): string {
    if(this.customSubtitle) {
      return this.customSubtitle;
    } else {
      return (subscription.availableChannels !== -1
          ? `Channels ${sanitizeNumber(subscription.availableChannels)} of ${sanitizeNumber(subscription.totalChannels)}`
          : ''
      );
    }
  }

  public onClickSubscription(subscription: SubscriptionCard): void {
    this.clickSubscriptionCard.emit(subscription);
  }

}
