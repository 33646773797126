export const Icon = {
  // Generic toolbar base 24px
  HIERARCHY_OPEN: 'icon-sidebar_open',
  HIERARCHY_CLOSE: 'icon-sidebar_close',
  INFO: 'icon-message',

  // PDF Viewer 18px
  REPORT_VIEWER_LEFT_ARROW: 'icon-left_large',
  REPORT_VIEWER_RIGHT_ARROW: 'icon-right_large',
  REPORT_VIEWER_PEN: 'icon-edit',
  REPORT_VIEWER_PEN_FILLED: 'icon-edit_filled',
  REPORT_VIEWER_PAN_TOOL: 'icon-hand',
  REPORT_VIEWER_SELECT_TOOL: 'icon-pointer',
  REPORT_VIEWER_ZOOM_OUT: 'icon-zoom_out',
  REPORT_VIEWER_ZOOM_IN: 'icon-zoom_in',
  REPORT_VIEWER_FIT_PAGE: 'icon-fit_page',
  REPORT_VIEWER_ENTER_FULLSCREEN: 'icon-full_screen',
  REPORT_VIEWER_EXIT_FULLSCREEN: 'icon-minimize',
  REPORT_VIEWER_SEARCH: 'icon-search1',
  REPORT_VIEWER_CLOSE: 'icon-close_medium',
  REPORT_VIEWER_DOWNLOAD: 'icon-button_download',

  // Image Viewer and Kimera Grid CV Viewer 18px
  IMAGE_LEFT_ARROW: 'icon-left_large',
  IMAGE_RIGHT_ARROW: 'icon-right_large',
  IMAGE_NAVIGATOR: 'icon-navigator',
  IMAGE_RIGHT_ROTATE: 'icon-rotate_right',
  IMAGE_ZOOM_OUT: 'icon-zoom_out',
  IMAGE_ZOOM_IN: 'icon-zoom_in',
  IMAGE_FIT_PAGE: 'icon-fit_page',
  IMAGE_ENTER_FULLSCREEN: 'icon-full_screen',
  IMAGE_EXIT_FULLSCREEN: 'icon-minimize',
  IMAGE_SHARE: 'icon-share',
  IMAGE_CLOSE: 'icon-close_medium',
  IMAGE_UNWATERMAKED_IMAGE_ACCESS: 'icon-image-watermark',
  IMAGE_FAVORITE: 'icon-star',
  MEASURE_AREA: 'icon-mesure_area',
  MEASURE_LINE: 'icon-ruler2',

  // Schematic Viewer 18px
  SCHEMATIC_LEFT_ARROW: 'icon-button_back',
  SCHEMATIC_RIGHT_ARROW: 'icon-button_login',
  SCHEMATIC_ZOOM_OUT: 'icon-zoom_out',
  SCHEMATIC_ZOOM_IN: 'icon-zoom_in',
  SCHEMATIC_FIT_PAGE: 'icon-fit_page'
}

export type IconName = typeof Icon[keyof typeof Icon];
