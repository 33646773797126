export class Alert {
  type: AlertType;
  message: string;
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning,
}

export enum AlertMessages {
  SETTINGS_SAVED                     = 'Settings saved.',
  SETTING_SAVED                      = 'Setting saved.',
  SAVE_ERROR                         = 'Save error. Try again.',
  SETTINGS_NOT_SAVED                 = 'Settings was not saved. Please refresh the page or try again later.',
  SETTINGS_FIELDS_INVALID            = 'Please fill in the required fields.',
  PROGRESS_TRACKER_DELETED           = 'Report progress tracker deleted.',
  SOMETHING_WENT_WRONG               = 'Something went wrong. Please refresh the page or try again later.',
  FAVORITES_ADD_SUCCEDED_ANALYSIS    = `Content has been added to My Favorites. <a class='alert-message-link' href='/my-library/favorites'>Go to My Favorites</a>`,
  FAVORITES_ADD_SUCCEDED_IMAGE       = `Content has been added to My Favorites. <a class='alert-message-link' href='/my-library/favorites?page=1&size=50#tab=image'>Go to My Favorites</a>`,
  FAVORITES_ADD_FAILED               = 'Failed to add content to My Favorites. Please try again.',
  FAVORITES_ADD_LIMIT                = `You have reached the maximum limit of 5000 favorites. Please <a class='alert-message-link' href='/my-library/favorites'>go to My Favorites</a> to remove some items and try again.`,
  FAVORITES_REMOVE_SUCCEDED          = 'Content has been removed from My Favorites.',
  FAVORITES_REMOVE_FAILED            = 'Failed to remove content from My Favorites. Please try again.',
  CALIBRATION_READY                  = 'Your calibration is ready. You can start to measure',
  CALIBRATION_FAILED                 = 'Failed to save calibration.<br>Your calibration is only valid within this session.',
}
