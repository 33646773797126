import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@app/@shared/services/dialog/dialog.service';
import { MarketAnalysisService } from '@app/@shared/services/market-analysis/market-analysis.service';
import { CustomOption, DropDownOption, OptionGroup, PropagationEvent } from '../generic-toolbar/option-model';
import { StatelessBehavior } from '../generic-toolbar/state-behavior-model';
import { Icon } from '../generic-toolbar/icons';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { DashboardContentOptions, DashboardExperience, EmbeddingContext, Parameter } from 'amazon-quicksight-embedding-sdk/dist/index';
import { ShareItemModalComponent } from '../share-item-modal/share-item-modal.component';
import { DeepLinkingService } from '@app/@shared/services/deep-linking.service';
import {forkJoin, combineLatest} from 'rxjs';
import { Observable } from 'rxjs-compat';
import { RouteHelper } from '@app/@shared/utils/route-helper';
import { environment } from '@env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import {MetaService} from '@shared/services/meta.service';
import {TelemetryEvent} from '@shared/models/telemetry-event';
import {TelemetryEventName} from '@shared/enums';
import {moduleNameFromUrl} from '@shared/utils/common';
import {Analysis, AnalysisReportMimeType} from '@shared/models/reverse-engineering/analysis.models';

@UntilDestroy()
@Component({
  selector: 'app-quicksight-viewer',
  templateUrl: './quicksight-viewer.component.html',
  styleUrls: ['./quicksight-viewer.component.scss'],
})
export class QuicksightViewerComponent implements OnInit, AfterViewInit {
  @Input() set assetId(id: string) {
    if (id && !this.quicksightAssetId) {
      this.quicksightAssetId = id;
      if (this.quicksightContainerLoaded) {
        this.embedDashboard();
      }
    }
  }
  @Input() analysisMetadata: Analysis;

  @Input() set analysisCode(id: string) {
    if(id && !this.analysisId) {
      this.analysisId = id;
    }
  }

  @Output() closeAssetViewer: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('AssetViewerContainer') assetViewerContainer: ElementRef;
  @ViewChild('ShareDropdownContainer') shareDropdownContainer: ElementRef;
  @ViewChild('QuicksightContainer') quicksightContainer: ElementRef;

  public toolbarOptions: OptionGroup[];
  public embeddingContext: any;
  public dashboardExperience: DashboardExperience;
  public isLoading = false ;
  public getViewId: string;

  private eventToMethod = {
    'toggle-fullscreen': 'onClickToggleFullscreen',
    'close-viewer': 'closeViewer',
    share: 'share',
  };
  private quicksightAssetId = '';
  private analysisId = '';
  private quicksightContainerLoaded = false;
  private channelId: string;
  private subscriptionId: string;
  private reportName: string;
  private reportCode: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deepLinkingService: DeepLinkingService,
    private dialogService: DialogService,
    private marketAnalysisService: MarketAnalysisService,
    private metaService: MetaService,
  ) {}

  /**
   * Listens for changes in fullscreen mode and updates toolbar options accordingly.
   *  If the user exits fullscreen mode, it resets the toolbar options to the default.
   */
  @HostListener('document:fullscreenchange') exitFullScreenHandler() {
    if (document.fullscreenElement === null) {
      this.toolbarOptions = this.createToolbarOptions();
    }
  }

  ngOnInit(): void {
    combineLatest([
      this.route.fragment,
      this.route.paramMap
    ]).subscribe(([fragment, routeParams]) => {
      const params = new URLSearchParams(fragment || '');
      this.getViewId = params.get('view');
      this.reportName = params.get('reportName');
      this.channelId = routeParams.get('channelId');
      this.subscriptionId = routeParams.get('subscriptionId');
      this.reportCode = routeParams.get('analysisCode');
    });
  }

  ngAfterViewInit(): void {
    this.toolbarOptions = this.createToolbarOptions();
    this.quicksightContainerLoaded = true;
    if (this.quicksightAssetId) {
      this.embedDashboard();
    }
  }

  public executeToolbarOption(event: PropagationEvent): void {
    if (event.key in this.eventToMethod) {
      const methodName = this.eventToMethod[event.key];
      this[methodName](event);
    }
  }

  public share(stateId?: string): void {
    this.isLoading = false;
    const shareFormModalInstance = this.dialogService.open(ShareItemModalComponent, { windowClass: 'shareModalClass' });
    shareFormModalInstance.componentInstance.isDocumentShareable = true;
    shareFormModalInstance.componentInstance.shareEntityType = 'ASSET';
    shareFormModalInstance.componentInstance.itemDeepLinkUrl = environment.basePath + this.createURL(stateId);
  }

  public shareWithParameters(): void {
    Promise.all([
      this.dashboardExperience.getSelectedSheetId(),
      this.dashboardExperience.getParameters()
    ]).then(([id, params]) => {
      this.isLoading = true;
      this.storeDashboardState(id, params);
    });
  }

  public closeViewer(): void {
    this.closeAssetViewer.emit();
  }

  public onClickToggleFullscreen(): void {
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    } else {
      this.toolbarOptions = this.createFullScreenToolbarOption();
      this.assetViewerContainer.nativeElement.requestFullscreen();
    }
  }

  private embedDashboard(): void {
    const quicksightRequests: Observable<any>[] = [
      this.marketAnalysisService.getQuicksightEmbeddedURL(this.quicksightAssetId)
    ];

    if (this.getViewId) {
      quicksightRequests.push(this.marketAnalysisService.getQuicksightSheetAndParameters(this.getViewId));
    }

    forkJoin(quicksightRequests).subscribe(([qsURL, state]) => {
      this.sendTelemetryEvent();

      this.quicksightContainer.nativeElement.innerHTML = '';

      const frameOptions = {
        container: this.quicksightContainer.nativeElement,
        url: qsURL,
        resizeHeightOnSizeChangedEvent: true,
      };

      const contentOptions: DashboardContentOptions = {
        parameters: null,
        sheetOptions: {initialSheetId: null}
      };

      if(state) {
        contentOptions.parameters = state.parameters;
        contentOptions.sheetOptions.initialSheetId = state.sheetId;
      }

      createEmbeddingContext().then((embeddingContext: EmbeddingContext) => {
        this.embeddingContext = embeddingContext;
        embeddingContext.embedDashboard(frameOptions, contentOptions).then((dashboardExperience) => {
          this.dashboardExperience = dashboardExperience;
        })
      });
    },
    (err: any) => {
      switch (err.status) {
        case 403:
          this.deepLinkingService.redirectTo403();
          break;
        case 404:
          this.router.navigate(['/404']);
          break;
      }
    });
  }

  private createToolbarOptions(): OptionGroup[] {
    const optionGroups: OptionGroup[] = [];
    const otherOptions: any[] = [];
    const zoomOptions: any[] = [];
    const closeOption: any[] = [];
    otherOptions.push(
      new DropDownOption('share', Icon.IMAGE_SHARE, 'Share', this.shareDropdownContainer.nativeElement)
    );
    zoomOptions.push(
      new CustomOption(
        'toggle-fullscreen',
        [{ name: 'click', key: 'toggle-fullscreen' }],
        new StatelessBehavior(Icon.IMAGE_ENTER_FULLSCREEN, 'Full Screen')
      )
    );
    closeOption.push(
      new CustomOption(
        'close-viewer',
        [{ name: 'click', key: 'close-viewer' }],
        new StatelessBehavior(Icon.REPORT_VIEWER_CLOSE, 'Close'),
        'Close'
      )
    );
    optionGroups.push(new OptionGroup(zoomOptions));
    optionGroups.push(new OptionGroup(otherOptions));
    optionGroups.push(new OptionGroup(closeOption));
    return optionGroups;
  }

  private createFullScreenToolbarOption(): OptionGroup[] {
    const optionGroup: OptionGroup[] = [];
    const fullScreenOptions: any[] = [];
    fullScreenOptions.push(
      new CustomOption(
        'toggle-fullscreen',
        [{ name: 'click', key: 'toggle-fullscreen' }],
        new StatelessBehavior(Icon.IMAGE_EXIT_FULLSCREEN, 'Exit Full Screen')
      )
    );
    optionGroup.push(new OptionGroup(fullScreenOptions));
    return optionGroup;
  }

  private sendTelemetryEvent() {
    if(this.analysisMetadata.entitled) {
      const applicationModule = moduleNameFromUrl(this.router.url);
      const eventPayload = {
        artifactType: AnalysisReportMimeType.QUICKSIGHT,
        artifactName: `${this.reportName}.qs`,
        assetId: this.quicksightAssetId,
        reportCode: this.reportCode,
        reportId: this.analysisMetadata?.id,
        channelId: this.channelId,
        subscriptionId: this.subscriptionId,
        reportTitle: this.analysisMetadata?.name,
      }
      const telemetryEvent =
        new TelemetryEvent(TelemetryEventName.EVENT_QS_ASSET_VIEW, eventPayload, applicationModule);
      this.metaService.postTelemetryEvent(telemetryEvent).subscribe();
    }
  }


  private storeDashboardState(id: string, params: Parameter[]): void {
    const qsState = {
      sheetId: id,
      parameters: params
    };
    this.marketAnalysisService.postQuicksightSheetAndParameters(qsState).subscribe(
      (id) => {
        this.share(id);
      },
      (error) => {
        this.share();
      }
    )
  }

  private createURL(stateId: string): string {
    const fragment = new URLSearchParams();
    fragment.set('assetId', this.quicksightAssetId);
    stateId && fragment.set('view', stateId);

    return this.router.serializeUrl(
      this.router.createUrlTree(['analysis-view', this.analysisId], {
        fragment: fragment.toString(),
        relativeTo: RouteHelper.getModuleRouteOrShell(this.route),
      })
    )
  }
}
