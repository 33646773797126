import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  catchError,
  map,
  tap
} from 'rxjs/operators';
import {
  Observable,
  of
} from 'rxjs';
import {
  NotificationModel,
  NotificationTypeEnum
} from '@app/store/Models/notification/notificationModel';

import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { Store } from '@ngrx/store';
import { pushNotifications } from 'src/app/store/Actions/notification/notification.actions';


export interface INotificationContent {
  id: string;
  medium: string;
  message: string;
}

export interface ISort {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

export interface IPageable {
  sort: ISort;
  pageNumber: number;
  pageSize: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}


export interface INotificationResponse {
  content: INotificationContent[];
  pageable: IPageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  sort: ISort;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

/**
 * A Service handle notifications in general (via websocket and a REST API)
 */
@Injectable(
  {
    providedIn: 'root'
  }
)
export class NotificationsService {
  public stompClient: any;
  public msg: any[] = [];
  private readonly NOTIFICATIONS_ENDPOINT = '/notifications';

  constructor(private _http: HttpClient,
    private store: Store) {
  }

  /**
   * Gets the single use token to start the connection for the notification websocket
   * @param userId      user which wants to start the connection
   * @param userToken   user JWT token
   */
  getWebSocketSingleUseToken(userId: string,
    userToken: string): Observable<string> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    return this._http.get<any>(environment.websocketUrl + '/token/', {
      headers
    })
      .pipe(
        map((res: any) => res.token),
        tap(
          token => {
            this.initializeWebSocketConnection(token, userId, userToken);
          }
        ),
        catchError(err => of(null))
      );
  }

  /**
   * Starts websocket connection with the live notifications websocket
   * @param wsSingleUseToken    websocket single use token
   * @param userId              User requesting the connection
   * @param userToken           User JWT Token
   */
  initializeWebSocketConnection(wsSingleUseToken: string,
    userId: string,
    userToken: string) {
    const serverUrl = environment.websocketUrl + '/websocket?authorization=' + wsSingleUseToken;
    const ws = new SockJS(serverUrl);
    this.stompClient = Stomp.over(ws);
    const that = this;
    this.stompClient.connect({ authorization: userToken, withCredentials: false }, () => {
      that.stompClient.subscribe('/topic/' + userId, (message: { body: any; }) => {
        if (message.body) {
          this.dispatchNewNotification(message);
        }
      });
    });
  }

  /**
   * Gets the notification history for a given user (determined by the attached JWT token)
   * @param page    Notification history page
   * @param size    Notification history page size
   */
  getNotificationHistory(page: number,
    size: number): Observable<INotificationResponse> {
    return this._http.get(`${environment.notificationsUrl}/history/websocket?page=${page}&size=${size}&sort=createdAt,desc`)
      .pipe(map((res: INotificationResponse) => {
                  return res
      }));
  }

  /**
   * Dispatches a new notification to the store from the websocket notification event
   * @param message   message to notify
   */
  private dispatchNewNotification(message: any) {
    let notification: NotificationModel;
    if(typeof message.body === 'string'){
      notification =  {
        title: message.body.toString(),
        body: message.body.toString(),
        acknowledged: false,
        date: new Date(1603120309706),
        read: false,
        type: NotificationTypeEnum.SHARED,
        id: '1'
      }
    } else {
      // TBD
    }
    this.store.dispatch(pushNotifications(
      {
        notifications: [
         notification
        ]
      }
    ));
  }
}
