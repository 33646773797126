import { Component } from '@angular/core';
import {DialogService} from '@shared/services/dialog/dialog.service';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {EntitlementService} from '@shared/services/entitlement/entitlement.service';

@Component({
  selector: 'app-non-entitled-user-landing-page',
  templateUrl: './non-entitled-user-landing-page.component.html',
  styleUrls: ['./non-entitled-user-landing-page.component.scss']
})
export class NonEntitledUserLandingPageComponent {
  public complimentaryLink = '/search/result/ALL?q=&page=1&filters=%7B"contentStatus":%5B"Complimentary"%5D%7D'
  public readonly currentYear = new Date().getFullYear();

  constructor(private dialogService: DialogService, private entitlementService: EntitlementService) {}

  public openRequestModal() {
    this.entitlementService.postSubscriptionAccessRequest('ChannelName:ProductCode')
      .pipe(
        catchError(() => {
          this.dialogService.openError();
          return of(null);
        })
      )
      .subscribe((success) => {
        if (success) {
          this.dialogService.openGeneric({
            actionButtonLabel: 'Done',
            body: 'Our team has received your request for more ' +
              'information and will process your inquiry within 2 business days. ' +
              'We are looking forward to helping you!',
            title: 'Your request has been sent!',
            titleInformationLabel: 'Subscription Information Request',
          });
        }
    });
  }
}
