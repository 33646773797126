import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-no-fill-colour-picker',
  templateUrl: './no-fill-colour-picker.component.html',
  styleUrls: ['./no-fill-colour-picker.component.scss']
})
export class NoFillColourPickerComponent {

  @Input() currentOpacity: number;
  @Input() currentColour: string;
  @Input() set colours(colours: string[]){
    this._colours = ['']
    colours.forEach((colour)=>{
      this._colours.push(colour);
    })
  }
  @Output() changeOpacity: EventEmitter<number> = new EventEmitter<number>();
  @Output() changeColour: EventEmitter<string> = new EventEmitter<string>();

  _colours:string[];


  onChangeOpacity(val: number){
    this.changeOpacity.emit(val);
  }

  onColourChange(colour: string){
    this.changeColour.emit(colour);
  }

}
