import {ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';
import {AnimationItem} from 'lottie-web';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

/**
 * Component for the 404 page
 */
@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundPageComponent implements OnInit {
  options: AnimationOptions = {
    path: 'assets/animations/not-found-page/404.json',
    autoplay: false,
    loop: false
  };
  shouldGoHome: boolean;

  private animationItem: AnimationItem;
  private subs: Subscription = new Subscription();

  constructor(private ngZone: NgZone,
              private cdRef: ChangeDetectorRef,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  /**
   * Sets the animation item and plays it
   * @param $event  animation item
   */
  animationCreated($event: AnimationItem) {
    this.animationItem = $event;
    this.animationItem.setSpeed(1);
    this.animationItem.setSubframe(false);
    this.ngZone.runOutsideAngular(() => {
      this.animationItem.play();
      this.cdRef.detectChanges();
    });
  }

  /**
   * Goes back to home
   */
  goBack() {
    this.router.navigate(['/home']);
  }
}
