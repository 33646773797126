<app-card
  class="card-container"
  [class.disabled]="!blog.entitled"
>
  <div class="card-tagline">
    <em class="icon-locked" *ngIf="!blog.entitled"></em>
    <span class="truncate-ellipsis">{{ blog.category }}</span>
  </div>
  <div
    class="card-title truncate-paragraph-ellipsis line-clamp-2"
    [class.fixed-height]="consistentHeight"
    [innerHTML]="blog.title"
  ></div>
  <div
    class="card-description truncate-paragraph-ellipsis line-clamp-3"
    [class.fixed-height]="consistentHeight"
    [innerHTML]="blog.description"
  ></div>
  <div class="card-footer">
    <span class="publication-date">{{ blog.publicationDate | date: 'MMM d, yyyy' }}</span>
    <div class="footer-content">
      <em
        class="author-avatar"
        [class.icon-profile]="!blog.authorAvatarUrl"
        [style.background-image]="blog.authorAvatarUrl ? 'url(' + blog.authorAvatarUrl + ')' : 'none'"
      ></em>
      {{ blog.authorName }}
    </div>
  </div>
</app-card>
