import { Component } from '@angular/core';
import {
  ContentNavigatorTableComponent
} from '@shared/components/content-navigator/content-navigator-table/content-navigator-table.component';
import {ContentNavigatorTableRow} from '@shared/models/content-navigator/content-navigator.model';

@Component({
  selector: 'app-mcclean-content-navigator-table',
  templateUrl: './mcclean-content-navigator-table.component.html',
  styleUrls: ['./mcclean-content-navigator-table.component.scss'],
})
export class MccleanContentNavigatorTableComponent extends ContentNavigatorTableComponent<ContentNavigatorTableRow> {}

