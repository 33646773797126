import {environment} from '@env/environment';

export enum APIEnum {
  NOTIFICATIONS = 'Notifications',
  SEARCH = 'Search',
  ENTITLEMENT = 'Entitlement',
  AUTHENTICATION = 'Authentication',
  CONTENT_LAKE = 'Assets Bucket',
  JANET = 'Janet',
  META = 'Meta',
  UNLISTED = 'Unlisted',
  THUMBNAILSERVICE = 'Thumbnail Service',
  REPORT_SERVICE = 'Report Service'
}

export interface IError {
  httpResponseCode: number,
  affectedAPI: APIEnum,
  error: any,
}

export function getAffectedAPI(url: string): APIEnum {
  if(url.includes(environment.websocketUrl)
    || url.includes(environment.notificationsUrl)){
    return APIEnum.NOTIFICATIONS
  }
  if(url.includes(environment.searchServiceUrl)) {
    return APIEnum.SEARCH
  }
  if(url.includes(environment.entitlementServiceUrl)) {
    return APIEnum.ENTITLEMENT
  }
  if(url.includes(environment.authServiceBaseUrl)) {
    return APIEnum.AUTHENTICATION
  }
  if(url.includes(environment.contentLakeBaseUrl) || url.includes(environment.contentLakeBucket)) {
    return APIEnum.CONTENT_LAKE
  }
  if(url.includes(environment.thumbnailService)){
    return APIEnum.THUMBNAILSERVICE
  }
  return APIEnum.UNLISTED
}
