import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FilterSidebarComponent } from '@app/@shared/components/filter-sidebar/filter-sidebar.component';
import { ContentNavigatorTab, TabGroup } from '@app/@shared/models/content-navigator/content-navigator.model';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { AnalysisFilter, FiltersChangedEvent } from '@app/@shared/models/reverse-engineering/analysis.models';
import { SelectOption } from '@app/@shared/models/shared.models';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationModules } from '@app/@shared/models/navigation/navigation.models';

/**
 * Use the showFilters input as false if the filter sidebar is not required
 */
@Component({
  selector: 'app-tabbed-analysis-list',
  templateUrl: './tabbed-analysis-list.component.html',
  styleUrls: ['./tabbed-analysis-list.component.scss'],
})
export class TabbedAnalysisListComponent implements OnInit {
  @Input() showFilters = true;
  @Input() showSort = true;
  @Input() showResultsText = true;
  @Input() loading = false;
  @Input() count: number;
  @Input() pageSize: number;
  @Input() page: number;
  @Input() pageSizes: number[];
  @Input() tabGroups: Array<TabGroup>;
  @Input() activeTab: ContentNavigatorTab;
  @Input() activeTabGroup: TabGroup;
  @Input() filtersCollapsed = false;
  @Input() set filters(incomingFilters: AnalysisFilter[]) {
    this._filters = incomingFilters;
    this.filtersApplied = this._filters?.find((f) => f.options.filter((o) => o.selected).length > 0) !== undefined;
  }
  get filters(): AnalysisFilter[] {
    return this._filters;
  }
  @Input() set searchTerm(searchTerm: string) {
    this._searchTerm = searchTerm?.replace(/,/g, '" OR "');
  }

  @Input() breadcrumbString: string;
  @Input() showPagination = true;
  @Input() sortOptions: SelectOption<string>[];
  @Input() defaultSort: SelectOption<string>;
  @Input() showHighlightToggle = true;
  @Input() showHighlights = false;
  @Input() searchModule = '';

  @Output() changePaginationOptions = new EventEmitter<PaginationParameters>();
  @Output() clickTab = new EventEmitter<any>();
  @Output() toggleFilters = new EventEmitter<boolean>();
  @Output() changeFilters = new EventEmitter<FiltersChangedEvent>();

  @ViewChild('filtersBar') filtersBar: FilterSidebarComponent;

  sortArray: Array<SelectOption> = [
    {
      value: 'analysisEndDate,DESC',
      label: 'Newest First',
    },
    {
      value: 'analysisEndDate,ASC',
      label: 'Oldest First',
    },
    {
      value: 'deviceManufacturer,ASC',
      label: 'Manufacturer',
    },
  ];

  filtersApplied = false;
  _searchTerm: string;
  maxAmountResultsExceededMessage =
    'Your search query returned more than 10000 results.\nIf you don\'t see what you\'re looking for, try adjusting\nyour search terms or using filters.';
  iconClassInfo = 'icon-info';
  readonly search = NavigationModules.SEARCH;

  private _filters: AnalysisFilter[];

  ngOnInit() {
    this.filtersApplied = this.filters?.find((f) => f.options.filter((o) => o.selected).length > 0) !== undefined;
    this.filters = this.filters?.map((component) => {
      if (component.field === 'analysisYear') {
        component.options = component.options.filter((option) => option.label !== 'In Progress');
      }
      return component;
    });
  }

  getFilteredTabs(tabs: Array<ContentNavigatorTab>): Observable<Array<ContentNavigatorTab>> {
    return of(tabs);
  }

  getShowedTabs(tabs: Array<ContentNavigatorTab>): Observable<Array<ContentNavigatorTab>> {
    return this.getFilteredTabs(tabs).pipe(
      map((filteredTabs) => {
        return filteredTabs.filter((t) => !t.hide);
      })
    );
  }

  /**
   * Emits an event when a new tab is clicked
   * @param tab the newly selected tab
   */
  onClickTab(tab: ContentNavigatorTab, tabGroupId: string) {
    this.clickTab.emit({ tab, tabGroupId });
  }

  /**
   * Emits an event when the pagination options changes
   * @param options the new pagination options
   */
  onChangePaginationOptions(options: PaginationParameters) {
    this.changePaginationOptions.emit(options);
  }

  /**
   * Emits an event when the button to toggle the sidebar is clicked
   */
  onToggleFilters() {
    this.toggleFilters.emit(!this.filtersCollapsed);
  }

  /**
   * Emits an event if the selected filters change
   * @param e the event
   */
  onFiltersChanged(e: FiltersChangedEvent) {
    this.filtersApplied = e.applied.length > 0;
    this.changeFilters.emit(e);
  }

  /**
   * Clears all selected filters
   */
  clearFilters() {
    this.filtersBar.clearSelectedFilters();
  }
}
