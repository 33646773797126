import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare const piTracker: (absoluteUrl: string, options?: object) => void;

// Pardot Docs:
// https://help.salesforce.com/s/articleView?id=000384195&type=1
// https://help.salesforce.com/s/articleView?id=sf.pardot_basics_cookies.htm&type=5

@Injectable({
  providedIn: 'root'
})
export class PardotTrackerService {

  constructor(
    private router: Router,
  ) { }

  public pageViewTrack() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = new URL(event.url, window.location.origin);
        this.trackPageView(url.toString());
      }
    });
  }

  private trackPageView(url: string) {
    if (typeof piTracker !== 'undefined') {
      piTracker(url);
    }
  }
}
