import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import { PreviousPageService } from '@app/@shared/services/previous-page.service';

@Component({
  selector: 'app-roadmap-request-access',
  templateUrl: './roadmap-request-access.component.html',
  styleUrls: ['./roadmap-request-access.component.scss']
})
@UntilDestroy()
export class RoadmapRequestAccessComponent implements OnInit {

  constructor(
    private entitlementService: EntitlementService,
    private navigationService: NavigationService,
    private previousPageService: PreviousPageService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const { subscriptionId } = this.route.parent.snapshot.params;
    const moduleName = this.route.snapshot.data.module;

    this.navigationService.getSubscription(subscriptionId, moduleName)
      .pipe(untilDestroyed(this))
      .subscribe(({ name }) => {
        this.entitlementService.openRequestAccessConfirmDialog(`${name}: Upcoming Analysis`, () => this.handleOnclose());
      });
  }

  private handleOnclose(): void {
    this.previousPageService.navigateBack();
  }
}
