import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {
  loadSettingsSuccess,
  saveNotificationSettingsFailure,
  saveNotificationSettingsSuccess,
  saveUserProfileFailure,
  SettingsActionTypes
} from 'src/app/store/Actions/settings/settings.actions';
import {initialSettingsState} from 'src/app/store/Reducers/settings/settings.reducer';
import {of} from 'rxjs';
import {AuthenticationService, Profile} from 'src/app/auth';
import {IUserProfile} from 'src/app/store/Models/settings/settingsModel';
import { setUserProfile } from '@app/store/Actions/workspace/workspace.actions';
import { AlertService } from '@app/@shared/services/alert/alert-service';
import { AlertMessages } from '@app/@shared/models/alert';

/**
 * Side effect for settings actions
 */
@Injectable()
export class SettingsEffects {

  /**
   * Side effect to fetch settings
   */
  GetSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActionTypes.loadSettings),
        // Using exhaustMap since we only want ONE sub to getNotifications to pass
        // and ignore the rest even if the action is triggered
        exhaustMap(action => {
          return of(loadSettingsSuccess({ data: initialSettingsState }));
        })
      )
  );

  /**
   * Side effect to save the user profile
   *
   * has a mapping of the profile interface to the accepted request body object
   */
  SaveUserProfile$ = createEffect(
    () =>
      this.actions$.pipe(ofType(SettingsActionTypes.saveUserProfile)).pipe(
        // Using exhaustMap since we only want ONE sub to getNotifications to pass
        // and ignore the rest even if the action is triggered
        exhaustMap((action) => {
          const actionProfile: IUserProfile = (action as any).userProfile as IUserProfile;
          const updatedProfile: Profile = new Profile(actionProfile)
          return this.auth.updateProfile(updatedProfile)
            .pipe(
              map((profile: Profile) => setUserProfile({ userProfile: updatedProfile.toIUserProfile() })),
              tap((_) => { this.alertService.success(AlertMessages.SETTINGS_SAVED) })
            )
        }),
        catchError(async (err) => {
          // TODO: Handle other types of error regarding types and such
          this.alertService.error(AlertMessages.SAVE_ERROR);
          return saveUserProfileFailure({ error: err });
        })
      )
  );

  /**
   * Side effect to save notification settings
   *
   * Right now it's not used since the notification settings section is TBD
   */
  SaveNotificationSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActionTypes.saveNotificationSettings),
        // Using exhaustMap since we only want ONE sub to getNotifications to pass
        // and ignore the rest even if the action is triggered
        exhaustMap((action: any) => {
          return of(saveNotificationSettingsSuccess({ notificationSettings: action.notificationSettings }));
        }),
        catchError(async (err) => {
          // TODO: Handle other types of error regarding types and such
          return saveNotificationSettingsFailure({ error: err });
        })
      )
  );

  constructor(
    private actions$: Actions,
    private auth: AuthenticationService,
    private alertService: AlertService
  ){}

}
