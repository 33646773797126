<div
  class="card-container"
  [class.max-columns]="subscriptions?.length >= maxCardColumns"
>
  <div
    class="subscription"
    *ngFor="let subscription of subscriptions"
    [class.heap-non-entitled-vertical]="!subscription.bypassEntitlement && (!subscription.entitled || subscription.availableChannels === 0) "
    (click)="onClickSubscription(subscription)"
  >
    <div 
      class="card-icon"
      [class]="subscription.icon"
    ></div>
    <div
      [ngClass]="subscription.availableChannels >= 0 ? 'card-title truncate-ellipsis' : 'card-title no-subtitle'">
      {{ subscription.name }}</div>
    <div class="card-subtitle truncate-ellipsis">{{ buildSubtitle(subscription) }}</div>
  </div>
</div>
