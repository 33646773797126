<div class="row-container header-container">
  <div>
    <app-generic-panel
      [actions]="panelActions"
      [amount]="selectedRows.length"
      [totalSize]="table?.rows?.length"
      (allSelected)="onAllRowsSelected($event)"
      (clickAction)="onClickPanelAction($event)"
    ></app-generic-panel>
  </div>
  <div class="header"></div>
  <div class="header">Title</div>
  <div class="header center">Subscription</div>
  <div class="header">Channel</div>
  <div class="header">Date</div>
</div>
<ng-container *ngFor="let row of table.rows">
  <a class="row-container"
     [href]="createRowUrl(row)"
     [attr._ti_content_navigator]="row.id"
     (click)="onClickRow(row, $event)"
     >
    <div class="it-checkbox-wrapper">
      <label class="it-checkbox" (click)="$event.stopPropagation()">
        <input
          type="checkbox"
          [checked]="!!row.selected"
          [disabled]="panelActions.length === 0"
          (change)="onSelectRow($event, row)"
        />
        <div></div>
      </label>
    </div>
    <div>
      <ng-container *ngIf="!row.entitled; else entitled">
        <em class="icon small icon-locked"></em>
      </ng-container>
      <ng-template #entitled>
        <em
          *ngIf="isAnnotationReport(row.productCode)"
          ngbTooltip="Document contains annotations"
          class="icon small icon-edit1"
        ></em>
      </ng-template>
    </div>
    <div>
      <div
        class="emphasis truncate-paragraph-ellipsis line-clamp-2"
        [innerHTML]="row.title"
      ></div>
      <div
        class="description truncate-paragraph-ellipsis line-clamp-2"
        [innerHTML]="row.description"
      ></div>
    </div>
    <div class="center"><span [class]="row.subscription" class="icon" [ngbTooltip]="row.subscriptionName" container="body"></span></div>
    <div>{{ row.channel}}</div>
    <div>
      {{ row.completionDate? (row.completionDate |date: 'MMM d, yyyy' : '+0000') : 'In progress'}}
    </div>
    <div class="center meatball-wrapper">
      <app-meatball-menu>
        <button (click)="onClickOpenNewTab($event, row)">
          <em class="icon-external"></em> Open in new tab
        </button>
        <button (click)="onClickShare($event, row)">
          <em class="icon-share"></em> Share
        </button>
        <button (click)="onClickAddToFavorites($event, row)">
          <em class="icon-star"></em> Add to Favorites
        </button>
      </app-meatball-menu>
    </div>
  </a>
</ng-container>
<div *ngIf="showNoResults" class="no-results">
  <img src="assets/images/icons/Icon_Null-Search.svg" alt="No results."/>
  <div class="text">
    <span class="main-title">
      Your search query did not find any matches.
    </span>
    <span class="sub-title">
      Try adjusting your search terms and filter settings.
    </span>
  </div>
</div>
