<div class="scroll-container">
  <div *ngIf="tabs" class="tab-bar">
    <ng-container *ngFor="let tab of tabs">
      <div *ngIf="!tab.hide"
      class="tab"
      [class.active]="tab.filterValue === activeTab.filterValue"
      (click)="onClickTab(tab)">
        {{ tab.name }}
      </div>
    </ng-container>
  </div>
  <div *ngIf="isContentLoading; else contentLoaded" class="spinner-container">
    <progress class="pure-material-progress-circular"></progress>
  </div>
</div>
<ng-template #contentLoaded>
  <section class="content-navigator-table-container">
    <ng-content></ng-content>
  </section>
</ng-template>
