<section class="post-header">
    <div *ngIf="prevPage.hasHistory()" class="back" (click)="onClickBack()">
        <em class="icon-left_small"></em><span class="hide-on-mobile">Back</span>
    </div>
    <div class="title-element">
        <span class="truncate-paragraph-ellipsis line-clamp-2">{{htmlReport?.header.title || htmlReport?.name}}</span>
    </div>
</section>
<app-generic-toolbar
  *ngIf="showDownloadButton && htmlReport && (enableDownloadButton$ | async)"
  [toolbarOptions]="toolbarOptions"
  [addRightMargin]="true"
  (propagateEvent)="executeToolbarOption($event)">
</app-generic-toolbar>
<app-hygraph
  [htmlReport]="htmlReport"
  #hygraph
></app-hygraph>
