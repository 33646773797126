<div class="progress" *ngIf="isContentLoading; else cardsContainer">
  <app-progress-bar-circular></app-progress-bar-circular>
</div>
<ng-template #cardsContainer>
  <div class="containers-grid">
    <div class="main-container">
      <div class="card-container" *ngFor="let card of cards;">
        <app-content-card [card]="card" (click)="onCardClick(card)"></app-content-card>
      </div>
    </div>
  </div>
</ng-template>
