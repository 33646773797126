<app-title-bar
  [tagNameList]="[subtitle]"
  [title]="title"
  [showBackButton]="prevPage.hasHistory()"
  (clickBack)="onClickBack()"
></app-title-bar>

<ng-container *ngIf="loadingTableViewer; else loaded">
  <div class="loading-indicator">
    <app-progress-bar-circular></app-progress-bar-circular>
  </div>
</ng-container>
<ng-template #loaded>
  <ng-container *ngIf="!this.showError; else error">
    <app-table-viewer
      *ngIf="tableViewerOptions"
      [tableViewerOptions]="tableViewerOptions"
    ></app-table-viewer>
  </ng-container>
  <ng-template #error>
    <app-generic-error
      [message]="errorMessage"
    ></app-generic-error>
  </ng-template>
</ng-template>

