import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {OTHER, US_CODE} from '../onboarding-modal.component';

export const requiredIfDepartmentOther: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const departmentControl = control?.parent?.get('departments');
  const departmentValue = departmentControl?.value;
  let isValid = departmentValue !== OTHER;

  if(departmentValue === OTHER) {
    isValid = control?.value?.length > 0;
  }

  return isValid ? null : { requiredIfDepartmentOtherError: true };
}

export const requiredIfUsIsSelected: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const countries = control?.parent?.get('countries');

  let isValid = countries?.value !== US_CODE;

  if(countries?.value === US_CODE) {
    isValid = control?.value?.length > 0;
  }

  return isValid ? null : { requiredIfUsIsSelectedError: true };
};
