import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-meatball-menu',
  templateUrl: './meatball-menu.component.html',
  styleUrls: ['./meatball-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MeatballMenuComponent {
  @Input() disabled = false;
  @Input() iconFontSize = '24px';
  @ViewChild('options') meatballOptions: ElementRef;

  public onMeatballIconClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  onHover() {
    this.meatballOptions.nativeElement.style = 'top:100%;';
    const rect = this.meatballOptions.nativeElement.getBoundingClientRect();
    if (window.innerHeight - rect.bottom > 0) {
      this.meatballOptions.nativeElement.style = 'top: 100%';
    } else {
      this.meatballOptions.nativeElement.style = 'bottom: 100%;';
    }
  }
}
