<app-module-header
  [ngStyle]="{ 'box-shadow': 'none' }"
  [icon]="'icon-menu_search_filled'"
  [title]="'Search'"
  [showContactCem]="false"
></app-module-header>
<div class="header-navigator" *ngIf="!isLoading">
  <div class="search-results-search-bar">
    <app-generic-search-bar
      [hiddenSearchButton]="hiddenSearchButton"
      [hiddenSearchLabel]="hiddenSearchLabel"
      [chipList]="(ifMainSearchTriggred && searchTerm) || ''"
      [isSearchResult]="true"
      [setRecentSearches]="recentSearchTerms"
      (searchTermsChanged)="onChangeSearchTerms($event)"
    ></app-generic-search-bar>
  </div>
</div>

<div *ngIf="isLoading; else loaded" class="progress">
  <app-progress-bar-circular [size]="sizeOption"></app-progress-bar-circular>
  <div class="progress-message">Loading search results...</div>
</div>

<ng-template #loaded>
  <app-tabbed-analysis-list
    [tabGroups]="tabGroups"
    [activeTabGroup]="activeTabGroup"
    [activeTab]="activeTab"
    [count]="count"
    [page]="paginationOptions.page"
    [pageSizes]="pageSizes"
    [pageSize]="paginationOptions.size"
    [showHighlightToggle]="showHighlightToggle"
    [showHighlights]="paginationOptions.showHighlights"
    [filters]="filters"
    [showFilters]="showFilters"
    [filtersCollapsed]="filtersCollapsed"
    [searchTerm]="searchTerm"
    [breadcrumbString]="getBreadcrumbString()"
    [loading]="loadingTable"
    [sortOptions]="sortOptions"
    [defaultSort]="selectedSortOption"
    [searchModule]="searchModule"
    (changePaginationOptions)="onChangePaginationOptions($event)"
    (clickTab)="onClickTab($event)"
    (changeFilters)="onChangeFilters($event)"
    (toggleFilters)="onToggleFilters($event)"
  ></app-tabbed-analysis-list>
</ng-template>
