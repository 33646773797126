import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { DynamicDataTreeHelper } from '@shared/models/market-analysis/dynamic-data-tree-helper';
import {
  CHANNEL_FIELD,
  FILTERS_NOT_SHOWN_IN_SIDEBAR,
  SIMPLE_FILTERS_FIELD_NAME,
  SUBSCRIPTION_FIELD,
} from 'src/app/strategy-analytics/services/strategy-analytics-icon-map';
import {
  CSSContentCell,
  CSSRow,
  CSSRowGroup,
  CSSTable,
} from '@app/manufacturing/models/manufacturing.models';
import {
  DynamicContentTable,
  DynamicDataHierarchy,
  DynamicHierarchyFrequencies,
  MarketAnalysisChannelExcerpt,
  MarketAnalysisSubscription,
  Report,
  ReportFileTypeMap,
  ReportsPage,
  SurveyContentType,
} from '@app/@shared/models/market-analysis/market-analysis';
import { ChannelCard, SubscriptionCard } from '@shared/models/shared.models';
import { PaginationParameters } from '@shared/models/pagination';
import { ContentNavigatorTablePage } from '@app/@shared/models/content-navigator/content-navigator.model';
import {
  AnalysisAsset,
  AnalysisFilter,
  AnalysisFilterFacet,
  AnalysisFilterType,
  AnalysisMetadataResponse,
  AnalysisReportPage,
  AssetGroup, CombinedAssets,
  Filters,
  IImageFilter,
  QuicksighDashboard,
  RelatedContent
} from '@app/@shared/models/reverse-engineering/analysis.models';
import { ContentLakeService } from '../content-lake.service';
import { PaginationHelper } from '@shared/utils/pagination-helper';
import { FilterHelper } from '@app/@shared/utils/filter-helper';
import { FeatureFlagService } from '../featureflag.service';
import { Channel, NavigationElement, NavigationModules, NavigationObjectType, Subscription } from '@shared/models/navigation/navigation.models';
import { NavigationService } from '@shared/services/navigation/navigation.service';
import { SearchService } from '../search/search.service';
import { CTISearchResult } from '../../models/search/cti-search.model';
import { WebCVJSONObject } from '@app/@shared/models/kimera/webCVJSON';
import { RegularExpressions } from '@shared/expresions/regular-expressions';
import { REPORT_ORDER_TYPE } from '@shared/enums';
import { defaultSort } from '@app/@shared/consts';

@Injectable({
  providedIn: 'root',
})
export class MarketAnalysisService {
  private entitlementUrl = environment.entitlementServiceUrl;
  private metaUrl = environment.metaServiceBaseUrl;
  private vlsiUrl = environment.vlsiDynamicDbBaseUrl;
  private stashUrl = environment.stashServiceBaseUrl;
  private readonly ACCEPT_HEADERS = new HttpHeaders().set('Accept', 'application/json');
  private readonly PAGINATION_PARAMS = new PaginationParameters(500, 1, defaultSort.value);

  constructor(
    private http: HttpClient,
    private ffService: FeatureFlagService,
    private navigationService: NavigationService,
    private contentLakeService: ContentLakeService,
    private searchService: SearchService
  ) { }

  getSubscriptionExcerpts(): Observable<Array<SubscriptionCard>> {
    return this.navigationService.getModuleCardsByName(NavigationModules.MANUFACTURING_ANALYSIS).pipe(
      map((response) => {
        const subscriptions = response as Subscription[];
        return subscriptions.map((subscription) => ({
          id: subscription.id,
          entitled: true,
          icon: 'icon-manufacturing_analysis',
          name: subscription.name,
          isCustom: subscription.isCustom,
          availableChannels: subscription.availableChannels,
          totalChannels: subscription.totalChannels,
        }));
      })
    );
  }

  getSubscriptionWNav(subscriptionId: string): Observable<Subscription> {
    return this.navigationService.getAllSubscriptions().pipe(
      map((subscriptions) => subscriptions.find((sub) => sub.id === subscriptionId)),
    )
  }

  getSubscriptionByName(subscriptionName: string) {
    return this.getSubscriptionInternal((sub) => sub.nameUwa === subscriptionName);
  }

  /**
   * returns a subscription given a subscription id
   * @param subscriptionId string representing the id of a subscription
   * @returns Observable of MarketAnalysisSubscription
   */
  getSubscription(subscriptionId: string): Observable<MarketAnalysisSubscription> {
    return this.navigationService.getSubscription(subscriptionId, NavigationModules.MANUFACTURING_ANALYSIS).pipe(
      map((subscription) => {
          return {
              id: subscription.id,
              name: subscription.name,
              entitled: subscription.entitled,
              briefingFilename: subscription.briefingFilename,
              channels: subscription.children.map((child) => this.buildChannelExcerpt(child))
            }
      }))
  }

  getMaSubChannelCards(channelId: string): Observable<ChannelCard[]> {
    return this.navigationService.getChannel(channelId, NavigationModules.MANUFACTURING_ANALYSIS)
    .pipe(
      map((channel) => {
        return channel.children
        .filter((child) => child.type === NavigationObjectType.REPORT_MODULE)
      }),
      concatMap((subChannels: NavigationElement[]) => {
        const mergedIds = subChannels.reduce(
          (previousValue: string, currentValue: NavigationElement) => `${previousValue},${currentValue.id}`,
          ''
        );
        const countingUrl = `${this.metaUrl}/products/counting`;
        const headers = new HttpHeaders().set('Accept', 'application/json');
        const params = new HttpParams().set('report_module', mergedIds);
        return this.http
          .get<any>(countingUrl, { headers, params })
          .pipe(
            map((reportCounts) => {
              const subChannelCardInfo: ChannelCard[] = [];
              subChannels.forEach((subChannel: Channel) => {
                const countInfo = reportCounts.products.find(
                  (count:any) => subChannel.id === count.report_module
                );
                subChannelCardInfo.push(
                  new MarketAnalysisChannelExcerpt(
                    subChannel.id,
                    subChannel.name,
                    subChannel.entitled,
                    false,
                    0,
                    0,
                    countInfo?.count
                  )
                )
              });
              return subChannelCardInfo;
            })
          );
      })
    )
  }

  /**
   * getSubscriptionBriefXodUrl gets a signed XOD URL for the given PDF filename.
   *
   * @param fileName PDF filename to get the XOD URL for.
   */
  getSubscriptionBriefXodUrl(fileName: string): Observable<any> {
    const url = `${this.metaUrl}/user/subscription-brief/download?fileName=${fileName}`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get(url, { headers });
  }

  /**
   * Get reports ONLY for channels. Do not use this for subchannels
   * @returns Observable<Array<Report>> object
   */
  getChannelReports(channelId: string, pagination?: PaginationParameters): Observable<ReportsPage> {
    return this.getReportsInternal(
      PaginationHelper.withPaginationHeaders(pagination, new HttpParams().set('channels', channelId))
    );
  }

  /**
   * Get reports ONLY for SubChannels. Do not use this for channels
   *
   * @returns Observable<Array<Report>> object
   */
  getSubChannelReports(subChannelId: string, pagination?: PaginationParameters): Observable<ReportsPage> {
    return this.getReportsInternal(
      PaginationHelper.withPaginationHeaders(pagination, new HttpParams().set('report_module', subChannelId))
    );
  }

  /**
   * Search reports ONLY for channels. Do not use this for subchannels
   * @returns Observable<Array<Report>> object
   */
  searchChannelReports(
    channelId: string,
    searchTerm: string,
    pagination?: PaginationParameters
  ): Observable<ReportsPage> {
    return this.getReportsInternal(
      PaginationHelper.withPaginationHeaders(
        pagination,
        new HttpParams().set('channels', channelId).set('searchTerm', searchTerm)
      )
    );
  }

  /**
   * Search reports ONLY for SubChannels. Do not use this for channels
   *
   * @returns Observable<Array<Report>> object
   */
  searchSubChannelReports(
    subChannelId: string,
    searchTerm: string,
    pagination?: PaginationParameters
  ): Observable<ReportsPage> {
    return this.getReportsInternal(
      PaginationHelper.withPaginationHeaders(
        pagination,
        new HttpParams().set('report_module', subChannelId).set('searchTerm', searchTerm)
      )
    );
  }

  getAllChannels(): Observable<Channel[]> {
    return this.navigationService.getAllChannels();
  }

  /**
   * Gets all the metadata for the report
   * @param reportId - The report id
   * @returns An object with AnalysisMetadataResponse
   */
  getAnalysisMetadata(reportId: string): Observable<AnalysisMetadataResponse> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http
      .get(`${this.metaUrl}/report/assetGroup/${reportId}`, { headers })
      .pipe(map((response: any) => response as AnalysisMetadataResponse));
  }

  /**
   * Gets related content list from a given report
   * @param reportId - The report id
   * @returns A list with RelatedContent
   */
  getRelatedContent(reportId: string): Observable<RelatedContent[]> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get(`${this.metaUrl}/report/${reportId}/related_content`, { headers }).pipe(
      map((response: any) => {
        return this.mapToRelatedContent(response);
      })
    );
  }

  mapToRelatedContent(relatedContentList: any): RelatedContent[] {
    const filteredList = relatedContentList.filter((relatedContent: any) => relatedContent.type !== 'PDF');

    return filteredList.map((relatedContent: any) => ({
      id: relatedContent.id ?? '',
      name: relatedContent.name ?? '',
      entitled: relatedContent.entitled ?? false,
      type: relatedContent.type ?? '',
      description: relatedContent.description ?? '',
      publishedDate: relatedContent.publishedDate ?? '',
      reportCode: relatedContent.reportCode ?? '',
      fileId: relatedContent.fileId ?? '',
      fileType: relatedContent.fileType ?? '',
      clObjectId: relatedContent.clObjectId ?? '',
    })).sort((a: RelatedContent, b: RelatedContent) => {
      const dateA = a.publishedDate ? new Date(a.publishedDate).getTime() : 0;
      const dateB = b.publishedDate ? new Date(b.publishedDate).getTime() : 0;
      return dateB - dateA;
    });
  }

  /**
   * Gets a list of folders within the report in order to use them as image filters
   * @param reportId - The report id
   * @param baseAssetGroup - The report assetGroupId
   * @returns An array with IImageFilter
   */
  getImageFilters(reportId: string): Observable<IImageFilter[]> {
    const params = new HttpParams()
      .set('assetClassifications', 'IMAGE');

    return this.http.get(`${this.metaUrl}/assetgroup/report/${reportId}`, { params }).pipe(
      map((assetGroupList: any) => {
        return this.mapToIImageFilter(assetGroupList);
      })
    );
  }

  /**
   * Maps the object response from /assetgroup/report/ to an IImageFilter object
   * @param assetGroupList - The response from endpoint
   * @returns A IImageFilter with the data from the endpoint
   */
  mapToIImageFilter(assetGroupList: any): IImageFilter[] {
    return assetGroupList.map((assetGroup: any) => ({
      key: assetGroup.assetGroupId,
      label: assetGroup.name,
      value: assetGroup.assetGroupId,
      amount: assetGroup.size,
      children: assetGroup.childrenAssetGroups.length > 0 ? this.mapToIImageFilter(assetGroup.childrenAssetGroups) : [],
      parentKey: assetGroup.parentAssetGroup,
      level: assetGroup.level,
      expanded: false,
    }));
  }

  getAnalysisWebCVJSON(reportId: string): Observable<WebCVJSONObject> {
    return this.http.get(`${this.metaUrl}/asset/webcv/${reportId}`).pipe(
      concatMap((webCVAssets: any) => {
        if (webCVAssets[0] && webCVAssets[0].contentLakeObjectId) {
          const $obs1 = this.contentLakeService.downloadContentLakeObjectBlob(webCVAssets[0].contentLakeObjectId);
          const $obs2 = of(webCVAssets[0].contentLakeObjectId);
          return forkJoin([$obs1, $obs2])
            .pipe(
              concatMap(([blob, contentLakeObjectId]) => {
                return forkJoin([blob.text(), of(contentLakeObjectId)]);
              }),
              map(([webCVJSONString, contentLakeObjectId]) => {
                return {
                  webCVJSON: JSON.parse(webCVJSONString),
                  contentLakeObjectId
                }
              })
            )
        } else {
          return of(null)
        }
      })
    );
  }


  /**
   * Get all assets from a report folder
   * @param assetGroupId - The folder id
   * @param reportId - The report id
   * @param paginationParameters - Pagination variables
   * @param fileType - A string with the asset type: 'image' | 'pdf'
   * @param ignoreFolders - An array with the assetGroupId we want to ignore
   * @returns An object with the asset list and the total assets amount
   */
  getAnalysisReports(
    assetGroupId: string,
    reportId: string,
    paginationParameters: PaginationParameters,
    fileType: string[],
    ignoreFolders: string
  ): Observable<AnalysisReportPage> {
    let paramsWithoutPagination;

    if (ignoreFolders && ignoreFolders !== 'none') {
      paramsWithoutPagination = new HttpParams()
        .set('sort', 'assetName,ASC')
        .set('assetGroupId', assetGroupId)
        .set('reportId', reportId)
        .set('assetClasses', fileType.join(','))
        .set('ignoreFolders', ignoreFolders);
    } else {
      paramsWithoutPagination = new HttpParams()
        .set('sort', 'assetName,ASC')
        .set('assetGroupId', assetGroupId)
        .set('reportId', reportId)
        .set('assetClasses', fileType.join(','));
    }

    const params = PaginationHelper.withPaginationHeaders(paginationParameters, paramsWithoutPagination);
    if (fileType.includes('image')) {
      return this.http.get(`${this.metaUrl}/assetgroup/flat-assets`, { params }).pipe(
        map((reportPage: any) => ({
          reports: reportPage.assetList,
          count: reportPage.totalAssets,
        })),
        catchError(_ => { return of({ reports: [],
          count:0 }) })
      );
    } else {
      const assetGroupsParams = new HttpParams().set(
        'assetClassifications',
        'OTHER,PCB_IMAGE,CIRCUIT_VISION,DATA_GRID,PDF'
      );
      return combineLatest([
        this.http.get(`${this.metaUrl}/assetgroup/${assetGroupId}`, { params })
          .pipe(catchError(_ => { return of({ assets: [] }) })),
        this.http.get(`${this.metaUrl}/assetgroup/report/${reportId}`, {
          params: assetGroupsParams,
        }),
      ]).pipe(
        map(([reportPage, assetGroups]) => {
          const filteredWebCVAssets = this.filterWebCVAssets(this.
            buildReports(reportPage, reportId)) as AnalysisAsset[];
          const groups = this.filterWebCVAssets(this.buildAssetGroups(assetGroups)) as AssetGroup[];

          const orderedReports = this.orderAssets(filteredWebCVAssets) as AnalysisAsset[];
          const orderedGroups = this.orderAssets(groups) as AssetGroup[];
          const count = orderedReports.length + orderedGroups.length;
          return {
            reports: orderedReports,
            assetGroups: orderedGroups,
            count
          }
        })
      );
    }
  }

  filterWebCVAssets(assets: CombinedAssets): CombinedAssets {
    return assets.filter(asset => asset.assetName &&
      asset.assetName.toLowerCase() !== 'webcv.json' // file
      && asset.assetName.toLowerCase() !== 'webcv') // folder or group
  }

  orderAssets(assets: CombinedAssets): CombinedAssets {
    const pinnedReports: AssetGroup[] = [];
    const datedReports: AssetGroup[] = [];
    const rest: AssetGroup[] = [];

    assets.forEach((asset) => {
      const { assetName } = asset;
      if (RegularExpressions.startsWithPinned.test(assetName)) {
        pinnedReports.push(asset);
      } else if (RegularExpressions.startsWithYear.test(assetName)) {
        datedReports.push(asset);
      } else {
        rest.push(asset);
      }
    })

    return [
      ...this.formatPinnedAssets(pinnedReports),
      ...this.reorderContentSubGroup(datedReports, REPORT_ORDER_TYPE.DATE),
      ...this.reorderContentSubGroup(rest, REPORT_ORDER_TYPE.DEFAULT),
    ];
  }

  reorderContentSubGroup(assets: CombinedAssets, groupType: REPORT_ORDER_TYPE) {
    switch (groupType) {
      case REPORT_ORDER_TYPE.PINNED:
        this.reorderPinnedAssets(assets);
        break;
      case REPORT_ORDER_TYPE.DATE:
        this.reorderDatedAssets(assets);
        break;
      case REPORT_ORDER_TYPE.DEFAULT:
        assets.sort((
          { assetName: assetNameA },
          { assetName: assetNameB }) => assetNameA.localeCompare(assetNameB));
        break;
      default:
        return assets;
    }
    return assets;
  }

  reorderPinnedAssets(assets: CombinedAssets) {
    assets.sort((a, b) => {
      const [
        [prefixA = ''],
        [prefixB = ''],
      ] = [a.assetName, b.assetName].map((name) => /\d/.exec(name));
      return Number(prefixA) - Number(prefixB);
    });
  }

  reorderDatedAssets(assets: CombinedAssets) {
    assets.sort((a, b) => {
      const [
        [prefixA = ''],
        [prefixB = ''],
      ] = [a.assetName, b.assetName].map((name) => RegularExpressions.startsWithYear.exec(name));

      const dateTimeA = new Date(prefixA).getTime();
      const dateTimeB = new Date(prefixB).getTime();
      return dateTimeB - dateTimeA;
    })
  }

  formatPinnedAssets(assets: CombinedAssets) {
    const reorderedPinnedSubGroup = this.reorderContentSubGroup(assets, REPORT_ORDER_TYPE.PINNED);
    this.trimPrefix(reorderedPinnedSubGroup);

    return reorderedPinnedSubGroup;
  }

  trimPrefix(assets: CombinedAssets) {
    assets.forEach((asset) => {
      asset.assetName = asset.assetName.replace(RegularExpressions.startsWithPinned, '');
    })
  }

  /**
   * This method is used to download a given XLSX report
   * @param clObjectId The content lake object id for the report
   * @returns Observable<Blob> the report to be downloaded
   */
  downloadXLSXReport(clObjectId: string): Observable<Blob> {
    return this.contentLakeService.downloadContentLakeObjectBlob(clObjectId);
  }

  getPostContent(link: any) {
    const url = link;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get<any>(url, { headers });
  }

  /**
   * getDynamicHierarchy gets the full dynamic content hierarchy for a VIC.
   *
   * @param vicCode VLSI Internal Code for the root node.
   * @returns DynamicDataHierarchy given a VIC.
   */
  getDynamicHierarchy(vicCode: string): Observable<DynamicDataHierarchy> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const paramsYearly = new HttpParams().set('frequency', DynamicHierarchyFrequencies.YEARLY);
    const paramsQuarterly = new HttpParams().set('frequency', DynamicHierarchyFrequencies.QUARTERLY);
    const url = `${this.vlsiUrl}/hierarchy/${vicCode}`;

    const yearly$ = this.http.get<any>(url, { headers, params: paramsYearly });
    const quarterly$ = this.http.get<any>(url, { headers, params: paramsQuarterly });

    return forkJoin([yearly$, quarterly$]).pipe(
      map((response: any[]) => {
        const yearlyHierarchy = DynamicDataTreeHelper.buildDynamicDataTreeNodeChildren(response[0]);
        const quarterlyHierarchy = DynamicDataTreeHelper.buildDynamicDataTreeNodeChildren(
          response[0],
          DynamicDataTreeHelper.getAllVicCodes(response[1])
        );
        return {
          yearly: yearlyHierarchy,
          quarterly: quarterlyHierarchy,
        };
      })
    );
  }

  /**
   * getDynamicHierarchyCriticalSubsystems gets the full dynamic content hierarchy for a Critical Subsystems.
   *
   * @param vicCode VLSI Internal Code for the root node.
   * @returns DynamicDataHierarchy given a VIC.
   */
  getDynamicHierarchyCriticalSubsystems(vicCode: string): Observable<DynamicDataHierarchy> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const params = new HttpParams().set('frequency', DynamicHierarchyFrequencies.CRITICAL_SUBSYSTEMS);
    const url = `${this.vlsiUrl}/hierarchy/${vicCode}`;
    const response$ = this.http.get<any>(url, { headers, params });
    return response$.pipe(
      map((response: any) => ({
        yearly: DynamicDataTreeHelper.buildDynamicDataTreeNodeChildren(response),
        quarterly: null,
      }))
    );
  }

  /**
   * getDynamicContentTable gets VLSI dynamic content for a period.
   *
   * @param vicCode VLSI Internal Code to get content for.
   * @param period Period (yearly/quarterly).
   * @returns DynamicContentTable given a VIC and period.
   */
  getDynamicContentTable(vicCode: string, period: DynamicHierarchyFrequencies): Observable<DynamicContentTable> {
    const url = `${this.vlsiUrl}/${period.toLocaleLowerCase()}`;
    return this.getDynamicContentTableInternal(url, vicCode, period);
  }

  /**
   * getDynamicContentTableCriticalSubsystems gets VLSI dynamic content for Critical Subsystems.
   *
   * @param vicCode VLSI Internal Code to get content for.
   * @returns DynamicContentTable given a VIC.
   */
  getDynamicContentTableCriticalSubsystems(
    vicCode: string,
    period: DynamicHierarchyFrequencies
  ): Observable<DynamicContentTable> {
    const url = `${this.vlsiUrl}/critical_subsystems`;
    return this.getDynamicContentTableInternal(url, vicCode, period);
  }

  /**
   * Download a excel file for a list
   *
   * @returns Blob to be saved
   */
  downloadVLSITables(vicCodeList: Array<string>, period: DynamicHierarchyFrequencies): Observable<Blob> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const body = {
      vicCodeList,
      contentType: period,
    };

    return this.http
      .post<any>(`${this.vlsiUrl}/download`, body, { headers })
      .pipe(concatMap(({ url }) => this.http.get(url, { responseType: 'blob' })));
  }

  /**
   * Download Customer Satisfaction Survey data
   *
   * @returns Blob to be saved
   */
  downloadSurveyData(sf_product_code: string, view: SurveyContentType): Observable<Blob> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const params = new HttpParams().set('content_type', view.valueOf());
    return this.http
      .get<any>(`${this.vlsiUrl}/css/download/${sf_product_code}`, { headers, params })
      .pipe(concatMap((url) => this.http.get(url, { responseType: 'blob' })));
  }

  /**
   * This method builds CSS Table from a list of reports. This saves time, in case
   * caller already has requested reports, and wants to show as a CSS table, knowing
   * before hand these are CSS reports
   *
   * @param reports from which table will be construct
   * @returns Customer Satisfaction Survey Table made upon reports
   */
  getCustomerSatisfactionSurveyTableForReports(channelId: string): Observable<CSSTable> {
    return forkJoin([
      this.http.get<any>(`${this.vlsiUrl}/css/table_static_content`, {
        headers: this.ACCEPT_HEADERS,
      }),
      this.searchService.cTISearch<CTISearchResult>([], this.PAGINATION_PARAMS, {
        channelId: [channelId],
        yearOfAnalysis: [environment.cssYearOfAnalysis],
      }),
    ]).pipe(
      map(([table, search]) => {
        // Filter out columns without report_module (just 'title')
        const columns = table.columns
          .filter((column: any) => column.report_module);

        return {
          // Get column titles
          columns: table.columns.map((column: any) => ({ title: column.name })),
          // Fill rows with data
          rowGroups: table.rows.map((row: any): CSSRowGroup => ({
            title: row.name,
            rows: this.buildCSSRows(search.results, columns, row.children),
          })),
        };
      })
    );
  }

  getChannelCards(moduleName: string): Observable<Array<MarketAnalysisChannelExcerpt>> {
    return this.navigationService.getModuleCardsByName(moduleName).pipe(
      concatMap((response: Channel[]) => {
        const mergedIds = response.reduce(
          (previousValue: string, currentValue: Channel) => `${previousValue},${currentValue.id}`,
          '',
        );

        const countingUrl = `${this.metaUrl}/products/counting`;
        const headers = new HttpHeaders().set('Accept', 'application/json');
        const params = new HttpParams().set('channels', mergedIds);

        return this.http.get<any>(countingUrl, { headers, params }).pipe(
          map((channelCounts) => {
            const channelCardInfo: Array<MarketAnalysisChannelExcerpt> = [];

            response.forEach((channel: Channel) => {
              const channelInfo = channelCounts.products.find(
                (channelCount: { channel: string }) => channel.id === channelCount.channel
              );
              if(channelInfo?.count > 0){
                channelCardInfo.push(
                  new MarketAnalysisChannelExcerpt(
                    channel.id,
                    channel.name,
                    channel.entitled,
                    false,
                    0,
                    0,
                    channelInfo?.analysis_count_new
                  )
                )
              }
            });

            return channelCardInfo;
          })
        );
      })
    );
  }

  /**
   * Builds a Report Analysis table. By default the search is performed in
   * the channels of all Market Analysis modules
   * @param searchTerms one or many terms, user input
   * @param filters Filters
   * @param paginationParameters PaginationParameters
   * @returns Report Analysis table
   */
  getSearchResults(
    searchTerms: string,
    filters: Filters,
    paginationParameters: PaginationParameters
  ): Observable<ContentNavigatorTablePage> {
    if (!filters.hasOwnProperty('channel')) {
      return combineLatest([
        this.navigationService.getNonSubscriptionModuleChannels(NavigationModules.PROCESSOR_ANALYSIS),
        this.navigationService.getModuleChannelsByName(NavigationModules.MANUFACTURING_ANALYSIS),
        this.navigationService.getModuleChannelsByName(NavigationModules.STRATEGY_ANALYTICS),
        this.navigationService.getNonSubscriptionModuleChannels(NavigationModules.THE_MCCLEAN_REPORT),
        this.navigationService.getModuleChannelsByName(NavigationModules.MARKET_SEGMENTS)
      ])
        .pipe(
          map(([paChannels, maChannels, saChannels, mcCleanChannels, msChannels]) => {
            const allChannels = [...paChannels, ...maChannels, ...saChannels, ...mcCleanChannels, ...msChannels];
            const idChannels = allChannels.map((channel) => channel.id);
            return idChannels;
          }),
          concatMap((idChannels) => {
            const allFilters = { ...filters, channel: idChannels };
            return this.searchService.getTable(searchTerms, allFilters, paginationParameters);
          })
        );
    }
    return this.searchService.getTable(searchTerms, filters, paginationParameters);
  }

  /**
   * Gets the analysis filters related to market analysis
   * by combining facets and navigation services
   * @returns a list of the filters
   */
  getAnalysisFiltersForSidebar(): Observable<AnalysisFilter[]> {
    return combineLatest([
      this.navigationService.getNonSubscriptionModuleChannels(NavigationModules.PROCESSOR_ANALYSIS),
      this.navigationService.getModuleCardsByName(NavigationModules.MANUFACTURING_ANALYSIS),
      this.navigationService.getModuleCardsByName(NavigationModules.STRATEGY_ANALYTICS),
      this.navigationService.getNonSubscriptionModuleChannels(NavigationModules.THE_MCCLEAN_REPORT),
      this.navigationService.getModuleCardsByName(NavigationModules.MARKET_SEGMENTS),
      this.getFacets(),
    ]).pipe(
      map(([paChannels, maSubscriptions, saSubscriptions, mcCleanChannels, msChannels, facets]) => {
        const allSubscriptions = [...maSubscriptions, ...saSubscriptions, ...msChannels];
        const allNonSubscriptions = [...paChannels, ...mcCleanChannels];
        return facets
          .map((f) => this.buildFilter(f, allSubscriptions, allNonSubscriptions))
          .sort((a, b) => {
            if (SIMPLE_FILTERS_FIELD_NAME.includes(a.field)) {
              return -1;
            } else if (SIMPLE_FILTERS_FIELD_NAME.includes(b.field)) {
              return 1;
            }
            return 0;
          });
      })
    );
  }

  /**
   * Get A list of analysis for a content navigator table
   * @param channelId channelId to filter analysis
   * @param pagination pagination parameters for analysis
   * @param searchTerm a search term for analysis
   * @returns a result that can be formatted for a content navigator table
   * Any type return as this method returns raw backend data to be mapped by other services
   * ! This method should only be used by other services and not directly in a component
   */
  getAnalysisByChannelId(channelId: string, pagination?: PaginationParameters, searchTerm?: string): Observable<any> {
    const filters: Filters = { channel: [channelId] };

    if (!pagination) {
      pagination = new PaginationParameters(1, 50);
    }

    if (!pagination.sort) {
      pagination.sort = 'releaseDate,DESC';
    }

    searchTerm = searchTerm ?? '';

    return this.searchService.cTISearch<CTISearchResult>(
      searchTerm.split(','),
      pagination,
      filters,
    ).pipe(
      map((ctiResults) => ({
        reports: ctiResults.results
          .map((ctiResult) => ({
            id: ctiResult.id,
            name: ctiResult.name,
            channel: ctiResult.channelName,
            description: ctiResult.description,
            analysisEndDate: ctiResult.analysisEndDate ? new Date(ctiResult.analysisEndDate) : null,
            documentsCount: ctiResult.documentsCount,
            code: ctiResult.code,
            entitled: ctiResult.entitled,
          })),
        totalElements: ctiResults.total,
      }))
    );
  }

  /**
   * Get subscription ids related to market analysis
   * by combining navigation service responses
   * @returns a list of the filters
   */
  getSubscriptionIdsForBlogSearch(): Observable<string[]> {
    return combineLatest([
      this.navigationService.getModuleCardsByName(NavigationModules.MANUFACTURING_ANALYSIS),
      this.navigationService.getModuleCardsByName(NavigationModules.STRATEGY_ANALYTICS),
      this.navigationService.getModuleCardsByName(NavigationModules.MARKET_SEGMENTS)
    ]).pipe(
      map(([maSubscriptions, saSubscriptions, msChannels]) => {
        const allSubscriptions : NavigationElement[] = [...maSubscriptions, ...saSubscriptions, ...msChannels];
        return allSubscriptions.map((item) => item.id);
      })
    );
  }

  /**
   * Returns a list of assets from an asset group
   * @param baseAssetGroup the base asset group id
   * @returns Array<AnalysisAsset>
   */
  getAssetsByGroup(baseAssetGroup: string): Observable<Array<AnalysisAsset>> {
    return this.http.get(`${this.metaUrl}/assetgroup/${baseAssetGroup}`).pipe(map(({ assets } : any) => assets.map((asset : any) => ({
          assetId: asset.id,
          assetName: asset.name,
          fileType: asset.file_type,
          clObjectId: asset.cl_object_id,
          fileSize: asset.file_size,
          assetGroupId: '',
          assetGroupLevel: -1,
    }))));
  }

  /**
   * Gets and quicksight url with a gibven assetId
   * @param assetId
   * @returns an quicksight url
   */
  getQuicksightEmbeddedURL(assetId: string): Observable<string> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const url = `${this.metaUrl}/dashboardasset/embedurl/${assetId}`;

    return this.http.get(url, { headers, responseType: 'text' });
  }

  /**
   * Sends a body that needs the endpoint to store the information, returns an id (string)
   * @returns id
   */
  postQuicksightSheetAndParameters(qsState: QuicksighDashboard): Observable<string> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    const body = JSON.stringify(qsState);
    const url = `${this.stashUrl}/stash`;
    return this.http.post<any>(url, body, { headers }).pipe(
      map(response => response.id),
    );
  }

  /**
   * Get the sheet id and parameters of the dashboard
   */
  getQuicksightSheetAndParameters(id: string): Observable<QuicksighDashboard> {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json');
    const url = `${this.stashUrl}/stash/${id}`;

    return this.http.get(url, { headers }).pipe(
      map((resp: any) => {
        return {
          sheetId: resp.sheetId,
          parameters: resp.parameters
        }
      })
    );
  }

  private buildReports(reportPage: any, reportIdParam?: string): AnalysisAsset[] {
    return reportPage.assets.filter((asset: any) => asset.type === 'ASSET')
      .map((asset: any): AnalysisAsset => ({
        assetId: asset.id,
        assetName: asset.name,
        fileType: asset.file_type,
        assetGroupId: reportPage.id,
        assetGroupLevel: 0,
        clObjectId: asset.cl_object_id,
        fileSize: asset.file_size,
        reportId: reportIdParam,
        postId: asset.post_id,
      }));
  }

  private buildAssetGroups(assetGroups: any): AssetGroup[] {
    return assetGroups
      .filter((asset: any) => asset.level === 1)
      .map((asset: any) => ({
        assetName: asset.name,
        assetGroupId: asset.assetGroupId,
        assetGroupLevel: 0,
        fileSize: asset.size,
      }));
  }

  /**
   * Builds rows for children recursively.
   *
   * @param reports data to populate cells
   * @param columns column categories to peak reports
   * @param children row tree to populate
   * @returns CSSRow array
   */
  private buildCSSRows(
    reports: Array<CTISearchResult>,
    columns: Array<any>,
    children: Array<any>,
    indentLevel: number = 0,
  ): Array<CSSRow> {
    return [].concat(...children.map((child: any): CSSRow[] => [
      {
        title: child.name,
        indentLevel,
        contentCells: this.buildCSSContentCells(reports, columns, child),
      },
      ...this.buildCSSRows(reports, columns, child.children, indentLevel + 1),
    ]));
  }

  /**
   * Creates the cells for this flavor
   * @param reports data to populate cells
   * @param columns column categories to peak reports
   * @param row row to peak reports
   * @returns cells for this flavor
   */
  private buildCSSContentCells(
    reports: Array<CTISearchResult>,
    columns: Array<any>,
    row: any,
  ): Array<CSSContentCell> {
    return columns.map((column: any): CSSContentCell => {
      const report = reports.find((element) =>
        element.contentCategory === row.cssFlavor && element.reportModule === column.report_module
      );

      return report ? {
        baseAssetGroup: report.baseAssetGroup,
        entitled: report.entitled,
        reportCode: report.code,
        sfProdName: report.name,
        rowType: row.cssFlavor,
        downloading: false,
      } : {
        entitled: false,
        downloading: false,
      };
    });
  }

  /**
   * This is to get subscription internally by any field
   *
   * @param comparisonFunction function that gets a subscription and tells if is the one you need
   * @returns MarketAnalysisSubscription
   */
  private getSubscriptionInternal(comparisonFunction: (sub: any) => boolean): Observable<MarketAnalysisSubscription> {
    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http
      .get<any>(`${this.entitlementUrl}/subscription/entitlement-visibility`, { headers })
      .pipe(
        // get the subscription by id
        map((response) => response.subscriptions.find(comparisonFunction)),

        // get channels for subscription and return subscription + channels object
        concatMap((selectedSubscription: any) =>
          this.getChannels(selectedSubscription.id).pipe(
            map((channels) => ({
              ...selectedSubscription,
              totalChannelsCount: channels.length,
              channels: channels.filter((channel: any) => channel.showChannelUwa),
            }))
          )
        ),
        // for each channel get counts
        concatMap((subscription: any) =>
          forkJoin(
            [].concat(
              of(subscription),
              subscription.channels.map((channel: any) => {
                return this.getChannelSubItemsCount(channel).pipe(map((itemsCount) => (channel.count = itemsCount)));
              })
            )
          ).pipe(
            // return only the subscription, dismiss any other observables
            map((responses) => responses[0])
          )
        ),

        // map to model view object
        map((subscription) => this.buildMarketAnalysisSubscription(subscription))
      );
  }

  /**
   * Reports calculation are more or less the same. It varies the endpoint's
   * query parameter, depending if is a channel or subchannel
   *
   * @param params specific query parameter (channel|report_module)
   * @returns Observable<Array<Report>>
   */
  private getReportsInternal(params: HttpParams): Observable<ReportsPage> {
    // Temporary fix for reports with VIC codes
    if (environment.reportModulesWithVICCodes.includes(params.get('report_module'))) {
      params = params.append('vic', 'true');
    }

    // get products with entitlement already set by backend endpoint for each product.
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http
      .get<any>(`${this.metaUrl}/products`, { headers, params })
      .pipe(
        map((responses) => ({
          reports: responses.products.map((product: any) => this.buildReport(product)),
          count: responses.totalElements,
        }))
      );
  }

  /**
   * Gets VLSI dynamic content from the appropriate endpoint.
   * @param url Endpoint to call
   * @param vicCode VLSI Internal Code
   */
  private getDynamicContentTableInternal(
    url: string,
    vicCode: string,
    period: DynamicHierarchyFrequencies
  ): Observable<DynamicContentTable> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const requestBody = {
      vic: vicCode,
      sort_col: 'CEH_AOW',
      sort_type: 'ASC',
    };
    return this.http
      .post(url, requestBody, { headers })
      .pipe(map((response: any) => DynamicDataTreeHelper.buildDynamicContentTable(response, period)));
  }

  /**
   * Takes a json and transforms it into a MarketAnalysisSubscription object
   * @param subscription a backend response
   * @returns MarketAnalysisSubscription object
   */
  private buildMarketAnalysisSubscription(subscription: any): MarketAnalysisSubscription {
    return {
      name: subscription.nameUwa,
      id: subscription.id,
      entitled: true,
      briefingFilename: subscription.briefingFilename,
      channels: subscription.channels.map((channel: any) => this.buildChannelExcerpt(channel)),
    };
  }

  /**
   * Takes a json and transforms it into a Subscription object
   * @param channel a backend response
   * @returns MarketAnalysisChannelExcerpt object
   */
  private buildChannelExcerpt(channel: any): MarketAnalysisChannelExcerpt {
    return new MarketAnalysisChannelExcerpt(
      channel.id,
      channel.name,
      channel.entitled,
      channel.reports.length > 0,
      channel.children.length,
      channel.children.children?.length,
      channel.reportCount,
    );
  }

  /**
   * Transforms a backend response to a Report object.
   * @param report Backend response, type any
   * @returns Report object
   */
  private buildReport(report: any): Report {
    return {
      id: report.sf_id,
      internalId: report.internalId,
      objectId: report.clObjectId,
      fileType: ReportFileTypeMap[report.fileType],
      entitled: report.entitled,
      downloadEnabled: true,
      downloading: false,
      // Remove any "TI" prefix on report titles so it doesn't appear in the UI:
      //  Only remove from the beginning of the string, in case the name is
      //  something like "Alphagetti Analysis 2022.xlsx".
      //  This prefix will be added to the filename when downloading.
      //  https://techinsights.atlassian.net/browse/CP-3294
      title: report.name.trim().replace(/^TI /i, ''),
      description: report.description,
      publishDate: report.analysisEndDate,
      vicCode: report.vicCode,
      contentCategory: report.content_category__c,
      reportModuleId: report.report_module,
      reportCode: report.code,
      authorName: report.authors && report.authors[0] && report.authors[0].name ? report.authors[0].name : undefined,
      authorAvatarUrl:
        report.authors && report.authors[0] && report.authors[0].avatars ? report.authors[0].avatars[48] : undefined,
      viewCount: report.viewCount,
      assetName: report.assetName,
    };
  }

  private getChannelSubItemsCount(channel: any): Observable<any> {
    const headers = new HttpHeaders().set('Accept', 'application/json');

    if (channel.contentGroupingC) {
      // channel has subchannels
      const params = new HttpParams().set('channel_sf_id', channel.id);
      return this.http
        .get<any>(`${this.entitlementUrl}/report_module`, { headers, params })
        .pipe(
          map((response) => response.report_modules),
          map((reportModules) => {
            return {
              subChannelsCount: reportModules.length,
              entitledSubChannelsCount: reportModules.filter((reportModule: any) => reportModule.entitled).length,
              reportsCount: 0,
            };
          })
        );
    } else {
      // channel does not have subchannels
      // currently making one call per channel | PERFORMANCE: this endpoint accepts a list of channel ids
      const params = new HttpParams().set('channels', channel.id);
      return this.http
        .get<any>(`${this.metaUrl}/products/counting`, { headers, params })
        .pipe(
          map((response) => response.products[0] ?? { count: 0 }),
          map((response) => {
            return {
              subChannelsCount: 0,
              entitledSubChannelsCount: 0,
              reportsCount: response.count,
            };
          })
        );
    }
  }

  /**
   * Gets the channels for a subscription
   * @param subscriptionId the id of the subscription containing the channels
   * @returns a list of channels
   */
  private getChannels(subscriptionId: string) {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const params = new HttpParams().set('subscription', subscriptionId);

    return this.http
      .get<any>(`${this.entitlementUrl}/channel/counting`, { headers, params })
      .pipe(map((response) => response.channels));
  }

  /**
   * Gets the raw facets from meta service
   * @returns a list of facets
   */
  private getFacets(): Observable<any[]> {
    return this.http.get(`${environment.metaServiceBaseUrl}/facets`).pipe(
      map((filters: Array<any>) => {
        return filters
          .filter((f) => !FILTERS_NOT_SHOWN_IN_SIDEBAR.includes(f.fieldName))
      })
    );
  }

  private buildFilter(f: any, subscriptions: NavigationElement[], channels: NavigationElement[]) {
    const type = SIMPLE_FILTERS_FIELD_NAME.includes(f.fieldName) ?
      AnalysisFilterType.Simple :
      AnalysisFilterType.List;
    if (f.fieldName === SUBSCRIPTION_FIELD) {
      const subscriptionlIds = subscriptions.map((subscription) => (subscription.id));
      f.facets = f.facets.filter((facet: any) => subscriptionlIds.includes(facet.value));
    } else if (f.fieldName === CHANNEL_FIELD) {
      const channelIds =
        channels.map(c => c.id)
          .concat(...subscriptions.map((subscription) => subscription.children.map((channel) => channel.id)));
      f.facets = f.facets.filter((facet: any) => channelIds.includes(facet.value));
    }
    const options = f.facets.map((facet: any) => ({
      id: facet.id,
      selected: false,
      label: facet.displayName === '' ? '[blank]' : facet.displayName,
      value: facet.value,
    }))
      .sort(
        (a: AnalysisFilterFacet, b: AnalysisFilterFacet) => FilterHelper.sortFilters(a, b, f.fieldName)
      );
    return {
      id: f.id,
      label: f.displayName,
      field: f.fieldName,
      type,
      expanded: false,
      options,
    };
  }
}
