import {Component, Input} from '@angular/core';
import {ContentCardInterface} from '@shared/interfaces/content-card.interface';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent {
  @Input() card: ContentCardInterface;
}
