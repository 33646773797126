<div *ngIf="info?.key == null"
     [ngClass]="{selected: info?.selected}"
     class="chip noselect">
  <span class="name" id="chip-{{info?.values}}"
        contenteditable="true"
        (keydown.enter)="edit($event)">{{info?.values}}</span>
  <em (click)="remove()"
      class="icon-close_small"></em>
</div>
<div *ngIf="info?.key != null"
     [ngClass]="{selected: info?.selected}"
     class="chip noselect" [class.filter-chip]="isArray(info.values)">
  <ng-container *ngIf="isArray(info.values)">
    <span class="type">{{info?.label}}</span>
    <div *ngFor="let value of info?.values; let i=index;"
         class="item-container">
      <div *ngIf="i > 0"
           class="conditional">
        <span>OR</span>
      </div>
      <span class="name">{{value}}</span>
      <em (click)="onChange(i)"
          class="icon-close_small"></em>
    </div>
  </ng-container>
  <ng-container *ngIf="!isArray(info.values)">
    <div class="item-container">
      <span class="name">{{info.values}}</span>
      <em (click)="remove()"
          class="icon-close_small"></em>
    </div>
  </ng-container>
</div>
