import {
  Component, DoCheck, EventEmitter, Input, IterableDiffer, IterableDiffers, OnInit, Output
} from '@angular/core';
import { BaseAnalysisImagesViewDirective } from '../base-analysis-images-view/base-analysis-images-view.directive';
import { AnalysisImage } from '@app/@shared/models/reverse-engineering/analysis.models';
import { removeFileNameExtension } from '@shared/utils/map-table-content-helper';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-image-list-view',
  templateUrl: './image-list-view.component.html',
  styleUrls: [ './image-list-view.component.scss' ],
})
export class ImageListViewComponent extends BaseAnalysisImagesViewDirective implements OnInit, DoCheck {
  @Input() downloadEnabled = false;
  @Output() downloadImage = new EventEmitter<AnalysisImage>();

  public iterableDiffer: IterableDiffer<AnalysisImage>;

  constructor(
    private _iterableDiffers: IterableDiffers,
    protected ffService: FeatureFlagService,
  ) {
    super();
    this.iterableDiffer = _iterableDiffers.find(this.images).create();
  }

  ngOnInit(): void {
    this.updateSelectedImages();
    this.images?.forEach((image) => {
      image.selected = false;
    });
  }

  ngDoCheck(): void {
    const changes = this.iterableDiffer.diff(this.images);
    if (changes) {
      this.updateSelectedImages();
    }
    if (this.selectedImages.selectedImagesQty && changes) {
      for (const image of this.images) {
        for (const someImage of this.selectedImages.allImages) {
          if (image.id === someImage.id) {
            image.selected = someImage.selected;
          }
        }
      }
    }
  }

  public onClickSelectImage(image: AnalysisImage): void {
    image.selected = !image.selected;
    for (const someImage of this.selectedImages.allImages) {
      if (someImage.id === image.id) {
        someImage.selected = image.selected;
        break;
      }
    }
    this.countImages(image.selected);
    this.updateSelectAll();
    this.updateSelectedImages();
  }

  public onClickSelectAll(allSelected: boolean = !this.selectedImages.allSelected) {
    this.selectedImages.allSelected = allSelected;
    this.selectedImages.allImages.forEach((image) => {
      image.selected = this.selectedImages.allSelected;
    });
    this.images.forEach((image) => {
      image.selected = this.selectedImages.allSelected;
    });
    if (!this.selectedImages.allSelected) {
      this.selectedImages.selectedImagesQty = this.images.filter((image) => image.selected === true).length;
    }
    this.updateSelectedImages();
  }

  public onDownloadImage(image: AnalysisImage): void {
    this.downloadImage.emit(image);
  }

  public mapImageName(imageName: string): string {
    return removeFileNameExtension(imageName);
  }

  private updateSelectAll(): void {
    if (this.images.filter((image) => image.selected).length !== this.images.length) {
      this.selectedImages.allSelected = false;
    }
    if (this.selectedImages.selectedImagesQty !== this.imageCount) {
      this.selectedImages.allSelected = false;
    }
  }

  private countImages(selected: boolean) {
    if (this.selectedImages.selectedImagesQty === this.imageCount && !selected) {
      this.selectedImages.selectedImagesQty = this.images.filter((image) => image.selected === true).length;
    } else if (this.selectedImages.selectedImagesQty !== this.imageCount && !selected) {
      --this.selectedImages.selectedImagesQty;
    } else {
      ++this.selectedImages.selectedImagesQty;
    }
  }
}
