<ng-container *ngIf="(workspaceLoaded$ | async)">
  <nav *ngIf="!hideNavBar" class="sidebar hide-on-mobile hide-on-tablet">
    <app-nav-more-container
    #navMoreContainerComponent
    [class.non-clickable]="!popoverOnTop"
    ></app-nav-more-container>
  </nav>
  <div class="main-container">
    <div class="hide-on-desktop-custom" (click)="closeMobileNavigation()" *ngIf="mobileNavigationOn"></div>
    <div class="hide-on-desktop-custom mobile-nav-bar">
      <div class="mobile-icon-container">
        <div class="icon icon-hamburger_menu pointer" (click)="openMobileNavigation()" *ngIf="!mobileNavigationOn"></div>
        <div class="icon icon-close_medium pointer" (click)="closeMobileNavigation()" *ngIf="mobileNavigationOn"></div>
        <img src="../../../../assets/images/logo-stacked-black.svg" alt="TechInsights"/>
      </div>
      <div class="right-container">
        <div class="search-bar">
          <app-generic-search-bar
            [class]="hideSearchButton ? 'shrinked-search-bar' : 'expanded-search-bar'"
            [chipList]="searchTerm"
            [hiddenSearchButton]="hideSearchButton"
            [searchPlaceholder]="searchContext"
            (searchTermsChanged)="onChangeSearchTerms($event)"
            (focusSearchbar)="onFocusSearchbar()"
            (blurSearchbar)="onBlurSearchbar($event)">
          </app-generic-search-bar>
        </div>
        <app-nav-mega-menu-compact *ngIf="mobileNavigationOn"
          (menuItemClicked)="closeMobileNavigation()"
          (searchExecuted)="closeMobileNavigation()">
        </app-nav-mega-menu-compact>
        <app-header-bar
          class="header-bar"
          *ngIf="showHeaderBar && isMobileScreen"
        ></app-header-bar>
      </div>
    </div>
    <app-header-bar
      class="header-bar hide-on-tablet-custom"
      *ngIf="showHeaderBar && !isMobileScreen"
    ></app-header-bar>
    <app-nav-mega-menu class="hide-on-mobile hide-on-tablet-custom"></app-nav-mega-menu>
    <app-alert-bar></app-alert-bar>
    <main>
      <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
    </main>
  </div>
</ng-container>

<div class="splashscreen" *ngIf="(workspaceLoaded$ | async) !== true" @showHideSplash>
  <div class="splash-logo"></div>
  <progress class="pure-material-progress-circular"></progress>
  <div class="splash-text">Loading your workspace...</div>
</div>
