<div class="content-container">
  <ng-content></ng-content>
  <div class="expanding-search-bar-container blog-header">
    <app-generic-search-bar
      [class]="hideSearchButton ? 'shrinked-search-bar' : 'expanded-search-bar'"
      [chipList]="searchTerm"
      [hiddenSearchButton]="hideSearchButton" [searchPlaceholder]="searchContext"
      (searchTermsChanged)="onChangeSearchTerms($event)" (focusSearchbar)="onFocusSearchbar()"
      (blurSearchbar)="onBlurSearchbar($event)">
    </app-generic-search-bar>
  </div>
  <section class="tab-bar">
    <div class="filter-sidebar-header" [class.collapsed]="filtersCollapsed">
      <em class="icon-sidebar_close" (click)="onToggleFilters($event)"></em>
      <span>Filters</span>
      <span class="clear-all" (click)="onClearFilters()" *ngIf="filtersApplied">
        <em class="icon-close_small"></em>
        Clear All
      </span>
    </div>
  </section>
  <section class="blog-view">
    <aside class="sidebar-wrapper" [class.collapsed]="filtersCollapsed">
      <app-filter-sidebar class="scrollbar" [filters]="filters" (changeFilters)="onChangeFilters($event)"
        #filtersBar></app-filter-sidebar>
    </aside>
    <div class="blog-view-container">
      <app-content-list-view
        [content]="blogs"
        [paginationOptions]="paginationOptions"
        [contentCount]="blogCount"
        [sortOptions]="sortOptions"
        [selectedSort]="selectedSort"
        [showSort]="true"
        [searchTerm]="searchTerm"
        [loading]="isComponentLoading"
        [showHighlights]="paginationOptions.showHighlights"
      >
        <div *ngIf="!isComponentLoading" class="search-string-highlight-wrapper">
          <section class="search-string">
            <strong>{{blogCount}} TechStream Blogs</strong>
            <span *ngIf="searchTerm">
              <strong>for</strong>
              "{{searchTerm | replace : ',' : ' OR '}}"
            </span>
            in {{searchContext}}
          </section>
          <app-pagination-highlight
            [currentPage]="paginationOptions.page" [currentPageSize]="paginationOptions.size" [selectedSort]="selectedSort"
            [showHighlights]="paginationOptions.showHighlights"
            (changePaginationOptions)="onChangePaginationOptions($event)">
          </app-pagination-highlight>
        </div>
        <section class="blog-list scrollbar">
          <app-blog-card *ngFor="let blog of blogs" [blog]="blog" (click)="onClickBlog(blog)"></app-blog-card>
        </section>
      </app-content-list-view>
    </div>
  </section>
</div>
