import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { SelectOption } from '@app/@shared/models/shared.models';
import { PaginationHelper } from '@app/@shared/utils/pagination-helper';

@Component({
  selector: 'app-pagination-bottom',
  templateUrl: './pagination-bottom.component.html',
  styleUrls: ['./pagination-bottom.component.scss'],
})
export class PaginationBottomComponent {
  @Input() set startingPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.setUpPagination();
  }
  @Input() set startingPage(page: number) {
    this.currentPage = page;
    if (this.allPages) {
      this.setPagesToShowForPageNumber(page);
    }
  }
  @Input() set count(count: number) {
    this._count = count;
    this.setUpPagination();
  }
  @Input() selectedSort: SelectOption<string>;
  @Input() showHighlights: boolean;

  @Output() changePaginationOptions = new EventEmitter<PaginationParameters>();

  totalPages: number;
  pageSize = 100;
  allPages: Array<number>;
  pagesToShow: Array<number>;
  currentPage = 1;

  private _count: number;

  /**
   * Goes to the selected page
   * @param page the selected page
   */
  goToPage(page: number) {
    this.currentPage = page;
    this.changePaginationOptions.emit(new PaginationParameters(this.pageSize, page, this.selectedSort?.value, {
      showHighlights: this.showHighlights,
    }));
    this.setPagesToShowForPageNumber(page);
  }

  /**
   * Goes to the next page
   */
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.goToPage(this.currentPage + 1);
    }
  }

  /**
   * Goes to the previous page
   */
  prevPage() {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  }

  /**
   * Sets the array of pages to show based on the current page
   * @param page the current page for which to generate the array
   */
  private setPagesToShowForPageNumber(page: number) {
    if(this.totalPages > 0) {
        this.pagesToShow = PaginationHelper.getPagesToShowForPageNumber(page, this.totalPages, this.allPages);
    } else {
        this.pagesToShow = [];
    }
  }

  /**
   * Sets up the needed attributes for the bottom pagination to be displayed
   */
  private setUpPagination() {
    if (this._count === undefined || this._count === null) {
      return;
    }
    this.totalPages = Math.ceil((this._count > 10000 ? 10000 : this._count) / this.pageSize);
    this.allPages =
      this.totalPages - 2 > 0
        ? Array(this.totalPages - 2)
            .fill(0)
            .map((_, index) => index + 2)
        : [];
    this.setPagesToShowForPageNumber(this.currentPage);
  }
}
