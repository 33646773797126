import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

/**
 * A directive to determine if a click was done outside the element which has this directive.
 * Useful for containers that needs to be closed when user focuses on other part of the app.
 */
@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any) {
    const clickedInside = target && document.body.contains(target) && this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}
