import { Component, Input } from '@angular/core';
import { selectMetadataPanelCollapsed } from '@app/store/Selectors/search/search.selectors';
import { Store } from '@ngrx/store';
import { Observable, interval, of } from 'rxjs';
import { delayWhen } from 'rxjs/operators';
import { Metadata } from '@shared/models/metadata';

@Component({
  selector: 'app-metadata-panel',
  templateUrl: './metadata-panel.component.html',
  styleUrls: ['./metadata-panel.component.scss']
})
export class MetadataPanelComponent {

  @Input() metadata: Metadata;

  isMetadataPanelCollapsed: Observable<boolean> = this.store
    .select(selectMetadataPanelCollapsed)
    .pipe(delayWhen((v) => (v ? interval(0) : of(v))));

  constructor(private store: Store) {
  }

  shouldDisplayMetadata(): boolean {
    return this.metadata !== undefined;
  }

  shouldDisplayEntry(key: string): boolean {
    const value = this.metadata.getValue(key);
    return !!value && value !== '' && value !== 'null';
  }
}
