import { createAction, props } from '@ngrx/store';
import {
  IContentType,
  IFilterType,
  ISavedSearch,
  ISearchResults,
  ISearchSort,
  ISearchTerms,
} from '@app/store/Models/search/searchModels';
/**
 * Search actions definitions
 *
 * Here goes all the actions that affects the search behaviour, like setting filters, changing the content type,
 * view type, trigger a saved search, creating a saved search, etc
 */
export enum SearchActionTypes {
  loadFilterTypes = '[Search] Load Filter Types',
  loadFilterTypesSucess = '[Search] Load Filter Types Sucess',
  loadFilterTypesFailure = '[Search] Load Filter Types Failure',
  loadSearchResults = '[Search] Load Search Results',
  loadInitialSearchResults = '[Search] Load Initial Search Results',
  loadSearchResultsSucess = '[Search] Load Search Results Sucess',
  loadSearchResultsFailure = '[Search] Load Search Results Failure',
  loadSavedSearches = '[Search] Load Saved Searches',
  loadSavedSearchesSucess = '[Search] Load Saved Searches Sucess',
  loadSavedSearchesFailure = '[Search] Load Saved Searches Failure',
  setResultsViewType = '[Search] Set Results View Type',
  setViewContentType = '[Search] Set View Content Type',
  saveRecentSearch = '[Search] Save Recent Search',
  saveUserSearch = '[Search] Save User Search',
  saveUserSearchSuccess = '[Search] Save User Search Success',
  saveUserSearchFailure = '[Search] Save User Search Failure',
  setScrollIndex = '[Search] Set Scroll Index',
  setUserSearch = '[Search] Set User Search',
  setSearchSorting = '[Search] Set Search Sorting',
  setSearchTerms = '[Search] Set Search Terms',
  cleanupSearch = '[Search] Clean Up Search Terms',
  toggleFiltersCollapsed = '[Search] Toggle filters collapsed',
  toggleHierarchyCollapsed = '[Search] Toggle hierarchy collapsed',
  toggleMetadataPanelCollapsed = '[Search] Toggle metadatapanel collapsed',
  deleteSavedSearch = '[Search] Delete Saved Search',
  deleteSavedSearchSuccess = '[Search] Delete Saved Search Sucess',
  deleteSavedSearchFailure = '[Search] Delete Saved Search Failure',
}

export const loadFilterTypes = createAction(SearchActionTypes.loadFilterTypes);
export const loadFilterTypesSucess = createAction(
  SearchActionTypes.loadFilterTypesSucess,
  props<{ filterTypes: IFilterType[] }>()
);
export const loadFilterTypesFailure = createAction(
  SearchActionTypes.loadFilterTypesFailure,
  props<{ error: string }>()
);

export const loadInitialSearchResults = createAction(
  SearchActionTypes.loadInitialSearchResults,
  props<{ searchTerms: ISearchTerms }>()
);
export const loadSearchResults = createAction(
  SearchActionTypes.loadSearchResults,
  props<{ searchTerms: ISearchTerms }>()
);
export const loadSearchResultsSucess = createAction(
  SearchActionTypes.loadSearchResultsSucess,
  props<{ results: ISearchResults }>()
);
export const loadSearchResultsFailure = createAction(
  SearchActionTypes.loadSearchResultsFailure,
  props<{ error: string }>()
);

export const loadSavedSearches = createAction(SearchActionTypes.loadSavedSearches);
export const loadSavedSearchesSucess = createAction(
  SearchActionTypes.loadSavedSearchesSucess,
  props<{ savedSearches: ISavedSearch[] }>()
);
export const loadSavedSearchesFailure = createAction(
  SearchActionTypes.loadSavedSearchesFailure,
  props<{ error: string }>()
);

export const setResultsViewType = createAction(SearchActionTypes.setResultsViewType, props<{ viewType: string }>());
export const setViewContentType = createAction(
  SearchActionTypes.setViewContentType,
  props<{ contentType: IContentType, gridRowLength: number, reportGridRowLength: number }>()
);

export const saveUserSearch = createAction(SearchActionTypes.saveUserSearch, props<{userSearch: ISavedSearch}>());
export const saveUserSearchSuccess = createAction(
    SearchActionTypes.saveUserSearchSuccess,
    props<{userSearch: ISavedSearch}>()
  );
export const saveUserSearchFailure = createAction(SearchActionTypes.saveUserSearchFailure, props<{error: any}>());
export const setUserSearch = createAction(SearchActionTypes.setUserSearch, props<{searchId: string}>());
export const setScrollIndex = createAction(SearchActionTypes.setScrollIndex, props<{scrollIndex: number}>());

export const saveRecentSearch = createAction(SearchActionTypes.saveRecentSearch);
export const setSearchTerms = createAction(SearchActionTypes.setSearchTerms, props<{ searchTerms: ISearchTerms }>());

export const toggleFiltersCollapsed = createAction(SearchActionTypes.toggleFiltersCollapsed);

export const toggleHierarchyCollapsed = createAction(SearchActionTypes.toggleHierarchyCollapsed);
export const toggleMetadataPanelCollapsed = createAction(SearchActionTypes.toggleMetadataPanelCollapsed);

export const deleteSavedSearch = createAction(SearchActionTypes.deleteSavedSearch, props<{ id: string }>());

export const deleteSavedSearchSuccess = createAction(
  SearchActionTypes.deleteSavedSearchSuccess,
  props<{ id: string }>()
);

export const deleteSavedSearchFailure = createAction(
  SearchActionTypes.deleteSavedSearchFailure,
  props<{ error: string }>()
);

export const setSearchSorting = createAction(SearchActionTypes.setSearchSorting, props<{ sortMethod: ISearchSort }>());
