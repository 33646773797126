<div class="thumbnail-container scrollbar">
  <img
    *ngFor="let thumbnail of thumbnails"
    [src]="thumbnail.url"
    class="thumbnail"
    [class.selected]="thumbnail.id === selectedThumbnail.id"
    (click)="onClickThumbnail(thumbnail)"
    (error)="onErrorThumbnail($event)"
    alt=""
    [id]="thumbnail.id"
    #thumbnailElement
  />
</div>
