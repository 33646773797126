import { Component, Input, OnInit } from '@angular/core';
import { PowerSearchTab } from '../power-search-tabs/power-search-tabs.component';
import {CATEGORY_TYPE_ENUM} from '@shared/models/search';

@Component({
  selector: 'app-static-search-banner',
  templateUrl: './static-search-banner.component.html',
  styleUrls: ['./static-search-banner.component.scss'],
})
export class StaticSearchBannerComponent implements OnInit {
  @Input() set activeTab(activeTab: PowerSearchTab) {
    if(activeTab){
      this.setBanner(activeTab.category);
    }
  };
  category: string;
  titleBeforeTotalSearch: string;
  titleAfterTotalSearch: string;
  totalSearch: string;
  bannerBody: string;

  ngOnInit(): void {
    // Comment needed for Sonar
  }

  private setBanner(category: string): void {
    this.category = category;

    switch (category) {
      case CATEGORY_TYPE_ENUM.RE: {
        this.titleBeforeTotalSearch = 'Search ';
        this.titleAfterTotalSearch = ' Reverse Engineering analyses';
        this.totalSearch = '15,000+';
        this.bannerBody = 'Includes all Reverse Engineering and Teardown subscriptions';
        break;
      }
      case CATEGORY_TYPE_ENUM.MA: {
        this.titleBeforeTotalSearch = 'Search ';
        this.titleAfterTotalSearch = ' Market Analysis reports and datasets';
        this.totalSearch = '4,000+';
        this.bannerBody = 'Includes Market Analysis, Manufacturing Analysis, Processor Analysis, and McClean Report subscriptions';
        break;
      }
      case CATEGORY_TYPE_ENUM.ALL: {
        this.titleBeforeTotalSearch = 'Search ';
        this.titleAfterTotalSearch = ' Product Analysis and Market Analysis reports';
        this.totalSearch = '1,500,000';
        this.bannerBody = 'Discover analysis, summaries and synthesis on innovative technologies and deep market insights.';
        break;
      }
      default: {
        this.category = null;
        this.titleBeforeTotalSearch = null;
        this.titleAfterTotalSearch = null;
        this.totalSearch = null;
        this.bannerBody = null;
        break;
      }
    }
  }
}
