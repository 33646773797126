import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContentNavigatorTable, ContentNavigatorTablePage, FlavourCounts, ReportFlavor } from '@app/@shared/models/content-navigator/content-navigator.model';
import { Blog, Video } from '@app/@shared/models/market-analysis/market-analysis';
import { Channel, MarketSegmentSubscription, NavigationElement, NavigationModules, Subscription } from '@app/@shared/models/navigation/navigation.models';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { AnalysisFilter, AnalysisFilterFacet, AnalysisFilterType, AnalysisParametersEvent, Filters } from '@app/@shared/models/reverse-engineering/analysis.models';
import { SubscriptionCard } from '@app/@shared/models/shared.models';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import { FilterHelper } from '@app/@shared/utils/filter-helper';
import { environment } from '@env/environment';
import { Observable, combineLatest, of } from 'rxjs';
import { concatMap, switchMap, map } from 'rxjs/operators';
import { SearchService } from '@shared/services/search/search.service';
import { MarketSegmentsChannelCard } from '../models/market-segments-channel-card.model';

const FILTERS_NOT_SHOWN_IN_SIDEBAR = ['flavor'];
const SIMPLE_FILTERS_FIELD_NAME = ['entitlementState'];
const SUBSCRIPTION_FIELD = 'subscriptionName';
const CHANNEL_FIELD = 'channel';
@Injectable({
  providedIn: 'root',
})
export class MarketSegmentsService {
  private readonly MARKET_SEGMENTS = 'Solutions';
  private readonly DEFAULT_ICON = 'icon-default';
  private readonly metaUrl = environment.metaServiceBaseUrl;

  constructor(
    private navigationService: NavigationService,
    private searchService: SearchService,
    private httpClient: HttpClient,
  ) { }

  /**
   * Gets the segments subscriptions and transforms them into SubscriptionCard elements
   * to be displayed in the UI
   * @returns SubscriptionCard elements that represent each segment
   */
  getSubscriptionExcerpts(): Observable<Array<SubscriptionCard>> {
    return this.navigationService.getModuleCardsByName(this.MARKET_SEGMENTS).pipe(
      map((response) => {
        const subscriptions = response as Subscription[];
        return subscriptions.map((segment) => ({
          id: segment.id,
          name: segment.name,
          icon: this.buildIcon(segment.name),
          entitled: true,
          totalChannels: segment.totalChannels,
          availableChannels: segment.availableChannels,
        }));
      })
    );
  }

  /**
   * Gets the segment subscription to be used by the UI
   * @param subscriptionId  the ID of the segment
   * @returns a subscription to be used by the UI
   */
  getSubscription(subscriptionId: string): Observable<MarketSegmentSubscription> {
    return this.navigationService.getSubscription(subscriptionId, this.MARKET_SEGMENTS).pipe(
      map((subscription) => ({
        ...subscription,
        icon: this.buildIcon(subscription.name),
        getContentNavigatorTabs: subscription.getContentNavigatorTabs,
        getLandingPageTabs: subscription.getLandingPageTabs,
      }))
    );
  }

  getSubscriptionTabCounts(subscriptionId: string): Observable<FlavourCounts> {
    return this.getSubscription(subscriptionId).pipe(
      concatMap((subscription) => {
        const channelIds = subscription.children.map((channel) => channel.id).join(',');
        return this.searchService.getTabCounts(channelIds)
      })
    )
  }

  getTabCounts(ids: string): Observable<FlavourCounts> {
    return this.searchService.getTabCounts(ids);
  }

  getChannelCardInfo(channels: Array<Channel>): Observable<Array<MarketSegmentsChannelCard>> {
    return this.httpClient.get<any>(`${this.metaUrl}/products/counting`, {
        params: {
          channels: channels.map((channel) => channel.id).join(','),
        }
      }).pipe(
      map(({products}) => {
        const channelCardInfo: Array<MarketSegmentsChannelCard> = [];

        channels.forEach((channel: Channel) => {
          const channelInfo = products.find((p: any) => p.channel === channel.id)

          if(channelInfo?.count > 0){
            channelCardInfo.push(
              new MarketSegmentsChannelCard({
                id: channel.id,
                title: channel.name,
                entitled: channel.entitled,
                reportsCount: channelInfo.count,
              })
            )
          }
        });
        return channelCardInfo;
      })
    );
  }

  /**
   * Gets a limit number of latest reports of the provided flavor for a subscription
   *
   * @param subscriptionId: a subscription id
   * @param flavor: any value of ReportFlavor
   * @param limit: max number of reports to retrieve
   * @returns Observable<ContentNavigatorTable>
   */
  getLatestAnalysisForSubscription(
    subscriptionId: string,
    flavor: ReportFlavor,
    limit: number
  ): Observable<ContentNavigatorTable> {
    return this.navigationService.getSubscription(subscriptionId, NavigationModules.MARKET_SEGMENTS).pipe(
      concatMap((subscription: Subscription) => {
        const idChannels = subscription.children.map((channel) => channel.id);
        return this.getLatestAnalysisForChannels(flavor, limit, idChannels);
      })
    );
  }

  /**
   * Gets the n latest analysis for a list of channels
   *
   * @param channelIds lint warning (describe)
   * @param flavor lint warning (describe)
   * @param limit lint warning (describe)
   * @returns Observable of ContentNavigatorTable
   */
  getLatestAnalysisForChannels(
    flavor: ReportFlavor,
    limit: number,
    channelIds?: Array<string>,
    subscriptionIds?: Array<string>
  ): Observable<ContentNavigatorTable> {

    return this.searchService.getTable('',
      { channelId: channelIds, subscriptionId: subscriptionIds, flavor: [flavor] },
      new PaginationParameters(limit, 0, 'releaseDate,DESC', { from: 0 }))
      .pipe(
        map((tablePage) => {
          return tablePage.table
        }))
  }

  /**
   * Gets a limit number of the latest reports of the provided flavor for MS module
   * By latest means the latest completion date.
   *
   * @param flavor: any of flavor strings, i.e., ['FINAL', 'IN_PROGRESS', 'ANALYST', 'SAMPLES']
   * @param limit: number of reports
   * @returns Observable<ContentNavigatorTable>
   */
  public getLatestAnalysisForMSModule(flavor: ReportFlavor, limit: number)
    : Observable<ContentNavigatorTable> {
    return this.navigationService.getModuleChannelsByName(NavigationModules.MARKET_SEGMENTS).pipe(
      switchMap((channels) => this.getLatestAnalysisForChannels(flavor, limit, [...channels.map((c) => c.id )], []))
    );
  }

  getAllAnalysis(
    searchTerm: string,
    filters: Filters,
    paginationParameters: PaginationParameters,
    subscriptionId?: string
  ): Observable<ContentNavigatorTablePage> {
    return this.resolveFilters(filters, subscriptionId).pipe(
      concatMap((resolvedFilters) => {
        return this.searchService.getTable(searchTerm, resolvedFilters, paginationParameters);
      })
    );
  }

  /**
   * Gets a single blog
   * @param id of the blog
   * @param marketSegmentName of the blog
   * @returns a blog
   */
  getBlog(id: string, marketSegmentName: string): Observable<Blog> {
    return this.searchService.getBlog(id, marketSegmentName);
  }

  /**
   * Gets a single video
   * @param id of the video
   * @returns a video
   */
  getVideo(id: string): Observable<Video> {
    return this.searchService.getVideo(id);
  }

  getChannelAllAnalysis(
    channelId: string,
    flavor: string,
    pagination: PaginationParameters,
    searchTerm?: string,
    filters?: Filters,
  ): Observable<ContentNavigatorTablePage> {
    const currentFilters = {
      channel: [channelId],
      flavor: [flavor],
      ...filters,
    };
    return this.searchService.getTable(searchTerm, currentFilters, pagination);
  }

  getChannels(): Observable<Array<Channel>> {
    return this.navigationService.getModuleChannelsByName(NavigationModules.MARKET_SEGMENTS);
  }

  /**
   * Gets the raw facets from meta service
   * @returns a list of facets
   */
  getFacets(): Observable<any[]> {
    return this.httpClient.get(`${environment.metaServiceBaseUrl}/facets`).pipe(
      map((filters: Array<any>) => {
        return filters
          .filter((f) => !FILTERS_NOT_SHOWN_IN_SIDEBAR.includes(f.fieldName));
      }
      ));
  }

  getAnalysisFiltersForSidebar(): Observable<AnalysisFilter[]> {
    return combineLatest([
      this.navigationService.getModuleCardsByName(NavigationModules.MARKET_SEGMENTS),
      this.getFacets()
    ]).pipe(
      map(([subscriptions, analisis]) => {
        return analisis
          .map((f) => this.buildFilter(f, subscriptions))
          .sort((a, b) => {
            if (SIMPLE_FILTERS_FIELD_NAME.includes(a.field)) {
              return -1;
            } else if (SIMPLE_FILTERS_FIELD_NAME.includes(b.field)) {
              return 1;
            }
            return 0;
          })
      })
    );
  }

  getNoResultsInProgress(tab: string, inProgressCount: number, terms?: AnalysisParametersEvent): boolean {
    let noResultsInProgress = tab === ReportFlavor.IN_PROGRESS && inProgressCount === 0;
    if (terms?.filters) {
      noResultsInProgress = noResultsInProgress && Object.keys(terms.filters).length === 0
        && terms.searchTerm.length === 0;
    } else if (terms) {
      noResultsInProgress = noResultsInProgress && terms.searchTerm.length === 0
    }
    return noResultsInProgress;
  }

  private buildFilter(f: any, subscriptions: NavigationElement[]) {
    const type = SIMPLE_FILTERS_FIELD_NAME.includes(f.fieldName)
      ? AnalysisFilterType.Simple
      : AnalysisFilterType.List;
    if (f.fieldName === SUBSCRIPTION_FIELD) {
      const subscriptionlIds = subscriptions.map((subscription) => (subscription.id));
      f.facets = f.facets.filter((facet: any) => subscriptionlIds.includes(facet.value));
    } else if (f.fieldName === CHANNEL_FIELD) {
      const channelIds =
        [].concat(...subscriptions.map((subscription) => subscription.children.map((channel) => channel.id)));
      f.facets = f.facets.filter((facet: any) => channelIds.includes(facet.value));
    }
    return {
      id: f.id,
      label: f.displayName,
      field: f.fieldName,
      type,
      expanded: false,
      options: f.facets.map((facet: any) => ({
        id: facet.id,
        selected: false,
        label: facet.displayName === '' ? '[blank]' : facet.displayName,
        value: facet.value,
      })).sort(
        (a: AnalysisFilterFacet, b: AnalysisFilterFacet) => FilterHelper.sortFilters(a, b, f.fieldName)
      ),
    };
  }

  private resolveFilters(filters: Filters, subscriptionId?: string): Observable<Filters> {
    if (subscriptionId && !filters.hasOwnProperty('channel')) {
      return this.navigationService.getSubscription(subscriptionId, NavigationModules.MARKET_SEGMENTS).pipe(
        concatMap((subscription: Subscription) => {
          const idChannels = subscription.children.map((channel) => channel.id);
          return of({ ...filters, channel: idChannels });
        })
      );
    } else if (filters.hasOwnProperty('channel')) {
      return of(filters);
    }

    return this.navigationService.getModuleChannelsByName(NavigationModules.MARKET_SEGMENTS).pipe(
      concatMap((allChannels) => {
        const idChannels = allChannels.map((channel) => channel.id);
        return of({ ...filters, channel: idChannels });
      })
    );
  }


  /**
   * Selects the icon for each segment
   * @param name of the segment
   * @returns the icon name
   * !!! The icons are still missing from the design, to be added here
   */
  private buildIcon(_: string): string {
    return this.DEFAULT_ICON;
  }
}
