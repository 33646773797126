import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageViewerComponent } from './image-viewer.component';
import { ThumbnailBarComponent } from './thumbnail-bar/thumbnail-bar.component';
import { GenericToolbarModule } from '@shared/components/generic-toolbar/generic-toolbar.module';
import { MetadataPanelModule } from '@app/@shared/components/metadata-panel/metadata-panel.module';
import { ShareItemModalModule } from '../share-item-modal/share-item-modal.module';
import { CalibrationInstructionsModalComponent } from './calibration-instructions-modal/calibration-instructions-modal.component';

@NgModule(
  {
    imports: [
      CommonModule,
      GenericToolbarModule,
      MetadataPanelModule,
      ShareItemModalModule,
    ],
    declarations: [
      ImageViewerComponent,
      ThumbnailBarComponent,
      CalibrationInstructionsModalComponent,
    ],
    exports: [
      ImageViewerComponent,
    ],
  },
)
export class ImageViewerModule {}
