<ng-container *ngIf="isRoadmapLocked">
  <app-roadmap-request-access></app-roadmap-request-access>
</ng-container>

<div *ngIf="tableauRoadmapConfig">
  <app-tableau-iframe [config]="tableauRoadmapConfig"> </app-tableau-iframe>
</div>

<div class="retool-app" *ngIf="isRetool">
  <app-retool-iframe [embeddedURLRequest]="{appId: retoolAppId}"></app-retool-iframe>
</div>
