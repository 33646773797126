import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IInputProps} from '@shared/components/input/input.component';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ITextAreaProps} from '@shared/models/shared.models';
import {AuthenticationService} from '@app/auth';
import {EntitlementService} from '@shared/services/entitlement/entitlement.service';
import {Router} from '@angular/router';

enum REQUEST_IMAGE_STATE {
  REQUEST = 'request',
  ERROR = 'error',
  SUCCESS = 'success',
}
@Component({
  selector: 'app-request-image-modal',
  templateUrl: './request-image-modal.component.html',
  styleUrls: ['./request-image-modal.component.scss']
})
export class RequestImageModalComponent implements OnInit {
  @Input() imageTitle: string;
  @Input() analysisCode: string;
  @Input() clObjectID: string;
  requestImageState = REQUEST_IMAGE_STATE.REQUEST;
  imageTitleInputProps: IInputProps = {
    class: 'form-control readonly uui-modal-input',
    label: 'Entitlement Request for',
    disabled: true,
    placeholder: '',
  };
  emailProps: IInputProps = {
    class: 'form-control readonly uui-modal-input',
    label: 'Corporate Email',
    disabled: true,
    placeholder: '',
  };
  optionalMessageProps: ITextAreaProps = {
    name: 'topic',
    class: 'form-control uui-modal-input',
    label: 'Optional message',
    rows: 2,
    cols: 50,
    minLength: 1,
    maxLength: 500,
  };
  requestAccessFormModal: UntypedFormGroup  = this.formBuilder.group({
    email: new UntypedFormControl(''),
    title: new UntypedFormControl(''),
    message: new UntypedFormControl(''),
  });

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthenticationService,
    private entitlementService: EntitlementService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.authService.me().subscribe((userData) => {
      this.requestAccessFormModal.get('email').setValue(userData.email);
    });
    this.imageTitleInputProps.placeholder = `${this.imageTitle} ${this.analysisCode}`;
  }

  modalClose(result: string) {
    this.modal.close(result);
  }

  modalDismiss(result: string) {
    this.modal.dismiss(result)
  }

  onModalRequest() {
    const comment = this.requestAccessFormModal.controls.message.value;
    const url = this.router.url;
    this.entitlementService.postUnwatermarkedImageRequest(url, this.clObjectID, comment)
      .subscribe(() => {
        this.requestImageState = REQUEST_IMAGE_STATE.SUCCESS;
      }, (error) => {
        this.requestImageState = REQUEST_IMAGE_STATE.ERROR;
      })
  }
}
