import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ChannelCard } from '@shared/models/shared.models';

@Component({
  selector: 'app-channel-card-area',
  templateUrl: './channels-card-area.component.html',
  styleUrls: ['./channels-card-area.component.scss'],
})
export class ChannelsCardAreaComponent<T extends ChannelCard> {
  @Input() title = 'All Analysis by Channel';
  @Input() channels: Array<T>;
  @Input() showViewAll = true;
  @Input() showSubtitle = true;

  @Output() clickViewAll = new EventEmitter<void>();
  @Output() clickChannel = new EventEmitter<T>();

  public onClickViewAll(): void {
    this.clickViewAll.emit();
  }

  public onClickChannel(channel: ChannelCard): void {
    this.clickChannel.emit(channel as T);
  }
}
