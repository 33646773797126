import { Component, Input } from '@angular/core';
import { HTMLReport } from '@app/@shared/services/hygraph/hygraph.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ImageModalComponent} from '@shared/components/hygraph/image-modal/image-modal.component';

@Component({
  selector: 'app-hygraph',
  templateUrl: './hygraph.component.html',
  styleUrls: ['./hygraph.component.scss']
})
export class HygraphComponent {
  @Input() htmlReport: HTMLReport;

  constructor(private modalService: NgbModal) {}

  openImageModal(imageUrl: string, altText: string) {
    const modalRef = this.modalService.open(ImageModalComponent);
    modalRef.componentInstance.imageUrl = imageUrl;
    modalRef.componentInstance.altText = altText;
  }
}
