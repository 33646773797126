import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { RecentViewTable } from '@app/@shared/models/user-view-history/recent-view-table.model';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';
import { PreviousPageService } from '@app/@shared/services/previous-page.service';
import { RecentViewHistoryService } from '@app/@shared/services/recent-view-history/recent-view-history.service';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-recent-activity-browser',
  templateUrl: './recent-activity-browser.component.html',
  styleUrls: ['./recent-activity-browser.component.scss']
})
export class RecentActivityBrowserComponent implements OnInit {
  loadingTable = false;
  tagName = ['TechInsights'];
  public tabs = [{ name: 'Recently Viewed' }];
  currentPage = 1;
  pageSize = 50;
  table: RecentViewTable;

  constructor(
    private router: Router,
    public prevPage: PreviousPageService,
    private entitlementService: EntitlementService,
    private recentHistoryService: RecentViewHistoryService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.loadingTable = true;
    this.route.queryParams.pipe(tap((queryParams) => {
      this.currentPage = queryParams.page ?? 1;
      this.pageSize = queryParams.size ?? 50;
    })
      , switchMap(() => {
        this.loadingTable = true;
        return this.recentHistoryService.getRecentViewHistory(this.currentPage, this.pageSize)
      }
      )).subscribe((response) => {
        this.table = response
        this.loadingTable = false;
      },
      ()=>{
        this.loadingTable = false;
      })
  }

  public onClickBack() {
    this.prevPage.navigateBack();
  }

  public onClickRow(row: any) {
    if (!row.entitled) {
      this.entitlementService.openRequestAccessFormDialog(row.channel ? `${row.channel}:${row.id}`: `${row.id}`);
    } else {
      this.router.navigateByUrl(row.url);
    }
  }

  onChangePaginationOptions(options: PaginationParameters) {
    this.router.navigate([], {
      queryParams: { ...options },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

}
