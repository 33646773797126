<div class="help-and-resources">
  <footer>
    <header>Resources</header>
    <div>
      <a rel="noopener" [href]="resourcesURL.whatsNew" target="_blank">
        What’s New and Changelog </a>
      <span class="icon-external"></span>
    </div>
    <div *ngIf="!isFreeUser">
      <a rel="noopener" [href]="resourcesURL.apiUserGuide" target="_blank">
        API User Guide </a>
      <span class="icon-external"></span>
    </div>
    <header>Legal and Privacy</header>
    <div>
      <a rel="noopener" [href]="resourcesURL.termsConditions" target="_blank">
        Terms and Conditions</a><span class="icon-external"></span>
    </div>
    <div>
      <a rel="noopener" [href]="resourcesURL.privacyPolicy" target="_blank">
        Privacy Policy</a><span class="icon-external"></span>
    </div>
    <div>
      <a rel="noopener" [href]="resourcesURL.cookiePolicy" target="_blank">
        Cookie Policy</a><span class="icon-external"></span>
    </div>
    <div>
      <a rel="noopener" [href]="resourcesURL.dataProtection" target="_blank">
        Data Protection Agreement</a><span class="icon-external"></span>
    </div>
    <div>
      <a [href]="resourcesURL.listOfSubprocesses" rel="noopener" target="_blank">
        List of Sub-Processors</a><span class="icon-external"></span>
    </div>
    <header>Help</header>
    <div class="help-support">
      <span class="help-support-link" (click)="openSupportChat()">
        Chat Live with Support
      </span>
      <span class="help-support-link" (click)="openCEMModal()">
        Contact Support
      </span>
    </div>
  </footer>
</div>
