<div class="custom-modal-header">
  <button class="close-button" aria-label="Close" (click)="modal.dismiss(ModalDismissType.cross)">
    <em class="icon-close_medium"></em>
  </button>
</div>
<div class="custom-modal-body">
  <h5>{{config.headerTitle}}</h5>
  <h3>{{config.bodyTitle}}</h3>
  <div class="description">{{config.description}}</div>
  <form [formGroup]="form">
    <fieldset class="form-group" [class.error]="emailCtrl.touched && emailCtrl.invalid">
      <legend>Corporate Email</legend>
      <input type="email" class="form-control" formControlName="email" placeholder="Corporate Email" readonly/>
    </fieldset>
    <fieldset [class.error]="productCodeCtrl.touched && productCodeCtrl.invalid">
      <legend>Product Code</legend>
      <input type="text" class="form-control" formControlName="productCode" placeholder="Product Code" readonly/>
    </fieldset>
    <fieldset [class.error]="messageCtrl.touched && messageCtrl.invalid">
      <legend>{{config.messageInputLabel}}</legend>
      <textarea
        class="form-control"
        formControlName="message"
        placeholder="Write your question"
        rows="5"
      ></textarea>
    </fieldset>
  </form>
</div>
<div class="custom-modal-footer">
  <button class="btn cancel-button" (click)="modal.dismiss(ModalDismissType.cancel)">Cancel</button>
  <button class="btn submit-button" (click)="onSubmitMessage()" [disabled]="form.invalid">Submit Question</button>
</div>
