<em
  class="icon-table"
  [class.active]="isMenuOpened"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="toggleMenuPopover()"
  ngbTooltip="Show and Hide columns"
  placement="top"
  container="body"
></em>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayOpen]="isMenuOpened"
  (backdropClick)="toggleMenuPopover()"
>
  <div class="column-picker-menu">
    <div class="search-box">
      <em class="icon-search1"></em>
      <input
        class="search-box-input"
        type="text"
        (input)="onSearchInputValueChange(searchKeyword)"
        [(ngModel)]="searchKeyword"
        placeholder="Find Columns"
       />
      <em
        class="icon-close_small"
        *ngIf="searchKeyword"
        (click)="onSearchInputClear()"
      ></em>
    </div>

    <div class="columns">
      <div class="columns-title">
        Show/Hide Columns
      </div>
      <div class="columns-list">
        <div *ngFor="let column of _columns" class="column-item">
          <label class="it-checkbox">
            <input
              type="checkbox"
              [id]="column.colId"
              [checked]="!column.hide"
              (click)="onHideToggleColumClick($event, column)"
            />
            <div></div>
            {{column.headerName}}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>
