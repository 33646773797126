// Relax rules as this is code for testing:
/* eslint-disable */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { metaHygraphReportResponseMock } from './interceptor-mock-data';
/**
 * Serializes parameters for some requests known for having parameters with spaces or
 * characters that can't be put as is in the URL
 */
@Injectable({
  providedIn: 'root',
})
export class MockDataInterceptor implements HttpInterceptor {

  private readonly interceptorEnabled = false;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldMockRequest(request)) {
      return this.getMockResponse(request, next);
    } else {
      return next.handle(request);
    }
  }

  /**
   * shouldMockRequest determines if this HTTP request should be mocked.
   *  Add/remove checks here as needed.
   *
   * @param request original
   * @returns true or false
   */
  private shouldMockRequest(request: HttpRequest<any>): boolean {
    // Add/remove conditions here as necessary:
    return (
      this.interceptorEnabled && !environment.production
    );
  }

  /**
   * getMockResponse is the fun zone where the response is updated with mock data.
   *  The checks here must match the ones in shouldMockRequest.
   *
   * @param request
   * @param next
   */
  private getMockResponse(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Add/remove checks here for conditions in shouldMockRequest:

    // Continue normally if missing mock response:
    console.warn(`MockDataInterceptor: Missing mock response for ${request.urlWithParams}`);
    return next.handle(request);
  }

  /**
   * Usage example
   * return this.addElementToResponse(request, next, (response: HttpResponse<any>) => {
   *     const res = response.clone();
   *     res.body[0].children.push({
   *       children: [],
   *       container: 'SIDE_BAR',
   *       content: null,
   *       isCustom: false,
   *       name: 'Reverse Engineering',
   *       type: 'MODULE',
   *     });
   *     return res;
   *   });
   * }
   */
  private addElementToResponse(
    request: HttpRequest<any>,
    next: HttpHandler,
    mapFunction: (s: HttpResponse<any>) => HttpResponse<any>
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      filter((event) => event instanceof HttpResponse),
      map(mapFunction)
    );
  }

  private create200HttpResponseForObject(payload: any): HttpResponse<any> {
    return new HttpResponse({
      status: 200,
      body: payload,
    });
  }

}
