<section class="content">
  <div class="loading" *ngIf="loading; else loaded">
    <app-progress-bar-circular></app-progress-bar-circular>
  </div>
  <ng-template #loaded>
    <iframe
      #iframe
      *ngIf="embeddedUrl"
      [src]="embeddedUrl"
      title="retool-iframe"
    ></iframe>
  </ng-template>
</section>
