import { Point } from 'openseadragon';

export enum MeasurementType {
    Area = 'Area',
    Length = 'Length'
}

export interface MeasuringPoints{
    initialized: boolean,
    firstViewPortPoint: Point,
    lastViewPortPoint : Point,
}

export interface Measurement{
    measurements: number[],
    measurementType: MeasurementType
}

export interface MeasuringScalingFactor{
    micronScalingFactor: number;
    measuringToolsDisabled : boolean;
}