import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {IPosition} from '@app/shell/components/nav-bar/nav.component';
import {animate, query, style, transition, trigger} from '@angular/animations';
import {MenuModel} from '@app/store/Models/workspace/workspaceModel';
import {
  selectMenuItems,
  selectMoreContainerShown,
  selectVisibleButtonsAmount
} from '@app/store/Selectors/workspace/workspace.selectors';
import {Store} from '@ngrx/store';
import {toggleMoreContainer} from '@app/store/Actions/workspace/workspace.actions';
import {tap} from 'rxjs/operators';
import {untilDestroyed} from '@core';

/**
 * Component for the nav-more container, containing all the buttons that do not fit on the nav-bar
 */
@Component({
  selector: 'app-nav-more-container',
  templateUrl: './nav-more-container.component.html',
  styleUrls: ['./nav-more-container.component.scss'],
  animations: [
    trigger('listParent', [
      transition('* => *', [
        query(':enter', style({ width: 0, opacity: 0})),
        query(':enter', animate('150ms ease-in', style({ width: '*', opacity: 1 })))
      ])
    ])
  ]
})
export class NavMoreContainerComponent implements OnInit, OnDestroy {
  @Input() position: IPosition;
  len: any;
  showMore = false;
  menuSize: Observable<number> = this.store.select(selectVisibleButtonsAmount).pipe(untilDestroyed(this));

  buttons: Observable<MenuModel[]> = this.store.select(selectMenuItems).pipe(untilDestroyed(this));

  constructor(private readonly store: Store) {
  }

  ngOnInit(): void {
    // Observe the total length of the buttons list
    this.buttons.pipe(
      untilDestroyed(this)
    ).subscribe((res) => (this.len = res.length))

    // Observe if this container should be shown
    this.store.select(selectMoreContainerShown)
      .pipe(
        tap(v => this.showMore = v),
        untilDestroyed(this)
      ).subscribe()
  }

  /**
   * Toggle container state
   */
  close() {
    this.store.dispatch(toggleMoreContainer())
  }

  ngOnDestroy(): void {
    // Needed for untilDestroyed
  }
}
