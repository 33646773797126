import { NumberSymbol } from '@angular/common';
import { ChannelCard } from '@app/@shared/models/shared.models';
import { sanitizeNumber } from '@app/@shared/utils/common';

export class MarketSegmentsChannelCard extends ChannelCard {
  reportsCount: number;

  constructor(channel: {
    id: string,
    title: string,
    entitled: boolean,
    reportsCount: NumberSymbol,
  }) {
    super(channel.id, channel.title, channel.entitled);
    this.reportsCount = channel.reportsCount;
  }

  public buildSubtitle(): string {
    return `${sanitizeNumber(this.reportsCount)} Reports`;
  }

  public getSubtitleSegments(): Array<{label: string}> {
    return [
      { label: this.buildSubtitle() },
    ];
  }
}
