<div class="progress-circular">
<ng-lottie
  width="{{size?.width}}px"
  height="{{size?.height}}px"
  [options]="options"
  (animationCreated)="animationCreated($event)"
  (enterFrame)="setFrame($event)"
  (complete)="complete()"
></ng-lottie>
<span *ngIf="determined && currentFrame > 0 && currentFrame <= 100">{{currentFrame}}%</span>
</div>