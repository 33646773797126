import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TabItem } from '@app/@shared/models/shared.models';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: [ './tab-bar.component.scss' ],
})
export class TabBarComponent {

  @Input() tabs: TabItem[];
  @Input() activePosition: TabItem;

  @Output() tabClick = new EventEmitter<TabItem>();

  onClickTab(tab: TabItem): void {
    if (!tab.disabled) {
      this.activePosition = tab;
      this.tabClick.emit(tab);
    }
  }

}
