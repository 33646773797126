import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { TableauService } from '@shared/services/tableau.service';
import { DBToolsSubscriptionCard, TableauConfig } from '@shared/models/shared.models';
import { PreviousPageService } from '@shared/services/previous-page.service';
import { DatabaseToolsService } from '@app/database-tools/service/database-tools.service';
import { EntitlementService } from '@shared/services/entitlement/entitlement.service';
import { DatabaseToolsIds } from '@app/database-tools/service/database-tools';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { environment } from '@env/environment';
import { concatMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-database-tools-tableau-container',
  templateUrl: './database-tools-tableau-container.component.html',
  styleUrls: ['./database-tools-tableau-container.component.scss'],
})
@UntilDestroy()
export class DatabaseToolsTableauContainerComponent implements OnInit {
  public tableauDatabaseToolsConfig: TableauConfig;
  public titleName = 'Database Tools';
  public subscriptionName = '';
  public carboonFootprintCard: DBToolsSubscriptionCard;
  public carbonFootprintFFlag = false;
  public dbToolsIds: DatabaseToolsIds[] = [
    DatabaseToolsIds.PRODUCT_SEARCH,
    DatabaseToolsIds.PRODUCT_SEARCH_SUBSYSTEM,
    DatabaseToolsIds.IC_SEARCH
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tableauService: TableauService,
    public prevPage: PreviousPageService,
    private databaseToolsService: DatabaseToolsService,
    private entitlementService: EntitlementService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    combineLatest([this.route.paramMap, this.route.fragment,
                   this.databaseToolsService.getAllDatabaseTools(),
                   this.featureFlagService.getFlag(environment.enableCarbonFootprint)])
      .pipe(concatMap(response => this.tableauService.getTableauTicket()
        .pipe(map(ticket => ([ticket, ...response])))), untilDestroyed(this))
      .subscribe(([{ticket}, paramMap, fragment, allDBTools, productSearchCFfeatureFlag]: any[]) => {
        const fragments = new URLSearchParams(fragment);
        if (paramMap.has('subscriptionId') && ticket) {
          const dbToolsConfig = this.databaseToolsService.getDBToolsConfig(paramMap.get('subscriptionId'), ticket);
          if (dbToolsConfig?.url) {
            this.subscriptionName = fragments.get('name');
            this.tableauDatabaseToolsConfig = dbToolsConfig;
          } else {
            this.router.navigate(['database-tools']);
          }
        } else {
          this.router.navigate(['database-tools']);
        }
        const subscriptionId = paramMap.get('subscriptionId') as DatabaseToolsIds;
        if (this.dbToolsIds.includes(subscriptionId)) {
          this.getCarbonFootprintCard(allDBTools, subscriptionId);
          this.setFeatureFlagValue(subscriptionId, productSearchCFfeatureFlag);
        }
      });
  }

  /**
   * Navigates back
   */
  public onClickBack() {
    this.router.navigate([this.prevPage.getPreviousUrl()]);
  }

  /**
   * This function call the request access modal if the button is clicked in
   * the title-bar.component (Upgrade to Display Carboon Footprint button)
   */
  public onClickRequestAccess() {
    this.entitlementService.displayEntitlementRequestPanel(this.carboonFootprintCard, true);
  }

  /**
   * This function retrieves a specific card (when the id match) from an array
   * of DBToolsSubscriptionCard objects.
   * @param allDBTools An array of DBToolsSubscriptionCard objects.
   * @param subsId A String with the id from the url.
   */
  private getCarbonFootprintCard(allDBTools: DBToolsSubscriptionCard[], subsId: string) {
    const toolMappings = [
      { toolId: DatabaseToolsIds.PRODUCT_SEARCH_CARBON_FOOTPRINT, subsId: DatabaseToolsIds.PRODUCT_SEARCH },
      { toolId: DatabaseToolsIds.PRODUCT_SEARCH_SUBSYSTEM_CARBON_FOOTPRINT,
        subsId: DatabaseToolsIds.PRODUCT_SEARCH_SUBSYSTEM },
      { toolId: DatabaseToolsIds.IC_SEARCH_CARBON_FOOTPRINT, subsId: DatabaseToolsIds.IC_SEARCH }
    ];
    this.carboonFootprintCard = allDBTools.find(item => {
      return toolMappings.some(mapping => {
        return item.id === mapping.toolId && subsId === mapping.subsId;
      });
    });
  }

  /**
   * Depending on the condition, the value of the variable "carbonFootprintFFlag" will be set.
   * @param subsId
   * @param productSearchCFfeatureFlag
   */
  private setFeatureFlagValue(subsId: string, productSearchCFfeatureFlag: boolean): void {
    if (subsId === DatabaseToolsIds.PRODUCT_SEARCH || subsId === DatabaseToolsIds.PRODUCT_SEARCH_SUBSYSTEM) {
      this.carbonFootprintFFlag = productSearchCFfeatureFlag;
    }  else if (subsId === DatabaseToolsIds.IC_SEARCH) {
      this.carbonFootprintFFlag = true;
    }
  }
}
