import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ModalDismissType} from '@shared/enums/modal-dismiss-type';
import {ContactModalConfigurationInterface} from '@shared/interfaces/contact-modal-configuration.interface';
import {ContactModalAskAnExpertConfig} from '@shared/models/contact/contact-modal-ask-an-expert.config';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss']
})
export class ContactModalComponent {
  @Input() config: ContactModalConfigurationInterface = new ContactModalAskAnExpertConfig();

  @Input() set email(value: string) {
    this.emailCtrl.setValue(value);
  }

  @Input() set productCode(value: string) {
    this.productCodeCtrl.setValue(value);
  }

  @Input() set message(value: string) {
    this.messageCtrl.setValue(value);
  }

  form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    productCode: new UntypedFormControl('', [Validators.required]),
    message: new UntypedFormControl('', [Validators.required])
  });
  ModalDismissType = ModalDismissType;

  constructor(public modal: NgbActiveModal) { }

  onSubmitMessage(): void {
    if(this.form.valid) {
      this.modal.close(this.form.value);
    } else {
      this.form.markAllAsTouched();
    }
  }

  get emailCtrl(): UntypedFormControl {
    return this.form.get('email') as UntypedFormControl;
  }

  get productCodeCtrl(): UntypedFormControl {
    return this.form.get('productCode') as UntypedFormControl;
  }

  get messageCtrl(): UntypedFormControl {
    return this.form.get('message') as UntypedFormControl;
  }
}
