<app-title-bar
  [title]="title"
  [showBackButton]="prevPage.hasHistory()"
  (clickBack)="onClickBack()"
></app-title-bar>
<ng-container *ngIf="wordpressUrl; else pdfPvo">
  <iframe [src]="wordpressUrl | noSanitizeUrl" [title]="title"></iframe>
</ng-container>
<ng-template #pdfPvo>
  <app-pdf-viewer
    *ngIf="pdfAssetInfo"
    class="pdf-viewer"
    [pdfAssetInfo]="pdfAssetInfo"
    [toolbarTitle]="filename"
    [showSharedButton] = "true"
    [(showDownloadButton)] = "showDownloadButton"
    (downloadPdf) = "onDownloadPdf()"
  ></app-pdf-viewer>
</ng-template>
