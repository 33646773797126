/* eslint-disable no-console */
import {Injectable} from '@angular/core';
import { AuthenticationService } from '@app/auth/authentication.service';
import { selectWorkspaceLoaded } from '@app/store/Selectors/workspace/workspace.selectors';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class DatadogService{

  private workspaceLoaded$ = this.store.select(selectWorkspaceLoaded);
  constructor(private authService: AuthenticationService, private store: Store) {
    this.workspaceLoaded$.pipe(filter((x) => x === true)).subscribe(() => {
      if(environment.runDatadogRUM) {
        this.setDatadogUser();
      }
    })
  }

  initialize(){
    if(environment.runDatadogRUM) {
      datadogRum.init({
        applicationId: environment.datadogApplicationId,
        clientToken: environment.datadogClientToken,
        env: environment.datadogEnv,
        site: environment.datadogSite,
        service: environment.datadogService,
        sampleRate: 100,
        trackInteractions: true,
      })
      this.logInitializing();
    }
  }

  logInitializing(){
    console.log('DataDog init done')
  }

  setDatadogUser(){
    this.authService.me()
    .pipe(
      map((user) =>
        datadogRum.setUser({
          id: user.id
        })
      )
    ).subscribe();
  }
}
