import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TelemetryEvent } from '@app/@shared/models/telemetry-event';
import { MetaService } from '@app/@shared/services/meta.service';
import { PreviousPageService } from '@app/@shared/services/previous-page.service';
import { CredentialsService } from '@app/auth';
import { Video } from '@shared/models/market-analysis/market-analysis';
import { tap, withLatestFrom, concatMap } from 'rxjs/operators';
import { TelemetryEventName } from '@shared/enums/telemetry-event-name';
import { SearchService } from '@shared/services/search/search.service';

@Component({
  selector: 'app-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss'],
})
export class VideoViewerComponent implements OnInit {
  video: Video;
  subscription: string;
  isLoading = true;

  constructor(
    private credentialService: CredentialsService,
    private searchService: SearchService,
    private metaService: MetaService,
    private route: ActivatedRoute,
    public prevPage: PreviousPageService
  ) { }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        withLatestFrom(this.route.fragment),
        tap(([, fragment]) => (this.subscription = new URLSearchParams(fragment).get('subscription'))),
        concatMap(([params]) => this.searchService.getVideo(params.get('videoId')))
      )
      .subscribe((video) => {
        this.video = video;
        this.isLoading = false;
      });
  }

  public onClickBack() {
    this.prevPage.navigateBack();
  }

  public onTrackVideoViewTelemetryEvent() {
    const { applicationModule = 'Unknown' } = this.route.snapshot.data;

    const payload = {
      userId: this.credentialService.getUserId(),
      videoId: this.video.id,
      videoTitle: this.video.title
    };

    const event = new TelemetryEvent(TelemetryEventName.EVENT_TISAPP_VIDEO_VIEW, payload,applicationModule);
    this.metaService.postTelemetryEvent(event).subscribe();
  }
}
