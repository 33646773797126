export const ReverseSortOptions = [
    {
        label: 'Most Relevant',
        value: 'relevancy,DESC',
    },
    {
        label: 'Newest First',
        value: 'releaseDate,DESC',
    },
    {
        label: 'Oldest First',
        value: 'releaseDate,ASC',
    },
    {
        label: 'Manufacturer',
        value: 'deviceManufacturer,ASC',
    }
]
export const MarketSortOptions = [
    {
        label: 'Most Relevant',
        value: 'relevancy,DESC',
    },
    {
        label: 'Newest First',
        value: 'releaseDate,DESC',
    },
    {
        label: 'Oldest First',
        value: 'releaseDate,ASC',
    }
]
export const globalDefaultSort = {
    label: 'Most Relevant',
    value: 'relevancy,DESC',
}
export const defaultSort = {
    label: 'Newest First',
    value: 'releaseDate,DESC'
}
