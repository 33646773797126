import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TileSourceLayerTableGui } from '@app/@shared/models/kimera/webCVJSON';

@Component({
  selector: 'app-kimera-colour-picker',
  templateUrl: './kimera-colour-picker.component.html',
  styleUrls: ['./kimera-colour-picker.component.scss']
})
export class KimeraColourPickerComponent {

  @Output() applyViewerColour: EventEmitter<{colour: string, opacity: number}>
                      = new EventEmitter<{colour: string, opacity: number}>();
  @Output() closeColourPicker: EventEmitter<any> = new EventEmitter();
  @Input() set currentSelectedTileSourceLayer( currentSelectedTileSourceLayer: TileSourceLayerTableGui){
      if(currentSelectedTileSourceLayer){
        this.currentColour = currentSelectedTileSourceLayer.colour;
        this.currentOpacity = parseInt((currentSelectedTileSourceLayer.opacity*100).toFixed(0),10);
        this._currentSelectedTileSourceLayer = currentSelectedTileSourceLayer;
      }
  }
  @Input() scrollDiff: number;
  @Input() cvToolbarHeight: number;
  @Input() kimeraGridCvContentComponentHeight: number;
  @Input() metaDataItemHeight: number;
  @Input() metaDataItemWidth: number;

  colourPickerContainerHeight = 220;
  currentOpacity: number;
  currentColour: string;
  _currentSelectedTileSourceLayer: TileSourceLayerTableGui;
  colours:string[] = ['#C11C26','#FF0000','#FF8000','#FFFF00','#000080','#0000FF', '#5FB5FF',
  '#73F9FD','#800080','#8E5BE1','#F82FDC','#325E18','#26A73E','#41D8B1','#00FF00','#964B00','#C38241','#E9DDC8','#858585','#D6D6D6','#FFFFFF']


  onChangeOpacity(opacity: number){
    this.currentOpacity = opacity;
  }
  onColourChange(colour: string){
    this.currentColour = colour;
  }

  onResetToDefault(){
    this.applyViewerColour.emit(null);
  }

  apply(){
    this.applyViewerColour.emit({colour: this.currentColour, opacity: this.currentOpacity/100});
  }

  getStyle(){
      const baseStyle = {'height':`${this.colourPickerContainerHeight}px`,'left':`${this.metaDataItemWidth+4}px`};
      if(this.kimeraGridCvContentComponentHeight< this.colourPickerContainerHeight){
        return {...baseStyle, 'top': `${this.cvToolbarHeight}px`};
      }
      const top = Math.max((this._currentSelectedTileSourceLayer.index)*this.metaDataItemHeight - this.scrollDiff
                  ,this.cvToolbarHeight);
      const isOverflowing =(top+this.colourPickerContainerHeight)>this.kimeraGridCvContentComponentHeight;
      return {
        ...baseStyle,
        top: isOverflowing ? 'auto' : `${top}px`,
        bottom: isOverflowing ? '0px' : 'auto'
      };
  }


  onClickOutsideColourPicker(){
    this.closeColourPicker.emit();
  }


}
