import { TableauConfig } from '@app/@shared/models/shared.models';
import { environment as env } from '@env/environment';

export enum DatabaseToolsIds {
  PRODUCT_SEARCH = 'product-search',
  PRODUCT_SEARCH_SUBSYSTEM = 'product-search-subsystem',
  IC_SEARCH = 'ic-search',
  MODULE_SEARCH = 'module-search',
  PRODUCT_SEARCH_W_EXTRA_DIE = 'product-search-with-extra-die',
  IC_SEARCH_W_EXTRA_DIE = 'ic-search-with-extra-die',
  AUTOMOTIVE_IC_DESIGN_WINS = 'automotive-ic-design-wins',
  IMAGE_SENSOR = 'image-sensor-content-navigator',
  COMPONENT_PRICE_ANALYZER = 'component-price-analyzer',
  PRODUCT_SEARCH_CARBON_FOOTPRINT = 'product-search-carbon-footprint',
  PRODUCT_SEARCH_SUBSYSTEM_CARBON_FOOTPRINT = 'product-search-subsystem-carbon-footprint',
  IC_SEARCH_CARBON_FOOTPRINT = 'ic-search-carbon-footprint'
};

export const DATABASE_TOOL_LINK_MAP: Map<DatabaseToolsIds, string> = new Map([
  [DatabaseToolsIds.COMPONENT_PRICE_ANALYZER, env.componentPriceAnalyzerUrl]
])

export const DatabaseToolsConfig = {
  PRODUCT_SEARCH: {url: env.productSearchUrl, width: env.defaultVizWidth, height: env.defaultVizHeight},
  PRODUCT_SEARCH_SUBSYSTEM:
    {url: env.productWSubsystemUrl, width: env.defaultVizWidth, height: env.defaultVizHeight},
  IC_SEARCH: {url: env.icSearchUrl, width: env.defaultVizWidth, height: env.defaultVizHeight},
  MODULE_SEARCH: {url: env.modulesSearchUrl, width: env.defaultVizWidth, height: env.defaultVizHeight},
  PRODUCT_SEARCH_W_EXTRA_DIE:
    {url: env.productWExtraDieInfo, width: env.productWExtraDieVizWidth, height: env.productWExtraDieVizHeight},
  IC_SEARCH_W_EXTRA_DIE: {
    url: env.icSearchWithExtraDieInfo,
    width: env.icSearchWithExtraDieVizWidth,
    height: env.icSearchWithExtraDieVizHeight
  },
  PRODUCT_SEARCH_CARBON_FOOTPRINT: {
    url: env.productSearchCarbonFootprintUrl,
    width: env.defaultVizWidth,
    height: env.defaultVizHeight,
  },
  PRODUCT_SEARCH_SUBSYSTEM_CARBON_FOOTPRINT: {
    url: env.productSearchWSubsystemCarbonFootprintUrl,
    width: env.defaultVizWidth,
    height: env.defaultVizHeight,
  },
  IC_SEARCH_CARBON_FOOTPRINT: {
    url: env.icSearchCarbonFootprintUrl,
    width: env.productWExtraDieVizWidth,
    height: env.productWExtraDieVizHeight
  }
};

export const DATABASE_TOOLS_MAP: Map<DatabaseToolsIds, TableauConfig> = new Map([
  [DatabaseToolsIds.PRODUCT_SEARCH, DatabaseToolsConfig.PRODUCT_SEARCH],
  [DatabaseToolsIds.PRODUCT_SEARCH_SUBSYSTEM, DatabaseToolsConfig.PRODUCT_SEARCH_SUBSYSTEM],
  [DatabaseToolsIds.IC_SEARCH, DatabaseToolsConfig.IC_SEARCH],
  [DatabaseToolsIds.MODULE_SEARCH, DatabaseToolsConfig.MODULE_SEARCH],
  [DatabaseToolsIds.PRODUCT_SEARCH_W_EXTRA_DIE, DatabaseToolsConfig.PRODUCT_SEARCH_W_EXTRA_DIE],
  [DatabaseToolsIds.IC_SEARCH_W_EXTRA_DIE, DatabaseToolsConfig.IC_SEARCH_W_EXTRA_DIE],
  [DatabaseToolsIds.PRODUCT_SEARCH_CARBON_FOOTPRINT, DatabaseToolsConfig.PRODUCT_SEARCH_CARBON_FOOTPRINT],
  [
    DatabaseToolsIds.PRODUCT_SEARCH_SUBSYSTEM_CARBON_FOOTPRINT,
    DatabaseToolsConfig.PRODUCT_SEARCH_SUBSYSTEM_CARBON_FOOTPRINT
  ],
  [DatabaseToolsIds.IC_SEARCH_CARBON_FOOTPRINT, DatabaseToolsConfig.IC_SEARCH_CARBON_FOOTPRINT],
]);

export const ENTITLEMENT_MAP_DATABASE_TOOL: Map<DatabaseToolsIds, string> = new Map([
  [DatabaseToolsIds.PRODUCT_SEARCH, env.principalEntitlementId],
  [DatabaseToolsIds.PRODUCT_SEARCH_SUBSYSTEM, env.entitlementSubsystemId],
  [DatabaseToolsIds.IC_SEARCH, env.principalEntitlementId],
  [DatabaseToolsIds.MODULE_SEARCH, env.principalEntitlementId],
  [DatabaseToolsIds.PRODUCT_SEARCH_W_EXTRA_DIE, env.entitlementExtraId],
  [DatabaseToolsIds.IC_SEARCH_W_EXTRA_DIE, env.entitlementExtraId],
  [DatabaseToolsIds.IMAGE_SENSOR, 'SME-1901-802'],
  [DatabaseToolsIds.AUTOMOTIVE_IC_DESIGN_WINS, 'AID-2306-801'],
  [DatabaseToolsIds.COMPONENT_PRICE_ANALYZER, env.entitlementCPA],
  [DatabaseToolsIds.PRODUCT_SEARCH_CARBON_FOOTPRINT, env.entitlementProductSearchCarbon],
  [DatabaseToolsIds.PRODUCT_SEARCH_SUBSYSTEM_CARBON_FOOTPRINT, env.entitlementProductSearchWSubsystemCarbon],
  [DatabaseToolsIds.IC_SEARCH_CARBON_FOOTPRINT, env.entitlementIcSearchCarbon],
]);
export const DATABASE_TOOL_ICON_MAP: Map<DatabaseToolsIds, string> = new Map([
  [DatabaseToolsIds.PRODUCT_SEARCH, 'icon-product'],
  [DatabaseToolsIds.PRODUCT_SEARCH_SUBSYSTEM, 'icon-product'],
  [DatabaseToolsIds.IC_SEARCH, 'icon-packaging'],
  [DatabaseToolsIds.MODULE_SEARCH, 'icon-document'],
  [DatabaseToolsIds.PRODUCT_SEARCH_W_EXTRA_DIE, 'icon-product'],
  [DatabaseToolsIds.IC_SEARCH_W_EXTRA_DIE, 'icon-packaging'],
  [DatabaseToolsIds.IMAGE_SENSOR, 'icon-image_sensor'],
  [DatabaseToolsIds.AUTOMOTIVE_IC_DESIGN_WINS, 'icon-automotive'],
  [DatabaseToolsIds.COMPONENT_PRICE_ANALYZER, 'icon-cpl'],
  [DatabaseToolsIds.PRODUCT_SEARCH_CARBON_FOOTPRINT, 'icon-product'],
  [DatabaseToolsIds.PRODUCT_SEARCH_SUBSYSTEM_CARBON_FOOTPRINT, 'icon-product'],
  [DatabaseToolsIds.IC_SEARCH_CARBON_FOOTPRINT, 'icon-packaging'],
])
