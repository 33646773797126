<div [ngClass]="fromUnifiedSearch ? 'pagination-top-container-unified-search' : 'pagination-top-container'" 
    [class.desktop-search-unified-search]="fromUnifiedSearch">
  <div class="sort" *ngIf="showSort;else render">
    <span class="text">Sort By</span>
    <it-select [options]="sortOptions" [initialOption]="selectedSort" (changeOption)="changeSort($event)"></it-select>
  </div>
  <ng-template #render>
    <div></div>
  </ng-template>
  <div class="pagination-control-top" [class.hidden]="showSort">
    <div class="icon-left_large toolbar_arrow" [class.arrows-hidden]="currentPage === 1" (click)="prevPage()"></div>
    {{ generateTitle() }}
    <div class="icon-right_large toolbar_arrow next-arrow" [class.arrows-hidden]="currentPage === totalPages" (click)="nextPage()"></div>
  </div>
  <div class="pagination-amount">View:
    <a *ngFor="let size of pageSizes" [class.selected]="pageSize === size" (click)="changePageSize(size)">{{size}}</a>
  </div>
</div>
