<!-- ANALYSIS -->
<ng-template #report let-report>
  <div class="content-wrapper">
    <div class="title emphasis truncate-paragraph-ellipsis line-clamp-2"
      [innerHTML]="report.name">
    </div>
    <div class="description truncate-paragraph-ellipsis line-clamp-2"
      [innerHTML]="report.description">
    </div>
    <div class="footer">
      <div class="center indicator-content" (click)="onClickFileCounter($event, report)"
        [class.disabled]="!report.entitled || report.documentsCount === 0">
        <span class="icon-document icon small indicator">
        </span>
        <span class="count-text indicator">
          {{ (parseInt(report.documentsCount)&gt;=0) ? report.documentsCount : '0' }}
        </span>
      </div>
      <div class="center indicator-content" (click)="onClickImageCounter($event, report)"
        *ngIf="report.imagesCount && report.imagesCount !== 0"
        [class.disabled]="!report.entitled">
        <span class="icon-image icon small indicator">
        </span>
        <span class="count-text indicator">
          {{ (parseInt(report.imagesCount)&gt;=0) ? report.imagesCount : '0' }}
        </span>
      </div>
      <div class="metadata-container truncate-paragraph-ellipsis line-clamp-1">
        <span *ngIf="report.deviceManufacturer" class="analysis-metadata-info margin-for-analysis">
          Manufacturer: {{ report.deviceManufacturer }}
        </span>
        <span *ngIf="report.deviceName" class="analysis-metadata-info margin-for-analysis">
          Device Name: {{ report.deviceName }}
        </span>
        <span *ngIf="report.deviceType" class="analysis-metadata-info margin-for-analysis">
          Device Type: {{ report.deviceType }}
        </span>
        <span *ngIf="report.productCode" class="analysis-metadata-info margin-for-analysis">
          Product Code: {{ report.productCode }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
<!-- BLOGS -->
<ng-template #blog let-blog>
  <div class="content-wrapper">
    <div class="title emphasis truncate-paragraph-ellipsis line-clamp-2"
      [innerHTML]="blog.name">
    </div>
    <div class="description truncate-paragraph-ellipsis line-clamp-2"
      [innerHTML]="blog.description">
    </div>
    <div class="footer">
      <div *ngIf="blog.author" class="metadata-container blog-metadata-info margin-for-blogs truncate-paragraph-ellipsis line-clamp-1">
        Author: {{ blog.author ? blog.author : '-' }}
      </div>
    </div>
  </div>
</ng-template>
<!-- VIDEOS -->
<ng-template #video let-video>
  <div class="content-wrapper content-video-wrapper">
    <div class="video-wrapper">
      <img [src]="video.thumbnailUrl ? video.thumbnailUrl : '-'"
        alt=""
        aria-hidden="true"/>
      <div class="title emphasis truncate-paragraph-ellipsis line-clamp-2 video-text"
        [innerHTML]="video.name">
      </div>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="!showNoResults && results">
  <a
  *ngFor="let result of results; let i = index"
  class="row-container"
  [href]="result.contentHref ?? createContentTypeUrl(result)"
  [attr._ti_list_all_item]="i"
  [class.heap-non-entitled-report]="!result.entitled"
  (click)="onClickRow(result, $event)">
    <div class="header-wrapper">
      <div *ngIf="false" class="it-checkbox-wrapper">
        <label class="it-checkbox">
          <input
            type="checkbox"
            [checked]="!!result.selected"/>
          <div></div>
        </label>
      </div>
      <div class="header-content-wrapper">
        <div *ngIf="!result.entitled" class="lock-icon-container">
          <em class="icon small icon-locked"></em>
        </div>
        <div class="type-date-info truncate-paragraph-ellipsis line-clamp-1">
          <span>{{result.showType}} <em *ngIf="result.completionDate" class="vertical-line">|</em> {{result.completionDate | date: 'MMM d, yyyy' : '+0000' }}
            <em class="vertical-line">|</em> {{result.assetLocation}}</span>
        </div>
      </div>
      <div class="meatball-wrapper">
        <app-meatball-menu *ngIf="!hideMeatballMenu" [disabled]="true">
          <button>
            <em class="icon-external"></em> Open in new tab
          </button>
          <button>
            <em class="icon-share"></em> Share
          </button>
          <button *ngIf="result.type === 'report'">
            <em class="icon-star"></em> Add to Favorites
          </button>
        </app-meatball-menu>
      </div>
    </div>
    <ng-container
      [ngTemplateOutlet]="getTemplate(result.type)"
      [ngTemplateOutletContext]="{$implicit: result}">
    </ng-container>
  </a>
</ng-container>
<div *ngIf="showNoResults" class="no-results">
  <img src="assets/images/icons/Icon_Null-Search.svg" alt="No results."/>
  <div class="text">
    <span class="main-title">
      {{ noContentTitle }}
    </span>
    <span class="sub-title" [innerHTML]="noContentSubTitle"></span>
  </div>
</div>
