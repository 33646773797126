import {Injectable, Injector} from '@angular/core';
import {combineLatest, EMPTY, forkJoin, Observable, of} from 'rxjs';
import {
  IFilterResponse,
  IFilterType,
  INewUpdateResult,
  IRecentSearch,
  IResultsAmount,
  IReturnedSearchItem,
  ISavedSearch,
  ISearchResponse,
  ISearchResults,
  ISearchTerms
} from '../../../store/Models/search/searchModels';
import {environment} from '@env/environment';
import {SearchServiceMock} from './search.service.mock';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, concatMap, defaultIfEmpty, map } from 'rxjs/operators';
import {MetaService} from '@shared/services/meta.service';
import {CONTENT_TYPE_ENUM, getContentTypeLabel} from '@shared/models/search';
import {PaginationParameters} from '@app/@shared/models/pagination';
import {
  AnalysisFilter,
  AnalysisFilterKey,
  AnalysisFilterType,
  Facet,
  Filters,
  UnifiedFilterKey
} from '@app/@shared/models/reverse-engineering/analysis.models';
import {
  ChannelCount,
  BlogContent,
  CTIBlogSearchResult,
  CTIContentType,
  CTIFilterResponse,
  CTISearchBaseResult,
  CTISearchRequest,
  CTISearchResponse,
  CTISearchResult,
  CTISearchResults,
  CTIVideoSearchResult,
  ContentPaginatedList,
  ReportContent,
  VideoContent,
  PublicCTISearchResponse
} from '@app/@shared/models/search/cti-search.model';
import {PaginationHelper} from '@app/@shared/utils/pagination-helper';
import {NavigationService} from '../navigation/navigation.service';
import {FilterHelper} from '@app/@shared/utils/filter-helper';
import {
  ContentNavigatorTablePage,
  FlavourCounts
} from '@app/@shared/models/content-navigator/content-navigator.model';
import {
  Channel,
  DefaultSubscription,
  NavigationElement,
  Subscription,
  isChannel,
} from '@app/@shared/models/navigation/navigation.models';
import {ProductInterface} from '@shared/interfaces/product.interface';
import {ReportContainerRequestModel} from '@shared/models/report-container-request.model';
import {
  Blog,
  BlogExcerptPaginatedList,
  Video,
  VideoPaginatedList
} from '@shared/models/market-analysis/market-analysis';
import {FeatureFlagService} from '@shared/services/featureflag.service';
import {SearchTermsRequestModel} from '@shared/models/search/search-terms-request.model';
import {ReportSearchResultModel} from '@shared/models/search/report-search-result.model';
import {RegularExpressions} from '@shared/expresions/regular-expressions';
import {defaultIcon, iconMap} from '@app/strategy-analytics/services/strategy-analytics-icon-map';

/**
 * The Search service. Has all the API calls and mappings to interact with the Search APIs
 */
@Injectable({
  providedIn: 'root',
})
export class SearchService {

  RECENT_SEARCHES_KEY = 'recentSearches';
  private WISTIA_EMBED_URL_INSECURE = 'http://embed.wistia.com';
  private WISTIA_EMBED_URL_SECURE = 'https://embed-ssl.wistia.com';
  private mockService: SearchServiceMock = null;
  private _SAVED_SEARCH = '/saved_searches';
  private readonly SIMPLE_FILTERS_FIELD_NAME = ['entitlementState'];
  private readonly FILTERS_SHOW_IN_SIDEBAR = [
    'subscriptionName',
    'channel',
    'type',
    'deviceManufacturer',
    'deviceType',
    'analysisYear',
  ];
  constructor(private injector: Injector,
              private http: HttpClient,
              private metaService: MetaService,
              private navService: NavigationService,
              private ffService: FeatureFlagService) {
    this.mockService = this.injector.get(SearchServiceMock) as SearchServiceMock;
  }

  /**
   * Gets the result amounts for the given terms, omitting content type to fetch all the amounts.
   * Currently, if you ask the search API for a set of results specifying the content type, it will only return the
   * amounts for that content type ONLY. This is a method to work around that.
   * @param searchTerms   search terms
   */
  getSearchResultsAmounts(searchTerms: ISearchTerms): Observable<IResultsAmount> {
    const searchTermsRequest = new SearchTermsRequestModel(searchTerms);
    searchTermsRequest.indexEnd = 0;
    searchTermsRequest.indexStart = 0;
    searchTermsRequest.selectedContentType = [];
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.http.post(`${environment.searchServiceUrl}/search`, searchTermsRequest, {headers})
      .pipe(map((response: ISearchResponse) => {
        return this.normalizeResultsAmounts(response?.resultsAmount ?
          response.resultsAmount : null);
      }));
  }

  /**
   * Gets the initial search results for the 'All' content type.
   * This needs to make an API call for each content type (in parallel using a forkJoin()),
   * since we cannot do this on a single call, due to data availability.
   * @param searchTerms   search terms
   */
  getInitialSearchResults(searchTerms?: ISearchTerms): Observable<[ISearchResults, IFilterType[]]> {

    let terms: SearchTermsRequestModel;
    const contentTypes: string[] = Object.values(CONTENT_TYPE_ENUM);

    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    const filtersRequestBody = new SearchTermsRequestModel(searchTerms);
    filtersRequestBody.indexEnd = 1;
    filtersRequestBody.indexStart = 0;
    const requests = contentTypes.map(contentType => {
      terms = new SearchTermsRequestModel(searchTerms);
      terms.indexStart = 0;
      terms.indexEnd = 10;
      terms.selectedContentType = [contentType];
      return this.http.post(`${environment.searchServiceUrl}/search`,
        terms,
        {headers})
    }).concat(this.http.post(`${environment.searchServiceUrl}/search`,
      filtersRequestBody,
      {headers}))

    return forkJoin(requests)
      .pipe(map((response: ISearchResponse[]) => {
        return this.mapInitialResponse(response);
      }));
  }

  /**
   * Gets the search results from the Search API using the given search terms
   * @param terms   search terms
   */
  getSearchResults(terms: ISearchTerms): Observable<[ISearchResults, IFilterType[]]> {
    if (!environment.usesExternalAPI) {
      return this.mockService.getSearchResults(terms).pipe(map(v => [
        v,
        this.getFiltersSync()
      ]));
    } else {
      const headers = new HttpHeaders()
        .set('Accept', 'application/json');
      const termsRequest: SearchTermsRequestModel = new SearchTermsRequestModel(terms);
      return this.http.post(`${environment.searchServiceUrl}/search`, termsRequest, {headers})
        .pipe(map((response: ISearchResponse) => {
          return this.mapToSearchResults(response);
        }));
    }
  }

  getItemHightlights(docId: string, searchItem: ISearchTerms ): Observable<any> {
    const filters = searchItem.selectedFilters
    .map(selectedFilter => {
      return {
        ...selectedFilter,
        subTypes: Array.isArray(selectedFilter.subTypes) ?
                  selectedFilter.subTypes.filter(v => v.value === true)
                                                         : []
      }}).filter(selectedFilter => selectedFilter.subTypes.length > 0)
      .map(selectedFilter => {
        return selectedFilter.subTypes.map(v => {
          if(v.value === true) {
            return {
              key: selectedFilter.key,
              value: v.key
            };
          }
        })

        });

    const jsonObject = {
      searchText: searchItem.searchText,
      selectedFilters: filters[0],
      sortMethod: searchItem.sortMethod
    }

    const headers = new HttpHeaders()
    .set('Accept', 'application/json');

    return this.http.post(`${environment.searchServiceUrl}/search/highlight/${docId}`, jsonObject, { headers });
  }

  /**
   * Gets the new and updated results for a given user
   * @param request   request
   */
  getNewAndUpdatedResults(request: string): Observable<INewUpdateResult>{
    return this.http.post(
      `${environment.savedSearchesUrl}/new-content`, request)
      .pipe(
        map((response: INewUpdateResult) => {
          return response;
        })
      );
  }

  /**
   * Saves the current user search on the Saved Search API
   * @param savedSearch   search terms to save
   */
  saveUserSearch(savedSearch: ISavedSearch): Observable<ISavedSearch> {
    return this.http.post(environment.savedSearchesUrl + this._SAVED_SEARCH, savedSearch)
      .pipe(map(v => v as ISavedSearch))
  }

  /**
   * Gets the user saved searches
   */
  getUserSearches(): Observable<ISavedSearch[]> {
    return this.http.get(`${environment.savedSearchesUrl}${this._SAVED_SEARCH}`)
      .pipe(map(res => res as ISavedSearch[]))
  }

  /**
   * Gets the user saved search with a given ID. This will have way more fields than the general saved searches list.
   * @param id      ID of the saved search
   */
  getUserSearchById(id: string): Observable<ISavedSearch> {
    return this.http.get(`${environment.savedSearchesUrl}${this._SAVED_SEARCH}/${id}`)
      .pipe(map(res => res as ISavedSearch))
  }

  /**
   * Deletes a saved search with a given ID
   * @param id    ID of the saved search to delete
   */
  deleteSavedSearchById(id: string): Observable<boolean | any> {
    return this.http.delete(`${environment.savedSearchesUrl}${this._SAVED_SEARCH}/${id}`)
  }

  editSavedSearch(id: string, request: { name: string, notifications_enabled: boolean }): Observable<ISavedSearch> {
    return this.http.put<ISavedSearch>(`${environment.savedSearchesUrl}${this._SAVED_SEARCH}/${id}`, request);
  }

  /**
   * Gets a set of mock filters
   */
  getFilters(): Observable<IFilterType[]> {
    if (!environment.usesExternalAPI) {
      return this.mockService.getSearchFilters();
    } else {
      return EMPTY;
    }
  }

  /**
   * Gets a set of mock filters in a sync way
   */
  getFiltersSync(): IFilterType[] {
    return this.mockService.getSearchFiltersSync();
  }

  /**
   * Transforms a given search terms object to a legible string.
   * This is used to save the legible string as a recent search, or as the title of a saved search to be.
   * @param searchTerms   search terms to transform
   */
  transformToString(searchTerms: ISearchTerms): string {
    const filters = searchTerms.selectedFilters.map(filter => {
      return {
        ...filter,
        subTypes: filter.subTypes.filter(subtype => subtype.value === true)
      }
    })
    let newLabel = '( ';
    filters.filter(
      f => Array.isArray(f.subTypes) && f.subTypes.length > 0 && f.subTypes
        .filter(s => s.value === true).length > 0)
      .forEach((filter,
                fIndex) => {
        if (filter.subTypes.length > 0) { // if any checkbox checked
          if (fIndex > 0) {
            newLabel += ' & ';
          } // if 2 dropdown actives with checkboxes checked
          newLabel += filter.label + ' > ';
        }
        filter.subTypes.forEach((subType,
                                 sIndex,
                                 sArr) => {
          newLabel += subType.key;
          if (sIndex < sArr.length - 1) {
            newLabel += ' , ';
          }
        });

      });
    newLabel += ') ';

    newLabel += searchTerms.searchText;


    let fixedLabel = null;
    // replacing errors
    if (newLabel.indexOf('(  &') !== -1) {
      fixedLabel = newLabel.replace('(  &', '(');
    }

    if (newLabel === '( )' || newLabel === '( ) ') {
      return '';
    }

    if (newLabel.indexOf('( ) ') !== -1) {
      fixedLabel = newLabel.replace('( ) ', '');
    }

    return fixedLabel || newLabel;
  }

  /**
   * Saves the given search terms to the local storage for future retrieval and re-searching
   * @param searchTerms   search terms to save
   */
  saveInLocalstorage(searchTerms: ISearchTerms) {
    // ex:  (Manufacturer > Sony, Xiaomi & Device Type: LCD) Search text
    const search = Object.assign({}, searchTerms);

    // Removing "false" values
    search.selectedFilters = Array.isArray(search.selectedFilters)
      ? search.selectedFilters
        .map(filter => {
          return {
            ...filter,
            subTypes: Array.isArray(filter.subTypes)
              ? filter.subTypes.filter(subtype => subtype.value === true)
              : []
          };
        })
        .filter(filter => filter.subTypes.length > 0)
      : [];

    const newLabel = this.transformToString(search);
    if (newLabel.length === 0) {
      return;
    }

    const prevRecentSearches: IRecentSearch[] = JSON.parse(localStorage.getItem(this.RECENT_SEARCHES_KEY)) || [];

    const newSearch = {
      label: newLabel,
      searchTerms: search
    };

    if (prevRecentSearches.findIndex(s => JSON.stringify(s) === JSON.stringify(newSearch)) >= 0) {
      // Avoid adding duped search
      return;
    }

    prevRecentSearches.unshift(newSearch);

    if (prevRecentSearches.length > 10) {
      prevRecentSearches.pop();
    }

    localStorage.setItem(this.RECENT_SEARCHES_KEY, JSON.stringify(prevRecentSearches));
  }

  /**
   * Gets the recent searches from local storage
   */
  getrecentSearchesFromLocalstorage(): IRecentSearch[] {
    return JSON.parse(localStorage.getItem(this.RECENT_SEARCHES_KEY));
  }

  /**
   * Maps the response from the search API to the app interfaces
   * @param response    Response to map
   */
  mapToSearchResults(response: ISearchResponse): [ISearchResults, IFilterType[]] {
    let searchResponse: ISearchResults = {
      newResultsAmount: undefined,
      paginated: false,
      resultsAmount: {
        components: 0,
        dies: 0,
        images: 0,
        pcb: 0,
        products: 0,
        reports: 0,
        schematics: 0,
        all: 0
      },
      component: [],
      die: [],
      image: [],
      pcb: [],
      product: [],
      report: [],
      schematic: []
    };

    searchResponse = this.mapResponseValues(searchResponse, response as ISearchResponse);
    const filters: IFilterType[] = response.filterResponse != null
      ? response.filterResponse.map(filter => {
        return {
          label: filter.label,
          key: filter.key,
          subTypes: filter.values.map(subtype => {
            return {
              key: subtype.value,
              label: subtype.label,
              amount: subtype.amount,
              value: false
            };
          })
        };
      })
      : [];
    return [
      searchResponse,
      filters
    ];
  }

  /**
   * Maps the filters from a Search API response to the app's interfaces
   * @param filterResponse    Filter response from the API
   */
  mapFilterResponseToFilterTypes(filterResponse: IFilterResponse[]): IFilterType[] {
    const filtersMap = new Map();
    let filtersToReturn: IFilterType[] = [];
    if (!Array.isArray(filterResponse)) {
      return []
    }
    // Made a set of filters and a set of subtypes, to avoid duplication.
    // If a duplicated item is found, the amounts are accumulated
    filterResponse.forEach(filter => {
      if (!filtersMap.has(filter.key)) {
        filtersMap.set(filter.key, {...filter, subTypes: new Map()})
      }
      if (Array.isArray(filter.values)) {
        filter.values.forEach(subtype => {
          if (!filtersMap.get(filter.key).subTypes.has(subtype.value)) {
            filtersMap.get(filter.key).subTypes.set(subtype.value, {...subtype, amount: 0})
          }

          filtersMap.get(filter.key).subTypes.get(subtype.value).amount += subtype.amount;
        })
      } else {
        filtersMap.get(filter.key).subTypes.set(filter.value, {value: filter.value, label: filter.value, amount: 0})
      }
    });

    // Normalizing labels and formatting the object in general
    filtersToReturn = [...filtersMap.values()].map(filter => {
      return {
        key: filter.key,
        label: filter.label ? filter.label : filter.key.replace(/([A-Z]+)/g, ' $1')
          .replace(/([A-Z][a-z])/g, ' $1'),
        subTypes: [...filter.subTypes.values()].map(subtype => {
          return {
            label: subtype.label,
            key: subtype.value,
            amount: subtype.amount,
            value: true
          }
        })
      }
    })
    // Returning sorted filters
    filtersToReturn.forEach((filter, index) => {
      filtersToReturn[index].subTypes = filter.subTypes
        .sort((subtypeA, subtypeB) => subtypeA.label <= subtypeB.label ? -1 : 1);
    })
    return filtersToReturn;
  }

  /**
   * Returns list of report codes associated with genealogy code
   * @param genealogyCode to look fo
   */
  getReportCodesForGenealogyCode(genealogyCode: string): Observable<Array<string>> {
    const url = `${environment.searchServiceUrl}/search`;
    const headers = new HttpHeaders().set('Accept', 'application/json');

    const body = {
      selectedFilters: [{key: 'genealogyCode', value: genealogyCode}],
      searchText: [genealogyCode],
      selectedContentType: ['report'],
      indexStart: 0,
      indexEnd: 1,
      sortMethod: {}
    }

    return this.http.post<any>(url, body, {headers}).pipe(
      map((searchResult) => {
        return searchResult.response
      }),
      map((response) => {
        return response.map((obj: any) => {
          return obj.reportCode
        }).filter((element: any) => {
          return element
        })
      })
    );
  }

  /**
   * Returns list of report codes associated with matCode
   * @param matCode to look fo
   */
  getReportCodesForMatCode(matCode: string): Observable<Array<string>> {
    const url = `${environment.searchServiceUrl}/search`;
    const headers = new HttpHeaders().set('Accept', 'application/json');

    const body = {
      selectedFilters: [{key: 'matCode', value: matCode}],
      searchText: [matCode],
      selectedContentType: ['report'],
      indexStart: 0,
      indexEnd: 1,
      sortMethod: {}
    }

    return this.http.post<any>(url, body, {headers}).pipe(
      map((searchResult) => {
        return searchResult.response
      }),
      map((response) => {
        return response.map((obj: any) => {
          return obj.reportCode
        }).filter((element: any) => {
          return element
        })
      })
    );
  }

  /**
   * Searchs for filters in the Search API given a text, returns a list of filters
   * @param text    text used to find filters
   */
  getTypeaheadFilters(text: string): Observable<IFilterResponse[]> {
    return this.http.post(`${environment.searchServiceUrl}/typeahead`, new SearchTermsRequestModel({
      searchText: [text],
      selectedContentType: []
    })).pipe(map((response: ISearchResponse) =>
      response.filterResponse.filter(filter => filter.values && filter.values.length > 0)))
  }

  /**
   * Gets list of reports (no paginated) for the given search criteria
   * @param searchText texts to search, array of words
   * @param selectedFilters filters to use, key is the filter, value, well, the value
   */
  getReportsForSearchCriteria(searchText: string[],
        selectedFilters: {key:string, value:string}[]): Observable<Array<ReportSearchResultModel>> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const body = {
      searchText,
      selectedFilters
    }

    return this.http.post(`${environment.searchServiceUrl}/search/report`, body, { headers }).pipe(
      map((response: any) => response.response.map((elem: ReportSearchResultModel) => this.sanitizeReportsResult(elem)))
    )
  }


  /**
   * Performs a CTI search and returns paginated results
   * @param terms a list of search terms
   * @param paginationParameters the pagination settings
   * @param filters an object containing selected filters
   * @param contentType the content type to search for (report, blog, etc)
   */
  cTISearch<T extends CTISearchBaseResult>(
    terms: string[],
    paginationParameters: PaginationParameters,
    filters: Filters,
    contentType: CTIContentType = CTIContentType.REPORT,
  ): Observable<CTISearchResults<T>> {
    const body: CTISearchRequest = {
      chips: terms,
      filter: FilterHelper.mapFiltersToCTIFilters(filters),
      ...PaginationHelper.withPaginationHeadersCTISearch(paginationParameters),
    };

    body.filter.contentType = contentType;

    if (!terms || terms.length === 0) {
      body.sort = { type: 'releaseDate', order: 'desc' };
    }

    return this.http.post(`${environment.metaServiceBaseUrl}/search`, body).pipe(
      map((result: CTISearchResponse) => ({
        results: result.documents.map((doc) => this.mapDocumentToSearchResult(doc, body.showHighlights)),
        total: result.total,
        filterSuggestions: result.filterSuggestions,
      }))
    );
  }

  getFeaturedReport(reportCode: string): Observable<CTISearchResult> {
    const body = {
      filter: {
        contentType: CTIContentType.REPORT,
      },
      chips: [reportCode]
    };

    return this.http.post(`${environment.metaServiceBaseUrl}/search`, body)
      .pipe(map((response: any): any => {
        return response.documents
          .find((report: CTISearchResult) => report.code === reportCode) || null;
      }));
  };

  // @TODO: must be removed once (CP-14350) is implemented
  getLatestReportFromSearch(channel: ReportContainerRequestModel): Observable<CTISearchResult> {
    const body = {
      contentType: CTIContentType.REPORT,
      filter: {
        channelId: [channel.channelId]
      },
      size: 1,
      sort: {
        type: 'releaseDate',
        order: 'desc'
      }
    };
    return this.http.post(`${environment.metaServiceBaseUrl}/search`, body)
      .pipe(map((response: any) => response.documents.length > 0 ? response.documents[0] : null));
  }

  getLatestReportsByChannelIds(channels: string[]): Observable<ProductInterface[]> {
    const reportRequests = channels.map(channelId =>
        (this.http.get(`${environment.metaServiceBaseUrl}/products`, {
          params: new HttpParams()
            .append('channels', channelId)
            .append('size', '1')
        }) as Observable<any>).pipe(map(
          (response): ProductInterface => response.products.length > 0 ? response.products[0] : null
        )));

    return forkJoin(reportRequests);
  }

  getLatestReportsByReportContainerRequests(containers: ReportContainerRequestModel[]): Observable<ProductInterface[]> {
    const channelIds = containers.map(c => c.channelId);

    return this.getLatestReportsByChannelIds(channelIds).pipe(map(
        reports => {
          reports.forEach((report, index) => {
            if(report) {
              report.moduleName = containers[index].moduleName;
              report.moduleUrl = containers[index].moduleUrl;
            }
          })

          return reports;
        }));
  }

  /**
   * Get a list of blogs applying the search and filters
   * @param search search Term user entered
   * @param filters filters provided by users or default
   * @param pagination pagination pages
   * @returns ContentNavigator table page
   */
  getBlogs(
    search: string,
    filters: Filters,
    pagination: PaginationParameters,
    subscriptionIds?: string[],
  ): Observable<BlogExcerptPaginatedList> {
    pagination.sort ??= 'releaseDate,DESC';

    return this.cTISearch(
      search.split(','),
      pagination,
      FilterHelper.buildBlogSearchFilter(filters, subscriptionIds),
      CTIContentType.BLOG,
    ).pipe(
      concatMap((ctiResults: CTISearchResults<CTIBlogSearchResult>) => {
        return this.getAnalysisFiltersForCTISearch(ctiResults.filterSuggestions)
          .pipe(map(filterSuggestions => ({
              blogs: ctiResults.results
                .map((ctiResult: CTIBlogSearchResult) => ({
                  id: (new URL(ctiResult.link)).pathname.split('/')[2],
                  category: ctiResult.subscriptionName,
                  title: ctiResult.name,
                  description: ctiResult.description,
                  publicationDate: ctiResult.releaseDate ? new Date(ctiResult.releaseDate) : null,
                  authorName: ctiResult.author?.name ?? '',
                  authorAvatarUrl: ctiResult.author?.avatarList['48'] ?? '',
                  entitled: ctiResult.entitled,
                })),
              count: ctiResults.total,
              filters: filterSuggestions
            })
          ))
      }));
  }

  /**
   * Get a list of blogs
   * @param sfIds List of SF IDs for blogs
   * @param paginationParameters Page number and size, and sorting parameters
   */
  getBlogsExcerpt(sfIds: string[], paginationParameters: PaginationParameters): Observable<BlogExcerptPaginatedList> {
    return this.getBlogs('',{},paginationParameters,sfIds)
  }

  /**
   * Get content naviagtor table for analysis assets
   * @param search search Term user entered
   * @param filters filters provided by users or default
   * @param pagination pagination pages
   * @returns ContentNavigator table page
   */
  getTable(
    search: string,
    filters: Filters,
    pagination: PaginationParameters
  ): Observable<ContentNavigatorTablePage> {
    if (!pagination.sort) {
      pagination.sort = 'releaseDate,DESC';
    }
    search ??= '';
    return this.cTISearch(search.split(','), pagination, filters, CTIContentType.REPORT).pipe(
      concatMap((ctiResults: CTISearchResults<CTISearchResult>) => {
        return this.getAnalysisFiltersForCTISearch(ctiResults.filterSuggestions)
          .pipe(map(filterSuggestions => (
            {
              table: {
                rows: ctiResults.results.map((ctiResult) => ({
                  id: ctiResult.id,
                  title: ctiResult.name ?? '',
                  analysisType: ctiResult.reportType ?? '',
                  productCode: ctiResult.code ?? '',
                  description: ctiResult.description ?? '',
                  deviceName: ctiResult.deviceName ?? '',
                  manufacturer: ctiResult.deviceManufacturer ?? '',
                  deviceType: ctiResult.deviceType ?? '',
                  subscription: this.buildIcon(ctiResult.subscriptionName),
                  channel: ctiResult.channelName,
                  completionDate: ctiResult.analysisEndDate ? new Date(ctiResult.analysisEndDate) : null,
                  documentsCount: ctiResult.documentsCount,
                  imagesCount: ctiResult.imagesCount,
                  entitled: ctiResult.entitled,
                  subscriptionName: ctiResult.subscriptionName,
                  reportModule: ctiResult.reportModule ?? '',
                  contentCategory: ctiResult.contentCategory ?? '',
                  baseAssetGroup: ctiResult.baseAssetGroup ?? '',
                })),
              },
              count: ctiResults.total,
              filters: filterSuggestions,
            })
          )
        );
      })
    );
  }

  /**
   * Get public content list for all contents
   * @param search search Term user entered
   * @returns Content list
   */
  getPublicContent(search: string): Observable<ContentPaginatedList> {
    const chips = search.split(',');
    return this.http.post<PublicCTISearchResponse>(`${environment.publicServiceBaseUrl}/v1/search`, { chips })
      .pipe(
        map(result => ({
          results: result.documents.map((doc) => this.mapDocumentToSearchResult(doc, false)),
          total: result.total,
        })),
        map((ctiResults: CTISearchResults<CTISearchResult | CTIBlogSearchResult | CTIVideoSearchResult>) => {
          return {
            contentList: ctiResults.results.map((ctiResult) => {
              switch (ctiResult.contentType) {
                case CTIContentType.REPORT:
                  return new ReportContent(ctiResult);
                case CTIContentType.BLOG:
                  return new BlogContent(ctiResult);
                case CTIContentType.VIDEO:
                  return new VideoContent(ctiResult);
                }
            }),
            count: ctiResults.total,
          }
        })
      );
  }

  /**
   * Get content list for all contents
   * @param search search Term user entered
   * @param filters filters provided by users or default
   * @param pagination pagination pages
   * @returns ContentPaginated list
   */
  getContent(
    search: string,
    filters: Filters,
    pagination: PaginationParameters
  ): Observable<ContentPaginatedList> {
    if (!pagination.sort) {
      pagination.sort = 'releaseDate,DESC';
    }
    search ??= '';
    // we need to change the parameter CTIContentType.REPORT and
    // mapping the row properties
    return this.cTISearch(search.split(','), pagination, filters).pipe(
      concatMap((ctiResults: CTISearchResults<CTISearchResult | CTIBlogSearchResult | CTIVideoSearchResult>) => {
        const defaultFilters = filters[FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.ContentType].field] ?? [];
        return this.getFiltersForUnifiedSearch(ctiResults.filterSuggestions, defaultFilters)
          .pipe(map(filterSuggestions => {
            return {
              contentList: ctiResults.results.map((ctiResult) => {
                switch (ctiResult.contentType) {
                  case CTIContentType.REPORT:
                    return new ReportContent(ctiResult);
                  case CTIContentType.BLOG:
                    return new BlogContent(ctiResult);
                  case CTIContentType.VIDEO:
                    return new VideoContent(ctiResult);
                  }
              }),
              count: ctiResults.total,
              filters: filterSuggestions,
            }
          })
        );
      })
    );
  }

  /**
   * Gets the analysis filters related to market analysis
   * by combining facets and navigation services
   * @returns a list of the filters
   *
   **/
  getAnalysisFiltersForCTISearch(filterSuggestions: CTIFilterResponse[]): Observable<AnalysisFilter[]> {
    return combineLatest([
      this.navService.getAllSubscriptions(),
      this.navService.getAllChannels(),
    ]).pipe(
      map(([subscriptions, allChannels]) => {
        return (filterSuggestions ?? [])
          .map((filter) => this.buildFilterResults(filter, allChannels, subscriptions))
          .filter((filter) => filter.options.length > 0)
          .sort((a, b) => a.id.localeCompare(b.id));
      }),
    );
  }

  /**
   * Gets the filters related to unified search
   * by combining facets and navigation services
   * @returns a list of the filters
   *
   **/
  getFiltersForUnifiedSearch(
    filterSuggestions: CTIFilterResponse[],
    defaultFilters: string | string[]
  ):Observable<AnalysisFilter[]> {
    const contentTypeFilter: CTIFilterResponse = {
      key: UnifiedFilterKey.ContentType,
      values: [
        {amount: 1, value: CTIContentType.REPORT},
        {amount: 1, value: CTIContentType.BLOG},
        {amount: 1, value: CTIContentType.VIDEO}]
    }
    filterSuggestions.push(contentTypeFilter);
    return combineLatest([
      this.navService.getAllSubscriptions(),
      this.navService.getAllChannels(),
    ]).pipe(
      map(([subscriptions, allChannels]) => {
        return (filterSuggestions ?? [])
          .map((filter) =>
              this.buildFilterUnifiedResults(filter, defaultFilters, allChannels, subscriptions))
          .filter((filter) => filter.options.length > 0)
          .sort((a, b) => Number(a.id) - Number(b.id));
      }),
    );
  }

  getAnalysisFiltersForSidebar(): Observable<AnalysisFilter[]> {
    return combineLatest([
      this.navService.getAllSubscriptions(),
      this.getFacets(),
    ]).pipe(
      map(([subscriptions, analysis]) => {
        return analysis
          .map((f) => FilterHelper.buildFilter(f, subscriptions))
          .sort((a, b) => {
            if (this.SIMPLE_FILTERS_FIELD_NAME.includes(a.field)) {
              return -1;
            } else if (this.SIMPLE_FILTERS_FIELD_NAME.includes(b.field)) {
              return 1;
            }
            return 0;
          });
      })
    );
  }

  getTabCounts(ids: string): Observable<FlavourCounts> {
    const params = new HttpParams().set('channels', ids);
    const countingUrl = `${environment.metaServiceBaseUrl}/products/counting`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get<any>(countingUrl, { headers, params }).pipe(
      map((response) => {
        const resp: FlavourCounts = {
          finalCount: 0,
          inProgress: 0,
          analystCount: 0,
          reportsCount: 0,
          marketDataCount: 0,
          sampleCount: 0
        };
        response.products.forEach((prod: any) => {
          resp.finalCount += prod.final_count;
          resp.inProgress += prod.in_progress_count;
          resp.analystCount += prod.analysis_count;
          resp.reportsCount += prod.analysis_count_new;
          resp.marketDataCount += prod.market_data_count;
          resp.sampleCount += prod.sample_count;
        });
        return resp;
      })
    )
  }

  getBlog(id: any, subscriptionName: string, isWpAsset?: boolean): Observable<Blog> {
    const endpointUrl = isWpAsset
      ? `${environment.metaServiceBaseUrl}/wp-asset/post/${id}`
      : `${environment.metaServiceBaseUrl}/post/${id}`
    return this.http.get<any>(endpointUrl).pipe(
      map((element) => ({
        id: element.id,
        category: this.resolveCategory(element.categories, subscriptionName),
        content: element.content,
        title: element.title,
        excerpt: element.excerpt,
        publicationDate: element.date,
        authorName: element.author.name,
        authorAvatarUrl: element.author.avatar_list['96'],
        link: element.link,
        tags: element.tags.filter((tag:string)=> !tag.startsWith('#')),
        description: '',
        entitled: true,
      }))
    );
  }

  /**
   * Get a List of videos from cTI search
   * @param search search Term user entered
   * @param filters filters provided by users or default
   * @param pagination pagination parameters
   * @param channelIds context channel ids
   * @param subscriptionId context subscription id - IMPORTANT: this should be removed on FF removal
   * @returns Observable<VideoPaginatedList> object
   */
  getVideos(
    search: string,
    filters: Filters,
    pagination: PaginationParameters,
    channelIds: string[],
  ): Observable<VideoPaginatedList> {
    pagination.sort ??= 'relevancy,DESC';

    const cTISearch$ = this.cTISearch(
      search.split(','),
      pagination,
      FilterHelper.buildVideoSearchFilter(filters, channelIds),
      CTIContentType.VIDEO,
    ).pipe(
      concatMap((ctiResults: CTISearchResults<CTIVideoSearchResult>): Observable<VideoPaginatedList> =>
        this.getAnalysisFiltersForCTISearch(ctiResults.filterSuggestions).pipe(
          map((filterSuggestions): VideoPaginatedList => ({
            count: ctiResults.total,
            filters: filterSuggestions,
            videos: ctiResults.results.map(video => this.mapCTIVideo(video)),
          })),
        )
      ),
    );

    return cTISearch$
  }

  /**
   * Get a List of videos
   * @returns Observable<VideoPaginatedList> object
   */
  getVideoExcerpts(
    subscription: Subscription,
    paginationParameters: PaginationParameters,
  ): Observable<VideoPaginatedList> {
    return this.getVideos(
      '',
      {},
      paginationParameters,
      subscription.children.filter(isChannel).map((channel) => channel.id),
    );
  }

  /**
   * Get Video
   * @returns Observable<Video> object
   */
  getVideo(id: any): Observable<Video> {
    return this.http.get<any>(`${environment.metaServiceBaseUrl}/video/${id}`)
      .pipe(map((element) => this.mapVideo(element)));
  }

  /**
   * Gets the raw facets from meta service
   * @returns a list of facets
   */
  getFacets(): Observable<any[]> {
    return this.http.get(`${environment.metaServiceBaseUrl}/facets`).pipe(
      map((filters: Array<any>) => {
        return filters.filter((f) => this.FILTERS_SHOW_IN_SIDEBAR.includes(f.fieldName));
      }),
      map((filters: Array<any>) => {
        return this.FILTERS_SHOW_IN_SIDEBAR.map(item => {
          return filters.find(filter => filter.fieldName === item);
        });
      })
    );
  }

  storeSearchTerms(context: string, searchTerm: string, searchStorage: string) {
    const storedSearchTerms = this.getContextSearchTerms(context, searchStorage);

    if(!this.isSearchTermValid(searchTerm, storedSearchTerms)) {
      return;
    }

    const searchTermStorage = [searchTerm, ...this.getContextSearchTerms(context, searchStorage)];

    if(searchTermStorage.length > 10) {
      searchTermStorage.pop();
    }

    const updatedSearchTerms = {
      [context]: searchTermStorage,
    };

    this.serializeSearchTerms(updatedSearchTerms, searchStorage);
  }


  getContextSearchTerms(context: string, storageKey: string): string[] {
    const searchTerms = this.getRecentSearchTerms(storageKey);
    return searchTerms[context] || [];
  }

  getChannelCounts(channelIds: string[]): Observable<ChannelCount[]> {
    const params = new HttpParams().set('channels', channelIds.join(','));
    const countingUrl = `${environment.metaServiceBaseUrl}/products/counting`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get<any>(countingUrl, { headers, params }).pipe(
      map((response) => {
        return response.products
      })
    )
  }

  private getRecentSearchTerms(key: string) {
    return JSON.parse(localStorage.getItem(this.buildStorageKey(key))) || {};
  }

  private buildStorageKey(key: string) : string {
    return `recentSearchTerms-${key}`;
  }

  private serializeSearchTerms(searchTerms: { [p: string]: string[] }, searchStorage: string) {
    const storageKey = this.buildStorageKey(searchStorage);
    localStorage.setItem(storageKey, JSON.stringify(searchTerms));
  }

  private isSearchTermValid(searchTerm: string, searchTerms: string[]): boolean {
    const isSearchTermEmpty = searchTerm === '';
    const isSearchTermDuplicated = searchTerms.find(term => term.toLowerCase() === searchTerm.toLowerCase());
    return !isSearchTermEmpty && !isSearchTermDuplicated;
  }

  private mapDocumentToSearchResult<T extends CTISearchBaseResult>(
    body: any,
    showHighlights: boolean,
  ): T {
    const { highlight, ...document } = body;
    const result: T = {
      ...document,
      flavor: document.flavors?.split(',') ?? [],
      channelId: null,
      subscriptionId: null,
    }

    let channelIds = [];
    let subscriptionIds = [];

    if(typeof document.sfIdChannel === 'string' ) {
      channelIds = document.sfIdChannel?.split(',') ?? [];
    } else {
      channelIds = document.sfIdChannel;
    }

    if(typeof document.sfIdChannel === 'string' ) {
      subscriptionIds = document.sfIdSubscription?.split(',') ?? [];
    } else {
     subscriptionIds = document.sfIdSubscription;
    }

    result.channelId = channelIds[0];
    result.subscriptionId = subscriptionIds[0];

    forkJoin([
      forkJoin(
        channelIds.map((channelId : string) => this.navService.getChannelById(channelId.trim()).pipe(catchError(() => of({name: ''}))))
      ).pipe(defaultIfEmpty([])),
      forkJoin(
        subscriptionIds.map((subscriptionId : string) => this.navService.getSubscriptionById(subscriptionId.trim()).pipe(catchError(() => of({name: ''}))))
      ).pipe(defaultIfEmpty([]))
    ])
    .subscribe(([channels, subscriptions]: [Channel[], DefaultSubscription[]]) => {
      result.channelName = channels.map((channel: Channel) => channel.name).filter((name) => name !== '').join(', ')
      result.subscriptionName = subscriptions.map((sub) => sub.name).filter(name => name !== '').join(', ');
    });

    if (showHighlights) {
      result.name = highlight?.name ? this.convertToHtmlTags(highlight.name) : result.name;
      result.description = highlight?.description ? this.convertToHtmlTags(highlight.description) : result.description;
    }

    return result;
  }

  private convertToHtmlTags(text: string[]) {
    return text.join('... ').replace(/\[\[highlight\]\](.*?)\[\[\/highlight\]\]/g, '<em class="search-highlight">$1</em>');
  }

  /**
   * Maps the initial response for 'All' tab into the app's interfaces
   * @param response    Initial search response
   *
   */
  private mapInitialResponse(response: ISearchResponse[]): [ISearchResults, IFilterType[]] {
    let results: IResultsAmount = {
      all: 0, components: 0, dies: 0, images: 0, pcb: 0, products: 0, reports: 0, schematics: 0
    }
    let initialSearchResponse: ISearchResults = {
      component: [],
      die: [],
      image: [],
      newResultsAmount: Object.assign({}, results),
      paginated: false,
      pcb: [],
      product: [],
      report: [],
      resultsAmount: results,
      schematic: []

    };
    const filtersToReturn: IFilterType[] = [];
    const unmappedFilters: IFilterResponse[] = [];
    const filtersResponse = response.splice(response.length -1, 1)[0];
    // For each content type, the response is mapped and the results amounts are determined after the mapping
    response.forEach(searchResponse => {
      if (searchResponse.response.length > 0){
        initialSearchResponse =  this.mapResponseValues(initialSearchResponse, searchResponse);
        results = {
          ...results,
          [getContentTypeLabel(searchResponse.response[0].contentType)]:
            searchResponse.resultsAmount[getContentTypeLabel(searchResponse.response[0].contentType)]
        }
      }
    });
    initialSearchResponse.resultsAmount = results;
    filtersToReturn.push(...this.mapFilterResponseToFilterTypes(filtersResponse.filterResponse));

    // Reducing the results amount for each content type into a total of items
    initialSearchResponse.resultsAmount.all = Object.keys(initialSearchResponse.resultsAmount)
      .map(key => initialSearchResponse.resultsAmount[key])
      .reduce(((a, b) => a + b));

    return [initialSearchResponse, filtersToReturn];
  }

  /**
   * Maps the response results amount values from the Search API into the app's interface
   * @param searchResponse    search response currently mutated
   * @param singleResponse    API response
   *
   * @returns                 the search response already mutated
   */
  private mapResponseValues(searchResponse: ISearchResults,
                            singleResponse: ISearchResponse): ISearchResults {
    singleResponse.response.forEach((v: IReturnedSearchItem) => {
      v = Object.assign({}, v);
      Object.keys(v).forEach(key => {
        v[key] = v[key] != null && v[key] !== 'null' ? v[key] : '';
      })
      searchResponse[v.contentType].push(v);
    });
    searchResponse.resultsAmount = singleResponse.resultsAmount;
    // Setting zeroes on null values
    searchResponse.resultsAmount = this.normalizeResultsAmounts(searchResponse.resultsAmount);
    return searchResponse;
  }

  /**
   * Normalizes the results amount for each content type, starting with zeroes instead of nulls, and accumulating 'All'
   * @param resultsAmount Results amount object to normalize
   *
   */
  private normalizeResultsAmounts(resultsAmount: IResultsAmount): IResultsAmount {
    if (!resultsAmount) {
      return {
        all: 0, components: 0, dies: 0, images: 0, pcb: 0, products: 0, reports: 0, schematics: 0
      }
    }
    Object.keys(resultsAmount).forEach(key => {
      resultsAmount[key] = resultsAmount[key] ? resultsAmount[key] : 0;
    })
    resultsAmount.all = Object.values(resultsAmount).reduce((a, b) => Number(a) + Number(b))
    return resultsAmount;
  }

  /**
   * Removes null values from a ReportSearchResult object
   * @param element a report search result
   * @returns result object without null values
   */
  private sanitizeReportsResult(element: ReportSearchResultModel) {
    const result = Object.assign({}, element);
    Object.keys(element).forEach(key => {
      result[key] = result[key] === null || result[key] === 'null' ? '' : result[key];
    });
    return result;
  }

  /**
   * Resolve Blog category
   * BlogList/BlogPost
   */
  private resolveCategory(categories: { sfId: string, name: string }[], subscription: any) {
    if (Array.isArray(subscription)) {
      return categories.filter((cat) => subscription.includes(cat.sfId))[0]?.name ?? '';
    } else {
      const subscriptionName = categories.filter((cat: any) => cat.subscriptionName === subscription)[0];
      return subscriptionName ? subscriptionName.name : '';
    }
  }

  private buildIcon(subscriptionName: string): string {
    const extraSpacesRegex: RegExp = RegularExpressions.extraSpaces;
    const names = subscriptionName?.replace(extraSpacesRegex, ' ').trim().split(',');
    return names?.map(name => iconMap[name])[0] || defaultIcon;
  }

  private buildFilterResults(
    filter: CTIFilterResponse,
    channels?: NavigationElement[],
    subscriptions?: NavigationElement[],
  ): AnalysisFilter {
    switch (filter.key) {
      case AnalysisFilterKey.Subscription:
        return {
          label: FilterHelper.ANALYSIS_FILTERS[AnalysisFilterKey.Subscription].label,
          expanded: false,
          field: FilterHelper.ANALYSIS_FILTERS[AnalysisFilterKey.Subscription].field,
          id: FilterHelper.ANALYSIS_FILTERS[AnalysisFilterKey.Subscription].id,
          type: AnalysisFilterType.List,
          options: filter.values.filter((value) => value.amount > 0)
            .map(value => ({
              label: subscriptions.find(sub => sub.id === value.value)?.name,
              selected: false,
              id: value.value,
              value: value.value
            }))
            .filter(option => option.label !== undefined)
            .sort((a, b) => FilterHelper.sortFilters(a, b,
              FilterHelper.ANALYSIS_FILTERS[AnalysisFilterKey.Subscription].field))
        }
      case AnalysisFilterKey.Channel:
        const channelFacets: Facet[] = filter.values.filter((value) => value.amount > 0)
        .map((value) => ({
          id: value.value,
          displayName: channels.find(ch => ch.id === value.value)?.name,
          value: value.value
        }));

        const groups = FilterHelper.groupChannelFiltersBySubscription(
          subscriptions, channelFacets, 'channelId');

        return {
          label: FilterHelper.ANALYSIS_FILTERS[AnalysisFilterKey.Channel].label,
          expanded: false,
          field: FilterHelper.ANALYSIS_FILTERS[AnalysisFilterKey.Channel].field,
          id: FilterHelper.ANALYSIS_FILTERS[AnalysisFilterKey.Channel].id,
          type: AnalysisFilterType.Group,
          groups,
          options: []
            .concat(...groups.map(group => group.options))
            .sort((a, b) => FilterHelper.sortFilters(a, b,
              FilterHelper.ANALYSIS_FILTERS[AnalysisFilterKey.Channel].field))
        }
      case AnalysisFilterKey.InMySubscription:
        return {
          label: FilterHelper.ANALYSIS_FILTERS[AnalysisFilterKey.InMySubscription].label,
          expanded: false,
          field: FilterHelper.ANALYSIS_FILTERS[AnalysisFilterKey.InMySubscription].field,
          id: FilterHelper.ANALYSIS_FILTERS[AnalysisFilterKey.InMySubscription].id,
          type: AnalysisFilterType.List,
          options: filter.values
          .filter(values => values.value === 'true')
          .map(value => ({
            label: 'My Subscription',
            selected: false,
            id: value.value,
            value: value.value
          }))
        }
      default:
        return {
          label: FilterHelper.ANALYSIS_FILTERS[filter.key].label,
          expanded: false,
          field: FilterHelper.ANALYSIS_FILTERS[filter.key].field,
          id: FilterHelper.ANALYSIS_FILTERS[filter.key].id,
          type: AnalysisFilterType.List,
          options: filter.values.filter((value) => value.amount > 0)
            .map(value => ({
              label: value.value.charAt(0).toUpperCase() + value.value.slice(1),
              selected: false,
              id: value.value,
              value: value.value
            }))
            .sort((a, b) => FilterHelper.sortFilters(a, b,
              FilterHelper.ANALYSIS_FILTERS[filter.key].field))
        }
    }
  }

  private buildFilterUnifiedResults(
    filter: CTIFilterResponse,
    defaultFilters: string | string[],
    channels?: NavigationElement[],
    subscriptions?: NavigationElement[],
    enableGrouping?: boolean,
  ): AnalysisFilter {
    switch (filter.key) {
      case UnifiedFilterKey.Subscription:
        return {
          label: FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.Subscription].label,
          expanded: false,
          field: FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.Subscription].field,
          id: FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.Subscription].id,
          type: AnalysisFilterType.List,
          options: filter.values.filter((value) => value.amount > 0)
            .map(value => ({
              label: subscriptions.find(sub => sub.id === value.value)?.name,
              selected: false,
              id: value.value,
              value: value.value
            }))
            .filter(option => option.label !== undefined)
            .sort((a, b) => FilterHelper.sortFilters(a, b,
              FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.Subscription].field))
        }
      case UnifiedFilterKey.Channel:
        const channelFacets: Facet[] = filter.values.filter((value) => value.amount > 0)
        .map((value) => ({
          id: value.value,
          displayName: channels.find(ch => ch.id === value.value)?.name,
          value: value.value
        }));

        const groups = FilterHelper.groupChannelFiltersBySubscription(
          subscriptions, channelFacets, 'channelId');

        return {
          label: FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.Channel].label,
          expanded: false,
          field: FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.Channel].field,
          id: FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.Channel].id,
          type: enableGrouping ? AnalysisFilterType.Group : AnalysisFilterType.List,
          groups,
          options: []
            .concat(...groups.map(group => group.options))
            .sort((a, b) => FilterHelper.sortFilters(a, b,
              FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.Channel].field))
        }
      case UnifiedFilterKey.InMySubscription:
        return {
          label: FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.InMySubscription].label,
          expanded: false,
          field: FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.InMySubscription].field,
          id: FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.InMySubscription].id,
          type: AnalysisFilterType.List,
          options: filter.values
          .filter(values => values.value === 'true')
          .map(value => ({
            label: 'My Subscription',
            selected: false,
            id: value.value,
            value: value.value
          }))
        }
      case UnifiedFilterKey.ContentType:
        return {
          label: FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.ContentType].label,
          expanded: false,
          field: FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.ContentType].field,
          id: FilterHelper.UNIFIED_FILTERS[UnifiedFilterKey.ContentType].id,
          type: AnalysisFilterType.List,
          options: filter.values.filter((value) => value.amount > 0)
            .map(value => ({
              label: FilterHelper.CONTENT_TYPE_FILTER_MAP[value.value] ?? value.value,
              selected: false,
              id: value.value,
              value: value.value
            }))
        }
      default:
        return {
          label: FilterHelper.UNIFIED_FILTERS[filter.key].label,
          expanded: false,
          field: FilterHelper.UNIFIED_FILTERS[filter.key].field,
          id: FilterHelper.UNIFIED_FILTERS[filter.key].id,
          type: AnalysisFilterType.List,
          options: filter.values.filter((value) => value.amount > 0)
            .map(value => ({
              label: value.value.charAt(0).toUpperCase() + value.value.slice(1),
              selected: false,
              id: value.value,
              value: value.value
            }))
            .sort((a, b) => FilterHelper.sortFilters(a, b,
              FilterHelper.UNIFIED_FILTERS[filter.key].field))
        }
    }
  }

  private mapVideo(videoResponse: any): Video {
    return {
      id: videoResponse.id,
      title: videoResponse.title,
      hashedId: videoResponse.hashedId,
      wistiaUrl: `//fast.wistia.net/embed/iframe/${videoResponse.hashedId}`,
      channelName: videoResponse.channelName,
      thumbnailUrl: videoResponse.thumbnailUrl.replace(
        new RegExp(this.WISTIA_EMBED_URL_INSECURE, 'i'),
        this.WISTIA_EMBED_URL_SECURE,
      ),
      entitled: videoResponse.entitled,
      createDate: videoResponse.createDate,
      updateDate: videoResponse.updateDate,
      duration: videoResponse.duration,
      published: videoResponse.published ?? '',
    };
  }

  private mapCTIVideo(videoResponse: CTIVideoSearchResult): Video {
    return {
      id: videoResponse.id,
      title: videoResponse.name,
      hashedId: videoResponse.wistiaId,
      description: videoResponse.description ?? '',
      channelId: videoResponse.channelId,
      channelName: videoResponse.channelName,
      subscriptionId: videoResponse.subscriptionId,
      subscriptionName: videoResponse.subscriptionName,
      thumbnailUrl: videoResponse.thumbnailUrl.replace(
        new RegExp(this.WISTIA_EMBED_URL_INSECURE, 'i'),
        this.WISTIA_EMBED_URL_SECURE,
      ),
      entitled: videoResponse.entitled,
      createDate: videoResponse.createDate,
      updateDate: videoResponse.updateDate,
      duration: videoResponse.duration,
      published: videoResponse.wistiaCreateDate,
      wistiaUrl: videoResponse.wistiaUrl,
      wistiaCreateDate: videoResponse.wistiaCreateDate,
      wistiaUpdateDate: videoResponse.wistiaUpdateDate,
      viewCount: videoResponse.viewCount,
      free: videoResponse.free,
    };
  }
}
