<ul class="scrollbar">
  <li class="filter" *ngFor="let filter of filters" [attr._ti_filter]="filter.label">
    <div class="filter-header" (click)="onClickFilterGroup(filter)">
      <span class="filter-label">
        {{ filter.label }}
      </span>
      <span *ngIf="getSelectedCount(filter) > 0" class="filter-count">{{ getSelectedCount(filter) }}</span>
      <em class="filter-icon icon-down_small" [ngClass]="{ 'filter-icon-up': filter.expanded }"></em>
    </div>
    <ng-container [ngSwitch]="filter.type">
      <app-list-filter
        *ngSwitchCase="'list'"
        [facets]="filter.options"
        (changeFilter)="onChangeFilters($event)"
        class="overflow-hidden"
        [@expandAnimation]="filter.expanded ? 'open' : 'closed'"
      ></app-list-filter>

      <app-group-filter
        *ngSwitchCase="'group'"
        [filterGroups]="filter.groups"
        (changeFilter)="onChangeFilters($event)"
        class="overflow-hidden"
        [@expandAnimation]="filter.expanded ? 'open' : 'closed'"
      ></app-group-filter>

      <app-simple-filter
        *ngSwitchCase="'simple'"
        [facets]="filter.options"
        (changeFilter)="onChangeFilters($event)"
        class="overflow-hidden"
        [@expandAnimation]="filter.expanded ? 'open' : 'closed'"
      ></app-simple-filter>
    </ng-container>
  </li>
  <li class="no-filter" *ngIf="!filters.length">
    <div class="filter-header">
      <span class="filter-label">
        No filters available
      </span>
    </div>
  </li>
</ul>
