import {Inject, Injectable} from '@angular/core';
import {WindowToken} from '@shared/utils/window';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, map, startWith, tap} from 'rxjs/operators';

/**
 * Service to inject window object when needed in a component/service.
 * Made to improve testability and restrict window use to what's on this service
 */
@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor(@Inject(WindowToken) public window: Window) { }

  /**
   * Assigns an URL location and navigates towards that
   * @param url   URL to assign
   */
  assignLocation(url: string) {
    this.window.location.assign(url)
  }

  /**
   * Reloads the page
   */
  reload() {
    this.window.location.reload();
  }

  /**
   * Gets the inner width of the page
   */
  getInnerWidth(): number {
    return this.window.innerWidth
  }

  /**
   * Gets the resize observer for the window, in case there's a need to detect when the window is beign resized
   * Returns the observed width
   */
  getWidthResizeObserver(): Observable<number>{
    return fromEvent(window, 'resize')
      .pipe(
        startWith(window.innerWidth),
        debounceTime(50),
        map(() => window.innerWidth)
      )
  }
}
