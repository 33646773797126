<div class="header">
    <h1 class="title">{{header.title }}</h1>
    <h2 class="sub-title">{{header.subTitle}}</h2>
    <div class="info">
        <span>Analysis</span>
        <em *ngIf="date" class="vertical-line">|</em>
        <span>{{date | date: 'MMM dd, YYYY'}}</span>
        <em *ngIf="header.customerSegment" class="vertical-line">|</em>
        <span>{{header.customerSegment?.join(', ')}}</span>
    </div>
</div>
