<a
  *ngFor="let tab of tabs"
  class="tab"
  [class.active]="tab === activePosition"
  [class.disabled]="tab.disabled"
  (click)="onClickTab(tab)"
><em
  *ngIf="tab.icon"
  [class]="tab.icon"
></em>{{ tab.name + (tab.count !== undefined ? ' (' + tab.count + ')' : '') }}</a>
