import {Component, ViewChild, ElementRef, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {regexConst} from '@shared/consts/regex.const';
import {environment} from '@env/environment';
import {concatMap} from 'rxjs/operators';
import {VendorIntegrationService} from '@shared/services/vendor-integration.service';
import {TelemetryEventName} from '@shared/enums';
import {TelemetryService} from '@shared/services/telemetry-service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-prelogin-access-modal',
  templateUrl: './prelogin-access-modal.component.html',
  styleUrl: './prelogin-access-modal.component.scss'
})
export class PreloginAccessModalComponent implements OnInit {
  public emailCtrl = new FormControl('', [Validators.required, Validators.pattern(regexConst.EMAIL)]);
  public authServiceUrl: string;

  @ViewChild('formRef') formRef: ElementRef<any>;

  constructor(
    private vendorIntegrationService: VendorIntegrationService,
    private telemetryService: TelemetryService
  ) {
    this.authServiceUrl = environment.authServiceBaseUrl;
  }

  ngOnInit() {
      this.telemetryService.sendHeapEvent(TelemetryEventName.EVENT_HEAP_METERED_ACCESS_MODAL_OPENED)
        .subscribe();
  }

  onReadFullVersion() {
    this.emailCtrl.markAsTouched();
    if (this.emailCtrl.valid) {
      this.vendorIntegrationService.submitPardotForm(environment.pardotForms.fullAccessForm, {
        username: this.emailCtrl.value,
      })
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this.submitForm();
          },
          error: () => {
            this.submitForm();
          }
        });
    }
  }

  private submitForm() {
    this.telemetryService.sendHeapEvent(TelemetryEventName.EVENT_HEAP_METERED_ACCESS_EMAIL_SUBMIT, {
      email: this.emailCtrl.value,
    }).subscribe(() => {
      setTimeout(() => {
        this.formRef.nativeElement.submit();
      }, 2000);
    });
  }
}
