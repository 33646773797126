import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TokenComponent } from 'src/app/auth/token/token.component';
import {LogoutComponent} from '@app/auth/logout/logout.component';

const routes: Routes = [
  {
    path: 'token/:token', component: TokenComponent, data: {}
  },
  { path: 'logout', component: LogoutComponent, data: {} }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
