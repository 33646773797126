<div class="modal-close-button" (click)="onClose(true)">
  <em class="icon-close_medium"></em>
</div>
<div class="content">
  <div class="title">
    <span>{{props.title}}</span>
  </div>
    <div class="body" *ngIf="props.body">{{ props.body }}</div>


  <div class="modal-footer">
    <button class="btn btn-modal-secondary" (click)="onClose(true)">
      {{props.closeButtonLabel}}
    </button>
    <button class="btn btn-modal-primary" (click)="onClose(false)">
      {{props.actionButtonLabel}}
    </button>
  </div>
  <div class="checkbox">
    <app-checkbox
      [props]="checkboxProps"
      [(ngModel)]="checkboxState"
      (ngModelChange)="onClickCheckbox($event)"
    ></app-checkbox>
  </div>
</div>
