import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '@shared/services/dialog/dialog.service';
import { TableauService } from 'src/app/@shared/services/tableau.service';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import { environment } from '@env/environment';
import { TableauConfig } from '@app/@shared/models/shared.models';
import {Subscription, Roadmap, NavigationObjectType} from '@app/@shared/models/navigation/navigation.models';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';

@Component({
  selector: 'app-roadmap-tab-container',
  templateUrl: './roadmap-tab-container.component.html',
  styleUrls: [ './roadmap-tab-container.component.scss' ],
})
@UntilDestroy()
export class RoadmapTabContainerComponent implements OnInit {

  public isRoadmapLocked: boolean;
  public tableauRoadmapConfig: TableauConfig;
  public isRetool = false;
  public retoolAppId: string;
  public roadmapName = '';

  constructor(
    private dialogService: DialogService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private tableauService: TableauService,
    private ffService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    const moduleName = this.route.snapshot.data.module;
    combineLatest([
      this.navigationService.isRequestAccessForRoadmapRequired(moduleName),
      this.route.parent.params,
      this.route.params
    ]).pipe(
      concatMap(([ isLocked, { subscriptionId }, { roadmapName }]) => {
        this.isRoadmapLocked = isLocked;
        this.roadmapName = roadmapName ?? '';
        return this.navigationService.getSubscription(subscriptionId, moduleName);
      }),
      untilDestroyed(this),
    ).subscribe((subscription: Subscription) => {
      const retoolRoadmaps = subscription.roadmaps.filter(
        (roadmap: Roadmap) => roadmap.dashboardType === NavigationObjectType.RETOOL
      );
      const tableauRoadmaps = subscription.roadmaps.filter(
        (roadmap: Roadmap) => roadmap.dashboardType === NavigationObjectType.TABLEAU
      );
      if (retoolRoadmaps.length > 0) {
        this.isRetool = true;
        this.loadRetoolRoadmap(retoolRoadmaps);
      } else if (tableauRoadmaps.length > 0) {
        this.loadTableauRoadmap(tableauRoadmaps);
      }
    });
  }

  private loadRetoolRoadmap(roadmaps: Array<Roadmap>): void {
    this.ffService.getFlag(environment.enableUpcomingAnalysisTabForAllUsers)
      .pipe(untilDestroyed(this))
      .subscribe(isRetoolRoadmapEnabledForAllUsers => {
        if (isRetoolRoadmapEnabledForAllUsers) {
          this.isRoadmapLocked = false;
        }
      })
    const roadmap = roadmaps.find(
      (rmap: Roadmap) => rmap.name.toLocaleLowerCase() === this.roadmapName.toLocaleLowerCase()
    );
    if (roadmap) {
      this.retoolAppId = roadmap.dashboardEmbedString;
    } else {
      this.router.navigate([ '../../' ], { relativeTo: this.route });
    }
  }

  private loadTableauRoadmap(roadmaps: Array<Roadmap>): void {
    this.tableauService.getTableauTicket().pipe(untilDestroyed(this)).subscribe(
      ({ ticket }) => {
        const roadmap = roadmaps.find(
          (rmap: Roadmap) => rmap.name.toLocaleLowerCase() === this.roadmapName.toLocaleLowerCase()
        );
        if (roadmap) {
          this.tableauRoadmapConfig = {
            url: this.getTableauUrl(roadmap.dashboardEmbedString, ticket),
            height: environment.roadmapVizHeight,
            width: environment.roadmapVizWidth,
          };
        }
      },
      () => this.dialogService.openError(),
    );
  }

  private getTableauUrl(roadMapLink: string, ticket: string): string {
    const path = roadMapLink.split('views/').pop();
    const { roadmapUrl } = environment;
    return roadmapUrl
      .replace('<ticket>', ticket)
      .replace('<roadmapPath>', path);
  }

}
