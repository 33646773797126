<div class="header">
  <h4 *ngIf="!fullTitle" class="header4-text">Newest {{ subscriptionName }} Analysis and Teardown Reports</h4>
  <h4 *ngIf="fullTitle" class="header4-text">{{ fullTitle }}</h4>
  <a class="body-text" (click)="onClickViewAll()">View All</a>
</div>

<app-content-navigator-multi-tab
  [tabs]="tabs"
  [activeTab]="activeTab"
  [isContentLoading]="isContentLoading"
  (clickTab)="onClickTab($event)"
  class="scrollbar horizontal bottom-spacing"
>
  <app-re-content-navigator-table
    *ngIf="table"
    [table]="table"
    [annotatedReports]="annotatedReports"
    (clickRow)="onClickRow($event)"
    (clickImageCounter)="onClickImageCounter($event)"
    (clickFileCounter)="onClickFileCounter($event)"
  ></app-re-content-navigator-table>
</app-content-navigator-multi-tab>
