import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { ITextAreaProps } from '@app/@shared/models/shared.models';


@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true
    }
  ]
})
export class TextAreaComponent implements OnInit, ControlValueAccessor {
  get props(): ITextAreaProps {
    return this._props;
  }
  @Input()
  set props(value: ITextAreaProps) {
    this._props = value;
  }
  @Input() public disabled? = false;
  @Input() public required? = false;

  public textValue = '';
  public focused = false;
  _control: NgControl;

  private _props: ITextAreaProps;

  constructor(private injector: Injector) { }

  onTouched = () => {
    this.focused = true;
  };

  onChange = (_value: any) => {
  };

  ngOnInit(): void {
    this._control = this.injector.get(NgControl);
  }

  onFocus(){
    this.focused = true;
  }

  onBlur(){
    this.focused = false;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setErrors(errors: ValidationErrors): void {
    this._control.control.setErrors(errors);
  }

  writeValue(value: string): void {
    this.onChange(value);
    this.textValue = value;
  }

  handleChange(value: string) {
    this.writeValue(value);
  }

}
