<div *ngIf="loading" class="downloading-spinner">
    <app-progress-bar-circular></app-progress-bar-circular>
    <div class="message">Downloading the file..</div>
</div>

<div *ngIf="!loading && downloadComplete" class="message">
     File has been downloaded.
</div>

<div *ngIf="!loading && !downloadComplete" class="message">
    Could not download the file.
</div>
