import { Component, Input, OnInit } from '@angular/core';
import { AnalysisFilterFacet } from '@app/@shared/models/reverse-engineering/analysis.models';
import { BaseFilterComponent } from '../base-filter/base-filter.component';

@Component({
  selector: 'app-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
})
export class ListFilterComponent extends BaseFilterComponent implements OnInit {
  @Input() searchBox = true;
  filterText = '';
  filteredFacets: AnalysisFilterFacet[];

  ngOnInit(): void {
    this.showAllFacets();
  }

  /**
   * Filters the visible facets within a filter by user input text
   */
  filterFacets() {
    if (this.filterText) {
      this.filteredFacets = this.facets.filter((facet) => this.facetFilteringFn(this.filterText, facet));
    } else {
      this.showAllFacets();
    }
  }

  /**
   * Deletes the text input by the user in the filter box and clears facet filtering
   */
  clearFacetFilter() {
    this.filterText = '';
    this.showAllFacets();
  }

  /**
   * Filtering function for facets
   * @param q user input text
   * @param facet current facet
   * @returns true if facet label contains user input text. False otherwise
   */
  private facetFilteringFn(q: string, facet: AnalysisFilterFacet) {
    return facet.label.toLowerCase().indexOf(q.toLowerCase()) > -1;
  }

  /**
   * Fills the the filteredFacets collection with the initial facets
   */
  private showAllFacets() {
    this.filteredFacets = [...this.facets];
  }
}
