import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SelectOption } from '../../models/shared.models';


/**
 * A select like input with customized styling
 * matching the rest of the UI
 * That will look the same independent of the browser
 *
 * @Input options your options with a value and a label
 * @Input defaultOption the first selected option
 * @Output emits the value of the selected option
 * The name of the input is "search-select" so browser plugins like
 * 1Pass don't try to autocomplete the input
 */
@Component({
  selector: 'it-select',
  templateUrl: './it-select.component.html',
  styleUrls: ['./it-select.component.scss'],
})
export class ItSelectComponent {
  @Input() error: string;
  @Input() label: string;
  @Input() editable = false;
  @Input() placeholder: string;
  @Input() set options(options: Array<SelectOption>) {
    this.filteredOptions = options;
    this.allOptions = [...options];
  }
  @Input() set initialOption(option: SelectOption) {
    this._selectedOption = option;
    this.inputText = option?.label;
    if (option === null){
      this.onNgModelChange('')
    }
  }
  @Input() upward = false;
  @Input() disabled = false;
  @Output() changeOption = new EventEmitter<SelectOption>();
  @Output() blurInput = new EventEmitter<void>();

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChildren('dropdownElement') dropdownElements: QueryList<ElementRef>;
  dropdownToggle = false;
  set selectedOption(option: SelectOption) {
    this._selectedOption = option;
    this.changeOption.emit(option);
    this.inputText = option?.label;
  }
  get selectedOption(): SelectOption {
    return this._selectedOption;
  }
  inputText: string;
  filteredOptions: Array<SelectOption>;
  invalid = false;
  private _selectedOption: SelectOption;
  private allOptions: Array<SelectOption>;

  /**
   * Expand dropdown
   */
  openDropdown() {
    this.dropdownToggle = true;
  }

  /**
   * Close the select dropdown
   */
  closeDropdown() {
    this.dropdownToggle = false;
  }

  /**
   * Validate input text once clicked outside of the input field
   *  set focus on it and emit null to parent if input text is not a valid option label
   */
  validateInputText() {
    if (!this.selectedOption) {
      return;
    }
    if (this.isValidateOptionLabel()){
      this.invalid = false;
    } else {
      this.invalid = true;
      this.searchInput.nativeElement.focus();
      this.resetSelectedOption();
    }
  }

  /**
   * Validate whether the label is a valid option label
   * @returns true only if input text is either null or a whole label of one option
   */
  isValidateOptionLabel() {
    return !this.inputText || this.allOptions.find(option => option.label === this.inputText);
  }

  /**
   * Toggle dropdown
   */
   toggleDropdown() {
    this.dropdownToggle = !this.dropdownToggle;
  }

  /**
   * Set selected option, close dropdown, and reset invalid status of input text
   * @param option an option clicked
   */
  optionClick(option: SelectOption) {
    this.selectedOption = option;
    this.closeDropdown();
    if (this.isValidateOptionLabel()){
      this.invalid = false;
    }
  }

  /**
   * When a key is pressed control the list with the keyboard
   * @param event the key event emmited by the browser
   */
  onKeyDown(event: any) {
    const index = this.filteredOptions.findIndex((x) => this.selectedOption === x) ?? 0;
    switch (event.key) {
      case 'ArrowDown':
      case 'Tab':
        event.preventDefault();
        const nextIndex = (index + 1) % this.filteredOptions.length;
        this.selectedOption = this.filteredOptions[nextIndex];
        this.dropdownElements.toArray()[nextIndex].nativeElement.scrollIntoView({ block: 'end' });
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (index > 0) {
          const prevIndex = (index - 1) % this.filteredOptions.length;
          this.selectedOption = this.filteredOptions[prevIndex];
          this.dropdownElements.toArray()[prevIndex].nativeElement.scrollIntoView({ block: 'end' });
        }
        break;
      case 'Enter':
        event.preventDefault();
        if (this.selectedOption) {
          this.closeDropdown();
        }
    }
  }

  /**
   * When input text changes filter the options
   * If the filtered options are none or input text is empty emits a null value to clear selection
   * @param event the string with the new text from the input
   */
  onNgModelChange(event: string) {
    const normalizedEvent = event.toLocaleLowerCase();
    this.filteredOptions = this.allOptions.filter((element) => {
      const normalizedElementLabel = element.label.toLocaleLowerCase();
      const normalizedElementValue = element.value.toLocaleLowerCase();
      return normalizedElementValue.includes(normalizedEvent) || normalizedElementLabel.includes(normalizedEvent);
    });
    if (this.inputText === '' || this.filteredOptions.length === 0) {
      this.resetSelectedOption();
    }
  }

  onBlur() {
    this.validateInputText();
    this.blurInput.emit();
  }

  resetSelectedOption(){
    this._selectedOption = null;
    this.inputText = null;
    this.changeOption.emit(null);
  }
}
