import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { untilDestroyed } from '@app/@core';
import { MarketSortOptions, globalDefaultSort } from '@app/@shared/consts';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { AnalysisFilter, FiltersChangedEvent } from '@app/@shared/models/reverse-engineering/analysis.models';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import { FilterHelper } from '@app/@shared/utils/filter-helper';
import { PaginationHelper } from '@app/@shared/utils/pagination-helper';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, combineLatest } from 'rxjs';
import { FilterSidebarComponent } from '../../filter-sidebar/filter-sidebar.component';
import { TABLET_MAX_WIDTH } from '@app/@shared/consts/breakpoints.consts';
import { TelemetryService } from '@app/@shared/services/telemetry-service';

@Component({
  template: '',
})
@UntilDestroy()
export abstract class AbstractSubscriptionContentsComponent implements OnInit {
  @ViewChild('filtersBar') filtersBar: FilterSidebarComponent;
  isComponentLoading = true;

  // Context attributes
  searchContext = '';
  subscriptionIds: Array<string> = [];
  channelIds: Array<string> = [];

  // Pagination attributes
  paginationOptions: PaginationParameters;

  // Search bar attributes
  previousSearchTerm: string;
  searchTerm: string;
  hideSearchButton = true;

  // Sort attributes
  sortOptions = MarketSortOptions;
  selectedSort = globalDefaultSort;

  // Filters attributes
  filters: Array<AnalysisFilter> = [];
  filtersCollapsed = false;

  get filtersApplied(): boolean {
    return Boolean(this.filters?.find((f) => f.options.filter((o) => o.selected).length > 0));
  }

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected navigationService: NavigationService,
    protected telemetryService: TelemetryService
  ) {}

  ngOnInit(): void {
    this.listenToSidebarState();
    combineLatest([
      this.getContext(),
      this.route.queryParamMap,
    ]).pipe(
      untilDestroyed(this),
    ).subscribe(([{ subscriptionIds, channelIds, searchContext }, queryParams]) => {
      this.subscriptionIds = subscriptionIds;
      this.channelIds = channelIds;
      this.searchContext = searchContext;
      this.refreshSearchOptions(queryParams);
      this.isComponentLoading = true;
      this.requestContent(queryParams)
        .subscribe((filterSuggestions) => {
          this.refreshFilters(queryParams, filterSuggestions);
          this.isComponentLoading = false;
        });
    });
  }

  /**
   * Called when the user changes the search term highlighting option
   */
  public onChangePaginationOptions(options: PaginationParameters) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { ...options },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  /**
   * Called when the search term changes
   */
  public onChangeSearchTerms(term: string): void {
    this.telemetryService.setRaiseTelemetryEvent(true);
    this.router.navigate([], {
      queryParams: { q: term, page: 1, searchKey: 'main-search' },
      preserveFragment: true,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Called when any of the filters change
   */
  public onChangeFilters(event: FiltersChangedEvent): void {
    const filters = FilterHelper.analysisFiltersToURLParams(event.applied);
    this.router.navigate([], {
      queryParams: {
        page: 1,
        filters: JSON.stringify(filters),
      },
      preserveFragment: true,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Clears all selected filters
   */
  public onClearFilters() {
    this.filtersBar.clearSelectedFilters();
  }

  /**
   * Called when the user toggles filter sidebar
   */
  public onToggleFilters(_: PointerEvent) {
    this.router.navigate([], {
      fragment: `sidebar=${this.filtersCollapsed}`,
      queryParamsHandling: 'preserve',
      skipLocationChange: false,
    });
  }

  /**
   * Called when the search button is clicked
   */
  public onFocusSearchbar() {
    this.hideSearchButton = false;
  }

  /**
   * Called when the search bar loses focus
   */
  public onBlurSearchbar(blurSearchbar: boolean) {
    this.hideSearchButton = blurSearchbar;
  }

  protected refreshSearchOptions(queryParams: ParamMap) {
    // Pagination attributes
    this.paginationOptions = PaginationHelper.fromParamsOrDefault(queryParams,
      new PaginationParameters(50, 1, globalDefaultSort.value));
    // Search bar attributes
    this.previousSearchTerm = this.searchTerm;
    this.searchTerm = queryParams.getAll('q')
      .map((term) => term.trim())
      .filter(Boolean)
      .join(',');
    // Sort attributes
    this.selectedSort = this.sortOptions
      .find((sort) => sort.value === queryParams.get('sort')) ?? globalDefaultSort;
    // Filters attributes are set after search
  }

  protected refreshFilters(queryParams: ParamMap, filterSuggestions: AnalysisFilter[]) {
    if (this.previousSearchTerm !== this.searchTerm) {
      const filtersFromURL = FilterHelper.getSelectedFiltersFromURLParams(
        this.filterFiltersBasedOnContext(filterSuggestions),
        FilterHelper.parseFiltersFromParams(queryParams),
      );
      // We remove the Subscription facet because we already are in a subscription page
      this.filters = filtersFromURL.filter((facet) => facet.field !== 'subscriptionId');
    }
  }

  private listenToSidebarState() {
    this.route.fragment.subscribe((fragment) => {
      const params = new URLSearchParams(fragment);
      this.filtersCollapsed = params.has('sidebar')
        ? params.get('sidebar').toLowerCase() === 'false'
        : window.innerWidth <= TABLET_MAX_WIDTH;
    });
  }

  private filterFiltersBasedOnContext(filterSuggestions: AnalysisFilter[]): AnalysisFilter[] {
    return FilterHelper.filterOutNotContainedSubscriptionIdsAndChannelIds(
      filterSuggestions,
      this.channelIds,
      this.subscriptionIds,
    );
  }

  /**
   * Requests the content to be displayed in the component
   * @param queryParams the query params from the URL
   * @returns the filters suggested by the backend
   */
  protected abstract requestContent(queryParams: ParamMap): Observable<AnalysisFilter[]>;

  protected abstract getContext(): Observable<SubscriptionContentContextModel>;
}

export interface SubscriptionContentContextModel {
  subscriptionIds: Array<string>;
  channelIds: Array<string>;
  searchContext: string;
}
