import {ContactModalConfigurationInterface} from '../../interfaces/contact-modal-configuration.interface';

export class ContactModalAskAnExpertConfig implements ContactModalConfigurationInterface{
  headerTitle = 'Contact a Subject Matter Expert';
  bodyTitle = 'You have questions? We have insights.';
  description = 'Complete this form to ask a Techinsights subject matter expert' +
    ' a question and receive expert domain knowledge support.';
  messageInputLabel = 'Your Question';
  cancelButtonText = 'Cancel';
  submitButtonText = 'Submit Question';
}
