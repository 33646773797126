import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { environment } from '@env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PaginationParameters } from '@shared/models/pagination';
import {
  Analysis,
  AnalysisAsset,
  AnalysisReportMimeType,
  AnalysisReportPage,
  AssetGroup,
  RelatedContent,
} from '@shared/models/reverse-engineering/analysis.models';
import { getAssetContentType, removeFileNameExtension } from '@shared/utils/map-table-content-helper';

@UntilDestroy()
@Component({
  selector: 'app-report-list-view',
  templateUrl: './report-list-view.component.html',
  styleUrls: [ './report-list-view.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ReportListViewComponent {
  @Input() currentAnalysis: Analysis;
  @Input() isLoading = false;
  @Input() set analysisReportPage(analysisReportPage: AnalysisReportPage) {
    this.mapReports(analysisReportPage);
  }
  @Input() pageSize = 50;
  @Input() currentPage = 1;
  @Input() annotatedDocuments: Array<string>;
  @Input() relatedContentList: RelatedContent[];
  @Input() sidebarOpen = false;

  @Output() itemViewClicked: EventEmitter<AnalysisAsset> = new EventEmitter<AnalysisAsset>();
  @Output() changePaginationOptions = new EventEmitter<PaginationParameters>();
  @Output() downloadAssetGroup = new EventEmitter<AssetGroup>();
  @Output() documentNewTab: EventEmitter<AnalysisAsset> = new EventEmitter<AnalysisAsset>();
  @Output() documentShare: EventEmitter<AnalysisAsset> = new EventEmitter<AnalysisAsset>();

  public reports: Array<AnalysisAsset> = [];
  public assetGroups: Array<AssetGroup> = [];
  public readonly pageSizes = [ 10, 50, 100 ];
  public selectedRowIds: Array<string> = [];
  public isCheckboxBulkActionsDisabled = true;

  constructor() {}

  /**
   * Emits an event when row is clicked.
   * @param report The report clicked.
   */
  public onClickReport(report: AnalysisAsset): void {
    this.itemViewClicked.emit(report);
  }

  /**
   * Emits an event when download button is clicked.
   * @param report The report clicked.
   * @param _currentAnalysis Current Analysis
   */
   public onDownloadAssetGroup(report: AssetGroup, event: Event) {
    event.stopPropagation();
    this.downloadAssetGroup.emit(report);
  }

  /**
   * Emits an event when row is clicked.
   * @param report The report clicked.
   * @param _currentAnalysis Current Analysis
   */
  public onClickRelatedDataRow(report: AssetGroup, { entitled }: Analysis) {
    if (!entitled) {
      this.downloadAssetGroup.emit(report);
    }
  }

  /**
   * Emits an event if the pagination options change.
   * @param event The event from the pagination controls.
   */
  public onChangePaginationOptions(event: PaginationParameters): void {
    this.pageSize = event.size;
    this.currentPage = event.page;
    this.changePaginationOptions.emit(event);
  }

  public isReport(report: AnalysisAsset): boolean {
    return report.fileType === AnalysisReportMimeType.PDF
      || report.fileType === AnalysisReportMimeType.BLOG || report.fileType === AnalysisReportMimeType.QUICKSIGHT
      || report.fileType === AnalysisReportMimeType.HYGRAPH;
  }

  public isBlog(report: AnalysisAsset): boolean {
    return report.fileType === AnalysisReportMimeType.BLOG;
  }

  public mapContentType(reportFileType: string): string {
    return getAssetContentType(reportFileType);
  }

  public mapReportName(reportName: string): string {
    return removeFileNameExtension(reportName);
  }

   public onMeatballMenuOptionClick(event: Event, report?: AnalysisAsset) {
    event.preventDefault();
    event.stopPropagation();
    this.documentNewTab.emit(report);
  }

  public onMeatballMenuShareClick(event: Event, report?: AnalysisAsset) {
    event.preventDefault();
    event.stopPropagation();
    this.documentShare.emit(report);
  }

  /**
   * This method is extracting the extension from the filename based on
   * the period which is what the regex is doing for each report we
   * are also checking for undefined/null filename
   * @param analysisAsset An analysis report page
   */
  private mapReports(analysisAsset: AnalysisReportPage): void {
    if (analysisAsset && analysisAsset.reports) {
      this.reports = analysisAsset.reports;
      this.assetGroups = analysisAsset.assetGroups;
    }
  }

}
