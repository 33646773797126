<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body text-center">
    <img [src]="imageUrl" [alt]="altText" class="img-fluid" />
    <div class="image-caption">
        <span>{{altText}}</span>
    </div>
</div>
