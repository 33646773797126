import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { combineLatest, Observable, forkJoin, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators'
import { environment } from '@env/environment';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import {
  Dashboard as NavDashboard,
  Channel,
  NavigationElement,
  NavigationModules,
  NavigationObjectType,
  Subscription,
  NavigationHint
} from '@app/@shared/models/navigation/navigation.models';
import {SubscriptionCard, TableauConfig} from '@app/@shared/models/shared.models';
import {
  iconMap,
  defaultIcon,
  FILTERS_NOT_SHOWN_IN_SIDEBAR,
  SIMPLE_FILTERS_FIELD_NAME,
  STRATEGY_ANALYTICS,
} from './strategy-analytics-icon-map';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { ContentNavigatorTablePage, FlavourCounts, ReportFlavor } from '@app/@shared/models/content-navigator/content-navigator.model';
import { AnalysisFilter, Filters } from '@app/@shared/models/reverse-engineering/analysis.models';
import { FilterHelper } from '@app/@shared/utils/filter-helper';
import { SaChannelCard } from '../models/sa-channel-card.model';
import {
  Dashboard,
  DashboardContentNavigatorTable,
  DashboardContentNavigatorTableRow,
  SaContentNavigatorTable,
  SaReportFlavor
} from '../models/sa-content-navigator.model';
import { SearchService } from '@app/@shared/services/search/search.service';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { CTISearchResult, CTISearchResults } from '@app/@shared/models/search/cti-search.model';
import {SaAnalyticsDashboardCardModel} from '@app/strategy-analytics/models/sa-analytics-dashboard-card.model';

@Injectable({
  providedIn: 'root',
})
export class StrategyAnalyticsService {
  // variables
  private metaUrl = environment.metaServiceBaseUrl;

  // constructor
  constructor(
    private navigationService: NavigationService,
    private httpClient: HttpClient,
    private searchService: SearchService,
    private featureFlagService: FeatureFlagService,
  ) {}

  // Methods
  getSubscriptionExcerpts(): Observable<Array<SubscriptionCard>> {
    return this.navigationService.getModuleCardsByName(STRATEGY_ANALYTICS).pipe(
      map((response) => {
        const subscriptions = response as Subscription[];
        return subscriptions.map((subscription) => ({
          id: subscription.id,
          name: subscription.name,
          totalChannels: subscription.totalChannels,
          icon: this.buildIcon(subscription.name),
          availableChannels: subscription.availableChannels,
          // below values are same as RE for now
          entitled: true,
          bypassEntitlement: false,
          isCustom: subscription.isCustom
        }));
      })
    );
  }

  getSubscription(subscriptionId: string): Observable<Subscription> {
    return this.navigationService.getSubscription(subscriptionId, STRATEGY_ANALYTICS).pipe(
      map((subscription) => ({
        ...subscription,
        getContentNavigatorTabs: subscription.getContentNavigatorTabs,
        getLandingPageTabs: subscription.getLandingPageTabs,
        icon: this.buildIcon(subscription.name),
        isCustom: subscription.isCustom
      }))
    );
  }

  getTabCounts(channelIds: string): Observable<FlavourCounts> {
    return forkJoin([
      this.searchService.getTabCounts(channelIds),
      this.getAllDashboards()
    ]).pipe(
      map(([response,dashboards]) => {
        response.dashboardCount = dashboards.filter((dashboard)=> channelIds.includes(dashboard.channel_sf_id)).length
        return response;
      })
    )
  }

  getChannels(): Observable<Array<Channel>> {
    return this.navigationService.getModuleChannelsByName(NavigationModules.END_MARKET_ANALYSIS);
  }

  getChannelCardInfo(subscriptionId: string): Observable<Array<SaChannelCard>> {
    return this.getSubscription(subscriptionId).pipe(
      concatMap((subscription: Subscription) => {
        const children: NavigationElement[] = subscription.children
          .filter(c => c.type === NavigationObjectType.CHANNEL);
        const mergedIds = children
          .reduce(
          (previousValue: string, currentValue: NavigationElement) => `${previousValue},${currentValue.id}`,
          '',
        );
        const countingUrl = `${this.metaUrl}/products/counting`;
        const headers = new HttpHeaders().set('Accept', 'application/json');
        const params = new HttpParams().set('channels', mergedIds);

        return forkJoin([
          this.httpClient.get<any>(countingUrl, { headers, params }),
          this.getAllDashboards()
        ]).pipe(
          map(([channelCounts, dashboards]) => {
            const channelCardInfo: Array<SaChannelCard> = [];
            children.forEach((channel: Channel) => {
              const channelInfo = channelCounts.products.find(
                (channelCount: { channel: string }) => channel.id === channelCount.channel
              );
              const channelDashboards = dashboards.filter(d => (d.channel_sf_id === channel.id));
              if(channelInfo?.count > 0){
                channelCardInfo.push(
                  new SaChannelCard(
                    channel.id,
                    channel.name,
                    channel.entitled,
                    channelInfo ? channelInfo.market_data_count : undefined,
                    channelInfo ? channelInfo.analysis_count_new : undefined,
                    channelInfo ? channelInfo.sample_count : undefined,
                    channelDashboards.length,
                    channel.isCustom?? false
                  )
                );
              }
            });
            return channelCardInfo;
          })
        );
      })
    );
  }

  public getLatestAnalysisForSubscriptions(
    subscriptionId: string,
    flavor: ReportFlavor,
    limit: number
  ): Observable<SaContentNavigatorTable> {

    return this.navigationService.getSubscription(subscriptionId, NavigationModules.STRATEGY_ANALYTICS).pipe(
      concatMap((subscription: Subscription) => {
        const idChannels = subscription.children.map((channel) => channel.id);
        const filters: Filters = {
          channel: idChannels,
          strategyAnalyticsFlavor: [flavor],
        };
        return this.getAnalysisByFilters(filters, limit);
      })
    )
  }

  /**
   * Gets a limit number of the latest reports of the provided flavor for SA module
   * By latest means the latest completion date.
   *
   * @param flavor: any of flavor strings, i.e., ['MARKET DATA', 'IN_PROGRESS', 'SAMPLES']
   * @param limit: number of reports
   * @returns Observable<SaContentNavigatorTable>
   */
  public getLatestAnalysisForSAModule(flavor: SaReportFlavor, limit: number):
    Observable<SaContentNavigatorTable> {
    return this.navigationService.getModuleChannelsByName(STRATEGY_ANALYTICS).pipe(
      concatMap((allChannels: Channel[]) => {
        const filters: Filters = {
          channel: allChannels.map((channel) => channel.id),
          strategyAnalyticsFlavor: [flavor],
        };
        return this.getAnalysisByFilters(filters, limit);
      })
    );
  }

  getChannelAllAnalysis(
    channelId: string,
    flavor: string,
    pagination?: PaginationParameters,
    searchTerm?: string,
    filters?: Filters,
    isCustom?: boolean
  ): Observable<ContentNavigatorTablePage> {
    const flavorKey = isCustom ? 'flavor' : 'strategyAnalyticsFlavor';
    const selectedFilters = {
      channel: [channelId],
      [flavorKey]: [flavor],
      ...filters,
    };
    return this.searchService.getTable(searchTerm,selectedFilters,pagination);
  }

  /**
   * It takes in a search term, filters, and pagination parameters, and returns an observable of a
   * ContentNavigatorTablePage
   * @param searchTerm - string,
   * @param filters - Filters,
   * @param paginationParameters - PaginationParameters
   * @returns Observable<ContentNavigatorTablePage>
   */
   getAllAnalysis(
    searchTerm: string,
    filters: Filters,
    paginationParameters: PaginationParameters,
    subscriptionId?: string
  ): Observable<ContentNavigatorTablePage> {
    return this.resolveFilters(filters, subscriptionId).pipe(
      concatMap( resolvedFilters => {
        return this.searchService.getTable(searchTerm, resolvedFilters, paginationParameters);
      })
    )
  }

  /**
   * Gets the raw facets from meta service
   * @returns a list of facets
   */
  getFacets(): Observable<any[]> {
    return this.httpClient.get(`${environment.metaServiceBaseUrl}/facets`).pipe(
      map((filters: Array<any>) => {
        return filters
          .filter((f) => !FILTERS_NOT_SHOWN_IN_SIDEBAR.includes(f.fieldName))
        }
      ));
  }

  /**
   * Gets the analysis filters related to strategy analytics
   * by combining facets and navigation services
   * @returns a list of the filters
   */
   getAnalysisFiltersForSidebar(): Observable<AnalysisFilter[]> {
    return combineLatest([
      this.navigationService.getModuleCardsByName(STRATEGY_ANALYTICS),
      this.getFacets(),
    ]).pipe(
      map(([subscriptions, analisis]) => {
        return analisis
          .map((f) => FilterHelper.buildFilter(f, subscriptions))
          .sort((a, b) => {
            if (SIMPLE_FILTERS_FIELD_NAME.includes(a.field)) {
              return -1;
            } else if (SIMPLE_FILTERS_FIELD_NAME.includes(b.field)) {
              return 1;
            }
            return 0;
          });
      })
    );
  }

  /**
   * Gets all the dashboards of a given channel
   *
   * @param channelId: channel id string
   * @returns Observable<DashboardContentNavigatorTable>
   */
  public getChannelDashboards(channelId: string, subscriptionId: string): Observable<DashboardContentNavigatorTable> {
    const emaFF = environment.emaDashboardsFF.get(channelId)??''
    return combineLatest([
      this.getAllDashboards(),
      this.featureFlagService.getFlag(emaFF)
    ]).pipe(
      map(([dashboards,flag]) => ({
        dashboards: dashboards.filter((d) => d.channel_sf_id === channelId),
        flag
      })),
      concatMap((result) => this.buildDashboardRows(result.dashboards, result.flag, subscriptionId)),
      map((dashboards) => ({ rows: dashboards })),
    );
  }

  getSaConfig(dashboardUrl: string, ticket: string): TableauConfig {
    const finalUrl = dashboardUrl.replace('#', 'trusted/' + ticket);

    return {
      url: finalUrl,
      width: '100%',
      height: '100%',
    };
  }

  /**
   * Get a mocked list of channel card information for Logic analytics.
   * @returns a list of the channel cards
   */
  getAnalyticsDashboardCardInfo(subscriptionId: string): Observable<Array<SaAnalyticsDashboardCardModel>> {
    return forkJoin([this.getSubscription(subscriptionId), this.getAllDashboards()]).pipe(
      concatMap(([subscription, dashboards]) => {
        const channelCardInfo: Array<SaAnalyticsDashboardCardModel> = [];
        const entitlements: any = {};

        dashboards.forEach(d => entitlements[d.channel_sf_id] = d.has_full_permission);

        subscription.children.forEach((navElement: NavDashboard) => {
          if(navElement.type !== NavigationObjectType.DASHBOARD){
            return;
          }
          const isRetoolDashboard = navElement.content === NavigationHint.RETOOL_DASHBOARD;
          channelCardInfo.push(
            new SaAnalyticsDashboardCardModel(
              navElement.id,
              navElement.name,
              isRetoolDashboard ? true : !!entitlements[navElement.channelSfId],
              0,
              0,
              0,
              0,
              !!navElement.isCustom,
              navElement.content,
              isRetoolDashboard ? '' : navElement.channelSfId,
              isRetoolDashboard ? '' : navElement.dashboardUrl,
              isRetoolDashboard ? navElement.dashboardId : ''
            )
          );
        });

        return of(channelCardInfo);
      })
    );
  }

  /**
   * Gets all the dashboards
   * @returns Observable<Array<Dashboard>>
   */
  getAllDashboards(): Observable<Array<Dashboard>> {
    return this.httpClient.get<any>(`${environment.metaServiceBaseUrl}/strategy_analytics/dashboard_permissions`)
      .pipe(
        map(({ dashboard_list }) => dashboard_list)
        ,catchError(_ => of([])),
      );
  }

  private buildDashboardRows(
    dashboards: Array<Dashboard>,
    hasDashboard: boolean,
    subscriptionId: string,
  ): Observable<Array<DashboardContentNavigatorTableRow>> {
    const requests$ =  dashboards.map(d => {
      const dashboardEntitled = d.has_full_permission || d.has_demo_permission;
      const getDashboardUrl$ = this.getDashboardUrl(d.supported_dashboard, d.user_group, d.dashboard_name);
      const dashboardUrl$ = dashboardEntitled ? getDashboardUrl$ : of(undefined);

      return forkJoin([
        dashboardUrl$,
        this.navigationService.getSubscription(subscriptionId, STRATEGY_ANALYTICS),
        this.navigationService.getChannel(d.channel_sf_id, STRATEGY_ANALYTICS)
      ]).pipe(
        map(([url, subscription, channel]) => {
          return {
            title: d.name,
            subscription: this.buildIcon(subscription.name),
            channel: channel.name,
            entitled: dashboardEntitled && channel.entitled,
            url: hasDashboard ? environment.emaDashboards[d.channel_sf_id] : url,
            dashboardType: d.supported_dashboard
          }
        }),
      );
    });

    return requests$.length > 0 ? forkJoin(requests$) : of([]);
  }

  /**
   * Get access Dashboard URL
   * @returns Observable<string>
   */
  private getDashboardUrl(supportedDashboard: string, userGroup: string, dashboardName: string): Observable<string> {
    const params = new HttpParams()
      .set('dashboard', dashboardName)
      .set('user_group', userGroup)

    return this.httpClient.get<string>(`${environment.metaServiceBaseUrl}/strategy_analytics/dashboard_url/${supportedDashboard}`, {params});
  }

  private mapCTISearchResponseToSaContentNavigatorTable(
    response: CTISearchResults<CTISearchResult>
  ): SaContentNavigatorTable {
    return {
      rows: response.results.map((result: CTISearchResult) => ({
        id: result.id,
        title: result.name,
        analysisType: result.reportType,
        productCode: result.code,
        description: result.description,
        deviceName: result.deviceName,
        manufacturer: result.deviceManufacturer,
        deviceType: result.deviceType,
        subscription: this.buildIcon(result.subscriptionName),
        channel: result.channelName,
        completionDate: result.analysisEndDate ? new Date(result.analysisEndDate) : null,
        documentsCount: result.documentsCount,
        imagesCount: result.imagesCount,
        entitled: result.entitled,
        subscriptionName: result.subscriptionName,
      })),
    };
  }

  private getAnalysisByFilters(
    filters: Filters,
    limit: number
  ): Observable<SaContentNavigatorTable> {
    return this.searchService.cTISearch<CTISearchResult>([], new PaginationParameters(limit, 0, 'releaseDate,DESC', { from: 0 }), filters).pipe(
      map((response) => this.mapCTISearchResponseToSaContentNavigatorTable(response))
    );
  }

  private resolveFilters(filters:Filters, subscriptionId?:string): Observable<Filters> {
    if (subscriptionId && !filters.hasOwnProperty('channel')) {
      return this.navigationService.getSubscription(subscriptionId, NavigationModules.STRATEGY_ANALYTICS)
      .pipe(
        concatMap((subscription: Subscription) => {
          const idChannels = subscription.children.map((channel) => channel.id);
          return of({...filters, channel: idChannels})
        })
      )
    } else if (filters.hasOwnProperty('channel')){
      return of(filters)
    }

    return this.navigationService.getModuleChannelsByName(NavigationModules.STRATEGY_ANALYTICS).pipe(
      concatMap((allChannels) => {
        const idChannels = allChannels.map((channel) => channel.id);
        return of({ ...filters, channel: idChannels })
      })
    );
  }

  private buildIcon(subscriptionName: string): string {
    if (subscriptionName.includes('Custom Library')) {
      return iconMap['Custom Library'];
    }
    if (iconMap[subscriptionName]) {
      return iconMap[subscriptionName];
    } else {
      return defaultIcon;
    }
  }

}
