/**
 * Gets a file extension and maps it to the name of the content
 * @param fileType file extension .pdf, .xls etc.
 * @returns content type of the file
 */
import { ASSET_FILE_TYPE, REPORT_FILE_TYPE } from '../enums/report-file-types.enum';


export const getContentType = (fileType: string): string => {
  return REPORT_FILE_TYPE[fileType] ?? fileType;
}

export const getAssetContentType = (fileType: string): string => {
  return ASSET_FILE_TYPE[fileType] ?? fileType;
}

export const removeFileNameExtension = (fileName: string): string => {
  return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
};
