<div class="colour-selection-tiles-container">
<div class="colour-selection-tile" *ngFor="let colour of _colours" [class.selected]="colour===currentColour" (click)="onColourChange(colour)" [ngStyle]="{'background': colour}">
    <div *ngIf="!colour" class="no-fill-line">
    </div>
</div>
</div>

<div class="opacity-selection-container">
    <input type="range" min="1" max="100" [value]="currentOpacity" #ref (input)="onChangeOpacity(ref.value)" (change)="onChangeOpacity(ref.value)"  [ngStyle]="{'background-color': currentColour}"/>
    <span>{{currentOpacity}}%</span>
</div>