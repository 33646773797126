<section class="content">
  <div class="related-list-container scrollbar horizontal">
    <div class="scroll-container">
      <div class="title-header">
        <span>Related Content</span>
      </div>
      <div
        *ngFor="let relatedContent of relatedContentList"
        class="relatedContent"
        (click)="onClickRelatedContent(relatedContent)"
      >
        <div class="card-icon">
          <em *ngIf="!relatedContent.entitled" class="icon-locked"></em>
        </div>
        <div class="info">
          <div class="card-title" [class.non-entitled]="!relatedContent.entitled && relatedContent.type !== 'REPORT'">
            {{relatedContent.name}}
          </div>
          <div class="card-subtitle">
            <span
              class="subtitle-items"
              [class.non-entitled]="!relatedContent.entitled && relatedContent.type !== 'REPORT'"
            >
              {{ relatedContent.publishedDate | date:'mediumDate' }}
              <span *ngIf="relatedContent.publishedDate" >| </span>
              {{relatedContent.type !== 'REPORT' ? (relatedContent.type | titlecase) : 'Analysis'}}
            </span>
          </div>
        </div>
        <div class="card-icon icon-external"></div>
      </div>
    </div>
  </div>
</section>
