import { InjectionToken } from '@angular/core';

export const WindowToken = new InjectionToken('Window');
export function windowProvider() { return window; }

export const MockWindow = {
  location: {
    _href: '',
    set href(url: string) { this._href = url },
    get href() { return this._href },
    assign: (url: string) => {},
    reload: () => {},
  }
};
