import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationParameters } from '@app/@shared/models/pagination';
import {
  Analysis,
  AnalysisImage,
  AnalysisResponseThumbnails,
  IImageFilter,
} from '@app/@shared/models/reverse-engineering/analysis.models';
import { Image } from '@shared/models/image';

@Component({
  selector: 'app-image-content',
  templateUrl: './image-content.component.html',
  styleUrls: ['./image-content.component.scss'],
})
export class ImageContentComponent {
  @Input() sidebarOpen = false;
  @Input() currentAnalysis: Analysis;
  @Input() analysisReports: AnalysisResponseThumbnails;
  @Input() paginationParameters: PaginationParameters;
  @Input() searchTerm: string;
  @Input() isLoading = false;
  @Input() selectedImage: { objectId: string; url: string };
  @Input() showImageViewer = false;
  @Input() imageFilters: IImageFilter[];
  @Input() channelId: string;
  @Input() subscriptionId: string;
  @Input() downloadEnabled = false;
  @Input() addToFavoritesEnabled = false;
  @Input() enableUnwatermarkedImageRequest = false;

  @Output() changeSelectedImage = new EventEmitter<Image>();
  @Output() changePaginationParameters = new EventEmitter<PaginationParameters>();
  @Output() closeImageViewer = new EventEmitter<void>();
  @Output() changeFilter = new EventEmitter<{ selectedFilter: IImageFilter; ignoreFilter: string }>();
  @Output() downloadImage = new EventEmitter<Image>();
  @Output() downloadImages = new EventEmitter<any>();
  @Output() shareImage = new EventEmitter<AnalysisImage>();
  @Output() addToFavorites = new EventEmitter<Image>();

  public title = '';

  onChangeFilter(filterEvent: { selectedFilter: IImageFilter; ignoreFilter: string }): void {
    this.title = filterEvent.selectedFilter.label;
    this.changeFilter.emit(filterEvent);
  }

  onChangeSelectedImage(image: Image): void {
    this.changeSelectedImage.emit(image);
  }

  onCloseImageViewer(): void {
    this.closeImageViewer.emit();
  }

  onChangePaginationParameters(paginationParams: PaginationParameters): void {
    this.changePaginationParameters.emit(paginationParams);
  }

  public onDownloadImage($image: Image): void {
    this.downloadImage.emit($image);
  }

  public onDownloadImages(selectedIds: any): void {
    this.downloadImages.emit(selectedIds);
  }

  public onShareImage(image: AnalysisImage): void {
    this.shareImage.emit(image);
  }

  public onAddToFavorites(image: Image): void {
    this.addToFavorites.emit(image);
  }
}
