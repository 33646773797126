<ng-template #tooltipContent>
    <div class="d-flex flex-column">
      <span class="text-left mb-2">{{ tipContent.lineOne }}</span>
      <span class="text-left mb-2">{{ tipContent.lineTwo }}</span>
      <span class="text-left">{{ tipContent.lineThree }}</span>
    </div>
</ng-template>

<div class="header">
  <div class="title-container">
    <em class="icon" [ngClass]="icon"></em>
    <div class="title-text-container">
      <span class="title"
        >{{ title }}
        <span class="icon-info" *ngIf="tipContent"  tooltipClass="header-tooltip" [ngbTooltip]="tooltipContent" placement="bottom" container="body">
        </span>
      </span>
      <span *ngIf="subtitle" class="subtitle">{{ subtitle }}</span>
    </div>
  </div>

  <div *ngIf="showContactCem" class="contact-cem">
    <em class="icon-help_filled"></em>
    <div class="contact-cem-container">
      <span>Questions about your subscriptions?</span>
      <a (click)="onClickContactCem()">Contact your Customer Engagement Manager</a>
    </div>
  </div>

  <div *ngIf="tabs && tabs.length > 0" class="tabs">
    <a *ngFor="let tab of tabs" class="tab" routerLinkActive="active" [routerLink]="tab.routerLink" [fragment]="tab.fragment">
      <em class="icon" [ngClass]="tab.icon"></em>
      {{ tab.label }}
    </a>
  </div>
</div>

<div *ngIf="subheader" class="subheader">
  <div class="subheader-text">{{ subheader }}</div>
</div>

<div class="content-section">
    <ng-content></ng-content>
</div>
