import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DropdownItem} from '@shared/models/shared.models';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {of} from 'rxjs';
import {concatMap} from 'rxjs/operators';
import {AnalyticsChannelsService} from '@shared/services/analytics/analytics-channels.service';
import {ChannelsAnalyticsDropdownType} from '@shared/enums/channels-analytics-dropdown.type';
import {Dashboard} from '@shared/models/navigation/navigation.models';

@Component({
  selector: 'app-channels-analytics-dropdown',
  templateUrl: './channels-analytics-dropdown.component.html',
  styleUrls: ['./channels-analytics-dropdown.component.scss']
})
@UntilDestroy()
export class ChannelsAnalyticsDropdownComponent implements OnInit {
  @Input() channels: Dashboard[] = [];

  type: ChannelsAnalyticsDropdownType = ChannelsAnalyticsDropdownType.CHANNELS;
  channelID: string;
  subChannelID: string;
  items: DropdownItem[] = [];
  currentItem: DropdownItem;
  hideIconOnDropdown: boolean;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private analyticsChannelsService: AnalyticsChannelsService,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        concatMap((paramMap) => {
          if (paramMap.has('channelID')) {
            this.channelID = paramMap.get('channelID');

            if (paramMap.has('subChannelID')) {
              this.subChannelID = paramMap.get('subChannelID');
              this.type = ChannelsAnalyticsDropdownType.SUBCHANNELS;
            }

            return this.analyticsChannelsService.getAnalyticsChannel(this.channelID, this.channels);
          } else {
            return of(null);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe((channel) => {
        if (channel) {
          this.hideIconOnDropdown = true;

          if (this.type === ChannelsAnalyticsDropdownType.CHANNELS) {
            this.items = this.channels.map(
              ({id, name, isCustom}: DropdownItem) => ({id, name, isCustom})
            );

            this.currentItem = channel;
          } else {
            this.items = channel.children ? channel.children.map(
              ({id, name, isCustom}: DropdownItem) => ({id, name, isCustom})
            ) : [];

            this.currentItem = this.items.find(
              (analyticsSubChannel) => analyticsSubChannel.id === this.subChannelID
            );
          }
        }
      });
  }

  onChange(item: DropdownItem): void {
    this.currentItem = item;

    let commands: any[];
    if (item.id) {
      if (this.type === ChannelsAnalyticsDropdownType.CHANNELS) {
        commands = [item.id];
      } else {
        commands = [this.channelID, item.id];
      }
    }

    this.router.navigate(commands, {relativeTo: this.route.parent}).then();
  }
}
