import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, AnimationBuilder, AnimationPlayer, style, transition, trigger } from '@angular/animations';
import { combineLatest, fromEvent, merge, Observable, of } from 'rxjs';
import { filter, map, mergeMap, pairwise, tap } from 'rxjs/operators';
import { untilDestroyed } from '@core';
import { Store } from '@ngrx/store';
import {
  loadWorkspace,
  setMoreContainerIconByRoute,
  setMoreContainerShownState,
  toggleMoreContainer,
} from '@app/store/Actions/workspace/workspace.actions';
import { IPosition } from '@app/shell/components/nav-bar/nav.component';
import {
  selectMoreContainerPosition,
  selectWorkspaceLoaded,
} from '@app/store/Selectors/workspace/workspace.selectors';
import { setOnlineState } from '@app/store/Actions/application-health/application-health.actions';
import { selectRouter } from '@app/store';
import { AuthenticationService } from 'src/app/auth';
import { DeepLinkingService } from '@app/@shared/services/deep-linking.service';
import { UserService } from '@shared/services/user/user.service';
import { VendorIntegrationService } from '../@shared/services/vendor-integration.service';
import { environment } from '@env/environment';
import { AbstractSubscriptionContentsComponent } from '@app/@shared/components/abstract/abstract-subscription-contents/abstract-subscription-contents.component';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import { TelemetryService } from '@app/@shared/services/telemetry-service';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';

/**
 * Primary component for the shell module.
 * This component holds the navigation and the content of other rendered modules
 */
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: [ './shell.component.scss' ],
  animations: [
    trigger('showHideSplash', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('400ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ShellComponent extends AbstractSubscriptionContentsComponent implements OnInit, OnDestroy {

  @ViewChild('navMoreContainerComponent', { read: ElementRef }) navMoreContainer: ElementRef;

  public popoverOnTop = false;
  public showHeaderBar = true;
  public workspaceLoaded$ = this.store.select(selectWorkspaceLoaded);
  public positionMore: Observable<IPosition> = this.store.select(selectMoreContainerPosition).pipe(
    pairwise(),
  ).pipe(
    map((value) => this.createAnimation(value)),
  );
  public mobileNavigationOn = false;
  public isMobileScreen = window.innerWidth < 1100;
  public hideNavBar = false;

  constructor(
    private readonly store: Store,
    protected readonly activatedRoute: ActivatedRoute,
    private readonly auth: AuthenticationService,
    private readonly animationBuilder: AnimationBuilder,
    private readonly ffService: FeatureFlagService,
    protected readonly router: Router,
    private readonly userService: UserService,
    private readonly vendorIntegrationService: VendorIntegrationService,
    private readonly deepLinkingService: DeepLinkingService,
    protected readonly navigationService: NavigationService,
    protected readonly telemetryService: TelemetryService,
  ) {
    super(activatedRoute, router, navigationService, telemetryService);
  }

  ngOnInit() {
    this.setNavBar();
    /**
     * If the shell component inits, it means that the user has just logged in, so
     * the app needs to kickstart the communication with the websocket for notifications,
     * load the history of notifications, get the current path and set the active button in
     * the nav-bar, and set the online state of the app
     */
    this.store.dispatch(loadWorkspace());
    // Check for a signed EULA when the workspace is ready,
    // and load third party tools if it's signed
    this.workspaceLoaded$.pipe(
      filter((isLoaded: boolean) => isLoaded === true),
      mergeMap((_isLoaded: boolean) => this.auth.checkEULASignature()),
      untilDestroyed(this),
    ).subscribe((response) => {
      if (response === null) {
        this.vendorIntegrationService.initialize();
      }
    });
    // Handle a user's default landing page setting
    combineLatest([
      this.userService.getUserInfo(),
      this.ffService.getFlag(environment.enableMyTechInsights),
    ]).pipe(
      untilDestroyed(this),
    ).subscribe(([userInfo, isMyTechInsightsEnabled]) => {
      // If no deep link redirection has already occurred, process the default landing page.
      // Note: Because AppRoutingModule no longer redirects from / to /home,
      // do not check for a missing landing page URL here, so redirectToLandingPage can do it.
      //
      // https://techinsights.atlassian.net/browse/SWSD-5624
      // A deep link redirection should in theory result in router.url not being '/'
      // but race conditions have proven otherwise.  hasRedirectedOnStartup is an additional interlock.
      // Also see comments for hasRedirected in DeepLinkingService.
      if (!this.deepLinkingService.getHasRedirectedOnStartup() && this.router.url === '/') {
        // The problem here is the My TechInsights feature flag arrives initially as false,
        // then true shortly after, if enabled for the user.
        // This check is to only redirect to My TechInsights once, the first time it arrives as true.
        // If it later changes to false, it won't redirect because getHasRedirectedOnStartup() will be true.
        // If it later changes to true (e.g. it is enabled for the user much later),
        // it will redirect randomly while the user is browsing.  Hopefully this doesn't happen
        // while we redesign the My TechInsights system and stop using a feature flag to control access in
        // https://techinsights.atlassian.net/browse/CP-16761
        if (isMyTechInsightsEnabled) {
          userInfo.landing_page = '/my-techinsights';
          this.deepLinkingService.setHasRedirectedOnStartup(true);
        }
        this.redirectToLandingPage(userInfo?.landing_page);
      }
    });
    // This controls the nav menu when there are too many items
    this.store.select(selectRouter).pipe(
      pairwise(),
    ).pipe(
      tap((router) => {
        this.store.dispatch(setMoreContainerIconByRoute({ route: router[1].state.url }));
        if (router[0].state.url !== router[1].state.url) {
          this.store.dispatch(setMoreContainerShownState({ shown: false }));
        }
      }),
      untilDestroyed(this),
    ).subscribe();
    // Where the more container should be positioned
    this.positionMore.pipe(untilDestroyed(this)).subscribe();
    // Shows offline banner in PWA mode
    this.setOnlineState();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.isMobileScreen = window.innerWidth < 1100;
  }

  ngOnDestroy(): void {
    return;
  }

  /**
   * Toggles the nav-more-container
   */
  showMore() {
    this.store.dispatch(toggleMoreContainer());
  }

  /**
   * Creates the animations for the nav-more-container when resizing the window
   * @param value   container positions (previous and actual)
   */
  createAnimation(value: [{ posX: number; posY: number }, { posX: number; posY: number }]) {
    let player: AnimationPlayer;
    const prev = value[0];
    const actual = value[1];
    // We define start to both the initial state of the app and an unchanged state in
    // the position (ex: double clicking "more" button)
    const createAnimationFromStart = (value[0].posY === value[1].posY && value[0].posX === value[1].posX)
      || (value[0].posY === 0 && value[0].posX === 0);
    // eslint-disable-next-line prefer-const
    player = this.createContainerAnimation(
      prev,
      actual,
      this.navMoreContainer.nativeElement,
      createAnimationFromStart
    );
    this.popoverOnTop = false;
    player.play();
    player.onDone(() => this.popoverOnTop = true);
    return actual;
  }

  /**
   * Will loop through all child routes to see if it has controlled properties in route data
   * If they're found in multiple children, the method will use the lowest child value
   */
  onRouterOutletActivate(_event: any) {
    let routeChildRef = this.activatedRoute.snapshot.firstChild
    let localShowHeader = true;
    while (routeChildRef) {
      if ('showHeader' in routeChildRef.data) {
        localShowHeader = routeChildRef.data.showHeader;
      }
      routeChildRef = routeChildRef.firstChild;
    }
    this.showHeaderBar = localShowHeader;
  }

  openMobileNavigation() {
    this.mobileNavigationOn = true;
  }

  closeMobileNavigation() {
    this.mobileNavigationOn = false;
  }

  override onChangeSearchTerms(term: string) {
    if(term.length) {
      this.router.navigate(['search/result/ALL'], {
        queryParams: { q: term, searchKey: 'main-search' },
        preserveFragment: true,
        queryParamsHandling: 'merge',
      });
      this.closeMobileNavigation();
    }
  }

  protected requestContent(): any {};
  protected getContext(): any {};

  private createContainerAnimation(prev: IPosition,
    actual: IPosition,
    element: any,
    fromClick: boolean
  ): AnimationPlayer {
    // If the animation is from a click event in the "More" button,
    // then we calculate 60px more from the starting point. If the starting point
    // is 0 due the initial state of the app then we add 80xp (the size of the collapsed nav bar).
    // If not, we take the previous position to start animating
    const prevPosX = prev.posX === 0 ? 80 : prev.posX;
    const startX = fromClick ? -1 * prevPosX + 60 : prev.posX;
    // If the animation is from a click, we use the actual height,
    // if it's from a resize or any other event, we use the previous to animate elevation
    const startY = fromClick ? actual.posY : prev.posY;
    const animation = this.animationBuilder.build([
      style({
        opacity: 0,
        transform: `translate(${startX}px, ${startY}px)`,
      }),
      // Imitating the nav bar animation when collapsing
      animate(prev.posY < actual.posY ? '400ms ease-out' : '500ms ease-in', style({
        opacity: 1,
        transform: `translate(${actual.posX}px, ${actual.posY}px)`,
      })),
    ]);
    return animation.create(element);
  }

  /**
   * Sets view of Navigation Bar
   */
  private setNavBar() {
    this.activatedRoute.queryParamMap
    .subscribe((queryParams) => {
      if(queryParams.has('hn')){
        const hideNav = queryParams.get('hn')
        localStorage.setItem('hn', hideNav)
        this.hideNavBar = localStorage.getItem('hn') === 'true'
      } else if (localStorage.getItem('hn') !== null){
        this.hideNavBar = localStorage.getItem('hn') === 'true'
      }
    })
  }

  /**
   * Sets the connection state of the app. If the user has no internet, it will show a banner
   * that let's the user know they have no connection
   */
  private setOnlineState(): void {
    merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(
      map(() => navigator.onLine)).subscribe(
      res => {
        this.store.dispatch(setOnlineState({ online: res }));
      },
      untilDestroyed(this)
    );
  }

  /**
   * redirectToLandingPage uses the router to navigate to a relative route path,
   * and redirects to the home page if it fails for any reason.
   * @param url The user's landing page URL.  It can be a full URL or relative path.
   * @private
   */
  private redirectToLandingPage(url: string): void {
    // Check that the URL is set so there isn't a navigation to /null or /undefined
    // causing a redirection to /404.  This also catches '' or '/' which will look like a
    // valid landing page URL and will navigate to '/' and not redirect to home.
    if (url && url !== '/') {
      // The URL interface is used because it is robust, performing URL encoding,
      // catching invalid URLs, and provides components of the URL.
      // Setting the base path simply ensures the URL will be generated in case
      // the landing page is a relative path.  An exception can still happen
      // when url is something invalid like '//'.
      try {
        const landingPageUrl: URL = new URL(url, 'this://is-ignored');
        // Navigate to the URL path only, including query parameters, but ignoring the domain.
        // This prevents inadvertent navigation off the platform and allows a full URL like
        // https://library.techinsights.com/tech-library to work in all environments or running locally.
        this.router.navigateByUrl(landingPageUrl.pathname + landingPageUrl.search).then(() => {
          // If the navigation succeeds, it could still be that the catch-all route (**) was triggered
          // on an invalid route, which redirects to /404.  There is no simple way to predetermine if
          // a route is valid or not, so this checks if the current route that was just navigated to is 404.
          // It's not an ideal solution because this needs to be updated if the 404 route ever changes
          // (not likely, but it could be), and it also leaves the 404 page in the
          // browser's navigation history, but it works.
          if (this.router.url === '/404') {
            this.redirectToHome();
          }
        }).catch(() => this.redirectToHome());
      } catch (error) {
        this.redirectToHome();
      }
    } else {
      this.redirectToHome();
    }
  }

  /**
   * redirectToHome simply redirects to the home page.
   * This is a separate method so changing the redirection can be done in one place.
   * @private
   */
  private redirectToHome(): void {
    this.router.navigate(['home']).then();
  }

}
