<section class="content">
  <div
    class="main-containter"
    [class.show-related-content]="relatedContentList?.length > 0"
  >
    <div class="analysis-summary-report-list">
      <app-analysis-summary
        *ngIf="currentAnalysis && !isLoading"
        [currentAnalysis]="currentAnalysis"
      ></app-analysis-summary>
      <div class="report-list-container" _ti_report-list-container="report-list-container">
        <div class="scroll-container">
          <div class="title-header">
            <span>Reports</span>
          </div>
          <div class="list-header row-container template-columns">
            <label class="it-checkbox">
              <input type="checkbox"
              [disabled]="isCheckboxBulkActionsDisabled"/>
              <div></div>
            </label>
            <span></span>
            <span>Name</span>
            <span>Content Type</span>
            <app-checkbox-bulk-actions
              *ngIf="selectedRowIds.length > 0">
                <button>
                  <em class="icon-share"></em> Share
                </button>
                <button>
                  <em class="icon-button_download"></em> Download
                </button>
            </app-checkbox-bulk-actions>
          </div>
          <div
            *ngIf="!isLoading && reports?.length > 0; else noResults"
            class="element-list"
          >
            <div
              *ngFor="let report of reports"
              class="element row-container template-columns"
              [class.element-disabled]="!currentAnalysis.entitled"
              (click)="onClickReport(report)"
            >
              <div class="element-column no-padding">
                <ng-container>
                  <label class="it-checkbox relocated" (click)="$event.stopPropagation()">
                    <input type="checkbox" [disabled]="isCheckboxBulkActionsDisabled"/>
                    <div></div>
                  </label>
                </ng-container>
              </div>
              <div class="element-column no-padding">
                <em class="icon-locked" *ngIf="!currentAnalysis.entitled"></em>
                <em
                    *ngIf="annotatedDocuments.includes(report.assetId) && currentAnalysis.entitled"
                    class="annotation-icon icon-edit1"
                    placement="right"
                    ngbTooltip="Document contains annotations"
                  ></em>
              </div>
              <div class="element-column">
                <ng-container>
                  <span *ngIf="isBlog(report)" class="title" [innerHTML]="mapReportName(report.assetName)"></span>
                  <span *ngIf="!isBlog(report)" class="title">
                    {{ mapReportName(report.assetName) }}
                  </span>
                </ng-container>
              </div>
              <div class="element-column">{{ mapContentType(report.fileType) }}</div>
              <div class="element-column element-center-blue">
                <a *ngIf="isReport(report)">View</a>
                <a *ngIf="!isReport(report)">Download</a>
              </div>
              <div class="element-column">
                <app-meatball-menu [disabled]="!isReport(report) || !currentAnalysis.entitled">
                  <button (click)="onMeatballMenuOptionClick($event, report)">
                    <em class="icon-external"></em> Open in new tab
                  </button>
                  <button
                    (click)="onMeatballMenuShareClick($event, report)"
                  >
                    <em class="icon-share"></em> Share
                  </button>
                </app-meatball-menu>
              </div>
            </div>
          </div>
          <ng-container *ngIf="(!isLoading && assetGroups?.length > 0)">
            <div class="title-secundary">
              <span>Related Data</span>
            </div>
            <div class="element-list" *ngIf="!isLoading && assetGroups?.length > 0; else noResults">
              <div
                *ngFor="let assetGroup of assetGroups"
                class="element row-container template-columns"
                [class.element-disabled]="!currentAnalysis.entitled"
                (click)="onClickRelatedDataRow(assetGroup, currentAnalysis)"
              >
                <div class="element-column no-padding">
                  <ng-container>
                    <label class="it-checkbox" (click)="$event.stopPropagation()">
                      <input type="checkbox" [disabled]="isCheckboxBulkActionsDisabled"/>
                      <div></div>
                    </label>
                  </ng-container>
                </div>
                <div class="element-column no-padding">
                  <em class="icon-locked" *ngIf="!currentAnalysis.entitled"></em>
                </div>
                <div class="element-column">
                  <ng-container>
                    <span class="title"> {{ assetGroup.assetName }}</span>
                  </ng-container>
                </div>
                <div class="element-column">--</div>
                <div class="element-column element-center-blue"
                  (click)="onDownloadAssetGroup(assetGroup, $event)"
                >
                  <a>Download</a>
                </div>
                <div class="element-column">
                  <app-meatball-menu [disabled]="true">
                    <button (click)="onMeatballMenuOptionClick($event, assetGroup)">
                      <em class="icon-button_download"></em> Download
                    </button>
                  </app-meatball-menu>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #noResults>
            <div *ngIf="isLoading" class="loading">
              <app-progress-bar-circular></app-progress-bar-circular>
            </div>
            <div *ngIf="!isLoading" class="no-results">
              <img src="assets/images/icons/Icon_Null-Search.svg" alt="0 results"/>
              <div class="text">
                <span class="main-title">No results.</span>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <app-related-content
      *ngIf="relatedContentList?.length > 0"
      [relatedContentList] = "relatedContentList"
      [sidebarOpen] = "sidebarOpen"
    ></app-related-content>
  </div>
</section>
