import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GenericPanelAction } from '@app/@shared/models/generic-panel/generic-panel.model';

@Component({
  selector: 'app-generic-panel',
  templateUrl: './generic-panel.component.html',
  styleUrls: ['./generic-panel.component.scss'],
})
export class GenericPanelComponent {
  @Input() actions: Array<GenericPanelAction>;
  @Input() amount: number;
  @Input() totalSize: number;

  @Output() allSelected = new EventEmitter<boolean>();
  @Output() clickAction = new EventEmitter<any>();

  selectAll(event: { target: HTMLInputElement }) {
    event.target.checked = this.amount <= 0;
    this.allSelected.emit(event.target.checked);
  }

  onClickAction(action: GenericPanelAction): void {
    this.clickAction.emit(action);
    this.allSelected.emit(false);
  }
}
