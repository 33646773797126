import { Injectable } from '@angular/core';
import { Class } from '@app/@shared/utils/class';
import { ConfirmationModalComponent } from '@app/settings/components/confirmation-modal/confirmation-modal.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GenericDialogComponent } from '@shared/components/generic-dialog/generic-dialog.component';

export const GENERIC_MODAL_WINDOW_CLASS = 'generic-dialog';

// Deprecated: Only used by deprecated WarningModalComponent
export const DEFAULT_MODAL_OPTIONS: NgbModalOptions = {
  windowClass: 'modal-notification',
  size: 'md',
  centered: true,
};

export interface DialogOptions {
  heading?: string;
  title?: string;
  titleIconClass?: string;
  body?: string;
  bodyTitle?: string;
  bodyList?: string[];
  closeButtonLabel?: string;
  closeButtonIconClass?: string;
  actionButtonLabel?: string;
  actionButtonIconClass?: string;
  titleInformationLabel?: string;
  isSubscription?: boolean;
}

export const DEFAULT_ERROR_OPTIONS: DialogOptions = {
  titleIconClass: 'icon-warning',
  title: 'Oops... Something went wrong',
  body: 'Sorry, we could not send your request due to a technical problem. ' +
    'If this problem persists please try again in a few minutes.',
  closeButtonLabel: 'Close',
};

export const DEFAULT_SEARCH_WARNING_OPTIONS: DialogOptions = {
  title: 'Clear all active filters and change search category?',
  body: 'Active search terms and search results will be cleared.',
  actionButtonLabel: 'Change search category',
  closeButtonLabel: 'Cancel',
};

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  constructor(
    private modalService: NgbModal,
  ) {}

  /**
   * open is wrapper for NgbModal.open so you don't need both services in a component.
   * @param dialogComponent Component to show in the modal
   * @param modalOptions NgbModalOptions for the modal
   * @param props Properties to pass to the component
   */
  public open<T>(
    dialogComponent: Class<T>,
    modalOptions?: NgbModalOptions,
    props: Partial<T> = {},
  ): NgbModalRef {
    const modalRef = this.modalService.open(dialogComponent, {
      ...(modalOptions ?? DEFAULT_MODAL_OPTIONS),
    });
    Object.assign(modalRef.componentInstance, props);
    return modalRef;
  }

  /**
   * openGeneric shows a generic dialog with a heading, title, body, and buttons.
   *  See GenericDialogComponent.
   * @param dialogOptions DialogOptions for the dialog
   */
  public openGeneric(
    dialogOptions: DialogOptions,
  ): NgbModalRef {
    const modalRef = this.modalService.open(GenericDialogComponent, {
      windowClass: GENERIC_MODAL_WINDOW_CLASS,
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.dialogOptions = dialogOptions;
    return modalRef;
  }

  /**
   * openError shows a generic error dialog with an heading, icon, title, body, and buttons.
   *  See GenericDialogComponent.
   * @param dialogOptions Optional DialogOptions for the dialog, defaults to DEFAULT_ERROR_OPTIONS
   */
  public openError(
    dialogOptions?: DialogOptions,
  ): NgbModalRef {
    const modalRef = this.modalService.open(GenericDialogComponent, {
      windowClass: GENERIC_MODAL_WINDOW_CLASS,
      size: 'lg',
      centered: true,
      keyboard: true,
    });
    modalRef.componentInstance.dialogOptions = {
      ...(dialogOptions ?? DEFAULT_ERROR_OPTIONS),
      titleIconClass: 'icon-warning',
      closeButtonLabel: 'Close',
      actionButtonLabel: null,
    };
    return modalRef;
  }

  public openConfirmationModal(){
    const modalInstance = this.open(
      ConfirmationModalComponent,
      { size: 'sm', centered: true, windowClass: 'uui-modal' }
    );

    modalInstance.componentInstance.props.title = 'Are you sure you want to unfollow?';
    modalInstance.componentInstance.props.body = 'You will no longer receive notification updates for this analysis.';
    modalInstance.componentInstance.props.actionButtonLabel = 'Unfollow';
    return modalInstance;
  }

}
