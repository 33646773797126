import { SelectableRow } from '../content-navigator/content-navigator.model';
import { AnalysisFilter } from '../reverse-engineering/analysis.models';

export interface CTISearchRequest {
  chips: string[];
  filter: CTISearchFilter;
  from?: number;
  size?: number;
  sort: CTISearchSort;
  showHighlights?: boolean;
};

export interface CTISearchSort {
  type: string;
  order: string;
}

export enum CTIContentType {
  REPORT = 'report',
  BLOG = 'blog',
  VIDEO = 'video',
}

export const defaultCTIContentTypeFilters: Array<CTIContentType> = [
  CTIContentType.BLOG,
  CTIContentType.REPORT,
  CTIContentType.VIDEO
];

export enum CTIShowContentType {
  REPORT = 'Analysis',
  BLOG = 'Blog',
  VIDEO = 'Video',
}

export interface CTISearchBaseResult {
  id: string;
  name: string;
  channelId: string;
  channelName: string;
  subscriptionId: string;
  subscriptionName: string;
  contentType: `${CTIContentType}`;
  description: string;
};

export interface CTISearchResult {
  id: string;
  name: string;
  code: string;
  channelId: string;
  channelName: string;
  subscriptionId: string;
  subscriptionName: string;
  releaseDate: string;
  analysisStartDate: string;
  publishDate?: string;
  analysisYear: number;
  description: string;
  deviceManufacturer: string;
  deviceName: string;
  deviceType: string;
  downstreamProduct: string;
  reportType: string;
  reportHandle: string;
  reportModule: string;
  analysisEndDate: string;
  primaryItemCodeCommonName: string;
  productBenefitStatement: string;
  workCodeCategory: string;
  vicCode: string;
  contentType: `${CTIContentType.REPORT}`;
  contentCategory: string;
  entitled: boolean;
  free: boolean;
  flavor: string[];
  isFinal: boolean;
  documentsCount: number;
  imagesCount: number;
  baseAssetGroup: string;
  pmAdvertisedPublishedDate?: string;
  nsSubProjectStartDate?: string;
}

export interface CTIBlogSearchResult {
  id: string;
  channelId: string;
  channelName: string;
  subscriptionId: string;
  subscriptionName: string;
  releaseDate: string;
  authorName: string[];
  author: {
    id: string;
    name: string;
    avatarList: {['24']: string, ['48']: string, ['96']: string};
  };
  name: string;
  description: string;
  tags: string;
  entitled: boolean;
  contentType: `${CTIContentType.BLOG}`;
  link: string;
}

export interface CTIVideoSearchResult {
  id: string;
  contentType: `${CTIContentType.VIDEO}`;
  name: string;
  wistiaId: string;
  wistiaUrl: string;
  thumbnailUrl: string;
  description: string;
  viewCount: number;
  entitled: boolean;
  duration: number;
  free: boolean;
  channelId: string;
  channelName: string;
  subscriptionId: string;
  subscriptionName: string;
  wistiaCreateDate: string;
  wistiaUpdateDate: string;
  createDate: string;
  updateDate: string;
}

export interface CTISearchResults<T extends CTISearchBaseResult> {
  total: number;
  results: T[];
  filterSuggestions: CTIFilterResponse[];
}

export interface CTISearchFilter {
  [x: string] : string | string[] | boolean;
}

export interface CTISearchResponse {
  total: number;
  documents: any[];
  lastValues: number[];
  filterSuggestions: CTIFilterResponse[];
}

export interface PublicCTISearchResponse {
  total: number;
  documents: any[];
}

export interface CTIFilterResponse {
  key: string;
  values: CTIFilterValue[];
}

export interface CTIFilterValue {
  value: string;
  amount: number;
}

export interface ChannelCount {
  channel: string;
  count: number;
}

export interface ContentPaginatedList {
  contentList: Array<BaseContent>;
  count: number;
  filters?: AnalysisFilter[];
}
export abstract class BaseContent implements SelectableRow {
  public selected: boolean;
  public contentUrl: string[];
  public contentHref?: string;
  public assetLocation: string;
  constructor(
    public id: string,
    public type: string,
    public showType: string,
    public name: string,
    public description: string,
    public chanelId: string,
    public chanelName: string,
    public subscriptionId: string,
    public subscriptionName: string,
    public entitled: boolean,
    public completionDate: Date,
  ){}
}
export class ReportContent extends BaseContent {
  public documentsCount: number;
  public imagesCount: number;
  public productCode: string;
  public deviceManufacturer: string;
  public deviceName: string;
  public deviceType: string;
  constructor(analysis: CTISearchResult){
    super(analysis.id, CTIContentType.REPORT, CTIShowContentType.REPORT, analysis.name, analysis.description,
      analysis.channelId, analysis.channelName, analysis.subscriptionId, analysis.subscriptionName, analysis.entitled,
      analysis.analysisEndDate ? new Date(analysis.analysisEndDate) : null
    );
    this.documentsCount = analysis.documentsCount;
    this.imagesCount = analysis.imagesCount;
    this.productCode = analysis.code ?? '';
    this.deviceManufacturer = analysis.deviceManufacturer;
    this.deviceName = analysis.deviceName;
    this.deviceType = analysis.deviceType;
    this.contentUrl = ['analysis-view', this.productCode];
    this.assetLocation = analysis.subscriptionName;
  }
}
export class BlogContent extends BaseContent {
  public author: string;
  constructor(blog: CTIBlogSearchResult){
    super(blog.id, CTIContentType.BLOG, CTIShowContentType.BLOG, blog.name, blog.description, blog.channelId,
      blog.channelName, blog.subscriptionId, blog.subscriptionName, blog.entitled,
      blog.releaseDate ? new Date(blog.releaseDate) : null
    );
    this.author = blog.author?.name ?? '';
    this.contentUrl = ['blog-viewer', blog.id];
    this.assetLocation = blog.subscriptionName;
  }
}
export class VideoContent extends BaseContent {
  public thumbnailUrl: string;
  constructor (video: CTIVideoSearchResult){
    super(video.id, CTIContentType.VIDEO, CTIShowContentType.VIDEO, video.name, video.description, video.channelId,
      video.channelName, video.subscriptionId, video.subscriptionName, video.entitled,
      video.wistiaCreateDate ? new Date(video.wistiaCreateDate) : null
    );
    this.thumbnailUrl = video.thumbnailUrl;
    this.contentUrl = ['video-viewer', video.id];
    this.assetLocation = video.channelName;
  }
}
