import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PortalModule } from '@angular/cdk/portal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LottieComponent  } from 'ngx-lottie';

import { ClickOutsideDirective } from 'src/app/@shared/directives/click-outside/clickoutside.directive';
import { DateAgoPipe } from './pipes/date-ago/date-ago.pipe';
import { OrderByPipe } from './pipes/list-order-by/order-by.pipe';
import { LoaderComponent } from './loader/loader.component';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { NotificationItemComponent } from './components/notification-item/notification-item.component';
import { GenericSearchBarComponent } from './components/generic-search-bar/generic-search-bar.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { RadioComponent } from './components/radio/radio.component';
import { ProgressBarCircularComponent } from 'src/app/@shared/components/progress-bar-circular/progress-bar-circular.component';
import { SwitchComponent } from './components/switch/switch.component';
import { ImageComponent } from './components/image/image.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ItSelectComponent } from './components/it-select/it-select.component';
import { MultiTabHeaderComponent } from './components/multi-tab-header/multi-tab-header.component';
import { IframeModalComponent } from './components/iframe-modal/iframe-modal.component';
import { CardComponent } from './components/card/card.component';
import { ModuleHeaderComponent } from './components/module-header/module-header.component';
import { SubscriptionCardListComponent } from '@shared/components/subscription-card-list/subscription-card-list.component';
import { PaginationBottomComponent } from './components/pagination-bottom/pagination-bottom.component';
import { PaginationTopComponent } from './components/pagination-top/pagination-top.component';
import { ContentNavigatorMultiTabComponent } from './components/content-navigator/content-navigator-multi-tab/content-navigator-multi-tab.component';
import { ContentNavigatorTableComponent } from './components/content-navigator/content-navigator-table/content-navigator-table.component';
import { SAContentNavigatorTableComponent } from './components/content-navigator/sa-content-navigator-table/sa-content-navigator-table.component';
import { REContentNavigatorTableComponent } from './components/content-navigator/re-content-navigator-table/re-content-navigator-table.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { VideoPlayerComponent } from './components/videos/video-player/video-player.component';
import { BlogViewerComponent } from '@shared/components/tech-stream/blog-viewer/blog-viewer.component';
import { BlogLoaderComponent } from '@shared/components/tech-stream/blog-loader/blog-loader.component';
import { BlogCardComponent } from '@shared/components/tech-stream/blog-card/blog-card.component';
import { VideoViewerComponent } from '@shared/components/videos/video-viewer/video-viewer.component';
import { TitleBarComponent } from '@shared/components/title-bar/title-bar.component';
import { VideoCardComponent } from '@shared/components/videos/video-card/video-card.component';
import { ContentListViewComponent } from '@shared/components/content-list-view/content-list-view.component';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { ChannelsCardListComponent } from '@shared/components/channels-card-list/channels-card-list.component';
import { TextAreaComponent } from '@shared/components/text-area/text-area.component';
import { PlatformEntitlementRequestModalComponent } from '@shared/components/platform-entitlement-request-modal/platform-entitlement-request-modal.component';
import { GenericToolbarModule } from '@shared/components/generic-toolbar/generic-toolbar.module';
import { FilterSidebarComponent } from './components/filter-sidebar/filter-sidebar.component';
import { ListFilterComponent } from './components/filter-sidebar/list-filter/list-filter.component';
import { SimpleFilterComponent } from './components/filter-sidebar/simple-filter/simple-filter.component';
import { MarketPdfViewerComponent } from '@shared/components/market-pdf-viewer/market-pdf-viewer.component';
import { ImageViewerModule } from '@shared/components/image-viewer/image-viewer.module';
import { ProductOverviewComponent } from './components/product-overview/product-overview.component';
import { RecentVideoSectionComponent } from './components/recent-video-section/recent-video-section.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { GenericDialogComponent } from '@shared/components/generic-dialog/generic-dialog.component';
import { WarningModalComponent } from './components/warning-modal/warning-modal.component';
import { PowerSearchTabsComponent } from './components/power-search-tabs/power-search-tabs.component';
import { MetadataPanelModule } from './components/metadata-panel/metadata-panel.module';
import { ShareItemModalModule } from './components/share-item-modal/share-item-modal.module';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { TabbedAnalysisListComponent } from './components/analysis/tabbed-analysis-list/tabbed-analysis-list.component';
import { ChannelAnalysisComponent } from './components/analysis/channel-analysis/channel-analysis.component';
import { DownloadPanelComponent } from './components/image-views/download-panel/download-panel.component';
import { DownloadErrorModalComponent } from './components/download-error-modal/download-error-modal.component';
import { DownloadProgressModalComponent } from './components/download-progress-modal/download-progress-modal.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { AnalysisBrowserComponent } from './components/analysis-browser/analysis-browser.component';
import { SearchChipComponent } from '@app/tech-library/components/search-bar/search-input/search-chip/search-chip.component';
import { NoSanitizePipe, NoSanitizeUrlPipe } from '@shared/utils/nosanitizepipe';
import { CategoriesDropdownComponent } from './components/categories-dropdown/categories-dropdown.component';
import { TableauIframeComponent } from './components/tableau-iframe/tableau-iframe.component';
import { StaticSearchBannerComponent } from './components/static-search-banner/static-search-banner.component';

import { PdfViewerNotesPanelHeaderComponent } from './components/pdf-viewer/pdf-viewer-notes-panel-header/pdf-viewer-notes-panel-header.component';
import { AlertBarComponent } from './components/alert-bar/alert-bar.component';
import { TableViewerPageComponent } from './components/table-viewer-page/table-viewer-page.component';
import { TableViewerModule } from './components/table-viewer/table-viewer.module';
import { PdfViewerFiltersModalComponent } from './components/pdf-viewer/pdf-viewer-filters-modal/pdf-viewer-filters-modal.component';
import { DigestFrequencyRadioComponent } from './components/digest-frequency-radio/digest-frequency-radio.component';
import { CrossLinkingComponent } from './components/cross-linking/cross-linking.component';
import { AnalysisSummaryComponent } from './components/analysis-summary/analysis-summary.component';
import {
  PaContentNavigatorTableComponent
} from '@shared/components/content-navigator/pa-content-navigator-table/pa-content-navigator-table.component';
import {
  MccleanContentNavigatorTableComponent
} from '@shared/components/content-navigator/mcclean-content-navigator-table/mcclean-content-navigator-table.component';
import { NoFillColourPickerComponent } from './components/no-fill-colour-picker/no-fill-colour-picker.component';
import { TechstreamAreaComponent } from './components/techstream-area/techstream-area.component';
import { GroupFilterComponent } from './components/filter-sidebar/group-filter/group-filter.component';
import { DocumentPdfLoaderComponent } from './components/document-pdf-loader/document-pdf-loader.component';
import { MeatballMenuComponent } from './components/meatball-menu/meatball-menu.component';
import { RedirectToAssetComponent } from './components/redirect-to-asset/redirect-to-asset.component';
import { BlogSearchComponent } from './components/tech-stream/blog-search/blog-search.component';
import { TabbedBlogListComponent } from './components/analysis/tabbed-blog-list/tabbed-blog-list/tabbed-blog-list.component';
import { CheckboxBulkActionsComponent } from './components/checkbox-bulk-actions/checkbox-bulk-actions.component';
import { ContentExportComponent } from './components/content-export/content-export.component';
import { ContentCardComponent } from '@shared/components/content-card/content-card.component';
import { ContentCardContainerComponent } from '@shared/components/content-card-container/content-card-container.component';
import { PaginationHighlightComponent } from './components/pagination-highlight/pagination-highlight.component';
import { AutomativeIcDesignWinsTableViewerPageComponent } from './components/automative-ic-design-wins-table-viewer-page/automative-ic-design-wins-table-viewer-page.component';
import { RecentViewTableComponent } from './components/recent-view-table/recent-view-table.component';
import { RecentActivityBrowserComponent } from './components/recent-activity-browser/recent-activity-browser.component';
import {
  TableauIframeViewComponent
} from '@shared/components/tableau-iframe-view/tableau-iframe-view.component';
import { ConfirmationModalWithSaveStateComponent } from './components/confirmation-modal-with-save-state/confirmation-modal-with-save-state.component';
import { RecentTypeaheadContainerComponent } from '@shared/recent-typeahead-container/recent-typeahead-container.component';
import { UserSettingsShortcutsComponent } from './components/user-settings-shortcuts/user-settings-shortcuts/user-settings-shortcuts.component';
import { GenericPanelComponent } from './components/generic-panel/generic-panel.component';
import { GenericIframeLandingPageComponent } from './components/generic-iframe-landing-page/generic-iframe-landing-page.component';
import {
  ChannelsAnalyticsDropdownComponent
} from '@shared/components/channels-analytics-dropdown/channels-analytics-dropdown.component';
import { MarketSummaryContentNavigatorTableComponent } from './components/content-navigator/market-summary-content-navigator-table/market-summary-content-navigator-table.component';
import { HomeContentNavigatorTableComponent } from './components/content-navigator/home-content-navigator-table/home-content-navigator-table/home-content-navigator-table.component';
import { RequestImageModalComponent } from '@shared/components/request-image-modal/request-image-modal.component';
import { ContentNavigatorAreaComponent } from '@app/@shared/components/content-navigator/content-navigator-area/content-navigator-area.component';
import { RoadmapRequestAccessComponent } from './components/roadmap-request-access/roadmap-request-access.component';
import { RoadmapTabContainerComponent } from './components/roadmap/roadmap-tab-container.component';
import { ChannelsCardAreaComponent } from './components/channels-card-area/channels-card-area.component';
import { AbstractImageViewerComponent } from './components/abstract/abstract-image-viewer/abstract-image-viewer/abstract-image-viewer.component';
import { SubscriptionVideosPageComponent } from './components/videos/subscription-videos-page/subscription-videos-page.component';
import { TabbedVideosListComponent } from './components/analysis/tabbed-videos-list/tabbed-videos-list.component';
import { AbstractSubscriptionContentsComponent } from './components/abstract/abstract-subscription-contents/abstract-subscription-contents.component';
import { ReplacePipe } from './pipes/replace/replace.pipe';
import { AbstractVerticalComponent } from './components/abstract/abstract-vertical/abstract-vertical.component';
import { RetoolIframeComponent } from './components/retool-iframe/retool-iframe.component';
import {DatabaseToolAreaComponent} from '@shared/components/database-tool-area/database-tool-area.component';
import { ImageCalibrationModalComponent } from './components/image-viewer/image-calibration-modal/image-calibration-modal.component';
import { ThumbnailPipe } from './pipes/thumbnail/thumbnail.pipe';
import { RetoolBackNavigationComponent } from './components/retool-back-navigation/retool-back-navigation.component';
import {
  DatabaseToolsTableauContainerComponent
} from '@shared/components/database-tools-tableau-container/database-tools-tableau-container.component';
import { GenericErrorComponent } from './components/generic-error/generic-error.component';
import { QuicksightViewerComponent } from './components/quicksight-viewer/quicksight-viewer.component';
import { ListAllComponent } from './components/analysis/list-all/list-all.component';
import {HygraphComponent} from '@shared/components/hygraph/hygraph.component';
import {HygraphHeaderComponent} from '@shared/components/hygraph/hygraph-header/hygraph-header.component';
import { HygraphNavigationComponent } from '@shared/components/hygraph/hygraph-navigation/hygraph-navigation.component';
import { HygraphViewerComponent } from '@shared/components/hygraph/hygraph-viewer/hygraph-viewer.component';
import { ImageModalComponent } from './components/hygraph/image-modal/image-modal.component';
import { HygraphRelatedContentComponent } from './components/hygraph/related-content/related-content.component';
import {RelatedContentComponent} from '@shared/components/analysis/related-content/related-content.component';
import { TextScriptsPipe } from './pipes/text-scripts/text-scripts.pipe';
import {PreloginAccessModalComponent} from '@shared/components/prelogin-access-modal/prelogin-access-modal.component';
import {CookieLinkComponent} from '@shared/components/cookie-link/cookie-link.component';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

export const DIRECTIVES: any[] = [ClickOutsideDirective];

export const PIPES: any[] = [
  DateAgoPipe,
  OrderByPipe,
  NoSanitizePipe,
  NoSanitizeUrlPipe,
  ReplacePipe,
  ThumbnailPipe,
  TextScriptsPipe
];

export const COMPONENTS: any[] = [
  AbstractVerticalComponent,
  AutomativeIcDesignWinsTableViewerPageComponent,
  LoaderComponent,
  NotificationItemComponent,
  CustomToastComponent,
  DropdownComponent,
  GenericSearchBarComponent,
  CheckboxComponent,
  RadioComponent,
  ProgressBarCircularComponent,
  SwitchComponent,
  AvatarComponent,
  ImageComponent,
  ItSelectComponent,
  MultiTabHeaderComponent,
  IframeModalComponent,
  CardComponent,
  ModuleHeaderComponent,
  SubscriptionCardListComponent,
  PaginationBottomComponent,
  PaginationTopComponent,
  ContentNavigatorMultiTabComponent,
  ContentNavigatorTableComponent,
  PaContentNavigatorTableComponent,
  SAContentNavigatorTableComponent,
  REContentNavigatorTableComponent,
  VideoPlayerComponent,
  BlogViewerComponent,
  BlogLoaderComponent,
  BlogCardComponent,
  VideoViewerComponent,
  TitleBarComponent,
  VideoCardComponent,
  ContentListViewComponent,
  ChannelsCardListComponent,
  BreadcrumbComponent,
  TextAreaComponent,
  PlatformEntitlementRequestModalComponent,
  MarketPdfViewerComponent,
  ProductOverviewComponent,
  ListFilterComponent,
  SimpleFilterComponent,
  FilterSidebarComponent,
  RecentVideoSectionComponent,
  TabBarComponent,
  GenericDialogComponent,
  WarningModalComponent,
  PowerSearchTabsComponent,
  PdfViewerComponent,
  PdfViewerFiltersModalComponent,
  PdfViewerNotesPanelHeaderComponent,
  TabbedAnalysisListComponent,
  ChannelAnalysisComponent,
  DownloadPanelComponent,
  DownloadErrorModalComponent,
  DownloadProgressModalComponent,
  SearchResultsComponent,
  AnalysisBrowserComponent,
  SearchChipComponent,
  CategoriesDropdownComponent,
  TableauIframeComponent,
  StaticSearchBannerComponent,
  AlertBarComponent,
  TableViewerPageComponent,
  DigestFrequencyRadioComponent,
  CrossLinkingComponent,
  AnalysisSummaryComponent,
  NoFillColourPickerComponent,
  TechstreamAreaComponent,
  MccleanContentNavigatorTableComponent,
  GroupFilterComponent,
  DocumentPdfLoaderComponent,
  MeatballMenuComponent,
  RedirectToAssetComponent,
  BlogSearchComponent,
  TabbedBlogListComponent,
  CheckboxBulkActionsComponent,
  ContentExportComponent,
  ContentCardComponent,
  ContentCardContainerComponent,
  PaginationHighlightComponent,
  RecentViewTableComponent,
  RecentActivityBrowserComponent,
  ConfirmationModalWithSaveStateComponent,
  TableauIframeViewComponent,
  RecentTypeaheadContainerComponent,
  UserSettingsShortcutsComponent,
  GenericPanelComponent,
  GenericIframeLandingPageComponent,
  ChannelsAnalyticsDropdownComponent,
  MarketSummaryContentNavigatorTableComponent,
  HomeContentNavigatorTableComponent,
  RequestImageModalComponent,
  RoadmapRequestAccessComponent,
  RoadmapTabContainerComponent,
  SubscriptionVideosPageComponent,
  TabbedVideosListComponent,
  AbstractSubscriptionContentsComponent,
  ContentNavigatorAreaComponent,
  ChannelsCardAreaComponent,
  RetoolIframeComponent,
  DatabaseToolAreaComponent,
  AbstractImageViewerComponent,
  ImageCalibrationModalComponent,
  RetoolBackNavigationComponent,
  DatabaseToolsTableauContainerComponent,
  RelatedContentComponent,
  GenericErrorComponent,
  QuicksightViewerComponent,
  ListAllComponent,
  HygraphComponent,
  HygraphHeaderComponent,
  HygraphNavigationComponent,
  HygraphViewerComponent,
  ImageModalComponent,
  HygraphRelatedContentComponent,
  PreloginAccessModalComponent,
  CookieLinkComponent,
];

export const MODULES: any[] = [
  CommonModule,
  RouterModule,
  NgbModule,
  FormsModule,
  ReactiveFormsModule,
  ScrollingModule,
  PortalModule,
  NgSelectModule,
  InfiniteScrollModule,
  GenericToolbarModule,
  ImageViewerModule,
  MetadataPanelModule,
  ShareItemModalModule,
  TableViewerModule,
];

@NgModule(
  {
    imports: [
      ...MODULES,
      NgOptimizedImage,
      LottieComponent,
    ],
    declarations: [
      ...COMPONENTS,
      ...PIPES,
      ...DIRECTIVES,
    ],
    exports: [
      LottieComponent,
      ...COMPONENTS,
      ...PIPES,
      ...DIRECTIVES,
      ...MODULES,
    ],
  }
)
export class SharedModule {}
