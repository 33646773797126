import {Component, Inject} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {Router, ActivatedRoute, } from '@angular/router';
import {
  AbstractSubscriptionContentsComponent,
} from '@shared/components/abstract/abstract-subscription-contents/abstract-subscription-contents.component';
import {TelemetryService} from '@shared/services/telemetry-service';
import {NavigationService} from '@shared/services/navigation/navigation.service';
import {SearchService} from '@shared/services/search/search.service';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: [ './header-bar.component.scss' ],
})
export class HeaderBarComponent extends AbstractSubscriptionContentsComponent {
  public isOpened = false;
  public title: string;
  public activeMenu: string;
  public recentSearchTerms: string[];
  private menuItems: Map<string, string>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    router: Router,
    telemetryService: TelemetryService,
    route: ActivatedRoute,
    navigationService: NavigationService,
    private searchService: SearchService,
  ) {
    super(route, router, navigationService, telemetryService);
    this.menuItems = new Map();
    this.menuItems.set('help', 'Help and Resources');
    this.menuItems.set('profile', 'My Profile');
    // Fix for the notification counter position over the notifications button.
    // https://techinsights.atlassian.net/browse/CP-13247
    // Using this style override in the component CSS and even in the
    // Beamer appearance settings doesn't work, including the more specific
    // div[style].beamer_icon.
    const widgetStyleOverride = this.document.createElement('style');
    widgetStyleOverride.innerHTML = '.beamer_icon { inset: 14px 22px !important; }';
    this.document.head.appendChild(widgetStyleOverride);
    this.getRecentSearches();
  }

  public openMenu(key: string): void {
    this.isOpened = true;
    this.activeMenu = key;
    this.title = this.menuItems.get(this.activeMenu);
  }

  public closeMenu(): void {
    this.isOpened = false;
  }

  public onClickSettings(): void{
    this.router.navigate([`./settings/my-entitlements`]);
  }

  public onClickLogo(): void{
    this.router.navigate(['/home'])
  }

  override onChangeSearchTerms(term: string) {
    if(term.length) {
      this.router.navigate(['search/result/ALL'], {
        queryParams: { q: term, searchKey: 'header-search' },
        preserveFragment: true,
        queryParamsHandling: 'merge',
      });
    }
  }

  protected requestContent(): any {};
  protected getContext(): any {};

  private getRecentSearches() {
    this.recentSearchTerms = this.searchService.getContextSearchTerms('ALL', 'main-search');
  }

}
