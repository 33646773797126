import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { VendorIntegrationService } from '@app/@shared/services/vendor-integration.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { EmbeddedURLModel } from '@shared/models/embedded-url.model';
import { EmbeddedURLRequestModel } from '@shared/models/embedded-url-request.model';
import { environment } from '@env/environment';

@Component({
  selector: 'app-retool-iframe',
  templateUrl: './retool-iframe.component.html',
  styleUrls: ['./retool-iframe.component.scss']
})
@UntilDestroy()
export class RetoolIframeComponent implements OnInit {
  @Input() embeddedURLRequest: EmbeddedURLRequestModel;
  public embeddedUrl: SafeResourceUrl;
  public loading = true;

  constructor(
    private route: ActivatedRoute,
    protected vendorIntegrationService: VendorIntegrationService,
    private readonly sanitizer: DomSanitizer
  ) {
  }

  public ngOnInit(): void {
    if(!this.embeddedURLRequest) {
      this.embeddedURLRequest = {
        appId: this.route.snapshot.params?.appId,
      }
    }

    this.loadEmbeddedUrl(this.embeddedURLRequest);
  }

  private loadEmbeddedUrl(embeddedURLRequestModel: EmbeddedURLRequestModel) {
    this.loading = true;
    this.vendorIntegrationService
      .getEmbeddedURL(embeddedURLRequestModel)
      .pipe(
        catchError(_err => {
          this.loading = false;
          return of({ 'embedUrl': '' });
        }),
        untilDestroyed(this)
      ).subscribe((embeddedUrl: EmbeddedURLModel) => {
      this.loading = false;

      this.embeddedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getSafeUrl(embeddedUrl.embedUrl));
    });
  }

  private getSafeUrl(url: string): string {
    if (url.startsWith(environment.retoolUrl)) {
      return url;
    }

    return '';
  }
}
