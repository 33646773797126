import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogExcerpt } from '@app/@shared/models/market-analysis/market-analysis';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { Filters } from '@app/@shared/models/reverse-engineering/analysis.models';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';

@Component({
  selector: 'app-tabbed-blog-list',
  templateUrl: './tabbed-blog-list.component.html',
  styleUrls: ['./tabbed-blog-list.component.scss']
})
export class TabbedBlogListComponent {
  @Input() blogs: BlogExcerpt[];
  @Input() blogCount: number;

  @Output() changePaginationOptions = new EventEmitter<PaginationParameters>();
  @Output() clickBlog = new EventEmitter<any>();

  paginationOptions = new PaginationParameters(50, 1);
  isLoading = true;
  searchTerm: string;
  filters: Filters;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entitlementService: EntitlementService,
  ) { }


  public onClickBlog(blog: BlogExcerpt) {
    if(blog.entitled) {
      this.router.navigate(['blog-viewer', blog.id], { relativeTo: this.route.parent })
    } else {
      this.entitlementService.openRequestAccessConfirmDialog(`TechStream: ${blog.category}: ${blog.title}`);
    }
  }
}
