import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DropdownItem } from '@app/@shared/models/shared.models';

/*
Example Implementation:

.dropdown-container {
  width: 300px;
}

<div class="dropdown-container">
    <app-dropdown
    [itemList]="itemList"
    [selectedItem]="selectedItem"
    (itemSelected)="selectItem($event)"
    (dropdownOpened)="openDropdown()"
    (dropdownClosed)="closeDropdown()"
    ></app-dropdown>
  </div>
*/

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() itemList: DropdownItem[];
  @Input() selectedItem: DropdownItem;
  @Input() hideIconOnDropdown: boolean;
  @Input() titleSize = '24';

  @Output() changeDropdownItem: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();
  @Output() dropdownOpened: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();
  @Output() dropdownClosed: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();

  public isOpened = false;

  onClickOpenDropdown() {
    this.isOpened = true;
    this.dropdownOpened.emit();
  }

  onClickCloseDropdown() {
    this.isOpened = false;
    this.dropdownClosed.emit();
  }

  toggleDropdown() {
    if (this.isOpened) {
      this.onClickCloseDropdown();
      return;
    }
    this.onClickOpenDropdown();
  }

  onClickItem(item: DropdownItem) {
    this.selectedItem = item;
    this.changeDropdownItem.emit(item);
  }
}
