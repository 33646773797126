import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IRadioProps } from '@shared/components/radio/radio.component';

@Component({
  selector: 'app-digest-frequency-radio',
  templateUrl: './digest-frequency-radio.component.html',
  styleUrls: ['./digest-frequency-radio.component.scss'],
})
export class DigestFrequencyRadioComponent {
  @Input() set position(value: string) {
    if (value === 'vertical') {
      this.radioProps.class = 'radio-form-vertical';
    }
  }

  @Input() radioState:string;

  @Output() frequencyChange: EventEmitter<string> = new EventEmitter<string>();

  radioProps: IRadioProps = {
    groupName: 'frequencyRadio',
    class: 'radio-form-horizontal',
    items: [
    {
      key: 'never',
      class: 'custom-radio',
      placeholder: 'Never',
      label: 'Never'
    },
    {
      key: 'daily',
      class: 'custom-radio',
      placeholder: 'Daily',
      label: 'Daily'
    },
    {
      key: 'weekly',
      class: 'custom-radio',
      placeholder: 'Weekly',
      label: 'Weekly'
    }
  ]}

  handleFrequencyChange() {
    this.frequencyChange.emit(this.radioState);
  }
}
