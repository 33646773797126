<div class="modal-header">
  <h3 class="modal-title">Inquiry for Accessing Content</h3>
  <span>The content you are trying to access is part of a <i>paid subscription</i> service to which you are not currently entitled.<br/><br/>Complete the form below to explore subscription options for this content.</span>
  <button type="button" class="icon-close_medium" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<form [formGroup]="requestAccessFormModal">
  <div class="modal-body">
    <app-input [props]="inputPropsEmail" formControlName="email"></app-input>
    <app-input [props]="inputPropsSubject" formControlName="subject"></app-input>
    <app-text-area [props]="inputCommentProps" formControlName="topic" [disabled]="loading"></app-text-area>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-modal-secondary" (click)="modal.dismiss('close')">Cancel</button>
    <button type="submit" class="btn-modal-primary" (click)="onModalClose()">Send Subscription Inquiry</button>
  </div>
</form>
