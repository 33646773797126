import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadcrumbType } from '@app/@shared/models/shared.models';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() breadcrumb: Array<BreadcrumbType>;
  @Output() clickBreadcrumb = new EventEmitter<BreadcrumbType>();

  onClickBreadcrumb(crumb: BreadcrumbType) {
    this.clickBreadcrumb.emit(crumb);
  }
}
