export enum TelemetryEventName {
  EVENT_VIEW_IN_BROWSER = 'EVENT_VIEW_IN_BROWSER',
  EVENT_WP_VIEW_IN_BROWSER = 'EVENT_WP_VIEW_IN_BROWSER',
  EVENT_SEARCH = 'EVENT_SEARCH',
  EVENT_USER_LOGIN = 'EVENT_USER_LOGIN',
  EVENT_DOWNLOAD_JOB = 'EVENT_TISAPP_DOWNLOAD_SINGLE',
  EVENT_CONTENT_RATING_UPDATE = 'EVENT_CONTENT_RATING_UPDATE',
  EVENT_VIDEO_VIEW = 'EVENT_VIDEO_VIEW',
  EVENT_ANNOTATION_MESSAGE = 'EVENT_ANNOTATION_MESSAGE',
  EVENT_USER_INVITATION = 'EVENT_USER_INVITATION',
  EVENT_BLOG_VIEW = 'EVENT_BLOG_VIEW',
  EVENT_ORIGIN_CROSSLINKING = 'EVENT_ORIGIN_CROSSLINKING',
  EVENT_DESTINATION_CROSSLINKING = 'EVENT_DESTINATION_CROSSLINKING',

  EVENT_TISAPP_SEARCH = 'EVENT_TISAPP_SEARCH',
  EVENT_TISAPP_VIDEO_VIEW = 'EVENT_TISAPP_VIDEO_VIEW',
  EVENT_TISAPP_COPY_SHARE_URL = 'EVENT_TISAPP_COPY_SHARE_URL',
  EVENT_TISAPP_BLOG_VIEW = 'EVENT_TISAPP_BLOG_VIEW',
  UNIFIED_UI_SCHEMATIC_VIEW = 'UNIFIED_UI_SCHEMATIC_VIEW',
  UNIFIED_UI_REPORT_SINGLE_VIEW = 'UNIFIED_UI_REPORT_SINGLE_VIEW',
  UNIFIED_UI_REPORT_MULTI_VIEW = 'UNIFIED_UI_REPORT_MULTI_VIEW',
  UNIFIED_UI_DEVICE_DETAILS_PAGE = 'UNIFIED_UI_DEVICE_DETAILS_PAGE',
  UNIFIED_UI_SAVED_SEARCHES = 'UNIFIED_UI_SAVED SEARCHES',
  UNIFIED_UI_GENEALOGY_VIEW_OPEN = 'GENEALOGY VIEW OPEN',
  EVENT_CIRCUITVISION_REPORT_OPEN = 'EVENT_CIRCUITVISION_REPORT_OPEN',
  EVENT_QS_ASSET_VIEW = 'EVENT_QS_ASSET_VIEW',
  EVENT_HG_ASSET_VIEW = 'EVENT_HG_ASSET_VIEW',
  EVENT_HG_ASSET_DOWNLOAD = 'EVENT_TISAPP_DOWNLOAD_HG_SINGLE',

  // Event names used with Heap
  EVENT_HEAP_EULA_POPUP = 'EULA_Modal_Popup',
  EVENT_HEAP_ONBOARDING_POPUP = 'Onboarding_Modal_Popup',
  EVENT_HEAP_METERED_ACCESS_EMAIL_SUBMIT = 'Metered Access Email Submit',
  EVENT_HEAP_METERED_ACCESS_MODAL_OPENED = 'Metered Access Modal Pop-Up',

  // Use only for unit testing
  EVENT_UNIT_TEST = 'EVENT_UNIT_TEST',
}
