import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DBToolsSubscriptionCard, TableauConfig } from '@shared/models/shared.models';
import { PreviousPageService } from '@shared/services/previous-page.service';

@Component({
  selector: 'app-tableau-iframe-view',
  templateUrl: './tableau-iframe-view.component.html',
  styleUrls: [ './tableau-iframe-view.component.scss' ],
})
export class TableauIframeViewComponent{

  @Input() tableauConfig: TableauConfig;
  @Input() titleName = '';
  @Input() subscriptionName = '';
  @Input() cFootprintCard: DBToolsSubscriptionCard;
  @Input() cFootprintFFlag: boolean;
  @Output() requestAccess = new EventEmitter();

  constructor(
    public readonly prevPage: PreviousPageService,
  ) {}

  public onClickBack(): void {
    this.prevPage.navigateBack();
  }

  public onClickRequestAccess(): void {
    this.requestAccess.emit();
  }

}
