import {Component, Input} from '@angular/core';
import {BlogAuthorModel} from '@shared/models/blog-author.model';

@Component({
  selector: 'app-ask-the-author-list',
  templateUrl: './ask-the-author-list.component.html',
  styleUrls: ['./ask-the-author-list.component.scss']
})
export class AskTheAuthorListComponent{
  @Input() authors: BlogAuthorModel[] = [];
}
