<div class="metadata-container scrollbar">
  <ng-template ngFor let-metadataItem [ngForOf]="metadataList">
    <div class="metadata-item">
      <div class="key">
        {{ metadataItem.key }}
      </div>
      <div class="value">
        {{ metadataItem.value }}
      </div>
    </div>
  </ng-template>
</div>
