<div
  class="card-container"
  [class.max-columns]="channels?.length >= maxCardColumns"
>
  <div
    *ngFor="let channel of channels"
    class="channel"
    [class.heap-non-entitled-channel]="(channelEntitled(channel) | async) === false"
    (click)="onClickChannel(channel)"
  >
    <div
      class="card-icon"
    ></div>
    <div class="card-title truncate-ellipsis">{{ channel.title }}</div>
    <div *ngIf="showSubtitle" class="card-subtitle truncate-ellipsis">
      <span
        class="subtitle-items"
        *ngFor="let subtitleSegment of channel.getSubtitleSegments(); let first = first"
      >
        <span *ngIf="!first">|</span>
        {{ subtitleSegment.label }}
      </span>
    </div>
  </div>
</div>
