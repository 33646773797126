import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Image } from '@shared/models/image';

@Component({
  selector: 'app-thumbnail-bar',
  templateUrl: './thumbnail-bar.component.html',
  styleUrls: ['./thumbnail-bar.component.scss'],
})
export class ThumbnailBarComponent {
  @ViewChildren('thumbnailElement') thumbnailElements: QueryList<ElementRef>;
  @Input() thumbnails: Array<Image> = [];
  @Input() set selectedThumbnail(selected: Image) {
    this._selectedThumbnail = selected;
    const thumbnailElement = this.thumbnailElements?.find((x) => x.nativeElement.getAttribute('id') === selected.id);
    this.scrollIntoView(thumbnailElement);
  }
  get selectedThumbnail() {
    return this._selectedThumbnail;
  }
  @Output() clickThumbnail: EventEmitter<string> = new EventEmitter<string>();
  private _selectedThumbnail: Image;
  public onClickThumbnail(thumbnail: Image): void {
    this.selectedThumbnail = thumbnail;
    this.clickThumbnail.emit(thumbnail.id);
  }

  public onErrorThumbnail(event: any): void {
    event.target.src = 'assets/NotFound/Placeholder.svg';
    event.target.classList.add('error');
  }

  private scrollIntoView(elem: ElementRef) {
      elem?.nativeElement?.scrollIntoView({ inline: 'center' });
  }
}
