import {
  createAction,
  props
} from '@ngrx/store';
import { MenuModel } from '@app/store/Models/workspace/workspaceModel';
import { IPosition } from '@app/shell/components/nav-bar/nav.component';
import { IUserProfile } from '@app/store/Models/settings/settingsModel';
/**
 * Menu actions definitions
 *
 * Here goes all the actions that affects the menu on the nav-bar, ex: nav-bar state, nav-more-container position,
 * buttons state, which menu buttons to show, etc
 */
export enum WorkspaceActionTypes {
  setVisibleAmount = '[Menu] Set visible buttons amount',
  openMenuCollapsed = '[Menu] Open menu',
  closeMenuCollapsed = '[Menu] Close menu',
  toggleProfileDrawer = '[Menu] Toggle Profile Drawer',
  closeProfileDrawer = '[Menu] Close Profile Drawer',
  toggleHelpAndResourcesDrawer = '[Menu] Toggle Help And Resources',
  closeHelpAndResourcesDrawer = '[Menu] Close Help And Resources',
  setContainerMorePosition = '[Menu] Set Container More Position',
  toggleMoreContainer = '[Menu] Toggle Container More',
  setMoreContainerShownState = '[Menu] Set Container More Shown State',
  setMoreContainerIconByRoute = '[Menu] Set Container More Icon By Route',
  setSeenMenuItem = '[Menu] Set Seen Menu Item',
  loadWorkspace = '[Workspace] Load workspace',
  loadWorkspaceSuccess = '[Workspace] Load workspace success',
  loadWorkspaceFailure = '[Workspace] Load workspace failure',
  setUserProfile = '[Workspace] Set user profile',
}

export const setVisibleAmount = createAction(WorkspaceActionTypes.setVisibleAmount, props<{ amount: number }>());

export const toggleMoreContainer = createAction(WorkspaceActionTypes.toggleMoreContainer);

export const toggleProfileDrawer = createAction(WorkspaceActionTypes.toggleProfileDrawer);

export const closeProfileDrawer = createAction(WorkspaceActionTypes.closeProfileDrawer);

export const toggleHelpAndResourcesDrawer = createAction(WorkspaceActionTypes.toggleHelpAndResourcesDrawer);
export const closeHelpAndResourcesDrawer = createAction(WorkspaceActionTypes.closeHelpAndResourcesDrawer);

export const setMoreContainerShownState =
  createAction(WorkspaceActionTypes.setMoreContainerShownState, props<{ shown: boolean }>());

export const setMoreContainerIconByRoute =
  createAction(WorkspaceActionTypes.setMoreContainerIconByRoute, props<{ route: string }>());

export const setSeenMenuItem = createAction(WorkspaceActionTypes.setSeenMenuItem, props<{ id: string }>());

export const openMenu = createAction(WorkspaceActionTypes.openMenuCollapsed);
export const closeMenu = createAction(WorkspaceActionTypes.closeMenuCollapsed);

export const setContainerMorePosition = createAction(
  WorkspaceActionTypes.setContainerMorePosition,
  props<{ position: IPosition }>()
);

export const loadWorkspace = createAction(
  WorkspaceActionTypes.loadWorkspace
);

export const loadWorkspaceSuccess = createAction(
  WorkspaceActionTypes.loadWorkspaceSuccess,
  props<{ userProfile: IUserProfile, menu: MenuModel[] }>()
);

export const loadWorkspaceFailure = createAction(
  WorkspaceActionTypes.loadWorkspaceFailure,
   props<{ error: any }>()
);

export const setUserProfile = createAction(
  WorkspaceActionTypes.setUserProfile,
  props<{ userProfile : IUserProfile }>()
)