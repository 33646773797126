import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface ConfirmationModalConfig {
  headerTitle: string;
  bodyTitle: string;
  action: string;
  localStorageKey: string;
}

@Component({
  selector: 'app-confirmation-modal-with-save-state',
  templateUrl: './confirmation-modal-with-save-state.component.html',
  styleUrls: ['./confirmation-modal-with-save-state.component.scss'],
})
export class ConfirmationModalWithSaveStateComponent {
  @Input() config: ConfirmationModalConfig;
  hideModal: boolean;

  constructor(public modal: NgbActiveModal) {}

  onConfirm() {
    if (this.hideModal) {
      localStorage.setItem(this.config.localStorageKey, this.hideModal.toString());
    }
    this.modal.close('confirm');
  }
}
