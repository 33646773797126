export class CategoryMapper {
  static readonly DEFAULT_SUBSCRIPTION = 'default_subscription';
  static readonly AUTOMOTIVE_SENSING = 'automotive_sensing';
  static readonly IMAGE_SENSOR = 'image_sensor';
  static readonly IOT = 'iot';
  static readonly LOGIC = 'logic';
  static readonly MEMORY = 'memory';
  static readonly MEMORY_NAND = 'memory_nand';
  static readonly MOBILE_RF = 'mobile_rf';
  static readonly PACKAGING = 'packaging';
  static readonly POWER = 'power';
  static readonly TEARDOWN = 'teardown';
  static readonly PRICING = 'pricing';
  static readonly STORAGE = 'storage';
  static readonly BATTERY = 'battery';

  static readonly CATEGORIES = [
    CategoryMapper.DEFAULT_SUBSCRIPTION,
    CategoryMapper.AUTOMOTIVE_SENSING,
    CategoryMapper.IMAGE_SENSOR,
    CategoryMapper.IOT,
    CategoryMapper.LOGIC,
    CategoryMapper.MEMORY,
    CategoryMapper.MEMORY_NAND,
    CategoryMapper.MOBILE_RF,
    CategoryMapper.PACKAGING,
    CategoryMapper.POWER,
    CategoryMapper.TEARDOWN,
    CategoryMapper.PRICING,
    CategoryMapper.STORAGE,
    CategoryMapper.BATTERY
  ];

  /**
   * ONLY FOR TEST PURPOSE!
   */
  public static getRandomCategory(): string {
    return CategoryMapper.DEFAULT_SUBSCRIPTION;
  }

  public static sanitizeCategory(category: string): string {
    if(!!category && category !== '' && category !== 'null') {
      for(const innerCategory of CategoryMapper.CATEGORIES) {
        if(innerCategory === category) {
          return innerCategory;
        }
      }
    } else {
      return CategoryMapper.TEARDOWN;
    }
  }
}
