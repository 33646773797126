import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Measurement, MeasurementType } from '@app/@shared/models/kimera/measurement';

@Component({
  selector: 'app-kimera-generic-model-box',
  templateUrl: './kimera-generic-model-box.component.html',
  styleUrls: ['./kimera-generic-model-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export  class KimeraGenericModelBoxComponent {

  @Input() set measurement(measurement: Measurement){
    this.measurements = measurement.measurements;
    this.measurementType = measurement.measurementType;
    if(measurement.measurementType === MeasurementType.Area){
      this.measurements.push(this.measurements[0]*this.measurements[1]);
      this.measurements = this.measurements.map((m: number)=>this.convertToTwoDecimalPlaces(m));
      this.measurementText = `${this.measurements[2]} μm² (${this.measurements[0]} μm x ${this.measurements[1]} μm)`;
      this.iconClass = 'icon-mesure_area';
    }else{
      this.measurements[0] = this.convertToTwoDecimalPlaces(this.measurements[0]);
      this.measurementText = `${this.measurements[0]} μm`;
      this.iconClass = 'icon-ruler2';
    }
  }

  measurements: number[];
  measurementType: MeasurementType;
  iconClass: string;
  measurementText: string;
  footerText = 'Note: Layout objects are reconstructed. Sizes are approximate.';

  constructor(public modal: NgbActiveModal) { }

  private convertToTwoDecimalPlaces(number: number): number{
    return (parseFloat(number.toFixed(2)));
  }
}