/* eslint-disable no-console */
import { Component } from '@angular/core';
import {
  Toast,
  ToastPackage,
  ToastrService
} from 'ngx-toastr';
import {
  NotificationModel,
  NotificationTypeEnum
} from '@app/store/Models/notification/notificationModel';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';


/**
 * A Custom toast for the ngx-toastr library
 */
@Component({
  selector: '[custom-toastr]',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ transform: 'translateY(100%)' })),
      state('active', style({ transform: 'translateY(0%)' })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition(
        'active => removed',
        animate('{{ easeTime }}ms {{ easing }}')
      )
    ])
  ],
})
export class CustomToastComponent extends Toast {
  // used for demo purposes
  undoString = 'undo';

  // constructor is only necessary when not using AoT
  notification: NotificationModel;
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
    if(this.toastPackage.message) {
      try {
        this.notification = JSON.parse(this.toastPackage.message) as NotificationModel;
      } catch (e) {
        console.error('notification object', this.toastPackage.message);
        // unreadable notification
        this.notification = {
          title: 'Error with notification',
          body: 'Notification object unparseable',
          type: NotificationTypeEnum.COLLECTION,
          date: new Date(),
          read: false,
          id: '0'
        }
      }
    }
  }

  /**
   * Triggers a given action on click
   * @param event     the click event
   */
  action(event: Event) {
    event.stopPropagation();
    this.undoString = 'undid';
    this.toastPackage.triggerAction();
    return false;
  }
}
