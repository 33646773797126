<div class="form-group">
  <input
    [class]="props.class"
    [class.input-error]="_control.invalid && _control.touched"
    [class.load-value]="true"
    (focus)="onFocus() "
    (blur)="onBlur()"
    [(ngModel)]="value"
    [class.focus-value]="focused"
    (ngModelChange)="writeValue(value)"
    type="text"
    name="empty_input"
    [disabled]="props.disabled || disabled"
    [placeholder]="props.placeholder"
  />
  <label [class.label-error]="_control.invalid"
         for="empty_input">{{props.label}}</label>
</div>
