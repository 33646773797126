<section class="title-bar">
  <div *ngIf="showBackButton" class="back" (click)="onClickBack()">
    <em class="icon-left_small"></em>
    <div class="back-title">{{ backButtonTitle }}</div>
  </div>
  <div class="title-element">
    <div class="d-flex">
      <ng-template ngFor let-tagName [ngForOf]="tagNameList">
        <div class="vertical pretitle-text" [ngStyle]="{'background-color': tagColor}">{{ tagName }}</div>
      </ng-template>
    </div>
    <span class="header4-text truncate-paragraph-ellipsis line-clamp-2" [innerHTML]="title"></span>
  </div>
  <div class="upgrade-btn-container" *ngIf="dbToolCard?.entitled == false && cFootprintFF">
    <button class="upgrade-btn" (click)="onClickRequestAccess()">{{ upgradeBtnTitle }}</button>
  </div>
  <ng-content></ng-content>
</section>
