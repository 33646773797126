
<div class="checkbox-bulk-actions">
  <label class="checkbox it-checkbox" (click)="$event.stopPropagation()">
    <input
      type="checkbox"
      [checked]="checked"
      [indeterminate]="indeterminate"
      (change)="onAllSelectionChange($event)"
    />
    <div></div>
  </label>
  <div class="counting">{{ count }} Selected</div>
  <div class="actions">
    <ng-content></ng-content>
  </div>
</div>
