import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Analysis } from '@app/@shared/models/reverse-engineering/analysis.models';

@Component({
  selector: 'app-analysis-summary',
  templateUrl: './analysis-summary.component.html',
  styleUrls: ['./analysis-summary.component.scss'],
  animations: [
    trigger('expandAnimation', [
      state(
        'closed',
        style({
          height: 0,
        })
      ),
      state(
        'open',
        style({
          height: 0,
        })
      ),
      transition('open => closed', animate('.3s ease-in-out')),
      transition('closed => open', animate('.3s ease-in-out')),
    ]),
  ],
})
export class AnalysisSummaryComponent {

  @Input() set currentAnalysis(analysis: Analysis) {
    this.setMetadataList(analysis);
  };

  @ViewChild('descriptions') descriptions: ElementRef;

  public collapsed = true;
  public shortDescription = '';
  public longDescription = '';

  setMetadataList(analysis: Analysis): void {
    if (analysis) {
      this.shortDescription = analysis.description ?? '';
      this.longDescription = analysis.productBenefitStatement ?? '';
    }
  }

  onToggle() {
    if (this.isExpandable()) {
      this.collapsed = !this.collapsed;
    }
  }

  isExpandable() {
    return this.descriptions?.nativeElement?.offsetHeight ? this.descriptions.nativeElement?.offsetHeight > 144 : 0;
  }

}
