<button
  class="close"
  type="button"
  aria-describedby="modal-title"
  (click)="modal.close('Cross click')"
>
  <span aria-hidden="true">&times;</span>
</button>

<iframe  *ngIf="url"
  title="support-form"
  [src]="url"
></iframe>
