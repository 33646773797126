<div class="custom-modal-header">
  <button class="close-button" aria-label="Close" (click)="modal.dismiss('close')">
    <em class="icon-close_medium"></em>
  </button>
</div>
<div class="custom-modal-body">
  <h3>{{config.headerTitle}}</h3>
  <p>{{config.bodyTitle}}</p>
  <label class="it-checkbox">
    <input
      type="checkbox"
      [(ngModel)]="hideModal"
    />
    <div></div> <span>Don't show this message again</span>
  </label>
</div>
<div class="custom-modal-footer">
  <button class="btn cancel-button" (click)="modal.dismiss('cancel')">Cancel</button>
  <button class="btn submit-button" (click)="onConfirm()">{{config.action}}</button>
</div>
