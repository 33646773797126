import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { NotFoundPageComponent } from '@app/shell/components/not-found-page/not-found-page.component';
import { ShellComponent } from './shell/shell.component';
import { AuthenticationGuard } from './auth';
import { CrossLinkingComponent } from '@shared/components/cross-linking/cross-linking.component';
import { RedirectToAssetComponent } from '@shared/components/redirect-to-asset/redirect-to-asset.component';
import { ContentExportComponent } from '@shared/components/content-export/content-export.component';
import { AnalysisViewComponent } from '@shared/components/analysis/analysis-view/analysis-view.component';
import {environment} from '@env/environment';
import {
  NonEntitledUserLandingPageComponent
} from '@app/shell/components/non-entitled-user-landing-page/non-entitled-user-landing-page.component';
import { BlogLoaderComponent } from './@shared/components/tech-stream/blog-loader/blog-loader.component';
import {HygraphViewerComponent} from '@shared/components/hygraph/hygraph-viewer/hygraph-viewer.component';
import {ExecutiveSummaryGuard} from '@app/market-summary/executive-summary.guard';

const routes: Routes = [
  {
    path: 'public',
    loadChildren: () => import('./teaser-shell/teaser-shell.module').then((m) => m.TeaserShellModule),
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always',
    // Reuse ShellComponent instance when navigating between child views
    data: { reuse: true },
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'search',
        loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'tech-library',
        loadChildren: () => import('./tech-library/tech-library.module').then((m) => m.TechLibraryModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'reverse-engineering',
        // eslint-disable-next-line max-len
        loadChildren: () =>
          import('./reverse-engineering/reverse-engineering.module').then((m) => m.ReverseEngineeringModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'company-overview',
        loadChildren: () => import('./company-overview/company-overview.module').then((m) => m.CompanyOverviewModule ),
        data: {
          preload: false,
        }
      },
      {
        path: 'insightful-chat',
        loadChildren: () => import('./semi-gpt/semi-gpt.module').then((m) => m.SemiGptModule ),
        data: {
          preload: false,
        },
      },
      {
        path: 'my-techinsights',
        loadChildren: () => import('./my-techinsights/my-techinsights.module').then((m) => m.MyTechinsightsModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'progress-tracker',
        loadChildren: () => import('./progress-tracker/progress-tracker.module').then((m) => m.ProgressTrackerModule),
      },
      {
        path: 'report-detail/:analysisCode',
        redirectTo: 'reverse-engineering/analysis-view/:analysisCode',
      },
      {
        path: 'special-content',
        redirectTo: environment.modulesRedirection.specialContent,
      },
      {
        path: 'sustainability',
        redirectTo: environment.modulesRedirection.sustainability,
      },
      {
        path: 'strategy-analytics',
        // eslint-disable-next-line max-len
        loadChildren: () =>
          import('./strategy-analytics/strategy-analytics.module').then((m) => m.StrategyAnalyticsModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'market-analysis',
        loadChildren: () => import('./market-analysis/market-analysis.module').then((m) => m.MarketAnalysisModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'manufacturing',
        loadChildren: () => import('./manufacturing/manufacturing.module').then((m) => m.ManufacturingModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'processor',
        loadChildren: () => import('./processor/processor.module').then((m) => m.ProcessorModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'mcclean',
        loadChildren: () => import('./mcclean/mcclean.module').then((m) => m.McCleanModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'events',
        loadChildren: () => import('./events/events.module').then((m) => m.EventsModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'seminars',
        redirectTo: 'events',
      },
      {
        path: 'database-tools',
        loadChildren: () => import('./database-tools/database-tools.module').then((m) => m.DatabaseToolsModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'market-segments',
        loadChildren: () => import('./market-segments/market-segments.module').then((m) => m.MarketSegmentsModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'my-library',
        loadChildren: () => import('./my-library/my-library.module').then((m) => m.MyLibraryModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'market-summary',
        loadChildren: () => import('./market-summary/market-summary.module').then((m) => m.MarketSummaryModule),
        canActivate: [ExecutiveSummaryGuard],
        data: {
          preload: false,
        },
      },
      {
        path: 'executive-insights',
        loadChildren: () => import('./market-summary/market-summary.module').then((m) => m.MarketSummaryModule),
        data: {
          preload: false,
        },
      },
      {
        path: 'analysis-view/:analysisCode',
        component: AnalysisViewComponent,
      },
      {
        path: 'hg-asset/:reportId',
        component: HygraphViewerComponent,
        data: {
          forceHideTechstream: true,
          showHeader: true,
        },
      },
      {
        path: 'wp-asset/:postId',
        component: BlogLoaderComponent,
        data: {
          forceHideTechstream: true,
          showHeader: false,
        },
      },
      {
        path: 'contentexport',
        component: ContentExportComponent,
      },
      {
        path: 'cross-linking/:linking-type',
        component: CrossLinkingComponent,
      },
      {
        path: 'asset/:md5Hash/:reportCode',
        component: RedirectToAssetComponent,
      },
      {
        path: 'asset/:md5Hash',
        component: RedirectToAssetComponent,
      },
      {
        path: '404',
        component: NotFoundPageComponent,
      },
      {
        path: '403',
        component: NonEntitledUserLandingPageComponent,
      },
      {
        path: 'seminars',
        redirectTo: 'events'
      }
    ],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy, paramsInheritanceStrategy: 'always' }),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {}
