export const environment = {
  name: 'stg1',
  agGridLicense:
    'Using_this_AG_Grid_Enterprise_key_( AG-042555 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( TechInsights )_is_granted_a_( Multiple Applications )_Developer_License_for_( 5 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 2 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 16 June 2024 )____[v2]_MTcxODQ5MjQwMDAwMA==2720b12b5cd84e9fcdd876c4377625fa',
  // Reverse Engineering dashboards
  analyticsEnabledSubscriptions: [
    'a6rf30000001LhCAAU', // Reverse Engineering - Logic
  ],
  // Market Analysis (Strategy Analytics) dashboards
  dashboardEnabledSubscriptions: [
    'a6rf30000008SdXAAU', // Market Analysis - Mobile, also Reverse Engineering - Mobile RF,
    'a6rf30000001LhWAAU', // Market Analysis - Connected Computing Devices
  ],
  applicationsServiceUrl: 'https://persona-application.stg1.techinsights-staging.com',
  authServiceBaseUrl: 'https://auth.stg1.techinsights-staging.com',
  basePath: 'https://library.stg1.techinsights-staging.com',
  complimentary: '/search/result/ALL?q=&page=1&filters=%7B%7D&size=50&sort=relevancy,DESC&activeTab=SAMPLE&tabGroup=allAnalysisGroup',
  contactYourCEMForm: 'https://go.techinsights.com/l/1043171/2023-11-23/3ttn47',
  contentLakeBaseUrl: 'https://contentlake.stg1.techinsights-staging.com',
  contentLakeBucket: 'https://ti-corp-content-lake.s3.ca-central-1.amazonaws.com',
  contentNavigatorReportsCount: 5,
  contentRecommendationsFlag: 'content-recommendations-enabled',
  cssChannelIds: [],
  cssYearOfAnalysis: '2023',
  csubsModulesWithVic: ['a0L4y00000TWgmWEAT'],
  chamaeleonFastUrl: 'https://fast.chameleon.io/',
  chamaeleonSecret: 'VV7DDzbHRFPhoZfEvHdI6BIpqe1b-1P8728-E6to6u8TvckTTEho',
  chamaeleonToken: 'STsLDA9vihTg6jkUBEMesUYPRfWNApnfEqwmST2FYkmOy4-1P8728-E6to6u8TvckTTEho',
  componentPriceAnalyzerUrl: 'https://price-analyzer.stg1.techinsights-staging.com/cpa',
  cp20135DailyInsightsFlag: 'cp-20135-daily-insights',
  dailyInsightsChannelId: 'a6pAu000000211dIAA',
  datadogApplicationId: '',
  datadogClientToken: '',
  datadogEnv: 'stg1',
  datadogService: 'unified-ui',
  datadogSite: 'datadoghq.com',
  defaultLanguage: 'en-US',
  defaultVizWidth: 1366,
  defaultVizHeight: 801,
  defaultREVizHeight: 610,
  emaDashboards: new Map([
    ['a6p4y000000L6IqAAK', 'https://tableau-dev.techinsights.com/trusted/<ticket>/views/TabletVendorDashboard/TabletVendorsDashboard?:iid=1'],
    ['a6p4y000000L6J1AAK', 'https://tableau-dev.techinsights.com/trusted/<ticket>/workbooks/1275/views'],
  ]),
  emaDashboardsFF: new Map([
    ['a6p4y000000L6IqAAK', 'enable-connected-computing-dasboard'],
    ['a6p4y000000L6J1AAK', 'enable-spectrax-dasboard'],
  ]),
  enableAutomotiveIcDesignWins: 'automotive-ic-design-wins',
  enableUpcomingAnalysisTabForAllUsers: 'upcoming-analysis-tab-for-all-users',
  enablePreLoginPageSearch: 'pre-login-page-search',
  enableAccessRetoolICCAPM: 'iccapm-access-retool-app-from-the-platform',
  enableDownloadHygraphReports: 'cp-20733-enable-download-for-hygraph',
  entitlementCPA: 'a6p4y000000PFyLAAW',
  entitlementExtraId: 'a6pf3000000Gv3OAAS',
  entitlementIcSearchCarbon: 'a6pDo000000537KIAQ',
  entitlementProductSearchCarbon: 'a6pDo00000052yNIAQ',
  entitlementProductSearchWSubsystemCarbon: 'a6pDo00000052ySIAQ',
  entitlementAgentId: 'a0ec9984-43b0-11eb-b378-0242ac130002',
  enableSemiGptModule: 'enable-ai-module',
  enableMarketSegments: 'market-segment-enabled',
  enableMyTechInsights: 'my-techInsights-enabled',
  enableCarbonFootprint: 'enable-carbon-footprint',
  enableSaForConnectedComputingDevicesAnalyticsTab: 'enable-sa-analytics-tab-on-connected-computing-devices',
  enableRequestUnwatermarkedImage: 'provide-ability-to-request-unwatermarked-image',
  enableCarbonModelAlpha: 'carbon-model-alpha-enabled',
  entitlementRequestForm: 'https://w2.techinsights.com/l/4202/2021-01-05/2kz6dm',
  entitlementServiceUrl: 'https://entitlement.stg1.techinsights-staging.com',
  entitlementSubsystemId: 'a6pf3000000Gv3JAAS',
  heapAppId: '1100758637',
  hmr: false,
  icKnowledgeChannels: [
    'a6pDo000000wtBFIAY',
    'a6pDo000000wtB5IAI',
    'a6pDo000000wtBAIAY',
    'a6pDo000000wtB0IAI',
    'a6pDo000000wtBKIAY',
    'a6pDo000000wtBPIAY',
    'a6p4y000000wqHLAAY',
    'a6p4y000000wqHKAAY',
    'a6p4y000000wqHIAAY',
    'a6p4y000000wqH6AAI'
  ],
  inventoryServiceUrl: 'https://inventory.stg1.techinsights-staging.com',
  pardotForms: {
    fullAccessForm: '/l/4202/2024-06-14/81hhqg'
  },
  reportContainersRequest: [{
    channelId: 'a6p4y000000L9B4AAK',
    moduleName: 'McClean Report',
    moduleUrl: '/mcclean',
  }, {
    channelId: 'a6p4y000000wrxvAAA',
    moduleName: 'Microprocessor Report',
    moduleUrl: '/processor',
  }, {
    channelId: 'a6p4y000000wqGsAAI',
    moduleName: 'Chip Insider',
    moduleUrl: '/manufacturing',
  }],
  featuredHomePageReports: [
    'ACE-2307-803',
    'ACE-2304-801'
  ],
  // eslint-disable-next-line max-len
  icSearchCarbonFootprintUrl:
    'https://tableau-dev.techinsights.com/trusted/<ticket>/views/ICSearch/ICSearch?ce=1&domain=tisapp.stg1.techinsights-staging.com&:embed=y&:customViews=no&:display_share=no&:tabs=no',
  icSearchUrl:
    'https://tableau-dev.techinsights.com/trusted/<ticket>/views/ICSearch/ICSearch?domain=tisapp.stg1.techinsights-staging.com&:embed=y&:customViews=no&:display_share=no&:tabs=no',
  // eslint-disable-next-line max-len
  icSearchWithExtraDieInfo:
    'https://tableau-dev.techinsights.com/trusted/<ticket>/views/ICSearchwExtraDieInfo/ICSearch?domain=tisapp.stg1.techinsights-staging.com&:embed=y&:customViews=no&:display_share=no&:tabs=no',
  icSearchWithExtraDieVizWidth: 1870,
  icSearchWithExtraDieVizHeight: 801,
  automotiveSubscriptionId: 'a6rf3000000PDdCAAW',
  imageSensorSubscriptionId: 'a6rf30000001LhRAAU',
  janetUrl: 'https://janetbackend.stg1.techinsights-staging.com',
  kimeraImageFetchBaseUrl: 'https://image-fetch.stg1.techinsights-staging.com',
  featureFlagClientId: '6095acbc4d31420cb546214b',
  featureFlagSDKKey: 'sdk-9fee312d-32e4-44b6-93c8-12cf1d9b3e6f',
  flexEntitlementFlag: 'flex-entitlements-accessible',
  loginUrl: 'https://auth.stg1.techinsights-staging.com/login/username?app=UNIFIEDUI-PROFILE',
  nsfrqjqnrx:
    'TechInsights Inc.(techinsights.com):OEM:Inside Technology;Teardown; TechInsights App::L:AMS(20211005):1D7771101FB60EE586FBBBC2E0616F5CEF56A5737454E684141C508E428231F5C7',
  maChannelRedirections: {
    'a6p4y000000wqGsAAI': '/executive-insights/analysis/a6p4y000000wqGsAAI',
    'a6pDo000000svRmIAI': '/market-segments/a6rDo000000L6B9IAK/dashboard/semiconductor-manufacturing-carbon-model',
    'a6pDo000000wtB5IAI': '/manufacturing/a6rDo000000L6B9IAK/dashboard/iccapm',
  },
  iccapmChannelId: 'a6pDo000000wtB5IAI',
  manufacturingBlogs: ['a6p4y000000TPAsAAO', 'a6p4y000000TPAxAAO', 'a6p4y000000TPB2AAO'],
  manufacturingBlogsFlag: 'manufacturing-blogs',
  manufacturingChannelNames: [
    'Manufacturing Analysis CMRS',
    'Manufacturing Analysis CSS',
    'Manufacturing Analysis CSUBS',
    'Manufacturing Analysis TCS',
    'Manufacturing Analysis CSS',
    'Semiconductor Manufacturing and Economics',
    '2023 Complimentary',
  ],
  manufacturingVideos: 'a6r4y000000wk6aAAA',
  mccleanSubscriptionId: 'a6r4y000000H5dVAAS',
  mccleanPvoFilename: 'Product_Vertical_Overview_McCleanReport.pdf',
  mccleanSubscriptionName: 'The McClean Report',
  metaServiceBaseUrl: 'https://meta.stg1.techinsights-staging.com',
  publicServiceBaseUrl: 'https://public-service.stg1.techinsights-staging.com',
  platformIdMappingBaseUrl: 'https://platform-id-mapping.stg1.techinsights-staging.com',
  stashServiceBaseUrl: 'https://stash.stg1.techinsights-staging.com',
  userListServiceBaseUrl: 'https://userlist.stg1.techinsights-staging.com',
  modules: {
    linley: 'LINLEY',
    vlsi: 'VLSI',
    mcclean: 'MCCLEAN',
  },
  modulesRedirection: {
    specialContent: '/reverse-engineering/a6r4y000000kypOAAQ/summary',
    sustainability: '/market-segments/a6rDo000000L6B9IAK/summary',
  },
  modulesSearchUrl:
    'https://tableau-dev.techinsights.com/trusted/<ticket>/views/ModulesSearch/ModulesSearch?domain=tisapp.stg1.techinsights-staging.com&:embed=y&:customViews=no&:display_share=no&:tabs=no',
  mySubscriptions: '/settings/my-entitlements',
  myFavoritesDescriptionFlag: 'my-favorites-description',
  msFeatureContent: [{
    title: 'The Chip Observer',
    subtitle: 'Read the latest The Chip Observer',
    description: 'Covering the semiconductor value chain, from the fab floor to the customer\'s door.',
    isEntitled: true,
    route: ['/executive-insights', 'chip-observer'],
    url: 'https://blog.svc.techinsights.com/2023/10/26/chip-observer-october-2023/',
  }],
  navigationServiceUrl: 'https://navigation-api.stg1.techinsights-staging.com',
  notificationsUrl: 'https://notification.stg1.techinsights-staging.com',
  nonEntitledRedirect: [
    'https://meta.stg1.techinsights-staging.com/post',
    'https://meta.stg1.techinsights-staging.com/video',
    'https://inventory.stg1.techinsights-staging.com',
    'https://meta.stg1.techinsights-staging.com/wp-asset/post'
  ],
  ourIndustryExpertsUrl: '/reverse-engineering/blog-viewer/544109#name=TechInsight%20Internal',
  principalEntitlementId: 'a6pf30000009PYCAA2',
  // Processor Analysis is essentially an alias for the Logic subscription,
  // but there is currently no provision to have multiple PVOs for a subscription.
  // https://techinsights.atlassian.net/browse/CP-3394
  processorPvoFilename: 'Product_Vertical_Overview_Processor_Analysis.pdf',
  processorSubscriptionId: 'a6rDo000000HMJeIAO',
  // eslint-disable-next-line max-len
  productWExtraDieInfo:
    'https://tableau-dev.techinsights.com/trusted/<ticket>/views/ProductSearchwExtraDieInfo/ProductSearch?domain=tisapp.stg1.techinsights-staging.com&:embed=y&:customViews=no&:display_share=no&:tabs=no',
  productWExtraDieVizWidth: 1870,
  productWExtraDieVizHeight: 801,
  // eslint-disable-next-line max-len
  productSearchUrl:
    'https://tableau-dev.techinsights.com/trusted/<ticket>/views/ProductSearch/ProductSearch?domain=tisapp.stg1.techinsights-staging.com&:embed=y&:customViews=no&:display_share=no&:tabs=no',
  // eslint-disable-next-line max-len
  productSearchCarbonFootprintUrl:
    'https://tableau-dev.techinsights.com/trusted/<ticket>/views/ProductSearch/ProductSearch?ce=1&domain=tisapp.stg1.techinsights-staging.com&:embed=y&:customViews=no&:display_share=no&:tabs=no',
  productSearchWSubsystemCarbonFootprintUrl:
    'https://tableau-dev.techinsights.com/trusted/<ticket>/views/ProductSearchwSubsystems/ProductSearch?ce=1&domain=tisapp.stg1.techinsights-staging.com&:embed=y&:customViews=no&:display_share=no&:tabs=no',
  // eslint-disable-next-line max-len
  productWSubsystemUrl:
    'https://tableau-dev.techinsights.com/trusted/<ticket>/views/ProductSearchwSubsystems/ProductSearch?domain=tisapp.stg1.techinsights-staging.com&:embed=y&:customViews=no&:display_share=no&:tabs=no',
  progressTrackerUrl:
    'https://tableau-dev.techinsights.com/trusted/<ticket>/views/DeviceTracker3_0/DeviceTrackerDashboard?:toolbar=no&domain=library.stg1.techinsights-staging.com&:embed=y&:customViews=no&:display_share=no&:tabs=no',
  production: false,
  publicWhatsNew: 'https://notifications.techinsights.com/',
  pwaUpdatePeriod: 900000, // every 15 minutes
  qualifiedLiveChatOverlay: 'qualified-live-chat-overlay',
  qualifiedLiveChatToken: '45LkJrNr2vrF3bYo',
  relayProxyBaseUrl: 'https://launchdarkly.cloud.techinsights.com',
  reportCodesPreventedForDownload: ['SME-1901-802', 'AID-2108-801', 'MCQ-2112-801'],
  reportModulesWithVICCodes: [
    'a0L4y00000TWgM0EAL',
    'a1q4y000008iyBgAAI',
    'a1q4y000008iyBlAAI',
    'a1q4y000008j1mBAAQ',
    'a0L4y00000TWgLzEAL',
    'a0L4y00000TWgM4EAL',
    'a6p4y000000wqHGAAY',
    'a0L4y00000TWgmWEAT',
    'a0L4y00000UTmw4EAD',
  ],
  reportService: 'https://report.stg1.techinsights-staging.com',
  requestAccessWPAssetFlag: 'request-access-wp-asset-flag',
  retoolUrl: 'https://retool.library.stg1.techinsights-staging.com',
  reverseEngineeringShowVerticalSummaryBreadcrumb: false,
  roadmapUrl:
    'https://tableau-dev.techinsights.com/trusted/<ticket>/views/<roadmapPath>?domain=library.techinsights.com&:toolbar=no&:showShareOptions=no',
  roadmapVizHeight: 975,
  roadmapVizWidth: 1920,
  runDatadogRUM: false,
  savedSearchesUrl: 'https://search-notification.stg1.techinsights-staging.com',
  searchServiceUrl: 'https://search.stg1.techinsights-staging.com',
  serverUrl: '/api',
  showErrorNotificationBar: false,
  spectraxLiteDashboardFlag: 'uui-spectrax-lite-dashboard',
  supportedLanguages: ['en-US'],
  tableauBaseUrl: 'https://tableau-dev.techinsights.com',
  techinsightsNowDashboards: new Map([
    // Note: Production URLs for now - update when tableau-dev URLs available
    ['connected-computing-devices', 'https://tableau-dev.techinsights.com/trusted/<ticket>/views/ConnectedComputingDevices-GlobalTabletForecastbyYear-Freemium/Dashboard'],
    ['device-technologies', 'https://tableau-dev.techinsights.com/trusted/<ticket>/views/DeviceTechnologies-GlobalHandsetSalesForecastbyTechnology-Freemium/Dashboard'],
    ['connected-tv-devices', 'https://tableau-dev.techinsights.com/trusted/<ticket>/views/ConnectedTVDevices-QuarterlyOSShare-Freemium/Dashboard'],
    ['handset-component-technologies', 'https://tableau-dev.techinsights.com/trusted/<ticket>/views/HandsetComponentTechnologies-SmartphoneAppsProcessorForecast-Freemium/Dashboard-TOTAL'],
  ]),
  thumbnailService: 'https://thumbnail.stg1.techinsights-staging.com',
  toastConfig: {
    extendedTimeout: 10000,
    timeout: 5000,
  },
  unSupportedImageTypes: ['image/jp2'],
  userGuide: 'https://public.techinsights.com/techinsights-platform-user-guide/TechInsights_UserGuide.pdf',
  usesExternalAPI: true,
  vendorIntegrationServiceBaseUrl: 'https://vendor-integration.stg1.techinsights-staging.com',
  version: 'unified-web-app' + '-stg1',
  vlsiDynamicDbBaseUrl: 'https://vlsi-dynamic-db.stg1.techinsights-staging.com',
  webSocketTokenUrl: 'https://websocket.stg1.techinsights-staging.com/token',
  websocketUrl: 'https://websocket.stg1.techinsights-staging.com',
  blogServiceUrl: 'https://blog.svc.techinsights.com',
  annotationServiceBaseUrl: 'https://annotation.stg1.techinsights-staging.com',
  seminarServiceUrl: 'https://seminar.stg1.techinsights-staging.com',
  qualifiedLiveChat: 'experience-1678991271595',
  publicPolicyBaseUrl: 'https://public.techinsights-staging.com',
  companyOverviewDashboardId: 'company-overview',
  listOfSubprocessesUrl: 'https://blog.svc.techinsights.com/2024/03/12/list-of-sub-processors/',
  googleAnalyticsId: 'G-MFT49D5XY1', // Leave as empty string to not load in this environment
  clarityId: 'm9gupgyibb', // Leave as empty string to not load in this environment
  hygraphReportDisplayedPlatform: 'cp-19580-hygraph-report-asset-displayed-platform',
  preLoginPageBannerMessageUrl: 'https://configurable-login.cloud.techinsights.com/stg1/custom-banner/custom-banner.json',
};
