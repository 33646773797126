import {
  createAction,
  props
} from '@ngrx/store';
import {
  NotificationModel,
  PopupModel
} from '@app/store/Models/notification/notificationModel';

export enum NotificationsActionTypes {
  loadNotifications = '[Notifications] Load Notifications',
  loadNotificationsSuccess = '[Notifications] Load Notifications Success',
  loadNotificationsFailure = '[Notifications] Load Notifications Failure',
  setNotificationListSize = '[Notifications] Set List Size',
  readNotifications = '[Notifications] Read Notifications',
  dismissNotification = '[Notifications] Dismiss Notification',
  dismissAllNotifications = '[Notifications] Dismiss All Notifications',
  toggleNotificationDrawer = '[Notifications] Toggle Notification Drawer',
  closeNotificationDrawer = '[Notifications] Close Notification Drawer',
  pushNotifications = '[Notifications] Pushing New Notifications',
  showPopup = '[Notifications] Show Popup Notification',
  showOnboardingPopup = '[Notifications] Show Onboarding Popup Notification'
}

export const loadNotifications = createAction(
  NotificationsActionTypes.loadNotifications,
  props<{ page: number, size: number }>()
);

export const loadNotificationsSuccess = createAction(
  NotificationsActionTypes.loadNotificationsSuccess,
  props<{ data: NotificationModel[], totalNotifications: number }>()
);

export const loadNotificationsFailure = createAction(
  NotificationsActionTypes.loadNotificationsFailure,
  props<{ error: any }>()
);

export const readNotifications = createAction(NotificationsActionTypes.readNotifications);

export const pushNotifications = createAction(
  NotificationsActionTypes.pushNotifications,
  props<{ notifications: NotificationModel[] }>()
);

export const showPopup = createAction(
  NotificationsActionTypes.showPopup,
  props<{ popup: PopupModel }>()
);

export const showOnboardingPopup = createAction(
  NotificationsActionTypes.showOnboardingPopup,
  props<{ popup: Partial<PopupModel> }>()
);

export const dismissNotification = createAction(
  NotificationsActionTypes.dismissNotification,
  props<{ id: string }>()
);

export const dismissAllNotifications = createAction(NotificationsActionTypes.dismissAllNotifications);
export const toggleNotificationDrawer = createAction(NotificationsActionTypes.toggleNotificationDrawer);
export const closeNotificationDrawer = createAction(NotificationsActionTypes.closeNotificationDrawer);
