import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textScripts',
})
export class TextScriptsPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    return value.replace(/\^(\S*?)\^/g, '<sup>$1</sup>').replace(/\~(\S*?)\~/g, '<sub>$1</sub>');
  }
}
