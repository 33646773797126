import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-table-viewer-columns-hide-menu',
  templateUrl: './table-viewer-columns-hide-menu.component.html',
  styleUrls: ['./table-viewer-columns-hide-menu.component.scss'],
})
@UntilDestroy()
export class TableViewerColumnsHideMenuComponent implements OnInit {
  @Output() hideColumnToggle: EventEmitter<ColDef> = new EventEmitter();
  isMenuOpened = false;
  columns$ = new BehaviorSubject<Array<ColDef>>([]);
  _columns: Array<ColDef> = [];
  searchKeyword$ = new BehaviorSubject<string>('');
  searchKeyword = '';

  @Input() set columns(value: Array<ColDef>) {
    this.columns$.next(value);
  }

  ngOnInit(): void {
    combineLatest([
      this.columns$,
      this.searchKeyword$,
    ])
    .pipe(untilDestroyed(this))
    .subscribe(([columns, searchKeyword]) => {
      this._columns = columns
        .filter(column => !column.pinned)
        .filter(column => column.headerName.toLowerCase().includes(searchKeyword.toLowerCase()));
    });
  }

  toggleMenuPopover() {
    this.isMenuOpened = !this.isMenuOpened;
  }

  onSearchInputValueChange(value: string) {
    this.searchKeyword$.next(value);
  }

  onSearchInputClear() {
    this.searchKeyword = '';
    this.searchKeyword$.next('');
  }

  onHideToggleColumClick(event: Event, column: ColDef) {
    event.preventDefault();
    this.hideColumnToggle.emit(column);
  }

}
