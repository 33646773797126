import {
  createReducer,
  on
} from '@ngrx/store';
import {
  closeNotificationDrawer,
  dismissAllNotifications,
  dismissNotification,
  loadNotifications,
  loadNotificationsFailure,
  loadNotificationsSuccess,
  NotificationsActionTypes,
  pushNotifications,
  readNotifications,
  toggleNotificationDrawer,
} from '../../Actions/notification/notification.actions';
import { NotificationModel } from '../../Models/notification/notificationModel';

export const notificationsFeatureKey = 'notifications';

export interface NotificationsState {
  notifications: NotificationModel[];
  currentPage: number,
  totalNotifications: number,
  size: number,
  unread: number;
  loading: boolean;
  notificationDrawerOpen: boolean,
  seen: boolean
}

export const initialNotificationsState: NotificationsState = {
  notifications: [],
  unread: 0,
  currentPage: 0,
  totalNotifications: 0,
  size: Math.floor((window.innerHeight - 60) / 80) + 2,
  loading: false,
  notificationDrawerOpen: false,
  seen: true
};

export const notificationsReducer = createReducer(
  initialNotificationsState,
  on(toggleNotificationDrawer, state => {
    return {
      ...state,
      seen: !state.notificationDrawerOpen === true
            ? true
            : state.seen,
      notificationDrawerOpen: !state.notificationDrawerOpen
    };
  }),
  on(closeNotificationDrawer, state => {
    return {
      ...state,
      notificationDrawerOpen: false
    };
  }),
  on(loadNotifications, (state) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(
    loadNotificationsFailure, loadNotificationsSuccess, (state,
      action) => {
      if (action.type === NotificationsActionTypes.loadNotificationsFailure) {
        return {
          ...state,
          loading: false
        };
      }
      // avoid adding duped notifications
      return {
        ...state,
        notifications: [
          ...action.data.filter((n) => state.notifications.findIndex((v) => n.id === v.id) < 0),
          ...state.notifications
        ],
        unread: action.data.filter((value) => value.read === false).length,
        seen: action.data.filter((value) => value.read === false).length === 0,
        loading: false
      };
    }),
  on(readNotifications, (state) => {
    return {
      ...state,
      notifications: state.notifications.map((v: NotificationModel) => {
        v.read = true;
        return v;
      }),
      unread: 0,
      loading: false
    };
  }),
  on(dismissAllNotifications, (state) => {
    return {
      ...state,
      notifications: []
    };
  }),
  on(
    dismissNotification, (state,
      action) => {
      return {
        ...state,
        notifications: state.notifications.filter(v => v.id !== action.id)
      };
    }),
  on(
    pushNotifications, (state,
      action) => {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          ...action.notifications
        ],
        totalNotifications: state.totalNotifications + action.notifications.length,
        unread: state.unread + action.notifications.length,
        seen: false
      };
    })
);
