<div class="analysis-summary-container" *ngIf="shortDescription || longDescription">
  <div class="short-description-title" [ngClass]="{ 'is-expandable': isExpandable() }" (click)="onToggle()">
    <h5>Analysis Description</h5>
    <em class="filter-icon icon-up_small" *ngIf="isExpandable()" [ngClass]="{ 'filter-icon-down': collapsed }"></em>
  </div>
  <div class="description-content" #descriptions [ngClass]="{ 'collapsed': collapsed }">
    <div *ngIf="shortDescription">{{shortDescription}}</div>
    <div
      *ngIf="longDescription"
      class="long-description"
      [innerHTML]="longDescription"
    ></div>
    <div class="gradient" *ngIf="collapsed && isExpandable()"></div>
  </div>
</div>
