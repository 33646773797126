<div class="filter-modal" #modal>
  <div class="swipe-indicator"></div>
  <div class="filter-title">
    <label>Filter<span *ngIf="filtersCount>0">&nbsp;({{filtersCount}})</span></label>
    <div class="close-modal" (click)="onClose()">
      <em class="icon-close_medium"></em>
    </div>
  </div>

  <div #legacyFilters></div>

  <div class="filter" #accessibilityFilters>
    <div class="heading">Accessibility</div>
    <div class="buttons">
      <div class="ui__base ui__choice ui__choice--center">
        <input [formControl]="publicCtrl" class="ui__choice__input" type="checkbox" id="public-filter"/>
        <label class="filter-label" for="public-filter">Public</label>
      </div>
      <div class="ui__base ui__choice ui__choice--center">
        <input [formControl]="privateCtrl" class="ui__choice__input" type="checkbox" id="private-filter"/>
        <label class="filter-label" for="private-filter">Private</label>
      </div>
    </div>
  </div>

  <div class="footer">
    <button class="Button filter-annot-clear" type="button" (click)="onClearAll()">Clear all</button>
    <button class="Button filter-annot-apply" type="button" (click)="onApply()"><span>Apply</span></button>
    <div class="legacy-hidden-actions" #legacyActions>
    </div>
  </div>
</div>
