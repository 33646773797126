import { TabItem } from '../shared.models';

const RE_LANDING_PAGE_BASE_TABS: TabItem[] = [
  {
    name: 'Summary',
    routerLink: 'summary',
    icon: 'icon-list2',
    disabled: false,
  },
  {
    name: 'Analysis',
    routerLink: 'analysis',
    icon: 'icon-log',
    disabled: false,
  },
  {
    name: 'Videos',
    routerLink: 'videos',
    icon: 'icon-videos',
    disabled: false,
  },
  {
    name: 'TechStream',
    routerLink: 'techstream',
    icon: 'icon-blog',
    disabled: false,
  },
  {
    name: '',
    routerLink: 'roadmaps',
    icon: '',
    disabled: false,
  },
];

export const RE_LANDING_PAGE_TABS: TabItem[] = RE_LANDING_PAGE_BASE_TABS.concat([
  {
    name: 'Product Overview',
    routerLink: 'product-overview',
    icon: 'icon-info',
    disabled: false,
  },
]);

export const SA_LANDING_BASE_PAGE_TABS: TabItem[] = [
  {
    name: 'Summary',
    routerLink: 'summary',
    icon: 'icon-list2',
    disabled: false,
  },
  {
    name: 'Analysis',
    routerLink: 'analysis',
    icon: 'icon-log',
    disabled: false,
  },
  {
    name: 'Videos',
    routerLink: 'videos',
    icon: 'icon-videos',
    disabled: false,
  },
  {
    name: 'TechStream',
    routerLink: 'techstream',
    icon: 'icon-blog',
    disabled: false,
  },
  {
    name: '',
    routerLink: 'roadmaps',
    icon: '',
    disabled: false,
  },
];

export const SA_LANDING_PAGE_TABS: TabItem[] = SA_LANDING_BASE_PAGE_TABS.concat([
  {
    name: 'Product Overview',
    routerLink: 'product-overview',
    icon: 'icon-info',
    disabled: false,
  },
]);

export const SA_ANALYTICS_LANDING_PAGE_TABS: TabItem[] = SA_LANDING_PAGE_TABS.slice(0);

SA_ANALYTICS_LANDING_PAGE_TABS.splice(
  SA_LANDING_PAGE_TABS.length - 1,
  0,
  {
    name: 'Dashboards',
    routerLink: 'analytics',
    icon: 'icon-menu_analytics',
    disabled: false,
  }
);

export const RE_LOGIC_LANDING_PAGE_TABS: TabItem[] = RE_LANDING_PAGE_BASE_TABS.concat([
  {
    name: 'Analytics',
    routerLink: 'analytics',
    icon: 'icon-menu_analytics',
    disabled: false,
  },
  {
    name: 'Product Overview',
    routerLink: 'product-overview',
    icon: 'icon-info',
    disabled: false,
  },
]);

export const RE_LANDING_PAGE_CUSTOM_TABS = [
  {
    name: 'Analysis',
    routerLink: 'analysis',
    icon: 'icon-log',
    disabled: false,
  },
];

export const SA_LANDING_PAGE_CUSTOM_TABS = [
  {
    name: 'Analysis',
    routerLink: 'analysis',
    icon: 'icon-log',
    disabled: false,
  },
];

export const MARKET_SEGMENTS_BASE_PAGE_TABS: TabItem[] = [
  {
    name: 'Summary',
    routerLink: 'summary',
    icon: 'icon-list2',
    disabled: false,
  },
  {
    name: 'Analysis',
    routerLink: 'analysis',
    icon: 'icon-log',
    disabled: false,
  },
  {
    name: 'Videos',
    routerLink: 'videos',
    icon: 'icon-videos',
    disabled: false,
  },
  {
    name: 'TechStream',
    routerLink: 'techstream',
    icon: 'icon-blog',
    disabled: false,
  },
];

export const MARKET_SEGMENTS_PAGE_TABS = MARKET_SEGMENTS_BASE_PAGE_TABS.concat([
    {
      name: '',
      routerLink: 'roadmaps',
      icon: '',
      disabled: false,
    },
  ]);

export const MARKET_SUMMARY_LANDING_PAGE_TABS: TabItem[] = [
  {
    name: 'Summary',
    routerLink: '/executive-insights',
    icon: 'icon-list2',
    disabled: false,
  },
  {
    name: 'TechStream',
    routerLink: 'tech-stream',
    icon: 'icon-blog',
    disabled: false,
  }
];
