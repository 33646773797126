import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@env/environment';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import { NavigationObjectType, Channel, Roadmap, Subscription } from '@app/@shared/models/navigation/navigation.models';
import { Breadcrumb, DropdownItem, TabItem } from '@app/@shared/models/shared.models';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';

@Component({
  template: '',
})
@UntilDestroy()
export abstract class AbstractVerticalComponent {

  public dropdownSubscriptions: Array<DropdownItem>;
  public currentSubscription: DropdownItem;
  public activeTab: TabItem;
  public currentChannel: Channel;
  public currentSubChannel: Channel;
  public breadcrumbs: Array<Breadcrumb>;
  public defaultTabRouterLink = 'summary';
  public tabs: Array<TabItem> = [];
  public loadingTabs = true;

  protected moduleName: string;

  protected constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected navigationService: NavigationService,
    protected ffService: FeatureFlagService,
  ) {}

  public getTabs(): void {
    const url = this.router.url.split(/[?#]/)[0];
    this.route.params.pipe(
      concatMap(params =>
        this.navigationService.getAbstractSubscription(params.subscriptionId, this.moduleName).pipe(
          map((subscription) => subscription),
        )
      ),
      untilDestroyed(this),
    ).subscribe((subscription: Subscription) => {
      const retoolRoadmaps: Array<Roadmap> = subscription.roadmaps.filter(
        (roadmap: Roadmap) => roadmap.dashboardType === NavigationObjectType.RETOOL
      );
      const tableauRoadmaps: Array<Roadmap> = subscription.roadmaps.filter(
        (roadmap: Roadmap) => roadmap.dashboardType === NavigationObjectType.TABLEAU
      );
      this.tabs = [ ...subscription.getLandingPageTabs() ];
      // Prefer Retool dashboards over Tableau if both are present
      if (retoolRoadmaps.length > 0) {
        this.loadRoadmapTabs(retoolRoadmaps);
      } else {
        this.loadRoadmapTabs(tableauRoadmaps);
      }
      this.activeTab = this.tabs.find((tab) => tab.routerLink === url.split('/')[3]);
    });
  }

  /**
   * onChangeSubscription navigates to a subscription that was
   * selected in the dropdown in the header bar.
   * The selected tab remains the same.
   * @param subscription The subscription dropdown item that was selected.
   */
  public onChangeSubscription(subscription: DropdownItem): void {
    if (subscription.isCustom) {
      this.defaultTabRouterLink = 'analysis';
    } else {
      this.defaultTabRouterLink = 'summary';
    }
    this.router.navigate([subscription.id, this.defaultTabRouterLink], { relativeTo: this.route.parent });
    this.loadingTabs = subscription.name !== this.currentSubscription?.name;
  }

  /**
   * onClickBreadcrumb navigates to the clicked breadcrumb.
   * @param breadcrumb The breadcrumb that was clicked.
   */
  public onClickBreadcrumb(breadcrumb: Breadcrumb): void {
    this.router.navigate([breadcrumb.route], { relativeTo: this.route });
  }

  /**
   * navigate and change active tab
   * @param tab The tab item clicked
   */
  public tabClicked(tab: TabItem) {
    this.activeTab = tab;
    this.router.navigate([tab.routerLink], { relativeTo: this.route });
  }

  protected getChannels(id: string) {
    this.navigationService.getChannel(id, this.moduleName).pipe(
      untilDestroyed(this)
    ).subscribe(
      (response) => this.currentChannel = response
    );
  }

  private loadRoadmapTabs(roadmaps: Array<Roadmap>): void {
    combineLatest([
      this.navigationService.isRequestAccessForRoadmapRequired(this.moduleName),
      this.ffService.getFlag(environment.enableUpcomingAnalysisTabForAllUsers),
    ])
    .subscribe(([disabled, isRetoolRoadmapEnabledForAllUsers]) => {
      const roadmapTabs = roadmaps.map(({ name, dashboardType }) => {
        if (isRetoolRoadmapEnabledForAllUsers && dashboardType === NavigationObjectType.RETOOL) {
          disabled = false;
        }
        return {
          name: name ? `${name} Upcoming Analysis` : 'Upcoming Analysis',
          routerLink: name ? `${name}-roadmap` : 'roadmap',
          icon: disabled ? 'icon-locked' : 'icon-roadmap',
          disabled,
        };
      });
      const roadmapPlaceholderIndex = this.tabs.findIndex((x) => x.routerLink === 'roadmaps');
      if (roadmapPlaceholderIndex >= 0) {
        this.tabs.splice(roadmapPlaceholderIndex, 1, ...roadmapTabs);
      }
      // Generate breadcrumbs for roadmap tabs which are dynamic
      const urlPath = this.router.url.split(/[?#]/)[0];
      const urlSegments = urlPath.substring(1).split('/');
      this.activeTab = this.tabs.find((tab) => tab.routerLink === urlSegments[2]);
      this.loadingTabs = false;
    });
  }

}
