import { Component, ElementRef, Input } from '@angular/core';
import { MeasuringScalingFactor } from '@app/@shared/models/kimera/measurement';
import { TileSourceLayerTableGui, WebCVJSONContent } from '@app/@shared/models/kimera/webCVJSON';

@Component({
  selector: 'app-kimera-grid-cv-content',
  templateUrl: './kimera-grid-cv-content.component.html',
  styleUrls: ['./kimera-grid-cv-content.component.scss']
})
export class KimeraGridCvContentComponent {


  @Input() analysisCode: string;
  @Input() webCVContentLakeId: string;
  @Input() sidebarOpen: boolean;
  @Input() set webCVJSON(webCVJSON: WebCVJSONContent) {
    if(webCVJSON){
      const tileSourceLayers: Array<TileSourceLayerTableGui> = webCVJSON.multilayer_image_source
      .tile_source_layer_table.map((tile_source_layer, index)=>{
            return {
            index,
            ...tile_source_layer,
            isSelected: index === 0,
            isVisible: index === 0,
            colour: '',
            opacity: 1
        }})
        this.measuringScalingFactor = {
          micronScalingFactor: webCVJSON.multilayer_image_source.micron_width,
          measuringToolsDisabled: !(webCVJSON.multilayer_image_source.can_measure)
        }
        this.tileSourceLayers = tileSourceLayers;
        this.gridId = webCVJSON.multilayer_image_source.tile_source_layer_table[0].tileSources[0].tile_source['WEBCV-GRID-SUFFIX'];
    }
  }
  tileSourceLayers: Array<TileSourceLayerTableGui> = [];
  measuringScalingFactor: MeasuringScalingFactor = {
    micronScalingFactor: null,
    measuringToolsDisabled: true,
  }
  gridId: string;
  colourPickerShown = false;
  //relative styles for positioning
  scrollDiff = 0;
  metaDataItemHeight = 45;
  metaDataItemWidth = 276;
  cvToolbarHeight = 50;
  kimeraGridCvContentComponentHeight: number;

  constructor(private elementRef: ElementRef) {
  }

  onToggleVisibility(tile_source_layer: TileSourceLayerTableGui){
    this.tileSourceLayers = this.tileSourceLayers.map((tileSourceLayer)=>(tileSourceLayer.key === tile_source_layer.key)
                            ? {...tileSourceLayer, isVisible: !tileSourceLayer.isVisible}: tileSourceLayer);
  }

  onToggleSelection(tile_source_layer: TileSourceLayerTableGui){
    if(!tile_source_layer.isSelected){
      this.tileSourceLayers = this.tileSourceLayers
                                  .map((tileSourceLayer)=>(tileSourceLayer.key === tile_source_layer.key)
      ? {...tileSourceLayer, isSelected: true}
      : {...tileSourceLayer, isSelected: false});
    }
  }

  onSelectColourPicker(tileSourceLayerWithScrollDiff:
    {tileSourceLayerTableGui: TileSourceLayerTableGui, scrollDiff: number}){
      this.scrollDiff = tileSourceLayerWithScrollDiff.scrollDiff;
      this.kimeraGridCvContentComponentHeight = this.elementRef.nativeElement.offsetHeight;
      this.onToggleSelection(tileSourceLayerWithScrollDiff.tileSourceLayerTableGui);
      setTimeout(()=>{
          this.colourPickerShown = true;
      },0)
  }

  onApplyColour(colourAndOpacity: {colour: string, opacity: number}){
    if(!colourAndOpacity){
      colourAndOpacity = {
        colour: '',
        opacity: 1
      }
    }
    const currentSelectedTileSourceLayer = {...this.getCurrentSelectedTileSourceLayer(),colour: colourAndOpacity.colour,
        opacity: colourAndOpacity.opacity}
    this.tileSourceLayers = this.tileSourceLayers.map(
      (tileSourceLayer)=>tileSourceLayer.key === currentSelectedTileSourceLayer.key ?
    currentSelectedTileSourceLayer : tileSourceLayer)
  }

  onCloseColourPicker(){
    this.colourPickerShown = false;
  }

  getCurrentSelectedTileSourceLayer(): TileSourceLayerTableGui{
    return this.tileSourceLayers.find((tileSourceLayer)=>tileSourceLayer.isSelected === true);
  }
}
