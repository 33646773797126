import { Component } from '@angular/core';
import { ContentNavigatorTableComponent } from '@shared/components/content-navigator/content-navigator-table/content-navigator-table.component';
import {
  ContentNavigatorTableRow,
  ReportFlavor
} from '@shared/models/content-navigator/content-navigator.model';
import {NoAvailableContentMessage} from '@shared/consts/messages';

@Component({
  selector: 'app-re-content-navigator-table',
  templateUrl: './re-content-navigator-table.component.html',
  styleUrls: [ './re-content-navigator-table.component.scss' ],
})
export class REContentNavigatorTableComponent extends ContentNavigatorTableComponent<ContentNavigatorTableRow> {

  showDocumentsCount = true;
  showImagesCount = true;
  noContentTitle = NoAvailableContentMessage.defaultMessage.title;
  noContentSubTitle = NoAvailableContentMessage.defaultMessage.subTitle;
  inProgress = ReportFlavor.IN_PROGRESS;
  parseInt = parseInt;
}
