import {Component, Input} from '@angular/core';
import {Section} from '@shared/services/hygraph/hygraph.models';

@Component({
  selector: 'app-hygraph-navigation',
  templateUrl: './hygraph-navigation.component.html',
  styleUrls: ['./hygraph-navigation.component.scss']
})
export class HygraphNavigationComponent {
  @Input() sections: Section[];
  @Input() includeTableOfContent: boolean;

  public onClick(elementId: string): void {
    const el = document.getElementById(elementId);
    const scrollableParent = document.querySelector('main');

    const elementPosition = el.getBoundingClientRect().top;
    const parentPosition = scrollableParent.getBoundingClientRect().top;

    scrollableParent.scrollTo({
      top: scrollableParent.scrollTop + elementPosition - parentPosition - 60,
      behavior: 'smooth'
    });
  }
}
