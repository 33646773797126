import { Component, Input } from '@angular/core';
import { Analysis, IMetadataItem } from '@app/@shared/models/reverse-engineering/analysis.models';
import moment from 'moment';
import { BlogAuthorModel } from '@shared/models/blog-author.model';
import { DialogService } from '@shared/services/dialog/dialog.service';
import { ContactModalComponent } from '@shared/components/contact-modal/contact-modal.component';
import { ContactMessageModel } from '@shared/models/contact/contact-message.model';
import { AuthenticationService, User } from '@app/auth';
import { ContactSupportService } from '@app/@shared/services/contact-support/contact-support.service';

@Component({
  selector: 'app-document-metadata',
  templateUrl: './document-metadata.component.html',
  styleUrls: [ './document-metadata.component.scss' ],
})
export class DocumentMetadataComponent {

  @Input() sidebarOpen = false;
  @Input() set currentAnalysis(analysis: Analysis) {
    this.setMetadataList(analysis);
  };
  @Input() authors: BlogAuthorModel[] = [];

  public metadataList: Array<IMetadataItem>;
  public analysis: Analysis;

  constructor(
    public contactSupportService: ContactSupportService,
    private authService: AuthenticationService,
    private dialogService: DialogService,
  ) {}

  public setMetadataList(analysis: Analysis): void {
    if (analysis) {
      this.analysis = analysis;
      this.metadataList = [];
      // Mandatory Fields
      this.metadataList.push({ key: 'Analysis Title', value: analysis.name ?? '' });
      this.metadataList.push({ key: 'Product Code', value: analysis.code ?? '' });
      this.metadataList.push({
        key: 'Date Completed',
        value: analysis.analysisEndDate ? moment(analysis.analysisEndDate).utc().format('MM/DD/YYYY') : 'In Progress'
      });

      // Only show if not empty
      analysis.deviceName && this.metadataList.push({ key: 'Device Name', value: analysis.deviceName });
      analysis.devicePartNumber && this.metadataList.push({ key: 'Device Part No', value: analysis.devicePartNumber });
      analysis.deviceManufacturer && this.metadataList.push({ key: 'Device Manufacturer', value: analysis.deviceManufacturer });
      analysis.deviceType && this.metadataList.push({ key: 'Device Type', value: analysis.deviceType });
      analysis.downstreamProduct && this.metadataList.push({ key: 'Downstream Product', value: analysis.downstreamProduct });

      // these fields have to be attached the last
      this.metadataList.push({
        key: 'ECCN',
        value: analysis.eccn || '',
      });
    }
  }

  onAskTheAuthor(): void {
    this.authService.me().subscribe((user: User) => {
      const modalRef = this.dialogService.open(ContactModalComponent, {
        windowClass: 'contact-modal',
      });
      modalRef.componentInstance.email = user.email;
      modalRef.componentInstance.productCode = this.analysis.code;
      modalRef.result.then((data: ContactMessageModel) => {
        this.callAskTheAuthor(data);
      });
    });
  }

  callAskTheAuthor(data: ContactMessageModel): void {
    const authorName: string = this.authors ? this.authors[0].name : 'TechInsights';
    this.contactSupportService.askTheAuthor(data.productCode, authorName, data.message).subscribe({
      next: () => this.dialogService.openGeneric({
        title: 'Your request has been sent!',
        heading: 'Contact a Subject Matter Expert',
        body: 'Our team has received you request for more information and will process your inquiry within 2 business days. We are looking forward helping you!',
        actionButtonLabel: 'Done',
      }),
      error: () => this.dialogService.openError(),
    });
  }
}
