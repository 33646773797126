/* eslint-disable no-console */
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { RegularExpressions } from '@shared/expresions/regular-expressions';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '@shared/services/meta.service';
import { TelemetryEvent } from '@shared/models/telemetry-event'
import { IInputProps } from '../input/input.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { TelemetryEventName } from '../../enums/telemetry-event-name';
import { TIME_IN_MILLISECONDS } from '@shared/consts';
import { ShareLinkType } from '@app/@shared/models/share-link/share-link.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService, User } from '@app/auth';
import { first } from 'rxjs/operators';
import moment from 'moment';

export enum ShareModalState {
  SHARE = 'share',
  ERROR = 'error',
  SUCCESS = 'success'
}
@Component({
  selector: 'app-share-item-modal',
  templateUrl: './share-item-modal.component.html',
  styleUrls: ['./share-item-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@UntilDestroy()
export class ShareItemModalComponent implements OnInit {

  @Input() clObjectId: string;
  @Input() shareTitle = 'Share Content With Other Subscribers';
  // 'generic' default phrases should be overriden
  @Input() copyToClipboardNotice = 'Content shared through links will only be accessible for users with an active account and entitlements on the TechInsights Platform.';
  @Input() shareUsingEmailNotice = 'Access to content can be shared through email to other members of your corporate domain(s).';
  @Input() emailRequiredErrorMsg = 'At least one email address is required to share the item.';
  @Input() invalidEmailErrorMsg = 'One or more emails are invalid.';
  @Input() copyLinkTelemetryEvent: TelemetryEvent; // provide telemetry payload for user clicking 'Copy Link'
  @Input() isDocumentShareable = false;
  @Input() itemDeepLinkUrl = window.location.href;
  // These 3 inputs for the share image and share pdf
  @Input() reportCode: string;
  @Input() assetGroupId: string;
  @Input() assetId: string;
  // These 3 inputs for the share link
  @Input() shareItemEntityId: string;
  @Input() shareItemEntityTitle: string;
  @Input() shareEntityType: ShareLinkType;

  messageToShare: string;
  invalidEmails: string[] = [];
  correctEmails: string[] = [];
  emailRegex = RegularExpressions.email;
  splitRegex = RegularExpressions.splitChips;
  errors: any;
  isSpinnerShown = false;
  copyButtonText = 'Copy Link';
  modalBody: any;
  emailsWithErrors: any;
  shareModalFormGroup: UntypedFormGroup;
  shareState = ShareModalState.SHARE;

  inputPropsLink: IInputProps = {
    class: 'form-control share-modal-link readonly',
    placeholder: '',
    label: 'Link',
    disabled: true,
  };

  authUser: User;
  newTitle = 'Share Content';
  msTeamLogo = '../../assets/images/icons/ms_teams.svg';
  messageSubject = 'TechInsights Share Notification';
  messageBody = 'Some content has been shared with you from the TechInsights Platform.';

  constructor(
    public modal: NgbActiveModal,
    private metaService: MetaService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.authService.me().pipe(first()).subscribe((user: User) => {this.authUser = user})
    if (this.isDocumentShareable) {
      this.route.paramMap
      .pipe(untilDestroyed(this))
      .subscribe( params => {
        this.reportCode = params.get('reportCode');
        this.assetGroupId = params.get('assetGroupId');
        this.assetId = params.get('assetId');
      });
    }
    this.itemDeepLinkUrl = this.buildItemDeepLinkUrl(this.itemDeepLinkUrl);
    this.shareModalFormGroup = new UntypedFormGroup(
      {
        link: new UntypedFormControl(this.itemDeepLinkUrl),
      }
    );
  }

  modalClose(result: string) {
    this.modal.close(result);
  }

  modalDismiss(result: string) {
    this.modal.dismiss(result)
  }

  onClickSharedDocument() {
    if (this.emailValidation()) {
      this.showLoadingSpinner();
      const request = this.isDocumentShareable ? this.shareDocument() : this.shareLink();
      request.subscribe(() => {
        this.showLoadingSpinner();
        this.closeModalAndCleanState();
        this.shareState = ShareModalState.SUCCESS;
      }, (res: any) => {
        this.showLoadingSpinner();
        this.closeModalAndCleanState();
        this.shareState = ShareModalState.ERROR
        if (res.status === 400) {
          this.showErrorModal(res.error);
        }
      });
    }
  }

  openShareToTeamsPopup() {
    const newQueryParams = {
      utm_medium: 'teams',
      utm_source: 'platform',
      utm_campaign: 'platform_share',
      utm_date: moment().utc().format('YYYY-MM-DD'),
      utm_id: this.authUser.salesForceContactId
    };
    const finalUrl = this.appendUTMQueryParamsToUrl(this.itemDeepLinkUrl, newQueryParams);

    const width = 700;
    const height = 600;
    const top = window.top.outerHeight / 2 + window.top.screenY - ( height / 2);
    const left = window.top.outerWidth / 2 + window.top.screenX - ( width / 2);
    window.open(
      `https://teams.microsoft.com/share?href=${encodeURIComponent(finalUrl)}`,
      'ms-teams-share-popup',
      `width=${width},height=${height},top=${top},left=${left}`
    );
  }

  shareDocument() {
    return this.metaService.postShareDocument({
      emails: this.correctEmails.map(email => email.toLowerCase()),
      message: this.messageToShare ? this.messageToShare : '',
      appId: environment.entitlementAgentId,
      url: this.itemDeepLinkUrl,
      clObjectId: this.clObjectId
    });
  }

  shareLink() {
    return this.metaService.postShareLink({
      entityType: this.shareEntityType,
      entityId: this.shareItemEntityId,
      entityTitle: this.shareItemEntityTitle,
      shareLink: this.itemDeepLinkUrl,
      emails: this.correctEmails.map(email => email.toLowerCase()),
      message: this.messageToShare ? this.messageToShare : ''
    });
  }

  showErrorModal(error: any) {
    this.modalBody = {
      whitelistedDomain: error.whitelistedDomain,
      emailsNotWhitelisted: error.emailsNotWhitelisted,
      emailsWithCreationErrors: error.emailsWithCreationErrors
    };
    this.emailsWithErrors = this.modalBody.emailsNotWhitelisted.concat(this.modalBody.emailsWithCreationErrors);
  }

  isEmptyErrors() {
    return !this.errors || Object.keys(this.errors).length === 0;
  }

  showLoadingSpinner() {
    this.isSpinnerShown = !this.isSpinnerShown;
  }

  invalidEmailsChange($event: any) {
    this.errors = {};
    this.invalidEmails = $event;
    this.emailValidation();
  }

  emailsChange($event: any) {
    this.errors = {};
    this.correctEmails = $event;
    this.emailValidation();
  }

  emailValidation() {
    if (this.correctEmails.length === 0) {
      this.errors = { email : this.emailRequiredErrorMsg };
      return false;
    } else if (this.invalidEmails.length !== 0) {
      this.errors = { email : this.invalidEmailErrorMsg };
      return false;
    } else {
      this.errors = {};
      return true;
    }
  }

  onCopyItemUrl() {
    const newQueryParams = {
      utm_medium: 'copylink',
      utm_source: 'platform',
      utm_campaign: 'platform_share',
      utm_date: moment().utc().format('YYYY-MM-DD'),
      utm_id: this.authUser.salesForceContactId
    };
    const finalURL = this.appendUTMQueryParamsToUrl(this.itemDeepLinkUrl, newQueryParams);
    navigator.clipboard.writeText(finalURL)
      .then(() => {
        this.sendCopyLinkTelemetry();
        this.copyButtonText = 'Copied'
        setTimeout(this.hideClipboardSuccess.bind(this), TIME_IN_MILLISECONDS.SECOND);
      }).catch((err) => console.error(err));;
  }

  hideClipboardSuccess() {
    this.copyButtonText = 'Copy Link';
  }

  closeModalAndCleanState() {
    this.correctEmails = [];
    this.messageToShare = '';
  }

  sendCopyLinkTelemetry() {
    if (this.isDocumentShareable) {
      this.metaService.getAsset(this.assetId).subscribe(asset => {
        const copyLinkEvent = new TelemetryEvent(TelemetryEventName.EVENT_TISAPP_COPY_SHARE_URL, {
          contentType: asset.fileType,
          contentId: this.assetId
        });
        this.metaService.postTelemetryEvent(copyLinkEvent).subscribe();
      });
    } else {
      if (this.copyLinkTelemetryEvent) {
        this.metaService.postTelemetryEvent(this.copyLinkTelemetryEvent).subscribe();
      }
    }
  }

  openMailto() {
    const newQueryParams = {
      utm_medium: 'email',
      utm_source: 'platform',
      utm_campaign: 'platform_share',
      utm_date: moment().utc().format('YYYY-MM-DD'),
      utm_id: this.authUser.salesForceContactId
    };
    const finalURL= this.appendUTMQueryParamsToUrl(this.itemDeepLinkUrl, newQueryParams);

    const formattedBody = `${this.messageBody}\n\n${finalURL}`;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(this.messageSubject)}&body=${encodeURIComponent(formattedBody)}`;
    window.open(mailtoLink, '_blank');
  }

  private buildItemDeepLinkUrl(linkUrl: string): string {
    // we should not to show utm parms
    let currentUUIUrl = new URL(linkUrl);
    linkUrl.replace(currentUUIUrl.origin, environment.basePath);
    currentUUIUrl = new URL(linkUrl);
    const existingParams = new URLSearchParams(currentUUIUrl.searchParams.toString());
    const rejectQueryParams = {
      utm_medium: 'utm_medium',
      utm_source: 'utm_source',
      utm_campaign: 'utm_campaign',
      utm_date: 'utm_date',
      utm_id: 'utm_id'
    };
    const queryParams = new URLSearchParams(rejectQueryParams);
    for (const [key] of queryParams.entries()) {
      if (existingParams.has(key)) {
        existingParams.delete(key);
      }
    }
    currentUUIUrl.search = existingParams.toString();
    return currentUUIUrl.toString();
  }

  /**
   * The utm parameters will be added to a given url
   * @param urlString
   * @param newQueryParams
   * @returns URL with utm parameters (converted to string)
   */
  private appendUTMQueryParamsToUrl(urlString: string, newQueryParams: { [key: string]: string }): string {
    const url = new URL(urlString);
    const existingParams = new URLSearchParams(url.searchParams.toString());

    url.search = '';
    const hash = url.hash;
    url.hash = '';

    const queryParams = new URLSearchParams(newQueryParams);
    for (const [key, value] of existingParams.entries()) {
      // We have to show the actual
      // and not the browser url utm params
      if (!queryParams.has(key)) {
        queryParams.set(key, value);
      }
    }

    url.hash = hash + url.hash;
    url.search = queryParams.toString();

    return url.toString();
  }
}
