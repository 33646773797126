import { Component } from '@angular/core';
import { ContentNavigatorTableComponent } from '@shared/components/content-navigator/content-navigator-table/content-navigator-table.component';
import { ContentNavigatorTableRow } from '@app/@shared/models/content-navigator/content-navigator.model';

@Component({
  selector: 'app-market-summary-content-navigator-table',
  templateUrl: './market-summary-content-navigator-table.component.html',
  styleUrls: ['./market-summary-content-navigator-table.component.scss']
})
export class MarketSummaryContentNavigatorTableComponent
extends ContentNavigatorTableComponent<ContentNavigatorTableRow> {}
