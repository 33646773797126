import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { AutomativeIcDesignWins } from '@shared/models/automative-ic-design-wins';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { NavigationService } from './navigation/navigation.service';
import { Channel } from '../models/navigation/navigation.models';

export interface ResultArray {
  names: string[];
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  constructor(private httpClient: HttpClient, private navigationService: NavigationService) {}

  getAutomativeIcDesignWins(): Observable<Array<AutomativeIcDesignWins>> {
    const channelKeyName = 'Channel Name';
    return forkJoin([
      this.httpClient.get<AutomativeIcDesignWins[]>(environment.inventoryServiceUrl),
      this.navigationService.getAllChannels(),
    ]).pipe(
      map(([values, allChannels]) => {
        const duplicatedChannels = this.haveDifferentNamesById(allChannels);
        if (duplicatedChannels.length > 0) {
          allChannels = this.replaceChannelName(duplicatedChannels, allChannels);
        }
        const allChannelIdNamePairs = allChannels.reduce((acc, curr) => {
          acc[curr.id] = curr.name;
          return acc;
        }, {} as any);

        const updatedValues = values.map((value: { [x: string]: any }) => {
          const updatedChannels = value[channelKeyName]?.split(';')
            .map((channelId: string) => (allChannelIdNamePairs[channelId] ? allChannelIdNamePairs[channelId] : ''))
            .filter((channelName: string) => channelName !== '')
            .join(', ');
          return { ...value, [channelKeyName]: updatedChannels };
        });
        return updatedValues;
      })
    );
  }

  private haveDifferentNamesById(allChannels: Channel[]): ResultArray[] {
    const data = allChannels;
    const resultMap = new Map<string, string[]>();

    data.forEach((item) => {
      if (resultMap.has(item.id)) {
        const names = resultMap.get(item.id);
        if (!names.includes(item.name)) {
          names.push(item.name);
        }
      } else {
        resultMap.set(item.id, [item.name]);
      }
    });

    const resultArray: ResultArray[] = [];
    resultMap.forEach((names, id) => {
      if (names.length > 1) {
        resultArray.push({ id, names });
      }
    });
    return resultArray;
  }

  /**
   * This logic goes through the array of allChannels and checkIfDuplicatedName.
   * It return the array obtained in case a channel has two names
   * @param checkIfDuplicatedName
   * @param allChannels
   */
  private replaceChannelName(checkIfDuplicatedName: ResultArray[], channels: Channel[]): any[] {
    const nameMap = new Map<string, string[]>();
    checkIfDuplicatedName.forEach((item: { id: string; names: string[] }) => {
      nameMap.set(item.id, item.names.sort((a,b) => a.localeCompare(b)));
    });

    channels.forEach((item: Channel) => {
      const updatedNames = nameMap.get(item.id);
      if (updatedNames) {
        item.name = updatedNames.join(', '); // Replace the 'name' field with the updated names
      }
    });
    return channels;
  }
}
