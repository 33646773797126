import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { DialogService } from '@shared/services/dialog/dialog.service';
import { WarningModalComponent } from '@app/@shared/components/warning-modal/warning-modal.component';
import { PlatformEntitlementRequestModalComponent } from '@app/@shared/components/platform-entitlement-request-modal/platform-entitlement-request-modal.component';
import { SubscriptionCard, Entitlement, AnalyticsMenu } from '@app/@shared/models/shared.models';
import { CustomerType, ReportAccess, ReportEntitlements } from '@app/@shared/models';
import { InsideTechEntitlement } from '@app/@shared/interfaces/inside-tech-entitlement.interface';

@Injectable({
  providedIn: 'root',
})
export class EntitlementService {
  private readonly HEADERS = new HttpHeaders().set('Accept', 'application/json');
  private entitlementUrl = environment.entitlementServiceUrl;
  private metaUrl = environment.metaServiceBaseUrl;

  constructor(
    private http: HttpClient,
    protected dialogService: DialogService,
  ) {}

  getReportAccess(reportCode: string): Observable<ReportAccess> {
    const url = `${this.entitlementUrl}/report/validate/${reportCode}`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const params = new HttpParams().set('url', environment.basePath);
    return this.http.get<ReportAccess>(url, { params, headers });
  }

  getAnalyticsEntitlements(): Observable<AnalyticsMenu> {
    const url = `${this.entitlementUrl}/subscriber/tableauAnalyticsChannelsEntitlement`;
    return this.http.get<any>(url);
  }

  getEntitlementList(): Observable<Array<Entitlement>> {
    const url = `${this.entitlementUrl}/entitlement-list`;
    return this.http.get<any>(url)
    .pipe(
      map(({ entitlements }) => entitlements)
    );
  }

  postSubscriptionAccessRequest(subscriptionName: string) : Observable<boolean> {
    const url = `${this.entitlementUrl}/entitlement/request`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const body = { subscriptionName };
    return this.http.post<any>(url, body, { headers });
  }

  postSubscriptionCommentRequest(subscriptionName: string, comment: string) : Observable<boolean>{
    const url = `${this.entitlementUrl}/entitlement/request`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const params = new HttpParams().set('comment', comment);

    const body = {
      subscriptionName
    }

    return this.http.post<any>(url, body, { headers, params });
  }

  postUnwatermarkedImageRequest(imageUrl: string, clObjectId: string, comment: string) : Observable<boolean>{
    const url = `${this.metaUrl}/assetDownloadRequest/unwatermarkedImage`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const params = new HttpParams().set('comment', comment);

    const body = {
      url: imageUrl,
      clObjectId,
    }

    return this.http.post<any>(url, body, { headers, params });
  }

  getCustomerType(): Observable<{ type: CustomerType }> {
    const url = `${this.entitlementUrl}/user/me/type`;
    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.get<any>(url, { headers });
  }

  openRequestAccessConfirmDialog(reportTitle: string, onClose?: () => void): void {
    const requestModalInstance = this.dialogService.open(WarningModalComponent,
      { size: 'sm', centered: true, windowClass: 'uui-modal' });
    requestModalInstance.componentInstance.props = {
      title: 'Your subscription does not include access to this content.',
      titleIconClass: 'icon-unentitled',
      body: 'Would you like to request access?',
      closeButtonLabel: 'Close',
      actionButtonLabel: 'Request Access',
    };
    requestModalInstance.result.then(() => {
      this.openRequestAccessFormDialog(reportTitle, onClose);
    }, onClose);
  }

  openRequestAccessFormDialog(reportTitle: string, onClose?: () => void): void {
    const requestFormModalInstance = this.dialogService.open(PlatformEntitlementRequestModalComponent,
      { size: 'lg', centered: true, windowClass: 'uui-modal' });
    requestFormModalInstance.componentInstance.reportTitle = reportTitle;
    requestFormModalInstance.result.then((formData) => {
      this.postSubscriptionCommentRequest(formData.subject, formData.topic)
        .pipe(
          catchError(() => {
            this.dialogService.openError();
            return of(null);
          })
        )
        .subscribe((success) => {
          if (success) {
            const modalServiceRef = this.dialogService.openGeneric({
              title: 'Entitlement Request sent!',
              body: 'Our team has received your request for access ' +
                'and will process your inquiry within 2 business days. ' +
                'We are looking forward to helping you!',
              closeButtonLabel: 'Done',
            });
            modalServiceRef.result.then(() => {}, onClose)
          }
        });
    }, onClose);
  }

  public displayEntitlementRequestPanel(subscriptionCard: SubscriptionCard, subscriptionEnable: boolean = false) {
    const modalInstance = this.dialogService.open(WarningModalComponent, {
      size: 'sm',
      centered: true,
      windowClass: 'uui-modal',
    });
    modalInstance.componentInstance.props = {
      title: 'Your subscription does not include access to this content.',
      titleIconClass: 'icon-unentitled',
      body: !subscriptionEnable ? 'Would you like us to contact you with more information about this subscription?' : '',
      closeButtonLabel: 'Maybe Later',
      actionButtonLabel: 'Contact Me',
      isSubscription: subscriptionEnable
    }
    modalInstance.result
      .then(() => {
        this.postSubscriptionAccessRequest(subscriptionCard.name)
          .pipe(
            catchError(() => {
              this.dialogService.openError();
              return of(null);
            })
          )
          .subscribe((success) => {
            if (success) {
              this.dialogService.openGeneric({
                title: 'Your request has been sent!',
                body: 'Our team has received your request for more ' +
                  'information and will process your inquiry within 2 business days. ' +
                  'We are looking forward to helping you!',
                closeButtonLabel: 'Done',
              });
            }
          });
      });
  }

  /**
   * Returns ReportEntitlement object from a report id
   *
   * @param reportId a report id
   */
  public getReportEntitlements(reportId: string): Observable<ReportEntitlements> {
    return this.http.get<any>(`${this.entitlementUrl}/report/${reportId}`, { headers: this.HEADERS })
      .pipe(
        catchError(() => {
          return of({
            id: '',
            name: '',
            code: '',
            deleted: false,
            sf_id_product2: '',
            download_enabled: {
                pdf: false,
                image: false,
                data_grid: false,
                circuit_vision: false,
            }
          })
        })
      );
  }

  public isTechLibraryImagesDownloadEnabled(): Observable<boolean> {
    return this.http.get<any>(`${this.entitlementUrl}/entitlement/insidetech/mine`)
      .pipe(
        map(({ download }) => download),
      );
  }

  public getTechLibraryEntitlement(): Observable<boolean> {
    const url = `${this.entitlementUrl}/entitlement/insidetech/mine`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const params = new HttpParams().set('url', environment.basePath);
    return this.http.get<any>(url, { params, headers }).pipe(
      map((result: InsideTechEntitlement) => !!result?.assetAvailabilityTimeEnum || !!result?.genealogy
    ));
  }
}
