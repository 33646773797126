/* eslint-disable no-console */
import {
  loadFilterTypesFailure,
  loadSavedSearches,
  loadSavedSearchesFailure,
  loadSavedSearchesSucess,
  saveUserSearchSuccess,
  saveUserSearchFailure
} from './../../Actions/search/search.actions';
import {
  IContentType,
  IFilterType,
  IReturnedSearchItem,
  ISearchResults,
  ISearchTerms
} from './../../Models/search/searchModels';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, exhaustMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {SearchService} from '@shared/services/search/search.service';
import {
  deleteSavedSearchFailure,
  deleteSavedSearchSuccess,
  loadFilterTypesSucess,
  loadSearchResults,
  loadSearchResultsFailure,
  loadSearchResultsSucess,
  SearchActionTypes
} from '@app/store/Actions/search/search.actions';
import {ToastrService} from 'ngx-toastr';
import {Store} from '@ngrx/store';
import {selectSearchResultsAmounts, selectSearchState} from '@app/store/Selectors/search/search.selectors';
import {Observable, of} from 'rxjs';

@Injectable()
export class SearchEffects {


  /**
   * Side effect for all the actions that would trigger a new search,
   * like changing content type, showing a saved search, sorting or scrolling
   */
  TriggerSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SearchActionTypes.setResultsViewType,
        SearchActionTypes.setViewContentType,
        SearchActionTypes.setUserSearch,
        SearchActionTypes.setSearchTerms,
        SearchActionTypes.setSearchSorting
      ),
      withLatestFrom(this.store.select(selectSearchState)),
      switchMap(([action, searchState]) => {
        /**
         * Behavior for triggering a saved search.
         * It will fetch the search query for the given saved search,
         * parse the query, and then trigger a search with the parsed object
         */
        if ((action as any).type === SearchActionTypes.setUserSearch) {
            return this._searchService.getUserSearchById((action as any).searchId)
              .pipe(map(userSearch => {
                const filters: IFilterType[] = this._searchService.mapFilterResponseToFilterTypes(
                  JSON.parse(userSearch.search_filters ? userSearch.search_filters : '[]')
                );
                let parsedContentType = JSON.parse(userSearch.search_view)[0];
                parsedContentType = parsedContentType ? parsedContentType : '';
                const selectedContentType: IContentType = {
                  key: parsedContentType,
                  label: parsedContentType
                }
                const searchTerms: ISearchTerms = {
                  searchText: JSON.parse(userSearch.search_terms ? userSearch.search_terms : '[]'),
                  selectedFilters: filters,
                  selectedContentType,
                  indexStart: 0,
                  indexEnd: 24,
                  source: 'userSearch'
                }
                return loadSearchResults({searchTerms})
              }))
          }
        /**
         * Behavior when sorting.
         * It will trigger a new search with the source as 'effect'.
         * See below the file how it affects LoadSearchResults$ effect
         */
        if ((action as any).type === SearchActionTypes.setSearchSorting) {
            return of(loadSearchResults({
              searchTerms: {
                ...searchState.currentSearchTerms,
                indexStart: 0,
                indexEnd: 20,
                source: 'effect'
              }
            }))
          }
        /**
         * Behavior when user scrolls
         */
          if ((action as any).type === SearchActionTypes.setSearchTerms) {
            // We choose the corresponding amount depending on the content type as reports have wider cards
            const rowLength = (searchState.currentSearchTerms.selectedContentType as IContentType).key === 'report'
              ? searchState.reportGridRowLength
              : searchState.gridRowLength;

            return of(loadSearchResults({
              searchTerms: {
                ...searchState.currentSearchTerms,
                indexStart: searchState.currentSearchTerms.source !== 'scrolling'
                  ? (action as any).searchTerms.indexStart
                  : searchState.currentSearchTerms.indexStart + searchState.currentSearchTerms.indexEnd,
                indexEnd: searchState.currentSearchTerms.source !== 'scrolling'
                  ? (action as any).searchTerms.indexEnd
                  : this.getAmountToFetch(searchState.viewType, rowLength),
                source: 'scrolling'
              }
            }))
          }
        /**
         * Behavior when setting the view content type.
         * We need to rerun the query since there are different limits of items between list and gridview
         */
        if ((action as any).type === SearchActionTypes.setViewContentType) {
          const rowLength = (action as any).contentType.key === 'report'
            ? searchState.reportGridRowLength
            : searchState.gridRowLength;

            return of(loadSearchResults({
              searchTerms: {
                ...searchState.currentSearchTerms,
                indexStart: 0,
                indexEnd: (action as any).contentType.key === ''
                  ? 5
                  : this.getAmountToFetch(searchState.viewType, rowLength),
                source: 'effect'
              }
            }))
          }
        // Returning the new action
          return of(loadSearchResults(
            {
              searchTerms: {
                ...searchState.currentSearchTerms,
                source: ((action as any).type === SearchActionTypes.setUserSearch) ? 'userSearch' : 'effect'
              }
            }
          ));
        }
      )
    )
  );

  /**
   * Side effect when triggering a search or an initial search
   */
  LoadSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActionTypes.loadSearchResults,
        SearchActionTypes.loadInitialSearchResults),
      withLatestFrom(this.store.select(selectSearchState)),
      switchMap(([action, searchState]) => {
        // If the following conditions are met, then we are looking for a specific content type.
        // If not, we trigger a search for all the content types
          if ((action as any).type === SearchActionTypes.loadSearchResults
            && (!Array.isArray(searchState.currentSearchTerms.selectedContentType)
              && ((searchState.currentSearchTerms.selectedContentType).key
                && searchState.currentSearchTerms.selectedContentType.key.length > 0))) {
            let prevResults: IReturnedSearchItem[] = searchState.searchResults[
              (searchState.currentSearchTerms.selectedContentType).key
              ]
            // Accumulating previous results to the new results in case of scrolling
            prevResults = Array.isArray(prevResults) && searchState.currentSearchTerms.source === 'scrolling'
              ? prevResults
              : [];
            return this.loadSearchResultsForSingleContentType(searchState.currentSearchTerms, prevResults);
          } else {
            return this.loadSearchResultsForAllContentTypes(searchState.currentSearchTerms);
          }
        }
      )
    )
  );

  /**
   * Side effect when saving an user search
   * Saves the user search and, if it's successful,
   * dispatches an action to notify the saved search success
   * and another to load the initial search results.
   *
   * In case of error, dispatches an error action with the message as the payload
   */
  SaveUserSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActionTypes.saveUserSearch),
      withLatestFrom(this.store.select(selectSearchResultsAmounts)),
      exhaustMap(([action, resultsAmounts]) =>
        this._searchService.saveUserSearch((action as any).userSearch)
          .pipe(switchMap(savedSearch => {
              return [
                saveUserSearchSuccess({
                  userSearch: {
                    ...savedSearch,
                    total_results: savedSearch.results_since_last_acknowledged
                      ? savedSearch.total_results
                      : resultsAmounts.all,
                    results_since_last_acknowledged: 0
                  }
                })
              ]
            }),
            catchError(async (err) => {
              return saveUserSearchFailure({error: err})
            })
          )
      )
    )
  )

  /**
   * Side effect for the load saved searches action
   *
   * Fetches the data from the API and dispatches the success/error action accordingly
   */
  LoadSavedSearches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActionTypes.loadSavedSearches),
      exhaustMap((action: any) => {
        return this._searchService.getUserSearches()
          .pipe(
            map(savedSearches => loadSavedSearchesSucess({savedSearches})),
            catchError(async (err) => {
              return loadSavedSearchesFailure({error: err})
            })
          )
      })
    )
  )

  /**
   * Side effect when deleting the saved search
   *
   * If the saved search does not exists, it's deleted from the store
   */
  DeleteSavedSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActionTypes.deleteSavedSearch),
      exhaustMap((action: any) =>
        this._searchService.deleteSavedSearchById(action.id).pipe(
          switchMap((res: boolean) => [
            deleteSavedSearchSuccess({id: action.id}),
            loadSavedSearches()
          ]),
          catchError(async (err) => {
            if (err.status === 404) {
              // Search id does not exists, so we proceed to delete it from the state
              return deleteSavedSearchSuccess({id: action.id})
            }
            // Show an error toast when there is a problem with the API
            // TODO: Handle other types of error regarding types and such
            // this.toastrService.error('Error', err);
            return deleteSavedSearchFailure({error: err});
          })
        )
      )
    )
  );

  /**
   * Side effect when loading filters
   *
   * Sets up the filters for the sidebar
   */
  LoadFilterTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActionTypes.loadFilterTypes),
      exhaustMap(action =>
        this._searchService.getFilters().pipe(
          switchMap((results: IFilterType[]) => [
            loadFilterTypesSucess({
              filterTypes: results
            })
          ]),
          catchError(async (err) => {
            // Show an error toast when there is a problem with the API
            // TODO: Handle other types of error regarding types and such
            // this.toastrService.error('Error', err);
            console.log(err);
            return loadFilterTypesFailure({error: err});
          })
        )
      )
    )
  );
  private readonly GRID_VIEW_ROW_AMOUNT = 5;

  constructor(private actions$: Actions,
              private _searchService: SearchService,
              private toastrService: ToastrService,
              private store: Store) {
  }

  /**
   * Maps the filters from the Search Service to a sorted array of filters that have at least one subtype,
   * and sets the active state for those filters which were used for the search
   * @param filters         list of filters
   * @param currentSearch   current search
   *
   */
  private mapFilters(filters: IFilterType[], currentSearch: any) {
    return filters.map(filter => {
      const mappedFilter = {
        ...filter,
        subTypes: filter.subTypes ? filter.subTypes.map(subtype => {
          const filterInSearch = currentSearch.selectedFilters
            .find((selectedFilter: IFilterType) => selectedFilter.key === filter.key);
          const value = filterInSearch != null
            ? filterInSearch.subTypes
            .find((stInSearch: IFilterType) =>
              stInSearch.key === subtype.key
              && stInSearch.value === true) != null
            : false
          return {
            ...subtype,
            value
          }
        }) : []
      };
      mappedFilter.subTypes.sort((subTypeA, subTypeB) => subTypeA.label <= subTypeB.label ? -1 : 1)
      return mappedFilter
    });
  }

  /**
   * Method to fetch the search results for a single content type.
   * Checks if the user is scrolling to avoid fetching the results amounts,
   * maps the updated results, and saves the current search in the local storage
   * @param currentSearch   current search
   * @param prevResults     previous results from the search before
   *
   */
  private loadSearchResultsForSingleContentType(currentSearch: ISearchTerms,
                                                prevResults: IReturnedSearchItem[]): Observable<any> {
    return this._searchService.getSearchResults(currentSearch).pipe(
      concatMap(searchResponse => {
        // If we are scrolling, we use the cache, if not, we make a new request
        const amountsOrigin$ = currentSearch.source === 'scrolling' ?
          this.store.select(selectSearchResultsAmounts)
          : this._searchService.getSearchResultsAmounts(currentSearch)
        return amountsOrigin$.pipe(
          map(amounts => {
            searchResponse[0].resultsAmount = amounts;
            return searchResponse;
          })
        )
      }),
      concatMap(searchResponse => {
        return this.mapNewAndUpdatedResults(searchResponse);
      }),
      switchMap(([searchResults, filters]) => {
        if (currentSearch.source !== 'effect') {
          this._searchService.saveInLocalstorage(currentSearch);
        }
        const filterTypes = this.mapFilters(filters, currentSearch)
        return [
          loadSearchResultsSucess(
            {
              results: {
                ...searchResults,
                [(currentSearch.selectedContentType as IContentType).key]: prevResults
                  .concat(searchResults[(currentSearch.selectedContentType as IContentType).key]),
                paginated: currentSearch.indexStart !== 0
              }
            }
          ),
          loadFilterTypesSucess(
            {
              filterTypes
            }
          )
        ];
      }),
      catchError(async (err) => {
        // Show an error toast when there is a problem with the API
        // TODO: Handle other types of error regarding types and such
        // this.toastrService.error('Error', err);
        console.log(err);
        return loadSearchResultsFailure({error: err});
      })
    );
  }

  /**
   * Method to fetch the search results for a all the content types.
   * Maps the updated results, and saves the current search in the local storage
   * @param currentSearch   current search
   *
   */
  private loadSearchResultsForAllContentTypes(currentSearch: ISearchTerms): Observable<any> {
    return this._searchService.getInitialSearchResults(currentSearch).pipe(
      concatMap(searchResponse => {
        return this.mapNewAndUpdatedResults(searchResponse);
      }),
      switchMap(([searchResults, filters]) => {
        if (currentSearch.source !== 'effect') {
          this._searchService.saveInLocalstorage(currentSearch);
        }
        const filterTypes = this.mapFilters(filters, currentSearch)
        return [
          loadSearchResultsSucess({results: searchResults}),
          loadFilterTypesSucess({filterTypes})
        ]
      }),
      catchError(async (err) => {
        // Show an error toast when there is a problem with the API
        // TODO: Handle other types of error regarding types and such
        // this.toastrService.error('Error', err);
        console.log(err)
        return loadSearchResultsFailure({error: err});
      }))
  }

  private mapNewAndUpdatedResults(
    searchResponse: [ISearchResults, IFilterType[]]
  ): Observable<[ISearchResults, IFilterType[]]> {
    // Check for new and updated items here
    const searchResults = searchResponse[0];
    const searchItemIds: string[] = [];

    Object.keys(searchResults).forEach(key => {
      if (key !== 'resultsAmount' && key !== 'newResultsAmount' && key !== 'paginated') {
        const resultsArray = searchResults[key];
        resultsArray.forEach((result: { idempotentId: string; }) => {
          // NOTICE!  This conditional is a temporary solution to the problem of multiple assets having the same
          //   idempotentId value. Not sure why this is happening. Have reached out to Joaquin Camacho about
          //   this as I beleive he added the idempotentId field to CL objects.
          if (!searchItemIds.includes(result.idempotentId)) {
            searchItemIds.push(result.idempotentId);
          }
        });
      }
    });

    const requestBody = JSON.stringify({Search_Item_Ids: searchItemIds});

    return this._searchService.getNewAndUpdatedResults(requestBody).pipe(
      map(newAndUpdated => {
        const newItems = newAndUpdated.Search_Item_Ids_Added;
        const updates = newAndUpdated.Search_Item_Ids_Updated;

        Object.keys(searchResults).forEach(key => {
          if (key !== 'resultsAmount' && key !== 'newResultsAmount' && key !== 'paginated') {
            const resultsArray = searchResults[key];

            resultsArray.forEach((result: { idempotentId: string, new?: boolean, updated?: boolean }) => {
              const idempotentId = result.idempotentId;
              if (updates.includes(idempotentId)) {
                result.updated = true;
              } else if (newItems.includes(idempotentId)) {
                result.new = true;
              }
            });
          }
        });

        return searchResponse;
      }),
      catchError(() => {
        return of(searchResponse);
      })
    );
  }

  /**
   * Gets the amount to fetch according the size of the screen
   * @param viewType  view type (list/grid)
   *
   */
  private getAmountToFetch(viewType: string, gridRowLength: number): number {
    // On list view elements have full width and a fixed height so we do the following calculation
    // to display the amount required
    if (viewType === 'LIST') {
      return Math.floor((window.innerHeight / 60) * 1.5);
    }
    // On grids, we get the corresponding number from the action and multiply it by a constatn of how many rows we want
    return gridRowLength * this.GRID_VIEW_ROW_AMOUNT;
  }
}
