import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { FavoriteCount, FavoriteContentType } from '@app/my-library/models/my-library.models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertService } from '../alert/alert-service';
import { AlertMessages } from '@app/@shared/models/alert';
import { ContentNavigatorTable, ContentNavigatorTableFavoriteRow } from '@app/@shared/models/content-navigator/content-navigator.model';

@Injectable({
  providedIn: 'root',
})
export class MyLibraryService {
  baseUrl = environment.userListServiceBaseUrl;

  constructor(private http: HttpClient, private alertService: AlertService) { }

  getFavorites(
    paginationParameters: PaginationParameters,
    type: FavoriteContentType,
  ): Observable<ContentNavigatorTable<ContentNavigatorTableFavoriteRow>> {
    return this.http
      .get(`${this.baseUrl}/mylibrary/favorites`, {
        params: {
          type,
          page: paginationParameters.page - 1,
          size: paginationParameters.size,
        },
      })
      .pipe(
        map((response: any): ContentNavigatorTable<ContentNavigatorTableFavoriteRow> => ({
          rows: response.contents.map(({ time, ...item }: any) => this.favoritesToTableRow(time, item, type)),
        }))
      );
  }

  addToFavorites(
    externalContentIds: string[],
    type: FavoriteContentType,
  ) {
    return this.http.post(`${environment.userListServiceBaseUrl}/mylibrary/favorites`, {
      externalContentIds,
      type,
    }).pipe(tap(
      () => {
        if(type === FavoriteContentType.ANALYSIS) {
          this.alertService.success(AlertMessages.FAVORITES_ADD_SUCCEDED_ANALYSIS);
        } else if (type === FavoriteContentType.IMAGE) {
          this.alertService.success(AlertMessages.FAVORITES_ADD_SUCCEDED_IMAGE);
        }
      },
      (error) => {
        if (error.status === 403) {
          this.alertService.error(AlertMessages.FAVORITES_ADD_LIMIT)
        } else {
          this.alertService.error(AlertMessages.FAVORITES_ADD_FAILED)
        }
      },
    ));
  }

  removeFavorites(ids: string[]): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/mylibrary/favorites`, {
      body: {
        ids,
      },
    }).pipe(tap(
      () => this.alertService.success(AlertMessages.FAVORITES_REMOVE_SUCCEDED),
      () => this.alertService.error(AlertMessages.FAVORITES_REMOVE_FAILED),
    ));
  }

  getFavoritesCount(): Observable<FavoriteCount> {
    return this.http.get<FavoriteCount>(`${this.baseUrl}/mylibrary/favorites/count`);
  }

  private favoritesToTableRow(
    time: string,
    item: any,
    contentType: FavoriteContentType,
  ): ContentNavigatorTableFavoriteRow {
    return {
      id: item.id,
      description: item.description ?? '',
      title: item.name,
      productCode: item.payload.reportCode,
      completionDate: new Date(time),
      entitled: item.entitled,
      selected: item.selected,
      contentType,
      assetGroupId: item.payload.assetGroupId,
      clObjectId: item.payload.clObjectId,
    }
  }
}
