import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { Analysis, IImageFilter } from '@app/@shared/models/reverse-engineering/analysis.models';
import { ImageFilterEventParam } from '@app/@shared/components/sidebar-image-filter/sidebar-image-filter.component';

@Component({
  selector: 'app-image-hierarchy',
  templateUrl: './image-hierarchy.component.html',
  styleUrls: [ './image-hierarchy.component.scss' ],
})
export class ImageHierarchyComponent {

  @Input() set currentAnalysis(analysis: Analysis) {
    this.analysis = analysis;
  }
  @Input() set imageFilters(filters: Array<IImageFilter>) {
    if (filters) {
      this.baseFolderKey = this.analysis.baseAssetGroup;
      this.processImageFilters(filters);
    }
  }
  @Input() sidebarOpen = false;
  @Input() paginationParameters: PaginationParameters;
  @Input() searchTerm = '';

  @Output() changeFilter = new EventEmitter<{ selectedFilter: IImageFilter; ignoreFilter: string }>();

  public analysis: Analysis;
  public baseFolderKey: string;
  public filterList: Array<IImageFilter>;
  public defaultFilterKey: string;

  public onClickFilter(params: ImageFilterEventParam): void {
    const selectedFilter = this.updateSelectedFilter(params.filter);
    this.changeFilter.emit({ selectedFilter, ignoreFilter: params.ignoreFilter });
  }

  public updateSelectedFilter(filter: string): IImageFilter {
    let selectedFilter: IImageFilter;
    if (filter === this.analysis.baseAssetGroup) {
      selectedFilter = {
        key: this.analysis.baseAssetGroup,
        label: 'All Source Images',
      };
    } else {
      selectedFilter = this.findFilter(this.filterList, filter);
      this.expandFilter(this.filterList, selectedFilter.parentKey, this.analysis.baseAssetGroup);
    }
    return selectedFilter;
  }

  private findFilter(filterList: IImageFilter[], key: string): IImageFilter {
    for (const item of filterList) {
      if (item.key === key) {
        return item;
      } else {
        if (item.children?.length) {
          const itemFound = this.findFilter(item.children, key);
          if (itemFound) {
            return itemFound;
          }
        }
      }
    }
  }

  private expandFilter(filterList: IImageFilter[], filterKey: string, baseFilterKey: string) {
    if (filterKey !== baseFilterKey) {
      const filter = this.findFilter(filterList, filterKey);
      filter.expanded = true;
      this.expandFilter(filterList, filter.parentKey, baseFilterKey);
    }
  }

  private processImageFilters(imageFilters: Array<IImageFilter>): void {
    this.filterList = imageFilters;
    if (!this.searchTerm) {
      const defaultReverseEng = 'report images';
      const defaultProgressTracker = 'images';
      this.defaultFilterKey = this.filterList?.find((item: IImageFilter) => {
        const newLabel = item.label.replace('_', ' ').toLowerCase();
        return newLabel === defaultReverseEng || newLabel === defaultProgressTracker;
      })?.key;
      this.onClickFilter({
        filter: this.defaultFilterKey ? this.defaultFilterKey : this.filterList[0]?.key,
        ignoreFilter: null,
      });
    } else {
      const currentFilter = this.findFilter(this.filterList, this.searchTerm);
      this.defaultFilterKey = currentFilter ? currentFilter.key : this.analysis.baseAssetGroup;
      this.updateSelectedFilter(this.defaultFilterKey);
    }
  }

}
