<div class="header">
  <h4 class="header4-text">TechStream</h4>
  <a class="body-text" (click)="onClickViewAll()">View All</a>
</div>
<div class="blog-cards">
  <app-blog-card
    *ngFor="let blog of blogs"
    [blog]="blog"
    [consistentHeight]="true"
    (click)="onClickBlog(blog)"
  ></app-blog-card>
</div>
