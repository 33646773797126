<div [ngClass]="(isMetadataPanelCollapsed | async) === true ? 'metadata-panel' : 'metadata-panel open'" class="scrollbar">
  <!--Display all metadata key value pairs-->
  <div [ngClass]="(isMetadataPanelCollapsed | async) === true ? 'meta-content closed' : 'meta-content'">
    <dl *ngIf="this.shouldDisplayMetadata()">
      <ng-template ngFor let-key [ngForOf]="this.metadata.getKeys()">
        <div *ngIf="this.shouldDisplayEntry(key)">
          <dt class="title">{{ key }}</dt>
          <dd [ngClass]="'detail ' + this.metadata.getCssClass(key)" [innerHTML]="this.metadata.getValue(key)"></dd>
        </div>
      </ng-template>
    </dl>
  </div>
</div>
