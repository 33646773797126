import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { SelectOption } from '@app/@shared/models/shared.models';

@Component({
  selector: 'app-pagination-highlight',
  templateUrl: './pagination-highlight.component.html',
  styleUrls: ['./pagination-highlight.component.scss']
})
export class PaginationHighlightComponent {
  @Input() currentPage: number;
  @Input() currentPageSize: number;
  @Input() selectedSort: SelectOption<string>;
  @Input() showHighlights: boolean;

  @Output() changePaginationOptions = new EventEmitter<PaginationParameters>();

  onToggleHighlight(event: any) {
    this.changePaginationOptions.emit(new PaginationParameters(
      this.currentPageSize,
      this.currentPage,
      this.selectedSort?.value,
      { showHighlights: event.target.checked },
    ));
  }
}
