import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationService} from '@shared/services/navigation/navigation.service';
import {PreviousPageService} from '@shared/services/previous-page.service';
import {moduleNameFromUrl} from '@shared/utils/common';

@Component({
  selector: 'app-retool-back-navigation',
  templateUrl: './retool-back-navigation.component.html',
  styleUrls: ['./retool-back-navigation.component.scss']
})
export class RetoolBackNavigationComponent implements OnInit {
  title: string;
  tagNameList: string[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navService: NavigationService,
    public prevPage: PreviousPageService,
  ) {}

  ngOnInit(): void {
    const moduleName = moduleNameFromUrl(this.router.url);
    this.navService.getSubscription(this.route.snapshot.params.subscriptionId, moduleName)
      .subscribe(subscription => {
        this.tagNameList = [subscription.name];
      });

    this.navService.getNavigationElementById(this.route.snapshot.params.appId, moduleName)
      .subscribe(e => {
        this.title = e.name;
      });
  }

  onClickBack(): void {
    this.router.navigate([ this.prevPage.getPreviousUrl() ]).then();
  }
}
