<app-image-hierarchy
  [sidebarOpen]="sidebarOpen"
  [currentAnalysis]="currentAnalysis"
  [imageFilters]="imageFilters"
  [searchTerm]="searchTerm"
  [paginationParameters]="paginationParameters"
  (changeFilter)="onChangeFilter($event)"
></app-image-hierarchy>
<app-image-files
  [class.sidebarOpen]="sidebarOpen"
  [currentAnalysis]="currentAnalysis"
  [title]="title"
  [analysisReports]="analysisReports"
  [paginationParameters]="paginationParameters"
  [searchTerm]="searchTerm"
  [showImageViewer]="showImageViewer"
  [selectedImage]="selectedImage"
  [isLoading]="isLoading"
  [channelId]="channelId"
  [subscriptionId]="subscriptionId"
  [downloadEnabled]="downloadEnabled"
  [addToFavoritesEnabled]="addToFavoritesEnabled"
  [enableUnwatermarkedImageRequest]="enableUnwatermarkedImageRequest"
  (changeSelectedImage)="onChangeSelectedImage($event)"
  (closeImageViewer)="onCloseImageViewer()"
  (changePaginationParameters)="onChangePaginationParameters($event)"
  (downloadImage)="onDownloadImage($event)"
  (downloadImages)="onDownloadImages($event)"
  (shareImage)="onShareImage($event)"
  (addToFavorites)="onAddToFavorites($event)"
></app-image-files>
