<div class="area-container scrollbar">
  <ng-container [ngSwitch]="documentAssetviewer">
    <app-report-list-view
      *ngSwitchCase="'report-list-view'"
      [currentAnalysis]="analysis"
      [analysisReportPage]="analysisReports"
      [isLoading]="isLoading"
      [annotatedDocuments]="annotatedDocuments"
      [currentPage]="paginationParameters.page"
      [pageSize]="paginationParameters.size"
      [relatedContentList] = "relatedContentList"
      [sidebarOpen] = "sidebarOpen"
      (itemViewClicked)="onItemViewClicked($event)"
      (changePaginationOptions)="onChangePaginationOptions($event)"
      (downloadAssetGroup)="onDownloadAssetGroup($event)"
      (documentNewTab)="onDocumentNewTab($event)"
      (documentShare)="onDocumentShare($event)"
    ></app-report-list-view>
    <app-document-pdf-loader
      *ngSwitchCase="'pdf-viewer'"
      [analysisCode]="analysisCode"
      [reportId]="selectedReportId"
      [channelId]="channelId"
      [subscriptionId]="subscriptionId"
      [isAnnotated]="getIsAnnotated()"
      (closePdfViewer)="onClosePdfViewer()"
    ></app-document-pdf-loader>
    <ng-container *ngIf="analysis">
      <app-quicksight-viewer
        *ngSwitchCase="'qs-viewer'"
        [assetId]="selectedAssetId"
        [analysisCode]="analysisCode"
        [analysisMetadata]="analysis"
        (closeAssetViewer)="onCloseAssetViewer()"
      ></app-quicksight-viewer>
    </ng-container>
  </ng-container>
</div>
<app-non-entitled-prompt
  *ngIf="!entitled"
  [channelNames]="channelNames"
  (clickPVO)="onClickPVO()"
  (clickRequest)="onClickRequest()"
></app-non-entitled-prompt>
