import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthRoutingModule } from './auth-routing.module';
import { TokenComponent } from './token/token.component';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NgbModule, AuthRoutingModule],
  declarations: [TokenComponent, LogoutComponent],
})
export class AuthModule {}
