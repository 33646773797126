import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TableViewerOptions, TableViewerRow } from '@app/@shared/models/table-viewer';
import { TableHelper } from '@app/@shared/utils/table-helper';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-table-viewer',
  templateUrl: './table-viewer.component.html',
  styleUrls: ['./table-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableViewerComponent implements OnInit {
  @Input() tableViewerOptions: TableViewerOptions;
  gridOptions: GridOptions;
  columns: Array<ColDef>;
  isTableSorted = false;
  isColumnFilterActive = false;
  defaultColumnsProps: Partial<ColDef> = {
    hide: false,
    resizable: true,
    width: 180,
  };

  private rows: TableViewerRow[];

  ngOnInit(): void {
    this.rows = this.tableViewerOptions.rows;
    this.columns = this.getColumnDefs(this.tableViewerOptions.columns);

    this.gridOptions = {
      rowData: this.getRowData(this.columns, this.rows),
      suppressCellSelection: true,
      suppressMovableColumns: true,
      suppressContextMenu: true,
      columnDefs: this.columns,
      defaultColDef: {
        sortable: true,
        filter: 'agSetColumnFilter',
        menuTabs: ['filterMenuTab'],
        comparator: (left, right) => TableHelper.stringComparator(left, right),
        cellRenderer: (params: ICellRendererParams<any, any>) => this.cellRenderer(params),
        wrapText: true,
        autoHeight: true,
      },
      onSortChanged: ({ columnApi }) => {
        const sortModel = columnApi.getColumns().filter(col => col.getSort() != null);

        this.isTableSorted = sortModel.length > 0;
      },
      onFilterChanged: ({ api }) => {
        this.isColumnFilterActive = api.isColumnFilterPresent();
      },
    }
  }

  handleClearAllFilters(): void {
    const { columns } = this.tableViewerOptions;
    this.columns = this.getColumnDefs(columns);
    this.gridOptions.api.setColumnDefs(this.columns);
    this.gridOptions.api.setFilterModel(null);
  }

  handleResetSort(): void {
    this.gridOptions.columnApi.resetColumnState();
  }

  handleHideColumnToggle(column: ColDef): void {
    const columnToggled = { ...column, hide: !column.hide };
    this.columns = this.columns.map(c => c.colId === column.colId ? columnToggled : c)
    this.gridOptions.columnApi.setColumnVisible(columnToggled.colId, !columnToggled.hide);
  }

  handleSearchValueChange(keyword: string): void {
    const filteredRowData = this.filterListByAnyKeyValue(this.gridOptions.rowData, keyword);
    this.gridOptions.api.setRowData(filteredRowData);
  }

  private getColumnDefs(columns: Array<ColDef>): Array<ColDef> {
    return columns.map(column => ({ ...this.defaultColumnsProps, ...column }));
  }

  /**
   * Returns an entity array
   * The entity model is formed by column names. And the value is taken from TableViewerRow
   */
  private getRowData(columns: Array<ColDef>, rows: Array<TableViewerRow>): Array<{[key: string]: string}> {
    return rows.map((tableViewerRow, index) => {
      const row = {};
      /* eslint-disable @typescript-eslint/dot-notation */
      row['originalIndex'] = index.toString(); // value not mapped to a column thus not visible
      /* eslint-enable @typescript-eslint/dot-notation */
      columns.forEach(({headerName}) => {
        row[headerName] = tableViewerRow[headerName].value;
      });
      return row;
    });
  }

  /**
   * Renders a cell in the agGrid table. If the cell contains a hyperlink, then inserts an <a> tag
   * Used by default to render every cell in the agGrid table
   */
  private cellRenderer(params: ICellRendererParams) {
    const tableViewerRows = this.rows[params.data.originalIndex]
    const { hyperlink } = tableViewerRows[params.colDef.headerName];
    if (hyperlink) {
      const legacyReportUrlWebApi = new URL(hyperlink);
      const uuiReportUrl = hyperlink.replace(legacyReportUrlWebApi.origin, window.location.origin);
      return `<a href="${uuiReportUrl}" target="_blank">${params.value}</a>`;
    } else {
      return params.value;
    }
  }

  private filterListByAnyKeyValue<T>(array: Array<T>, keyword: string): Array<T> {
    return array.filter(o =>
      Object.keys(o).some(k => o[k].toLowerCase().includes(keyword.toLowerCase())));
  }
}
