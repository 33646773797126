import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnInit
} from '@angular/core';
import {
  AnimationOptions,
  BMEnterFrameEvent
} from 'ngx-lottie';
import {
  AnimationItem,
  AnimationSegment
} from 'lottie-web';
import {
  Observable,
  Subscription
} from 'rxjs';

export const PROGRESS_PROGRESSBAR_ANIMATION: AnimationSegment = [
  0,
  100
];
export const SUCCESS_PROGRESSBAR_ANIMATION: AnimationSegment = [
  101,
  131
];
export const FAILURE_PROGRESSBAR_ANIMATION: AnimationSegment = [
  132,
  133
];

/**
 * A circular progress bar using Lottie to animate
 */
@Component(
  {
    selector: 'app-progress-bar-circular',
    templateUrl: './progress-bar-circular.component.html',
    styleUrls: ['./progress-bar-circular.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  }
)
export class ProgressBarCircularComponent implements OnInit {

  @Input()
  size: { width: number, height: number } = {
    width: 80,
    height: 80
  };
  @Input()
  playAnimation: Observable<void> = new Observable<void>();
  @Input()
  stopAnimation: Observable<void> = new Observable<void>();
  @Input()
  segments: AnimationSegment[] = [
    PROGRESS_PROGRESSBAR_ANIMATION,
    SUCCESS_PROGRESSBAR_ANIMATION
  ];
  options: AnimationOptions = {
    path: 'assets/animations/loading/loading-animation-undetermined.json',
    autoplay: true,
    loop: true
  };
  public currentFrame = -1;
  private animationItem: AnimationItem;
  private subs: Subscription = new Subscription();

  private _determined: boolean;

  get determined() {
    return this._determined;
  }

  @Input()
  set determined(value: boolean) {
    this._determined = value;
    if (this._determined) {
      this.options = {
        path: 'assets/animations/loading/loading-animation-determined.json',
        autoplay: false,
        loop: false
      };
    } else {
      this.options = {
        path: 'assets/animations/loading/loading-animation-undetermined.json',
        autoplay: true,
        loop: true
      };
    }
  }

  constructor(private ngZone: NgZone,
    private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  animationCreated($event: AnimationItem) {
    this.animationItem = $event;
    this.animationItem.setSpeed(1);
    this.animationItem.setSubframe(false);
    this.subs.add(
      this.playAnimation.subscribe(() => {
                                     this.ngZone.runOutsideAngular(() => {
                                       if (this._determined) {
                                         this.animationItem.playSegments(this.segments, true);
                                       } else {
                                         this.animationItem.play();
                                       }
                                     });
                                   }
      )
    );
    this.subs.add(
      this.stopAnimation.subscribe(() =>
                                     this.ngZone.runOutsideAngular(() => {
                                       this.animationItem.goToAndStop(0, true);
                                       this.currentFrame = -1;
                                     })
      )
    );
  }

  setFrame($event: BMEnterFrameEvent) {
    if (this.determined) {
      const totalTime = $event.totalTime === 100
                        ? $event.totalTime
                        : -1;
      this.currentFrame = Math.floor(($event.currentTime / totalTime) * 100);
      this.cdRef.detectChanges();
    }
  }

  complete() {
    this.currentFrame = -1;
  }
}
