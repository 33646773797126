<div class="row-container header-container">
  <div></div>
  <div class="header">Title</div>
  <div class="header center">Type</div>
  <div class="header">Publication Date</div>
  <div class="header">View Date</div>
  <div class="header center"></div>
</div>
<ng-container *ngIf="table">
  <a
    *ngFor="let row of table.rows"
    [href]="createRowUrl(row)"
    [attr._ti_content_navigator]="row.id"
    class="row-container"
    (click)="onClickRow(row)"
  >
    <div>
      <em *ngIf="!row.entitled" class="icon-locked icon small"></em>
      <em *ngIf="row.entitled && row.annotated"
        class="icon-edit1 icon small"
        [ngbTooltip]="'Document contains annotations'"
        placement="top"
      ></em>
    </div>
    <div class="emphasis truncate-paragraph-ellipsis line-clamp-2">
      {{ row.title }}
    </div>
    <div class="center icon-container">
      <span
        class="icon image"
        placement="top"
        [ngClass]="row.icon.style"
        [ngbTooltip]="row.icon.label"
      ></span>
    </div>
    <div>
      {{ row.publishDate? (row.publishDate |date: 'MMM d, yyyy' : '+0000') : 'In progress'}}
    </div>
    <div>
      {{ (row.viewDate |date: 'MMM d, yyyy' : '+0000')}}
    </div>
    <div>
      <a *ngIf="row.entitled"
        class="icon-external icon medium"
        [href]="row.url"
        (click)="$event.stopPropagation()"
        target="_blank"
        [ngbTooltip]="'Open in new tab'"
        placement="top"
        container="body"
      ></a>
    </div>
  </a>
</ng-container>
