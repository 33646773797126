<div class="modal-close-button" (click)="modal.dismiss('close')"><em class="icon-close_medium"></em></div>
<div class="content">
  <div class="title"><em class="icon-warning"></em>Oops... Something went wrong</div>
  <div class="body">
    An error has occurred while creating your download package. <br/>
    Please close this window and try again. If this issue persists, please<br/> <a class="link" routerLink="/">contact support</a>. 
    <!-- the contact support link is missing, I made a question in Sprint discussion and I will update the link as soon as possible -->
  </div>
  <div class="actions">
    <button class="close-btn" (click)="modal.dismiss('close')">Close</button>
  </div>
</div>
