<div
  *ngFor="let alert of alerts"
  class="alert {{cssClass(alert)}} alert-dismissable"
  [@fadeInOut]
>
  <em class="icon {{iconAlert(alert)}}"></em>
  <span class="alert-message" [innerHTML]="alert.message"></span>
  <em
    class="close-button icon-close_small"
    (click)="$event.stopPropagation(); removeAlert(alert)"
  ></em>
</div>
