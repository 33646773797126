<section class="view-options-bar">
  <app-pagination-top
    *ngIf="!isLoading && imageCount > 0"
    [count]="imageCount"
    [currentPage]="currentPage"
    [pageSizes]="pageSizes"
    [pageSize]="pageSize"
    [showSort]="false"
    (changePaginationOptions)="onChangePaginationOptions($event)"
  ></app-pagination-top>
</section>
<section class="content">
  <div class="image-grid-container scrollbar">
    <div class="title-header">
      <input
        [disabled]="!downloadEnabled"
        type="checkbox"
        class="checkbox custom-checkbox"
        [checked]="selectedImages.allSelected"
        (click)="clickSelectAll()"
      />
      <div>
        <span>{{ title ? title : 'Report Images' }}</span>
        <span *ngIf="!isLoading && images?.length > 0">( {{ images.length }} )</span>
      </div>
    </div>
    <div *ngIf="!isLoading && imageCount > 0; else noResults" class="element-grid">
      <div *ngFor="let image of images" class="element" [class.active]="image.selected">
        <div class="card-thumbnail">
          <div class="checkbox-container">
            <input
              [disabled]="!downloadEnabled"
              type="checkbox"
              class="checkbox custom-checkbox"
              (click)="clickSelectImage(image)"
              [checked]="image.selected"
            />
          </div>
          <app-image
            (click)="onClickImage(image)"
            class="image-container"
            [src]="image.thumbnailUrl"
            [alt]="image.description"
          ></app-image>
          <div class="dropdown">
            <app-meatball-menu
              [iconFontSize]="'inherit'"
            >
              <button
                (click)="onClickShare($event, image)"
              >
                <em class="icon-share"></em> Share
              </button>
              <button
                *ngIf="downloadEnabled"
                (click)="clickDownloadImage(image)"
              >
                <em class="icon-button_download"></em> Download
              </button>
              <button
                *ngIf="addToFavoriteEnabled"
                (click)="onClickAddToFavorites(image)"
              >
                <em class="icon-star"></em> Add to Favorites
              </button>
            </app-meatball-menu>
          </div>
        </div>
        <div class="card-footer truncate-ellipsis" (click)="onClickImage(image)">
          <div class="card-tagline truncate-ellipsis" title="{{ image.title }}">
            {{ image.name }}
          </div>
        </div>
      </div>
    </div>
    <ng-template #noResults>
      <div *ngIf="isLoading" class="loading">
        <app-progress-bar-circular></app-progress-bar-circular>
      </div>
      <div *ngIf="!isLoading" class="no-results">
        <img src="assets/images/icons/Icon_Null-Search.svg" alt="No results" />
        <div class="text">
          <span class="main-title">No results</span>
        </div>
      </div>
    </ng-template>
  </div>
  <app-download-panel
    *ngIf="selectedImages.selectedImagesQty > 0"
    class="download-panel"
    [downloadEnabled]="downloadEnabled"
    [selectedImages]="selectedImages"
    (allImagesSelected)="clickSelectAll($event)"
    (downloadImages)="onClickDownloadImages()"
  ></app-download-panel>
</section>
<section class="bottom-pagination">
  <app-pagination-bottom
    *ngIf="!isLoading && imageCount > pageSize"
    [count]="imageCount"
    [startingPage]="currentPage"
    [startingPageSize]="pageSize"
    (changePaginationOptions)="onChangePaginationOptions($event)"
  ></app-pagination-bottom>
</section>
