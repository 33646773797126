import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Observable, ReplaySubject, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, first, map, tap, timeout } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectUserProfile } from '@app/store/Selectors/workspace/workspace.selectors';
import { IUserProfile } from '@app/store/Models/settings/settingsModel';
import CryptoJS from 'crypto-js';
import { environment } from '@env/environment';
import { DatadogService } from '@shared/services/datadog.service';
import { AuthenticationService, CredentialsService, User } from '@app/auth';
import { EmbeddedURLRequestModel } from '@shared/models/embedded-url-request.model';
import { EmbeddedURLModel } from '@shared/models/embedded-url.model';

export const chmln = require('@chamaeleonidae/chmln');
export const PUBLIC_USER_KEY = 'public_user_id';

@Injectable({
  providedIn: 'root'
})
export class VendorIntegrationService {
  public defaultImageClass: any = window.Image;
  public proxyImage: any;
  public userProfile$ = this.store.select(selectUserProfile);

  private heapBehavior: ReplaySubject<void> = new ReplaySubject<void>(1);
  private heapEvents = new Map<string, Subject<void>>();

  private vendorUrl = environment.vendorIntegrationServiceBaseUrl;

  constructor(
    private readonly store: Store,
    private dataDogService: DatadogService,
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthenticationService,
    private httpClient: HttpClient,
    private credentialsService: CredentialsService
  ) {
    this.dataDogService.initialize();
  }

  /**
   * initialize loads all third party tools for normal app usage.
   *  Note that this is just for convenience.
   *  Any individual tools can be initialized as needed for special cases.
   */
  initialize(): void {
    this.initHeap().subscribe();
    this.initBeamer();
    this.initQualified();
    this.initChameleon();
    this.initDemandBase();
    this.initQuickSight();
    this.initWistia();
  }
  get heap$(): Observable<void> {
    return this.heapBehavior.asObservable().pipe(first()) as Observable<void>;
  }

  /**
   * initializeTeaserShell loads all third party tools for the public teaser page.
   *  Note that this is just for convenience.
   *  Any individual tools can be initialized as needed for special cases.
   */
  initializeTeaserShell(): void {
    this.initHeap();
    this.initDemandBase();
    this.initGoogleAnalytics();
    this.initClarity();
  }

  /**
   * initHeap runs the Heap analytics loader script.
   *  It checks if Heap is already loaded (a window.heap object will exist).
   *  It then gets user info and injects the loader script into the document head,
   *  passing in the Heap app ID and user information.
   */
  initHeap(): Observable<void> {
    if ((window as any).heap) {
      return this.heap$;
    }

    return this.authService.me().pipe(first()).pipe(
      concatMap((user: User) => {
        return this.loadHeapScript().pipe(tap(() => {
          window['heap'].identify(user.email);
          window['heap'].addUserProperties({
            domain: user.email?.split('@')[1] ?? 'Unknown',
            salesForceContactId: user.salesForceUserId,
            authId: user.id,
            Persona: user.persona,
            userId: user.email
          });
        }));
      }),
      catchError(() => this.loadHeapScript())
    ) as Observable<void>;
  }

  /**
   * initBeamer injects the Beamer widget scripts into the document head,
   *  passing in user information to identify them.
   */
  initBeamer(): void {
    this.userProfile$.pipe(
      first(),
      filter((profile: IUserProfile) => !!profile?.email)
    ).subscribe((profile: IUserProfile) => {
      // Beamer documentation can be found here:
      // https://www.getbeamer.com/docs
      const beamerConfigScript = this.document.createElement('script');
      beamerConfigScript.type = 'application/javascript';
      beamerConfigScript.innerHTML =
        'var beamer_config={' +
        'product_id: "iBobeSDl56092",' +
        'mobile: true,' +
        'selector: "#notifications_button",' +
        'button: false,' +
        'counter: true,' +
        'display: "right",' +
        `user_email: "${profile.email}"` +
        '};';
      this.document.head.appendChild(beamerConfigScript);
      const beamerScript = this.document.createElement('script');
      beamerScript.type = 'application/javascript';
      beamerScript.src = 'https://app.getbeamer.com/js/beamer-embed.js';
      beamerScript.async = true;
      this.document.head.appendChild(beamerScript);
    });
  }

  /**
   * Removes Beamer from application, including all elements and bindings inserted by the script.
   */
  destroyBeamer(): void {
    (window as any).Beamer?.destroy();
  }

  /**
   * initQualified injects Qualified live chat widget scripts into the document head,
   *  passing in user information to identify them.
   */
  initQualified(): void {
    this.userProfile$.pipe(
      first(),
      filter((profile: IUserProfile) => !!profile?.email)
    ).subscribe(
      (profile: IUserProfile) => {
        // Append the Qualified widget script to the document head:
        const widgetScript = this.document.createElement('script');
        widgetScript.type = 'application/javascript';
        widgetScript.innerHTML =
          '(function(w,q){w[\'QualifiedObject\']=q;w[q]=w[q]||function(){(w[q].q=w[q].q||[]).push(arguments)};})' +
          `(window,'qualified');qualified('identify', { email: '${profile.email}' });`;
        this.document.head.appendChild(widgetScript);
        // Append the Qualified live chat script to the document head:
        const liveChatScript = this.document.createElement('script');
        liveChatScript.src = `https://js.qualified.com/qualified.js?token=${environment.qualifiedLiveChatToken}`;
        liveChatScript.async = true;
        this.document.head.appendChild(liveChatScript);
        // Temporary workaround for the widget not responding over an iframe bug:
        //  This forces pointer-events: auto on the widget iframe,
        //  and means you won't be able to click though transparent parts of the widget.
        //  It's better to have it working until Qualified fixes the bug though.
        //  See https://techinsights.atlassian.net/browse/SWSD-3125
        const widgetStyleOverride = this.document.createElement('style');
        widgetStyleOverride.innerHTML =
          'iframe#q-messenger-frame.disable-pointer-events { pointer-events: auto !important; }' +
          'iframe#q-messenger-frame.full-height { min-height: fit-content !important; }';
        this.document.head.appendChild(widgetStyleOverride);
      }
    );
  }

  initChameleon(): void {
    this.userProfile$.pipe(first()).subscribe(userProfile => {
      const domain = userProfile.email.split('@')[1];
      chmln.init(environment.chamaeleonToken, { fastUrl: environment.chamaeleonFastUrl });
      chmln.identify(userProfile.id, {
        uid_hash: this.calculateChameleonUiHash(userProfile.id),
        email: userProfile.email,
        company: {
          uid: domain,
          name: domain
        }
      } as any);
    });
  }

  initChameleonAnonymously(): void {
    let publicUserId = localStorage.getItem(PUBLIC_USER_KEY);
    if (!publicUserId) {
      publicUserId = crypto.randomUUID();
      localStorage.setItem(PUBLIC_USER_KEY, publicUserId);
    }
    chmln.init(environment.chamaeleonToken, { fastUrl: environment.chamaeleonFastUrl });
    chmln.identify(publicUserId, {
      visitor: true,
      uid_hash: this.calculateChameleonUiHash(publicUserId)
    });
  }

  /**
   * Removes Chameleon instance.
   */
  destroyChameleon(): void {
    (window as any).chmln?.clear();
  }

  initDemandBase(): void {
    const demandBaseScript = this.document.createElement('script');
    demandBaseScript.src = 'https://tag.demandbase.com/d522da51.min.js';
    demandBaseScript.type = 'application/javascript';
    demandBaseScript.async = true;
    demandBaseScript.id = 'demandbase_js_lib';
    this.document.head.appendChild(demandBaseScript);
  }

  /**
   * initQuickSight adds some style overrides for the QuickSight iframe
   *  which is loaded as needed elsewhere.
   */
  initQuickSight(): void {
    const widgetStyleOverride = this.document.createElement('style');
    widgetStyleOverride.innerHTML = 'iframe.quicksight-embedding-iframe { display: block; height: 100%; }';
    this.document.head.appendChild(widgetStyleOverride);
  }

  /**
   * initWistia injects the Wistia script into the document head.
   */
  initWistia(): void {
    const wistiaScript = this.document.createElement('script');
    wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    wistiaScript.type = 'application/javascript';
    wistiaScript.async = true;
    this.document.head.appendChild(wistiaScript);
  }

  /**
   * initGoogleAnalytics injects the Google Tag Manager scripts into the document head.
   * https://techinsights.atlassian.net/browse/CP-18825
   */
  initGoogleAnalytics(): void {
    // Leaving the ID as an empty string will disable loading it
    if (environment.googleAnalyticsId) {
      const googleTagManagerScript = this.document.createElement('script');
      googleTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
      googleTagManagerScript.type = 'application/javascript';
      googleTagManagerScript.async = true;
      this.document.head.appendChild(googleTagManagerScript);
      const googleTagManagerConfigScript = this.document.createElement('script');
      googleTagManagerConfigScript.type = 'application/javascript';
      googleTagManagerConfigScript.innerHTML =
        'window.dataLayer=window.dataLayer||[];' +
        'function gtag(){dataLayer.push(arguments);}' +
        'gtag("js",new Date());' +
        `gtag("config","${environment.googleAnalyticsId}");`;
      this.document.head.appendChild(googleTagManagerConfigScript);
    }
  }

  /**
   * initClarity injects the Clarity script into the document head.
   * https://techinsights.atlassian.net/browse/CP-18825
   */
  initClarity(): void {
    // Leaving the ID as an empty string will disable loading it
    if (environment.clarityId) {
      const clarityScript = this.document.createElement('script');
      clarityScript.type = 'application/javascript';
      clarityScript.innerHTML =
        '(function(c,l,a,r,i,t,y){' +
        'c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};' +
        't=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;' +
        'y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})' +
        `(window,document,"clarity","script","${environment.clarityId}");`;
      this.document.head.appendChild(clarityScript);
    }
  }

  getEmbeddedURL(
    embeddedURLRequestModel: EmbeddedURLRequestModel
  ): Observable<EmbeddedURLModel> {
    const embedUrl = `${this.vendorUrl}/retool/embed-url/external-user`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    embeddedURLRequestModel.metadata = {
      ...embeddedURLRequestModel.metadata,
      authToken: `${this.credentialsService.getAccessToken()}`
    };
    return this.httpClient.post<EmbeddedURLModel>(embedUrl, embeddedURLRequestModel, { headers });
  }

  isUserEntitledToRetoolApp(appId: string): Observable<boolean> {
    return this.httpClient.get(`${this.vendorUrl}/retool/is-user-entitled-to-app/${appId}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  submitPardotForm(formId: string, data: any): Observable<any> {
    const url = new URL(`${environment.vendorIntegrationServiceBaseUrl}/pardot/form`);

    url.searchParams.append('formId', formId);

    return this.httpClient.post(url.href, data);
  }

  public loadHeapScript(): Observable<void> {
    if ((window as any).heap) {
      return this.heap$;
    }
    (window as any).heap = (window as any).heap || [];
    const heap = (window as any).heap;
    heap.load = (appId: string, config?: any) => {
      heap.appid = appId;
      heap.config = config || {};
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://cdn.heapanalytics.com/js/heap-${appId}.js`;

      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);
      const methods = [
        'addEventProperties',
        'addUserProperties',
        'clearEventProperties',
        'identify',
        'resetIdentity',
        'removeEventProperty',
        'setEventProperties',
        'track',
        'unsetEventProperty',
        'getSessionId'
      ];
      const createMethod = (methodName: string) => {
        return (...args: any[]) => {
          heap.push([methodName, ...args]);
        };
      };
      methods.forEach(method => {
        heap[method] = createMethod(method);
      });
      this.heapBehavior.next();
    };
    heap.load(environment.heapAppId);
    return this.heap$;
  }

  // Use this method in favor of directly calling heap.track as it will handle timeouts and errors
  public heapTrack(event: string, properties: any) {
    return this.initHeap().pipe(concatMap(() => {
      if (window.Image === this.proxyImage) {
        const customEventId = crypto.randomUUID();

        const subject = new Subject<any>();

        this.heapEvents.set(customEventId, subject);

        window['heap'].track(event, {
          ...properties,
          customEventId
        });

        return subject.asObservable().pipe(
          timeout(5000),
          catchError(err => {
            if (err.name === 'TimeoutError') {
              return throwError(() => new Error('Timeout'));
            }
            return throwError(() => err);
          })
        );
      } else {
        window['heap'].track(event, {
          ...properties
        });
        return of(null);
      }
    }));
  }

  public rollbackHeapImageProxy() {
    (window as any).Image = this.defaultImageClass;
  }

  public initHeapImageProxy() {
    if(!this.proxyImage) {
      this.proxyImage = this.createProxyImage();
    }

    (window as any).Image = this.proxyImage;
  }

  public createProxyImage() {
    const originalImage = this.defaultImageClass;
    const heapEvents = this.heapEvents;

    return function(height?: number, width?: number) {
      const img = new originalImage(height, width);

      return new Proxy(img, {
        set(target, property, value) {
          if (property === 'src') {
            const url = new URL(value);

            const params = new URLSearchParams(url.search);

            params.forEach((paramValue, key) => {
              if (paramValue === 'customEventId') {
                const customEventId = params.getAll(key)[1];

                img.onload = () => {
                  const subject = heapEvents.get(customEventId);

                  if (subject) {
                    subject.next();
                    subject.complete();
                    heapEvents.delete(customEventId);
                  }
                };

                img.onerror = () => {
                  const subject = heapEvents.get(customEventId);

                  if (subject) {
                    subject.error(new Error('Error loading image'));
                    heapEvents.delete(customEventId);
                  }
                };
              }
            });
          }

          target[property] = value;

          return true;
        }
      });
    };
  }

  /**
   * calculates the user id hash version for Identity Verification
   * @param userId user identification
   * @returns uid hash
   */
  private calculateChameleonUiHash(userId: string): string {
    // chameleon algorithm to calculate uid hash
    // you need to
    // 1. create a HMAC of whatever you will use as 'user id' (as for now, the email), using
    //    SHA256 algorithm with 'secret'
    // 2. digest (toString) with Hex encoding
    // 3. Concatenate 'digest-now'
    const secret = environment.chamaeleonSecret;
    const now = Math.floor(Date.now() / 1000);
    return CryptoJS.HmacSHA256(`${userId}-${now}`, secret).toString(CryptoJS.enc.Hex) + '-' + now;
  }

}
