import {
  deleteSavedSearch,
  deleteSavedSearchFailure,
  deleteSavedSearchSuccess,
  saveUserSearchFailure,
  saveUserSearchSuccess,
  setScrollIndex,
  setSearchSorting,
  setUserSearch,
  toggleFiltersCollapsed,
  toggleHierarchyCollapsed,
  toggleMetadataPanelCollapsed
} from './../../Actions/search/search.actions';
import {createReducer, on} from '@ngrx/store';
import {
  loadFilterTypes,
  loadFilterTypesFailure,
  loadFilterTypesSucess,
  loadInitialSearchResults,
  loadSavedSearches,
  loadSavedSearchesFailure,
  loadSavedSearchesSucess,
  loadSearchResults,
  loadSearchResultsFailure,
  loadSearchResultsSucess,
  saveRecentSearch,
  saveUserSearch,
  SearchActionTypes,
  setResultsViewType,
  setSearchTerms,
  setViewContentType,
} from '@app/store/Actions/search/search.actions';
import {
  IContentType,
  IFilterType,
  ISavedSearch,
  ISearchLoadingState,
  ISearchResults,
  ISearchTerms,
} from '@app/store/Models/search/searchModels';

export const searchFeatureKey = 'search';

export interface SearchState {
  filterTypes: IFilterType[];
  contentTypes: IContentType[];
  currentSearchTerms: ISearchTerms;
  searchHistory: ISearchTerms[];
  userSavedSearches: ISavedSearch[];
  viewType: string;
  searchResults: ISearchResults;
  loadingState: ISearchLoadingState;
  scrollIndex: number;
  gridRowLength: number;
  reportGridRowLength: number;
  isFiltersMenuCollapsed: boolean;
  isHierarchyPanelCollapsed: boolean;
  isMetadataPanelCollapsed: boolean;
  initialSearch: boolean;
}

export const initialSearchState: SearchState = {
  filterTypes: [],
  contentTypes: null,
  currentSearchTerms: {
    selectedFilters: [],
    searchText: [],
    selectedContentType: {
      key: '',
      label: '',
      amount: 0,
    },
    indexStart: 0,
    indexEnd: 20,
    sortMethod: {
      key: 'relevance',
      order: 'descending'
    }
  },
  searchHistory: [],
  userSavedSearches: [],
  viewType: 'GRID',
  scrollIndex: 0,
  gridRowLength: 0,
  reportGridRowLength: 0,
  searchResults: {
    resultsAmount: {
      all: 0,
      images: 0,
      pcb: 0,
      products: 0,
      components: 0,
      reports: 0,
      dies: 0,
      schematics: 0,
    },
  },
  loadingState: {
    loadingFilters: false,
    loadingSavedSearches: false,
    loadingSearchResults: false,
    savingUserSearch: false
  },
  isFiltersMenuCollapsed: false,
  isHierarchyPanelCollapsed: false,
  isMetadataPanelCollapsed: true,
  initialSearch: true
};

export const searchReducer = createReducer(
  initialSearchState,
  on(setScrollIndex, (state, action) => {
    return {
      ...state,
      scrollIndex: action.scrollIndex
    }
  }),
  on(loadFilterTypes, (state) => {
    return {
      ...state,
      loadingState: {
        ...state.loadingState,
        loadingFilters: true,
      },
    };
  }),
  on(loadFilterTypesSucess, loadFilterTypesFailure, (state, action) => {
    if (action.type === SearchActionTypes.loadFilterTypesFailure) {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          loadingFilters: false,
        },
      };
    }
    return {
      ...state,
      filterTypes: action.filterTypes,
      loadingState: {
        ...state.loadingState,
        loadingFilters: false,
      },
    };
  }),
  on(loadSearchResults, loadInitialSearchResults, (state,
                                                   action) => {
    let searchText: string[] = action.searchTerms && action.searchTerms.searchText
      ?
      action.searchTerms.searchText
      : state.currentSearchTerms.searchText;
    searchText = [].concat.apply([], searchText);


    let selectedContentType: IContentType | IContentType[] = action.searchTerms
    && action.searchTerms.selectedContentType
      ? action.searchTerms.selectedContentType
      : state.currentSearchTerms.selectedContentType;

    if (Array.isArray(selectedContentType)) {
      selectedContentType = selectedContentType.filter(v => v.key != null && v.key.length > 0)
    } else if (selectedContentType === null
      || selectedContentType.key === null
      || selectedContentType.key.length === 0) {
      selectedContentType = { key: '', label: '' };
    }
    return {
      ...state,
      currentSearchTerms: action.searchTerms
        ? {
          selectedFilters: action.searchTerms.selectedFilters
            ? action.searchTerms.selectedFilters
            : state.currentSearchTerms.selectedFilters,
          searchText,
          selectedContentType,
          indexStart: action.searchTerms.indexStart
            ? action.searchTerms.indexStart
            : initialSearchState.currentSearchTerms.indexStart,
          indexEnd: action.searchTerms.indexEnd
            ? action.searchTerms.indexEnd
            : initialSearchState.currentSearchTerms.indexEnd,
          source: action.searchTerms.source,
          sortMethod: state.currentSearchTerms.sortMethod,
        }
        : initialSearchState.currentSearchTerms,
      loadingState: {
        ...state.loadingState,
        loadingSearchResults: true
      },
      initialSearch: false
    }
  }),
  on(loadSearchResultsSucess, loadSearchResultsFailure, (state, action) => {
    if (action.type === SearchActionTypes.loadSearchResultsFailure) {
      return {
        ...state,
        searchResults: {
          ...initialSearchState.searchResults
        },
        loadingState: {
          ...state.loadingState,
          loadingSearchResults: false,
        },
      };
    }
    if (!Array.isArray(state.currentSearchTerms.selectedContentType)) {
      return {
        ...state,
        searchResults: {
          ...action.results
        },
        loadingState: {
          ...state.loadingState,
          loadingSearchResults: false
        }
      }
    } else {
      return state;
    }
  }),
  on(loadSavedSearches, (state) => {
    return {
      ...state,
      loadingState: {
        ...state.loadingState,
        loadingSavedSearches: true,
      }
    };
  }),
  on(loadSavedSearchesSucess, loadSavedSearchesFailure, (state, action) => {
    if (action.type === SearchActionTypes.loadSavedSearchesFailure) {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          loadingSavedSearches: false,
        },
      };
    }
    return {
      ...state,
      userSavedSearches: action.savedSearches,
      loadingState: {
        ...state.loadingState,
        loadingSavedSearches: false,
      },
    };
  }),
  on(setResultsViewType, (state, action) => {
    return {
      ...state,
      viewType: action.viewType,
      searchResults: {
        ...state.searchResults,
      },
      currentSearchTerms: {
        ...state.currentSearchTerms,
        indexStart: 0,
        indexEnd: 20
      }
    };
  }),
  on(setViewContentType, (state, action) => {
    return {
      ...state,
      currentSearchTerms: {
        ...state.currentSearchTerms,
        selectedContentType: action.contentType
      },
      searchResults: {
        ...initialSearchState.searchResults,
        resultsAmount: state.searchResults.resultsAmount
      },
      scrollIndex: 0,
      gridRowLength: action.gridRowLength,
      reportGridRowLength: action.reportGridRowLength
    }
  }),
  on(saveUserSearchSuccess, saveUserSearchFailure, (state, action) => {
    if (action.type === SearchActionTypes.saveUserSearchFailure) {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          savingUserSearch: false
        }
      }
    } else {
      const search: ISavedSearch = {
        date_created: action.userSearch.date_created,
        date_updated: action.userSearch.date_updated,
        last_acknowledged_date: action.userSearch.last_notification_date,
        last_notification_date: action.userSearch.last_notification_date,
        saved_search_id: action.userSearch.saved_search_id,
        name: action.userSearch.name,
        notifications_enabled: action.userSearch.notifications_enabled,
        results_since_last_acknowledged: action.userSearch.results_since_last_acknowledged,
        total_results: action.userSearch.total_results,
        search_boolean_operators: '[]'
      }
      return {
        ...state,
        userSavedSearches: [...state.userSavedSearches, search],
        loadingState: {
          ...state.loadingState,
          savingUserSearch: false
        }
      }
    }
  }),
  on(saveUserSearch, (state, action) => {
    return {
      ...state,
      loadingState: {
        ...state.loadingState,
        savingUserSearch: true
      }
    }
  }),
  on(setUserSearch, (state, action) => {
    return {
      ...state
    }
  }),
  on(saveRecentSearch, (state) => {
    return {
      ...state,
      searchHistory: [state.currentSearchTerms, ...state.searchHistory]
    }
  }),
  on(setSearchTerms, (state, action) => {
    return {
      ...state,
      currentSearchTerms: {
        selectedFilters: action.searchTerms.selectedFilters
          ? action.searchTerms.selectedFilters
          : state.currentSearchTerms.selectedFilters,
        searchText: action.searchTerms.searchText ? action.searchTerms.searchText : state.currentSearchTerms.searchText,
        selectedContentType: action.searchTerms.selectedContentType
          ? action.searchTerms.selectedContentType
          : state.currentSearchTerms.selectedContentType,
        indexStart: action.searchTerms.indexStart ? action.searchTerms.indexStart : state.currentSearchTerms.indexStart,
        indexEnd: action.searchTerms.indexEnd ? action.searchTerms.indexEnd : state.currentSearchTerms.indexEnd,
        sortMethod: state.currentSearchTerms.sortMethod,
        source: action.searchTerms.source,
      },
      scrollIndex: action.searchTerms.source === 'scrolling' ? state.scrollIndex : 0
    };
  }),
  on(toggleFiltersCollapsed, (state) => {
    return {
      ...state,
      isFiltersMenuCollapsed: !state.isFiltersMenuCollapsed,
    };
  }),

  on(toggleHierarchyCollapsed, (state) => {
    return {
      ...state,
      isHierarchyPanelCollapsed: !state.isHierarchyPanelCollapsed,
    };
  }),

  on(toggleMetadataPanelCollapsed, (state) => {
    return {
      ...state,
      isMetadataPanelCollapsed: !state.isMetadataPanelCollapsed
    }
  }),

  on(deleteSavedSearch, (state) => {
    return {
      ...state,
      loadingState: {
        ...state.loadingState,
        loadingSavedSearches: true
      }
    }
  }),

  on(deleteSavedSearchSuccess, deleteSavedSearchFailure, (state, action) => {
    if (action.type === SearchActionTypes.deleteSavedSearchFailure) {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          loadingSavedSearches: false
        }
      }
    }
    return {
      ...state,
      userSavedSearches: state.userSavedSearches.filter(x => x.saved_search_id !== action.id),
      loadingState: {
        ...state.loadingState,
        loadingSavedSearches: false
      }
    }
  }),
  on(setSearchSorting, (state, action) => {
    return {
      ...state,
      searchResults: {
        ...initialSearchState.searchResults,
        resultsAmount: state.searchResults.resultsAmount
      },
      currentSearchTerms: {
        ...state.currentSearchTerms,
        sortMethod: {
          key: action.sortMethod.key ? action.sortMethod.key : state.currentSearchTerms.sortMethod.key,
          order: action.sortMethod.order ? action.sortMethod.order : state.currentSearchTerms.sortMethod.order,
        },
      },
      scrollIndex: 0
    };
  })
);
