import {
  ANALYSIS_TAB_GROUP,
  DASHBOARD_CONTENT_NAVIGATOR_TAB_GROUP,
  TabGroup,
} from '../content-navigator/content-navigator.model';
import {TabItem} from '../shared.models';
import {
  MARKET_SEGMENTS_PAGE_TABS,
  RE_LANDING_PAGE_CUSTOM_TABS,
  RE_LANDING_PAGE_TABS,
  RE_LOGIC_LANDING_PAGE_TABS,
  SA_ANALYTICS_LANDING_PAGE_TABS,
  SA_LANDING_PAGE_CUSTOM_TABS,
  SA_LANDING_PAGE_TABS,
} from './navigation.constants';

/**
 * Enums
 */
export enum NavigationObjectType {
  MODULE = 'MODULE',
  SUBSCRIPTION = 'SUBSCRIPTION',
  DASHBOARD = 'DASHBOARD',
  TABLEAU = 'TABLEAU',
  RETOOL = 'RETOOL',
  CHANNEL = 'CHANNEL',
  DATABASE_TOOLS = 'DATABASE_TOOL',
  REPORT_MODULE = 'REPORT_MODULE',
  REPORT_MODULE_ITEM = 'REPORT_MODULE_ITEM',
}

export enum NavigationHint {
  SIDEBAR = 'SIDE_BAR',
  CARD_LIST = 'CARD_LIST',
  CHANNEL_CARD = 'CHANNEL_CARD',
  DASHBOARD_CARD = 'DASHBOARD_CARD',
  SUBSCRIPTION_CARD = 'SUBSCRIPTION_CARD',
  PRODUCT_LIST = 'PRODUCT_LIST',
  LINK = 'LINK',
  AG_GRID = 'AG_GRID',
  TABLEAU = 'TABLEAU', // Should be refactored to use NavigationObjectType.TABLEAU
  TABLEAU_DASHBOARD = 'TABLEAU_DASHBOARD',
  RETOOL_DASHBOARD = 'RETOOL_DASHBOARD',
}

export enum NavigationModules {
  HOME = 'Home',
  TECH_LIBRARY = 'TechLibrary',
  SEARCH = 'Search',
  REVERSE_ENGINEERING = 'Reverse Engineering',
  STRATEGY_ANALYTICS = 'Market Analysis',
  MANUFACTURING_ANALYSIS = 'Manufacturing Analysis',
  PROCESSOR_ANALYSIS = 'Processor Analysis',
  THE_MCCLEAN_REPORT = 'The McClean Report',
  MARKET_SUMMARY = 'Executive Insights',
  DATABASE_TOOLS = 'Database Tools',
  PROGRESS_TRACKER = 'Progress Tracker',
  SEMINARS = 'Seminars',
  MARKET_SEGMENTS = 'Solutions',
  END_MARKET_ANALYSIS = 'Market Analysis',
  SPECIAL_CONTENT = 'Special Content',
  SUSTAINABILITY = 'Sustainability',
  MY_LIBRARY = 'My Favorites',
  PRIVATE_PUBLIC_SUBSCRIPTIONS_CHANNELS_MODULE = 'Private-Public Subscriptions/Channels module',
  PRIVATE_SUBSCRIPTIONS_CHANNELS_MODULE = 'Private Subscriptions/Channels module',
  COMPANY_OVERVIEW = 'Company Overview',
  SEMI_GPT = 'Insightful Chat',
  MY_TECHINSIGHTS = 'My TechInsights',
}

/**
 * Types for the unparsed tree
 */
export interface NavigationTreeRoot {
  application: string;
  useCases: string[];
  children: NavigationTreeNode[];

  [x: string]: any;
}

export interface NavigationTreeChild {
  type: NavigationObjectType;
  name: string;
  isCustom: boolean;
  container: NavigationHint;
  content: NavigationHint;
  module_url?: string;
  module_id?: string;
  id?: string;
  sfId?: string;
  children?: NavigationTreeNode[];

  [x: string]: any;
}

export type NavigationTreeNode = NavigationTreeRoot | NavigationTreeChild;

/**
 * Parsed types to be used in the UI
 */
export abstract class NavigationElement {
  name: string;
  id?: string;
  type: NavigationObjectType;
  children: NavigationElement[] = [];
  content: NavigationHint;
  container: NavigationHint;
  isCustom?: boolean;
  route?: string;
}

export abstract class Subscription extends NavigationElement {
  type = NavigationObjectType.SUBSCRIPTION;
  totalChannels: number;
  availableChannels: number;
  icon: string;
  briefingFilename: string;
  productOverviewUrl: string;
  roadmaps: Array<Roadmap>;
  entitled: boolean;
  wordpressUrl?: string;

  abstract getContentNavigatorTabs (): Array<TabGroup>;

  abstract getLandingPageTabs (): Array<TabItem>;
}

export class DefaultSubscription extends Subscription {
  getContentNavigatorTabs (): TabGroup[] {
    return [];
  }

  getLandingPageTabs (): TabItem[] {
    return [];
  }
}

export class RESubscriberSubscription extends Subscription {
  private hasAnalytics = false;

  constructor (isLogicSubscription: boolean = false) {
    super();
    this.hasAnalytics = isLogicSubscription;
  }

  getContentNavigatorTabs (): TabGroup[] {
    return [ANALYSIS_TAB_GROUP];
  }

  getLandingPageTabs (): TabItem[] {
    return this.hasAnalytics ? RE_LOGIC_LANDING_PAGE_TABS : RE_LANDING_PAGE_TABS;
  }
}

export class RECustomSubscription extends Subscription {
  getContentNavigatorTabs (): TabGroup[] {
    return [ANALYSIS_TAB_GROUP];
  }

  getLandingPageTabs (): TabItem[] {
    return RE_LANDING_PAGE_CUSTOM_TABS;
  }
}

export class SASubscriberSubscription extends Subscription {
  private hasAnalytics: boolean;

  constructor (hasAnalytics = false) {
    super();
    this.hasAnalytics = hasAnalytics;
  }

  getContentNavigatorTabs (): TabGroup[] {
    return [ANALYSIS_TAB_GROUP, DASHBOARD_CONTENT_NAVIGATOR_TAB_GROUP];
  }

  getLandingPageTabs (): TabItem[] {
    return this.hasAnalytics ? SA_ANALYTICS_LANDING_PAGE_TABS : SA_LANDING_PAGE_TABS;
  }
}

export class SACustomSubscription extends Subscription {
  getContentNavigatorTabs (): TabGroup[] {
    return [ANALYSIS_TAB_GROUP];
  }

  getLandingPageTabs (): TabItem[] {
    return SA_LANDING_PAGE_CUSTOM_TABS;
  }
}

export class MarketSegmentSubscription extends Subscription {
  getContentNavigatorTabs (): TabGroup[] {
    return [ANALYSIS_TAB_GROUP];
  }

  getLandingPageTabs (): TabItem[] {
    return MARKET_SEGMENTS_PAGE_TABS;
  }
}

export class Channel extends NavigationElement {
  type = NavigationObjectType.CHANNEL;
  entitled: boolean;
  reports?: any[];
  reportCount = 0;
  subscriptionId?: string;
  modulePath?: string;
  module?: string;
}


export function isChannel (element: NavigationElement): element is Channel {
  return element.type === NavigationObjectType.CHANNEL;
}

export class Dashboard extends NavigationElement {
  type = NavigationObjectType.DASHBOARD;
  entitled: boolean;
  reports?: any[];
  reportCount = 0;
  subscriptionId?: string;
  modulePath?: string;
  module?: string;
  channelSfId?: string;
  dashboardUrl?: string;
  dashboardId?: string;
}

export class ReportModule extends NavigationElement {
  type = NavigationObjectType.REPORT_MODULE;
  entitled: boolean;
  reports?: any[];
  reportCount = 0;
}

export class SidebarItem extends NavigationElement {
  type = NavigationObjectType.MODULE;
  class: string;
  tooltip: string;
  route: string;
  icon_class: string;
  animation_type: string;
  animation_asset: string;
  hasNewInfo: boolean;
  dashboardId?: string;
}

/**
 * Navigation Service Errors
 */

export class NavigationElementMappingError extends Error {
}

export class DatabaseTools {
  type = NavigationObjectType.DATABASE_TOOLS;
  name: string;
  database_tool_id: string;
  content: NavigationHint;
}

export interface Roadmap {
  name: string;
  type: string;
  url: string;
  dashboardType: string;
  dashboardEmbedString?: string;
}

export class MenuItem {
  name: string;
  link: string;
  id: string;
  active?: boolean;
}

export class MegaMenu extends MenuItem {
  primaryMenu: {
    header: string
    items: MenuItem[]
  }[]
  secondaryMenu: {
    header: string
    items: MenuItem[]
  }[]
}

export interface SideBarVisibility {
  filterType: string;
  filterValue: string;
}

export interface Sidebar {
  name: string;
  link: string;
  icon: string;
  visibility?: SideBarVisibility;
}

export interface SidebarContainer {
  header: string;
  items: Sidebar[],
}

export interface SideBarResponse {
  containers: SidebarContainer[];
}
