<div class="form-group">
  <textarea
    [name]="props.name"
    [class]="props.class"
    [class.input-error]="_control.invalid && (_control.dirty || _control.touched)"
    [class.load-value]="textValue!=null"
    (focus)="onFocus() "
    (blur)="onBlur()"
    [ngModel]="textValue"
    (ngModelChange)="handleChange($event)"
    [class.focus-value]="focused"
    [rows]="props.rows"
    [cols]="props.cols"
    [maxlength]="props.maxLength || null"
    [minlength]="props.minLength || null"
    [disabled]="disabled"
    [required]="required"
    [placeholder]="props.placeholder || ''"
  >{{textValue}}</textarea>
  <label [class.label-error]="_control.invalid"
         [for]="props.name">{{props.label || ''}}
  </label>
</div>
