import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import {map, concatMap, catchError, mergeMap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReverseEngineeringService } from '@app/@shared/services/reverse-engineering/reverse-engineering.service';
import { StrategyAnalyticsService } from '@app/strategy-analytics/services/strategy-analytics.service';
import { MarketAnalysisService } from '@app/@shared/services/market-analysis/market-analysis.service';
import { MarketSegmentsService } from '@app/market-segments/service/market-segments.service';
import { SubscriptionCard } from '@app/@shared/models/shared.models';
import { ReportProgressSubscription } from '@app/settings/models/reportsProgressSubcription';
import { DigestEmailPreference } from '@app/settings/models/digestEmailPreferences';
import { NotificationConfig } from 'src/app/settings/models/notification';
import { Analysis } from '@shared/models/reverse-engineering/analysis.models';
import {ReportProgressWithDataModel} from '@app/settings/models/report-progress-with-data.model';
import {MetaService} from '@shared/services/meta.service';

export const NAMES_SEPARATOR = ' / ';

interface PreferenceInfo {
  displayName: string;
  icon: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationSettingsService {
  private metaUrl = environment.metaServiceBaseUrl;
  private entitlementUrl = environment.entitlementServiceUrl;

  constructor(
    private http: HttpClient,
    private reverseEngineeringService: ReverseEngineeringService,
    private strategyAnalyticsService: StrategyAnalyticsService,
    private marketAnalysisService: MarketAnalysisService,
    private marketSegmentsService: MarketSegmentsService,
    private metaService: MetaService
  ) {}

  getNotificationSetup(): Observable<NotificationConfig> {
    const url = `${this.entitlementUrl}/user/me/notification-config`;
    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.get<NotificationConfig>(url, { headers });
  }

  updateNotificationSetup(request: NotificationConfig): Observable<any> {
    const url = `${this.entitlementUrl}/user/me/notification-config`;
    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.post<NotificationConfig>(url, request, { headers });
  }

  getAllDigestEmailPreferences(): Observable<DigestEmailPreference[]> {
    return forkJoin({
      maSubs: this.marketAnalysisService.getSubscriptionExcerpts(),
      reSubs: this.reverseEngineeringService.getSubscriptionExcerpts(),
      saSubs: this.strategyAnalyticsService.getSubscriptionExcerpts(),
      msSubs: this.marketSegmentsService.getSubscriptionExcerpts(),
    })
    .pipe(
      map(({maSubs, reSubs, saSubs, msSubs}) => {
        return [...maSubs, ...reSubs, ...saSubs, ...msSubs];
      }),
      concatMap((subscriptions) => this.getPreferences(subscriptions))
    );
  }

  saveDigestEmailPreferences(digestEmailPreference: DigestEmailPreference) {
    const url = `${this.metaUrl}/preference/digest-email-preferences`;
    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.put<DigestEmailPreference>(url, digestEmailPreference, { headers });
  }

  getReportsDataByProgressSubscriptions(): Observable<ReportProgressWithDataModel[]> {
    return this.getReportProgressSubscriptions().pipe(mergeMap(reportProgressSubs => {
      const reports: Observable<ReportProgressWithDataModel>[] = reportProgressSubs.map(
        progress => this.metaService.getFullReportByCode(progress.reportCode).pipe(map(report => {
          return new ReportProgressWithDataModel(report, progress);
        }))
      );
      if(reports.length>0){
        return forkJoin(reports);
      } else {
        return of([])
      }
    }));
  }

  getReportProgressSubscriptions(): Observable<ReportProgressSubscription[]> {
    const url = `${this.metaUrl}/progress-tracker`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get<ReportProgressSubscription[]>(url, { headers });
  }

  getReportProgressState(report: Analysis): Observable<ReportProgressSubscription> {
    const url = `${this.metaUrl}/progress-tracker/${report.id}`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get<ReportProgressSubscription>(url, { headers }).pipe(
      catchError(() => {
        return of({
          reportId: report.id,
          reportName: report.name,
          reportCode: report.code,
          notifyFirstWipDeliverable: false,
          notifyAnalysisPublished: false,
          noFirstWipInProgressTracker: true,
        })
      })
    );
  }

  followReportProgress(report: Analysis): Observable<any> {
    const url = `${this.metaUrl}/progress-tracker`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const reportProgress = {
      reportId: report.id,
      reportName: report.name,
      reportCode: report.code,
      notifyFirstWipDeliverable: true,
      notifyAnalysisPublished: true,
      channel: report.channel,
      subscription: report.subscriptionName,
    }
    return this.http.post<any>(url, reportProgress, { headers });
  }

  saveReportProgressSubscription(reportProgress: ReportProgressSubscription): Observable<ReportProgressSubscription> {
    const url = `${this.metaUrl}/progress-tracker`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.post<ReportProgressSubscription>(url, reportProgress, { headers });
  }

  deleteReportProgressSubscription(reportId: string): Observable<boolean | any> {
    const url = `${this.metaUrl}/progress-tracker/subscriptions/${reportId}`;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.delete(url, { headers });
  }

  private getPreferences(subscriptions: SubscriptionCard[]): Observable<DigestEmailPreference[]> {
    const url = `${this.metaUrl}/preference/digest-email-preferences`;
    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.get<DigestEmailPreference[]>(url, { headers })
    .pipe(
      map((preferences: DigestEmailPreference[]) => {
        return preferences.map(p => {
          const { displayName, icon } = this.getPreferenceInfo(p.subscriptionId, subscriptions);
          return {
            ...p,
            subscriptionName: displayName ?? p.subscriptionName,
            icon,
          }
        })
        .sort((a, b) => a.subscriptionName.localeCompare(b.subscriptionName));
      })
    );
  }

  private getPreferenceInfo(subId: string, subscriptions: SubscriptionCard[]): PreferenceInfo {
    let displayName: string;
    let icon: string;
    const candidates = subscriptions.filter(s => s.id === subId);

    switch (candidates.length) {
      case 0:
        displayName = null;
        icon = 'icon-default';
        break;
      case 1:
        displayName = candidates[0].name;
        icon = candidates[0].icon;
        break;
      default:
        displayName = [... new Set(candidates.map(s => s.name))].join(NAMES_SEPARATOR);
        icon = 'icon-default';
    }
    return {displayName, icon};
  }
}
