import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ContentCardInterface} from '@shared/interfaces/content-card.interface';

@Component({
  selector: 'app-content-card-container',
  templateUrl: './content-card-container.component.html',
  styleUrls: ['./content-card-container.component.scss']
})
export class ContentCardContainerComponent {
  @Input() isContentLoading = false;
  @Input() cards: ContentCardInterface[] = [];
  @Output() cardClick: EventEmitter<ContentCardInterface> = new EventEmitter<ContentCardInterface>();

  onCardClick(card: ContentCardInterface) {
    this.cardClick.emit(card);
  }
}
