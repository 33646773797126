import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ContentNavigatorTableComponent } from '../../content-navigator/content-navigator-table/content-navigator-table.component';
import { BaseContent, CTIContentType } from '@app/@shared/models/search/cti-search.model';
import { NoAvailableContentMessage } from '@app/@shared/consts/messages';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteHelper } from '@app/@shared/utils/route-helper';
import { MyLibraryService } from '@app/@shared/services/my-library/my-library.service';
import { DialogService } from '@app/@shared/services/dialog/dialog.service';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { NavigationModules } from '@app/@shared/models/navigation/navigation.models';

@Component({
  selector: 'app-list-all',
  templateUrl: './list-all.component.html',
  styleUrls: ['./list-all.component.scss']
})
export class ListAllComponent extends ContentNavigatorTableComponent<BaseContent> implements OnInit {
  @Input() results: Array<BaseContent>;
  @Input() moduleName: NavigationModules;
  @Input() hideMeatballMenu: boolean;

  @ViewChild('blog', { static: true }) blog: TemplateRef<any>;
  @ViewChild('report', { static: true }) report: TemplateRef<any>;
  @ViewChild('video', { static: true }) video: TemplateRef<any>;
  tplsMap: Map<string, TemplateRef<any>|null> = new Map();

  noContentTitle = NoAvailableContentMessage.defaultMessage.title;
  noContentSubTitle = NoAvailableContentMessage.defaultMessage.subTitle;
  parseInt = parseInt;

  constructor(
    myLibraryService: MyLibraryService,
    dialogService: DialogService,
    router: Router,
    route : ActivatedRoute,
    ffService: FeatureFlagService,
  ) {
    super(myLibraryService, dialogService, router, route, ffService);
    this.hideMeatballMenu = false;
  }

  ngOnInit(): void {
    this.tplsMap.set('blog', this.blog);
    this.tplsMap.set('report', this.report);
    this.tplsMap.set('video', this.video);
  }

  getTemplate(type: string): TemplateRef<any> | null {
    return this.tplsMap.get(type) ?? null;
  }

  protected createContentTypeUrl(contentTypeRow: BaseContent): string {
    let fragment = '';
    if(contentTypeRow.type === CTIContentType.VIDEO) {
      fragment = `subscription=${encodeURIComponent(contentTypeRow.subscriptionName || contentTypeRow.chanelName)}&name=${encodeURIComponent(this.moduleName)}`;
    }
    return this.router.serializeUrl(
      this.router.createUrlTree(contentTypeRow.contentUrl, {
        fragment,
        // if we are not in a module (which shouldn't happen)
        // we go to the shell which has an analysis-view route
        relativeTo: RouteHelper.getModuleRouteOrShell(this.route),
      })
    );
  }

}
