<app-title-bar
  [tagNameList]="[subscriptionName]"
  [title]="title"
  (clickBack)="onClickBack()"
  [showBackButton]="prevPage.hasHistory()"
></app-title-bar>
<div class="content-container scrollbar">
    <div class="expanding-search-bar-container">
         <app-generic-search-bar
          [class] ="hiddenSearchButton ? 'shrinked-search-bar' : 'expanded-search-bar'"
          [hiddenSearchButton]="hiddenSearchButton"
          [chipList]="searchTerm"
          [searchPlaceholder]="searchPlaceholder"
          (searchTermsChanged)="onChangeSearchTerms($event)"
          (focusSearchbar)="onFocusSearchbar()"
          (blurSearchbar)="onBlurSearchbar($event)"
        ></app-generic-search-bar>
     </div>
     <div class="loading-indicator" *ngIf="loading; else loaded">
      <app-progress-bar-circular></app-progress-bar-circular>
    </div>
    <ng-template #loaded>
      <app-tabbed-analysis-list
        [count]="count"
        [activeTab]="activeTab"
        [tabGroups]="tabGroups"
        [activeTabGroup]="activeTabGroup"
        [pageSizes]="pageSizes"
        [pageSize]="paginationParameters.size"
        [page]="paginationParameters.page"
        [showHighlightToggle]="showHighlightToggle"
        [showHighlights]="paginationParameters.showHighlights"
        [filters]="filters"
        [loading]="loadingPageChange"
        [searchTerm]="searchTerm"
        [filtersCollapsed]="filtersCollapsed"
        [sortOptions]="sortOptions"
        [defaultSort]="selectedSortOption"
        [breadcrumbString]="subscriptionName === 'All Verticals' ? moduleName : subscriptionName"
        (changePaginationOptions)="onChangePaginationOptions($event)"
        (clickTab)="onClickTab($event)"
        (changeFilters)="onChangeFilters($event)"
        (toggleFilters)="onToggleFilters($event)"
      >
    </app-tabbed-analysis-list>
    </ng-template>
  </div>
