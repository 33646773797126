import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { Analysis, AnalysisAsset, AnalysisReportPage, AssetGroup, RelatedContent } from '@app/@shared/models/reverse-engineering/analysis.models';

@Component({
  selector: 'app-document-files',
  templateUrl: './document-files.component.html',
  styleUrls: [ './document-files.component.scss' ],
})
export class DocumentFilesComponent {

  @Input() set currentAnalysis(analysis: Analysis) {
    this.analysis = analysis;
    this.entitled = analysis ? analysis.entitled : true;
  }
  @Input() analysisReports: AnalysisReportPage;
  @Input() paginationParameters: PaginationParameters;
  @Input() annotatedDocuments: Array<string>;
  @Input() searchTerm: string;
  @Input() isLoading = false;
  @Input() subscriptionName: string;
  @Input() channelNames: string;
  @Input() channelId: string;
  @Input() subscriptionId: string;
  @Input() selectedReportId: string;
  @Input() selectedAssetId: string;
  @Input() analysisCode: string;
  @Input() documentAssetviewer = 'report-list-view';
  @Input() relatedContentList: RelatedContent[];
  @Input() sidebarOpen = false;

  @Output() changePaginationParameters = new EventEmitter<PaginationParameters>();
  @Output() clickDocument = new EventEmitter<AnalysisAsset>();
  @Output() closeDocument = new EventEmitter<void>();
  @Output() closeAssetViewer = new EventEmitter<void>();
  @Output() downloadAssetGroup  = new EventEmitter<AssetGroup>();
  @Output() clickPVO = new EventEmitter<any>();
  @Output() clickRequest = new EventEmitter<any>();
  @Output() documentNewTab = new EventEmitter<AnalysisAsset>();
  @Output() documentShare: EventEmitter<AnalysisAsset> = new EventEmitter<AnalysisAsset>();

  public analysis: Analysis;
  public entitled = true;

  public onItemViewClicked(report: AnalysisAsset): void {
    this.clickDocument.emit(report);
  }

  public onChangePaginationOptions(paginationParams: PaginationParameters): void {
    this.changePaginationParameters.emit(paginationParams);
  }

  public onDownloadAssetGroup(report: AssetGroup){
    this.downloadAssetGroup.emit(report)
  }

  public onDocumentNewTab(report: AnalysisAsset): void {
    this.documentNewTab.emit(report);
  }

  public onDocumentShare(report: AnalysisAsset): void {
    this.documentShare.emit(report);
  }

  public onClickPVO(): void {
    this.clickPVO.emit();
  }

  public onClickRequest(): void {
    this.clickRequest.emit();
  }

  public onClosePdfViewer() {
    this.closeDocument.emit();
  }

  public onCloseAssetViewer() {
    this.closeAssetViewer.emit();
  }

  getIsAnnotated() {
    return this.annotatedDocuments.find(x => x === this.selectedReportId) !== null;
  }

}
