import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '@env/environment';
import { UserType } from '@app/auth';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {

  constructor(
    private httpClient: HttpClient,
  ) {}

  getResource(userType: UserType): any {
    switch(userType) {
      default:
        return {
          whatsNew: environment.publicWhatsNew,
          apiUserGuide: `${environment.publicPolicyBaseUrl}/api/`,
          termsConditions: `${environment.publicPolicyBaseUrl}/legal/?d=terms-and-conditions`,
          privacyPolicy: `${environment.publicPolicyBaseUrl}/legal/?d=privacy-policy`,
          cookiePolicy: `${environment.publicPolicyBaseUrl}/legal/?d=cookie-policy`,
          dataProtection: `${environment.publicPolicyBaseUrl}/legal/?d=data-protection`,
          listOfSubprocesses: `${environment.listOfSubprocessesUrl}`,
        }
    }
  }

  getPreLoginPageBanners(): Observable<Object> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.get(environment.preLoginPageBannerMessageUrl, { headers });
  }
}
