
<div class="header-info">
  <h4 class="header4-text">Input value</h4>
</div>
<section class="calibration-form" [formGroup]="calibrationForm">
  <app-input
    [props]="scaleRefProps"
    formControlName="scaleRef"
    (blurInput)="onBlurInput('scaleRef')"
  ></app-input>
  <it-select
    class="it-select"
    [placeholder]="initialUnitsOption.value"
    [label]=""
    [options]="unitsOptions"
    [initialOption]="initialUnitsOption"
    [editable]="true"
    [upward]="true"
    (changeOption)="onChangeUnits($event)"
    (blurInput)="onBlurInput('units')"
  ></it-select>
</section>
<div class="buttons-container calibration-button-container">
  <button class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
  <button class="btn btn-primary" (click)="onApply()" [disabled]="!calibrationForm.valid">Apply</button>
</div>
