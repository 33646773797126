<div class="header" #headerElement>
  <div class="input-container" #searchContainerElement>
  </div>
  <div class="comments-counter" #commentsCounterElement>
  </div>
  <div class="sort-row">
    <button
      class="filter-annotation-button"
      type="button"
      data-element="filterAnnotationButton"
      aria-label="Filter"
      (click)="onFilterClicked()"
    >
      Filter <span *ngIf="filtersCount>0">&nbsp;({{filtersCount}})</span>
    </button>
    <div class="sort-container" data-element="sortContainer" #sortContainerElement>
    </div>
  </div>
</div>
