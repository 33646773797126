<button
  (click)="onClick($event)"
  [class.active]="getIsActive()"
  [class.external]="external"
  [class]="menuItem?.class"
  [routerLink]="menuItem?.route"
  appClickOutside
  [routerLinkActive]="''"
  [id]="menuItem?.class"
  #routeActive="routerLinkActive"
>
  <div class="button-icon-container">
    <div class="button-icon">
      <div [ngClass]="getIconClass(routeActive.isActive)" class="nav-icon"></div>
      <div *ngIf="menuItem?.hasNewInfo" class="marker"></div>
    </div>
  </div>
  <span *ngIf="!isCollapsed" [@inOutAnimation]>{{ menuItem.label }}</span>
</button>
