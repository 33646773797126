import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export interface ICheckboxProps {
  class?: string,
  placeholder?: string,
  disabled?: boolean
  label?: string,
  key: string,
  initialValue?: boolean
}

export interface ICheckbox {
  key: string;
  value: boolean;
  disabled: boolean;
}

/**
 * A general purpose checkbox component with implementation to accept ng-model and formcontrol
 */
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  elements= [
    false,
    true,
  ]

  get props(): ICheckboxProps [] {
    return this._props;
  }
  @Input()
  set props(value: ICheckboxProps[]) {
    if(this.currentValue && value && this.currentValue.length !== value.length){
      this.currentValue = value.map(v => {return {
        key: v.key,
        value: v.initialValue,
        disabled: v.disabled,
      }})
    }
    this._props = value;
  }

  public disabled: boolean;

  public currentValue: any = [];

  private _props: ICheckboxProps[];

  constructor() { }

  onTouched = () => {
  };

  onChange = (value: any) => {
  };

  ngOnInit(): void {
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if(!obj || obj.length === 0 || !this.currentValue || this.currentValue.length === 0){
      return;
    }
    this.onChange(obj);
    this.currentValue = obj;
  }

  handleChange(currentValue: any, i: number) {
    this.currentValue = Object.assign([], this.currentValue);
    this.currentValue[i] = {
      ...this.currentValue[i],
      value: this.currentValue[i].value ? !this.currentValue[i].value : true
    };
    this.writeValue(this.currentValue);
  }
}


