import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy, RouterModule, UrlSerializer } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { RouteReusableStrategy } from './route-reusable-strategy';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { AuthInterceptorService } from './http/auth-interceptor.service';
import { MockDataInterceptor } from './http/mock-data.interceptor';
import { RetryRequestInterceptor } from '@core/http/retry-request.interceptor';
import { ParamsSerializerInterceptor } from '@core/http/params-serializer.interceptor';
import { CustomUrlSerializer } from '@core/tools/custom-url-serializer';
import { ContentLakeService } from '@shared/services/content-lake.service'
import { MetaService } from '@shared/services/meta.service';
import { JanetService } from '@shared/services/janet.service'
import { ResourcesService } from '@shared/services/resources.service';
import { PdfTronWebViewerWrapper } from '@shared/utils/pdftron-webviewer-wrapper';
import { TableauService } from '@shared/services/tableau.service';
import { NonEntitledInterceptor } from './http/non-entitled.interceptor';

@NgModule({ imports: [CommonModule,
        RouterModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiPrefixInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RetryRequestInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ParamsSerializerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MockDataInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NonEntitledInterceptor,
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusableStrategy,
        },
        {
            provide: UrlSerializer,
            useClass: CustomUrlSerializer,
        },
        ContentLakeService,
        MetaService,
        JanetService,
        ResourcesService,
        PdfTronWebViewerWrapper,
        TableauService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
