<div class="pagination-bottom-container">
  <div class="icon-left_large clickeable prev-arrow" [class.arrows-hidden]="currentPage === 1" (click)="prevPage()"></div>
  <div class="clickeable" [class.selected]="currentPage === 1" (click)="goToPage(1)">1</div>
  <div *ngIf="pagesToShow?.length > 0 && (pagesToShow[0] !== 2)">...</div>

  <div *ngFor="let item of pagesToShow" class="clickeable" [class.selected]="currentPage === item"
  (click)="goToPage(item)">
    {{ item }}
  </div>

  <div *ngIf="pagesToShow && pagesToShow.length > 0 && (pagesToShow[pagesToShow.length - 1] !== totalPages - 1)">...</div>
  <div *ngIf="totalPages > 1" class="clickeable" [class.selected]="currentPage === totalPages"
    (click)="goToPage(totalPages)">
    {{ totalPages }}
  </div>
  <div class="icon-right_large clickeable next-arrow" [class.arrows-hidden]="currentPage >= totalPages" (click)="nextPage()"></div>
</div>
