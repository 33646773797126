<!--TODO: Divide popups if there is one more class-->
<div [class]="'popup-' + props?.type">
  <button (click)="modal.close('Cross click')" aria-describedby="modal-title" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="icon-content">
    <em class="icon" [ngClass]="[ props?.icon? props?.icon : '', props?.iconClass? props?.iconClass : '' ]"></em>
  </div>
  <div class="modal-body">
    <h3>{{ props?.header }}</h3>
    <p [ngStyle]="{'white-space': props?.type === 'download' ? 'pre-line':'inherit'}">{{ props?.body }}</p>
    <span *ngIf="props?.showContactEmail"> Please contact <a class="link" href="">support&#64;techinsights.com</a> for assistance. </span>
  </div>
  <div class="modal-footer">
    <button
      (click)="modal.close()"
      *ngIf="props?.secondaryButton" class="button button-secondary-rounded"
            type="button">{{props?.secondaryLabel}}
    </button>
    <button
      (click)="onPrimaryButtonClick()"
      *ngIf="props?.primaryButton"
      class="button button-primary-rounded"
      type="button">
      <span *ngIf="props?.primaryIcon" class="{{props?.primaryIcon}}"></span>
      {{props?.primaryLabel}}
    </button>
  </div>
</div>
