import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CredentialsService } from 'src/app/auth/credentials.service';

export interface TicketResponse {
  ticket: string;
}

@Injectable({
  providedIn: 'root',
})
export class TableauService {

  private baseUrl = environment.metaServiceBaseUrl;

  constructor(
    private httpClient: HttpClient,
    private credentialsService: CredentialsService,
  ) {}

  getTableauTicket(siteName?: string): Observable<TicketResponse> {
    siteName = siteName ? `/${siteName}` : '';
    const url = `${this.baseUrl}/tableau/ticket/${this.credentialsService.getUserId()}${siteName}`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');
    return this.httpClient.get<TicketResponse>(url, { headers });
  }

}
