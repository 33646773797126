import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';

export interface PlatformId {
  old: string;
  new: string;
}

@Injectable({
  providedIn: 'root'
})
export class PlatformIdMappingService {
  private readonly baseUrl = environment.platformIdMappingBaseUrl;

  constructor(private httpClient: HttpClient) {
  }

  public getMapping(id: string[]) {
    return this.httpClient.get<any>(`${this.baseUrl}/find_mappings/?platform_ids=${id}`);
  }
}
