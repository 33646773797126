import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MetadataPanelComponent } from './metadata-panel.component';

@NgModule(
  {
    declarations: [
      MetadataPanelComponent,
    ],
    imports: [
      CommonModule,
      NgbModule,
    ],
    exports: [
      MetadataPanelComponent,
    ]
  }
)
export class MetadataPanelModule {}
