import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  DoCheck,
  ChangeDetectorRef
} from '@angular/core';
import { Location } from '@angular/common';
import { Option, OptionGroup, PropagationEvent } from './option-model';
import { toggleHierarchyCollapsed, toggleMetadataPanelCollapsed } from '@app/store/Actions/search/search.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectHierarchyPanelCollapsed, selectMetadataPanelCollapsed }
  from '@app/store/Selectors/search/search.selectors';
import { tap } from 'rxjs/operators';
import { ToolbarType, ToolbarTypes } from '@shared/components/generic-toolbar/toolbar-type';
import { untilDestroyed } from '@core/until-destroyed';
@Component({
  selector: 'app-generic-toolbar',
  templateUrl: './generic-toolbar.component.html',
  styleUrls: ['./generic-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericToolbarComponent implements OnInit, DoCheck, OnDestroy {
  @Input() toolbarType: ToolbarType = ToolbarTypes.UNDEFINED;
  @Input() toolbarTitle = '';
  @Input() toolbarOptions: OptionGroup[] = [];
  @Input() currPage = 1;
  @Input() totalPages = 1;
  @Input() isFullScreen = false;
  @Input() addRightMargin = false;
  @Input() isLeftPanelOpen = false;

  @Output() propagateEvent: EventEmitter<PropagationEvent> = new EventEmitter<PropagationEvent>();
  @Output() toggleMetadataPanel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toggleHierarchyPanel: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() toggleLeftPanel: EventEmitter<boolean> = new EventEmitter<boolean>();


  // metadata.ts panel event emit and state control vars
  prevToolbarOptions: OptionGroup[] = [];
  isHierarchyPanelCollapsed: Observable<boolean> = this.store
    .select(selectHierarchyPanelCollapsed)
    .pipe(
      tap((value: boolean) => {
        this.hierarchyPanelState = !value;
        this.toggleHierarchyPanel.emit(this.hierarchyPanelState);
        this.cdRef.markForCheck();
      })
    )
    .pipe(untilDestroyed(this));
  isMetadataPanelCollapsed: Observable<boolean> = this.store
    .select(selectMetadataPanelCollapsed)
    .pipe(
      tap((value: boolean) => {
        this.metadataPanelState = !value;
        this.toggleMetadataPanel.emit(this.metadataPanelState);
        this.cdRef.markForCheck();
      })
    )
    .pipe(untilDestroyed(this));

  // Map holding the option ID as key and sort of eventsObject object.
  // This eventsObject has events (click, dblclick, ...) as its key, and unique identifier as its value
  registeredEvents: Map<string, { [key: string]: string }>;

  hierarchyPanelState = false; // Start the hierarchy panel closed.
  metadataPanelState = false; // Start the metadata panel closed.

  prevPageOption: Option;
  nextPageOption: Option;

  viewOption: Option;
  annotateOption: Option;
  annotationOptionGroup: OptionGroup;

  constructor(
    private location: Location,
    private store: Store,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.prevToolbarOptions = this.toolbarOptions;
    this.registerEvents();
    this.toolbarOptions.forEach((optionGroup) => {
      optionGroup.getOptions().forEach((element) => {
        switch (element.identifier) {
          case 'prev-page':
            this.prevPageOption = element;
            break;
          case 'next-page':
            this.nextPageOption = element;
            break;
          case 'view':
            this.viewOption = element;
            this.annotationOptionGroup = optionGroup;
            break;
          case 'annotate':
            this.annotateOption = element;
            this.annotationOptionGroup = optionGroup;
            break;
        }
      });
    });
    this.isHierarchyPanelCollapsed.subscribe();
    this.isMetadataPanelCollapsed.subscribe();
  }

  ngDoCheck() {
    if (this.prevToolbarOptions !== this.toolbarOptions) {
      this.prevToolbarOptions = this.toolbarOptions;
      this.registerEvents();
    }
  }

  // ngOnDestroy is necessary for the use of untilDestroyed.
  //  The return statement fakes out SonarCloud into thinking the method isn't empty.
  ngOnDestroy() {
    return;
  }

  registerEvents() {
    // Set registeredEvents in blank and iterates over all options.
    // For each, gathers its events and id associated with it
    this.registeredEvents = new Map();
    for (const group of this.prevToolbarOptions) {
      for (const { identifier, events } of group.getOptions()) {
        const eventsObject = {};
        for (const { name, key } of events) {
          eventsObject[name] = key;
        }
        this.registeredEvents.set(identifier, eventsObject);
      }
    }
  }

  doesItHaveEvent(eventType: string, identifier: string) {
    const eventsForId = this.registeredEvents.get(identifier);
    return eventsForId ? eventsForId[eventType] : undefined;
  }

  executeEvent(event: string, option: Option, element?: HTMLElement) {
    const eventFound = this.doesItHaveEvent(event, option.identifier);
    if (eventFound) {
      this.propagateEvent.next({ key: eventFound, value: option, element });
      this.cdRef.markForCheck();
    }
  }

  resetAllStates() {
    for (const optionGroup of this.toolbarOptions) {
      optionGroup.resetAllStates();
    }
  }

  onClickBack() {
    this.location.back();
  }

  getPaginateState(): string {
    switch (this.toolbarType) {
      case ToolbarTypes.PDF_VIEWER:
      case ToolbarTypes.PDF_VIEWER_NO_METADATA:
        return 'Page ' + this.currPage + ' of ' + this.totalPages;
      case ToolbarTypes.IMAGE_VIEWER:
        return 'Image ' + this.currPage + ' of ' + this.totalPages;
      case ToolbarTypes.OTHER:
        return 'Other ' + this.currPage + ' of ' + this.totalPages;
      default:
        return '';
    }
  }

  /**
   * Open or close the hierarchy side panel based on global search store
   */
  toggleHierarchy($event: any) {
    this.store.dispatch(toggleHierarchyCollapsed());
    $event.stopPropagation();
  }

  /**
   * Open or close the metadata side panel based on global search store
   */
  toggleMetadata() {
    this.store.dispatch(toggleMetadataPanelCollapsed());
  }

  onToggleLeftPanel() {
    this.isLeftPanelOpen = !this.isLeftPanelOpen;

    this.toggleLeftPanel.emit(this.isLeftPanelOpen);
  }
}
