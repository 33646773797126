import { ActivatedRoute } from '@angular/router';

export enum PathIndex {
  ROOT = 0,
  SHELL = 1,
  MODULE = 2,
  COMPONENT = 3,
}

export class RouteHelper {
  static getModuleRouteOrShell(route: ActivatedRoute): ActivatedRoute {
    // Go to the shell if we are not in a module
    return this.checkRoute('shell',
      this.getModuleRoute(route) ?? route.pathFromRoot[PathIndex.SHELL],
    );
  }

  static getModuleRouteOrDefault(route: ActivatedRoute, modulePath: string): ActivatedRoute {
    // Go to the given module path if we are not in a module
    return this.checkRoute(modulePath,
      this.getModuleRoute(route) ??
      route.pathFromRoot[PathIndex.SHELL]?.children.find(x => x.routeConfig.path === modulePath),
    );
  }

  private static getModuleRoute(route: ActivatedRoute): ActivatedRoute {
    // If we are not in a module, the last route in the path is the component
    return route.pathFromRoot.length <= PathIndex.COMPONENT ? null : route.pathFromRoot[PathIndex.MODULE];
  }

  private static checkRoute(pathName: string, value: ActivatedRoute | null | undefined): ActivatedRoute {
    if (value === null || value === undefined) {
      throw new Error(`Route to ${pathName} is null or undefined`);
    }
    return value;
  }
}
