import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CredentialsService } from './credentials.service';
import { DeepLinkingService } from '@shared/services/deep-linking.service';
import { WindowService } from '@shared/services/window-location/window.service';

const TEASER_QUERY_PARAM = 't';

/**
 * Guard to check if user has permissions to enter a given route
 */
@Injectable(
  {
    providedIn: 'root',
  },
)
export class AuthenticationGuard  {

  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private externalLocationService: WindowService,
    private deepLinkingService: DeepLinkingService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url.includes('logout') || state.url.includes('token') || !environment.usesExternalAPI) {
      return true;
    }

    // Get any teaser ID and remove the query parameter.
    // Teaser ID will be null if it doesn't exist
    const teaserId: string = route.queryParamMap.get(TEASER_QUERY_PARAM);
    // A URL base is used to prevent errors but isn't used in the final updated URL.
    const url: URL = new URL(state.url, 'https://base-not-used');

    const updatedUrl: string = url.pathname + url.search + url.hash;
    if (this.credentialsService.isAuthenticated()) {
      // https://techinsights.atlassian.net/browse/CP-17995 UUI async startup problems tech debt
      // Refactor this weirdness.
      // shouldRedirect returns an Observable which will always evaluate to true,
      // but it executes some code needed by redirect so this check can't just be removed.
      if (this.deepLinkingService.shouldRedirect(updatedUrl)) {
        this.deepLinkingService.redirect(updatedUrl);
      }
      return true;
    } else {
      // If the user is not authenticated, save the destination URL to redirect to after authentication.
      // If the URL contained a teaser ID, redirect to the public teaser page (e.g. blog teaser).
      // If the user logs in or signs up, they will then be redirected to the original destination URL (e.g. full blog).
      this.deepLinkingService.setRedirectionUrl(updatedUrl);
      if (teaserId) {
        // User is not authenticated, but with a teaser ID they are allowed to go to the public route
        this.router.navigateByUrl(`/public/${teaserId}${url.search}${url.hash}`);
      } else {
        // User is not authenticated and there is nothing for them to see, so redirect to login page
        this.credentialsService.removeToken();
        this.externalLocationService.assignLocation(this.deepLinkingService.getLoginUrl(updatedUrl));
      }
      return false;
    }
  }
}
