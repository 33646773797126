import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PlatformId, PlatformIdMappingService } from '@shared/services/platform-id-mapping.service';
import { environment } from '@env/environment';

export const REDIRECT_URL_KEY = 'redirect-url';

@Injectable({
  providedIn: 'root',
})
export class DeepLinkingService {

  private mappedIds: Array<PlatformId>;
  private urlFragments: Array<string>;

  // Temporary solution for
  // https://techinsights.atlassian.net/browse/SWSD-5624
  // State shouldn't be saved in services, but this is a quick fix
  // to alleviate landing page redirection racing with deep link redirection.
  // https://techinsights.atlassian.net/browse/CP-17995 UUI async startup problems tech debt
  // should address this.
  // Also consider renaming/repurposing this to RedirectionService
  private hasRedirectedOnStartup = false;

  constructor(
    private router: Router,
    private idMappingService: PlatformIdMappingService
  ) {}

  shouldRedirect(url: string): Observable<boolean> {
    this.splitUrl(this.getRedirectionUrl() ?? url);
    return this.getMapping(this.urlFragments).pipe(
      switchMap((idMap) => {
        this.mappedIds = idMap;
        return new Observable<boolean>((observer) => {
          const isMapped = this.isUrlMapped();
          observer.next(isMapped);
          observer.complete();
        });
      })
    );
  }

  redirect(url: string): void {
    this.shouldRedirect(url).subscribe((shouldRedirect) => {
      const storedUrl = this.getRedirectionUrl();
      this.clearRedirectionUrl();
      if (shouldRedirect) {
        const redirectUrl = storedUrl ?? url;
        this.setHasRedirectedOnStartup(true);
        this.router.navigateByUrl(this.replaceUrl(redirectUrl));
      } else if (storedUrl) {
        this.setHasRedirectedOnStartup(true);
        this.router.navigateByUrl(storedUrl);
      }
    });
  }

  getRedirectionUrl(): string {
    return localStorage.getItem(REDIRECT_URL_KEY);
  }

  setRedirectionUrl(url: string): void {
    if (url !== '/logout' && url !== '/' ) {
      localStorage.setItem(REDIRECT_URL_KEY, url);
    }
  }

  redirectTo403(){
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams: {[key: string]: any } = {};

    urlParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    this.router.navigate([ '/403' ], {
      queryParams
    });
  }

  // See comments above for hasRedirectedOnStartup
  getHasRedirectedOnStartup(): boolean {
    return this.hasRedirectedOnStartup;
  }

  setHasRedirectedOnStartup(hasRedirected: boolean): void {
    this.hasRedirectedOnStartup = hasRedirected;
  }

  getLoginUrl(url: string) {
    try {
      const loginUrl = new URL(environment.loginUrl);
      const urlInstance = new URL(url, window.location.origin);
      // Remove app query param as we use it in the login URL
      urlInstance.searchParams.delete('app');
      // Copy all search params from state url to login url (needed for Heap analytics)
      urlInstance.searchParams
        .forEach((value: string, key: string) => loginUrl.searchParams.set(key, encodeURI(value)));
      return loginUrl.toString() + urlInstance.hash;
    } catch (error) {
      return environment.loginUrl;
    }
  }

  private replaceUrl(url: string): string {
    return url.replace(this.mappedIds[0].old, this.mappedIds[0].new);
  }

  private isUrlMapped(): boolean {
    return this.urlFragments.some(fragment => {
      return this.mappedIds.some(id => {
        return id.old && fragment === id.old;
      });
    });
  }

  private splitUrl(url: string) {
    this.urlFragments = [].concat(...url?.split('/')?.map(element => {
      return element?.split('#');
    }) || []).filter(element => !!element);
  }

  private getMapping(ids: string[]): Observable<PlatformId[]> {
    return this.idMappingService.getMapping(ids);
  }

  private clearRedirectionUrl(): void {
    localStorage.removeItem(REDIRECT_URL_KEY);
  }

}
