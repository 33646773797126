import { SearchState } from '@app/store/Reducers/search/search.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/index';

const searchStateFeature = createFeatureSelector<SearchState>('searchState');

export const selectAppliedFiltersValues = createSelector( searchStateFeature,
   searchState => {
      const selectedFilters: { key:string; value:string }[] = []
      // nested for loops to get the active ones
      searchState.currentSearchTerms.selectedFilters.forEach( filterType => {
        filterType.subTypes.forEach (subType => {
          if(subType.value){
            selectedFilters.push( { key: filterType.key, value: subType.key } )
          }
        }
        )
      })
      return selectedFilters;
    })

export const selectSearchInputTerms = createSelector(searchStateFeature,
  (searchState) => {
    const textualTerms = searchState.currentSearchTerms.searchText;
    const filterTerms = searchState.currentSearchTerms.selectedFilters
      .map(({ label, key, subTypes }) => {
        return {
          label,
          key,
          values: Array.isArray(subTypes) ?
            subTypes.filter(s => s.value === true).map(s => s.key)
            : []
        };
      })
      .filter(({ values }) => values.length > 0);

    return { textualTerms, filterTerms };
  })

export const selectSearchState = (state: AppState) => state.searchState;
export const selectScrollIndex = (state: AppState) => state.searchState.scrollIndex;
export const selectCurrentSearch = (state: AppState) => state.searchState.currentSearchTerms;
export const selectFilterTypes = (state: AppState) => state.searchState.filterTypes;
export const selectViewType = (state: AppState) => state.searchState.viewType;
export const selectViewContentType = (state: AppState) => state.searchState.currentSearchTerms.selectedContentType;
export const selectSearchText = (state: AppState) => state.searchState.currentSearchTerms.searchText;
export const selectSearchSort = (state: AppState) => state.searchState.currentSearchTerms.sortMethod;
export const selectSearchSource = (state: AppState) => state.searchState.currentSearchTerms.source;
export const selectSearchTerms = (state: AppState) => state.searchState.currentSearchTerms;
export const selectInitialSearch = (state: AppState) => state.searchState.initialSearch;
export const selectCurrentFilters = (state: AppState) => state.searchState.currentSearchTerms.selectedFilters;
export const selectNewResultsAmount = (state: AppState) => state.searchState.searchResults.newResultsAmount;
export const selectSearchLoadingState = (state: AppState) => state.searchState.loadingState;
export const selectSearchHistory = (state: AppState) => state.searchState.searchHistory;
export const selectUserSavedSearches = (state: AppState) => state.searchState.userSavedSearches;
export const selectSearchResults = (state: AppState) => state.searchState.searchResults;
export const selectSearchResultsAmounts = (state: AppState) => state.searchState.searchResults.resultsAmount;
export const selectSearchResultsImages = (state: AppState) => state.searchState.searchResults.image;
export const selectSearchResultsSchematics = (state: AppState) => state.searchState.searchResults.schematic;
export const selectSearchResultsDies = (state: AppState) => state.searchState.searchResults.die;
export const selectSearchResultsReports = (state: AppState) => state.searchState.searchResults.report;
export const selectSearchResultsProducts = (state: AppState) => state.searchState.searchResults.product;
export const selectSearchResultsComponents = (state: AppState) => state.searchState.searchResults.component;
export const selectSearchResultsPcb = (state: AppState) => state.searchState.searchResults.pcb;
export const selectFiltersMenuCollapsed = (state: AppState) => state.searchState.isFiltersMenuCollapsed;
export const selectHierarchyPanelCollapsed = (state: AppState) => state.searchState.isHierarchyPanelCollapsed;
export const selectMetadataPanelCollapsed = (state: AppState) => state.searchState.isMetadataPanelCollapsed;
export const selectSavingUserSearch = (state: AppState) => state.searchState.loadingState.savingUserSearch;
export const selectLoadingUserSearches = (state: AppState) => state.searchState.loadingState.loadingSavedSearches;
