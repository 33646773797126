import {AfterViewInit, Component, ElementRef, EventEmitter, NgZone, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {AccessibilityState} from '@shared/enums/accessibility-state';

@Component({
  selector: 'app-pdf-viewer-filters-modal',
  templateUrl: './pdf-viewer-filters-modal.component.html',
  styleUrls: ['./pdf-viewer-filters-modal.component.scss']
})
export class PdfViewerFiltersModalComponent implements AfterViewInit {
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('legacyFilters') legacyFiltersRef: ElementRef<HTMLDivElement>;
  @ViewChild('legacyActions') legacyActionsRef: ElementRef<HTMLDivElement>;
  @ViewChild('modal') modalRef: ElementRef<HTMLDivElement>;
  @ViewChild('accessibilityFilters') accessibilityFiltersRef: ElementRef<HTMLDivElement>;

  filtersCount = 0;
  privateCtrl = new UntypedFormControl(false);
  publicCtrl = new UntypedFormControl(false);

  legacyClearButton: HTMLButtonElement;
  legacyApplyButton: HTMLButtonElement;

  initiated = false;

  constructor(private ngZone: NgZone) {
  }

  ngAfterViewInit(): void {
    // Remove from view
    this.modalRef.nativeElement.remove();
  }

  init(
    legacyModal: HTMLDivElement, legacyFilters: HTMLDivElement, legacyClearButton: HTMLButtonElement,
    legacyApplyButton: HTMLButtonElement
  ): void {
    if(!this.initiated) {
      legacyFilters.addEventListener('click', () => {
        const checkboxes: HTMLInputElement[] = Array.from(
          legacyFilters.querySelectorAll('input[type="checkbox"]')
        );

        this.onFiltersCountUpdate(checkboxes);
      });

      this.legacyFiltersRef.nativeElement.replaceWith(legacyFilters);
      legacyFilters.appendChild(this.accessibilityFiltersRef.nativeElement);

      this.legacyClearButton = legacyClearButton;
      this.legacyActionsRef.nativeElement.appendChild(legacyClearButton);

      this.legacyApplyButton = legacyApplyButton;
      this.legacyActionsRef.nativeElement.appendChild(legacyApplyButton);

      legacyModal.replaceWith(this.modalRef.nativeElement);
      legacyFilters.dispatchEvent(new MouseEvent('click'));

      this.initiated = true;
    }
  }

  onFiltersCountUpdate(checkboxes: HTMLInputElement[]): void {
    let count = 0;

    checkboxes.forEach(checkbox => count += checkbox.checked ? 1 : 0);

    this.ngZone.run(() => this.filtersCount = count);
  }

  onResetAccessibility(accessibilityState: AccessibilityState): void {
    switch (accessibilityState) {
      case AccessibilityState.none:
        this.publicCtrl.setValue(false);
        this.privateCtrl.setValue(false);
        break;
      case AccessibilityState.both:
        this.publicCtrl.setValue(true);
        this.privateCtrl.setValue(true);
        break;
      case AccessibilityState.private:
        this.publicCtrl.setValue(false);
        this.privateCtrl.setValue(true);
        break;
      case AccessibilityState.public:
        this.publicCtrl.setValue(true);
        this.privateCtrl.setValue(false);
        break;
    }
  }

  onClose(): void {
    this.closeModal.emit();
  }

  onClearAll(): void {
    this.privateCtrl.setValue(false);
    this.publicCtrl.setValue(false);

    if(this.legacyClearButton) {
      this.legacyClearButton.click();
    }
    this.filtersCount = 0;
  }

  onApply(): void {
    if(this.legacyApplyButton){
      this.legacyApplyButton.click();
    }
  }
}
