<section class="notification-banner">
  <em class="icon-{{iconName}} icon-class"></em>

  <div class="text-container">
    <span *ngIf="isFollowing; else notFollowing" class="header4-text">
      {{ bannerMessage }} Go to
      <a class="link" routerLink="/settings/my-interests">My Interests</a>
      to manage your settings.
    </span>
    <ng-template #notFollowing>
      <span class="header4-text">
        This analysis is in progress. Follow to receive notifications when content is available.
      </span>
    </ng-template>
  </div>
  <button
    class="button"
    [ngClass]="isFollowing ? 'unfollowButton' : 'followButton'"
    [disabled]="disabled"
    (click)="onClickButton()"
  >{{buttonText}}</button>
</section>