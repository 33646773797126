import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TelemetryEventName } from '@app/@shared/enums';
import { PdfAssetInfo } from '@app/@shared/models/pdf-asset-info';
import { TelemetryEvent } from '@app/@shared/models/telemetry-event';
import { MarketAnalysisService } from '@app/@shared/services/market-analysis/market-analysis.service';
import { MetaService } from '@app/@shared/services/meta.service';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss'],
})
@UntilDestroy()
export class ProductOverviewComponent implements OnInit {
  public pdfAssetInfo: PdfAssetInfo;
  public subscriptionName = '';
  public showErrorMessage = false;
  public subscriptionId = '';
  public wordpressUrl: string;
  private module: string;

  constructor(
    private route: ActivatedRoute,
    private marketAnalysisService: MarketAnalysisService,
    private metaService: MetaService,
    private navigationService: NavigationService
  ) {}

  public ngOnInit(): void {
    this.route.paramMap.pipe(
      concatMap((params) => {
        this.subscriptionId = params.get('subscriptionId');
        this.module = this.route.snapshot.data.module
        return this.navigationService.getSubscription(this.subscriptionId, this.module)
      }),
      concatMap((subscription) => {
        this.subscriptionName = subscription.name;
        this.wordpressUrl = subscription.productOverviewUrl;
        this.postEventForSubscriptionOverview();
        return this.marketAnalysisService.getSubscriptionBriefXodUrl(
          subscription.briefingFilename.replace(/.pdf$/i, '.xod')
        );
      }),
      untilDestroyed(this)
    ).subscribe((response) => {
      this.pdfAssetInfo = { url: response.url, clObjectId: null};
    }, () => {
      this.showErrorMessage = true;
    })
  }
  private postEventForSubscriptionOverview(): void{
    // Create parameters for view Product overview pdf events
    const payload = {
      reportTitle: `Product Overview - ${this.subscriptionName}`,
      artifactName: 'Product_Overview.pdf',
      artifactType: 'application/pdf',
      downloadSizeBytes: 0,
      subscriptionId: this.subscriptionId,
    };
    const applicationModule = this.module;
    const event = new TelemetryEvent(TelemetryEventName.EVENT_VIEW_IN_BROWSER, payload, applicationModule);
    this.metaService.postTelemetryEvent(event).subscribe();
  }


}

