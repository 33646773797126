import { Component, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteHelper } from '@app/@shared/utils/route-helper';
import { RecentViewTable, RecentViewTableRow } from '@shared/models/user-view-history/recent-view-table.model';

@Component({
  selector: 'app-recent-view-table',
  templateUrl: './recent-view-table.component.html',
  styleUrls: ['./recent-view-table.component.scss']
})
export class RecentViewTableComponent {
  @Input() table: RecentViewTable;
  @Output() clickRow = new EventEmitter<RecentViewTableRow>();

  constructor(private router: Router, private route: ActivatedRoute){}

  onClickRow(row: RecentViewTableRow) {
    this.clickRow.emit(row);
  }

  public createRowUrl(r: RecentViewTableRow): string {
    return this.router.serializeUrl(
      this.router.createUrlTree(['analysis-view', r.id], {
        relativeTo: RouteHelper.getModuleRouteOrShell(this.route),
      })
    );
  }
}
