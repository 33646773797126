import {
  Component,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnInit,
  OnChanges,
  SimpleChanges,
  NgZone,
} from '@angular/core';
import { Router } from '@angular/router';
import OpenSeaDragon from 'openseadragon';
import { MetaService } from '@shared/services/meta.service';
import { Image } from '@shared/models/image';
import { Icon } from '@app/@shared/components/generic-toolbar/icons';
import { ToolbarTypes } from '@shared/components/generic-toolbar/toolbar-type';
import { CustomOption, OptionGroup, Option, SocialOption } from '@app/@shared/components/generic-toolbar/option-model';
import { StatelessBehavior, ToggleBehavior } from '@app/@shared/components/generic-toolbar/state-behavior-model';
import { Metadata } from '@shared/models/metadata';
import { TelemetryEvent } from '@shared/models/telemetry-event';
import { TelemetryEventName } from '@shared/enums';
import { moduleNameFromUrl } from '@shared/utils/common';
import { ShareItemModalComponent } from '../share-item-modal/share-item-modal.component';
import { DialogService } from '@app/@shared/services/dialog/dialog.service';
import { RequestImageModalComponent } from '@shared/components/request-image-modal/request-image-modal.component';
import { AbstractImageViewerComponent } from '../abstract/abstract-image-viewer/abstract-image-viewer/abstract-image-viewer.component';
import { Measurement, MeasurementType } from '@app/@shared/models/kimera/measurement';
import { AnnotationService } from '@app/@shared/services/annotation/annotation.service';
import { CalibrationInstructionsModalComponent } from './calibration-instructions-modal/calibration-instructions-modal.component';
import { Calibration } from '@app/@shared/models/calibration/calibration.model';
import { ImageCalibrationModalComponent } from './image-calibration-modal/image-calibration-modal.component';
import { ImageMeasurementDialogComponent } from './image-measurement-dialog/image-measurement-dialog.component';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

// Exported for unit tests.
//  Should probably be GenericToolbarEvent exported from the generic toolbar.
export interface ImageViewerToolbarEvent {
  key: string;
  value: any;
}

const defaultZoomOptionsForMouse = {
  scrollToZoom: true,
  clickToZoom: false,
  dblClickToZoom: true,
  pinchToZoom: true,
};

declare function initializeOpenSeaDragonSvgRelay(openseadragon: any): any;

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent
  extends AbstractImageViewerComponent
  implements AfterViewInit, OnDestroy, OnInit, OnChanges
{

  @Input() thumbnailImages: Array<Image>;
  @Input() numberOfImages: number;
  @Input() imageNumber: number;
  @Input() subscriptionId: string;
  @Input() channelId: string;
  @Input() downloadEnabled = false;
  @Input() enableUnwatermarkedImageRequest = false;
  @Input() addToFavoriteEnabled = false;

  @Input() set selectedImage(image: Image) {
    this._selectedImage = image;
  }

  get selectedImage() {
    return this._selectedImage;
  }

  @Output() closeImageViewer: EventEmitter<any> = new EventEmitter<any>();
  @Output() thumbnailClicked = new EventEmitter<string>();
  @Output() nextImage = new EventEmitter<void>();
  @Output() previousImage = new EventEmitter<void>();
  @Output() downloadImage = new EventEmitter<Image>();
  @Output() addToFavorites = new EventEmitter<Image>();

  public readonly toolbarType = ToolbarTypes.IMAGE_VIEWER;
  public toolbarOptions: Array<OptionGroup> = null;
  public toolbarTitle = '';
  public metadata: Metadata;
  public isFullScreen = false;
  public fullscreenToggle: CustomOption = null;
  public lengthSelectionToggleOption: CustomOption;

  private readonly openSeadragonInitialSettings = {
    id: 'openseadragon', // HTML ID in the template of the element to embed the viewer
    prefixUrl: 'https://openseadragon.github.io/openseadragon/images/',
    showNavigator: true,
    navigatorAutoFade: false,
    showThumbnails: true,
    toolbar: 'toolbar', // HTML ID of the element shown as the toolbar in fullscreen mode
    gestureSettingsMouse: defaultZoomOptionsForMouse,
    preload: false,
    maxZoomPixelRatio: 50,
    visible: true,
    tileSources: {
      type: 'image',
      url: 'assets/NotFound/Placeholder.svg',
    },
  };
  private readonly eventToMethod = {
    'request-image': 'onClickImageRequest',
    'zoom-out': 'onClickZoomOut',
    'zoom-in': 'onClickZoomIn',
    'rotate-right': 'onClickRotateRight',
    'toggle-fullscreen': 'onClickFullscreenToggle',
    'fit-to-page': 'onClickFitPage',
    'toggle-navigator': 'onClickNavigatorToggle',
    'prev-page': 'onClickPreviousImage',
    'next-page': 'onClickNextImage',
    'close-viewer': 'onCloseImageViewer',
    'download-image': 'onDownloadImage',
    'share-image': 'onShareImage',
    'add-to-favorites': 'onAddToFavorites',
    'toggle-length-selection': 'onToggleLengthSelection',
  };
  private showNavigator = true;
  private calibration: Calibration;
  private CALIBRATION_INSTRUCTIONS_MODAL_OPTIONS: NgbModalOptions = {
    windowClass: 'calibration-instructions-dialog',
    size: 'lg',
    container: '.openseadragon-viewer',
  };

  constructor(
    _ngZone: NgZone,
    metaService: MetaService,
    private router: Router,
    dialogService: DialogService,
    annotationService: AnnotationService
  ) {
    super(_ngZone, metaService, dialogService, annotationService);
  }

  /**
   * onKeyUp allows the left/right arrow keys to browse previous/next images.
   *  Note that this is overriding the default pan behaviour of OpenSeadragon.
   * @param event The keyup event.
   */
  @HostListener('window:keyup', ['$event'])
  public onKeyUp(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      return this.onClickNextImage();
    } else if (event.key === 'ArrowLeft') {
      return this.onClickPreviousImage();
    }
  }

  ngOnInit() {
    this.toolbarOptions = this.createToolbarOptions();
    this.fullscreenToggle = this.getFullScreenToggle();
    this.measuringMode = MeasurementType.Length;
    this.hasCalibration = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedImage) {
      // Reset measurement tool when switching images
      this.lengthSelectionToggleOption?.resetState();
      this.measuringMode = null;

      const previousImage = changes.selectedImage.previousValue;
      const currentImage = changes.selectedImage.currentValue;

      // Check if the previous image is different from the actual one
      if (
        (!previousImage && currentImage) ||
        (previousImage && currentImage && previousImage.objectId !== currentImage.objectId)
      ) {
        this.postTelemetryEventForOpeningImage();
        this.hasCalibration = false;
        this.annotationService.getCalibration(currentImage.id).subscribe((calibration) => {
          this.hasCalibration = !!calibration;
          this.calibration = calibration;
        });
      }

      this.metadata = this.createMetadataFromImage(this._selectedImage);
      this.toolbarTitle = this.metadata.getValue('Filename') || this.metadata.getValue('File Name');
      this.viewer?.viewport?.setRotation(0);
      this.displaySelectedImageInOpenSeadragon();
    }
  }

  // ngOnDestroy is necessary for the use of untilDestroyed.
  // The return statement fakes out SonarCloud into thinking the method isn't empty.
  public ngOnDestroy(): void {
    return;
  }

  public ngAfterViewInit() {
    this.startOpenSeadragon();
    this.viewer.addHandler('full-screen', (_data: any) => {
      this.fullscreenToggle?.stateBehavior.onChange();
      this.isFullScreen = !this.isFullScreen;
    });
    // Remove empty div that OpenSeadragon adds to the 'toolbar' element
    document.querySelector('#toolbar').lastChild.remove();
    this.displaySelectedImageInOpenSeadragon();
  }

  /**
   * onToolbarEvent uses eventToMethod to execute event handlers for the toolbar.
   * @param key The event name to translate to an event handler.
   */
  public onToolbarEvent({ key }: ImageViewerToolbarEvent): void {
    if (key in this.eventToMethod) {
      const methodName = this.eventToMethod[key];
      this[methodName]();
    }
  }

  public onClickZoomIn(): void {
    const currentZoom = this.viewer.viewport.getZoom();
    this.viewer.viewport.zoomTo(currentZoom + 0.1);
  }

  public onClickZoomOut(): void {
    const currentZoom = this.viewer.viewport.getZoom();
    const newZoom = currentZoom - 0.1;
    this.viewer.viewport.zoomTo(newZoom > 0.1 ? newZoom : 0.1);
  }

  public onClickFitPage(): void {
    // true causes to fill instantly instead of quick zoom in/out
    this.viewer.viewport.fitHorizontally(true);
  }

  public onClickRotateRight(): void {
    const currentAngle = this.viewer.viewport.getRotation();
    this.viewer.viewport.setRotation(currentAngle + 90);
  }

  public onClickNavigatorToggle(): void {
    if (this.showNavigator) {
      this.viewer.navigator.element.style.display = 'none';
    } else {
      this.viewer.navigator.element.style.display = 'inline-block';
    }
    this.showNavigator = !this.showNavigator;
  }

  public onClickFullscreenToggle(): void {
    this.fullscreenToggle.stateBehavior.onChange();
    this.viewer.setFullScreen(!this.isFullScreen);
  }

  public onClickPreviousImage(): void {
    this.previousImage.emit();
  }

  public onClickNextImage(): void {
    this.nextImage.emit();
  }

  public onClickThumbnail(imageId: string): void {
    this.thumbnailClicked.emit(imageId);
  }

  public onCloseImageViewer(): void {
    if (this.isFullScreen) {
      // want to exit fullscreen not switch to grid
      this.viewer.setFullScreen(!this.isFullScreen);
    } else {
      this.closeImageViewer.emit();
    }
  }

  public onDownloadImage(): void {
    this.downloadImage.emit(this._selectedImage);
  }

  public onShareImage(): void {
    const shareFormModalInstanse = this.dialogService.open(ShareItemModalComponent, { windowClass: 'shareModalClass' });
    shareFormModalInstanse.componentInstance.clObjectId = this.selectedImage.objectId;
    shareFormModalInstanse.componentInstance.isDocumentShareable = true;
    shareFormModalInstanse.componentInstance.shareEntityType = 'ASSET';
  }

  public onAddToFavorites(): void {
    this.addToFavorites.emit(this._selectedImage);
  }

  public startOpenSeadragon(): void {
    initializeOpenSeaDragonSvgRelay(OpenSeaDragon);
    this.viewer = OpenSeaDragon(this.openSeadragonInitialSettings);
    this.addCanvasDragHandlers();
    this.hasRenderedOnce = true;
  }

  public displaySelectedImageInOpenSeadragon(): void {
    if (this.viewer) {
      if (!!this._selectedImage && !!this._selectedImage.url) {
        const tiledImage = {
          tileSource: {
            type: 'image',
            url: this._selectedImage.url,
          },
        };
        // Clear out the previous image to prevent overlapping
        this.viewer._cancelPendingImages();
        if (this.viewer.world.getItemCount()) {
          this.viewer.world.removeAll();
        }
        // Set the new image form render
        this.viewer.open(tiledImage);
      }
    }
  }

  public onClickImageRequest() {
    // Just a mock for the future logic
    const requestUnwatermarkedImageModelWindow = this.dialogService.open(RequestImageModalComponent, {
      size: 'lg',
      centered: true,
      windowClass: 'uui-modal',
    });
    requestUnwatermarkedImageModelWindow.componentInstance.imageTitle = this.toolbarTitle;
    requestUnwatermarkedImageModelWindow.componentInstance.analysisCode = this.selectedImage.analysisData.reportCode;
    requestUnwatermarkedImageModelWindow.componentInstance.clObjectID = this.selectedImage.objectId;
  }

  public getFullScreenToggle(): CustomOption {
    let fullScreenToggleOption: CustomOption;
    for (const toolbarGroup of this.toolbarOptions) {
      const options: Array<Option> = toolbarGroup.getOptions();
      fullScreenToggleOption = options.find((key: Option) => key.identifier === 'toggle-fullscreen') as CustomOption;
      if (fullScreenToggleOption) {
        break;
      }
    }
    return fullScreenToggleOption;
  }

  protected getScalingFactor(): number {
    return this.calibration.scaleRef / this.calibration.scale;
  }

  protected prepareForMeasuring(): void {
    if (!this.hasCalibration) {
      this.dialogService.open(CalibrationInstructionsModalComponent, this.CALIBRATION_INSTRUCTIONS_MODAL_OPTIONS);
    }
  }

  protected async calibrateMeasurement(lineDistance: number): Promise<void> {
    try {
      const dialog = this.dialogService.open(ImageCalibrationModalComponent, {
        windowClass: 'calibration-dialog',
        size: 'sm',
        container: '.openseadragon-viewer',
      });
      const calibrationUnit: Calibration = { ...(await dialog.result), scale: lineDistance };
      this.annotationService
        .postSaveCalibration(this._selectedImage.id, calibrationUnit, this.calibration?.id)
        .subscribe();
      this.hasCalibration = true;
      this.calibration = calibrationUnit;
      return;
    } catch (error) {
      // user closed the modal
    }
  }

  protected displayMeasurement(measurement: Measurement): Promise<any> {
    const dialog = this.dialogService.open(ImageMeasurementDialogComponent, this.DEFAULT_MODAL_OPTIONS);
    dialog.componentInstance.calibration = this.calibration;
    dialog.componentInstance.measurement = measurement;
    dialog.componentInstance.clickRecalibrate.subscribe(() => this.onClickRecalibrate());
    return dialog.result;
  }

  private onClickRecalibrate() {
    this.hasCalibration = false;
    this.dialogService.open(CalibrationInstructionsModalComponent, this.CALIBRATION_INSTRUCTIONS_MODAL_OPTIONS);
  }

  /**
   * createToolbarOptions creates the OptionGroup array of buttons to pass to the generic toolbar.
   */
  private createToolbarOptions(): Array<OptionGroup> {
    const pageOption = [];
    const optionGroups = [];
    pageOption.push(
      new CustomOption(
        'prev-page',
        [{ name: 'click', key: 'prev-page' }],
        new StatelessBehavior(Icon.IMAGE_LEFT_ARROW, 'Previous')
      )
    );
    pageOption.push(
      new CustomOption(
        'next-page',
        [{ name: 'click', key: 'next-page' }],
        new StatelessBehavior(Icon.IMAGE_RIGHT_ARROW, 'Next')
      )
    );

    const zoomOptions = [];
    if (this.enableUnwatermarkedImageRequest) {
      zoomOptions.push(
        new CustomOption(
          'request-image',
          [{ name: 'click', key: 'request-image' }],
          new StatelessBehavior(Icon.IMAGE_UNWATERMAKED_IMAGE_ACCESS, 'Request unwatermarked image')
        )
      );
    }
    zoomOptions.push(
      new CustomOption(
        'zoom-out',
        [{ name: 'click', key: 'zoom-out' }],
        new StatelessBehavior(Icon.IMAGE_ZOOM_OUT, 'Zoom Out')
      )
    );
    zoomOptions.push(
      new CustomOption(
        'zoom-in',
        [{ name: 'click', key: 'zoom-in' }],
        new StatelessBehavior(Icon.IMAGE_ZOOM_IN, 'Zoom In')
      )
    );
    zoomOptions.push(
      new CustomOption(
        'fit-to-page',
        [{ name: 'click', key: 'fit-to-page' }],
        new StatelessBehavior(Icon.IMAGE_FIT_PAGE, 'Fit to Page')
      )
    );
    zoomOptions.push(
      new CustomOption(
        'rotate-right',
        [{ name: 'click', key: 'rotate-right' }],
        new StatelessBehavior(Icon.IMAGE_RIGHT_ROTATE, 'Rotate Right')
      )
    );
    zoomOptions.push(
      new CustomOption(
        'toggle-navigator',
        [{ name: 'click', key: 'toggle-navigator' }],
        new ToggleBehavior(Icon.IMAGE_NAVIGATOR, 'Show Navigator', Icon.IMAGE_NAVIGATOR, 'Hide Navigator', true)
      )
    );
    zoomOptions.push(
      new CustomOption(
        'toggle-fullscreen',
        [{ name: 'click', key: 'toggle-fullscreen' }],
        new ToggleBehavior(Icon.IMAGE_ENTER_FULLSCREEN, 'Full Screen', Icon.IMAGE_EXIT_FULLSCREEN, 'Exit Full Screen')
      )
    );

    const shareOptions: CustomOption[] = [];
    shareOptions.push(
      new CustomOption(
        'share-image',
        [{ name: 'click', key: 'share-image' }],
        new StatelessBehavior(Icon.IMAGE_SHARE, 'Share')
      )
    );

    const otherOptions = [];
    otherOptions.push(
      new CustomOption(
        'close-viewer',
        [{ name: 'click', key: 'close-viewer' }],
        new StatelessBehavior(Icon.IMAGE_CLOSE, 'Close'),
        'Close'
      )
    );

    optionGroups.push(new OptionGroup(pageOption));

    const measurementOptions: CustomOption[] = [];
    const lengthTooltipText = 'Measure length';

    this.lengthSelectionToggleOption = new CustomOption(
      'toggle-length-selection',
      [{ name: 'click', key: 'toggle-length-selection' }],
      new ToggleBehavior(Icon.MEASURE_LINE, lengthTooltipText, Icon.MEASURE_LINE, lengthTooltipText, false),
      null
    );
    measurementOptions.push(this.lengthSelectionToggleOption);
    optionGroups.push(new OptionGroup(measurementOptions));

    optionGroups.push(new OptionGroup(zoomOptions));

    if (this.downloadEnabled) {
      const downloadOptions: CustomOption[] = [];
      const downloadTooltipMessage = 'Download';
      downloadOptions.push(
        new CustomOption(
          'download-image',
          [{ name: 'click', key: 'download-image' }],
          new StatelessBehavior(Icon.REPORT_VIEWER_DOWNLOAD, downloadTooltipMessage),
          null,
          !this.downloadEnabled
        )
      );
      optionGroups.push(new OptionGroup(downloadOptions));
    }

    if (this.addToFavoriteEnabled) {
      const socialOptions: SocialOption[] = [];
      socialOptions.push(
        new SocialOption(
          'add-to-favorites',
          [{ name: 'click', key: 'add-to-favorites' }],
          new StatelessBehavior(Icon.IMAGE_FAVORITE, 'Add to Favorites')
        )
      );
      optionGroups.push(new OptionGroup(socialOptions));
    }

    optionGroups.push(new OptionGroup(shareOptions));
    optionGroups.push(new OptionGroup(otherOptions));

    return optionGroups;
  }

  /**
   * createMetadataFromImage gathers metadata for the metadata panel.
   *  The order information is added here is the order it will appear in the UI.
   * @param image The image to gather metadata from.
   */
  private createMetadataFromImage(image: Image): Metadata {
    // The order information is added here is the order it will appear in the UI.
    const metadata: Metadata = new Metadata();
    // Summary data for analysis images
    if (image?.summaryData) {
      const {
        filename,
        analysisTitle,
        code,
        date,
        deviceName,
        devicePartNumber,
        manufacturer,
        deviceType,
        downstreamProduct,
        itemDescription,
      } = image.summaryData;
      metadata
        .addMetadata('File Name', filename)
        .addMetadata('Analysis Title', analysisTitle)
        .addMetadata('Product Code', code)
        .addMetadata('Date Completed', date)
        .addMetadata('Device Name', deviceName)
        .addMetadata('Device Part No', devicePartNumber)
        .addMetadata('Device Manufacturer', manufacturer)
        .addMetadata('Device Type', deviceType)
        .addMetadata('Downstream Product', downstreamProduct)
        .addMetadata('Description', itemDescription);
    }
    // Janet metadata (for genealogy in Search module):
    if (image?.janetdata) {
      const { name, manufacturer, deviceType, deviceCategory, marketAvailability, countryOfPurchase, itemDescription } =
        image.janetdata;
      metadata
        .addMetadata('Manufacturer', manufacturer)
        .addMetadata('Common Name', name)
        .addMetadata('Device Type', deviceType)
        .addMetadata('Device Category', deviceCategory)
        .addMetadata('Description', itemDescription)
        .addMetadata('Market Availability', marketAvailability)
        .addMetadata('Country of Purchase', countryOfPurchase);
    }
    // Image metadata:
    if (image?.metadata) {
      const { filename, perspective, features } = image.metadata;
      metadata
        .addMetadata('Filename', filename)
        .addMetadata('Perspective', perspective)
        .addMetadata('Feature', features);
    }
    return metadata;
  }

  /**
   * postTelemetryEventForOpeningImage posts telemetry event for opening image.
   *
   * NOTE: This method will need to be modified to send the appropriate payload
   *  for the type of image being displayed.  It should also not be called by
   *  displaySelectedImageInOpenSeaDragon so telemetry isn't sent for thumbnails.
   *  This method could be made generic and public, and called externally as needed.
   */
  private postTelemetryEventForOpeningImage() {
    // Check if the image is selected
    if (this._selectedImage) {
      let payload;

      if (this._selectedImage.janetdata) {
        const filetype = this._selectedImage.metadata.filename.split('.')[1];
        payload = {
          reportTitle: this.buildReportTitle(this._selectedImage.janetdata),
          reportId: this._selectedImage.reportId,
          reportCode: this._selectedImage.janetdata.genealogyCode,
          artifactName: this._selectedImage.metadata.filename,
          artifactType: filetype,
          subscriptionId: this.subscriptionId,
          channelId: this.channelId,
          downloadSizeBytes: 0,
        };
      } else {
        payload = {
          reportId: this._selectedImage.reportId,
          reportCode: this._selectedImage.analysisData.reportCode,
          reportTitle: this._selectedImage.summaryData.reportTitle
            ? this._selectedImage.summaryData.reportTitle
            : this._selectedImage.summaryData.analysisTitle,
          artifactName: this._selectedImage.summaryData.filename,
          artifactType: this._selectedImage.analysisData.fileType,
          subscriptionId: this.subscriptionId,
          channelId: this.channelId,
          downloadSizeBytes: 0,
        };
      }

      const applicationModule = moduleNameFromUrl(this.router.url);

      const event = new TelemetryEvent(TelemetryEventName.EVENT_VIEW_IN_BROWSER, payload, applicationModule);
      this.metaService.postTelemetryEvent(event).subscribe();
    }
  }

  private buildReportTitle(janetdata: any): string {
    const titleName = `${janetdata.partNumber} (${janetdata.name})`;
    return `${janetdata.manufacturer} ${janetdata.name ? titleName : janetdata.partNumber}`;
  }

}
