<div class="expanding-search-bar-container video-header">
  <app-generic-search-bar
    [class]="hideSearchButton ? 'shrinked-search-bar' : 'expanded-search-bar'"
    [chipList]="searchTerm"
    [hiddenSearchButton]="hideSearchButton"
    [searchPlaceholder]="searchContext"
    (searchTermsChanged)="onChangeSearchTerms($event)"
    (focusSearchbar)="onFocusSearchbar()"
    (blurSearchbar)="onBlurSearchbar($event)"
  ></app-generic-search-bar>
</div>
<section class="tab-bar">
  <div class="filter-sidebar-header" [class.collapsed]="filtersCollapsed">
    <em class="icon-sidebar_close" (click)="onToggleFilters($event)"></em>
    <span>Filters</span>
    <span class="clear-all" (click)="onClearFilters()" *ngIf="filtersApplied">
      <em class="icon-close_small"></em>
      Clear All
    </span>
  </div>
</section>
<section class="video-view">
  <aside class="sidebar-wrapper" [class.collapsed]="filtersCollapsed">
    <app-filter-sidebar
      class="scrollbar"
      [filters]="filters"
      (changeFilters)="onChangeFilters($event)"
      #filtersBar
    ></app-filter-sidebar>
  </aside>
  <div class="video-view-container">
    <app-content-list-view
      [content]="videos"
      [paginationOptions]="paginationOptions"
      [contentCount]="videoCount"
      [loading]="isComponentLoading"
      [sortOptions]="sortOptions"
      [selectedSort]="selectedSort"
      [showSort]="true"
      [searchTerm]="searchTerm"
      [showHighlights]="paginationOptions.showHighlights"
    >
      <div *ngIf="!isComponentLoading" class="search-string-highlight-wrapper">
        <section class="search-string">
          <strong>{{videoCount}} Video results</strong>
          <span *ngIf="searchTerm">
            <strong>for</strong>
            "{{searchTerm | replace : ',' : ' OR '}}"
          </span>
          in {{searchContext}}
        </section>
        <app-pagination-highlight
          [currentPage]="paginationOptions.page" [currentPageSize]="paginationOptions.size" [selectedSort]="selectedSort"
          [showHighlights]="paginationOptions.showHighlights"
          (changePaginationOptions)="onChangePaginationOptions($event)">
        </app-pagination-highlight>
      </div>
      <app-tabbed-videos-list
        [videos]="videos"
        [moduleName]="module"
      ></app-tabbed-videos-list>
    </app-content-list-view>
  </div>
</section>
