<app-pdf-viewer
  *ngIf="pdfAssetInfo"
  class="pdf-viewer"
  [pdfAssetInfo]="pdfAssetInfo"
  [showSharedButton]="true"
  [showCloseButton]="true"
  [(showDownloadButton)]="showDownloadButton"
  (downloadPdf)="downloadPdf()"
  (closePdfViewer)="onClosePdfViewer()"
></app-pdf-viewer>
