import { Injectable } from '@angular/core';
import { TelemetryEventName } from '@shared/enums';
import {VendorIntegrationService} from '@shared/services/vendor-integration.service';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TelemetryService {

  constructor(
    private window: Window,
    private vendorIntegrationService: VendorIntegrationService,
  ) {
    this.vendorIntegrationService.initHeap();
  }

  /**
   * sendHeapEvent explicitly send a Heap analytics event, and can be used when
   * Heap does not automatically track something (e.g. modal popups).
   * See https://developers.heap.io/reference/track for more information.
   * @param name TelemetryEventName: Name of the custom interaction. Limited to 255 characters.
   * @param properties any, optional: A JSON object, NSDictionary, or Map containing key-value pairs
   * to be associated with an event. Keys must be strings, while values may be a number or a string.
   */
  public sendHeapEvent(name: TelemetryEventName, properties?: any): Observable<void> {
    return this.vendorIntegrationService.initHeap().pipe(tap(() => {
      const heapObject = 'heap'; // Avoids lint warning
      this.window[heapObject]?.track(name, properties);
    }));
  }

  public setRaiseTelemetryEvent(value: boolean): void {
    localStorage.setItem('raiseTelemetry', value.toString());
  }

  public getRaiseTelemetryEvent(): boolean {
    const strValue = localStorage.getItem('raiseTelemetry');
    return strValue !== null && strValue === 'true';
  }

}
