import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogOptions } from '@shared/services/dialog/dialog.service';
import { environment } from 'src/environments/environment';

/**
 * WarningModalComponent is deprecated in favour of DialogService
 */

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: [ './warning-modal.component.scss' ],
})
export class WarningModalComponent {

  @Input() props: DialogOptions = {};

  public readonly complimentaryContent = environment.complimentary;
  public readonly mySubscriptions = environment.mySubscriptions;

  constructor(
    public modal: NgbActiveModal,
  ) {}

}
