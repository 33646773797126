import { Component, Input } from '@angular/core';
import { ContentNavigatorTableComponent } from '../../content-navigator-table/content-navigator-table.component';
import { RecentViewTableRow } from '@app/@shared/models/user-view-history/recent-view-table.model';

@Component({
  selector: 'app-home-content-navigator-table',
  templateUrl: './home-content-navigator-table.component.html',
  styleUrls: ['./home-content-navigator-table.component.scss']
})
export class HomeContentNavigatorTableComponent extends ContentNavigatorTableComponent<RecentViewTableRow>{
  @Input() activeTab: string;

  public onClickOpenNewTab(event: Event, row: any) {
    if(this.activeTab === 'recently-viewed'){
      event.preventDefault();
      event.stopPropagation();
      window.open(row.url, '_blank', 'noopener=true');
    } else {
      super.onClickOpenNewTab(event, row);
    }
  }
}
