<div class="search-bar">
  <app-generic-search-bar
    [chipList]="searchTerm"
    [hiddenSearchButton]="hideSearchButton"
    [searchPlaceholder]="searchContext"
    (searchTermsChanged)="onChangeSearchTerms($event)">
  </app-generic-search-bar>
</div>
<section *ngFor="let menu of megaMenu" [class.active]="activeMenu === menu.name">
  <div class="section-header" [class.active]="activeMenu === menu.name" (click)="onClickMenu(menu)">
    <a *ngIf="menu.name !== '...'" class="section-header-link">{{menu.name}}</a>
    <a *ngIf="menu.name === '...'" class="section-header-link"><em class="icon-more1"></em></a>
    <em *ngIf="menu.primaryMenu && menu.primaryMenu.length > 0" class="icon-down_small"></em>
  </div>
   <div class="menu" [class.active]="activeMenu === menu.name">
     <ng-container *ngFor="let menuItem of menu.primaryMenu">
       <a
         class="menu-element"
         *ngFor="let subMenuItem of menuItem.items"
         (click)="onClickMenuItem(subMenuItem)"
       >{{subMenuItem.name}}</a>
     </ng-container>
   </div>
</section>
