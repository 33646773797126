import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';

export interface IInputProps {
  class: string,
  placeholder: string,
  label: string,
  disabled?: boolean
}

/**
 * A general propose input component with implementation to accept ng-model and formcontrol
 */
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements OnInit, ControlValueAccessor {
  get props(): IInputProps {
    return this._props;
  }
  @Input()
  set props(value: IInputProps) {
    this._props = value;
  }
  @Output() blurInput = new EventEmitter<void>();

  public disabled: boolean;

  public value: any = null;
  public focused = false;
  _control: NgControl;

  private _props: IInputProps;

  constructor(private injector: Injector) { }

  onTouched = () => {
  };

  onChange = (value: any) => {
  };

  ngOnInit(): void {
    this._control = this.injector.get(NgControl);
  }

  onFocus(){
    this.focused = true
  }

  onBlur(){
    this.focused = false;
    this.blurInput.emit();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value= obj;
    this.onChange(obj);
  }

}
