import {createReducer, on} from '@ngrx/store';
import {INotificationSettings} from 'src/app/store/Models/settings/settingsModel';
import {
  loadSettings,
  loadSettingsFailure,
  loadSettingsSuccess,
  saveNotificationSettings,
  saveNotificationSettingsFailure,
  saveNotificationSettingsSuccess,
  saveUserProfile,
  saveUserProfileFailure,
  saveUserProfileSuccess,
  SettingsActionTypes,
  setUserProfile
} from 'src/app/store/Actions/settings/settings.actions';


export const settingsFeatureKey = 'settings';

export interface SettingsState {
  notificationSettings: INotificationSettings,
  loadingSettings?: boolean,
  savingSettings?: boolean,
  acceptedCookies: boolean
}

export const initialSettingsState: SettingsState = {
  notificationSettings: {
    notificationsEnabled: true,
    pushNotificationsEnabled: true,
    pushNotificationsOptions: null,
    emailNotificationsOptions: null,
    emailNotificationsEnabled: false,
    emailForNotifications: null
  },
  loadingSettings: false,
  savingSettings: false,
  acceptedCookies: false
};


export const settingsReducer = createReducer(
  initialSettingsState,
  on(loadSettings, state => {
    return {
      ...state,
      loadingSettings: true
    };
  }),
  on(loadSettingsSuccess, loadSettingsFailure, (state,
    action) => {
    if (action.type === SettingsActionTypes.loadSettingsFailure) {
      return {
        ...state,
        loadingSettings: false
      };
    }
    return {
      ...action.data,
      loadingSettings: false
    };
  }),
  on(saveUserProfile, setUserProfile, state => {
    return {
      ...state,
      savingSettings: true
    };
  }),
  on(saveUserProfileSuccess, saveUserProfileFailure, (state,
    action) => {
    if (action.type === SettingsActionTypes.saveUserProfileFailure) {
      return {
        ...state,
        savingSettings: false
      };
    }
    return {
      ...state,
      savingSettings: false
    };
  }),
  on(saveNotificationSettings, state => {
    return {
      ...state,
      savingSettings: true
    };
  }),
  on(saveNotificationSettingsSuccess, saveNotificationSettingsFailure, (state,
    action) => {
    if (action.type === SettingsActionTypes.saveNotificationSettingsFailure) {
      return {
        ...state,
        savingSettings: false
      };
    }
    return {
      ...state,
      notificationSettings: action.notificationSettings,
      savingSettings: false
    };
  }),
);

