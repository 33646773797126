import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TelemetryEventName } from '@app/@shared/enums/telemetry-event-name';
import { AnalysisMetadataResponse } from '@app/@shared/models/reverse-engineering/analysis.models';
import { TelemetryEvent } from '@app/@shared/models/telemetry-event';
import { MetaService } from '../meta.service';
import { MarketAnalysisService } from '../market-analysis/market-analysis.service';
import { RegularExpressions } from '@app/@shared/expresions/regular-expressions';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class CrossLinkingService {

  crossLinkingRegex : RegExp = RegularExpressions.crossLinkingUrl;

  constructor(
    private marketAnalysisService: MarketAnalysisService,
    private metaService: MetaService,
  ) {}

  public handleOnLinkClicked(from: ActivatedRouteSnapshot, toUri: string) {
    if(this.crossLinkingRegex.test(toUri)) {
      const payload = {
        productCode: from.params.analysisCode,
        productTitle: '',
        assetFileName: from.fragment.split('&')[1].split('=')[1],
        assetFileType: 'aplication/pdf'
      };
      this.marketAnalysisService.getAnalysisMetadata(from.params.analysisCode)
        .pipe(untilDestroyed(this))
        .subscribe((analysisMetadata: AnalysisMetadataResponse) => {
          payload.productTitle = analysisMetadata.report.name;
          const event = new TelemetryEvent(TelemetryEventName.EVENT_ORIGIN_CROSSLINKING, payload);
          this.metaService.postTelemetryEvent(event).subscribe();
        });
    };
    window.open(toUri);
  }
}
