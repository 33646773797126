import { TemplateRef } from '@angular/core';
import { AnalysisFilter } from '../reverse-engineering/analysis.models';
import { FavoriteContentType } from '@app/my-library/models/my-library.models';

export enum ReportFlavor {
  ALL = '',
  SAMPLE = 'SAMPLE',
  ANALYST = 'ANALYST',
  IN_PROGRESS = 'INPROGRESS',
  FINAL = 'FINAL',
  REPORTS = 'REPORTS',
  MARKET_DATA = 'MARKET_DATA',
  COMPLIMENTARY = 'COMPLIMENTARY',
  ANALYSIS = 'INPROGRESS,FINAL',
  DASHBOARD = 'DASHBOARDS',
}

export enum FilterCriteria {
  FLAVOR = 'flavor',
  SA_FLAVOR = 'strategyAnalyticsFlavor',
  CONTENT_TYPE = 'contentType',
  CONTENT_TYPE_LIST = 'contentTypes',
  OTHER = '',
}

export interface ContentNavigatorTab {
  filterCriteria: FilterCriteria;
  filterValue: ReportFlavor | FavoriteContentType | string;
  name: string;
  countMap?: string;
  hide: boolean;
  count?: number;
}

export interface TabGroup {
  id: string;
  tabs: Array<ContentNavigatorTab>;
  template: TemplateRef<any>;
}

export const CONTENT_NAVIGATOR_TABS: Array<ContentNavigatorTab> = [
  { filterCriteria: FilterCriteria.FLAVOR, filterValue: ReportFlavor.ALL, name: 'Analysis', countMap: 'allCount', hide: false },
];

export const CONTENT_NAVIGATOR_SA_TABS: Array<ContentNavigatorTab> = [
  { filterCriteria: FilterCriteria.SA_FLAVOR, filterValue: ReportFlavor.ALL, name: 'All Analysis', countMap: 'allAnalysisCount', hide: false },
  { filterCriteria: FilterCriteria.SA_FLAVOR, filterValue: ReportFlavor.MARKET_DATA, name: 'Market Data', countMap: 'marketDataCount', hide: false },
  { filterCriteria: FilterCriteria.SA_FLAVOR, filterValue: ReportFlavor.REPORTS, name: 'Reports', countMap: 'reportsCount', hide: false },
  { filterCriteria: FilterCriteria.SA_FLAVOR, filterValue: ReportFlavor.SAMPLE, name: 'Complimentary', countMap: 'sampleCount', hide: false },
];

export const CONTENT_NAVIGATOR_RE_CUSTOM_TABS: Array<ContentNavigatorTab> = [
  { filterCriteria: FilterCriteria.FLAVOR, filterValue: ReportFlavor.ANALYSIS, name: 'Analysis', hide: false },
];

export const CONTENT_NAVIGATOR_SA_CUSTOM_TABS: Array<ContentNavigatorTab> = [
  { filterCriteria: FilterCriteria.SA_FLAVOR, filterValue: ReportFlavor.ANALYSIS, name: 'Analysis', hide: false },
];

export const DASHBOARD_CONTENT_NAVIGATOR_TABS: Array<ContentNavigatorTab> = [
  { filterCriteria: FilterCriteria.SA_FLAVOR, filterValue: ReportFlavor.DASHBOARD, name: 'Dashboards', countMap: 'dashboardCount', hide: false },
];

export const RECENT_VIEW_CONTENT_NAVIGATOR_TABS: Array<ContentNavigatorTab> = [
  { filterCriteria: FilterCriteria.OTHER, filterValue: 'recently-viewed', name: 'Recently Viewed', hide: false },
];

export const CONTENT_NAVIGATOR_MARKET_SUMMARY_TABS: Array<ContentNavigatorTab> = [
  { filterCriteria: FilterCriteria.FLAVOR, filterValue: ReportFlavor.REPORTS, name: 'Reports', countMap: 'reportsCount', hide: false }
];

export const FAVORITES_ANALYSIS_TABS: Array<ContentNavigatorTab> = [
  {
    name: 'Analysis',
    filterCriteria: FilterCriteria.CONTENT_TYPE,
    filterValue: FavoriteContentType.ANALYSIS,
    hide: false,
  },
  {
    name: 'Images',
    filterCriteria: FilterCriteria.CONTENT_TYPE,
    filterValue: FavoriteContentType.IMAGE,
    hide: false,
  }
];

export const HOME_TAB_GROUP: TabGroup = {
  id: 'home',
  tabs: [
    {
      filterCriteria: FilterCriteria.OTHER,
      filterValue: 'my-latest',
      name: 'My Latest Analysis',
      hide: false
    },
    {
      filterCriteria: FilterCriteria.OTHER,
      filterValue: 'recently-viewed',
      name: 'My Recently Viewed',
      hide: false
    },
    {
      filterCriteria: FilterCriteria.OTHER,
      filterValue: 'recommendations',
      name: 'Recommendations',
      hide: false
    }
  ],
  template: null
}

export const ANALYSIS_TAB_GROUP: TabGroup = {
  id: 'analysisTabGroup',
  tabs: CONTENT_NAVIGATOR_TABS,
  template: null,
};

export const RE_CUSTOM_ANALYSIS_TAB_GROUP: TabGroup = {
  id: 'reCustomAnalysisGroup',
  tabs: CONTENT_NAVIGATOR_RE_CUSTOM_TABS,
  template: null,
};

export const SA_ANALYSIS_TAB_GROUP: TabGroup = {
  id: 'saAnalysisTabGroup',
  tabs: CONTENT_NAVIGATOR_SA_TABS,
  template: null,
};

export const SA_CUSTOM_ANALYSIS_TAB_GROUP: TabGroup = {
  id: 'saCustomAnalysisGroup',
  tabs: CONTENT_NAVIGATOR_SA_CUSTOM_TABS,
  template: null,
};

export const DASHBOARD_CONTENT_NAVIGATOR_TAB_GROUP: TabGroup = {
  id: 'dashboards',
  tabs: DASHBOARD_CONTENT_NAVIGATOR_TABS,
  template: null,
};

export const MS_ANALYSIS_TAB_GROUP: TabGroup = {
  id: 'msAnalysisTabGroup',
  tabs: CONTENT_NAVIGATOR_TABS,
  template: null,
};

export const TECHSTREAM_TAB_GROUP: TabGroup = {
  id: 'techstream',
  tabs: [{
    filterCriteria: FilterCriteria.CONTENT_TYPE,
    filterValue: 'blog',
    name: 'TechStream Blogs',
    hide: false,
  }],
  template: null,
};

export const FAVORITES_ANALYSIS_TAB_GROUP: TabGroup = {
  id: 'favorite-analysis',
  tabs: FAVORITES_ANALYSIS_TABS,
  template: null,
};

export const ALL_CONTENT_NAVIGATOR_TAB: ContentNavigatorTab = {
  filterCriteria: FilterCriteria.FLAVOR,
  filterValue: '',
  name: 'All Analysis',
  hide: false,
};

export const ALL_ANALYSIS_TAB_GROUP: TabGroup = {
  id: 'allAnalysisGroup',
  tabs: [
    ALL_CONTENT_NAVIGATOR_TAB,
    CONTENT_NAVIGATOR_TABS[4],
  ],
  template: null,
};

export const MARKET_SUMMARY_ANALYSIS_TAB_GROUP: TabGroup = {
  id: 'marketSummaryAnalysisGroup',
  tabs: CONTENT_NAVIGATOR_MARKET_SUMMARY_TABS,
  template: null
}

export const VIDEO_TAB_GROUP: TabGroup = {
  id: 'video',
  tabs: [{
    filterCriteria: FilterCriteria.CONTENT_TYPE,
    filterValue: 'video',
    name: 'Videos',
    hide: false
  }],
  template: null
}

export const UNIFIED_TAB_GROUP: TabGroup = {
  id: 'unifiedContent',
  tabs: [{
    filterCriteria: FilterCriteria.CONTENT_TYPE_LIST,
    filterValue: 'unifiedContent',
    name: 'unifiedContent',
    hide: true
  }],
  template: null
}

/**
 * Represents Content navigator table
 */
export interface ContentNavigatorTable<T = ContentNavigatorTableRow> {
  rows: Array<T>;
}

export interface SelectableRow {
  id: string;
  selected?: boolean;
}

/**
 * represents a single row in the content navigator table
 */
export interface ContentNavigatorTableRow extends SelectableRow {
  id: string;
  title: string;
  analysisType: string;
  productCode: string;
  description?: string;
  deviceName: string;
  manufacturer: string;
  deviceType: string;
  subscription: string;
  channel: string;
  completionDate: Date;
  documentsCount: number;
  imagesCount: number;
  entitled: boolean;
  subscriptionName?: string;
}

export interface ContentNavigatorTableFavoriteRow extends SelectableRow {
  id: string;
  description: string;
  title: string;
  productCode: string;
  contentType: FavoriteContentType;
  completionDate: Date;
  entitled: boolean;
  selected: boolean;
  assetGroupId?: string;
  clObjectId?: string;
}

/**
 * represents a paginated table
 */
export interface ContentNavigatorTablePage {
  table: ContentNavigatorTable,
  count: number,
  filters?: AnalysisFilter[],
}

export interface FlavourCounts {
  finalCount: number,
  inProgress: number,
  analystCount: number,
  reportsCount: number,
  marketDataCount: number,
  sampleCount: number,
  dashboardCount?: number,
}
