import {Component, OnInit, OnDestroy} from '@angular/core';
import { untilDestroyed } from '@app/@core';
import { IframeModalComponent } from '@app/@shared/components/iframe-modal/iframe-modal.component';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { ResourcesService } from '@app/@shared/services/resources.service';
import { selectUserProfile } from '@app/store/Selectors/workspace/workspace.selectors';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-help-and-resources',
  templateUrl: './help-and-resources.component.html',
  styleUrls: ['./help-and-resources.component.scss']
})
export class HelpAndResourcesComponent implements OnInit, OnDestroy {

  resourcesURL: any;
  userProfile$ = this.store.select(selectUserProfile);
  isUserSubscribed: boolean;
  isFreeUser = true;
  constructor(
    private resource: ResourcesService,
    private store: Store,
    private modalService: NgbModal,
  ){}

  ngOnInit(){
    this.userProfile$.pipe(untilDestroyed(this)).subscribe((profile) => {
      this.isFreeUser = profile.freeUserSubscriber;
      this.resourcesURL = this.resource.getResource(profile.type);
      this.isUserSubscribed = profile.isSubscribedUser;
    });
  }

  openCEMModal(){
    const modalInstance = this.modalService.open(IframeModalComponent, {
      scrollable: true,
      backdrop: true
    });
    modalInstance.componentInstance.formUrl = environment.contactYourCEMForm;
  }

  /**
   * initQualified injects Qualified live chat widget scripts into the document head,
   *  passing in user information to identify them.
   */
  openSupportChat() {
    (window as any).qualified('showExperience', environment.qualifiedLiveChat);
  }

  ngOnDestroy(): void{
    // Unsubscribe from subscriptions
  }
}
