<div class="measurement-dialog-container">
    <div class="measurement-type-icon" [class]="iconClass"></div>
    <div class="measurement-text-container">
        <div class="measurement-text-content">{{measurementType}}: {{measurementText}}
        </div>
        <div class="measurement-text-footer">
        {{footerText}}
        </div>
    </div>
    <div class="measurement-clear-button-container">
        <button (click)="modal.close()">Clear</button>
    </div>
</div>