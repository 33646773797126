export interface ShareLinkRequest {
  entityType: ShareLinkType;
  entityId: string;
  entityTitle: string;
  shareLink: string;
  emails: Array<string>;
  message: string;
};

export enum ShareLinkType {
  REPORT = 'REPORT',
  ASSET = 'ASSET',
  BLOG = 'BLOG',
};
