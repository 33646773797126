import { createSelector } from '@ngrx/store';
import { AppState } from '../../index';
import { WorkspaceState } from '../../Reducers/workspace/workspace.reducer';

export const selectMenu = (state: AppState) => state.workspaceState;
export const selectIsMenuLoading = (state: AppState) => state.workspaceState.isLoading;
export const selectProfileDrawerOpen = (state: AppState) => state.workspaceState.showProfileDrawer;
export const selectHelpAndResourcesDrawerOpen = (state: AppState) => state.workspaceState.showHelpAndResourcesDrawer;
export const selectMenuItems = (state: AppState) => state.workspaceState.menuItems;
export const selectVisibleButtonsAmount = (state: AppState) => state.workspaceState.visibleButtonsAmount;
export const selectMenuCollapsed = (state: AppState) => state.workspaceState.isCollapsed;
export const selectMoreContainerPosition =
(state: AppState) => state.workspaceState.showMoreMenuOptions.containerPosition;
export const selectMoreContainerShown = (state: AppState) => state.workspaceState.showMoreMenuOptions.shown;
export const selectMoreButtonClass = (state: AppState) => state.workspaceState.showMoreMenuOptions.buttonClass;

export const selectMenuItem = createSelector(selectMenu, (state: WorkspaceState, props: any) =>
  state.menuItems.find((item) => item.id === props.id)
);

export const selectUserProfile = (state: AppState) => state.workspaceState.userProfile;
export const selectWorkspaceLoaded = (state: AppState) => state.workspaceState.workspaceLoaded;