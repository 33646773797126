<div class="filter-controls">
  <app-table-viewer-columns-hide-menu
    [columns]="_columns"
    (hideColumnToggle)="handleHideColumnToggle($event)"
  ></app-table-viewer-columns-hide-menu>

  <div class="search-box">
    <em
      class="icon-filter"
      ngbTooltip="Filter table with text inputs"
      placement="top"
      container="body"
      (click)="searchInput.focus()"
    ></em>
    <input
      #searchInput
      placeholder="Filter any columns"
      [value]="searchInputValue"
      (keyup)="onSearchInputValueChange($event.target.value)"
    />
    <em
      class="icon-close_small"
      *ngIf="searchInputValue"
      (click)="onSearchInputValueChange('')"
    ></em>
  </div>
</div>

<div class="reset-controls">
  <button
    [disabled]="!_isTableSorted"
    (click)="onResetSort()"
  >
    <em class="icon-close_small"></em>
    <span>Remove Sort</span>
  </button>
  <button
    [disabled]="(
      searchInputValue.length === 0 &&
      !isSomeColumnHidden &&
      !_isColumnFilterActive
    )"
    (click)="onClearAllFilters()"
  >
    <em class="icon-close_small"></em>
    <span>Clear All Filters</span>
  </button>
</div>
