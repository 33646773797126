import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';


/**
 * An image component that sets a given placeholder if provided image 404'd.
 * If no placeholder is provided, it will use a default one
 */
@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input() alt: string;
  @Input() alternateSrc: string | SafeUrl;
  @Input() src: string | SafeUrl;

  readonly PLACEHOLDER = '../../../../assets/NotFound/Placeholder.svg';

  constructor() {
  }

  ngOnInit(): void {
  }

  getAlternateImage(event$: any) {
    if (!this.alternateSrc) {
      event$.target.src = this.PLACEHOLDER;
    } else {
      event$.target.src = this.alternateSrc;
    }
  }
}
