
<div *ngIf="isLoading; else loaded" class="progress">
  <app-progress-bar-circular></app-progress-bar-circular>
</div>
<ng-template #loaded>
  <app-title-bar [tagNameList]="[subscription]" [title]="video.title" [showBackButton]="prevPage.hasHistory()" (clickBack)="onClickBack()"></app-title-bar>
  <section class="viewer-wrapper">
    <app-video-player [videoId]="video.hashedId" (firstPlay)="onTrackVideoViewTelemetryEvent()"></app-video-player>
    <div class="video-info">
      <div class="category body-text">{{video.channelName}}</div>
      <div class="title header3-text">{{video.title}}</div>
      <div class="date body-text">{{video.published | date: 'MMM d, yyyy' : 'UTC'}}</div>
    </div>
  </section>
</ng-template>
