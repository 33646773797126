import WebViewer, { WebViewerInstance, WebViewerOptions } from '@pdftron/webviewer';

/**
 * Just a wrapper class for PDFTron Library WebViewer. This allows us to have our dependent
 * classes on pdfTron remain 'unit-testable'
 */
export class PdfTronWebViewerWrapper {

  /**
   * This is like the 'new' operation on WebViewerWrapper. Returns a promise containing a WebViewerInstance
   * @param options check WebViewerOptions object from pdf tron library
   * @param viewerElement HTMLElement the viewer will be created on
   */
  public createWebViewerInstance(options: WebViewerOptions, viewerElement: HTMLElement): Promise<WebViewerInstance> {
    return WebViewer(options, viewerElement);
  }
}