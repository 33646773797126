import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GenericToolbarComponent } from './generic-toolbar.component';

@NgModule(
  {
    declarations: [
      GenericToolbarComponent,
    ],
    imports: [
      CommonModule,
      NgbModule,
    ],
    exports: [
      GenericToolbarComponent,
    ]
  }
)
export class GenericToolbarModule {}
