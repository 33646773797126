const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export const TIME_IN_MILLISECONDS = {
  SECOND,
  MINUTE,
  HOUR,
  DAY,
} as const;
