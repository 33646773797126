<div class="image-filter-container">
  <div *ngIf="reportImagesFilter" class="filter-group" [class.group-active]="isGroupActive(reportImagesFilter.key)">
    <div class="filter-group-items items-header">
      <div class="title-container" (click)="onChangeFilter(reportImagesFilter.key, reportImagesFilter.key)">
        <div class="title strong">Report Images</div>
        <div class="amount">{{ reportImagesFilter.amount }}</div>
      </div>
    </div>
  </div>

  <div class="filter-group" *ngIf="sourceImagesAmount > 0">
    <div class="filter-group-items items-container">
      <div class="title-container" (click)="onChangeFilter(null, baseFolderKey)" [class.item-selected]="isGroupActive(baseFolderKey)">
        <div class="title strong">All Source Images</div>
        <div class="amount">{{ sourceImagesAmount }}</div>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="filterTemplate; context:{filterList: sourceImagesFilterList}">
    </ng-container> 
  </div>
</div>

<ng-template let-filterList="filterList" #filterTemplate>  
  <div class="filter-group-items items-container">
    <div
      *ngFor="let filter of filterList"
      class="filter-group-items child-container"
      [class.group-closed]="!filter.expanded"
    >
      <em 
        class="icon-down_small icon"
        (click)="onClickChevron(filter)"
        [ngStyle]="{'left': (24 * (filter.level - 1)) + 'px'}"
        *ngIf="filter.children.length"
      >
      </em>
      <div class="title-container" [class.item-selected]="isFilterSelected(filter.key)" (click)="onChangeFilter(filter.key, null)">
        <div class="title" [ngStyle]="{'padding-left': (24 * filter.level) + 'px'}" title="{{ filter.label }}">{{ filter.label }}</div>
        <div class="amount" title="{{ filter.amount }}">{{ filter.amount }}</div>
      </div>
      
      <ng-container *ngIf="filter.children.length">
        <ng-container *ngTemplateOutlet="filterTemplate; context:{filterList: filter.children}">
        </ng-container> 
      </ng-container> 
    </div>
  </div>
</ng-template>