<div class="kimera-colour-picker-container" appClickOutside (clickOutside)="onClickOutsideColourPicker()" [ngStyle]="_currentSelectedTileSourceLayer? getStyle() : null">
    <p class="heading">Set colour and opacity</p>
    <button class="closeButton" (click)="onClickOutsideColourPicker()"><em class="icon-close_medium"></em></button>
    <app-no-fill-colour-picker
    [currentOpacity]="currentOpacity"
    [currentColour]="currentColour"
    [colours]="colours"
    (changeOpacity)="onChangeOpacity($event)"
    (changeColour)="onColourChange($event)"
    ></app-no-fill-colour-picker>
    <div class="controls">
        <button (click)="onResetToDefault()" class="resetButton" [disabled]="_currentSelectedTileSourceLayer && _currentSelectedTileSourceLayer.colour==='' && _currentSelectedTileSourceLayer.opacity===1">Reset to default</button>
        <button (click)="apply()" class="applyButton">Apply</button>
    </div>
</div>