import { Component, Input, Output, EventEmitter } from '@angular/core';
import { formatBytes } from '@app/@shared/utils/common';
import {
  Analysis,
  AnalysisAsset,
  AnalysisImage,
  AnalysisReportPage,
  AnalysisResponseThumbnails,
} from '@app/@shared/models/reverse-engineering/analysis.models';
import { Image } from '@shared/models/image';
import { Thumbnail } from '@app/@shared/models/thumbnail';
import { PaginationParameters } from '@app/@shared/models/pagination';

@Component({
  selector: 'app-image-files',
  templateUrl: './image-files.component.html',
  styleUrls: [ './image-files.component.scss' ],
})
export class ImageFilesComponent {

  @Input() set currentAnalysis(analysis: Analysis) {
    this.analysis = analysis;
  }
  @Input() set analysisReports(objects: AnalysisResponseThumbnails) {
    this.processReports(objects as AnalysisReportPage);
    this.processThumbnails(objects);
  }
  @Input() set selectedImage(image: { objectId: string; url: string }) {
    if (image) {
      const index = this.imagesIndexMap.get(image.objectId);
      if (this.viewerImages[index]) {
        this.viewerImages[index].url = image.url;
        this._selectedImage = { ...this.viewerImages[index] };
        this.imageNumber = index + 1;
      }
    }
  }
  @Input() title: string;
  @Input() isLoading = false;
  @Input() paginationParameters: PaginationParameters;
  @Input() searchTerm: string;
  @Input() showImageViewer = false;
  @Input() channelId: string;
  @Input() subscriptionId: string;
  @Input() downloadEnabled = false;
  @Input() addToFavoritesEnabled = false;
  @Input() enableUnwatermarkedImageRequest = false;

  @Output() changeSelectedImage = new EventEmitter<Image>();
  @Output() changePaginationParameters = new EventEmitter<PaginationParameters>();
  @Output() closeImageViewer = new EventEmitter<void>();
  @Output() downloadImage = new EventEmitter<Image | AnalysisImage>();
  @Output() downloadImages = new EventEmitter<any>();
  @Output() shareImage = new EventEmitter<Image | AnalysisImage>();
  @Output() addToFavorites = new EventEmitter<Image>();

  public analysis: Analysis;
  public imageCount: number;

  public viewMode = 'list';
  public images: Array<AnalysisImage>;
  public viewerImages: Array<Image> = [];
  public imagesIndexMap: Map<string, number> = new Map();
  public _selectedImage: Image;
  public imageNumber = 0;

  public onClickView(view: string): void {
    this.viewMode = view;
  }

  public onClickImage(image: AnalysisImage): void {
    const index = this.imagesIndexMap.get(image.objectId);
    this.onChangeSelectedImage(this.viewerImages[index]);
  }

  public onCloseImageViewer(): void {
    this.closeImageViewer.emit();
  }

  public onThumbnailClicked(clId: string): void {
    const index = this.imagesIndexMap.get(clId);
    this.onChangeSelectedImage(this.viewerImages[index]);
  }

  public onNextImage(): void {
    if (this.imageNumber < this.viewerImages.length) {
      this.onChangeSelectedImage(this.viewerImages[this.imageNumber]);
    }
  }

  public onPreviousImage(): void {
    if (this.imageNumber > 1) {
      this.onChangeSelectedImage(this.viewerImages[this.imageNumber - 2]);
    }
  }

  public onDownloadImage($image: Image | AnalysisImage): void {
    this.downloadImage.emit($image);
  }

  public onDownloadImages(selectedIds: any): void {
    this.downloadImages.emit(selectedIds);
  }

  public onShareImage(image: any): void {
    this.shareImage.emit(image);
  }

  public onAddToFavorites(image: Image): void {
    this.addToFavorites.emit(image);
  }

  public onChangePaginationOptions(paginationParams: PaginationParameters): void {
    this.changePaginationParameters.emit(paginationParams);
  }

  /**
   * processReports maps the list of reports to an array used for the list in the UI.
   * @param reportsPage AnalysisReportPage returned by getReports.
   */
  private processReports(reportsPage: AnalysisReportPage): void {
    if (!this.analysis || !reportsPage) {
      return;
    }
    this.images = [];
    this.viewerImages = [];
    this.imagesIndexMap = new Map();
    reportsPage.reports?.forEach((reportAsset: AnalysisAsset) => {
      this.images.push({
        fileType: reportAsset.fileType,
        thumbnailUrl: null,
        description: '',
        name: reportAsset.assetName,
        id: reportAsset.clObjectId,
        objectId: reportAsset.clObjectId,
        title: '',
        entitled: null,
        publishDate: null,
        reportCode: this.analysis.code,
        size: formatBytes(reportAsset.fileSize),
        assetId: reportAsset.assetId,
        assetGroupId: reportAsset.assetGroupId,
      });
      const dateIndex = this.analysis.analysisEndDate ? new Date(this.analysis.analysisEndDate) : null;
      const lastLength = this.viewerImages.push({
        id: reportAsset.clObjectId,
        url: null,
        url_expires: null,
        metadata: {},
        summaryData: {
          filename: reportAsset.assetName,
          analysisTitle: this.analysis.name,
          code: this.analysis.code,
          date: dateIndex?.toLocaleDateString(),
          deviceName: this.analysis.deviceName,
          devicePartNumber: this.analysis.devicePartNumber,
          manufacturer: this.analysis.deviceManufacturer,
          deviceType: this.analysis.deviceType,
          downstreamProduct: this.analysis.downstreamProduct,
          itemDescription: this.analysis.description,
        },
        thumbnailUrl: null,
        objectId: reportAsset.clObjectId,
        reportId: this.analysis.id ?? reportAsset.reportId,
        analysisData: {
          reportCode: this.analysis.code,
          fileType: reportAsset.fileType,
          objectId: reportAsset.clObjectId,
        },
        assetId: reportAsset.assetId,
      });
      this.imagesIndexMap.set(reportAsset.clObjectId, lastLength - 1);
    });
    this.imageCount = reportsPage.count;
  }

  /**
   * processThumbnails maps the list of thumbnails to an array used for the list in the UI.
   * @param reportThumbnails AnalysisResponseThumbnails returned by getThumbnails.
   */
  private processThumbnails(reportThumbnails: AnalysisResponseThumbnails): void {
    if (this.analysis && reportThumbnails) {
      reportThumbnails.thumbnails = reportThumbnails.thumbnails.filter((thumb: Thumbnail) => !!thumb.signedUrl);
      reportThumbnails.thumbnails.forEach((thumb: Thumbnail) => {
        const imgIndex = this.imagesIndexMap.get(thumb.objectId);
        if (this.images[imgIndex]) {
          this.images[imgIndex].thumbnailUrl = thumb.signedUrl;

        }
        if (this.viewerImages[imgIndex]) {
          this.viewerImages[imgIndex].url = thumb.signedUrl;
          this.viewerImages[imgIndex].thumbnailUrl = thumb.signedUrl;

        }
      });
      this.images = this.images.filter((thumb: AnalysisImage) => !!thumb.thumbnailUrl);
      this.viewerImages = this.viewerImages.filter((thumb: Image) => !!thumb.thumbnailUrl);

      this._selectedImage = undefined;
      this.imageNumber = 0;
    }
  }

  private onChangeSelectedImage(image: Image): void {
    this._selectedImage = image;
    this.imageNumber = this.imagesIndexMap.get(image.objectId) + 1;
    this.changeSelectedImage.emit(image);
  }

}
