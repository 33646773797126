import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICheckbox, ICheckboxProps } from '@shared/components/checkbox/checkbox.component';
import { DialogConfirmations } from '@shared/consts/messages';

export interface ModalResponse {
  isDismissed: boolean,
  dontShowAgain: boolean,
};

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  checkBoxSelected = false;
  checkboxState: ICheckbox[];
  checkboxProps: ICheckboxProps[] = [{
    key: 'key',
    initialValue: false,
    disabled: false,
    class: 'custom-checkbox',
    placeholder: '',
    label: `Don't show this message again`
  }];

  props = {
    title: DialogConfirmations.digestSubscriptions.message,
    closeButtonLabel: 'Cancel',
    actionButtonLabel: 'Confirm',
    body: ''
  };

  constructor(private modal: NgbActiveModal) {}

  onClickCheckbox(state: ICheckbox[]) {
    this.checkBoxSelected = state[0].value;
  }

  onClose(isDismissed: boolean) {
    const state: ModalResponse = {
      isDismissed,
      dontShowAgain: this.checkBoxSelected,
    };
    this.modal.close(state);
  }
}
