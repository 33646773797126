import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Blog } from '@shared/models/market-analysis/market-analysis';
import { withLatestFrom, tap, concatMap, map, mergeMap, catchError } from 'rxjs/operators';
import { SearchService } from '@shared/services/search/search.service';
import { MetaService } from '@shared/services/meta.service';
import { moduleNameFromUrl } from '@shared/utils/common';
import { TelemetryEvent } from '@shared/models/telemetry-event';
import { TelemetryEventName } from '@shared/enums';
import {
  UserViewHistoryItemSource,
  UserViewHistoryItemType,
} from '@app/@shared/models/user-view-history/user-view-history-items';
import { RecentViewHistoryService } from '@app/@shared/services/recent-view-history/recent-view-history.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-blog-loader',
  templateUrl: './blog-loader.component.html',
  styleUrls: ['./blog-loader.component.scss'],
})
export class BlogLoaderComponent implements OnInit {
  subscriptionName: string;
  blog: Blog;
  isLoading = true;
  subscriptionDigestSearch: boolean;
  isTechstreamView: boolean;
  reportCode: string;
  subscriptionId: string;
  channelId: string;

  private name: string;

  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    private metaService: MetaService,
    private viewHistoryService: RecentViewHistoryService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        withLatestFrom(this.route.fragment),
        map(([params, fragment]) => ({
          postId: params.get('postId'),
          fragment: new URLSearchParams(fragment ?? ''),
        })),
        tap(({ fragment }) => {
          const name = fragment.get('name')?.replace(/_/g, ' ');
          const subscription = fragment.get('subscription');
          this.reportCode = fragment.get('code');
          this.subscriptionId = fragment.get('subscriptionId');
          this.channelId = fragment.get('channelId');
          this.subscriptionName = subscription || name;
          this.name = this.subscriptionName === 'Processor Analysis' ? this.subscriptionName : name;
        }),
        concatMap(({ postId }) => this.searchService.getBlog(postId, this.name, this.router.url.includes('wp-asset'))),
        catchError(() => {
          return of(null);
        }),
        untilDestroyed(this)
      )
      .subscribe((post) => {
        if (post) {
          this.subscriptionDigestSearch = this.route.snapshot.data.subscriptionDigestSearch;
          this.subscriptionName = post.category === '' ? post.category : this.subscriptionName;
          this.blog = post;
          this.isTechstreamView = post.tags.some((tag: string) => tag === 'techstream');
          if (this.router.url.includes('wp-asset')) {
            this.postTelemetryEvent(TelemetryEventName.EVENT_WP_VIEW_IN_BROWSER);
            this.sendViewHistoryEvent(post);
          }

          this.isLoading = false;
        }
      });
  }

  private postTelemetryEvent(eventName: TelemetryEventName): void {
    this.metaService
      .getFullReportByCode(this.reportCode)
      .pipe(
        mergeMap((response) => {
          const payload = {
            artifactName: `${this.blog.title}.html`,
            artifactType: 'text/html',
            reportCode: this.reportCode,
            channelId: this.channelId,
            subscriptionId: this.subscriptionId,
            reportId: response.id,
            reportTitle: this.blog.title,
          };

          const applicationModule = moduleNameFromUrl(this.router.url);
          const event = new TelemetryEvent(eventName, payload, applicationModule);

          return this.metaService.postTelemetryEvent(event);
        })
      )
      .subscribe();
  }

  private sendViewHistoryEvent(blog: Blog) {
    const moduleId = window.location.toString().split('/')[3].trim();
    this.viewHistoryService
      .postRecentViewHistory({
        itemName: blog.title,
        itemType: UserViewHistoryItemType.BLOG,
        itemSource: UserViewHistoryItemSource.META,
        itemId: blog.id,
        itemPublicationDate: new Date(blog.publicationDate),
        channelSfId: this.channelId,
        subscriptionSfId: this.subscriptionId,
        moduleId,
        clObjectId: null,
        payload: {
          url: this.router.url,
          isAnnotated: false,
          container: 'blog-viewer',
          reportCode: this.reportCode,
        },
      })
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
