<div *ngIf="searchBox" class="search-box">
  <em class="icon-filter"></em>
  <input class="search-box-input" [(ngModel)]="filterText" (keyup)="filterFacets()" placeholder="Filter options..." />
  <em class="icon-close_small" *ngIf="filterText" (click)="clearFacetFilter()"></em>
</div>
<div class="filter-group" *ngFor="let group of filteredGroups">
  <div class="filter-subscription">{{group.label}}</div>
  <div class="filter-facet" *ngFor="let facet of group.options">
    <label [for]="facet.id" class="it-checkbox" [class.selected]="facet.selected">
      <input [id]="facet.id" type="checkbox" [(ngModel)]="facet.selected" (change)="onChangeFilter(facet)" />
      <div></div>
      <span>{{ facet.label }}</span>
    </label>
  </div>
</div>