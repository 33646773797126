<app-title-bar
  title="Automotive - IC Design Wins"
  [tagNameList]="['Automotive']"
  [showBackButton]="prevPage.hasHistory()"
  (clickBack)="onClickBack()"
></app-title-bar>

<ng-container *ngIf="loadingTableViewer; else loaded">
  <div class="loading-indicator">
    <app-progress-bar-circular></app-progress-bar-circular>
  </div>
</ng-container>
<ng-template #loaded>
  <app-table-viewer
    *ngIf="tableViewerOptions"
    [tableViewerOptions]="tableViewerOptions"
  ></app-table-viewer>
</ng-template>
