import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MetaService, FileDownloadStatus, AssetZipJob } from '@shared/services/meta.service';

export const DEFAULT_POLL_INTERVAL = 1000;

export enum DownloadDialogTitle {
  PROCESSING = 'Creating your download package',
  COMPLETE = 'Download Ready',
}

@Component({
  selector: 'app-download-progress-modal',
  templateUrl: './download-progress-modal.component.html',
  styleUrls: ['./download-progress-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DownloadProgressModalComponent implements OnInit {
  @Input() jobId: string;

  public title = DownloadDialogTitle.PROCESSING;
  public message = 'Your file is now ready to download. Click the button below to download.';
  public downloadEnabled = false;
  public progress = 0;
  public skippedFiles: string[];
  public fileUrl = '';
  public timeOutId: any;

  constructor(private modal: NgbActiveModal, private metaService: MetaService) {}

  ngOnInit(): void {
    this.updateJobStatus();
  }

  onClickDownload(): void {
    this.modal.close('saveClicked');
    window.open(this.fileUrl);
  }

  onClickCancel(): void {
    clearTimeout(this.timeOutId);
    this.modal.close('cancelClicked');
  }

  private updateJobStatus(): void {
    this.metaService.getAssetZipJobsById(this.jobId).subscribe(
      (job: AssetZipJob) => {
        this.progress = job.percentage * 100;
        if (job.details && job.details.skippedFiles) {
          this.skippedFiles = job.details.skippedFiles;
        }
        if( job.state === FileDownloadStatus.COMPLETE) {
          this.title = DownloadDialogTitle.COMPLETE;
          this.fileUrl = job.signed_url;
          this.downloadEnabled = true;
        } else {
          this.pollJobStatus();
        }
      },
      (_err) => {
        this.modal.close('error');
      }
    );
  }

  private pollJobStatus(timeout: number = DEFAULT_POLL_INTERVAL): void {
    this.timeOutId = setTimeout(() => {
      this.updateJobStatus();
    }, timeout);
  }
}
