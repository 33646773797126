import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MegaMenu } from '@app/@shared/models/navigation/navigation.models';
import { NavigationService } from '@app/@shared/services/navigation/navigation.service';
import { environment } from '@env/environment';
import { combineLatest, Observable } from 'rxjs';
import { URLUtils } from '@app/@shared/utils/url-utils';

@Component({
  selector: 'app-nav-mega-menu',
  templateUrl: './nav-mega-menu.component.html',
  styleUrls: ['./nav-mega-menu.component.scss'],
})
export class NavMegaMenuComponent implements OnInit {
  @Input() getMegaMenu: () => Observable<MegaMenu[]>;
  menuItems: MegaMenu[] = [];
  currentlyOpenMenu: string;
  basePath = environment.basePath;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
  ) {
    this.getMegaMenu = this.getMegaMenu ?? this.navigationService.getMegaMenu.bind(this.navigationService);
  }

  ngOnInit(): void {
    combineLatest([this.getMegaMenu(), this.route.queryParamMap]).subscribe(
      ([megaMenu, queryParams]) => {
        const activeMenu = queryParams.get('activeMenu');
        this.menuItems = megaMenu;
        this.setMenuActive('');
        if (activeMenu) {
          this.setMenuActive(activeMenu);
        }
      }
    );
  }

  buildQueryParams(link: string, name: string): string {
    const linkPrefix = environment.basePath;
    if(link?.includes('tech-library')) {
      return link;
    }
    const url = URLUtils.addQueryParams(linkPrefix + link, { activeMenu: name });
    return url.replace(linkPrefix, '');
  }

  onClickMenuItem(link: string, name: string, shouldOpenMenu = false) {
    if(link) {
      const url = this.buildQueryParams(link, name);
      this.router.navigateByUrl(url);
    } else if(shouldOpenMenu) {
      this.currentlyOpenMenu = name;
    }
  }

  closeMenu() {
    this.currentlyOpenMenu = '';
  }

  hasMenu(item: MegaMenu) {
    return item.primaryMenu?.length > 0 || item.secondaryMenu?.length > 0;
  }

  private setMenuActive(activeMenu: string){
    this.menuItems = this.menuItems.map((item) => ({
      ...item,
      active: item.name === activeMenu,
    }));
  }
}
