import { Component, OnInit, Input } from '@angular/core';
import { NotificationModel } from '@app/store/Models/notification/notificationModel';
import { Store } from '@ngrx/store';
import { dismissNotification } from '@app/store/Actions/notification/notification.actions';

/**
 * A Notification item for the nav notification box
 */
@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
  @Input() notification: NotificationModel;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {}

  dismiss(id: string, $event: any) {
    this.store.dispatch(dismissNotification({id}));
    $event.stopPropagation();
    $event.preventDefault();
  }
}
