import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONTENT_NAVIGATOR_TABLE_ACTIONS, GENERIC_PANEL_ACTION_ADD_TO_FAVORITES, GENERIC_PANEL_ACTION_REMOVE_FROM_FAVORITES, GenericPanelAction, GenericPanelActionType } from '@app/@shared/models/generic-panel/generic-panel.model';
import { DialogService } from '@app/@shared/services/dialog/dialog.service';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { MyLibraryService } from '@app/@shared/services/my-library/my-library.service';
import { FavoriteContentType } from '@app/my-library/models/my-library.models';
import { environment } from '@env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ContentNavigatorTable, ContentNavigatorTableRow, SelectableRow } from '@shared/models/content-navigator/content-navigator.model'
import { DefaultSubscription, Subscription } from '@shared/models/navigation/navigation.models';
import { ShareItemModalComponent } from '../../share-item-modal/share-item-modal.component';
import { ShareLinkType } from '@app/@shared/models/share-link/share-link.model';
import { RouteHelper } from '@app/@shared/utils/route-helper';

@Component({
  selector: 'app-content-navigator-table',
  templateUrl: './content-navigator-table.component.html',
  styleUrls: [ './content-navigator-table.component.scss' ],
})
@UntilDestroy()
export class ContentNavigatorTableComponent<Row extends SelectableRow> implements OnInit {

  @Input() subscription: Subscription = new DefaultSubscription();
  @Input() table: ContentNavigatorTable<Row>;
  @Input() showNoResults = false;
  @Input() annotatedReports : string[] = [];

  @Output() clickRow = new EventEmitter<Row>();
  @Output() clickOpenNewTab = new EventEmitter<Row>();
  @Output() clickImageCounter = new EventEmitter<Row>();
  @Output() clickFileCounter = new EventEmitter<Row>();
  @Output() clickShare = new EventEmitter<Row>();

  public isFavoritesTable = false;
  public panelActions: Array<GenericPanelAction> = [];
  public get selectedRows(): Array<Row> {
    return this.table?.rows.filter((row) => row.selected) ?? [];
  }

  constructor(
    protected myLibraryService: MyLibraryService,
    protected dialogService: DialogService,
    protected router: Router,
    protected route : ActivatedRoute,
    protected ffService: FeatureFlagService,
  ) { }

  ngOnInit(): void {
    const panelActions = [...CONTENT_NAVIGATOR_TABLE_ACTIONS];
    if(this.isFavoritesTable){
      panelActions.push(GENERIC_PANEL_ACTION_REMOVE_FROM_FAVORITES);
    }else{
      panelActions.push(GENERIC_PANEL_ACTION_ADD_TO_FAVORITES);
    }
    this.panelActions = panelActions;
  }

  public onClickRow(row: Row, event: Event ) {
    event.preventDefault();
    event.stopPropagation();
    this.clickRow.emit(row);
  }

  public onClickImageCounter(event: Event, row: any) {
    event.preventDefault();
    event.stopPropagation();
    if (row.entitled && row.imagesCount !== 0) {
      this.clickImageCounter.emit(row);
    }
  }

  public onClickFileCounter(event: Event, row: any) {
    event.preventDefault();
    event.stopPropagation();
    if (row.entitled && row.documentsCount !== 0) {
      this.clickFileCounter.emit(row);
    }
  }

  public onClickOpenNewTab(event: Event, row: Row) {
    event.preventDefault();
    event.stopPropagation();
    window.open(this.createRowUrl(row), '_blank', 'noopener=true');
  }

  public onClickShare(event: Event, row: Row) {
    event.preventDefault();
    event.stopPropagation();
    this.openShareModalWindow(row);
  }

  public onClickAddToFavorites(event: Event, row: Row) {
    event.preventDefault();
    event.stopPropagation();
    this.myLibraryService.addToFavorites([row.id], FavoriteContentType.ANALYSIS).subscribe();
  }

  public onSelectRow(event: any, row: Row) {
    event.preventDefault();
    event.stopPropagation();
    row.selected = !row.selected;
  }

  public onAllRowsSelected(selected: boolean) {
    this.table?.rows.forEach((row) => {
      row.selected = selected;
    });
  }

  public onClickPanelAction(action: GenericPanelAction) {
    switch (action.type) {
      case GenericPanelActionType.ADD_TO_FAVORITES:
        this.myLibraryService
          .addToFavorites(this.selectedRows.map((row) => row.id), FavoriteContentType.ANALYSIS)
          .subscribe();
        break;
      case GenericPanelActionType.REMOVE_FROM_FAVORITES:
        this.myLibraryService
          .removeFavorites(this.selectedRows.map((row) => row.id))
          .subscribe();
        break;
    }
  }

  public isAnnotationReport(productCode: string) : boolean {
    return this.annotatedReports.includes(productCode)
  }

  protected createRowUrl(r: Row): string {
    // Here we assume all rows are analysis, if not this method should be overridden
    const row = r as unknown as ContentNavigatorTableRow;
    return this.router.serializeUrl(
      this.router.createUrlTree(['analysis-view', row.productCode], {
        // if we are not in a module (which shouldn't happen)
        // we go to the shell which has an analysis-view route
        relativeTo: RouteHelper.getModuleRouteOrShell(this.route),
      })
    );
  }

  protected openShareModalWindow(r: Row) {
    // Here we assume all rows are analysis, if not this method should be overridden
    const row = r as unknown as ContentNavigatorTableRow;
    this.dialogService.open(ShareItemModalComponent, { windowClass: 'shareModalClass' }, {
      isDocumentShareable: false,
      shareItemEntityId: row.id,
      shareItemEntityTitle: row.title,
      itemDeepLinkUrl: environment.basePath + this.createRowUrl(r),
      shareEntityType: ShareLinkType.REPORT,
    });
  }
}
