import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {map, mergeMap} from 'rxjs/operators';
import {forkJoin, from, Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Core, WebViewerInstance} from '@pdftron/webviewer';
import {PdfTronWebViewerWrapper} from '@shared/utils/pdftron-webviewer-wrapper';
import {Metadata} from '@shared/models/metadata';
import {ToolbarType, ToolbarTypes} from '@shared/components/generic-toolbar/toolbar-type';
import {CustomOption, OptionGroup, PropagationEvent} from '@shared/components/generic-toolbar/option-model';
import {Icon} from '@shared/components/generic-toolbar/icons';
import {
  StatefullBehavior,
  StatelessBehavior,
  ToggleBehavior,
} from '@shared/components/generic-toolbar/state-behavior-model';
import {ShareItemModalComponent} from '@shared/components/share-item-modal/share-item-modal.component';
import {PdfAssetInfo} from '@shared/models/pdf-asset-info';
import {AnnotationService} from '@shared/services/annotation/annotation.service';
import {AuthenticationService} from '@app/auth';
import {
  PdfViewerNotesPanelHeaderComponent
} from '@shared/components/pdf-viewer/pdf-viewer-notes-panel-header/pdf-viewer-notes-panel-header.component';
import {AnnotationRequest} from '@shared/models/annotation/annotation.models';
import {AnnotationType} from '@shared/enums/annotation-type';
import {AccessibilityState} from '@shared/enums/accessibility-state';
import {AnnotationAction} from '@shared/enums/annotation-action';
import {
  PdfViewerFiltersModalComponent
} from '@shared/components/pdf-viewer/pdf-viewer-filters-modal/pdf-viewer-filters-modal.component';
import { CrossLinkingService } from '../../services/pdf-links/cross-linking.service';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '@shared/services/meta.service';
import { DialogService } from '@app/@shared/services/dialog/dialog.service';

@UntilDestroy()
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: [ './pdf-viewer.component.scss' ],
})
export class PdfViewerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public static readonly ZOOM_STEP = 0.1;

  @Input() set pdfAssetInfo(pdfAssetInfo: PdfAssetInfo) {
    this.pdfAsset = pdfAssetInfo;
    this.setUrl(pdfAssetInfo.url);
  }
  @Input() toolbarTitle = '';
  @Input() metadata: Metadata;
  @Input() addWatermarks = false;
  @Input() showCloseButton = false;
  @Input() showDownloadButton = false;
  @Input() showSharedButton = false;
  @Input() downloadingPdf = false;

  @Output() showDownloadButtonChange = new EventEmitter();
  @Output() closePdfViewer = new EventEmitter();
  @Output() downloadPdf = new EventEmitter();

  @ViewChild('docViewer') docViewer: ElementRef;
  @ViewChild('filtersModal') filtersModal: PdfViewerFiltersModalComponent;
  @ViewChild('notesPanelHeader') notesPanelHeader: PdfViewerNotesPanelHeaderComponent;


  public pdfAsset: PdfAssetInfo;
  public documentUrl: string;
  public webViewerInstance: WebViewerInstance | any;
  public annotationManager: Core.AnnotationManager;
  public toolbarType: ToolbarType = ToolbarTypes.PDF_VIEWER_NO_METADATA;
  public currentPage = 1;
  public totalPages = 1;
  public toolbarOptions: OptionGroup[];
  public annotationSidebarOptionsBehavior = new ToggleBehavior(
    Icon.INFO, 'Click to open or hide the Comments Panel', Icon.INFO,
    'Click to open or hide the Comments Panel'
  );
  public eventToMethod = {
    'prev-page': 'goToPreviousPage',
    'next-page': 'goToNextPage',
    view: 'toggleView',
    annotate: 'toggleAnnotate',
    'annotation-sidebar': 'toggleAnnotationSidebar',
    'hide-annotation': 'toggleAnnotations',
    'pan-tool-mode': 'setPanToolMode',
    'select-tool-mode': 'setSelectToolMode',
    'zoom-out': 'zoomOut',
    'zoom-in': 'zoomIn',
    'toggle-fullscreen': 'onClickToggleFullscreen',
    'fit-to-page': 'fitPage',
    'toggle-search-box': 'toggleSearch',
    'close-viewer': 'closeViewer',
    share: 'share',
    download: 'download',
  };
  public annotationsFiltersCount = 0;
  public annotationsLoaded = false;
  public accessibilityState = AccessibilityState.none;
  public showAnnotations = true;
  public isLeftPanelOpen = true;
  private _pdfAssetId: string;

  constructor(
    private authenticationService: AuthenticationService,
    private pdfTronWebViewerWrapper: PdfTronWebViewerWrapper,
    private annotationService: AnnotationService,
    private crossLinkingService: CrossLinkingService,
    private route: ActivatedRoute,
    private metaService: MetaService,
    private dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.toolbarOptions = this.createToolbarOptions();
  }

  public ngAfterViewInit(): void {
    this.setUrl(this.documentUrl);
  }

  /**
   * ngOnChanges is used to detect metadata being set.
   *  This is not possible in ngOnInit.
   * @param changes SimpleChanges object passed in by the lifecycle hook.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.metadata) {
      this.toolbarType = this.metadata ? ToolbarTypes.PDF_VIEWER : ToolbarTypes.PDF_VIEWER_NO_METADATA;
    }
    // This needs to be refactored in a better way
    if (changes.showDownloadButton) {
      this.toolbarOptions = this.createToolbarOptions();
    }
  }

  public ngOnDestroy(): void {
    this.closeDocument();
  }

  public setUrl(url: string): void {
    if (url) {
      this.documentUrl = url;
      if (this.docViewer) {
        if (!this.webViewerInstance) {
          this.createPdfViewer(url);
        } else {
          this.loadDocument(url);
        }
      }
    } else {
      this.documentUrl = undefined;
      this.closeDocument();
    }
  }

  public createPdfViewer(url: string): void {
    this.pdfTronWebViewerWrapper.createWebViewerInstance(
      {
        path: '../../../../assets/lib/webviewer/public',
        disabledElements: [
          // Menu
          'viewControlsButton',
          'ribbons',
          'menuButton',
          'toggleNotesButton',
          // Side view actions
          'leftPanelButton',
          'thumbnailControl',
          'documentControl',
          // Resize bars
          'leftPanelResizeBar',
          'searchPanelResizeBar',
          // Others action
          'hideAnnotation',
          'panToolButton',
          'printModal',
          'textPopup',
          'header',
        ],
        css: '/assets/css/pdf-viewer/pdf-viewer.css',
        isReadOnly: false,
        enableAnnotations: true,
        enableRedaction: false,
      },
      this.docViewer.nativeElement,
    )
    .then((instance: WebViewerInstance) => {
      this.webViewerInstance = instance;
      this.webViewerInstance.UI.setTheme('dark');
      this.annotationManager = instance.Core.annotationManager;
      this.setCurrentUser();
      this.toggleView();
      this.loadDocument(url);
      this.addListeners();
      this.setupToolbar();
      this.setupFullscreenFeature();
      this.generateAnnotateHeaderItems();
      // In v8.2 dangerouslySetNoteTransformFunction is moved to PdfViewerComponent.UI
      this.webViewerInstance.UI.dangerouslySetNoteTransformFunction(
        (noteWrapper: HTMLElement, state: {
          annotation: Core.Annotations.Annotation;
          isSelected: boolean;
        }, createElement: any) =>
          this.setNoteTransformFunctionEvent(noteWrapper, state, createElement)
      );
      this.changeAnnotationsSidebarHeader();
      this.setupCrossLinking(instance);
    });
  }

  setCurrentUser(): void {
    this.authenticationService.me().subscribe((user) => {
      this.annotationManager.setCurrentUser(user.email);
    });
  }

  public loadDocument(url: string): void {
    this.closeDocument();
    this.setupWatermarks();
    this.webViewerInstance.Core.documentViewer.loadDocument(url, {
      filename: 'file.xod',
      xodOptions: { streaming: false },
    });
    this.initializePdfViewer();
  }

  /**
   * closeDocument closes the current PDF open in the viewer, if there is one.
   */
  public closeDocument(): void {
    if (this.webViewerInstance) {
      this.webViewerInstance.Core?.documentViewer.dispose();
      this.webViewerInstance.UI?.closeDocument().then();
    }
  }

  /**
   * closeViewer handles clicking the close button in the toolbar
   */
  public closeViewer(): void {
    this.closeDocument();
    this.closePdfViewer.emit();
  }

  public share(): void {
    const shareFormModalInstanse = this.dialogService.open(ShareItemModalComponent, { windowClass: 'shareModalClass' })
    shareFormModalInstanse.componentInstance.clObjectId = this.pdfAsset.clObjectId;
    shareFormModalInstanse.componentInstance.isDocumentShareable = true;
    shareFormModalInstanse.componentInstance.shareEntityType = 'ASSET';
  }

  public download(): void {
    this.downloadPdf.emit();
  }

  /**
   * setupWatermarks conditionally configures watermarks.
   *  Originally added for reports in
   *  https://techinsights.atlassian.net/browse/CP-1458
   *  Now that the PDF viewer is standalone,
   *  watermarks are enabled with the addWatermarks flag.
   */
  public setupWatermarks(): void {
    if (this.addWatermarks) {
      const watermarkOptions = {
        custom: (ctx: CanvasRenderingContext2D, pageWidth: number, pageHeight: number) => {
          ctx.fillStyle = '#404040';
          ctx.font = '36pt sans-serif';
          ctx.textBaseline = 'middle';
          ctx.textAlign = 'center';
          ctx.globalAlpha = 0.2;
          ctx.save();
          ctx.translate(pageWidth / 2, pageHeight / 2);
          ctx.fillText('TechLibrary', 0, 0);
          ctx.fillText('Property of TechInsights Inc.', 0, 60);
          ctx.fillText('All Rights Reserved', 0, 110);
          ctx.restore();
        },
      };
      this.webViewerInstance.Core.documentViewer.setWatermark(watermarkOptions);
    }
  }

  public filterNotesByAccessibility(): void {
    const annotations = this.annotationManager.getAnnotationsList().filter(annotation => {
      return annotation.getCustomData('accessibility') === this.accessibilityState ||
        this.accessibilityState === AccessibilityState.none ||
        this.accessibilityState === AccessibilityState.both;
    });

    this.annotationManager.hideAnnotations(this.annotationManager.getAnnotationsList());
    this.annotationManager.showAnnotations(annotations);
  }

  public addListeners(): void {
    this.docViewer.nativeElement.addEventListener('pageChanged', (event: any) => {
      const [pageNumber] = event.detail;
      this.currentPage = pageNumber;
    });

    this.webViewerInstance.Core.documentViewer.addEventListener('documentLoaded', () => {
      this.totalPages = this.webViewerInstance.Core.documentViewer.getPageCount();
      setTimeout(() => {
        this.webViewerInstance.UI.setFitMode(this.webViewerInstance.UI.FitMode.FitWidth);
      },500);


      this.metaService.getAssetFromClId(this.pdfAsset.clObjectId)
        .pipe(mergeMap(asset => {
          this._pdfAssetId = asset.id;

          return this.getAnnotations();
        }), untilDestroyed(this)).subscribe({
        complete: () => {
          this.annotationsLoaded = true;
        }
      });
    });

    // Annotation Manager Events
    this.annotationManager.addEventListener(this.webViewerInstance.Core.AnnotationManager.Events.ANNOTATION_CHANGED,
      (annotations: Core.Annotations.Annotation[], action: AnnotationAction) => {
        if (!this.annotationsLoaded) {
          return;
        }

        this.annotationChangedEvent(annotations, action);
      });

    // UI Events
    this.webViewerInstance.UI.addEventListener( this.webViewerInstance.UI.Events.ANNOTATION_FILTER_CHANGED,
      (e: CustomEvent) => {
        this.annotationFilterChangeEvent(e);
      });
  }

  annotationFilterChangeEvent(e: CustomEvent){
    const filters: any = e.detail;

    const keys = Object.keys(filters);

    let count = 0;
    keys.forEach(key => {
      const filter = filters[key];

      if (typeof filter === 'boolean') {
        count += filter ? 1 : 0;
      }

      if (Array.isArray(filter)) {
        count += filter.length;
      }
    });

    this.accessibilityState = AccessibilityState.none;
    if (this.filtersModal.privateCtrl.value) {
      count++;
      if (this.filtersModal.publicCtrl.value) {
        count++;
        this.accessibilityState = AccessibilityState.both;
      } else {
        this.accessibilityState = AccessibilityState.private;
      }
    } else if (this.filtersModal.publicCtrl.value) {
      count++;
      this.accessibilityState = AccessibilityState.public;
    }

    this.annotationsFiltersCount = count;
    this.filterNotesByAccessibility();
  }

  public initializePdfViewer(): void {
    this.initLeftPanel();
    this.setPanToolMode();
  }

  public initLeftPanel(): void {
    if(window.innerWidth < 900) {
      this.isLeftPanelOpen = false;
      this.webViewerInstance.UI.closeElements(['leftPanel'])
    } else {
      this.isLeftPanelOpen = true;
      this.webViewerInstance.UI.openElements(['leftPanel'])
    }
  }

  public toggleLeftPanel(): void {
    if(this.webViewerInstance.UI.isElementOpen('leftPanel')) {
      this.webViewerInstance.UI.closeElements(['leftPanel'])
    } else {
      this.webViewerInstance.UI.openElements(['leftPanel'])
    }
  }

  public goToPreviousPage(): void {
    const currentPage = this.webViewerInstance.Core.documentViewer.getCurrentPage();
    if (currentPage > 1) {
      this.setPage(currentPage - 1);
    }
  }

  public goToNextPage(): void {
    const currentPage = this.webViewerInstance.Core.documentViewer.getCurrentPage();
    if (currentPage < this.totalPages) {
      this.setPage(currentPage + 1);
    }
  }

  public setPage(number: number) {
    this.webViewerInstance.Core.documentViewer.setCurrentPage(number, true);
  }

  public toggleAnnotations(event: PropagationEvent): void {
    if(this.annotationManager.getAnnotationsList().length > 0) {
      if(this.showAnnotations) {
        this.annotationManager.hideAnnotations(this.annotationManager.getAnnotationsList());
      } else {
        this.annotationManager.showAnnotations(this.annotationManager.getAnnotationsList());
      }
    }
    this.showAnnotations = !this.showAnnotations;
  }

  public setPanToolMode(): void {
    this.webViewerInstance.UI.setToolMode('Pan');
  }

  public setSelectToolMode(): void {
    this.webViewerInstance.UI.setToolMode('TextSelect');
  }

  public zoomOut(): void {
    const currentZoom = this.webViewerInstance.Core.documentViewer.getZoom();
    this.webViewerInstance.Core.documentViewer.zoomTo(currentZoom - PdfViewerComponent.ZOOM_STEP);
  }

  public zoomIn(): void {
    const currentZoom = this.webViewerInstance.Core.documentViewer.getZoom();
    this.webViewerInstance.Core.documentViewer.zoomTo(currentZoom + PdfViewerComponent.ZOOM_STEP);
  }

  public fitPage(): void {
    this.webViewerInstance.UI.setFitMode(this.webViewerInstance.UI.FitMode.FitPage);
  }

  public onClickToggleFullscreen(): void {
    this.webViewerInstance.UI.toggleFullScreen();
  }

  public toggleSearch(): void {
    this.webViewerInstance.UI.toggleElementVisibility('searchPanel');
  }

  public toggleView() {
    this.webViewerInstance.UI.setToolbarGroup('toolbarGroup-View');
  }

  public toggleAnnotate() {
    this.webViewerInstance.UI.setToolbarGroup('toolbarGroup-Annotate');
  }

  public toggleAnnotationSidebar() {
    this.webViewerInstance.UI.toggleElementVisibility('notesPanel');
  }

  /**
   * createToolbarOptions sets up the GenericToolbarComponent.
   */
  public createToolbarOptions(): OptionGroup[] {
    const pageOption: any[] = [];
    pageOption.push(
      new CustomOption(
        'prev-page',
        [{ name: 'click', key: 'prev-page' }],
        new StatelessBehavior(Icon.REPORT_VIEWER_LEFT_ARROW, 'Previous')
      )
    );
    pageOption.push(
      new CustomOption(
        'next-page',
        [{ name: 'click', key: 'next-page' }],
        new StatelessBehavior(Icon.REPORT_VIEWER_RIGHT_ARROW, 'Next')
      )
    );
    const annotationOptions: any[] = [];
    const annotationSidebarOptions: any[] = [];
    if(this.toolbarType.showAnnotationOptions) {
      annotationOptions.push(
        new CustomOption('view', [{ name: 'click', key: 'view' }], new StatefullBehavior('', 'View', '', '', true))
      );
      annotationOptions.push(
        new CustomOption('annotate', [{ name: 'click', key: 'annotate' }], new StatefullBehavior('', 'Annotate'))
      );
      annotationSidebarOptions.push(
        new CustomOption(
          'annotation-sidebar',
          [{ name: 'click', key: 'annotation-sidebar' }],
          this.annotationSidebarOptionsBehavior
        )
      );
      annotationSidebarOptions.push(
        new CustomOption(
          'hide-annotation', [{ name: 'click', key: 'hide-annotation' }],
          new ToggleBehavior(
            Icon.REPORT_VIEWER_PEN_FILLED, `Click to show or hide comments and\nannotations added to this document`,
            Icon.REPORT_VIEWER_PEN, `Click to show or hide comments and\nannotations added to this document`
          )
        )
      );
    }
    const selectToolOption: any[] = [];
    selectToolOption.push(
      new CustomOption(
        'pan-tool-mode',
        [{ name: 'click', key: 'pan-tool-mode' }],
        new StatefullBehavior(Icon.REPORT_VIEWER_PAN_TOOL, 'Pan')
      )
    );
    selectToolOption.push(
      new CustomOption(
        'select-tool-mode',
        [{ name: 'click', key: 'select-tool-mode' }],
        new StatefullBehavior(Icon.REPORT_VIEWER_SELECT_TOOL, 'Select')
      )
    );
    const zoomOptions: any[] = [];
    zoomOptions.push(
      new CustomOption(
        'zoom-out',
        [{ name: 'click', key: 'zoom-out' }],
        new StatelessBehavior(Icon.REPORT_VIEWER_ZOOM_OUT, 'Zoom Out')
      )
    );
    zoomOptions.push(
      new CustomOption(
        'zoom-in',
        [{ name: 'click', key: 'zoom-in' }],
        new StatelessBehavior(Icon.REPORT_VIEWER_ZOOM_IN, 'Zoom In')
      )
    );
    // Note the FIT_PAGE icon is being used for 'Fit to Page' option. SWDEV-3623
    zoomOptions.push(
      new CustomOption(
        'fit-to-page',
        [{ name: 'click', key: 'fit-to-page' }],
        new StatelessBehavior(Icon.REPORT_VIEWER_FIT_PAGE, 'Fit to Page')
      )
    );
    const otherOptions: any[] = [];
    zoomOptions.push(
      new CustomOption(
        'toggle-fullscreen',
        [{ name: 'click', key: 'toggle-fullscreen' }],
        new StatelessBehavior(Icon.REPORT_VIEWER_ENTER_FULLSCREEN, 'Full Screen')
      )
    );
    otherOptions.push(
      new CustomOption(
        'toggle-search-box',
        [{ name: 'click', key: 'toggle-search-box' }],
        new ToggleBehavior(Icon.REPORT_VIEWER_SEARCH, 'Search', Icon.REPORT_VIEWER_SEARCH, 'Search')
      )
    );
    if (this.pdfAsset?.clObjectId && this.showSharedButton) {
      otherOptions.push(
        new CustomOption('share', [{ name: 'click', key: 'share' }], new StatelessBehavior(Icon.IMAGE_SHARE, 'Share'))
      );
    }
    if (this.showDownloadButton && this.pdfAsset.downloadUrl) {
      otherOptions.push(
        new CustomOption(
          'download',
          [{ name: 'click', key: 'download' }],
          new StatelessBehavior(Icon.REPORT_VIEWER_DOWNLOAD, '')
        )
      );
    }
    const optionGroups: any[] = [];
    optionGroups.push(new OptionGroup(pageOption));
    if(this.toolbarType.showAnnotationOptions) {
      const annotationSidebarOptionGroup = new OptionGroup(annotationSidebarOptions);

      // Prevent the default onChange method to be trigger so the css pressed state is changed by the sidebar instead
      annotationSidebarOptionGroup.onClickOption = () => {};

      optionGroups.push(new OptionGroup(annotationOptions));
      optionGroups.push(annotationSidebarOptionGroup);
    }
    optionGroups.push(new OptionGroup(selectToolOption));
    optionGroups.push(new OptionGroup(zoomOptions));
    optionGroups.push(new OptionGroup(otherOptions));
    if (this.showCloseButton) {
      const closeOption: any[] = [];
      closeOption.push(
        new CustomOption(
          'close-viewer',
          [{ name: 'click', key: 'close-viewer' }],
          new StatelessBehavior(Icon.REPORT_VIEWER_CLOSE, 'Close'),
          'Close',
        )
      );
      optionGroups.push(new OptionGroup(closeOption));
    }
    return optionGroups;
  }

  /**
   * setupToolbar configures the PDFTron toolbar.
   */
  public setupToolbar() {
    this.webViewerInstance.UI.setHeaderItems((header: any) => {
      header.update([
        {
          type: 'spacer',
        },
        {
          type: 'actionButton',
          img: 'icon-header-full-screen-exit',
          onClick: this.onClickToggleFullscreen.bind(this),
        },
      ]);
    });
  }

  /**
   * setupFullscreenFeature configures the PDFTron toolbar to be visible when going fullscreen.
   */
  public setupFullscreenFeature() {
    this.webViewerInstance.UI.disableElements(['header']);
    this.webViewerInstance.iframeWindow.onresize = () => {
      if (
        this.webViewerInstance.iframeWindow.innerWidth === window.innerWidth &&
        this.webViewerInstance.iframeWindow.innerHeight === window.innerHeight
      ) {
        this.webViewerInstance.UI.enableElements(['header']);
      } else {
        this.webViewerInstance.UI.disableElements(['header']);
      }
    };
  }

  public executeToolbarOption(event: PropagationEvent): void {
    if (event.key in this.eventToMethod) {
      const methodName = this.eventToMethod[event.key];
      this[methodName](event);
    }
  }

  // Annotations
  getAnnotations(): Observable<any[]> {
    return this.annotationService.getAnnotations(this._pdfAssetId).pipe(mergeMap(annotations => {
      const requests: Promise<any>[] = annotations.map(
        annotation => this.annotationManager.importAnnotations(annotation.content)
      );

      return forkJoin(requests);
    }));
  }

  generateAnnotateHeaderItems() {
    this.webViewerInstance.UI.setHeaderItems((header: any) => this.rearrangeHeaderItems(header));
  }

  rearrangeHeaderItems(header: any) {
    const items = header.getHeader('toolbarGroup-Annotate').getItems();
    const shapes = header.getHeader('toolbarGroup-Shapes').getItems();

    const divider = { type: 'divider' };
    const spacer = { type: 'spacer' };
    const eraser = { type: 'toolButton', toolName: 'AnnotationEraserTool' };
    const undoRedoIcons = items.filter((x: any) => ['undoButton', 'redoButton'].includes(x.dataElement));
    const highlightTextIcon = items.find((x: any) => x.toolGroup === 'highlightTools');
    const commentsIcons = items.find((x: any) => x.toolGroup === 'stickyTools');
    const freeText = items.find((x: any) => x.toolGroup === 'freeTextTools');
    const styleIcon = items.find((x: any) => x.dataElement === 'toolsOverlay');
    const arrowIcon = shapes.find((x: any) => x.toolGroup === 'arrowTools');
    const lineIcon = shapes.find((x: any) => x.toolGroup === 'lineTools');
    const shapeIcons = shapes.filter((x: any) => ['rectangleTools', 'ellipseTools'].includes(x.toolGroup));

    const leftTools = undoRedoIcons.concat(divider, eraser);
    const middleTools = [ highlightTextIcon ].concat(commentsIcons, freeText, arrowIcon, lineIcon, shapeIcons);

    header
      .getHeader('toolbarGroup-Annotate')
      .update([].concat(leftTools, spacer, middleTools, spacer, styleIcon));
  }

  setNoteTransformFunctionEvent(noteWrapper: HTMLElement, state: {
    annotation: Core.Annotations.Annotation;
    isSelected: boolean;
  }, createElement: any) {
    const divStateOverflow = createElement('div');
    divStateOverflow.setAttribute('class', 'state-and-overflow');

    const toolTip = createElement('div');
    toolTip.setAttribute('class', 'accessibility-tooltip');
    toolTip.innerText = state.annotation.getCustomData('accessibility') === AccessibilityState.public ? 'Visible' : 'Private';

    const toolTipArrow = createElement('div');
    toolTipArrow.setAttribute('class', 'accessibility-tooltip-arrow');
    toolTip.style.display = 'none';
    toolTip.appendChild(toolTipArrow);

    const button = createElement('button');
    const img = createElement('img');
    img.setAttribute('class', 'accessibility-eye');
    img.src = state.annotation.getCustomData('accessibility') === AccessibilityState.public
      ? '/assets/images/icons/show-eye.svg'
      : '/assets/images/icons/hide-eye.svg';

    button.appendChild(img);
    button.appendChild(toolTip);
    button.setAttribute('class', 'accessibility-toggle');

    // Accessibility button Events
    button.addEventListener('mouseenter', () => toolTip.style.display = 'flex');
    button.addEventListener('mouseleave', () => toolTip.style.display = 'none');
    button.addEventListener('click', (e: MouseEvent) => {
      e.stopPropagation();

      this.toggleAnnotationAccessibilityStateButton(state.annotation, img, toolTip);
    });

    divStateOverflow.appendChild(button);
    if (state.annotation.Author === this.annotationManager.getCurrentUser()) {
      const wrapper = noteWrapper.querySelector('.author-and-overflow');
      const child = noteWrapper.querySelector('.author-and-time');
      wrapper.insertBefore(divStateOverflow, child.nextSibling);
    }
  }

  toggleAnnotationAccessibilityStateButton(
    annotation: Core.Annotations.Annotation, icon: HTMLImageElement, toolTip: HTMLDivElement
  ) {
    if (annotation.getCustomData('accessibility') === AccessibilityState.public) {
      annotation.setCustomData('accessibility', AccessibilityState.private);
      icon.src = '/assets/images/icons/hide-eye.svg';
      toolTip.innerText = 'Private';
    } else {
      annotation.setCustomData('accessibility', AccessibilityState.public);
      icon.src = '/assets/images/icons/show-eye.svg';
      toolTip.innerText = 'Visible';
    }

    this.annotationChangedEvent([annotation], AnnotationAction.modify);
    this.filterNotesByAccessibility();
  }

  annotationChangedEvent(annotations: Array<any>, action: any) {
    const filteredAnnotations = annotations.filter(a => a.Subject !== null && a.Listable)

    switch (action) {
      case AnnotationAction.add:
        this.exportAnnotations(filteredAnnotations).pipe(
          mergeMap(annotationRequest => this.annotationService.postAddAnnotation(annotationRequest)),
          untilDestroyed(this),
        ).subscribe();
        break;
      case AnnotationAction.modify:
        this.exportAnnotations(filteredAnnotations).pipe(
          mergeMap(annotationRequest => this.annotationService.postEditAnnotation(annotationRequest)),
          untilDestroyed(this),
        ).subscribe();
        break;
      case AnnotationAction.delete:
        this.annotationService.deleteAnnotations(filteredAnnotations).pipe(untilDestroyed(this)).subscribe();
        break;
    }
  }

  /**
   * gets and returns the iframe html element containing the pdf
   */
  getPDFIframeElement(): Document {
    const iframe = this.docViewer.nativeElement.getElementsByTagName('iframe')[0];
    return iframe ? iframe.contentWindow.document : null;
  }

  /** Gets Html Elements from the pdfTron iframe by its data-element property */
  getIframeDataElement(dataElementProperty: string): HTMLElement {
    let element = null;
    const iframeDocument = this.getPDFIframeElement();
    if (iframeDocument) {
      element = iframeDocument.querySelectorAll('[data-element="' + dataElementProperty + '"]')[0] as HTMLElement;
    }
    return element;
  }

  changeFiltersModal() {
    const filterModal = this.getIframeDataElement('filterModal');
    const modal: HTMLDivElement = filterModal.querySelector('div.filter-modal');
    const legacyFilters: HTMLDivElement = filterModal.querySelector('div.filter-options');
    const clearButton: HTMLButtonElement = filterModal.querySelector('button.Button.filter-annot-clear');
    const applyButton: HTMLButtonElement = filterModal.querySelector('button.Button.filter-annot-apply');

    this.filtersModal.init(modal, legacyFilters, clearButton, applyButton);
  }

  onNotesPanelFilter(): void {
    this.resetAccessibilityStateFilter();

    this.webViewerInstance.UI.openElement('filterModal');

    this.changeFiltersModal();
  }

  onFiltersModalClose(): void {
    this.webViewerInstance.UI.closeElements(['filterModal']);
  }

  resetAccessibilityStateFilter(): void {
    this.filtersModal.onResetAccessibility(this.accessibilityState);
  }

  changeAnnotationsSidebarHeader() {
    this.webViewerInstance.UI
      .addEventListener(this.webViewerInstance.UI.Events.VISIBILITY_CHANGED, (e: CustomEvent) => {
        if(e.detail.element === 'leftPanel') {
          this.isLeftPanelOpen = e.detail.isVisible;
        }

        if(e.detail.element === 'notesPanel'){
          this.annotationSidebarOptionsBehavior.setPressed(e.detail.isVisible);

          // Trigger toolbar change detection
          this.toolbarOptions = [...this.toolbarOptions];

          if (e.detail.isVisible) {
            const target = e.target as any;

            // Getting previous components
            const notesPanel = target.document.querySelector('[data-element="notesPanel"]');

            const notesPanelHeader: HTMLDivElement = notesPanel.querySelector('div.header');

            const searchContainer: HTMLDivElement = notesPanel.querySelector('div.input-container');
            const commentsCounter: HTMLDivElement = notesPanel.querySelector('div.comments-counter');
            const sortContainer: HTMLDivElement = notesPanel.querySelector('div.sort-container');

            // Moving previous components to custom header
            this.notesPanelHeader.init(notesPanelHeader, searchContainer, commentsCounter, sortContainer);
          }
        }
      });
  }

  exportAnnotations(annotations: Core.Annotations.Annotation[]): Observable<AnnotationRequest> {
    return from(annotations).pipe(mergeMap((annotation) => {
      let collectionType: AccessibilityState = AccessibilityState.public;
      if(annotation.getCustomData('accessibility')) {
        collectionType = annotation.getCustomData('accessibility') as AccessibilityState;
      } else {
        collectionType = annotation.isReply() ? AccessibilityState.public : AccessibilityState.private;

        annotation.setCustomData('accessibility', collectionType);
      }

      return from(this.annotationManager.exportAnnotations({
        annotList: [ annotation ],
      })).pipe(map((content) => ({
        id: annotation.Id,
        documentId: this._pdfAssetId,
        content,
        type: AnnotationType.pdf,
        pageNo: annotation.getPageNumber(),
        collectionType,
      })));
    }));
  }

  private setupCrossLinking(instance: WebViewerInstance) {
    (instance as any).Actions.URI.prototype.onTriggered = (target:any) => {
      if (target instanceof (instance as any).Annotations.Link) {
        if (target.getActions) {
          this.crossLinkingService.handleOnLinkClicked(this.route.snapshot, target.getActions().U[0].uri)
        }
      }
    };
  }
}
