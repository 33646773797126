<div [class.disabled]="disabled" class="meatball-menu">
  <button
    class="meatball-menu-icon"
    [style.font-size]="iconFontSize"
    (click)="onMeatballIconClick($event)"
    (mouseenter)="onHover()"
  >
    <em class="icon-more_small"></em>
  </button>
  <div class="meatball-menu-options" #options>
    <ng-content></ng-content>
  </div>
</div>
