import {DynamicContentTable, DynamicDataTreeNode, DynamicHierarchyFrequencies} from './market-analysis';

/**
 * Helper to navigate DynamicDataTreeNode
 */
export class DynamicDataTreeHelper {

  /**
   * From a DynamicDataTreeNode recursive gets the vic codes
   *
   * @param tree DynamicDataTreeNode
   * @returns list of vic codes
   */
  public static getAllVicCodes(tree: DynamicDataTreeNode): Array<string> {
    return [tree.vic, ...this.getChildrenVicCodes(tree.children)];
  }

  /**
   * Creates DynamicDataTreeNode with all nodes available unless availableNodes passed
   * @param tree DynamicDataTreeNode like
   * @param availableNodes optional list of nodes that should not be disabled
   */
  public static buildDynamicDataTreeNodeChildren(tree: DynamicDataTreeNode,
      availableNodes?: Array<string>): DynamicDataTreeNode {
    const nodesProvided = !!availableNodes
    return {
      name: `${tree.vic}. ${tree.name}`,
      vic: tree.vic,
      disabled: nodesProvided && !availableNodes.includes(tree.vic),
      children: this.getNodes(tree.children, availableNodes)
    }
  }

  /**
   * Builds DynamicContentTable
   * @param result microservice response as is
   */
  public static buildDynamicContentTable(result: any, period: DynamicHierarchyFrequencies): DynamicContentTable {
    const fixedCols = ['AOW', 'MSTRID', 'COMPANY'];
    let yearCols = Array();
    if (result.table_content && result.table_content.length > 0) {
      yearCols = Object.keys(result.table_content[0].years ?? result.table_content[0].quarters) ;
    }

    const rows = result.table_content.map( (row: any) => Object.assign(
        row.years ?? row.quarters,
        {'AOW': row.aow, 'MSTRID': row.mstrid, 'COMPANY': row.company_name}
      )
    )
    const versionName = result.version_name ? result.version_name : '';
    return {
      name:  `${result.vic}. ${result.name}`,
      vic: result.vic,
      copyright: result.copyright,
      columns: fixedCols.concat(yearCols).map(col => ( { field: col } ) ),
      rows,
      totalRow: result.total,
      annualGrowth: result?.annual_growth,
      versionName,
      frequencies: period,
    }
  }

  public static getFilename(product: DynamicContentTable): string {
    const vicName = product.name.substring(product.name.indexOf(' ') + 1);
    const version = this.getVersion((product.versionName));
    const period = product.frequencies.charAt(0) + product.frequencies.substring(1).toLowerCase();
    return `TI_${vicName}_${period + version}`;
  }

  private static getVersion(version: string): string {
    const versionIndex =  version.length - 6;
    if(versionIndex < 0) {
      return '';
    }
    const regexVersion = /[Vv]\d{2}[.]\d{2}/;
    return regexVersion.test(version.substring(versionIndex)) ? ('_v.' + version.substring(versionIndex+1)) : '';
  }

  private static getNodes(children: Array<any>, availableNodes: Array<string>): Array<DynamicDataTreeNode> {
    if(children.length > 0) {
      return children.map( child => this.buildDynamicDataTreeNodeChildren(child, availableNodes))
    } else {
      return []
    }
  }

  private static getChildrenVicCodes(children: Array<any>): Array<string> {

    if(children.length > 1) {
      return children.reduce( (accumulator, next) => {
        return [...accumulator,
                next.vic,
                ...this.getChildrenVicCodes(next.children)]
      }, [])
    }

    if(children.length > 0) {
      return [children[0].vic, ...this.getChildrenVicCodes(children[0].children)]
    } else {
      return []
    }
  }
}