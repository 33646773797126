<app-generic-toolbar
  id="toolbar"
  class="toolbar"
  [toolbarType]="toolbarType"
  [toolbarTitle]="toolbarTitle"
  [toolbarOptions]="toolbarOptions"
  [currPage]="imageNumber"
  [totalPages]="numberOfImages"
  [isFullScreen]="isFullScreen"
  (propagateEvent)="onToolbarEvent($event)"
></app-generic-toolbar>

<div class="viewer-container">
  <app-metadata-panel [metadata]="metadata"></app-metadata-panel>
  <div class="feature-viewer">
    <div class="openseadragon-container">
      <div id="openseadragon" class="openseadragon-viewer"></div>
      <div
        *ngIf="numberOfImages > 1 && imageNumber > 1"
        class="viewer-nav-arrow left-arrow"
        (click)="onClickPreviousImage()"
      >
        <span class="icon-left_large"></span>
      </div>
      <div
        *ngIf="numberOfImages > 1 && imageNumber < numberOfImages"
        class="viewer-nav-arrow right-arrow"
        (click)="onClickNextImage()"
      >
        <span class="icon-right_large"></span>
      </div>
    </div>
    <app-thumbnail-bar
      *ngIf="numberOfImages > 1 && thumbnailImages?.length > 0 && selectedImage"
      class="thumbnail-bar"
      [thumbnails]="thumbnailImages"
      [selectedThumbnail]="selectedImage"
      (clickThumbnail)="onClickThumbnail($event)"
    ></app-thumbnail-bar>
  </div>
</div>
