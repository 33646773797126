export enum CalibrationUnits {
  CENTIMETERS = 'cm',
  MILLIMETERS = 'mm',
  MICROMETERS = 'µm',
  NANOMETERS = 'nm',
}

/**
 * Calibration model
 * @property {number} scale           - Number of pixels measured by the user
 * @property {number} scaleRef        - Scale reference value entered by the user
 * @property {CalibrationUnits} units - Enum value the user selected for units
 */
export interface Calibration {
  id: string
  scale: number
  scaleRef: number
  units: CalibrationUnits
}
