import { Injectable, Injector } from '@angular/core';
import { Router, NavigationEnd, NavigationSkipped } from '@angular/router';

/**
 * Service to keep track of the previous page.
 * Use this service on your component to ask what was the previous page that led to your component
 */
@Injectable()
export class PreviousPageService {
  private fromNavigationBack: boolean;
  private currentUrl: string;
  private urls: Array<string> = [];
  private router: Router;

  constructor(private inj: Injector) {
    // why having the injector and not the router? because this component is loaded in APP_INITIALIZER
    // and it lead to a cyclic dependency issue for @angular dependencies
  }

  /**
   * Get the previous visited url. Can be undefined
   */
  public getPreviousUrl() {
    return this.urls[this.urls.length - 1];
  }

  /**
   * Navigates to the last url, safely. This means it has no effect if there is no history back
   */
  public navigateBack(): void {
    if (this.hasHistory()) {
      this.fromNavigationBack = true;
      this.router.navigateByUrl(this.urls.pop());
      sessionStorage.setItem('previousUrls', JSON.stringify(this.urls));
    }
  }

  public navigateBackIgnoringPath(path: string): void {
    this.urls = this.urls.filter((url) => !url.includes(path));
    this.navigateBack();
  }

  /**
   * Tells if at least has one level of back history
   */
  public hasHistory(): boolean {
    return this.urls[this.urls.length - 1] !== undefined;
  }

  /**
   * Initialization method, it basic register to the navigation end event
   */
  public init() {
    this.router = this.inj.get(Router);

    if (sessionStorage.hasOwnProperty('previousUrls')) {
      this.urls = JSON.parse(sessionStorage.getItem('previousUrls'));
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationSkipped) {
        if (
          this.currentUrl &&
          !this.currentUrl.includes('token') &&
          !this.fromNavigationBack &&
          this.checkUrlDiffersFromPreviousOne(this.currentUrl, event.url)
        ) {
          this.urls.push(this.currentUrl);
          sessionStorage.setItem('previousUrls', JSON.stringify(this.urls));
        }
        this.currentUrl = event.url;
        this.fromNavigationBack = false;
      }
    });
  }

  /**
   * Adding URL in Session storage
   * Use this function when you open a new window and want to add the previous url
   */
  public addNavigation(url: string) {
    const urls = sessionStorage.hasOwnProperty('previousUrls')
      ? JSON.parse(sessionStorage.getItem('previousUrls'))
      : [];
    urls.push(url);
    sessionStorage.setItem('previousUrls', JSON.stringify(urls));
  }

  private checkUrlDiffersFromPreviousOne(currentUrl: string, nextUrl: string): boolean {
    const nextUrlWithoutParams = nextUrl.split(/[#?]/g)[0];
    const currentUrlWithoutParams = currentUrl.split(/[#?]/g)[0];
    return nextUrlWithoutParams !== currentUrlWithoutParams;
  }
}
