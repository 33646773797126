import { OnboardingModalComponent } from '@app/shell/components/onboarding-modal/onboarding-modal.component';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupComponent } from 'src/app/shell/components/popup/popup.component';
import {
  loadNotificationsFailure,
  loadNotificationsSuccess,
  NotificationsActionTypes,
} from 'src/app/store/Actions/notification/notification.actions';
import {
  INotificationResponse,
  NotificationsService
} from 'src/app/@shared/services/notifications/notifications.service';
import { NotificationTypeEnum } from 'src/app/store/Models/notification/notificationModel';
import { TelemetryService } from '@shared/services/telemetry-service';
import { TelemetryEventName } from '@shared/enums';

/**
 * Side effects for notification actions
 */
@Injectable()
export class NotificationEffects {

  /**
   * When the load notifications action is dispatched,
   * fetch the notification history via the service,
   * then dispatch a success action with the notifications,
   * or error action with the error message
   */
  GetNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.loadNotifications),
      // Using exhaustMap since we only want ONE sub to getNotifications to pass
      // and ignore the rest even if the action is triggered
      exhaustMap(
        (action: any) =>
          this._notificationsService.getNotificationHistory(action.page, action.size).pipe(
            switchMap((notifications: INotificationResponse) => [
              loadNotificationsSuccess(
                {
                  data: notifications.content
                    .map(notification => {
                           return {
                             title: notification.message,
                             body: notification.message,
                             id: notification.id,
                             type: NotificationTypeEnum.SEARCH,
                             date: new Date(),
                             read: false,
                             acknowledged: false
                           };
                         }
                    ),
                  totalNotifications: notifications.totalElements
                }
              )
            ]),
            catchError(async (err) => {
              // Show an error toast when there is a problem with the API
              // TODO: Handle other types of error regarding types and such
              // this.toastrService.error('Error', err);
              return loadNotificationsFailure({ error: err });
          })
        )
      )
    )
  );

  /**
   * When the action to show a push notification is dispatched,
   * trigger a toast showing a preview of the notification.
   * If it's more than one, trigger several toasts with 1 second of delay each
   */
  PushNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.pushNotifications),
      tap((action: any) => {
        let i = 1;
        for(const notification of action.notifications){
          setTimeout(() => this.toastrService.show(JSON.stringify(notification)),  1000 * i++)
        }
        return null;
      })
    ), { dispatch: false }
  )

  /**
   * When the show popup action is dispatched,
   * a modal with the configuration defined in the
   * action payload should be shown
   */
  ShowPopup$ = createEffect(() =>
  this.actions$.pipe(
    ofType(NotificationsActionTypes.showPopup),
    tap((action: any) => {
      const modalRef = this._modalService.open(PopupComponent);
      modalRef.componentInstance.props = {
        header: action.popup.header,
        body: action.popup.body,
        type: action.popup.type,
        primaryButton: action.popup.primaryButton,
        primaryLabel: action.popup.primaryLabel,
        primaryButtonBehaviour: action.popup.primaryButtonBehaviour,
        secondaryButton: action.popup.secondaryButton,
        secondaryLabel: action.popup.secondaryLabel,
        showContactEmail: action.popup.showContactEmail,
      }
    })
  ), { dispatch: false }
)
  /**
   * Same as previous side effect, but uses the OnboardingModalComponent
   * instead of the generic popup component
   */
  ShowOnboardingPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.showOnboardingPopup),
      tap((action: any) => {
        const modalRef = this._modalService.open(OnboardingModalComponent,
          {
            size: 'lg',
            keyboard: false,
            centered: true,
            windowClass: 'uui-modal',
          });
        modalRef.componentInstance.props = {
          body: action.popup.body,
        }
        this.telemetryService.sendHeapEvent(TelemetryEventName.EVENT_HEAP_ONBOARDING_POPUP).subscribe();
      })
    ), { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private _modalService: NgbModal,
    private _notificationsService: NotificationsService,
    private toastrService: ToastrService,
    private telemetryService: TelemetryService,
  ) {}

}
