<section class="view-options-bar">
  <app-pagination-top
    *ngIf="!isLoading && imageCount > 0"
    [count]="imageCount"
    [currentPage]="currentPage"
    [pageSize]="pageSize"
    [pageSizes]="pageSizes"
    [showSort]="false"
    (changePaginationOptions)="onChangePaginationOptions($event)"
  ></app-pagination-top>
</section>
<section class="content scrollbar">
  <div class="image-list-container horizontal-scrollbar">
    <div class="scrollbar-container">
      <div class="list-header">
        <div class="select-all">
          <input
            [disabled]="!downloadEnabled"
            type="checkbox"
            class="checkbox custom-checkbox"
            [checked]="selectedImages.allSelected"
            (click)="onClickSelectAll()"
          />
        </div>
        <span>Name</span>
        <span></span>
        <span>Content Type</span>
        <span></span>
<!--        right -->
        <app-download-panel
          *ngIf="downloadEnabled && selectedImages.selectedImagesQty > 0"
          class="download-panel"
          [downloadEnabled]="downloadEnabled"
          [selectedImages]="selectedImages"
          (allImagesSelected)="onClickSelectAll($event)"
          (downloadImages)="onClickDownloadImages()"
        ></app-download-panel>
      </div>
      <div *ngIf="!isLoading && images?.length > 0; else noResults" class="element-list scrollbar">
        <div
          *ngFor="let image of images"
          class="element"
          [class.element-disabled]="!image.entitled"
          [class.active]="image.selected"
        >
          <div class="element-column">
<!--            until we have other options, we only actuall want to enable this when download is also enabled-->
            <input
              [disabled]="!downloadEnabled"
              type="checkbox"
              class="checkbox custom-checkbox"
              (click)="onClickSelectImage(image)"
              [checked]="image.selected"
            />
          </div>
          <div class="element-column" (click)="onClickImage(image)">
            <app-image [src]="image.thumbnailUrl" [alt]="image.name"></app-image>
          </div>
          <div class="element-column title" (click)="onClickImage(image)">
            {{ mapImageName(image.name) }}
          </div>
          <div class="element-column" (click)="onClickImage(image)">
            Image
          </div>
          <div class="element-column"></div>
          <div class="element-column dropdown">
            <app-meatball-menu>
              <button
                (click)="onClickShare($event, image)"
              >
                <em class="icon-share"></em> Share
              </button>
              <button
                *ngIf="downloadEnabled"
                (click)="onDownloadImage(image)"
              >
                <em class="icon-button_download"></em> Download
              </button>
              <button
                *ngIf="addToFavoriteEnabled"
                (click)="onClickAddToFavorites(image)"
              >
                <em class="icon-star"></em> Add to Favorites
              </button>
            </app-meatball-menu>
          </div>
        </div>
      </div>
      <ng-template #noResults>
        <div *ngIf="isLoading" class="loading">
          <app-progress-bar-circular></app-progress-bar-circular>
        </div>
        <div *ngIf="!isLoading" class="no-results">
          <img src="assets/images/icons/Icon_Null-Search.svg" alt="No results" />
          <div class="text">
            <span class="main-title">No results</span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <section class="bottom-pagination">
    <app-pagination-bottom
      *ngIf="!isLoading && imageCount > pageSize"
      [count]="imageCount"
      [startingPage]="currentPage"
      [startingPageSize]="pageSize"
      (changePaginationOptions)="onChangePaginationOptions($event)"
    ></app-pagination-bottom>
  </section>
</section>
