<app-title-bar
  *ngIf="showBackButton && prevPage.hasHistory()"
  [showBackButton]="showBackButton && prevPage.hasHistory()"
  (clickBack)="onClickBack()"
></app-title-bar>

<progress class="pure-material-progress-circular" *ngIf="isIframeAppLoading"></progress>

<iframe
  *ngIf="srcUrl"
  [src]="srcUrl"
  title="generic-iframe-landing-page"
  [hidden]="isIframeAppLoading"
></iframe>
