import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IChips } from '@app/tech-library/components/search-bar/search-input/search-input.component';
import {Subject} from 'rxjs';
import {IRecentSearch, ISearchTerms} from '@app/store/Models/search/searchModels';

/**
 * Component for the generic search bar
 */
@Component({
  selector: 'app-generic-search-bar',
  templateUrl: './generic-search-bar.component.html',
  styleUrls: ['./generic-search-bar.component.scss'],
})
export class GenericSearchBarComponent implements OnInit {
  @Input() hiddenSearchButton: boolean;
  @Input() hiddenSearchLabel: boolean;
  @Input() isDisabled = false;
  @Input() chipList: string;
  @Input() isSearchResult = false;
  @Input() searchPlaceholder = '';
  @Input() set setRecentSearches(searchTerms: string[]) {
    this.recentSearches = searchTerms.map((searchTerm: string) => ({
      label: searchTerm,
      searchTerms: {
        searchText: [searchTerm],
      },
    }));
  }
  @Output() saveSearchTerms: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchTermsChanged: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() focusSearchbar = new EventEmitter<void>();
  @Output() blurSearchbar = new EventEmitter<boolean>();
  @ViewChild('scrollContainer', { read: ElementRef }) scrollContainer: ElementRef;

  searchForm: UntypedFormGroup;
  panningInput = false;
  prevPos: number;
  searchChips: IChips[] = [];
  isActive = false;
  showRecentSearches = false;
  focusRecent: Subject<string> = new Subject<string>();
  recentSearches: IRecentSearch[];

  get searchTerms(): string[] {
    return [].concat(this.searchChips.map((chip) => chip.values));
  }

  get searchInputValue(): string {
    return this.searchForm.get('searchInput').value;
  }

  set searchInputValue(value: string) {
    this.searchForm.get('searchInput').setValue(value);
  }

  constructor() {}

  ngOnInit() {
    this.searchForm = new UntypedFormGroup({
      searchInput: new UntypedFormControl('', [Validators.required]),
    });
    this.searchInputValue = this.chipList ? this.chipList : '';
  }

  /**
   * Clears search terms input
   */
  clearAll() {
    this.searchInputValue = '';
    this.searchChips.splice(0);
    if (!this.isSearchResult) {
      this.searchTermsChanged.emit(this.searchTerms);
    }
    this.blurSearchbar.emit(true);
  }

  /**
   * Emit search terms ready event
   */
  setSearchTerms() {
    this.searchTermsChanged.emit([this.searchInputValue]);
  }

  selectSearchTerms(searchTerms: ISearchTerms) {
    if (searchTerms !== null) {
      this.searchInputValue = searchTerms.searchText.join(' ');
      this.searchTermsChanged.emit([this.searchInputValue]);
      this.saveSearchTerms.emit(this.searchInputValue);
    }
  }

  /**
   * Marks the last chip for deletion.
   * If this method runs again, removes the chip and notifies the container to trigger the search
   */
  selectOrRemoveLastChip() {
    if (this.searchInputValue === '' && this.searchChips.length > 0) {
      const [lastChip] = this.searchChips.slice(-1);
      if (lastChip.selected) {
        this.searchChips.pop();
      } else {
        lastChip.selected = true;
      }
    }
  }

  focusOnTypeahead($event: KeyboardEvent) {
    if ($event.key === 'Tab') {
      $event.preventDefault();
      $event.stopPropagation();
    }
    if (this.recentSearches) {
      this.focusRecent.next($event.key);
    }
  }

  /**
   * Check if the chips container is scrollable
   */
  isSearchInputScrollable(): boolean {
    return this.scrollContainer?.nativeElement &&
      this.scrollContainer.nativeElement.scrollWidth>this.scrollContainer.nativeElement.getBoundingClientRect().width;
  }

  /**
   * Unselects all chips
   */
  unselectAllChips() {
    this.searchChips.forEach((chip) => (chip.selected = false));
  }

  /**
   * Checks if the search bar is empty
   */
  isSearchBarEmpty() {
      return this.searchInputValue === '';
  }

  /**
   * Stops the panning for the chips container
   */
  stopPanning() {
    this.prevPos = null;
    this.panningInput = false;
  }

  closeTypeAhead() {
    this.showRecentSearches = false;
  }

  /**
   * Emits an event when the searchbar gains focus
   */
  onFocus() {
    this.focusSearchbar.emit();
    this.showRecentSearches = true;
  }

  /**
   * Emits an event when the search bar loses focus
   */
  onBlur() {
    this.blurSearchbar.emit(this.isSearchBarEmpty());
  }

  onClick(event: Event) {
    this.focusRecent.next(event.type);
  }
}
