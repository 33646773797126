import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { TableauConfig } from '@app/@shared/models/shared.models';

declare const tableau: any;

@Component({
  selector: 'app-tableau-iframe',
  template: `<div #vizContainer></div>`,
  styleUrls: [ './tableau-iframe.component.scss' ],
})
export class TableauIframeComponent implements OnDestroy {

  @ViewChild('vizContainer', { static: true }) container: ElementRef;

  @Input() set config({ url, width, height }: TableauConfig) {
    this.initializeViz(url, width, height);
  }

  private viz: any;

  ngOnDestroy(): void {
    this.viz?.dispose();
  }

  private initializeViz(url: string, width: number|string, height: number|string): void {
    this.viz?.dispose();
    this.viz = new tableau.Viz(this.container.nativeElement, url, {
      width,
      height,
      hideTabs: false,
      disableUrlActionsPopups: true,
      onFirstInteractive: () => {
        this.viz.addEventListener(tableau.TableauEventName.URL_ACTION, this.urlActionEventHandler);
      }
    });
  }

  private urlActionEventHandler(event: any): void {
    const tableauEventUrl = event.getUrl();
    const tableauEventUrlWebApi = new URL(tableauEventUrl);
    const url = tableauEventUrl.replace(tableauEventUrlWebApi.origin, window.location.origin);
    window.open(url);
  }

}
