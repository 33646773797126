import { Component, Input, NgZone, OnChanges, OnDestroy, SimpleChange, SimpleChanges } from '@angular/core';
import OpenSeaDragon from 'openseadragon';
import { CredentialsService } from 'src/app/auth';
import { MetaService } from '@app/@shared/services/meta.service';
import { TileSourceLayerTableGui, TileSourceInner, TileSource } from '@app/@shared/models/kimera/webCVJSON';
import { Measurement, MeasurementType, MeasuringScalingFactor } from '@app/@shared/models/kimera/measurement';
import { KimeraPerformanceTrackingService} from '@shared/services/kimera/kimera-performance-tracking.service'
import { ToolbarTypes } from '@app/@shared/components/generic-toolbar/toolbar-type';
import { CustomOption, Option, OptionGroup } from '@app/@shared/components/generic-toolbar/option-model';
import { StatelessBehavior, ToggleBehavior } from '@app/@shared/components/generic-toolbar/state-behavior-model';
import { Icon } from '@app/@shared/components/generic-toolbar/icons';
import { environment } from '@env/environment';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '@app/@shared/services/dialog/dialog.service';
import { AbstractImageViewerComponent } from '@app/@shared/components/abstract/abstract-image-viewer/abstract-image-viewer/abstract-image-viewer.component';
import { AnnotationService } from '@app/@shared/services/annotation/annotation.service';
import { KimeraGenericModelBoxComponent } from '../kimera-generic-model-box/kimera-generic-model-box.component';


declare function initializeOpenSeaDragonSvgRelay(openseadragon: any): any;
export const SVG_STROKE_WIDTH = 2.5;


@Component({
  selector: 'app-kimera-grid-cv-viewer',
  templateUrl: './kimera-grid-cv-viewer.component.html',
  styleUrls: ['./kimera-grid-cv-viewer.component.scss']
})
export class KimeraGridCvViewerComponent extends AbstractImageViewerComponent implements OnDestroy, OnChanges{

  @Input() webCVContentLakeId: string;
  @Input() measuringScalingFactor: MeasuringScalingFactor;
  @Input() tileSourceLayers: Array<TileSourceLayerTableGui>;
  @Input() gridId: string;
  @Input() analysisCode: string;
  @Input() cvToolbarHeight: number;
  tileSourcesLayerKeyIndexMap: { [key: string]: number[] } = {};


  readonly toolbarType = ToolbarTypes.KIMERA_GRID_CV_VIEWER;
  toolbarOptions: OptionGroup[] = [];
  fullscreenToggleOption: CustomOption;
  areaSelectionToggleOption : CustomOption;
  DEFAULT_MODAL_OPTIONS: NgbModalOptions = {
    windowClass: 'kimera-generic-dialog',
    size: 'lg',
    container: '.openseadragon-viewer',
  };

  viewer: OpenSeaDragon.Viewer;
  hasRenderedOnce = false;
  isFullScreen = false;
  svgShape: any;
  rectBound: OpenSeaDragon.Rect;


  useEntitledBasedUrl = true;
  baseUrl = environment.kimeraImageFetchBaseUrl+'/im_transformed?';
  baseUrlEntitled = environment.kimeraImageFetchBaseUrl+'/im_transformed_entitled?'

  readonly eventToMethod = {
    'zoom-out':                 'onClickZoomOut',
    'zoom-in':                  'onClickZoomIn',
    'toggle-fullscreen':        'onClickFullscreenToggle',
    'fit-to-page':              'onClickFitPage',
    'toggle-area-selection':    'onToggleAreaSelection',
    'toggle-length-selection':  'onToggleLengthSelection'
  };

  constructor(
    _ngZone: NgZone,
    private credentialsService: CredentialsService,
    metaService: MetaService,
    private kimeraPerformanceTrackingService: KimeraPerformanceTrackingService,
    dialogService: DialogService,
    annotationService: AnnotationService,
    ) { super(_ngZone, metaService, dialogService, annotationService) }
  ngOnChanges(changes: SimpleChanges): void {
    const allInputsAreInitialized = this.measuringScalingFactor && this.tileSourceLayers
                                    && this.webCVContentLakeId && this.gridId
                                    && this.analysisCode;
    if(allInputsAreInitialized && !this.hasRenderedOnce){
      this.measuringToolsDisabled =  this.measuringScalingFactor.measuringToolsDisabled;
      this.initializeViewer();
    }
    if (this.hasRenderedOnce && changes.tileSourceLayers && this.selectionUnchanged(changes.tileSourceLayers)){
      const changedTileSourceLayer = this.findLayerThatChanged(changes.tileSourceLayers.previousValue,
                                      changes.tileSourceLayers.currentValue);
      if(changedTileSourceLayer){
        this.rebuildTileSources(changedTileSourceLayer.previousValue, changedTileSourceLayer.currentValue);
      }
    }
  }

  selectionUnchanged(tileSourceLayersChange: SimpleChange): boolean{
    const currentTileSourceLayers = tileSourceLayersChange.currentValue as Array<TileSourceLayerTableGui>;
    const previousTileSourceLayers = tileSourceLayersChange.previousValue as Array<TileSourceLayerTableGui>;

    const currentSelectedTileSource = currentTileSourceLayers
                                      .find((tileSourceLayer)=>tileSourceLayer.isSelected === true);
    const previousSelectedTileSource = previousTileSourceLayers
                                      .find((tileSourceLayer)=>tileSourceLayer.isSelected === true);

    return currentSelectedTileSource.key === previousSelectedTileSource.key;
  }

  ngOnDestroy(): void {
      this.kimeraPerformanceTrackingService.stopTracking();
  }

  initializeViewer(){
    const initialSettings = {
        id:'kimera-openseadragon-container',
        blendTime: 0,
        animationTime: 0.33,
        zoomPerScroll: 1.7,
        maxZoomPixelRatio: 4.0,
        flickMomentum: 0.25,
        immediateRender: false,
        showNavigator: true,
        showNavigationControl: false,
        sequenceMode: false,
        imageLoaderLimit: 6,
        toolbar: 'toolbar',
        loadTilesWithAjax: true,
        ajaxHeaders:{
          authorization:'Bearer ' +this.credentialsService.getAccessToken()
        }
    }
    this.loadOpenseadragonSvgOverlay();
    this.kimeraPerformanceTrackingService.startTracking(this.analysisCode, this.webCVContentLakeId,this.gridId);
    this.createToolbarOptions();
    this.startOpenSeaDragon(initialSettings);
  }

  loadOpenseadragonSvgOverlay(){
    initializeOpenSeaDragonSvgRelay(OpenSeaDragon)
  }

  findLayerThatChanged(previousTileSourceLayers: TileSourceLayerTableGui[],
    currentTileSourceLayers: TileSourceLayerTableGui[]):
    { previousValue: TileSourceLayerTableGui; currentValue: TileSourceLayerTableGui }
    | null {

    for (let i = 0; i < previousTileSourceLayers.length; i++) {
      const obj1 = previousTileSourceLayers[i];
      const obj2 = currentTileSourceLayers[i];

      for (const prop in obj1) {
        if (prop !== 'key' && obj1[prop] !== obj2[prop]) {
          return {previousValue: obj1, currentValue: obj2}
        }
      }
    }

    //no difference
    return null;
  }


  addTileSourceInsideTheViewer(
    tileSource: TileSource,
    correspondingTileSourceLayer: TileSourceLayerTableGui,
    viewer?: OpenSeaDragon.Viewer,
    index?: number,
  ){
    const viewerToAddTilesInto = viewer ?? this.viewer;
    viewerToAddTilesInto.addTiledImage({
      x: tileSource.x,
      y: tileSource.y,
      opacity: this.getTileSourceLayerOpacity(correspondingTileSourceLayer),
      width: tileSource.width,
      tileSource: {
        width: tileSource.tile_source.width,
        height: tileSource.tile_source.height,
        tileSize: tileSource.tile_source.tileSize,
        tileOverlap: tileSource.tile_source.tileOverlap,
        maxLevel: tileSource.tile_source.maxLevel,
        getTileUrl: this.getTileUrlBasedOnTileSouce(tileSource.tile_source, correspondingTileSourceLayer.colour)
      },
      index,
    });
  }

  initializeTileSources(viewer: OpenSeaDragon.Viewer){
      let countInnerLayers = 0;
      this.tileSourceLayers
      .forEach((tileSourceLayer)=>{
        this.tileSourcesLayerKeyIndexMap[tileSourceLayer.key] = [];
        tileSourceLayer.tileSources.forEach((tileSource)=>{
          this.addTileSourceInsideTheViewer(tileSource, tileSourceLayer,viewer)
          this.tileSourcesLayerKeyIndexMap[tileSourceLayer.key].push(countInnerLayers);
          countInnerLayers++;
        })
      })
  }

  getTileSourceLayerOpacity(tileSourceLayer: TileSourceLayerTableGui){
    return tileSourceLayer.isVisible? tileSourceLayer.opacity: 0;
  }

  rebuildTileSources( previousValue: TileSourceLayerTableGui, currentValue: TileSourceLayerTableGui ){
    const isNewFetchNeccessary = previousValue.colour !== currentValue.colour;
    const indexesOfChangedTileSource = this.tileSourcesLayerKeyIndexMap[previousValue.key];
    const tileSourceLayer = currentValue;
    if(!isNewFetchNeccessary){
      indexesOfChangedTileSource.forEach((index)=>{
        this.viewer.world.getItemAt(index).setOpacity(this.getTileSourceLayerOpacity(tileSourceLayer))
      })
    }else{
      [...indexesOfChangedTileSource].reverse().forEach((index)=>{
        this.viewer.world.removeItem(this.viewer.world.getItemAt(index));
      })
      tileSourceLayer.tileSources.forEach((tileSource,innerIndex)=>{
        const index = indexesOfChangedTileSource[innerIndex];
        this.addTileSourceInsideTheViewer(tileSource, tileSourceLayer, null, index)
      })
    }
  }

  startOpenSeaDragon(settings: any){
    setTimeout(()=>{
      this.viewer = this._ngZone.runOutsideAngular(() => {
      const viewer = OpenSeaDragon(settings)
      this.initializeTileSources(viewer)
      return viewer;
      })
      document.querySelector('#toolbar').lastChild.remove();
      this.viewer.addHandler('full-screen', () => {
       this.fullscreenToggleOption?.stateBehavior.onChange();
       this.isFullScreen = !this.isFullScreen;
     });
     this.addCanvasDragHandlers();
     this.hasRenderedOnce = true;
    },0)
  }



  getTileUrlBasedOnTileSouce( tileSource: TileSourceInner, colour: string){
    return (level: any, x: any, y: any) =>{

      const band = tileSource.maxLevel - level;
      const vpsize = 512 * Math.pow(2,band);
      const ur = y * vpsize + tileSource.ur_offset;
      const uc = x * vpsize + tileSource.uc_offset;
      let remainingArgs = `grid_id=${tileSource['WEBCV-GRID-SUFFIX']}&ur=${ur}&uc=${uc}`
                            +`&h=${vpsize}&w=${vpsize}&rw=512&rh=512`
                            +`&ngsw-bypass=true`;
      if(colour){
        remainingArgs = remainingArgs + `&tint=${colour.slice(1)}`
      }
      if(tileSource['GRID-VERSION']){
        remainingArgs = remainingArgs + `&version=${tileSource['GRID-VERSION']}`;
      }


    if(this.useEntitledBasedUrl){
     return `${this.baseUrlEntitled}cl_webcv_id=${this.webCVContentLakeId}&`+remainingArgs;
    }
    else{
      return `${this.baseUrl}`+remainingArgs;
    }
    }
  }

  public onClickZoomIn(): void {
    this.viewer.viewport.zoomBy(1.1);
    this.viewer.viewport.applyConstraints();
  }

  public onClickZoomOut(): void {
    this.viewer.viewport.zoomBy(0.9);
    this.viewer.viewport.applyConstraints();
  }

  public onClickFitPage(): void {
    const visibleLayerItemRectBounds = this.tileSourceLayers
                              .filter(tileSourceLayer=>tileSourceLayer.isVisible)
                              .reduce((allItemsRectBounds, currentTileSourceLayer)=>{
                                const indexsOfCurrentTileSourceLayer =
                                this.tileSourcesLayerKeyIndexMap[currentTileSourceLayer.key];
                                const rectBounds = indexsOfCurrentTileSourceLayer
                                .map((index)=>this.viewer.world.getItemAt(index).getBounds());
                                allItemsRectBounds.push(...rectBounds);
                                return allItemsRectBounds;
                              },[])

    if(visibleLayerItemRectBounds.length>0){
      const rect = new OpenSeaDragon.Rect();
      rect.x = Math.min(...visibleLayerItemRectBounds.map((rectInner)=>rectInner.x))
      rect.y = Math.min(...visibleLayerItemRectBounds.map((rectInner)=>rectInner.y))
      rect.height = Math.max(...visibleLayerItemRectBounds.map((rectInner)=>rectInner.height + rectInner.y - rect.y))
      rect.width = Math.max(...visibleLayerItemRectBounds.map((rectInner)=>rectInner.width + rectInner.x - rect.x));
      rect.degrees = 0;
      this.viewer.viewport.fitBounds(rect);
    }
  }


  public onClickFullscreenToggle(): void {
    this.fullscreenToggleOption?.stateBehavior.onChange();
    this.viewer.setFullScreen(!this.isFullScreen);
  }

  public onToggleAreaSelection(): void{

    if(this.areaSelectionToggleOption.stateBehavior.isPressed()){
      this.measuringMode = null;
    }else{
      this.measuringMode = MeasurementType.Area;
      this.lengthSelectionToggleOption.stateBehavior.reset();
    }
  }

  public executeToolbarOption({ key }: { key: string, value: Option }): void {
    if (key in this.eventToMethod) {
      const methodName = this.eventToMethod[key];
      this[methodName]();
    }
  }

  protected prepareForMeasuring(): void {}

  protected getScalingFactor(): number {
    return this.measuringScalingFactor.micronScalingFactor;
  }

  protected calibrateMeasurement(lineDistance: number): Promise<void> {
    return Promise.resolve();
  }

  protected displayMeasurement(measurement: Measurement): Promise<any> {
    if (this.isFullScreen) {
      this.DEFAULT_MODAL_OPTIONS.windowClass = 'kimera-generic-dialog dialog-top-separation';
    } else {
      this.DEFAULT_MODAL_OPTIONS.windowClass = 'kimera-generic-dialog';
    }

    const dialog = this.dialogService.open(KimeraGenericModelBoxComponent, this.DEFAULT_MODAL_OPTIONS)
    dialog.componentInstance.measurement = measurement;
    return dialog.result;
  }

  private createToolbarOptions():void{

    const optionGroups = [];
    const measurementOptions = [];
    const zoomOptions = [];

    const lengthTooltipText = this.measuringToolsDisabled? null: 'Measure length';

    this.lengthSelectionToggleOption = new CustomOption(
      'toggle-length-selection',
      [ { name: 'click', key: 'toggle-length-selection' } ],
      new ToggleBehavior(Icon.MEASURE_LINE, lengthTooltipText, Icon.MEASURE_LINE, lengthTooltipText, false),
      null,
      this.measuringToolsDisabled
    )
    measurementOptions.push(this.lengthSelectionToggleOption)

    const areaTooltipText = this.measuringToolsDisabled? null: 'Measure area';

    this.areaSelectionToggleOption = new CustomOption(
      'toggle-area-selection',
      [ { name: 'click', key: 'toggle-area-selection' } ],
      new ToggleBehavior(Icon.MEASURE_AREA, areaTooltipText, Icon.MEASURE_AREA, areaTooltipText, false),
      null,
      this.measuringToolsDisabled
    );
    measurementOptions.push(this.areaSelectionToggleOption);

    zoomOptions.push(new CustomOption(
      'zoom-out',
      [ { name: 'click', key: 'zoom-out' } ],
      new StatelessBehavior(Icon.IMAGE_ZOOM_OUT, 'Zoom Out'),
    ));
    zoomOptions.push(new CustomOption(
      'zoom-in',
      [ { name: 'click', key: 'zoom-in' } ],
      new StatelessBehavior(Icon.IMAGE_ZOOM_IN, 'Zoom In'),
    ));
    zoomOptions.push(new CustomOption(
      'fit-to-page',
      [ { name: 'click', key: 'fit-to-page' } ],
      new StatelessBehavior(Icon.IMAGE_FIT_PAGE, 'Fit to Page'),
    ));

    this.fullscreenToggleOption =new CustomOption(
      'toggle-fullscreen',
      [ { name: 'click', key: 'toggle-fullscreen' } ],
      new ToggleBehavior(
        Icon.IMAGE_ENTER_FULLSCREEN, 'Full Screen',
        Icon.IMAGE_EXIT_FULLSCREEN, 'Exit Full Screen',
      ));

    zoomOptions.push(this.fullscreenToggleOption);

    optionGroups.push(new OptionGroup(measurementOptions));
    optionGroups.push(new OptionGroup(zoomOptions));

    this.toolbarOptions = optionGroups;
  }


}
