import { MenuModel } from '@app/store/Models/workspace/workspaceModel';
import { SidebarItem } from '../models/navigation/navigation.models';

export class MenuHelper {
  static sidebarItemtoMenuModel(sidebarItem: SidebarItem): MenuModel {
    return {
      id: sidebarItem.id,
      label: sidebarItem.name,
      route: sidebarItem.route,
      class: sidebarItem.class,
      tooltip: sidebarItem.tooltip,
      icon_class: sidebarItem.icon_class,
      animation_asset: sidebarItem.animation_asset,
      animation_type: sidebarItem.animation_type,
      hasNewInfo: sidebarItem.hasNewInfo,
    };
  }
}
