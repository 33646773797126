<div class="content-container">
  <div class="header-navigator">
    <app-dropdown
      [itemList]="dropdownChannels"
      [selectedItem]="currentChannel"
      [hideIconOnDropdown]="hideIconOnDropdown"
      [titleSize]="'16'"
      (changeDropdownItem)="onItemSelected($event)"
    ></app-dropdown>

    <app-generic-search-bar
      [class] ="hiddenSearchButton ? 'shrinked-search-bar' : 'expanded-search-bar'"
      [hiddenSearchButton]="hiddenSearchButton"
      [hiddenSearchLabel]="hiddenSearchLabel"
      [isDisabled]="isDashboard"
      [searchPlaceholder]="currentChannel.name"
      (searchTermsChanged)="onChangeSearchTerms($event)"
      (focusSearchbar)="onFocusSearchbar()"
      (blurSearchbar)="onBlurSearchbar($event)"
    ></app-generic-search-bar>
  </div>

  <div *ngIf="isLoading; else loaded" class="progress">
    <app-progress-bar-circular></app-progress-bar-circular>
  </div>
  <ng-template #loaded>
    <app-tabbed-analysis-list
      [tabGroups]="tabGroups"
      [activeTabGroup]="activeTabGroup"
      [activeTab]="activeTab"
      [count]="count"
      [page]="paginationOptions.page"
      [pageSizes]="pageSizes"
      [pageSize]="paginationOptions.size"
      [showHighlightToggle]="showHighlightToggle"
      [showHighlights]="paginationOptions.showHighlights"
      [filters]="filters"
      [showFilters]="showFilters"
      [filtersCollapsed]="filtersCollapsed"
      [searchTerm]="searchTerm"
      [breadcrumbString]="currentChannel.name"
      [loading]="loadingTable"
      [showPagination]="!isDashboard"
      [sortOptions]="sortOptions"
      [defaultSort]="defaultSort"
      (changePaginationOptions)="onChangePaginationOptions($event)"
      (clickTab)="onClickTab($event)"
      (changeFilters)="onChangeFilters($event)"
      (toggleFilters)="onToggleFilters($event)"
    ></app-tabbed-analysis-list>
  </ng-template>
</div>
