import {Component, Input} from '@angular/core';
import {ContentHeaderBlock} from '@shared/services/hygraph/hygraph.models';

@Component({
  selector: 'app-hygraph-header',
  templateUrl: './hygraph-header.component.html',
  styleUrls: ['./hygraph-header.component.scss']
})
export class HygraphHeaderComponent {
  @Input() header: ContentHeaderBlock;
  @Input() date: Date;
}
