export enum NotificationTypeEnum {
  SEARCH = 'SEARCH',
  COLLECTION = 'COLLECTION',
  SHARED = 'SHARED',
}

export interface NotificationModel {
  id: string;
  title: string;
  body: string;
  img?: string;
  type: NotificationTypeEnum;
  acknowledged?: boolean
  read: boolean;
  date: Date;
}

export class PopupModel {
  header: string;
  type: string;
  primaryButton: boolean;
  secondaryButton: boolean;
  primaryLabel: string;
  primaryIcon?: string;
  secondaryLabel: string;
  primaryButtonBehaviour?: any
  showContactEmail?: boolean;
  icon?: string;
  iconClass?: string;
  body?: string;
}
