<div class="image-view-mode" *ngIf="!showImageViewer">
  <button [class.active]="viewMode === 'grid'" (click)="onClickView('grid')">
    <em class="icon-grid"></em>
  </button>
  <button [class.active]="viewMode === 'list'" (click)="onClickView('list')">
    <em class="icon-list"></em>
  </button>
</div>
<app-image-grid-view
  *ngIf="!showImageViewer && viewMode === 'grid'"
  [isLoading]="isLoading"
  [title]="title"
  [images]="images"
  [imageCount]="imageCount"
  [pageSize]="paginationParameters.size"
  [currentPage]="paginationParameters.page"
  [addToFavoriteEnabled]="addToFavoritesEnabled"
  (clickImage)="onClickImage($event)"
  [downloadEnabled]="downloadEnabled"
  (changePaginationOptions)="onChangePaginationOptions($event)"
  (downloadImage)="onDownloadImage($event)"
  (downloadImages)="onDownloadImages($event)"
  (shareImage)="onShareImage($event)"
  (addToFavorites)="onAddToFavorites($event)"
></app-image-grid-view>
<app-image-list-view
  *ngIf="!showImageViewer && viewMode === 'list'"
  [isLoading]="isLoading"
  [title]="title"
  [images]="images"
  [imageCount]="imageCount"
  [pageSize]="paginationParameters.size"
  [currentPage]="paginationParameters.page"
  [downloadEnabled]="downloadEnabled"
  [addToFavoriteEnabled]="addToFavoritesEnabled"
  (clickImage)="onClickImage($event)"
  (changePaginationOptions)="onChangePaginationOptions($event)"
  (downloadImage)="onDownloadImage($event)"
  (downloadImages)="onDownloadImages($event)"
  (shareImage)="onShareImage($event)"
  (addToFavorites)="onAddToFavorites($event)"
></app-image-list-view>
<app-image-viewer
  *ngIf="showImageViewer && viewerImages?.length > 0"
  class="image-viewer"
  [thumbnailImages]="viewerImages"
  [selectedImage]="_selectedImage"
  [imageNumber]="imageNumber"
  [numberOfImages]="viewerImages.length"
  [channelId]="channelId"
  [subscriptionId]="subscriptionId"
  [downloadEnabled]="downloadEnabled"
  [addToFavoriteEnabled]="addToFavoritesEnabled"
  [enableUnwatermarkedImageRequest]="enableUnwatermarkedImageRequest"
  (closeImageViewer)="onCloseImageViewer()"
  (thumbnailClicked)="onThumbnailClicked($event)"
  (nextImage)="onNextImage()"
  (previousImage)="onPreviousImage()"
  (downloadImage)="onDownloadImage($event)"
  (addToFavorites)="onAddToFavorites($event)"
></app-image-viewer>
