<form *ngFor="let element of props; let i = index;">
  <div *ngIf="currentValue[i]"
       class="form-group" (click)="handleChange(currentValue, i)">
    <input [ngModel]="currentValue[i]?.value"
           (ngModelChange)="handleChange(currentValue, i)"
           [ngModelOptions]="{standalone: true}"
           class="{{element?.class}}"
           [disabled]="currentValue[i]?.disabled || disabled"
           [name]="currentValue[i]?.key"
           (click)="this.onTouched()"
           name="chkbx_3"
           type="checkbox"
    />
    <label [for]="currentValue[i]?.key"> <span></span> <div class="title">{{element?.label}}</div> </label>
  </div>
</form>
