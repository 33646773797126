import { Component } from '@angular/core';
import { SaContentNavigatorTableRow } from '../../../../strategy-analytics/models/sa-content-navigator.model';
import { ContentNavigatorTableComponent } from '@shared/components/content-navigator/content-navigator-table/content-navigator-table.component';

@Component({
  selector: 'app-sa-content-navigator-table',
  templateUrl: './sa-content-navigator-table.component.html',
  styleUrls: [ './sa-content-navigator-table.component.scss' ],
})
export class SAContentNavigatorTableComponent extends ContentNavigatorTableComponent<SaContentNavigatorTableRow> {
  showDocumentsCount = true;
  showImagesCount = true;
  parseInt = parseInt;
}
