/**
 * Class to represent Pagination parameters, like page, sort etc
 */
export class PaginationParameters {
  size: number;
  page: number;
  sort: string | null;
  from?: number;
  showHighlights?: boolean;

  constructor(size: number, page: number, sort?: string, options?: Partial<PaginationParameters>) {
    this.size = size;
    this.page = page;
    this.sort = sort ?? null;
    this.from = options?.from;
    this.showHighlights = options?.showHighlights;
  }
}
