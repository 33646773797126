import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { Analysis, AnalysisAsset, AnalysisReportPage, AssetGroup, RelatedContent } from '@app/@shared/models/reverse-engineering/analysis.models';
import { BlogAuthorModel } from '@shared/models/blog-author.model';

@Component({
  selector: 'app-document-content',
  templateUrl: './document-content.component.html',
  styleUrls: ['./document-content.component.scss'],
})
export class DocumentContentComponent {

  @Input() sidebarOpen = false;
  @Input() currentAnalysis: Analysis;
  @Input() analysisReports: AnalysisReportPage;
  @Input() annotatedDocuments: Array<string>;
  @Input() paginationParameters: PaginationParameters;
  @Input() searchTerm: string;
  @Input() isLoading = false;
  @Input() authors: BlogAuthorModel[] = [];
  @Input() showInProgressMessage = false;
  @Input() channelNames: string;
  @Input() documentAssetviewer = 'report-list-view';
  @Input() analysisCode: string;
  @Input() selectedReportId: AnalysisAsset;
  @Input() selectedAssetId: string;
  @Input() channelId: string;
  @Input() subscriptionId: string;
  @Input() relatedContentList: RelatedContent[];

  @Output() changePaginationParameters = new EventEmitter<PaginationParameters>();
  @Output() clickDocument = new EventEmitter<AnalysisAsset>();
  @Output() downloadAssetGroup = new EventEmitter<AssetGroup>();
  @Output() clickPVO = new EventEmitter<any>();
  @Output() clickRequest = new EventEmitter<any>();
  @Output() closePdfViewer = new EventEmitter<void>();
  @Output() closeAssetViewer = new EventEmitter<void>();
  @Output() documentNewTab = new EventEmitter<AnalysisAsset>();
  @Output() documentShare: EventEmitter<AnalysisAsset> = new EventEmitter<AnalysisAsset>();

  public contentTitle = 'Analysis in Progress';
  public contentDescriptionTitle1 = 'There is no content to view at this time.';
  public contentDescriptionTitle2 = 'Follow to receive notifications when content is available.';

  public onChangePaginationParameters(paginationParams: PaginationParameters): void {
    this.changePaginationParameters.emit(paginationParams);
  }

  public onClickDocument(report: AnalysisAsset): void {
    this.clickDocument.emit(report);
  }

  public onDownloadAssetGroup(report: AssetGroup): void {
    this.downloadAssetGroup.emit(report);
  }

  public onDocumentNewTab(report: AnalysisAsset): void {
    this.documentNewTab.emit(report);
  }

  public onDocumentShare(report: AnalysisAsset): void {
    this.documentShare.emit(report);
  }

  public onClickPVO(): void {
    this.clickPVO.emit();
  }

  public onClickRequest(): void {
    this.clickRequest.emit();
  }

  public onClosePdfViewer(): void {
    this.closePdfViewer.emit()
  }

  public onCloseAssetViewer() {
    this.closeAssetViewer.emit();
  }
}
