import {TelemetryEventName} from '../enums/telemetry-event-name';

export class TelemetryEvent {
  name: string;
  payload: any;
  applicationModule?: string;

  constructor(name: TelemetryEventName, payload: any, applicationModule?: string) {
    this.name = name;
    this.payload = payload;
    this.applicationModule = applicationModule;
  }
}
