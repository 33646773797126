<div class="card-wrapper" [class.video-card-disabled]="!video.entitled">
  <app-card>
    <div class="player-thumbnail">
      <img
        [src]="video.thumbnailUrl"
        alt=""
        aria-hidden="true"
      />
      <div class="container-icon">
        <em class="icon-locked" *ngIf="!video.entitled"></em>
      </div>
    </div>
   
    <div class="card-footer">
      <div class="card-tagline truncate-ellipsis"><em class="icon-locked" *ngIf="!video.entitled"></em>{{video.channelName}}</div>
      <div class="card-title truncate-ellipsis" [innerHTML]="video.title"></div>
      <div>
          <p class="publication-date">{{video.published | date: 'MMM d, yyyy' : 'UTC'}}</p>
      </div> 
    </div>
  </app-card>
</div>
