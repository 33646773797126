<div class="search-bar" [class.active]="isActive" [class.no-border]="isSearchResult">
  <div
    *ngIf="showRecentSearches && recentSearches?.length"
    class="typeahead-container"
    (click)="$event.stopPropagation()"
  >
    <app-recent-typeahead-container
       [focused]="focusRecent"
       [recentSearches]="recentSearches"
       (search)="selectSearchTerms($event)"
    >
    </app-recent-typeahead-container>
  </div>
  <div #scrollContainer
   class="search-form-wrapper scrollbar"
   [class.grabbing]="panningInput"
   (mouseleave)="stopPanning()"
  >
    <form [formGroup]="searchForm"
      class="input-content cursor-grab"
      [class.cursor-grabbing]="panningInput"
      [class.cursor-grab]="isSearchInputScrollable()"
      autocomplete="off"
    >
      <div class="input-search">
        <input
          (clickOutside)="closeTypeAhead()"
          (click)="onClick($event)"
          (keyup.backspace)="selectOrRemoveLastChip()"
          (keydown.enter)="setSearchTerms()"
          (keydown)="focusOnTypeahead($event)"
          (input)="unselectAllChips()"
          (focus)="isActive = true; onFocus()"
          (blur)="isActive = false; onBlur()"
          [placeholder]="isSearchBarEmpty() ? 'Search ' + this.searchPlaceholder : 'Add search text'"
          [disabled]="isDisabled"
          appClickOutside
          class="input-item"
          name="searchTerm"
          formControlName="searchInput"
          type="text"
        />
      </div>
    </form>
  </div>

  <div *ngIf="searchInputValue.length" class="clear-input-search-chip-disabled">
    <em class="icon-close_small" (click)="clearAll()" ngbTooltip="Clear" placement="bottom"></em>
  </div>

  <div *ngIf="!hiddenSearchButton"
    [ngClass]="{'disabled-button': this.searchChips.length === 0 && this.searchForm.get('searchInput').value === ''}"
    class="search-button" (click)="setSearchTerms()">
      <em class="icon icon-search"></em>
    Search
  </div>
</div>
