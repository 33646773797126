import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { interval, Subscription } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class KimeraPerformanceTrackingService {

  FLUSH_INTERVAL_MS = 10000;
  MAX_PERFORMANCE_BUFFER = 2000;
  BASE_URL = environment.kimeraImageFetchBaseUrl+'/im_transformed';

  interval$: Subscription;
  reportCode: string;
  clWebcvId: string;
  gridId: string;

  constructor(private httpClient: HttpClient) { }


  public startTracking(reportCode: string, clWebcvId: string, gridId: string){
    if(performance){
      this.cleanUpSubscriptionIfRunning();
      this.reportCode = reportCode;
      this.clWebcvId = clWebcvId;
      this.gridId = gridId;
      performance.clearResourceTimings();
      performance.setResourceTimingBufferSize(this.MAX_PERFORMANCE_BUFFER);
      this.interval$ = interval(this.FLUSH_INTERVAL_MS).subscribe(()=>this.sendAndFlush());
    }
  }


  public stopTracking(){
    if(performance){
      this.sendAndFlush()
      this.cleanUpSubscriptionIfRunning();
    }
  }

  private sendAndFlush(){
    const statsRecords = performance.getEntriesByType('resource').filter(performanceEntry =>{
      return performanceEntry.name.includes(this.BASE_URL)});

    if(statsRecords.length>0){
      const headers = new HttpHeaders().set('Accept', 'application/json');
      const payload = {
        statsRecords,
        'reportCode': this.reportCode,
        'clWebcvId': this.clWebcvId,
        'gridId': this.gridId
      }
      this.httpClient.post<any>
      (environment.kimeraImageFetchBaseUrl+'/v1/performance_metrics', payload, {headers})
      .subscribe();

      performance.clearResourceTimings();
    }
  }


  private cleanUpSubscriptionIfRunning(){
    if(this.interval$ && !this.interval$.closed){
      this.interval$.unsubscribe();
    }
  }


}
