
import {Params} from '@angular/router';
import {IReturnedSearchItem} from '@app/store/Models/search/searchModels';
import {ElementRef} from '@angular/core';
import {Observable} from 'rxjs';

export enum CONTENT_TYPE_ENUM {
  PRODUCT = 'product',
  COMPONENT = 'component',
  DIE = 'die',
  REPORT = 'report',
  IMAGE = 'image',
  SCHEMATIC = 'schematic'
}

export enum CATEGORY_TYPE_ENUM {
  IP = 'IP',
  MA = 'MA',
  RE = 'RE',
  ALL = 'ALL',
}

export enum CATEGORY_NAME {
  MA = 'Market Analysis',
  RE = 'Reverse Engineering',
  ALL = '',
}

export const ASSETS = [CONTENT_TYPE_ENUM.IMAGE, CONTENT_TYPE_ENUM.SCHEMATIC, CONTENT_TYPE_ENUM.REPORT];
export const ASSETS_VALUES = ASSETS.map(v => v.toString());

export function getContentTypeLabel(contentType: CONTENT_TYPE_ENUM | string): string {
  return contentType.toString() !== 'pcb' ? contentType.toString() + 's' : contentType.toString()
}

export function isContentTypeAnAsset(contentType: CONTENT_TYPE_ENUM): boolean {
  return ASSETS.includes(contentType)
}
export function isContentTypeStringAnAsset(contentType: string): boolean {
  return ASSETS_VALUES.includes(contentType)
}
export function buildQueryParams(item: IReturnedSearchItem): Params {
  let tab: string;
  if(isContentTypeStringAnAsset(item.contentType)) {
    tab = `${item.contentType}s`
  }
  // Adding this ignore due to the mutation below to cleanup the object
  // eslint-disable-next-line prefer-const
  let params: Params = {
    tab,
    'id': item.contentType === CONTENT_TYPE_ENUM.REPORT.toString()
      ? item.reportCode
      : item.contentType !== CONTENT_TYPE_ENUM.SCHEMATIC.toString()
        ? item.objectId
        : null,
    'genealogyCode': item.genealogyCode
  }
  // Params cleanup to avoid null queryparams
  Object.keys(params).forEach(key => {
    if(params[key] == null || params[key].toString().length === 0) {
      delete params[key]
    }
  })
  return params;
}

export function buildQueryParamsWithNumber(item: any, activeTab: string): Params {
  let tab: string;
  if(isContentTypeStringAnAsset(item.contentType)) {
    tab = `${item.contentType}s`
  }
  // Adding this ignore due to the mutation below to cleanup the object
  // eslint-disable-next-line prefer-const
  let params: Params = {
    tab,
    'id': item.contentType === CONTENT_TYPE_ENUM.REPORT.toString()
      ? item.reportCode
      : item.contentType !== CONTENT_TYPE_ENUM.SCHEMATIC.toString()
        ? item.objectId
        : null,
    'genealogyCode': item.genealogyCode,
    activeTab
  }
  // Params cleanup to avoid null queryparams
  Object.keys(params).forEach(key => {
    if(params[key] == null || params[key].toString().length === 0) {
      delete params[key]
    }
  })
  return params;
}


export function getProductCommonName(item: IReturnedSearchItem) {
  if(item.modelNumber && item.modelNumber !== 'null'
    && item.commonName && item.commonName !== 'null') {
    return `${item.modelNumber} (${item.commonName})`
  }
  if(item.modelNumber && item.modelNumber !== 'null'
    && item.name && item.name !== 'null') {
    return `${item.modelNumber} (${item.name})`
  }
  if(item.modelNumber && item.modelNumber !== 'null') {
    return item.modelNumber
  }
  if(item.commonName && item.commonName !== 'null') {
    return item.commonName
  }
  if(item.name && item.name !== 'null') {
    return item.name
  }
  return '';
}


/*
* This function creates a resize observable as an RXJS Observable, which can be debounced,
* limited, and any other operation involving RXJS streams.
* The resize observer polyfill basically observes any DOM element for size changes, and returns
* the element dimensions.
*
* @see src/app/search/components/search-grid-view/search-grid-view.component.ts ngAfterViewInit() method
* for an example
* */
export function resizeObservable(elem: ElementRef) {
  return new Observable(subscriber => {
    const ro = new ResizeObserver(entries => {
      subscriber.next(entries);
    });

    // Observe one or multiple elements
    ro.observe(elem.nativeElement);
    return function unsubscribe() {
      ro.unobserve(elem.nativeElement);
      subscriber.unsubscribe()
    }
  });
}
