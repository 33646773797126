<div class="sidebar scrollbar" [ngStyle]="{'width': sidebarOpen ? metaDataItemWidth + 'px' : null}">
    <div class="metadata-container scrollbar" (scroll)="onScroll($event)" [ngStyle]="{'width': metaDataItemWidth + 'px'}">
        <div *ngFor="let tileSourceLayer of tileSourceLayers" class="metadata-item" [ngStyle]="{'height': metaDataItemHeight + 'px'}" [class.selection-on]="tileSourceLayer.isSelected === true">
          <div class="layer-selection-container" (click)="onToggleSelection(tileSourceLayer)">
              <div class="colour-swatch-container">
                <div *ngIf="!isDefaultColourAndDefaultOpacity(tileSourceLayer)">
                  <div class="base-swatch">
                      <div class="mini-square"></div>
                      <div class="mini-square"></div>
                      <div class="mini-square"></div>
                      <div class="mini-square"></div>
                      <div class="overlay" [ngStyle]="{ 'background-color': tileSourceLayer.colour, 'opacity': tileSourceLayer.opacity }">
                      </div>
                  </div>

                </div>
              </div>
              <div class="text-container" [class.visibility-off]="tileSourceLayer.isVisible === false">
                {{ tileSourceLayer.key }}
              </div>
          </div>
          <div class="change-colour-container iconClass" (click)="onSelectColourPicker(tileSourceLayer)">
            <span [ngClass]="iconClassSelectColour" [class.selection-on]="tileSourceLayer.isSelected === true" [ngbTooltip]="iconSelectColourTooltip" placement="right" container="body"></span>
          </div>
          <div class="layer-visibility-toggle-container iconClass" (click)="onToggleVisibility(tileSourceLayer)">
              <span [ngClass]="tileSourceLayer.isVisible === true ? iconClassEyeShow : iconClassEyeHide" [ngbTooltip]="iconEyeTooltip" placement="right" container="body"></span>
          </div>
        </div>
    </div>
</div>