import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TABLET_MAX_WIDTH } from '@app/@shared/consts/breakpoints.consts';
import {
  ContentNavigatorTab, ReportFlavor, TabGroup
} from '@app/@shared/models/content-navigator/content-navigator.model';
import { NavigationElement, NavigationObjectType, Subscription } from '@app/@shared/models/navigation/navigation.models';
import { PaginationParameters } from '@app/@shared/models/pagination';
import {
  AnalysisFilter,
  AnalysisParametersEvent,
  Filters,
  FiltersChangedEvent
} from '@app/@shared/models/reverse-engineering/analysis.models';
import { ChannelCount } from '@app/@shared/models/search/cti-search.model';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { SearchService } from '@app/@shared/services/search/search.service';
import { TelemetryService } from '@app/@shared/services/telemetry-service';
import { FilterHelper } from '@app/@shared/utils/filter-helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DropdownItem, SelectOption } from '@shared/models/shared.models';
import { PaginationHelper } from '@shared/utils/pagination-helper';
import { Observable, combineLatest } from 'rxjs';
import { concatMap, debounceTime, tap, map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-channel-analysis',
  templateUrl: './channel-analysis.component.html',
  styleUrls: ['./channel-analysis.component.scss'],
})
export class ChannelAnalysisComponent implements OnInit, OnDestroy {
  @Input() getSubscription: (subscriptionId: string) => Observable<Subscription>;
  @Input() showFilters = true;
  @Input() selectedFilters: Filters;
  @Input() moduleName: string;
  @Input() tabGroups: Array<TabGroup>;
  @Input() count: number;
  @Input() loadingTable = false;
  @Input() sortOptions: SelectOption[];
  @Input() defaultSort: SelectOption;
  @Input() showHighlightToggle = true;

  @Input() set filters(filters: AnalysisFilter[]) {
    this._filters = filters;
  }
  get filters() {
    return this._filters;
  }

  @Input() set activeTab(tab: ContentNavigatorTab) {
    this._activeTab = tab;
  }
  get activeTab() {
    return this._activeTab;
  }

  @Input() set activeTabGroup(group: TabGroup) {
    this._activeTabGroup = group;
  }
  get activeTabGroup() {
    return this._activeTabGroup;
  }

  @Output() parametersForAnalysis = new EventEmitter<AnalysisParametersEvent>();

  public paginationOptions = new PaginationParameters(50, 1, '');
  public searchTerm = '';
  public raiseTelemetry = false;
  public dropdownChannels: NavigationElement[] = [];
  public currentChannelId: string;
  public currentChannel: any;
  public hideIconOnDropdown: boolean;
  public hiddenSearchLabel: boolean;
  public hiddenSearchButton: boolean;
  public isLoading = true;
  public isDashboard = false;
  public pageSizes = [50, 100, 500];
  public filtersCollapsed = false;
  public fragment: URLSearchParams = new URLSearchParams();
  public selectedSortOption: SelectOption;
  private _activeTab: ContentNavigatorTab;
  private _activeTabGroup: TabGroup;
  private _filters: AnalysisFilter[];
  private subscriptionName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    private ffService: FeatureFlagService,
    private telemetryService: TelemetryService,
  ) {}

  public ngOnInit(): void {
    this.hideIconOnDropdown = true;
    this.hiddenSearchLabel = true;
    this.hiddenSearchButton = true;
    // update channel dropdown
    this.route.paramMap
      .pipe(
        tap((routeParams: ParamMap) => (this.currentChannelId = routeParams.get('channelId'))),
        concatMap(() => {
          const subscriptionId = this.route.parent.parent.snapshot.paramMap.get('subscriptionId');
          return this.getSubscription(subscriptionId);
        }),
        concatMap((subscription) => this.searchService.getChannelCounts(
            subscription.children.filter(c => c.type === NavigationObjectType.CHANNEL).map(c => c.id)
          ).pipe(
            map((result) => ({
              subscription,
              channelCounts: result
            }))
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(({subscription, channelCounts }:
                  { subscription: Subscription, channelCounts: ChannelCount[] }) => {
        this.subscriptionName = subscription.name;
        this.dropdownChannels = subscription.children.filter(
          c => c.type === NavigationObjectType.CHANNEL
          && (channelCounts.find(count => count.channel === c.id)?.count > 0)
        );
        this.dropdownChannels.sort(this.sortDropdownByName);
        this.currentChannel = this.dropdownChannels.find(
          (channel: DropdownItem) => channel.id === this.currentChannelId
        );
      });
    // emit new parameters for analysis table based on search term, pagination, and flavor
    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap,
      this.route.fragment,
    ])
      .pipe(
        debounceTime(0),
        tap(([_ , queryParams, fragment]) => {
          this.searchTerm = queryParams.getAll('q').map(term => term.trim()).filter(Boolean).join(',');
          this.raiseTelemetry = this.telemetryService.getRaiseTelemetryEvent();
          this.paginationOptions = PaginationHelper.fromParamsOrDefault(queryParams,
            { size: 50, page: 1, sort: this.defaultSort.value });
          this.selectedSortOption = this.sortOptions.find(
            x => x.value === this.paginationOptions.sort) ?? this.defaultSort;
          const fragmentParams = new URLSearchParams(fragment);
          const activeTabFlavor = fragmentParams.get('activeTab');
          const tabGroup = fragmentParams.get('tabGroup');
          this.activeTabGroup = tabGroup
            ? this.tabGroups.find(group => tabGroup === group.id)
            : this.tabGroups[0];
          this.activeTab = activeTabFlavor
            ? this.activeTabGroup.tabs.find((tab: ContentNavigatorTab) => tab.filterValue === activeTabFlavor)
            : this.activeTabGroup.tabs.filter((tab) => !tab.hide)[0];
          this.isDashboard = this.activeTab?.filterValue === ReportFlavor.DASHBOARD;
          this.mapFilters(queryParams.get('filters'));
        }),
        untilDestroyed(this)
      )
      .subscribe(([routeParams, _,__,]) => {
        this.parametersForAnalysis.emit({
          channelId: routeParams.get('channelId'),
          subscriptionId: this.route.parent.parent.snapshot.paramMap.get('subscriptionId'),
          pagination: this.paginationOptions,
          searchTerm: this.searchTerm,
          flavor: this.activeTab?.filterValue,
          filters: this.selectedFilters,
          raiseTelemetry: this.raiseTelemetry,
        });
        this.isLoading = false;
      });

    this.listenToSidebarState();
  }

  public ngOnDestroy(): void {
    return;
  }

  public onChangeSearchTerms(term: string): void {
    this.telemetryService.setRaiseTelemetryEvent(true);
    this.router.navigate([], {
      queryParams: {
        q: term,
        page: 1,
        filters: '',
      },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  public onItemSelected(channel: DropdownItem): void {
    this.router.navigate([channel.id], { relativeTo: this.route.parent });
  }

  public onChangePaginationOptions(event: PaginationParameters): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        q: this.searchTerm,
        ...event,
      },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  public onClickTab({ tab, tabGroupId }: { tab: ContentNavigatorTab, tabGroupId: string }): void {
    this.router.navigate([], {
      fragment: `activeTab=${tab.filterValue}&tabGroup=${tabGroupId}&sidebar=${this.fragment.get('sidebar')}`,
      queryParams: { page: 1 },
      queryParamsHandling: 'merge',
    });
  }

  public onChangeFilters(event: FiltersChangedEvent): void {
    const filters = FilterHelper.analysisFiltersToURLParams(event.applied);
    this.router.navigate([], {
      queryParams: {
        page: 1,
        filters: JSON.stringify(filters),
      },
      preserveFragment: true,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Changes the fragment with the updated
   * status of the sidebar
   * @param event sidebar status
   */
  onToggleFilters(event: boolean) {
    this.fragment = new URLSearchParams(this.route.snapshot.fragment)
    if (this.fragment.get('sidebar')) {
      this.fragment.set('sidebar', String(!event))
    } else {
      this.fragment = new URLSearchParams(`${this.fragment}&sidebar=${!event}`)
    }
    this.router.navigate([], {
      queryParamsHandling: 'preserve',
      fragment: this.fragment.toString()
    });
  }

  onFocusSearchbar() {
    this.hiddenSearchButton = false;
  }

  onBlurSearchbar(hide: boolean) {
    this.hiddenSearchButton = hide;
  }

  public getBreadcrumbString() {
      return `${this.moduleName} > ${this.subscriptionName} > ${this.currentChannel.name}`;
  }
  /**
   *  Loads the sidebar state from the url
   */
  private listenToSidebarState() {
    this.route.fragment.subscribe((fragment) => {
      const params = new URLSearchParams(fragment);
      this.filtersCollapsed = params.has('sidebar')
        ? params.get('sidebar').toLowerCase() === 'false'
        : window.innerWidth <= TABLET_MAX_WIDTH;
      this.fragment = params;
    });
  }

  private mapFilters(paramFilters: string): void {
    this.selectedFilters = FilterHelper.parseFiltersFromURL(paramFilters);
    if (this.selectedFilters) {
      this.filters = FilterHelper.getSelectedFiltersFromURLParams(this.filters, this.selectedFilters);
    }
  }

  private sortDropdownByName(a: NavigationElement, b: NavigationElement): number {
    const dropdownNameA = a.name.toLowerCase();
    const dropdownNameB = b.name.toLowerCase();
    if (dropdownNameA < dropdownNameB) {
      return -1;
    }
    if (dropdownNameA > dropdownNameB) {
      return 1;
    }
    return 0
  }
}
