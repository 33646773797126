import { AnalysisReportFileType, AnalysisReportMimeType } from '../models/reverse-engineering/analysis.models';

const REPORT = 'Report';
const DATA_TABLE = 'Data Table';
const MODEL = 'Data Model';
const DATA_VISUALIZATION = 'Data Visualization';

export const REPORT_FILE_TYPE = {
  PDF: REPORT,
  XLS: DATA_TABLE,
  XLSX: DATA_TABLE,
  XLSM: MODEL,
} as const;

export const ASSET_FILE_TYPE: {[t in AnalysisReportMimeType]: string} = {
  [AnalysisReportMimeType.PDF]: REPORT,
  [AnalysisReportMimeType.BLOG]: REPORT,
  [AnalysisReportMimeType.XLS]: DATA_TABLE,
  [AnalysisReportMimeType.XLSX]: DATA_TABLE,
  [AnalysisReportMimeType.XLSM]: MODEL,
  [AnalysisReportMimeType.XLSXO]: AnalysisReportFileType.XLSXO,
  [AnalysisReportMimeType.XXLS]: AnalysisReportFileType.XXLS,
  [AnalysisReportMimeType.QUICKSIGHT]: DATA_VISUALIZATION,
  [AnalysisReportMimeType.HYGRAPH]: REPORT,
} as const;
