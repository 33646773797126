<app-market-pdf-viewer
  *ngIf="!showErrorMessage && pdfAssetInfo?.url"
  title="Product Overview"
  [subscriptionName]="subscriptionName"
  [pdfAssetInfo]="pdfAssetInfo"
  [wordpressUrl]="wordpressUrl"
></app-market-pdf-viewer>
<div *ngIf="showErrorMessage" class="error-message">
  No Product Overview available.
</div>
