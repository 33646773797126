import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  ContentNavigatorTab,
  ContentNavigatorTable,
  ContentNavigatorTableRow,
} from '@app/@shared/models/content-navigator/content-navigator.model';

@Component({
  selector: 'app-content-navigator-area',
  templateUrl: './content-navigator-area.component.html',
  styleUrls: [ './content-navigator-area.component.scss' ],
})
export class ContentNavigatorAreaComponent {

  @Input() subscriptionName: string;
  @Input() tabs: Array<ContentNavigatorTab>;
  @Input() activeTab: ContentNavigatorTab;
  @Input() isContentLoading: boolean;
  @Input() table: ContentNavigatorTable;
  @Input() fullTitle: string = null;
  @Input() annotatedReports: Array<string>;

  @Output() clickViewAll: EventEmitter<void> = new EventEmitter<void>();
  @Output() clickTab: EventEmitter<ContentNavigatorTab> = new EventEmitter<ContentNavigatorTab>();
  @Output() clickRow = new EventEmitter<ContentNavigatorTableRow>();
  @Output() clickImageCounter = new EventEmitter<ContentNavigatorTableRow>();
  @Output() clickFileCounter = new EventEmitter<ContentNavigatorTableRow>()

  public onClickViewAll(): void {
    this.clickViewAll.emit();
  }

  public onClickTab(tab: ContentNavigatorTab): void {
    this.clickTab.emit(tab);
  }

  public onClickRow(row: ContentNavigatorTableRow): void {
    this.clickRow.emit(row);
  }

  public onClickFileCounter(row: ContentNavigatorTableRow): void {
    this.clickFileCounter.emit(row);
  }

  public onClickImageCounter(row: ContentNavigatorTableRow) {
    this.clickImageCounter.emit(row);
  }

}
