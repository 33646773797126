<section class="post-header">
  <div *ngIf="prevPage.hasHistory() && prevPage.getPreviousUrl()!='/'" class="back" (click)="onClickBack()">
    <em class="icon-left_small"></em><span class="hide-on-mobile">Back</span>
  </div>
  <div class="title-element">
    <div
      *ngIf="subscription.length > 0"
      class="vertical"
    >{{subscription}}</div>
    <span class="truncate-paragraph-ellipsis line-clamp-2" [innerHTML]="blog.title"></span>
  </div>
</section>
<app-generic-toolbar
  [toolbarOptions]="toolbarOptions"
  [addRightMargin]="true"
  (propagateEvent)="executeToolbarOption($event)">
</app-generic-toolbar>
<section class="scrollbar blog">
  <div class="post-content">
    <!-- TechStream logo -->
    <img
    src="../../../../../assets/images/techstream_light.svg"
    alt="TechStream blog logo"
    class="techstream-logo"
    *ngIf="isTechstreamView"
    />
    <div class="post-cat" *ngIf="isTechstreamView">{{blog.category}}</div>
    <div class="post-info" *ngIf="isTechstreamView">
      <span class="author-info">
        <span class="avatar" [ngStyle]="{'background-image': 'url(' + blog.authorAvatarUrl + ')'}"></span>
        <span class="author" id="blogPostAuthor">{{blog.authorName}}</span>
      </span>
      <span class="date" id="blogPostDate">{{ blog.publicationDate | date: 'MMM d, yyyy' }}</span>
    </div>

    <!-- Post title -->
    <h3
      class="post-title"
      id="blogPostTitle"
      [innerHTML]="blog.title"
      *ngIf="blog && blog.title">
    </h3>
    <div class="container py-4" *ngIf="!isTechstreamView; else techstream">
      <div class="row py-4">
        <div class="col-12 col-md-1"></div>
        <div class="col-12 col-md-12">
          <div class="post-body" [innerHTML]="blog.content | noSanitize"></div>
        </div>
      </div>
    </div>
    <ng-template #techstream>
      <div  class="post-body" [innerHTML]="blog.content | noSanitize"></div>
    </ng-template>
    <div class="post-footer">
      <div  *ngIf="isTechstreamView" class="post-tags">
        <div *ngFor="let tag of blog.tags" class="tag">{{tag}}</div>
      </div>
    </div>
  </div>
</section>
