<div class="modal-close-button" (click)="modal.dismiss()"><em class="icon-close_medium"></em></div>
<div class="title-information">{{dialogOptions.titleInformationLabel}}</div>
<div class="header">{{dialogOptions.heading}}</div>
<div class="content-container">
  <em *ngIf="dialogOptions.titleIconClass" class="icon {{ dialogOptions.titleIconClass }}"></em>
  <div class="content">
    <div *ngIf="dialogOptions.title" class="title" [class.icon]="!!dialogOptions.titleIconClass">{{ dialogOptions.title }}</div>
    <div class="body">{{ dialogOptions.body }}</div>
  </div>
</div>
<div class="footer">
  <button *ngIf="dialogOptions.closeButtonLabel" (click)="modal.dismiss()">
    <em *ngIf="dialogOptions.closeButtonIconClass" class="icon {{ dialogOptions.closeButtonIconClass }}"></em>
    {{ dialogOptions.closeButtonLabel }}
  </button>
  <button *ngIf="dialogOptions.actionButtonLabel" class="primary" (click)="modal.close()">
    <em *ngIf="dialogOptions.actionButtonIconClass" class="icon {{ dialogOptions.actionButtonIconClass }}"></em>
    {{ dialogOptions.actionButtonLabel }}
  </button>
</div>
