import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import FileSaver from 'file-saver';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


export enum DownloadResponse{
  SUCCESS,
  SERVER_ERROR,
  ENTITLEMENT_ERROR
}

@Injectable({
  providedIn: 'root',
})
export class ContentExportService {

  url = environment.metaServiceBaseUrl+'/content/xmlExport';

  constructor(private http: HttpClient) {
  }

  downloadFile(resp: string){
    const blob = new Blob([resp], {type: 'text/plain;charset=utf-8'});
    FileSaver.saveAs(blob, 'data.xml');
  }

  downloadXML(): Observable<DownloadResponse>{
    return this.http.get(this.url, {responseType: 'text'}).pipe(
      map(resp=>{
        this.downloadFile(resp)
        return DownloadResponse.SUCCESS
      }),
      catchError((error: HttpErrorResponse)=>{
        const status = error.status;
        if(status === 403){
          return of(DownloadResponse.ENTITLEMENT_ERROR)
        }
        else{
          return of(DownloadResponse.SERVER_ERROR)
        }
      })
    )
  }
}



