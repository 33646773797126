<!-- this button shows if hierarchy panel is collapsed (copy of the hierarchy panel toggle button)-->
<div class="generic-item-group">
  <div *ngIf="this.toolbarType.showLeftPanel" class="left-panel-container">
    <div *ngIf="!isFullScreen"
          [ngClass]="isLeftPanelOpen ? 'left-panel-button clicked' : 'left-panel-button'"
          (click)="onToggleLeftPanel()">
      <div class="icon-panel"></div>
    </div>
  </div>
  <div
    *ngIf="this.toolbarType.showHierarchyButton && !this.isFullScreen && (isHierarchyPanelCollapsed | async) === true"
    (click)="this.toggleHierarchy($event)"
    ngbTooltip="Collapse"
    placement="bottom"
    class="hierarchy-toggler">
    <img class="material-icons icon collapsed" src="../../../../assets/Sidebar/SidebarOpenIcon.svg" alt="Hierarchy Panel Collapsed" />
  </div>
  <!-- Will hold th metadata toggle button and the title that is the image name -->
  <div *ngIf="this.toolbarType.showMetadataButton" class="metadata-container">
    <span *ngIf="!isFullScreen"
          [ngClass]="(isMetadataPanelCollapsed | async) === true ? 'metadata-button' : 'metadata-button clicked'"
          (click)="toggleMetadata()">
      <div class="icon-info1"></div>
    </span>
  </div>
</div>

<p class="generic-toolbar-title">{{ toolbarTitle }}</p>

<!-- The curr page - total pages display with arrow buttons for navigation -->
<div *ngIf="this.toolbarType.showPagination" class="toolbar-options-group page-options ng-star-inserted">
  <!-- Left control arrow -->
  <div *ngIf="!prevPageOption.disabled"
       [ngbTooltip]="prevPageOption.getTooltip() || ''"
       class="toolbar-option"
       placement="bottom"
       (click)="executeEvent('click', prevPageOption, optionElement)"
       (dblclick)="executeEvent('dblclick', prevPageOption, optionElement)"
       (mouseenter)="executeEvent('mouseenter', prevPageOption, optionElement)"
       (mousedown)="executeEvent('mousedown', prevPageOption, optionElement)"
       (mouseup)="executeEvent('mouseup', prevPageOption, optionElement)"
       #optionElement
  >
    <div [ngClass]="prevPageOption.getCssConfig()"></div>
    <p *ngIf="prevPageOption.visibleName"
       class="toolbar-option-name">
      {{ prevPageOption.visibleName }}
    </p>
  </div>

  <div class="pagination-state"> {{ this.getPaginateState() }}</div>
  <!-- Right Control Arrow -->
  <div *ngIf="!nextPageOption.disabled"
       [ngbTooltip]="nextPageOption.getTooltip() || ''"
       class="toolbar-option"
       placement="bottom"
       (click)="executeEvent('click', nextPageOption, optionElement)"
       (dblclick)="executeEvent('dblclick', nextPageOption, optionElement)"
       (mouseenter)="executeEvent('mouseenter', nextPageOption, optionElement)"
       (mousedown)="executeEvent('mousedown', nextPageOption, optionElement)"
       (mouseup)="executeEvent('mouseup', nextPageOption, optionElement)"
       #optionElement
  >
    <div [ngClass]="nextPageOption.getCssConfig()">
    </div>
    <p *ngIf="nextPageOption.visibleName"
       class="toolbar-option-name">
      {{ nextPageOption.visibleName }}
    </p>
  </div>
</div>

<div *ngIf="toolbarType.showAnnotationOptions" class="toolbar-group page-options annotation-options">
  <div
    class="annotation-control"
    [class.annotation-control-active]="viewOption.stateBehavior.isActive()"
    (click)="executeEvent('click', viewOption, optionElement); annotationOptionGroup.onClickOption(viewOption, optionElement)"
    #optionElement
  >View</div>
  <div
    class="annotation-control"
    [class.annotation-control-active]="annotateOption.stateBehavior.isActive()"
    (click)="executeEvent('click', annotateOption, optionElement); annotationOptionGroup.onClickOption(annotateOption, optionElement)"
    #optionElement
  >Annotate</div>
</div>

<div class="toolbar-options-container" [class.toolbar-margin-right]="addRightMargin">
  <ng-template ngFor let-optionGroup [ngForOf]="toolbarOptions">
    <div class="toolbar-options-group">
      <ng-template ngFor let-option [ngForOf]="optionGroup.getOptions()">
        <div *ngIf="!option.disabled &&  !['next-page', 'prev-page', 'view', 'annotate'].includes(option.identifier)"
             class="toolbar-option"
             [class.toolbar-option-with-text]="option.visibleName"
             placement="bottom"
             [ngbTooltip]="option.getTooltip() || ''"
             [attr._ti_toolbar_option]="option.identifier"
             (click)="executeEvent('click', option, optionElement); optionGroup.onClickOption(option, optionElement)"
             (dblclick)="executeEvent('dblclick', option, optionElement)"
             (mouseenter)="executeEvent('mouseenter', option, optionElement)"
             (mousedown)="executeEvent('mousedown', option, optionElement)"
             (mouseup)="executeEvent('mouseup', option, optionElement)"
             #optionElement
        >
          <p *ngIf="option.visibleName"
             class="toolbar-option-name">
            {{ option.visibleName }}
          </p>
          <div [ngClass]="option.getCssConfig()"></div>
        </div>
        <div *ngIf="option.disabled"
             [ngbTooltip]="option.getTooltip() || ''"
             [class.toolbar-option-with-text]="option.visibleName"
             class="toolbar-option toolbar-option-disabled"
             placement="bottom">
          <p *ngIf="option.visibleName"
             class="toolbar-option-name">
            {{ option.visibleName }}
          </p>
          <div [ngClass]="option.getCssConfig()"></div>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
