import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectOption } from '@app/@shared/models/shared.models';
import { CONTENT_NAVIGATOR_TABS, ContentNavigatorTab, ANALYSIS_TAB_GROUP, TabGroup } from '@shared/models/content-navigator/content-navigator.model';
import { Subscription } from '@shared/models/navigation/navigation.models';
import { FilterHelper } from '@shared/utils/filter-helper';
import { PaginationHelper } from '@shared/utils/pagination-helper';
import { Observable, combineLatest, of } from 'rxjs';
import { concatMap, debounceTime, tap } from 'rxjs/operators';
import { PaginationParameters } from '../../models/pagination';
import { AnalysisFilter, AnalysisFilterFacet, AnalysisParametersEvent, Filters, FiltersChangedEvent } from '../../models/reverse-engineering/analysis.models';
import { PreviousPageService } from '../../services/previous-page.service';
import { TABLET_MAX_WIDTH } from '@app/@shared/consts/breakpoints.consts';
import { TelemetryService } from '@app/@shared/services/telemetry-service';

@Component({
  selector: 'app-analysis-browser',
  templateUrl: './analysis-browser.component.html',
  styleUrls: ['./analysis-browser.component.scss']
})
export class AnalysisBrowserComponent implements OnInit {
  @Input() loading = true;
  @Input() loadingPageChange = false;
  @Input() count = 0;
  @Input() getSubscription:(subscriptionId: string) => Observable<Subscription>;
  @Input() normalTitle: string;
  @Input() titleWithSubscription: string;
  @Input() tabGroups: Array<TabGroup>;
  @Input() sortOptions: SelectOption[];
  @Input() defaultSort: SelectOption;
  @Input() showHighlightToggle = true;
  @Input() moduleName: string;
  @Input() set filters(filters: AnalysisFilter[]) {
    this._filters = filters;
  }
  get filters() {
    return this._filters;
  }

  @Output() parametersForAnalysis = new EventEmitter<AnalysisParametersEvent>();

  pageSizes = [50, 100, 500];

  paginationParameters = new PaginationParameters(50, 1, '');
  subscriptionName = '';
  subscriptionId: string;
  title = '';
  searchTerm = '';
  raiseTelemetry = false;
  urlFilters: Filters = {};
  searchPlaceholder = '';



  activeTab: ContentNavigatorTab = CONTENT_NAVIGATOR_TABS[0];
  activeTabGroup: TabGroup = ANALYSIS_TAB_GROUP;
  filtersCollapsed = false;
  selectedSortOption: SelectOption;
  hiddenSearchButton = true;

  private _filters: AnalysisFilter[] = [];

  constructor(
    public prevPage: PreviousPageService,
    private route: ActivatedRoute,
    private router: Router,
    private telemetryService: TelemetryService
  ) {}

  ngOnInit(): void {
    this.setFilters();
    this.listenToSidebarState();
    this.activeTabGroup = this.tabGroups[0];
    this.selectedSortOption = this.defaultSort;
    this.paginationParameters.sort = this.defaultSort.value;
    this.route.paramMap
      .pipe(
        concatMap((paramMap) => {
          if (paramMap.has('subscriptionId')) {
            return this.getSubscription(paramMap.get('subscriptionId') ?? '');
          } else {
            return of(null);
          }
        })
      )
      .subscribe((result) => {
        if (result === null) {
          this.subscriptionName = 'All Verticals';
          this.title = this.normalTitle;
          this.searchPlaceholder = this.moduleName;
        } else {
          this.subscriptionName = result.name;
          this.title = this.titleWithSubscription ?? this.normalTitle;
          this.searchPlaceholder = result.name;
        }
      });

    // each time when route paramMap, queryParamMap, or fragment changes
    combineLatest([this.route.paramMap, this.route.queryParamMap, this.route.fragment])
      .pipe(
        tap(([, queryParamMap, _]) => {
          this.loadingPageChange = true;
          this.paginationParameters = PaginationHelper.fromParamsOrDefault(queryParamMap,
            { size: 50, page: 1, sort: this.defaultSort.value });
          this.selectedSortOption = this.sortOptions.find(
            x => x.value === this.paginationParameters.sort) ?? this.defaultSort;
          if (queryParamMap.get('q')) {
            this.searchTerm = queryParamMap.getAll('q').map(term => term.trim()).filter(Boolean).join(',');
          } else {
            this.searchTerm = '';
          }
          this.raiseTelemetry = this.telemetryService.getRaiseTelemetryEvent();
          if (queryParamMap.get('filters')) {
            this.urlFilters = FilterHelper.parseFiltersFromURL(queryParamMap.get('filters'))
          } else {
            this.urlFilters = {};
          }
        }),
        tap(([, , fragment]) => {
          const fragmentParams =  new URLSearchParams(fragment);
          const activeTabFlavor = fragmentParams.get('activeTab');
          const tabGroup = fragmentParams.get('tabGroup');
          this.activeTabGroup =  tabGroup? this.tabGroups.find(group =>  tabGroup === group.id) : this.tabGroups[0];
          this.activeTab = activeTabFlavor? this.activeTabGroup.tabs.find((tab) => tab.filterValue === activeTabFlavor)
            : this.activeTabGroup.tabs.find((t) => !t.hide);
        }),
        tap(([paramMap, ,]) => {
          this.subscriptionId = paramMap.has('subscriptionId')
                                    ? paramMap.get('subscriptionId')
                                    : '';

        }),
        debounceTime(0)
      )
      .subscribe(_ => {
        this.parametersForAnalysis.emit({
          subscriptionId: this.subscriptionId,
          pagination: this.paginationParameters,
          searchTerm: this.searchTerm,
          flavor: this.activeTab.filterValue,
          filters: this.urlFilters,
          raiseTelemetry: this.raiseTelemetry
        });
      });
    }

  /**
   * Gets called when the pagination parameters change
   * @param paginationParameters the new pagination parameters
   */
 onChangePaginationOptions(paginationParameters: PaginationParameters) {
  this.router.navigate([], {
    queryParams: { ...paginationParameters },
    queryParamsHandling: 'merge',
    preserveFragment: true,
  });
}

  /**
   * Navigates back
   */
  public onClickBack() {
    this.router.navigateByUrl(this.prevPage.getPreviousUrl());
  }

  /**
   * Gets called when the search term changes
   * @param term the new search term
   */
  onChangeSearchTerms(term: string[]) {
    this.searchTerm = term.join(',');
    this.telemetryService.setRaiseTelemetryEvent(true);
    this.router.navigate([], {
      queryParams: { q: this.searchTerm, page: 1, filters: '' },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  /**
   * Changes the query params to add the new filters
   * @param event selected filters
   */
  onChangeFilters(event: FiltersChangedEvent) {
    const filters = FilterHelper.analysisFiltersToURLParams(event.applied);
    this.router.navigate([], {
      queryParams: { page: 1, filters: JSON.stringify(filters) },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  onClickTab({ tab, tabGroupId }: {tab: ContentNavigatorTab, tabGroupId: string}) {
    this.router.navigate([], {
      fragment: `activeTab=${tab.filterValue}&tabGroup=${tabGroupId}`,
      queryParams: { page: 1 },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Changes the fragment with the updated
   * status of the sidebar
   * @param event sidebar status
   */
  onToggleFilters(event: boolean) {
    this.router.navigate([], {
      queryParamsHandling: 'preserve',
      fragment: `sidebar=${!event}`,
    });
  }

  /**
   * Replaces some filter labels.
   * @param filters List of filters whose labels could be replaced
   */
  replaceFilterLabels(filters: AnalysisFilter[], fixedFilterLabels: {
    field: string;
    oldLabel: string;
    newLabel: string;
  }[]) {
    for (const label of fixedFilterLabels) {
      const selectedField = filters.find(f => f.field === label.field);
      const selectedfilter = selectedField?.options.find(f => f.label === label.oldLabel);

      if(selectedfilter) {
        selectedfilter.label = label.newLabel;
        selectedField.options.sort(
          (a: AnalysisFilterFacet, b: AnalysisFilterFacet) => FilterHelper.sortFilters(a, b, selectedField.field)
        )
      }
    }
  }

  onFocusSearchbar() {
    this.hiddenSearchButton = false;
  }

  onBlurSearchbar(chipsAmount: number) {
    if(chipsAmount === 0) {
      this.hiddenSearchButton = true;
    }
  }



  /**
   * Sets the filters from the URL and backend query
   */
  private setFilters() {
    combineLatest([
      this.route.queryParamMap,
      this.route.paramMap,
    ]).subscribe(([queryParamMap, paramMap]) => {
      if (queryParamMap.has('filters')) {
        this.filters = FilterHelper.getSelectedFiltersFromURLParams(
          this.filters,
          FilterHelper.parseFiltersFromURL(queryParamMap.get('filters'))
        );
      }
      if (paramMap.has('subscriptionId')) {
        this.filters = this.filters.filter((x) => x.field !== 'subscriptionName');
      }

      this.replaceFilterLabels(this.filters, FilterHelper.FIXED_FILTER_LABELS);
    });
  }

  /**
   *  Loads the sidebar state from the url
   */
  private listenToSidebarState() {
    this.route.fragment.subscribe((fragment) => {
      const params = new URLSearchParams(fragment);
      this.filtersCollapsed = params.has('sidebar')
        ? params.get('sidebar').toLowerCase() === 'false'
        : window.innerWidth <= TABLET_MAX_WIDTH;
    });
  }
}
