import { PopupModel } from '@app/store/Models/notification/notificationModel';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, Profile } from '@app/auth';
import { untilDestroyed } from '@core';
import { UserService } from '@app/@shared/services/user/user.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SelectOption } from '@app/@shared/models/shared.models';
import { selectUserProfile } from '@app/store/Selectors/workspace/workspace.selectors';
import { Store } from '@ngrx/store';
import {forkJoin} from 'rxjs';
import {concatMap, first} from 'rxjs/operators';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder} from '@angular/forms';
import {IInputProps} from '@shared/components/input/input.component';
import {
  requiredIfUsIsSelected, requiredIfDepartmentOther,
} from '@app/shell/components/onboarding-modal/validators/form-validators.helper';
import {VendorIntegrationService} from '@shared/services/vendor-integration.service';

export const NONE_LABEL = '--None--';
export const US_CODE = 'United States';
export const OTHER_JOB_LABEL = 'Other (with input)';

export const OTHER = 'Other';

/**
 * Component for the Onboarding Popup
 */
@Component({
  selector: 'app-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
})

@UntilDestroy()
export class OnboardingModalComponent implements OnInit{
  @Input() props: Partial<PopupModel>;

  countries: Array<SelectOption>;
  states: Array<SelectOption>;
  departments: Array<SelectOption>;
  titleLevels: Array<SelectOption>;
  initialSelectionOption: SelectOption = { label: NONE_LABEL, value: NONE_LABEL };

  // props for the input fields
  onboardingForm: UntypedFormGroup;
  firstNameProps: IInputProps;
  lastNameProps: IInputProps;
  companyProps: IInputProps;
  countriesProps: IInputProps;
  stateProps: IInputProps;
  departmentsProps: IInputProps;
  otherDepartmentsProps: IInputProps;
  titleLevelsProps: IInputProps;

  isSigning = false;
  profile: any;
  public readonly OTHER_JOB_FUNCTION = OTHER_JOB_LABEL;
  readonly OTHER = OTHER;
  readonly US_CODE = US_CODE;
  private readonly userProfile$ = this.store.select(selectUserProfile);
  constructor(
    public modal: NgbActiveModal,
    private authService: AuthenticationService,
    private userService: UserService,
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private vendorIntegrationService: VendorIntegrationService
  ) {}

  ngOnInit(): void {
    this.initializeInputProps();
    const observableArray = [
      this.userService.getCountries(),
      this.userService.getStates(),
      this.userService.getDepartments(),
      this.userService.getTitleLevels(),
    ];

    forkJoin(observableArray).pipe(untilDestroyed(this))
      .subscribe(([countries, states, { payload: departments }, { payload: titleLevels }]: any ) => {
      this.countries = countries;
      this.states = states;
      this.departments = this.buildTitleLevelSelectOptions(departments);
      this.titleLevels = this.buildTitleLevelSelectOptions(titleLevels);

      this.onboardingForm = this.formBuilder.group({
        eulaChecked: new UntypedFormControl(null, Validators.requiredTrue),
        ppChecked: new UntypedFormControl(null, Validators.requiredTrue),
        firstName: new UntypedFormControl(null, Validators.required),
        lastName: new UntypedFormControl(null, Validators.required),
        company: new UntypedFormControl( null, Validators.required),
        countries: new UntypedFormControl(null, Validators.required),
        state: new UntypedFormControl(null, requiredIfUsIsSelected),
        departments: new UntypedFormControl(null, Validators.required),
        titleLevels: new UntypedFormControl(null, Validators.required),
        otherDepartment: new UntypedFormControl(null, requiredIfDepartmentOther),
      });
    });
  }

  onChangeOptionCountry(option: SelectOption) {
    this.onboardingForm.controls.countries.setValue(option?.label);
    this.onboardingForm.controls.state.reset(null);
  }

  onChangeOptionState(option: SelectOption) {
    this.onboardingForm.controls.state.setValue(option?.label);
  }

  onChangeDepartmentFunction(option: SelectOption) {
    this.onboardingForm.controls.departments.setValue(option?.value);
  }

  onChangeTitleLevelFunction(option: SelectOption) {
    this.onboardingForm.controls.titleLevels.setValue(option?.value);
  }

  onAcceptEULA() {
    this.isSigning = true;
    // update user profile
    this.userProfile$.pipe(
      concatMap(userProfile => {
        const updatedProfile = {
          ...userProfile,
          country: this.onboardingForm.controls.countries?.value,
          state: this.onboardingForm.controls.state?.value,
          company: this.onboardingForm.controls.company?.value,
          firstName: this.onboardingForm.controls.firstName?.value,
          lastName: this.onboardingForm.controls.lastName?.value,
          department: this.onboardingForm.controls.departments?.value,
          titleLevel: this.onboardingForm.controls.titleLevels?.value,
          otherDepartment: this.onboardingForm.controls.otherDepartment?.value,
        }
        const profile = new Profile(updatedProfile);
        return this.authService.updateProfile(profile);
      }),
      first()
    ).subscribe(() => {
      // TODO: Refactor this nested Observable subscription
      this.authService.signEULA(this.props.body).pipe(
        untilDestroyed(this),
      ).subscribe(() => {
        this.modal.close('Form is submitted');
        this.vendorIntegrationService.initialize();
      });
    });
  }

  onDeclineEULA() {
    this.modal.dismiss();
    this.authService.logout().subscribe();
  }

  onBlurControls(controlName: string, isSelect: boolean) {
    this.onboardingForm.controls[controlName].markAsTouched();
    const label = this[`${controlName}Props`].label.replace('*', '').replace('is required', '').trim();
    this[`${controlName}Props`] = this.buildInputProps(
      label,
      this.onboardingForm.controls[controlName],
      isSelect
    );
  }

  onBlurSelects(controlName: string, isSelect: boolean) {
    const label = this[`${controlName}Props`].label.replace('*', '').replace('is required', '').trim();
    this[`${controlName}Props`] = this.buildInputProps(
        label,
        this.onboardingForm.controls[controlName],
        isSelect,
    );
  }

  private buildInputProps(
    label: string,
    control: AbstractControl,
    isSelect: boolean = false,
    disabled?: boolean
  ): IInputProps {
    const isLabelRequiredErrorShown = !!control.errors?.required && control.touched;
    return {
      class: 'form-control',
      label: isLabelRequiredErrorShown ? `${label} is required *` : `${label}`,
      placeholder: !isSelect ? label : NONE_LABEL,
      disabled: disabled ?? false,
    };
  }

  private initializeInputProps() {
    this.firstNameProps = this.setDefaultInputProps('First Name');
    this.lastNameProps = this.setDefaultInputProps('LastName');
    this.countriesProps = this.setDefaultInputProps('Country');
    this.companyProps = this.setDefaultInputProps('Company');
    this.stateProps = this.setDefaultInputProps('State');
    this.departmentsProps = this.setDefaultInputProps('Department');
    this.titleLevelsProps = this.setDefaultInputProps('Title Level');
    this.otherDepartmentsProps = this.setDefaultInputProps('Other Department');
  }

  private setDefaultInputProps(label: string) {
    return {
      class: 'form-control',
      label:  `${label}*`,
      placeholder: label,
      disabled: false,
    }
  }

  private buildTitleLevelSelectOptions(list: Array<{ name: string, value: string }>): Array<SelectOption> {
    return list.map(({ name, value }) => ({ label: name, value }));
  }
}
