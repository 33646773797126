import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PdfAssetInfo } from '@app/@shared/models/pdf-asset-info';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';
import { MetaService } from '@app/@shared/services/meta.service';
import { ReportService } from '@app/@shared/services/report.service';
import { AssetModel } from '@app/@shared/models/asset.model';
import { Analysis, AnalysisReportMimeType } from '@app/@shared/models/reverse-engineering/analysis.models';
import { moduleNameFromUrl } from '@app/@shared/utils/common';
import { TelemetryEvent } from '@app/@shared/models/telemetry-event';
import { TelemetryEventName } from '@app/@shared/enums/telemetry-event-name';
import { RecentViewHistoryService } from '@app/@shared/services/recent-view-history/recent-view-history.service';
import {
  UserViewHistoryItemSource,
  UserViewHistoryItemType,
} from '@app/@shared/models/user-view-history/user-view-history-items';
import { DeepLinkingService } from '@app/@shared/services/deep-linking.service';

@UntilDestroy()
@Component({
  selector: 'app-document-pdf-loader',
  templateUrl: './document-pdf-loader.component.html',
  styleUrls: [ './document-pdf-loader.component.scss' ],
})
export class DocumentPdfLoaderComponent {

  @Input() set analysisCode(code: string) {
    this.getCurrentAnalysis(code);
  }
  @Input() reportId: string;
  @Input() channelId: string;
  @Input() subscriptionId: string;
  @Input() isAnnotated = false;

  @Output() closePdfViewer = new EventEmitter<void>();

  public pdfAssetInfo: PdfAssetInfo;
  public showDownloadButton = false;

  constructor(
    private entitlementService: EntitlementService,
    private metaService: MetaService,
    private reportService: ReportService,
    private router: Router,
    private viewHistoryService: RecentViewHistoryService,
    private deepLinkingService: DeepLinkingService,
  ) {}

  getCurrentAnalysis(analysisCode: string): void {
    if (analysisCode) {
      this.metaService.getReportAndTopAssetGroupByCode(analysisCode).pipe(
        map(({ report }) => report),
        concatMap((report) => forkJoin([
          this.reportService.getPDFReportURL(this.reportId),
          this.entitlementService.getReportEntitlements(report.id),
          this.metaService.getAssetFromClId(this.reportId),
          of(report),
        ])),
        untilDestroyed(this),
      ).subscribe(
        ([ pdfAssetInfo, {download_enabled}, asset, report ]) => {
          this.pdfAssetInfo = pdfAssetInfo;
          this.showDownloadButton = download_enabled.pdf;
          this.trackPDFViewEvent(asset, report, this.subscriptionId, this.channelId);
          this.sendViewHistoryEvent(asset, report);
        },
        (err: any) => {
          if (err.status === 403) {
            this.deepLinkingService.redirectTo403();
            return of(null);
          }
        }
      );
    }
  }

  downloadPdf(): void {
    this.reportService.downloadPdf(this.pdfAssetInfo).subscribe();
  }

  onClosePdfViewer(): void {
    this.closePdfViewer.emit();
  }

  private trackPDFViewEvent(asset: AssetModel, report: Analysis, subscriptionId: string, channelId: string): void {
    const payload: any = {
      artifactName: asset.name,
      artifactType: AnalysisReportMimeType.PDF,
      channelId,
      downloadSizeBytes: 0,
      reportCode: report.code,
      reportId: asset.id,
      reportTitle: report.name,
      subscriptionId,
    };
    const applicationModule = moduleNameFromUrl(this.router.url);
    const event = new TelemetryEvent(TelemetryEventName.EVENT_VIEW_IN_BROWSER, payload, applicationModule);
    this.metaService.postTelemetryEvent(event).subscribe();
  }

  private sendViewHistoryEvent(asset: AssetModel, report: Analysis): void {
    const moduleId = window.location.toString().split('/')[3].trim();
    const channelId = window.location.toString().split('/')[4].trim();
    this.viewHistoryService.postRecentViewHistory({
      itemName: asset.name.substring(0, asset.name.lastIndexOf('.')),
      itemType: UserViewHistoryItemType.PDF,
      itemSource: UserViewHistoryItemSource.META,
      itemId: asset.id,
      itemPublicationDate: report.analysisEndDate ? new Date(report.analysisEndDate) : null,
      channelSfId: channelId,
      subscriptionSfId: this.subscriptionId,
      moduleId,
      clObjectId: asset.contentLakeObjectId,
      payload: {
        reportId: report.id,
        reportCode: report.code,
        isAnnotated: this.isAnnotated,
        container: 'analysis-view',
      },
    }).pipe(untilDestroyed(this)).subscribe();
  }

}
