import { Component, ElementRef, ViewChild } from '@angular/core';
import { PreviousPageService } from '@shared/services/previous-page.service';
import { HygraphService } from '@shared/services/hygraph/hygraph.service';
import { ActivatedRoute } from '@angular/router';
import { HTMLReport } from '@shared/services/hygraph/hygraph.models';
import { MetaService } from '@app/@shared/services/meta.service';
import { TelemetryEvent } from '@app/@shared/models/telemetry-event';
import { TelemetryEventName } from '@app/@shared/enums';
import { combineLatest } from 'rxjs';
import { MarketAnalysisService } from '@app/@shared/services/market-analysis/market-analysis.service';
import { OptionGroup, PropagationEvent, SocialOption } from '../../generic-toolbar/option-model';
import { StatelessBehavior } from '../../generic-toolbar/state-behavior-model';
import { Icon } from '../../generic-toolbar/icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EntitlementService } from '@app/@shared/services/entitlement/entitlement.service';
import { concatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectUserProfile } from '@app/store/Selectors/workspace/workspace.selectors';
import { IUserProfile } from '@app/store/Models/settings/settingsModel';
import { FeatureFlagService } from '@app/@shared/services/featureflag.service';
import { environment } from '@env/environment';

@UntilDestroy()
@Component({
  selector: 'app-hygraph-viewer',
  templateUrl: './hygraph-viewer.component.html',
  styleUrls: ['./hygraph-viewer.component.scss']
})
export class HygraphViewerComponent {
  @ViewChild('hygraph', { static: true, read: ElementRef }) hygraphElement: ElementRef;
  htmlReport: HTMLReport;
  analysisCode: string;
  assetId: string;
  moduleName: string;
  reportName: string;
  subscriptionId: string;
  channelId: string;
  showDownloadButton = false;
  toolbarOptions: OptionGroup[];
  enableDownloadButton$ = this.featureFlagService.getFlag(environment.enableDownloadHygraphReports)

  private eventToMethod = {
    download: 'download',
  };
  private userProfile$ = this.store.select(selectUserProfile);

  constructor(
    public prevPage: PreviousPageService,
    private hygraphService: HygraphService,
    private route: ActivatedRoute,
    private metaService: MetaService,
    private marketAnalysisService: MarketAnalysisService,
    private entitlementService: EntitlementService,
    private store: Store,
    private featureFlagService: FeatureFlagService
  ) { }

  ngOnInit(): void {
    this.toolbarOptions = this.createToolbarOptions();
    combineLatest([this.route.fragment, this.route.params]).subscribe(([fragment, parameters]) => {
      const params = new URLSearchParams(fragment);
      this.assetId = parameters.reportId;
      this.moduleName = params.get('moduleName');
      this.analysisCode = params.get('reportCode');
      this.subscriptionId = params.get('subscriptionId');
      this.channelId = params.get('channelId');
      this.fetchData(this.assetId);
    });
  }

  public onClickBack() {
    this.prevPage.navigateBack();
  }

  public executeToolbarOption(event: PropagationEvent): void {
    if (event.key in this.eventToMethod) {
      const methodName = this.eventToMethod[event.key];
      this[methodName](event);
    }
  }

  public download(){
    this.userProfile$
    .pipe(untilDestroyed(this))
    .subscribe((profile: IUserProfile) => {

      const newWindow = window.open();
      // creating a new html node
      const html = document.createElement('html');

      // We can load the CSS by cloning the document head
      const head = document.head.cloneNode(true);

      // creating a new body element for our newWindow
      const body = document.createElement('body');
      const data = this.hygraphElement.nativeElement.cloneNode(true);
      const waterMark = document.createElement('div');
      waterMark.innerText = 'Downloaded by TechInsights subscriber ' +
                            `${profile.firstName} ${profile.lastName},`+
                            ` ${profile.email}`;

      body.appendChild(data).appendChild(waterMark);
      html.appendChild(head);
      html.appendChild(body);

      // write content to the new window's document.
      newWindow.document.write(html.innerHTML);

      // close document to stop writing
      newWindow.document.close();

      // print content in new window as PDF
      newWindow.print();

      // Send telemetry event and close the new window after printing
      newWindow.addEventListener('afterprint',() =>{
        this.postTelemetryEventForHTMLDownload();
        newWindow.close()
      })
    })
  }

  private createToolbarOptions(): OptionGroup[] {
    const optionGroups: OptionGroup[] = [];
    const options: SocialOption[] = [];

    options.push(
      new SocialOption('download', [{ name: 'click', key: 'download' }], new StatelessBehavior(Icon.REPORT_VIEWER_DOWNLOAD, 'Download'))
    );
    optionGroups.push(new OptionGroup(options));

    return optionGroups;
  }

  private fetchData(assetId: string): void {
    combineLatest([
      this.hygraphService.getReport(assetId),
      this.marketAnalysisService.getAnalysisMetadata(this.analysisCode),
    ]).pipe(
      concatMap(([hygraphResp, {report}]) => {
        this.htmlReport = hygraphResp;
        this.reportName = report.name;
        this.postTelemetryEventForHygraph();
        return this.entitlementService.getReportEntitlements(report.id)
      }),
      untilDestroyed(this)
    ).subscribe(({download_enabled}) => {
      this.showDownloadButton = download_enabled.pdf;
    });
  }

  private postTelemetryEventForHygraph() {
    const payload = {
      artifactName: `${this.htmlReport.name}.hg`,
      artifactType: 'application/hygraph',
      channelId: this.channelId,
      subscriptionId: this.subscriptionId,
      reportCode: this.analysisCode,
      reportId: this.assetId,
      reportTitle: this.reportName,
    }
    const event = new TelemetryEvent(TelemetryEventName.EVENT_HG_ASSET_VIEW, payload, this.moduleName);
    this.metaService.postTelemetryEvent(event).subscribe();
  }

  private postTelemetryEventForHTMLDownload() {
    const payload = {
      artifactName: `${this.htmlReport.name}.hg`,
      artifactType: 'application/hygraph',
      channelId: this.channelId,
      subscriptionId: this.subscriptionId,
      reportCode: this.analysisCode,
      reportId: this.assetId,
      reportTitle: this.reportName,
      downloadSizeBytes: 0
    }
    const event = new TelemetryEvent(TelemetryEventName.EVENT_HG_ASSET_DOWNLOAD, payload, this.moduleName);
    this.metaService.postTelemetryEvent(event).subscribe();
  }

}
