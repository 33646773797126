import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { IChips } from '@app/tech-library/components/search-bar/search-input/search-input.component';

/**
 * Component for the search chips
 */
@Component(
  {
    selector: 'app-search-chip',
    templateUrl: './search-chip.component.html',
    styleUrls: ['./search-chip.component.scss']
  }
)
export class SearchChipComponent {
  @Input() info: IChips;
  @Output() removed: EventEmitter<IChips> = new EventEmitter<IChips>();
  @Output() updated: EventEmitter<IChips> = new EventEmitter<IChips>();
  @Output() edited: EventEmitter<IChips> = new EventEmitter<IChips>();


  /**
   * Removes chip entirely
   */
  remove() {
    this.removed.emit(this.info);
  }

  edit($event: any) {
    $event.preventDefault();
    this.info.editNewValue = document.getElementById($event.target.id).innerText;
    this.edited.emit(this.info);
  }

  /**
   * Checks if the chip is a compound chip
   * @param values  chip object
   */
  isArray(values: any) {
    return Array.isArray(values)
  }

  /**
   * If the chip is a compound chip (ex: a filter with two subtipes like "manufacturer: Sony OR Samsung")
   * removes the clicked subtype.
   * @param index index of the changed chip
   */
  onChange(index: number) {
    if(!Array.isArray(this.info.values) || this.info.values.length === 0) {
      this.remove();
    } else {
      this.info.values.splice(index, 1);
      this.updated.emit(this.info);
    }
  }
}
