import { Filters, AnalysisFilter, AnalysisFilterFacet, Facet, AnalysisFilterGroup, AnalysisFilterType, AnalysisFilterKey, AnalysisFilterField, UnifiedFilterKey } from '@shared/models/reverse-engineering/analysis.models';
import { ReportFlavor } from '../models/content-navigator/content-navigator.model';
import { NavigationElement } from '../models/navigation/navigation.models';
import { CTIContentType, CTISearchFilter } from '../models/search/cti-search.model';
import { ParamMap, Params } from '@angular/router';

export class FilterHelper {
  static readonly ORDER_NUMBER_ASCENDING = ['analysisYear', 'yearOfAnalysis', 'publishedYear'];
  static readonly FIXED_FILTER_LABELS = [
    {
      field: 'subscriptionName',
      oldLabel: 'Mobile RF',
      newLabel: 'Wireless',
    },
    {
      field: 'subscriptionName',
      oldLabel: 'Teardown',
      newLabel: 'Consumer Electronics',
    },
  ];
  static readonly SIMPLE_FILTERS_FIELD_NAME = ['entitlementState'];
  static readonly SUBSCRIPTION_FIELD = 'subscriptionName';
  static readonly CHANNEL_FIELD = 'channel';
  static readonly MANUFACTURER_FIELD = 'deviceManufacturer';

  static readonly ANALYSIS_FILTERS: { [key in AnalysisFilterKey]: AnalysisFilterField } = {
    [AnalysisFilterKey.InMySubscription]:{
      id: '1',
      label: 'My Activity',
      field: 'inMySubscription',
    },
    [AnalysisFilterKey.ContentStatus]:{
      id: '2',
      label: 'Content Status',
      field: 'contentStatus',
    },
    [AnalysisFilterKey.ContentCategory]:{
      id: '3',
      label: 'Content Category',
      field: 'contentCategory',
    },
    [AnalysisFilterKey.Subscription]: {
      id: '4',
      label: 'Subscription',
      field: 'subscriptionId',
    },
    [AnalysisFilterKey.Channel]: {
      id: '5',
      label: 'Channel',
      field: 'channelId',
    },
    [AnalysisFilterKey.AnalysisType]: {
      id: '6',
      label: 'Analysis Type',
      field: 'analysisType',
    },
    [AnalysisFilterKey.Manufacturer]: {
      id: '7',
      label: 'Manufacturer',
      field: 'deviceManufacturer',
    },
    [AnalysisFilterKey.DeviceType]: {
      id: '8',
      label: 'Device Type',
      field: 'deviceType',
    },
    [AnalysisFilterKey.YearOfAnalysis]: {
      id: '9',
      label: 'Year of Analysis',
      field: 'yearOfAnalysis',
    },
    [AnalysisFilterKey.AuthorName]: {
      id: '10',
      label: 'Author Name',
      field: 'authorName',
    },
    [AnalysisFilterKey.PublishedYear]: {
      id: '11',
      label: 'Published Year',
      field: 'publishedYear',
    },
  };

  static readonly UNIFIED_FILTERS: { [key in UnifiedFilterKey]: AnalysisFilterField } = {
    [UnifiedFilterKey.InMySubscription]:{
      id: '1',
      label: 'My Activity',
      field: 'inMySubscription',
    },
    [UnifiedFilterKey.ContentStatus]:{
      id: '2',
      label: 'Content Status',
      field: 'contentStatus',
    },
    [UnifiedFilterKey.ContentCategory]:{
      id: '3',
      label: 'Analysis Category',
      field: 'contentCategory',
    },
    [UnifiedFilterKey.Subscription]: {
      id: '5',
      label: 'Subscription',
      field: 'subscriptionId',
    },
    [UnifiedFilterKey.Channel]: {
      id: '6',
      label: 'Channel',
      field: 'channelId',
    },
    [UnifiedFilterKey.AnalysisType]: {
      id: '7',
      label: 'Analysis Type',
      field: 'analysisType',
    },
    [UnifiedFilterKey.Manufacturer]: {
      id: '9',
      label: 'Manufacturer',
      field: 'deviceManufacturer',
    },
    [UnifiedFilterKey.DeviceType]: {
      id: '10',
      label: 'Device Type',
      field: 'deviceType',
    },
    [UnifiedFilterKey.YearOfAnalysis]: {
      id: '11',
      label: 'Analysis Published Year',
      field: 'yearOfAnalysis',
    },
    [UnifiedFilterKey.AuthorName]: {
      id: '8',
      label: 'Blog Author Name',
      field: 'authorName',
    },
    [UnifiedFilterKey.PublishedYear]: {
      id: '12',
      label: 'Blog and Video Published Year',
      field: 'publishedYear',
    },
    [UnifiedFilterKey.ContentType]: {
      id: '4',
      label: 'Content Type',
      field: 'contentTypes',
    },
  };

  static readonly CONTENT_TYPE_FILTER_MAP: { [key in CTIContentType]: string } = {
    [CTIContentType.REPORT]: 'Analysis',
    [CTIContentType.BLOG]: 'Blogs',
    [CTIContentType.VIDEO]: 'Videos',
  };

  static filtersToBEParams(filters: Filters) {
    return Object.entries(filters)
      .map(([key, value]) => {
        if (typeof value === 'string') {
          return `${key}=${value}`;
        }

        return `${key}=(${value.join(',')})`;
      })
      .join(',');
  }

  static analysisFiltersToURLParams(filters: AnalysisFilter[]) {
    const filtersArray = filters.map((currentFilter) => {
      if (currentFilter.type === AnalysisFilterType.Group) {
        const options: Array<AnalysisFilterFacet> = [].concat(...currentFilter.groups);
        return {
          [currentFilter.field]: options.map((option) => option.value),
        };
      }
      return {
        [currentFilter.field]: currentFilter.options.map((option) => option.value),
      };
    });
    return Object.assign({}, ...filtersArray);
  }

  static getSelectedFiltersFromURLParams(filters: AnalysisFilter[], selectedFilters: Filters) {
    const newFilters = [...filters];
    const selFilters = Object.entries(selectedFilters);
    if (selFilters && selFilters.length > 0) {
      selFilters.forEach(([fieldName, selectedValues]) => {
        const currentFilter = newFilters.find((x) => x.field === fieldName);
        currentFilter?.options.forEach((option) => {
          option.selected = selectedValues.includes(option.value);
        });
        currentFilter?.groups?.forEach((group) => {
          group.options.forEach((option) => {
            option.selected = selectedValues.includes(option.value);
          });
        });
      });
    } else {
      newFilters.forEach((filter) => {
        filter.options.forEach((option) => {
          option.selected = false;
        });
        filter.groups?.forEach((group) => {
          group.options.forEach((option) => {
            option.selected = false;
          });
        });
      });
    }
    return filters;
  }

  static sortFilters(a: AnalysisFilterFacet, b: AnalysisFilterFacet, fieldName: string) {
    if (FilterHelper.ORDER_NUMBER_ASCENDING.includes(fieldName)) {
      return FilterHelper.sortByValueDescending(a, b);
    } else if (a.label === '[blank]') {
      return 1;
    } else if (b.label === '[blank]') {
      return -1;
    } else {
      return a.label.localeCompare(b.label);
    }
  }

  static sortByValueAscending(a: AnalysisFilterFacet, b: AnalysisFilterFacet) {
    const aNumber = parseInt(a.value, 10);
    const bNumber = parseInt(b.value, 10);
    if (!isNaN(aNumber) && isNaN(bNumber)) {
      return -1;
    }
    if (isNaN(aNumber) && !isNaN(bNumber)) {
      return 1;
    }
    if (isNaN(aNumber) && isNaN(bNumber)) {
      return a.value.localeCompare(b.value);
    }
    return aNumber - bNumber;
  }

  static sortByValueDescending(a: AnalysisFilterFacet, b: AnalysisFilterFacet) {
    const aNumber = parseInt(a.value, 10);
    const bNumber = parseInt(b.value, 10);
    if (!isNaN(bNumber) && isNaN(aNumber)) {
      return -1;
    }
    if (isNaN(bNumber) && !isNaN(aNumber)) {
      return 1;
    }
    if (isNaN(bNumber) && isNaN(aNumber)) {
      return b.value.localeCompare(a.value);
    }
    return bNumber - aNumber;
  }

  static parseFiltersFromParams(queryParams: ParamMap): Filters {
    return JSON.parse(queryParams.get('filters') ?? '{}');
  }

  static parseFiltersFromURL(filters: string): Filters {
    try {
      return JSON.parse(filters) ?? {};
    } catch (e) {
      return {};
    }
  }

  static rejectUTMParams(): Params {
    return {'utm_medium': null, 'utm_source': null, 'utm_campaign': null, 'utm_date': null, 'utm_id': null};
  }

  static buildFilter(
    f: any,
    subscriptions: NavigationElement[]
  ): AnalysisFilter {
    let type = this.SIMPLE_FILTERS_FIELD_NAME.includes(f.fieldName)
      ? AnalysisFilterType.Simple
      : AnalysisFilterType.List;
    let groups: Array<AnalysisFilterGroup>;
    if (f.fieldName === this.SUBSCRIPTION_FIELD) {
      const subscriptionlIds = subscriptions.map((subscription) => subscription.id);
      f.facets = f.facets.filter((facet: any) => subscriptionlIds.includes(facet.value));
    } else if (f.fieldName === this.CHANNEL_FIELD) {
      groups = this.groupChannelFiltersBySubscription(subscriptions, f.facets, f.fieldName);
      type = AnalysisFilterType.Group;
      const channelIds = [].concat(...subscriptions.map(({ children }) => children.map(({ id }) => id)));
      f.facets = f.facets.filter((facet: any) => channelIds.includes(facet.value));
    }
    return {
      id: f.id,
      label: f.displayName,
      field: f.fieldName,
      type,
      expanded: false,
      options: this.buildAnalysisFilterFacets(f.facets, f.fieldName),
      ...(groups && { groups }),
    };
  }

  static buildBlogSearchFilter(
    {subscriptionName, subscriptionId, ...filters}: Filters,
    subscriptionIds: string[],
  ) {
    return { ...filters, subscriptionName: subscriptionName ?? subscriptionId ?? subscriptionIds };
  }

  static buildVideoSearchFilter(
    {channelName, channelId, ...filters}: Filters,
    channelIds: string[],
  ) {
    return {
      ...filters,
      channel: channelName ?? channelId ?? channelIds,
    };
  }

  static buildAnalysisFilterFacets(facets: Array<Facet>, facetFieldName: string): Array<AnalysisFilterFacet> {
    return facets
      .map((facet) => ({
        id: facet.id,
        selected: false,
        label: facet.displayName === '' ? '[blank]' : facet.displayName,
        value: facetFieldName === this.MANUFACTURER_FIELD ? facet.displayName : facet.value,
      }))
      .filter((facet) => facet.label !== undefined)
      .sort((a: AnalysisFilterFacet, b: AnalysisFilterFacet) => FilterHelper.sortFilters(a, b, facetFieldName));
  }

  static groupChannelFiltersBySubscription(
    subscriptions: NavigationElement[],
    channelFacets: Array<Facet>,
    facetFieldName: string
  ): Array<AnalysisFilterGroup> {
    /**
     * Merging identical Subscriptons that are in different modules with different childeren together
     * (e.g Automotive in RE and MA)
     */
    const uniqueSubscriptions = subscriptions.reduce((uniqueSubs, currSub) => {
      const indexFound = uniqueSubs.findIndex((sub) => sub.id === currSub.id)
      if(indexFound !== -1){
        uniqueSubs[indexFound].children = [
          ...uniqueSubs[indexFound].children,
          ...currSub.children
        ]
        return uniqueSubs
      } else {
        return [...uniqueSubs, currSub]
      }
    },[])

    const groups = uniqueSubscriptions.map(({ children: subscriptionChannels, name: subscriptionName }) => {
      const subscriptionChannelIds = [].concat(...subscriptionChannels.map((sub: any) => sub.id));
      const subscriptionChannelFacets = channelFacets.filter(({ value }) => subscriptionChannelIds.includes(value));
      return {
        label: subscriptionName,
        options: this.buildAnalysisFilterFacets(subscriptionChannelFacets, facetFieldName),
      };
    });
    return [].concat(...groups).filter(({ options }) => options.length > 0);
  }

  static mapFiltersToCTIFilters({
    type,
    channel,
    subscriptionName,
    flavor,
    strategyAnalyticsFlavor,
    analysisYear,
    inMySubscription,
    ...filters
  }: Filters): CTISearchFilter {
    let flavors: string[] = [];
    let isMarketData;

    if (flavor) {
      flavors = flavor as string[];
    }

    if (strategyAnalyticsFlavor?.includes(ReportFlavor.SAMPLE)) {
      flavors = strategyAnalyticsFlavor as string[];
    }

    if (strategyAnalyticsFlavor?.includes(ReportFlavor.MARKET_DATA)) {
      isMarketData = true;
    } else if (strategyAnalyticsFlavor?.includes(ReportFlavor.REPORTS)) {
      isMarketData = false;
    }

    return {
      analysisType: type,
      channelId: channel,
      subscriptionId: subscriptionName,
      flavor: flavors,
      yearOfAnalysis: analysisYear,
      isMarketData,
      inMySubscription: inMySubscription?.toString(),
      ...filters
    };
  }

  static filterOutNotContainedSubscriptionIdsAndChannelIds(
    filters: AnalysisFilter[],
    channelIds: string[],
    subscriptionIds: string[]
  ): AnalysisFilter[] {
    const subscriptionIndex = filters.findIndex((x) => x.field === 'subscriptionId');
    const channelIndex = filters.findIndex((x) => x.field === 'channelId');
    if (subscriptionIndex >= 0) {
      const subscriptionFilters = filters[subscriptionIndex].options;
      const filteredSubscriptions = subscriptionFilters.filter((x) => subscriptionIds.includes(x.value));
      filters[subscriptionIndex].options = filteredSubscriptions;
    }
    if (channelIndex >= 0) {
      const channelFilters = filters[channelIndex].options;
      const filteredChannels = channelFilters.filter((x) => channelIds.includes(x.value));
      filters[channelIndex].options = filteredChannels;
    }
    return filters;
  }
}
