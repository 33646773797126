import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareItemModalComponent } from './share-item-modal.component';
import { JoinStringsPipe } from '../../pipes/join-strings/join-strings.pipe';
import { EmailSearchInputComponent } from '@shared/components/email-search-input/email-search-input.component';
import { DropHintsComponent } from
    '@shared/components/email-search-input/drop-hints/drop-hints.component';
import { ChipComponent } from '@shared/components/email-search-input/search-chip/chip.component';
import { InputComponent } from '../input/input.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [
    ShareItemModalComponent,
    EmailSearchInputComponent,
    DropHintsComponent,
    ChipComponent,
    JoinStringsPipe,
    InputComponent
  ],
  exports: [ShareItemModalComponent, InputComponent],
})
export class ShareItemModalModule {}
