import { Component, Input } from '@angular/core';
import { BlogExcerpt } from '@shared/models/market-analysis/market-analysis';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: [ './blog-card.component.scss' ],
})
export class BlogCardComponent {

  @Input() blog: BlogExcerpt = {
    id: null,
    category: '',
    title: 'Missing blog info',
    description: '',
    publicationDate: null,
    authorName: '',
    authorAvatarUrl: '',
    entitled: false,
  };
  @Input() consistentHeight = false;

}
