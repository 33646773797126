import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-tracker-inform-content',
  templateUrl: './progress-tracker-inform-content.component.html',
  styleUrls: ['./progress-tracker-inform-content.component.scss']
})
export class ProgressTrackerInformContentComponent {
  // Inputs
  @Input() icon = 'icon-document';
  @Input() title = 'Title';
  @Input() descripLine1 = 'Description Line 1';
  @Input() descripLine2 = '';
  @Input() descripLine3 = '';
}
