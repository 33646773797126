import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {IRecentSearch, ISearchTerms} from '@app/store/Models/search/searchModels';
import {Observable} from 'rxjs';
import {untilDestroyed} from '@core';

/**
 *  Typeahead for the recent searches
 */
@Component({
  selector: 'app-recent-typeahead-container',
  templateUrl: './recent-typeahead-container.component.html',
  styleUrls: ['./recent-typeahead-container.component.scss']
})
export class RecentTypeaheadContainerComponent implements OnInit, OnDestroy {

  @Input() recentSearches: IRecentSearch[];

  /**
   * Sets the observable that captures the keyboard events
   * @param value   Observable to attach to this component
   */
  @Input()
  set focused(value: Observable<string>) {
    this._focused = value;
    this._focused
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.onKeyPress(res);
      })
  }

  @Output()
  search: EventEmitter<any> = new EventEmitter<ISearchTerms>();

  @ViewChild('container', {read: ElementRef}) container: ElementRef;
  focusedIndex = -1;
  prevFocusedIndex: number = null;
  private trackMouse: boolean;
  private _focused: Observable<string>;

  constructor() {
  }

  // ngOnDestroy is necessary for the use of untilDestroyed.
  // The return statement fakes out SonarCloud.
  ngOnDestroy(): void {
    return;
  }

  ngOnInit(): void {
    return;
  }

  /**
   * Defines behaviour when user presses a key, like adding or substracting from the focused indexes when
   * user presses an arrow key, or submitting a filter when user presses enter
   * @param $event   Keyboard event
   */
  onKeyPress($event: string) {
    switch ($event) {
      case 'ArrowDown':
        if (this.trackMouse) {
          break;
        }
        this.focusedIndex = this.focusedIndex === this.recentSearches.length - 1
          ? 0
          : this.focusedIndex + 1;
        break;
      case 'ArrowUp':
        if (this.trackMouse) {
          break;
        }
        this.focusedIndex = this.focusedIndex <= 0
          ? this.recentSearches.length - 1
          : this.focusedIndex - 1;
        break;
      case 'Enter':
      case 'Tab':
        if (this.recentSearches[this.focusedIndex]?.searchTerms) {
          this.search.emit(this.recentSearches[this.focusedIndex].searchTerms);
        } else if (this.focusedIndex > -1) {
          this.search.emit({source: 'Enter'});
        }
        break;
      case 'Escape':
        this.focusedIndex = -1;
        this.search.emit(null);
        break;
      default:
        this.focusedIndex = -1;
        break;
    }
  }

  /**
   * Sets the focused index to the one the mouse is pointing, and starts tracking the mouse
   * as long the mouse is over the typeahead container
   *
   * @param i index of the the selected element
   */
  onMouseEnter(i: number) {
    this.focusedIndex = i;
    this.trackMouse = true;
  }

  /**
   * Stops tracking the cursor
   */
  onMouseLeave() {
    this.focusedIndex = -1;
    this.trackMouse = false;
  }
}
