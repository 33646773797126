import { Component } from '@angular/core';

@Component({
  selector: 'app-user-settings-shortcuts',
  templateUrl: './user-settings-shortcuts.component.html',
  styleUrls: ['./user-settings-shortcuts.component.scss']
})
export class UserSettingsShortcutsComponent {

}
