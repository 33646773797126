import {Component, Input} from '@angular/core';
import {BlogAuthorModel} from '@shared/models/blog-author.model';

@Component({
  selector: 'app-ask-the-author-list-item',
  templateUrl: './ask-the-author-list-item.component.html',
  styleUrls: ['./ask-the-author-list-item.component.scss']
})
export class AskTheAuthorListItemComponent {
  @Input() author: BlogAuthorModel = new BlogAuthorModel();
  @Input() index: number;

  get avatarUrl(): string {
    if (this.author.avatarList) {
      const keys = Object.keys(this.author.avatarList);

      if (keys.length > 0) {
        // Get the image with best resolution
        const keysSorted = [...keys].sort();
        const bestResKey = keysSorted[keys.length - 1];

        return this.author.avatarList[bestResKey];
      }
    }

    return 'assets/Sidebar/author-avatar.svg';
  }

  get authorType(): string {
    return this.index === 0 ? 'Author' : 'Co Author';
  }
}
