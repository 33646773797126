/**
 * Helper class for table duties, like calculate sort
 */
export class TableHelper {

  /**
   * Compares 2 string taking into account if represents a number or not.
   * Numbers goes first then words, case insensitive.
   *
   * @param left value
   * @param right value
   * @returns 0 when same, gt 0 if left > right, lt 0 if left < right
   */
  public static stringComparator(left: string, right: string): number {
    if (TableHelper.isNumber(left)) {
      if (TableHelper.isNumber(right)) {
        return Number(left) - Number(right);
      } else {
        return 1;
      }
    } else {
      if (TableHelper.isNumber(right)) {
        return -1;
      } else {
        return left?.toLocaleUpperCase().localeCompare(right?.toLocaleUpperCase());
      }
    }
  }

  /**
   * Handy function to tell if an string represent a number value
   *
   * @param value any string value
   * @returns true if represents a number, false otherwise
   */
  public static isNumber(value: string): boolean {
    return value && value !== '' && !isNaN(Number(value));
  }
}