import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from '@app/auth';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IInputProps } from '../input/input.component';
import { ITextAreaProps } from '../../models/shared.models';

@Component({
  selector: 'app-platform-entitlement-request-modal',
  templateUrl: './platform-entitlement-request-modal.component.html',
  styleUrls: ['./platform-entitlement-request-modal.component.scss']
})
export class PlatformEntitlementRequestModalComponent implements OnInit {

  @Input() reportTitle = '';

  requestAccessFormModal: UntypedFormGroup;
  userEmail: string;
  loading = false;

  inputPropsEmail: IInputProps = {
    class: 'form-control uui-modal-input',
    placeholder: '',
    label: '',
    disabled: true,
  };

  inputPropsSubject: IInputProps = {
    class: 'form-control uui-modal-input',
    placeholder: 'Your message subject here',
    label: 'Entitlement request for',
  };

  inputCommentProps: ITextAreaProps = {
    name: 'topic',
    class: 'form-control uui-modal-input',
    label: 'Optional message',
    rows: 2,
    cols: 50,
    minLength: 1,
    maxLength: 500,
  };

  constructor(
    public modal: NgbActiveModal,
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.requestAccessFormModal = new UntypedFormGroup(
      {
        email: new UntypedFormControl(''),
        subject: new UntypedFormControl(this.reportTitle),
        topic: new UntypedFormControl(''),
      }
    );
    this.authService.me().subscribe((userData) => {
      this.userEmail = userData.email;
      this.requestAccessFormModal.get('email').setValue(userData.email);
    });
  }

  onModalClose() {
    const formData = {
      email: this.requestAccessFormModal.get('email').value,
      subject: this.requestAccessFormModal.get('subject').value,
      topic: this.requestAccessFormModal.get('topic').value,
    }
    this.modal.close(formData);
  }

}
