import { HttpParams } from '@angular/common/http';
import { ParamMap } from '@angular/router';
import { PaginationParameters } from '../models/pagination';
import { CTISearchSort } from '../models/search/cti-search.model';

export class PaginationHelper {
  static fromParams(params: ParamMap): PaginationParameters {
    return new PaginationParameters(Number(params.get('size')), Number(params.get('page')), params.get('sort') );
  }

  static fromParamsOrDefault(params: ParamMap, defaults?: Partial<PaginationParameters>) {
    return new PaginationParameters(
      params.has('size') ? Number(params.get('size')) : defaults?.size,
      params.has('page') ? Number(params.get('page')) : defaults?.page,
      params.has('sort') ? params.get('sort') : defaults?.sort, {
        showHighlights: params.has('showHighlights') ? params.get('showHighlights') === 'true' : defaults?.showHighlights,
      },
    );
  }

  /**
   * Add query parameters compatible with Spring Data Pageable, given a Pagination value
   */
  static withPaginationHeaders(pagination: PaginationParameters, params: HttpParams): HttpParams {
    if (pagination) {
      // No valid values can be falsy so this is safe:
      if (pagination.size) {
        params = params.set('size', String(pagination.size));
      }
      if (pagination.page) {
        params = params.set('page', String(pagination.page - 1));
      }
      if (pagination.sort && pagination.sort==='analysisEndDate,DESC') {
        params = params.set('sort', pagination.sort);
      }
    }
    return params;
  }

  /**
   * Add query parameters compatible for blogs Endpoint, given a Pagination value
   */
  static withPaginationHeadersBlogs(
    subscriptions: string[],
    pagination: PaginationParameters,
    params: HttpParams
  ): HttpParams {
    if (pagination) {
      // No valid values can be falsy so this is safe:
      params = params.set('subscriptions', String(subscriptions));
      if (pagination.size) {
        params = params.set('size', String(pagination.size));
      }
      if (pagination.page) {
        params = params.set('page', String(pagination.page));
    }
      if (pagination.sort) {
        params = params.set('sort', pagination.sort);
      }
    }
    return params;
  }

  /**
   * Add query parameters compatible for videos Endpoint, given a Pagination value
   */
  static withPaginationHeadersVideos(
    sfID: string,
    pagination: PaginationParameters,
    params: HttpParams
  ): HttpParams {

    params = params.set('subscriptionSfIds', sfID);
    if (pagination) {
      // No valid values can be falsy so this is safe:
      if (pagination.size) {
        params = params.set('size', String(pagination.size));
      }
      if (pagination.page) {
        params = params.set('page', String(pagination.page));
      }
      if (pagination.sort) {
        params = params.set('sort', pagination.sort);
      }
    }
    return params;
  }

  /**
   * Pagination params mapped to the CTISearch endpoint requirements
   * @param pagination a pagination object to transform
   * @returns pagination to be used in the CTISearch endpoint call
   */
  static withPaginationHeadersCTISearch(
    pagination: PaginationParameters
  ): Omit<PaginationParameters, 'sort' | 'page'> & { sort: CTISearchSort } {
    const sortParams = pagination.sort.split(',');
    const pageFrom = pagination.page - 1 > 0 ? (pagination.page - 1) * pagination.size : 0;
    return {
      from: pageFrom,
      size: pagination.size,
      sort: {
        type: sortParams[0],
        order: sortParams[1].toLowerCase()
      },
      showHighlights: pagination.showHighlights
    };
  }

  /**
   * Return the array of pages to show based on the current page
   * @param page the current page for which to generate the array
   */
  public static getPagesToShowForPageNumber(page: number, totalPages: number, allPages: Array<number>) {
    // We get the start index which is two numbers less than the current one
    // As allPages[page - 2] = page we substract 4
    const start = this.nat(page - 4);

    // We get the end which is
    // until what index we are going to take from the allPages array
    const end = page + 1;
    let result = allPages.slice(start, end);

    // if the start is 0 that means could have not
    // reached the minimum amount of numbers in the array
    // so we add more numbers at the end
    if (start === 0) {
      const toAdd = 6 - result.length;
      result = result.concat(allPages.slice(end, end + toAdd));
    }
    // if the end is totalPages - 3 it means we could have not
    // reached the minimum amount of numbers in the array
    // so we add more at the start
    if (end > totalPages - 3) {
      const toAdd = 6 - result.length;
      result = allPages.slice(this.nat(start - toAdd), start).concat(result);
    }

    // Then theres only one number between 1 and 3
    // So showing "..." is pointless
    // We add number  2
    if (result[0] === 3) {
      result.unshift(2);
    }

    // Then theres only one number between totalPages and totalPages - 2
    // So showing "..." is pointless
    // We add number  totalPages - 1
    if (result[result.length - 1] === totalPages - 2) {
      result.push(totalPages - 1);
    }

    // We return the result
    return result;
  }

  /**
   * Returns the number if it's positive,
   * if it is a negative number returns 0
   * @param number  to be verified
   * @returns number >= 0
   */
  private static nat(number: number) {
    return Math.sign(number) > 0 ? number : 0;
  }
}
