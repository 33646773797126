<div class="content-container">
    <app-hygraph-header *ngIf="htmlReport?.header" [date]="htmlReport.publishDate" [header]="htmlReport.header"></app-hygraph-header>
    <div class="body">
        <app-hygraph-navigation [includeTableOfContent]="htmlReport.includeTableOfContent" [sections]="htmlReport.sections"></app-hygraph-navigation>
        <div class="main-section">
            <ng-container *ngFor="let section of htmlReport.sections">
                <ng-container>
                    <div *ngIf="section" class="content-section" id="{{section.title}}">
                        <h2 *ngIf="section.title">{{ section.title }}</h2>
                        <h3 *ngIf="section.subTitle">{{section.subTitle}}</h3>
                        <ng-container *ngFor="let content of section.sectionContent" [ngSwitch]="content.image ? 'SectionContentImage' : content.table ? 'SectionContentTable' : 'SectionContentText'">
                            <div
                                *ngSwitchCase="'SectionContentImage'"
                                class="image-container"
                                [ngClass]="content.image.length > 2 ? 'fixed-columns' : 'auto-columns'"
                            >
                                <div *ngFor="let image of content.image">
                                    <img [src]="image.url" alt="Section Image" (click)="openImageModal(image.url, image.altText)" />
                                    <div>
                                        <a [href]="image.sourceUrl" target="_blank">{{image.fileName}}</a>
                                        <h5>{{image.altText}}</h5>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngSwitchCase="'SectionContentTable'">
                                <table  *ngFor="let html of content.table" [innerHTML]="(html.html | textScripts)"></table>
                            </ng-container>
                            <ng-container *ngSwitchCase="'SectionContentText'">
                                <div class="body-container" *ngFor="let html of content.body" [innerHTML]="(html.html | textScripts)"></div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <app-hygraph-related-content
          [includeRelatedContent]="htmlReport.includeRelatedContent"
          [relatedContentItems]="htmlReport.relatedContent"
        ></app-hygraph-related-content>
    </div>
</div>
