import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Analysis } from '@app/@shared/models/reverse-engineering/analysis.models';
import { NotificationSettingsService } from '@app/@shared/services/notification-settings/notification-settings.service';
import { ReportProgressSubscription } from '@app/settings/models/reportsProgressSubcription';
import { DialogService } from '@shared/services/dialog/dialog.service';


@Component({
  selector: 'app-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss']
})
export class NotificationBannerComponent implements OnInit {

  iconName: string;
  bannerMessage: string;
  disabled: boolean;
  dontShowAgain: boolean;
  isFollowing: boolean;

  @Input() analysis: Analysis

  // fix this on  https://techinsights.atlassian.net/browse/SWDEV-15144
  @Input() set unknowCondition(value: boolean) {
    if(value && this.analysis?.entitled) { // why? https://techinsights.atlassian.net/browse/SWDEV-15144
      this.initIconAndBanner();
    }
  };


  constructor(
    private dialogService: DialogService,
    private notificationSettingsService: NotificationSettingsService
  ){ }

  ngOnInit(): void {
    this.iconName = 'notification';
    this.bannerMessage = '';
    this.dontShowAgain = false;
    this.notificationSettingsService.getReportProgressState(this.analysis).subscribe(
      (reportProgressState: ReportProgressSubscription) => {
        if (reportProgressState.notifyAnalysisPublished && reportProgressState.notifyFirstWipDeliverable) {
          this.setBannerMessageAndIconNameAsFollowed();
        } else {
          this.isFollowing = false;
        }
      }
    );
    this.disabled = !this.analysis.entitled;
  }

  get buttonText() {
    return this.isFollowing ? 'Unfollow' : 'Follow';
  }

  onClickButton() {
    if(this.isFollowing) {
      if(!this.dontShowAgain) {
        this.dialogService.openConfirmationModal().result.then(
          (result) => {
            if (!result.isDismissed) {
              this.unfollow();
              this.dontShowAgain = result.dontShowAgain;
            }
          }
        );
      } else {
        this.unfollow();
      }
    } else {
      this.follow();
    }
  }

  private follow() {
    this.notificationSettingsService.followReportProgress(this.analysis).subscribe(() => {
      this.setBannerMessageAndIconNameAsFollowed();
    });
  }

  private setBannerMessageAndIconNameAsFollowed() {
    if (this.analysis.actualFirstWipDate) {
      this.bannerMessage = `${this.wipMessage}You have followed this analysis and will receive notification updates when content is available.`;
      this.iconName = 'notification';
    } else {
      this.bannerMessage = `You have followed this analysis and will receive notification updates when content is available.`;
      this.iconName = 'success_filled';
    }
    this.isFollowing = true;
  }

  private unfollow() {
    this.notificationSettingsService.deleteReportProgressSubscription(this.analysis.id).subscribe(() => {
      this.isFollowing = false;
      this.iconName = 'notification';
    });
  }

  /**
   * We really don't know why is this needed.
   * Will be tackle here https://techinsights.atlassian.net/browse/SWDEV-15144
   */
  private initIconAndBanner() {
    this.notificationSettingsService.getReportProgressState(this.analysis).subscribe(
      (reportProgressState: ReportProgressSubscription) => {
        if (reportProgressState.notifyAnalysisPublished && reportProgressState.notifyFirstWipDeliverable) {
          this.isFollowing = true;
          if (this.analysis.actualFirstWipDate) {
            this.bannerMessage = this.wipMessage;
          } else {
            this.iconName = 'success_filled';
          }
          this.bannerMessage = `${this.bannerMessage}We will send you an update when the final analysis is available.`;
        }
      }
    );
  }

  private get wipMessage() {
    const parsedDate = this.analysis.actualFirstWipDate.replace(/-/g, '/').replace(/T.+/, '');
    const date = formatDate(parsedDate, 'mediumDate', 'en-CA');
    return `1st WIP was completed on ${date}. `;
  }
}
