import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';

/**
 * Serializes parameters for some requests known for having parameters with spaces or
 * characters that can't be put as is in the URL
 */
@Injectable({
  providedIn: 'root'
})
export class ParamsSerializerInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ( request.params.get('genealogyCode')
        && request.params.get('genealogyCode').includes('+')
        ||
        request.params.get('genealogyCodes')
        && request.params.get('genealogyCodes').includes('+')) {
      const params = request.params.delete('genealogyCode')
        .delete('genealogyCodes');
      request = request.clone({
        url: request.urlWithParams.replace(/\+/g, '%2B'),
        params,
      });
    }
    return next.handle(request);
  }
}
