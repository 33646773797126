<app-kimera-grid-metadata-sidebar
  [sidebarOpen]="sidebarOpen"
  [metaDataItemHeight]="metaDataItemHeight"
  [metaDataItemWidth] ="metaDataItemWidth"
  [tileSourceLayers] = "tileSourceLayers"
  (toggleVisibility)="onToggleVisibility($event)"
  (toggleSelection)="onToggleSelection($event)"
  (selectColourPicker)="onSelectColourPicker($event)"
></app-kimera-grid-metadata-sidebar>
<app-kimera-grid-cv-viewer
    [class.sidebarOpen]="sidebarOpen"
    [cvToolbarHeight]="cvToolbarHeight"
    [analysisCode] = "analysisCode"
    [webCVContentLakeId] = "webCVContentLakeId"
    [measuringScalingFactor] = "measuringScalingFactor"
    [tileSourceLayers] = "tileSourceLayers"
    [gridId] = "gridId"
></app-kimera-grid-cv-viewer>
<app-kimera-colour-picker [hidden]="!colourPickerShown"
  [scrollDiff] = "scrollDiff"
  [cvToolbarHeight]="cvToolbarHeight"
  [kimeraGridCvContentComponentHeight] ="kimeraGridCvContentComponentHeight"
  [metaDataItemHeight]="metaDataItemHeight"
  [metaDataItemWidth] ="metaDataItemWidth"
  [currentSelectedTileSourceLayer]="getCurrentSelectedTileSourceLayer()"
  (closeColourPicker)="onCloseColourPicker()"
  (applyViewerColour)="onApplyColour($event)"
></app-kimera-colour-picker>
