import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ExecutiveSummaryGuard {
  constructor(private router: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const newBasePath = '/executive-insights';
    const newRoute = [newBasePath];

    route.url
      .filter(urlSegment => urlSegment.path !== 'market-summary')
      .forEach(urlSegment => newRoute.push(urlSegment.path));
    return this.router.createUrlTree(newRoute, {
      queryParams: route.queryParams,
      fragment: route.fragment
    });
  }
}