import { Component, OnInit, Input } from '@angular/core';
import { AnalysisFilterFacet, AnalysisFilterGroup  } from '@app/@shared/models/reverse-engineering/analysis.models';
import { BaseFilterComponent } from '../base-filter/base-filter.component';

@Component({
  selector: 'app-group-filter',
  templateUrl: './group-filter.component.html',
  styleUrls: ['./group-filter.component.scss']
})
export class GroupFilterComponent extends BaseFilterComponent implements OnInit {

  @Input() searchBox = true;
  @Input() filterGroups: AnalysisFilterGroup[]
  filterText = '';
  filteredGroups: AnalysisFilterGroup[];


  ngOnInit(): void {
    this.showAllFacets();
  }

  /**
   * Filters the visible facets within a filter by user input text
   */
  filterFacets() {
    if (this.filterText) {
      this.filteredGroups = this.filterGroups
        .map(({ label, options: groupOptions }) => {
          const options = groupOptions.filter(facet => this.facetFilteringFn(this.filterText, facet));
          return { label, options };
        })
        .filter(({ options }) => options.length > 0);
    } else {
      this.showAllFacets();
    }
  }

  /**
   * Deletes the text input by the user in the filter box and clears facet filtering
   */
  clearFacetFilter() {
    this.filterText = '';
    this.showAllFacets();
  }

  /**
   * Filtering function for facets
   * @param q user input text
   * @param facet current facet
   * @returns true if facet label contains user input text. False otherwise
   */
  private facetFilteringFn(q: string, facet: AnalysisFilterFacet) {
    return facet.label.toLowerCase().indexOf(q.toLowerCase()) > -1;
  }

  /**
   * Fills the the filteredFacets collection with the initial facets
   */
  private showAllFacets() {
    this.filteredGroups = [...this.filterGroups]
  }

}
