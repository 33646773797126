<div
  class="notification-item"
  [ngClass]="{
    unread: !notification?.read,
    collection: notification?.type?.toString() === 'COLLECTION',
    shared: notification?.type?.toString() === 'SHARED',
    search: notification?.type?.toString() === 'SEARCH'
  }"
>
  <div (click)="dismiss(notification.id, $event);" class="close"></div>
  <div class="avatar">
    <img *ngIf="notification?.type?.toString() === 'SHARED'" alt="User" src="../../../../assets/Menu/UserPhoto.svg" />
    <span *ngIf="notification?.type?.toString() !== 'SHARED'" class="icon">
      <em *ngIf="notification?.type?.toString() === 'COLLECTION'" class="icon-collection"></em>
      <em *ngIf="notification?.type?.toString() === 'SEARCH'" class="icon-search"></em>
    </span>
  </div>
  <div class="notification-detail">
    <h3>{{ notification?.title }}</h3>
    <p>{{ notification?.body }}</p>
    <span class="time">{{notification?.date | dateAgo}}</span>
  </div>
</div>
