import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {exhaustMap, tap} from 'rxjs/operators';
import {ApplicationHealthActionTypes} from '@app/store/Actions/application-health/application-health.actions';
import {PopupComponent} from '@app/shell/components/popup/popup.component';
import {getReasonPhrase} from 'http-status-codes';

/**
 * Side effects for the application health actions
 */
@Injectable()
export class ApplicationHealthEffects {

  /**
   * If an error is unrecoverable, notify the user and prompt them to reload
   */
  NotifyUnrecoverableError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationHealthActionTypes.notifyUnrecoverableError),
      // Using exhaustMap since we only want ONE sub to getNotifications to pass
      // and ignore the rest even if the action is triggered
      tap((action: any) => {
        const modalRef = this._modalService.open(PopupComponent);
        modalRef.componentInstance.props = {
          header: getReasonPhrase(action.error.httpResponseCode),
          body: 'Something went wrong with our ' + action.error.affectedAPI.toString() + ' API.',
          type: 'warning',
          primaryButton: true,
          primaryLabel: 'Refresh',
          secondaryButton: true,
          secondaryLabel: 'Cancel',
          showContactEmail: true,
          primaryButtonBehaviour: () => {window.location.reload()}
        }
      }),
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private _modalService: NgbModal) {
  }

}
