<nav [class.show-labels]="!menuHidden" (mouseenter)="openMenu()" (mouseleave)="closeMenu()">
  <div class="logo-stacked"></div>

  <div class="nav-buttons-container" [ngClass]="menuHidden ? 'overflow-hidden' : 'scrollbar'">
    <ng-container *ngFor="let item of (menu | async) | slice:0:getCalculatedButtonsAmount()">
      <app-nav-button
        class="nav-button"
        [ngbTooltip]="item?.tooltip"
        placement="right"
        container="body"
        [isCollapsed]="menuHidden"
        [menuItem]="item"
        [disableTooltip]="!menuHidden || !item?.tooltip"
      ></app-nav-button>
    </ng-container>
    <app-nav-more-button
      *ngIf="getCalculatedButtonsAmount() < (menu | async)?.length "
      class="nav-button"
      ngbTooltip="More items"
      placement="right"
      [isCollapsed]="menuHidden"
      [menuItem]="moreButton | async"
      [disableTooltip]="!menuHidden"
      (click)="onMoreToggle(); $event.stopPropagation()"
    ></app-nav-more-button>
  </div>

  <button
    class="{{expandedButtonClass()}} toggle-menu-button hide-on-mobile"
    (click)="toggleMenu(); $event.stopPropagation(); $event.preventDefault()"
  >
    <em
      class="icon"
      [ngClass]="expandedButtonActive ? 'icon-left_medium' : 'icon-right_medium'"
    ></em>
  </button>
</nav>
