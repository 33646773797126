export class MetadataKeys {

  static readonly REPORT_MANUFACTURER = 'SUPPLIER';
  static readonly REPORT_MODEL_NUMBER = 'MODELNO';
  static readonly REPORT_PART_NUMBER = 'PARTNUMBER';
  static readonly REPORT_COMMON_NAME = 'COMMONNAME';
  static readonly REPORT_DEVICE_TYPE = 'DEVICETYPETEXT';
  static readonly REPORT_DEVICE_CATEGORY = 'DT_L2_TEXT';
  static readonly REPORT_DESCRIPTION = 'ITEM_DESCRIPTION';
  static readonly REPORT_MARKET_AVAILABILITY = 'MARKET_AVAILABILITY';
  static readonly REPORT_COUNTRY_PURCHASE = 'CountryOfPurchase';
  static readonly REPORT_REPORT_DESCRIPTION = 'LONGDESC__C';
  static readonly REPORT_REPORT_CODE = 'SKU__C';
  static readonly REPORT_PUBLISH_DATE = 'Release_Date';
}

export class Metadata {

  private metadata: MetadataEntry[] = [];

  size(): number {
    return this.metadata.length;
  }

  getKeys(): string[] {
    const keys = [];
    for (const entry of this.metadata) {
      keys.push(entry.key);
    }
    return keys;
  }

  getEntry(key: string): MetadataEntry {
    for (const entry of this.metadata) {
      if (entry.key === key) {
        return entry;
      }
    }
    return undefined;
  }

  getValue(key: string): string {
    for (const entry of this.metadata) {
      if (entry.key === key) {
        return entry.value;
      }
    }
    return '';
  }

  getCssClass(key: string): string {
    for (const entry of this.metadata) {
      if (entry.key === key) {
        return entry.cssClassName;
      }
    }
    return '';
  }

  addMetadata(key: string, value?: string, cssClassName?: string): this {
    if (value) {
      const entry: MetadataEntry = this.getEntry(key);
      if (entry) {
        entry.value = value;
        entry.cssClassName = cssClassName;
      } else {
        this.metadata.push(new MetadataEntry(key, value, cssClassName));
      }
    }
    return this;
  }
}

export class MetadataEntry {

  key: string;
  value: string;
  cssClassName: string;

  constructor(key: string, value: string, cssClassName?: string) {
    this.key = key;
    this.value = value;
    this.cssClassName = cssClassName;
  }
}
