import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Credentials {
  // Customize received credentials here
  expirationDate: Date;
  token: string;
}

const credentialsKey = 'oauth';

/**
 * Provides storage for authentication credentials.
 */
@Injectable(
  {
    providedIn: 'root'
  }
)
export class CredentialsService {

  isAuthenticated$ = new BehaviorSubject<boolean>(false);

  get eulaId(): string {
    return this.getOauth().eula_id;
  }

  constructor() {
  }

  /**
   * Stores authentication object to localStorage
   * @param oauth   auth object
   */
  storeToken(oauth: any) {
    const expiresAt = new Date().getTime() + oauth.expires_in * 1000;
    localStorage.setItem('oauth', JSON.stringify(oauth));
    localStorage.setItem('oauth-expiration', expiresAt.toString());
    this.isAuthenticated$.next(true);
  }

  /**
   * Removes authentication object from local storage
   */
  removeToken() {
    localStorage.removeItem('oauth');
    localStorage.removeItem('oauth-expiration');
    this.isAuthenticated$.next(false);
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    const isAuthenticated = this.getOauth()
      && this.getAccessToken()
      && this.getOauthExpiration() >= new Date().getTime();
    this.isAuthenticated$.next(isAuthenticated);
    return isAuthenticated;
  }

  /**
   * Gets the JWT access token
   */
  getAccessToken(): string {
    return this.getOauth()
           ? this.getOauth().access_token
           : null;
  }

  /**
   * Gets the user id
   */
  getUserId(): string {
    return this.getOauth()
           ? this.getOauth().user_id
           : null;
  }

  /**
   * Checks if the user needs to sign eula
   */
  needsEULASignature(): boolean {
    return this.eulaId != null;
  }

  /**
   * Gets auth object
   */
  getOauth() {
    try {
      return JSON.parse(localStorage.getItem('oauth'));
    } catch (ex) {
      return null;
    }
  }

  /**
   * Gets expiration date in UNIX Date format
   */
  getOauthExpiration(): number {
    const expiration = parseInt(localStorage.getItem('oauth-expiration'), 10);
    if (!isNaN(expiration)) {
      return expiration;
    }
    return null;
  }
}
