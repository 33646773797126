import {createAction, props} from '@ngrx/store';
import {INotificationSettings, IUserProfile} from 'src/app/store/Models/settings/settingsModel';
import {SettingsState} from 'src/app/store/Reducers/settings/settings.reducer';
/**
 * Settings actions definitions
 *
 * Here goes all the actions that affects the settings module, like saving or fetching settings or user profile
 */
export enum SettingsActionTypes {
  loadSettings = '[Settings] Load Settings',
  loadSettingsSuccess = '[Settings] Load Settings Success',
  loadSettingsFailure = '[Settings] Load Settings Failure',
  saveUserProfile = '[Settings] Save User Profile',
  setUserProfile = '[Settings] Set User Profile',
  saveUserProfileSuccess = '[Settings] Save User Profile Success',
  saveUserProfileFailure = '[Settings] Save User Profile Failure',
  saveNotificationSettings = '[Settings] Save Notification Settings',
  saveNotificationSettingsSuccess = '[Settings] Save Notification Settings Success',
  saveNotificationSettingsFailure = '[Settings] Save Notification Settings Failure',
}
export const loadSettings = createAction(
  SettingsActionTypes.loadSettings
);

export const loadSettingsSuccess = createAction(
  SettingsActionTypes.loadSettingsSuccess,
  props<{ data: SettingsState }>()
);

export const loadSettingsFailure = createAction(
  SettingsActionTypes.loadSettingsFailure,
  props<{ error: any }>()
);

export const saveUserProfile = createAction(
  SettingsActionTypes.saveUserProfile,
  props<{ userProfile: IUserProfile }>()
);

export const setUserProfile = createAction(
  SettingsActionTypes.setUserProfile
);

export const saveUserProfileSuccess = createAction(
  SettingsActionTypes.saveUserProfileSuccess
);

export const saveUserProfileFailure = createAction(
  SettingsActionTypes.saveUserProfileFailure,
  props<{ error: any }>()
);

export const saveNotificationSettings = createAction(
  SettingsActionTypes.saveNotificationSettings,
  props<{ notificationSettings: INotificationSettings }>()
);

export const saveNotificationSettingsSuccess = createAction(
  SettingsActionTypes.saveNotificationSettingsSuccess,
  props<{ notificationSettings: INotificationSettings }>()
);

export const saveNotificationSettingsFailure = createAction(
  SettingsActionTypes.saveNotificationSettingsFailure,
  props<{ error: any }>()
);
