/* istanbul ignore file */
import {
  Observable,
  of
} from 'rxjs';
import {
  IContentType,
  IFilterType,
  ISearchResponse,
  ISearchResults,
  ISearchTerms
} from '@app/store/Models/search/searchModels';
import { delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PaginationParameters } from '@app/@shared/models/pagination';
import { CTISearchBaseResult, CTISearchResults } from '@app/@shared/models/search/cti-search.model';
import { Filters } from '@app/@shared/models/reverse-engineering/analysis.models';

@Injectable({
              providedIn: 'root'
            })
export class SearchServiceMock {
  constructor() {
  }

  getInitialSearchResults(): Observable<ISearchResults> {
    return of(mockSearchReturn).pipe(delay(3000));
  }

  getItemHightlights(): Observable<any> {
    return of({
      key: 'Test'
    })
  }

  getSearchResults(searchTerms: ISearchTerms): Observable<ISearchResults> {
    return this.filterByTerm(searchTerms)
  }

  filterByTerm(term: ISearchTerms): Observable<ISearchResults> {
    const mappedResult = this.mapToSearchResults(mockSearchReturn);
    const filteredList: any = {
      images: (term.selectedContentType as IContentType).label != null
              && (term.selectedContentType as IContentType).label !== ''
              && (term.selectedContentType as IContentType).label === 'images'
              ?
              this.filterList(mappedResult.image, term)
              : (term.selectedContentType as IContentType).label == null
                || (term.selectedContentType as IContentType).label === ''
                ?
                this.filterList(mappedResult.image, term).slice(0, 5)
                : [],
      schematics: (term.selectedContentType as IContentType).label != null
                  && (term.selectedContentType as IContentType).label !== ''
                  && (term.selectedContentType as IContentType).label === 'schematics'
                  ?
                  this.filterList(mappedResult.schematic, term)
                  : (term.selectedContentType as IContentType).label == null
                    || (term.selectedContentType as IContentType).label === ''
                    ?
                    this.filterList(mappedResult.schematic, term).slice(0, 5)
                    : [],
      components: (term.selectedContentType as IContentType).label != null
                  && (term.selectedContentType as IContentType).label !== ''
                  && (term.selectedContentType as IContentType).label === 'components'
                  ?
                  this.filterList(mappedResult.component, term)
                  : (term.selectedContentType as IContentType).label == null
                    || (term.selectedContentType as IContentType).label === ''
                    ?
                    this.filterList(mappedResult.component, term).slice(0, 5)
                    : [],
      pcb: (term.selectedContentType as IContentType).label != null
           && (term.selectedContentType as IContentType).label !== ''
           && (term.selectedContentType as IContentType).label === 'pcb'
           ?
           this.filterList(mappedResult.pcb, term)
           : (term.selectedContentType as IContentType).label == null
             || (term.selectedContentType as IContentType).label === ''
             ?
             this.filterList(mappedResult.pcb, term).slice(0, 5)
             : [],
      dies: (term.selectedContentType as IContentType).label != null
            && (term.selectedContentType as IContentType).label !== ''
            && (term.selectedContentType as IContentType).label === 'dies'
            ?
            this.filterList(mappedResult.die, term)
            : (term.selectedContentType as IContentType).label == null
              || (term.selectedContentType as IContentType).label === ''
              ?
              this.filterList(mappedResult.die, term).slice(0, 5)
              : [],
      products: (term.selectedContentType as IContentType).label != null
                && (term.selectedContentType as IContentType).label !== ''
                && (term.selectedContentType as IContentType).label === 'products'
                ?
                this.filterList(mappedResult.product, term)
                : (term.selectedContentType as IContentType).label == null
                  || (term.selectedContentType as IContentType).label === ''
                  ?
                  this.filterList(mappedResult.product, term).slice(0, 5)
                  : [],
      reports: (term.selectedContentType as IContentType).label != null
               && (term.selectedContentType as IContentType).label !== ''
               && (term.selectedContentType as IContentType).label === 'report'
               ?
               this.filterList(mappedResult.report, term)
               : (term.selectedContentType as IContentType).label == null
                 || (term.selectedContentType as IContentType).label === ''
                 ?
                 this.filterList(mappedResult.report, term).slice(0, 5)
                 : []
    };
    const resultsAmount = {
      images: this.filterList(mappedResult.image, term).length,
      schematics: this.filterList(mappedResult.schematic, term).length,
      components: this.filterList(mappedResult.component, term).length,
      pcb: this.filterList(mappedResult.pcb, term).length,
      dies: this.filterList(mappedResult.die, term).length,
      products: this.filterList(mappedResult.product, term).length,
      reports: this.filterList(mappedResult.report, term).length
    };
    return of(
      {
        ...filteredList,
        resultsAmount: {
          ...resultsAmount,
          all: Object.keys(resultsAmount)
            .reduce((sum,
                      key) => sum + parseFloat(resultsAmount[key] || 0), 0)
        }
      }
    );
  }

  getSearchFilters(): Observable<IFilterType[]> {
    return of(this.getSearchFiltersSync());
  }

  getSearchFiltersSync(): IFilterType[] {
    const filters: IFilterType[] = [
      {
        label: 'Manufacturer',
        key: 'manufacturer',
        subTypes: []
      },
      {
        label: 'Ultimate Parent',
        key: 'ultimateParent',
        subTypes: []
      },
      {
        label: 'Device Type',
        key: 'deviceType',
        subTypes: []
      },
    ];
    const mappedResult = this.mapToSearchResults(mockSearchReturn);

    filters.forEach((filter,
                      filterIndex) => {
      Object.keys(mappedResult).forEach(mockItemKey => {
        if(!['newResultsAmount', 'paginated', 'resultsAmount'].includes(mockItemKey)) {
          mappedResult[mockItemKey].forEach((item: any) => {
            if (item[filter.key]
              && filters[filterIndex].subTypes.findIndex(v => v.key === item[filter.key]) < 0) {
              filters[filterIndex].subTypes.push(
                {
                  label: item[filter.key],
                  key: item[filter.key]
                }
              );
            }
          });
        }
      });
    });

    return filters;
  }

  cTISearch<T extends CTISearchBaseResult>(..._: any[]): Observable<CTISearchResults<T>> {
    return of({ total: 0, results: [], filterSuggestions });
  }

  private filterList(list: any[],
    term: ISearchTerms): any[] {
    // Filter by different terms
    return list.filter(value => {
      let containsSomeField = true;
      if (term.selectedFilters.length > 0) {
        term.selectedFilters.filter(filter => Array.isArray(filter.subTypes)
          && filter.subTypes.length > 0
          && filter.subTypes.filter(s => s.value === true).length > 0)
          .forEach(field => {
              containsSomeField = (value[field.key]
                && field.subTypes
                && field.subTypes.length > 0
                && field.subTypes
                  .map(subtype => subtype.value === true
                    && value[field.key].toLowerCase().includes(subtype.key.toLowerCase()))
                  .reduce((a,
                            b) => a || b))
                && containsSomeField;
          });
        return containsSomeField;
      } else {
        return true;
      }
      // Filter by text
    }).filter(
      value =>
        Object.keys(value).some(
          key => {
            if (term.searchText && Array.isArray(term.searchText) && term.searchText.length > 0) {
              return term.searchText.map(v => value[key].toString().toLowerCase()
                .includes(v.toString().toLowerCase()))
                .reduce((a,
                          b) => a || b);
            } else {
              return true;
            }
          }
        )
    ).sort(((a, b) => {
      if(term.sortMethod && term.sortMethod.key && term.sortMethod.order) {
        const sortKey = term.sortMethod.key === 'date'
                        ? Object.keys(a).find(k => k.toLowerCase().includes('date'))
                        : term.sortMethod.key;
        const first = term.sortMethod.key === 'date'
                      ? new Date(a[sortKey])
                      : a[sortKey];
        const second = term.sortMethod.key === 'date'
                      ? new Date(b[sortKey])
                      : b[sortKey];

        if (first > second) {
          return term.sortMethod.order === 'ascending'
                 ? 1
                 : -1;
        } else if (first< second) {
          return term.sortMethod.order === 'ascending'
                 ? -1
                 : 1;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }));
  }

  private mapToSearchResults(response: ISearchResponse[] | ISearchResponse): ISearchResults {
    let searchResponse: ISearchResults = {
      newResultsAmount: undefined,
      paginated: false,
      resultsAmount: {
        components: 0,
        dies: 0,
        images: 0,
        pcb: 0,
        products: 0,
        reports: 0,
        schematics: 0,
        all: 0
      },
      component: [],
      die: [],
      image: [],
      pcb: [],
      product: [],
      report: [],
      schematic: []
    };

    if (!Array.isArray(response)) {
      searchResponse = this.mapResponseValues(searchResponse, response as ISearchResponse);
    } else {
      response.forEach(singleResponse => {
        if (singleResponse.response && singleResponse.response.length > 0) {
          searchResponse = this.mapResponseValues(searchResponse, singleResponse);
        }
      });
    }

    return searchResponse;
  }

  private mapResponseValues(searchResponse: ISearchResults,
    singleResponse: ISearchResponse): ISearchResults {
    singleResponse.response.forEach(v => {
      searchResponse.resultsAmount[v.contentType !== 'pcb' ? v.contentType + 's' : v.contentType]++;
      searchResponse.resultsAmount.all++;
      searchResponse[v.contentType].push(v);
    });
    return searchResponse;
  }

}

export const mockSavedSearch: ISearchResults = {
  image: [
    {
      id: '5f36f0b6fd81c2df35064141',
      new: true
    },
    {
      id: '5f36f0b6ddddc043af9faa40'
    }
  ],
  report: [
    {
      id: '5f36f0b691e237f43fb1988e'
    },
    {
      id: '5f36f0b69d6fabf323107039',
      new: true
    },
    {
      id: '5f36f0b68f4dc85a9da6301e'
    }
  ],
  schematic: [
    {
      id: '5f36f0b65467521fca15c242',
      new: true
    },
    {
      id: '5f36f0b67617f74458cccf16',
      new: true
    },
    {
      id: '5f36f0b6235a2d2a6212a5cd'
    },
    {
      id: '5f36f0b6db105169d3c46f14'
    },
  ],
  product: [
    {
      id: '5f36f0b6cd88a361d4527c26',
      new: true
    },
    {
      id: '5f36f0b6c107e61cfe360687'
    },
  ],
  component: [
    {
      id: '5f36f0b65299df977f75323d',
      new: true
    },
    {
      id: '5f36f0b65f8624f200c7f3d4'
    }
  ],
  die: [
    {
      id: '5f36f0b6804e5d0cea751961',
      new: true
    },
    {
      id: '5f36f0b66b6f3f50fd4aedd8',
      new: true
    },
    {
      id: '5f36f0b6918c790e81dbdf4d',
      new: true
    },
  ],
  pcb: [
    {
      id: '7a92275434e34d719a1c191',
      new: true
    },
    {
      id: '6a23e88fe6274c57a446f73'
    },
    {
      id: 'f0206ece9b8c45a08568580'
    }
  ],
  resultsAmount: {
    components: 2,
    dies: 3,
    images: 2,
    pcb: 3,
    products: 2,
    reports: 3,
    schematics: 2,
    all: 17
  },
  newResultsAmount: {
    components: 1,
    dies: 3,
    images: 1,
    pcb: 1,
    products: 1,
    reports: 1,
    schematics: 2,
    all: 10
  }
};


 export const mockSearchTerms = [
  {
      'key': 'manufacturer',
      'label': 'Manufacturer',
      'subTypes': [
          {
              'label': 'AAC Technologies',
              'key': 'aac technologies',
              'amount': 24,
              'value': false
          },
          {
              'label': 'AKM',
              'key': 'akm',
              'amount': 1,
              'value': false
          },
          {
              'label': 'AT&T',
              'key': 'at&t',
              'amount': 4,
              'value': false
          },
          {
              'label': 'AVAGO',
              'key': 'avago',
              'amount': 12,
              'value': false
          },
          {
              'label': 'AgaMatrix Inc.',
              'key': 'agamatrix inc.',
              'amount': 1,
              'value': false
          },
          {
              'label': 'AliveCor',
              'key': 'alivecor',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Alps',
              'key': 'alps',
              'amount': 22,
              'value': false
          },
          {
              'label': 'Amazon',
              'key': 'amazon',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Analog Devices',
              'key': 'analog devices',
              'amount': 3,
              'value': false
          },
          {
              'label': 'Anobit Technologies',
              'key': 'anobit technologies',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Apple',
              'key': 'apple',
              'amount': 6781,
              'value': false
          },
          {
              'label': 'Audience',
              'key': 'audience',
              'amount': 1,
              'value': false
          },
          {
              'label': 'BMW',
              'key': 'bmw',
              'amount': 29,
              'value': false
          },
          {
              'label': 'Brookstone',
              'key': 'brookstone',
              'amount': 1,
              'value': false
          },
          {
              'label': 'CECT',
              'key': 'cect',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Cloud Engines, Inc.',
              'key': 'cloud engines, inc.',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Cobra Electronics',
              'key': 'cobra electronics',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Contour',
              'key': 'contour',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Duracell',
              'key': 'duracell',
              'amount': 1,
              'value': false
          },
          {
              'label': 'EPCOS',
              'key': 'epcos',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Elpida',
              'key': 'elpida',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Eye-Fi',
              'key': 'eye-fi',
              'amount': 1,
              'value': false
          },
          {
              'label': 'FLIR Systems, Inc.',
              'key': 'flir systems, inc.',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Google',
              'key': 'google',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Griffin',
              'key': 'griffin',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Haier',
              'key': 'haier',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Honeywell',
              'key': 'honeywell',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Huawei',
              'key': 'huawei',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Hynix',
              'key': 'hynix',
              'amount': 6,
              'value': false
          },
          {
              'label': 'Im S.p.A.',
              'key': 'im s.p.a.',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Infineon',
              'key': 'infineon',
              'amount': 5,
              'value': false
          },
          {
              'label': 'Knowles',
              'key': 'knowles',
              'amount': 13,
              'value': false
          },
          {
              'label': 'Maxim',
              'key': 'maxim',
              'amount': 5,
              'value': false
          },
          {
              'label': 'Meizu',
              'key': 'meizu',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Murata',
              'key': 'murata',
              'amount': 26,
              'value': false
          },
          {
              'label': 'NXP Semiconductors',
              'key': 'nxp semiconductors',
              'amount': 4,
              'value': false
          },
          {
              'label': 'Panasonic',
              'key': 'panasonic',
              'amount': 6,
              'value': false
          },
          {
              'label': 'Peregrine Semiconductor',
              'key': 'peregrine semiconductor',
              'amount': 2,
              'value': false
          },
          {
              'label': 'RF Micro Devices',
              'key': 'rf micro devices',
              'amount': 17,
              'value': false
          },
          {
              'label': 'STMicroelectronics',
              'key': 'stmicroelectronics',
              'amount': 3,
              'value': false
          },
          {
              'label': 'Samsung',
              'key': 'samsung',
              'amount': 3,
              'value': false
          },
          {
              'label': 'SanDisk',
              'key': 'sandisk',
              'amount': 3,
              'value': false
          },
          {
              'label': 'Skyworks',
              'key': 'skyworks',
              'amount': 5,
              'value': false
          },
          {
              'label': 'Sony',
              'key': 'sony',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Texas Instruments',
              'key': 'texas instruments',
              'amount': 15,
              'value': false
          },
          {
              'label': 'Toshiba',
              'key': 'toshiba',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Universal Scientific Industrial',
              'key': 'universal scientific industrial',
              'amount': 5,
              'value': false
          },
          {
              'label': 'Unknown',
              'key': 'unknown',
              'amount': 53,
              'value': false
          },
          {
              'label': 'Xiaomi',
              'key': 'xiaomi',
              'amount': 2,
              'value': false
          },
          {
              'label': 'iHealth Lab Inc.',
              'key': 'ihealth lab inc.',
              'amount': 1,
              'value': false
          }
      ]
  },
  {
      'key': 'foundry',
      'label': 'Foundry',
      'subTypes': [
          {
              'label': 'Hynix Semiconductor',
              'key': 'hynix semiconductor',
              'amount': 2,
              'value': false
          },
          {
              'label': 'M/A-COM',
              'key': 'm/a-com',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Sony Semiconductor',
              'key': 'sony semiconductor',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Texas Instruments',
              'key': 'texas instruments',
              'amount': 1,
              'value': false
          }
      ]
  },
  {
      'key': 'processGeneration',
      'label': 'Process Generation',
      'subTypes': [
          {
              'label': '250',
              'key': '250',
              'amount': 1,
              'value': false
          },
          {
              'label': '2x',
              'key': '2x',
              'amount': 1,
              'value': false
          },
          {
              'label': '350',
              'key': '350',
              'amount': 2,
              'value': false
          },
          {
              'label': '500',
              'key': '500',
              'amount': 1,
              'value': false
          },
          {
              'label': '90',
              'key': '90',
              'amount': 1,
              'value': false
          }
      ]
  },
  {
      'key': 'deviceType',
      'label': 'Device Type',
      'subTypes': [
          {
              'label': '5G Handset',
              'key': '5g handset',
              'amount': 181,
              'value': true
          },
          {
              'label': 'Accelerometer',
              'key': 'accelerometer',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Action Camera',
              'key': 'action camera',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Amplifiers/Comparators',
              'key': 'amplifiers/comparators',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Antenna Switch Module',
              'key': 'antenna switch module',
              'amount': 11,
              'value': false
          },
          {
              'label': 'Applications Processor',
              'key': 'applications processor',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Audio DSP',
              'key': 'audio dsp',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Audio Headset',
              'key': 'audio headset',
              'amount': 36,
              'value': false
          },
          {
              'label': 'Baseband Controller',
              'key': 'baseband controller',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Battery',
              'key': 'battery',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Blu-ray Player/Media Player',
              'key': 'blu-ray player/media player',
              'amount': 1,
              'value': false
          },
          {
              'label': 'CDMA Cell Phone',
              'key': 'cdma cell phone',
              'amount': 32,
              'value': false
          },
          {
              'label': 'CMOS Image Sensor',
              'key': 'cmos image sensor',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Camera Module',
              'key': 'camera module',
              'amount': 18,
              'value': false
          },
          {
              'label': 'Cellular Phone/Handset',
              'key': 'cellular phone/handset',
              'amount': 2030,
              'value': false
          },
          {
              'label': 'Charge-control and Protection',
              'key': 'charge-control and protection',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Computer Tablet',
              'key': 'computer tablet',
              'amount': 2,
              'value': false
          },
          {
              'label': 'DC-DC Converter',
              'key': 'dc-dc converter',
              'amount': 2,
              'value': false
          },
          {
              'label': 'DDR3 SDRAM',
              'key': 'ddr3 sdram',
              'amount': 1,
              'value': false
          },
          {
              'label': 'DDR4 SDRAM',
              'key': 'ddr4 sdram',
              'amount': 1,
              'value': false
          },
          {
              'label': 'DRAM',
              'key': 'dram',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Digital Camera',
              'key': 'digital camera',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Digital Home: Other',
              'key': 'digital home: other',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Digital Video Recorder',
              'key': 'digital video recorder',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Display',
              'key': 'display',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Electronic Compass',
              'key': 'electronic compass',
              'amount': 25,
              'value': false
          },
          {
              'label': 'Flash',
              'key': 'flash',
              'amount': 5,
              'value': false
          },
          {
              'label': 'Front End Module',
              'key': 'front end module',
              'amount': 2,
              'value': false
          },
          {
              'label': 'GSM Cell Phone',
              'key': 'gsm cell phone',
              'amount': 140,
              'value': false
          },
          {
              'label': 'Glucose Monitor System',
              'key': 'glucose monitor system',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Infrared Sensor',
              'key': 'infrared sensor',
              'amount': 3,
              'value': false
          },
          {
              'label': 'Low Noise Amplifier',
              'key': 'low noise amplifier',
              'amount': 4,
              'value': false
          },
          {
              'label': 'Microphone',
              'key': 'microphone',
              'amount': 52,
              'value': false
          },
          {
              'label': 'Mobile Devices: Other',
              'key': 'mobile devices: other',
              'amount': 6,
              'value': false
          },
          {
              'label': 'Multi-band Handset',
              'key': 'multi-band handset',
              'amount': 4317,
              'value': false
          },
          {
              'label': 'NAND Flash',
              'key': 'nand flash',
              'amount': 4,
              'value': false
          },
          {
              'label': 'Other',
              'key': 'other',
              'amount': 5,
              'value': false
          },
          {
              'label': 'Others',
              'key': 'others',
              'amount': 29,
              'value': false
          },
          {
              'label': 'PA Duplexer Module',
              'key': 'pa duplexer module',
              'amount': 6,
              'value': false
          },
          {
              'label': 'Power Amplifiers',
              'key': 'power amplifiers',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Power Management IC',
              'key': 'power management ic',
              'amount': 3,
              'value': false
          },
          {
              'label': 'RF Switch',
              'key': 'rf switch',
              'amount': 12,
              'value': false
          },
          {
              'label': 'Set top box/Media streaming/TV Adapter',
              'key': 'set top box/media streaming/tv adapter',
              'amount': 6,
              'value': false
          },
          {
              'label': 'Smart Card IC',
              'key': 'smart card ic',
              'amount': 4,
              'value': false
          },
          {
              'label': 'Smart Watch',
              'key': 'smart watch',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Touch Controllers',
              'key': 'touch controllers',
              'amount': 4,
              'value': false
          },
          {
              'label': 'USB Adapter',
              'key': 'usb adapter',
              'amount': 3,
              'value': false
          },
          {
              'label': 'Unclassified',
              'key': 'unclassified',
              'amount': 73,
              'value': false
          },
          {
              'label': 'WiFi Module',
              'key': 'wifi module',
              'amount': 3,
              'value': false
          },
          {
              'label': 'WiFi SoC',
              'key': 'wifi soc',
              'amount': 2,
              'value': false
          }
      ]
  },
  {
      'key': 'deviceCategory',
      'label': 'Device Category',
      'subTypes': [
          {
              'label': 'Application Specific Analog',
              'key': 'application specific analog',
              'amount': 28,
              'value': false
          },
          {
              'label': 'Application Specific IC',
              'key': 'application specific ic',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Automotive',
              'key': 'automotive',
              'amount': 29,
              'value': false
          },
          {
              'label': 'Communications IC',
              'key': 'communications ic',
              'amount': 22,
              'value': false
          },
          {
              'label': 'Computing',
              'key': 'computing',
              'amount': 5,
              'value': false
          },
          {
              'label': 'Consumer Electronics',
              'key': 'consumer electronics',
              'amount': 4676,
              'value': false
          },
          {
              'label': 'Digital Home',
              'key': 'digital home',
              'amount': 13,
              'value': false
          },
          {
              'label': 'Memory',
              'key': 'memory',
              'amount': 13,
              'value': false
          },
          {
              'label': 'Microcomponent',
              'key': 'microcomponent',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Microcontrollers',
              'key': 'microcontrollers',
              'amount': 3,
              'value': false
          },
          {
              'label': 'Mobile Devices',
              'key': 'mobile devices',
              'amount': 2036,
              'value': false
          },
          {
              'label': 'Optical Devices',
              'key': 'optical devices',
              'amount': 3,
              'value': false
          },
          {
              'label': 'Power Management IC',
              'key': 'power management ic',
              'amount': 3,
              'value': false
          },
          {
              'label': 'Sensors & Actuators',
              'key': 'sensors & actuators',
              'amount': 104,
              'value': false
          },
          {
              'label': 'Standard Linear / Power',
              'key': 'standard linear / power',
              'amount': 13,
              'value': false
          },
          {
              'label': 'Unclassified',
              'key': 'unclassified',
              'amount': 5,
              'value': false
          },
          {
              'label': 'Wearable',
              'key': 'wearable',
              'amount': 36,
              'value': false
          },
          {
              'label': 'Wearable & Wellness',
              'key': 'wearable & wellness',
              'amount': 7,
              'value': false
          }
      ]
  },
  {
      'key': 'ultimateParent',
      'label': 'Ultimate Parent',
      'subTypes': [
          {
              'label': 'AAC Technologies',
              'key': 'aac technologies',
              'amount': 24,
              'value': false
          },
          {
              'label': 'AT&T',
              'key': 'at&t',
              'amount': 4,
              'value': false
          },
          {
              'label': 'AgaMatrix Inc.',
              'key': 'agamatrix inc.',
              'amount': 1,
              'value': false
          },
          {
              'label': 'AliveCor',
              'key': 'alivecor',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Amazon',
              'key': 'amazon',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Analog Devices',
              'key': 'analog devices',
              'amount': 3,
              'value': false
          },
          {
              'label': 'Apple',
              'key': 'apple',
              'amount': 6782,
              'value': false
          },
          {
              'label': 'Asahi Kasei',
              'key': 'asahi kasei',
              'amount': 1,
              'value': false
          },
          {
              'label': 'BMW',
              'key': 'bmw',
              'amount': 29,
              'value': false
          },
          {
              'label': 'Berkshire Hathaway',
              'key': 'berkshire hathaway',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Broadcom',
              'key': 'broadcom',
              'amount': 12,
              'value': false
          },
          {
              'label': 'Brookstone',
              'key': 'brookstone',
              'amount': 1,
              'value': false
          },
          {
              'label': 'CECT',
              'key': 'cect',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Cloud Engines, Inc.',
              'key': 'cloud engines, inc.',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Cobra Electronics',
              'key': 'cobra electronics',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Contour',
              'key': 'contour',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Dover',
              'key': 'dover',
              'amount': 14,
              'value': false
          },
          {
              'label': 'EPCOS',
              'key': 'epcos',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Eye-Fi',
              'key': 'eye-fi',
              'amount': 1,
              'value': false
          },
          {
              'label': 'FLIR Systems, Inc.',
              'key': 'flir systems, inc.',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Google',
              'key': 'google',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Griffin',
              'key': 'griffin',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Haier',
              'key': 'haier',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Honeywell',
              'key': 'honeywell',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Huawei',
              'key': 'huawei',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Hynix',
              'key': 'hynix',
              'amount': 6,
              'value': false
          },
          {
              'label': 'Im S.p.A.',
              'key': 'im s.p.a.',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Infineon',
              'key': 'infineon',
              'amount': 5,
              'value': false
          },
          {
              'label': 'LifeScan',
              'key': 'lifescan',
              'amount': 1,
              'value': false
          },
          {
              'label': 'MALATA',
              'key': 'malata',
              'amount': 1,
              'value': false
          },
          {
              'label': 'MITSUI MINING & SMELTING CO., LTD.',
              'key': 'mitsui mining & smelting co., ltd.',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Martian',
              'key': 'martian',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Maxim',
              'key': 'maxim',
              'amount': 5,
              'value': false
          },
          {
              'label': 'Meizu',
              'key': 'meizu',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Micron Technology',
              'key': 'micron technology',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Murata',
              'key': 'murata',
              'amount': 28,
              'value': false
          },
          {
              'label': 'NXP Semiconductors',
              'key': 'nxp semiconductors',
              'amount': 4,
              'value': false
          },
          {
              'label': 'Panasonic',
              'key': 'panasonic',
              'amount': 6,
              'value': false
          },
          {
              'label': 'Qorvo',
              'key': 'qorvo',
              'amount': 19,
              'value': false
          },
          {
              'label': 'SS&C',
              'key': 'ss&c',
              'amount': 22,
              'value': false
          },
          {
              'label': 'STMicroelectronics',
              'key': 'stmicroelectronics',
              'amount': 3,
              'value': false
          },
          {
              'label': 'Samsung',
              'key': 'samsung',
              'amount': 3,
              'value': false
          },
          {
              'label': 'Skyworks',
              'key': 'skyworks',
              'amount': 5,
              'value': false
          },
          {
              'label': 'Sony',
              'key': 'sony',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Texas Instruments',
              'key': 'texas instruments',
              'amount': 15,
              'value': false
          },
          {
              'label': 'Universal Scientific Industrial',
              'key': 'universal scientific industrial',
              'amount': 5,
              'value': false
          },
          {
              'label': 'Unknown',
              'key': 'unknown',
              'amount': 53,
              'value': false
          },
          {
              'label': 'Western Digital',
              'key': 'western digital',
              'amount': 3,
              'value': false
          },
          {
              'label': 'Xiaomi',
              'key': 'xiaomi',
              'amount': 2,
              'value': false
          },
          {
              'label': 'iHealth Lab Inc.',
              'key': 'ihealth lab inc.',
              'amount': 1,
              'value': false
          }
      ]
  },
  {
      'key': 'marketAvailability',
      'label': 'Market Availability',
      'subTypes': [
          {
              'label': '2009-06-08',
              'key': '2009-06-08',
              'amount': 78,
              'value': false
          },
          {
              'label': '2010-06-28',
              'key': '2010-06-28',
              'amount': 246,
              'value': false
          },
          {
              'label': '2011-01-11',
              'key': '2011-01-11',
              'amount': 30,
              'value': false
          },
          {
              'label': '2012-06-04',
              'key': '2012-06-04',
              'amount': 114,
              'value': false
          },
          {
              'label': '2012-09-21',
              'key': '2012-09-21',
              'amount': 162,
              'value': false
          },
          {
              'label': '2012-10-01',
              'key': '2012-10-01',
              'amount': 28,
              'value': false
          },
          {
              'label': '2013-09-20',
              'key': '2013-09-20',
              'amount': 137,
              'value': false
          },
          {
              'label': '2013-10-01',
              'key': '2013-10-01',
              'amount': 37,
              'value': false
          },
          {
              'label': '2013-10-03',
              'key': '2013-10-03',
              'amount': 46,
              'value': false
          },
          {
              'label': '2013-11-18',
              'key': '2013-11-18',
              'amount': 44,
              'value': false
          },
          {
              'label': '2014-05-15',
              'key': '2014-05-15',
              'amount': 63,
              'value': false
          },
          {
              'label': '2014-09-09',
              'key': '2014-09-09',
              'amount': 44,
              'value': false
          },
          {
              'label': '2014-09-19',
              'key': '2014-09-19',
              'amount': 202,
              'value': false
          },
          {
              'label': '2014-09-23',
              'key': '2014-09-23',
              'amount': 71,
              'value': false
          },
          {
              'label': '2014-10-30',
              'key': '2014-10-30',
              'amount': 44,
              'value': false
          },
          {
              'label': '2015-02-09',
              'key': '2015-02-09',
              'amount': 110,
              'value': false
          },
          {
              'label': '2015-08-19',
              'key': '2015-08-19',
              'amount': 152,
              'value': false
          },
          {
              'label': '2015-09-25',
              'key': '2015-09-25',
              'amount': 146,
              'value': false
          },
          {
              'label': '2015-09-28',
              'key': '2015-09-28',
              'amount': 114,
              'value': false
          },
          {
              'label': '2015-10-20',
              'key': '2015-10-20',
              'amount': 100,
              'value': false
          },
          {
              'label': '2016-03-30',
              'key': '2016-03-30',
              'amount': 102,
              'value': false
          },
          {
              'label': '2016-04-14',
              'key': '2016-04-14',
              'amount': 62,
              'value': false
          },
          {
              'label': '2016-09-07',
              'key': '2016-09-07',
              'amount': 102,
              'value': false
          },
          {
              'label': '2016-09-15',
              'key': '2016-09-15',
              'amount': 261,
              'value': false
          },
          {
              'label': '2016-09-20',
              'key': '2016-09-20',
              'amount': 62,
              'value': false
          },
          {
              'label': '2016-11-08',
              'key': '2016-11-08',
              'amount': 111,
              'value': false
          },
          {
              'label': '2017-09-12',
              'key': '2017-09-12',
              'amount': 194,
              'value': false
          },
          {
              'label': '2017-09-22',
              'key': '2017-09-22',
              'amount': 101,
              'value': false
          },
          {
              'label': '2017-09-25',
              'key': '2017-09-25',
              'amount': 50,
              'value': false
          },
          {
              'label': '2017-09-26',
              'key': '2017-09-26',
              'amount': 54,
              'value': false
          },
          {
              'label': '2017-10-26',
              'key': '2017-10-26',
              'amount': 44,
              'value': false
          },
          {
              'label': '2017-11-08',
              'key': '2017-11-08',
              'amount': 36,
              'value': false
          },
          {
              'label': '2018-02-09',
              'key': '2018-02-09',
              'amount': 81,
              'value': false
          },
          {
              'label': '2018-07-13',
              'key': '2018-07-13',
              'amount': 39,
              'value': false
          },
          {
              'label': '2018-09-20',
              'key': '2018-09-20',
              'amount': 151,
              'value': false
          },
          {
              'label': '2018-09-21',
              'key': '2018-09-21',
              'amount': 170,
              'value': false
          },
          {
              'label': '2018-09-28',
              'key': '2018-09-28',
              'amount': 94,
              'value': false
          },
          {
              'label': '2018-10-25',
              'key': '2018-10-25',
              'amount': 226,
              'value': false
          },
          {
              'label': '2018-11-12',
              'key': '2018-11-12',
              'amount': 64,
              'value': false
          },
          {
              'label': '2018-11-22',
              'key': '2018-11-22',
              'amount': 31,
              'value': false
          },
          {
              'label': '2019-02-15',
              'key': '2019-02-15',
              'amount': 91,
              'value': false
          },
          {
              'label': '2019-09-20',
              'key': '2019-09-20',
              'amount': 985,
              'value': false
          },
          {
              'label': '2020-03-05',
              'key': '2020-03-05',
              'amount': 337,
              'value': false
          },
          {
              'label': '2020-05-11',
              'key': '2020-05-11',
              'amount': 112,
              'value': false
          },
          {
              'label': '2020-10-23',
              'key': '2020-10-23',
              'amount': 180,
              'value': false
          },
          {
              'label': '2020-10-26',
              'key': '2020-10-26',
              'amount': 101,
              'value': false
          },
          {
              'label': '2020-10-27',
              'key': '2020-10-27',
              'amount': 50,
              'value': false
          },
          {
              'label': '2020-11-13',
              'key': '2020-11-13',
              'amount': 69,
              'value': false
          },
          {
              'label': '2020-11-16',
              'key': '2020-11-16',
              'amount': 173,
              'value': false
          },
          {
              'label': '2020-11-24',
              'key': '2020-11-24',
              'amount': 88,
              'value': false
          }
      ]
  },
  {
      'key': 'countryOfPurchase',
      'label': 'Country Of Purchase',
      'subTypes': [
          {
              'label': 'Australia',
              'key': 'australia',
              'amount': 57,
              'value': false
          },
          {
              'label': 'Brazil',
              'key': 'brazil',
              'amount': 195,
              'value': false
          },
          {
              'label': 'Canada',
              'key': 'canada',
              'amount': 1974,
              'value': false
          },
          {
              'label': 'China',
              'key': 'china',
              'amount': 508,
              'value': false
          },
          {
              'label': 'Europe',
              'key': 'europe',
              'amount': 117,
              'value': false
          },
          {
              'label': 'France',
              'key': 'france',
              'amount': 5,
              'value': false
          },
          {
              'label': 'Germany',
              'key': 'germany',
              'amount': 340,
              'value': false
          },
          {
              'label': 'Hong Kong',
              'key': 'hong kong',
              'amount': 14,
              'value': false
          },
          {
              'label': 'Italy',
              'key': 'italy',
              'amount': 4,
              'value': false
          },
          {
              'label': 'Japan',
              'key': 'japan',
              'amount': 142,
              'value': false
          },
          {
              'label': 'Korea',
              'key': 'korea',
              'amount': 47,
              'value': false
          },
          {
              'label': 'Malaysia',
              'key': 'malaysia',
              'amount': 115,
              'value': false
          },
          {
              'label': 'Mexico',
              'key': 'mexico',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Netherlands',
              'key': 'netherlands',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Poland',
              'key': 'poland',
              'amount': 9,
              'value': false
          },
          {
              'label': 'Taiwan',
              'key': 'taiwan',
              'amount': 44,
              'value': false
          },
          {
              'label': 'UK',
              'key': 'uk',
              'amount': 9,
              'value': false
          },
          {
              'label': 'United States',
              'key': 'united states',
              'amount': 3455,
              'value': false
          },
          {
              'label': 'unknown',
              'key': 'unknown',
              'amount': 50,
              'value': false
          }
      ]
  },
  {
      'key': 'dateOfPurchase',
      'label': 'Purchase Date',
      'subTypes': [
          {
              'label': '2009-11-03',
              'key': '2009-11-03',
              'amount': 78,
              'value': false
          },
          {
              'label': '2010-06-24',
              'key': '2010-06-24',
              'amount': 252,
              'value': false
          },
          {
              'label': '2012-06-04',
              'key': '2012-06-04',
              'amount': 114,
              'value': false
          },
          {
              'label': '2012-09-21',
              'key': '2012-09-21',
              'amount': 175,
              'value': false
          },
          {
              'label': '2013-09-20',
              'key': '2013-09-20',
              'amount': 170,
              'value': false
          },
          {
              'label': '2013-10-01',
              'key': '2013-10-01',
              'amount': 39,
              'value': false
          },
          {
              'label': '2013-10-03',
              'key': '2013-10-03',
              'amount': 57,
              'value': false
          },
          {
              'label': '2013-11-18',
              'key': '2013-11-18',
              'amount': 44,
              'value': false
          },
          {
              'label': '2014-05-15',
              'key': '2014-05-15',
              'amount': 67,
              'value': false
          },
          {
              'label': '2014-09-19',
              'key': '2014-09-19',
              'amount': 215,
              'value': false
          },
          {
              'label': '2014-09-23',
              'key': '2014-09-23',
              'amount': 70,
              'value': false
          },
          {
              'label': '2014-10-30',
              'key': '2014-10-30',
              'amount': 44,
              'value': false
          },
          {
              'label': '2015-02-09',
              'key': '2015-02-09',
              'amount': 115,
              'value': false
          },
          {
              'label': '2015-08-19',
              'key': '2015-08-19',
              'amount': 195,
              'value': false
          },
          {
              'label': '2015-09-25',
              'key': '2015-09-25',
              'amount': 156,
              'value': false
          },
          {
              'label': '2015-09-28',
              'key': '2015-09-28',
              'amount': 120,
              'value': false
          },
          {
              'label': '2015-10-20',
              'key': '2015-10-20',
              'amount': 103,
              'value': false
          },
          {
              'label': '2015-11-06',
              'key': '2015-11-06',
              'amount': 37,
              'value': false
          },
          {
              'label': '2016-03-30',
              'key': '2016-03-30',
              'amount': 103,
              'value': false
          },
          {
              'label': '2016-04-14',
              'key': '2016-04-14',
              'amount': 62,
              'value': false
          },
          {
              'label': '2016-09-15',
              'key': '2016-09-15',
              'amount': 261,
              'value': false
          },
          {
              'label': '2016-09-20',
              'key': '2016-09-20',
              'amount': 61,
              'value': false
          },
          {
              'label': '2016-11-08',
              'key': '2016-11-08',
              'amount': 110,
              'value': false
          },
          {
              'label': '2016-11-10',
              'key': '2016-11-10',
              'amount': 62,
              'value': false
          },
          {
              'label': '2016-12-02',
              'key': '2016-12-02',
              'amount': 40,
              'value': false
          },
          {
              'label': '2017-09-22',
              'key': '2017-09-22',
              'amount': 102,
              'value': false
          },
          {
              'label': '2017-09-25',
              'key': '2017-09-25',
              'amount': 49,
              'value': false
          },
          {
              'label': '2017-09-26',
              'key': '2017-09-26',
              'amount': 54,
              'value': false
          },
          {
              'label': '2017-10-26',
              'key': '2017-10-26',
              'amount': 44,
              'value': false
          },
          {
              'label': '2017-11-02',
              'key': '2017-11-02',
              'amount': 186,
              'value': false
          },
          {
              'label': '2017-11-08',
              'key': '2017-11-08',
              'amount': 36,
              'value': false
          },
          {
              'label': '2018-07-13',
              'key': '2018-07-13',
              'amount': 61,
              'value': false
          },
          {
              'label': '2018-08-13',
              'key': '2018-08-13',
              'amount': 52,
              'value': false
          },
          {
              'label': '2018-09-21',
              'key': '2018-09-21',
              'amount': 321,
              'value': false
          },
          {
              'label': '2018-10-25',
              'key': '2018-10-25',
              'amount': 225,
              'value': false
          },
          {
              'label': '2018-11-12',
              'key': '2018-11-12',
              'amount': 64,
              'value': false
          },
          {
              'label': '2019-02-05',
              'key': '2019-02-05',
              'amount': 93,
              'value': false
          },
          {
              'label': '2019-02-15',
              'key': '2019-02-15',
              'amount': 91,
              'value': false
          },
          {
              'label': '2019-09-19',
              'key': '2019-09-19',
              'amount': 318,
              'value': false
          },
          {
              'label': '2019-09-20',
              'key': '2019-09-20',
              'amount': 323,
              'value': false
          },
          {
              'label': '2019-09-24',
              'key': '2019-09-24',
              'amount': 85,
              'value': false
          },
          {
              'label': '2019-09-26',
              'key': '2019-09-26',
              'amount': 258,
              'value': false
          },
          {
              'label': '2020-03-05',
              'key': '2020-03-05',
              'amount': 336,
              'value': false
          },
          {
              'label': '2020-05-11',
              'key': '2020-05-11',
              'amount': 112,
              'value': false
          },
          {
              'label': '2020-10-23',
              'key': '2020-10-23',
              'amount': 182,
              'value': false
          },
          {
              'label': '2020-10-26',
              'key': '2020-10-26',
              'amount': 99,
              'value': false
          },
          {
              'label': '2020-10-27',
              'key': '2020-10-27',
              'amount': 50,
              'value': false
          },
          {
              'label': '2020-11-13',
              'key': '2020-11-13',
              'amount': 69,
              'value': false
          },
          {
              'label': '2020-11-16',
              'key': '2020-11-16',
              'amount': 173,
              'value': false
          },
          {
              'label': '2020-11-24',
              'key': '2020-11-24',
              'amount': 88,
              'value': false
          }
      ]
  },
  {
      'key': 'yearOfPurchase',
      'label': 'Year Of Purchase',
      'subTypes': [
          {
              'label': '2007',
              'key': '2007',
              'amount': 2,
              'value': false
          },
          {
              'label': '2008',
              'key': '2008',
              'amount': 4,
              'value': false
          },
          {
              'label': '2009',
              'key': '2009',
              'amount': 85,
              'value': false
          },
          {
              'label': '2010',
              'key': '2010',
              'amount': 264,
              'value': false
          },
          {
              'label': '2011',
              'key': '2011',
              'amount': 196,
              'value': false
          },
          {
              'label': '2012',
              'key': '2012',
              'amount': 351,
              'value': false
          },
          {
              'label': '2013',
              'key': '2013',
              'amount': 368,
              'value': false
          },
          {
              'label': '2014',
              'key': '2014',
              'amount': 491,
              'value': false
          },
          {
              'label': '2015',
              'key': '2015',
              'amount': 830,
              'value': false
          },
          {
              'label': '2016',
              'key': '2016',
              'amount': 790,
              'value': false
          },
          {
              'label': '2017',
              'key': '2017',
              'amount': 525,
              'value': false
          },
          {
              'label': '2018',
              'key': '2018',
              'amount': 781,
              'value': false
          },
          {
              'label': '2019',
              'key': '2019',
              'amount': 1223,
              'value': false
          },
          {
              'label': '2020',
              'key': '2020',
              'amount': 1120,
              'value': false
          },
          {
              'label': '2021',
              'key': '2021',
              'amount': 3,
              'value': false
          }
      ]
  },
  {
      'key': 'material',
      'label': 'Package Material',
      'subTypes': [
          {
              'label': 'Ceramic (GENERIC)',
              'key': 'ceramic (generic)',
              'amount': 4,
              'value': false
          },
          {
              'label': 'Metal (GENERIC)',
              'key': 'metal (generic)',
              'amount': 10,
              'value': false
          },
          {
              'label': 'Plastic (GENERIC)',
              'key': 'plastic (generic)',
              'amount': 57,
              'value': false
          },
          {
              'label': 'Plastic Package with Leadframe',
              'key': 'plastic package with leadframe',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Plastic Package with Organic PWB',
              'key': 'plastic package with organic pwb',
              'amount': 3,
              'value': false
          }
      ]
  },
  {
      'key': 'packageType',
      'label': 'Package Type',
      'subTypes': [
          {
              'label': 'BGA GENERIC (Ball Grid Array)',
              'key': 'bga generic (ball grid array)',
              'amount': 21,
              'value': false
          },
          {
              'label': 'BGA-PBGA (BGA with mold cap)',
              'key': 'bga-pbga (bga with mold cap)',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Camera Module (GENERIC)',
              'key': 'camera module (generic)',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Camera Module - Auto Focus',
              'key': 'camera module - auto focus',
              'amount': 2,
              'value': false
          },
          {
              'label': 'Camera Module - Fixed Focus',
              'key': 'camera module - fixed focus',
              'amount': 1,
              'value': false
          },
          {
              'label': 'DFN (Dual Flat no leads)',
              'key': 'dfn (dual flat no leads)',
              'amount': 3,
              'value': false
          },
          {
              'label': 'DPAC SO-Type',
              'key': 'dpac so-type',
              'amount': 1,
              'value': false
          },
          {
              'label': 'LGA (Land Grid Array)',
              'key': 'lga (land grid array)',
              'amount': 18,
              'value': false
          },
          {
              'label': 'MCP',
              'key': 'mcp',
              'amount': 1,
              'value': false
          },
          {
              'label': 'Others',
              'key': 'others',
              'amount': 1,
              'value': false
          },
          {
              'label': 'QFN (Quad Flat no leads)',
              'key': 'qfn (quad flat no leads)',
              'amount': 8,
              'value': false
          },
          {
              'label': 'SIP (Single-in-line Package)',
              'key': 'sip (single-in-line package)',
              'amount': 1,
              'value': false
          },
          {
              'label': 'SO GENERIC (Small Outline)',
              'key': 'so generic (small outline)',
              'amount': 1,
              'value': false
          },
          {
              'label': 'SO-SOP (Small Outline Package)',
              'key': 'so-sop (small outline package)',
              'amount': 1,
              'value': false
          },
          {
              'label': 'SO-TSOP (Thin profile Small Outline Package)',
              'key': 'so-tsop (thin profile small outline package)',
              'amount': 1,
              'value': false
          },
          {
              'label': 'WLP GENERIC (Wafer Level Package)',
              'key': 'wlp generic (wafer level package)',
              'amount': 12,
              'value': false
          },
          {
              'label': 'WLP-FIWLP (fan-in-WLP)',
              'key': 'wlp-fiwlp (fan-in-wlp)',
              'amount': 5,
              'value': false
          }
      ]
  },
  {
      'key': 'interconnectType',
      'label': 'Package Interconnect Type',
      'subTypes': [
          {
              'label': 'Flip chip (GENERIC)',
              'key': 'flip chip (generic)',
              'amount': 9,
              'value': false
          },
          {
              'label': 'Flip-chip (solder bump)',
              'key': 'flip-chip (solder bump)',
              'amount': 11,
              'value': false
          },
          {
              'label': 'Flip-chip+Wire bond',
              'key': 'flip-chip+wire bond',
              'amount': 3,
              'value': false
          },
          {
              'label': 'RDL (WLP)',
              'key': 'rdl (wlp)',
              'amount': 4,
              'value': false
          },
          {
              'label': 'Wire Bond (Au)',
              'key': 'wire bond (au)',
              'amount': 13,
              'value': false
          },
          {
              'label': 'Wire Bond (Cu)',
              'key': 'wire bond (cu)',
              'amount': 4,
              'value': false
          },
          {
              'label': 'Wire bond (generic)',
              'key': 'wire bond (generic)',
              'amount': 8,
              'value': false
          }
      ]
  },
  {
      'key': 'processType',
      'label': 'Process Type',
      'subTypes': [
          {
              'label': 'CIS',
              'key': 'cis',
              'amount': 1,
              'value': false
          },
          {
              'label': 'CMOS',
              'key': 'cmos',
              'amount': 1,
              'value': false
          },
          {
              'label': 'CMOS-Planar-Poly-Gate',
              'key': 'cmos-planar-poly-gate',
              'amount': 4,
              'value': false
          },
          {
              'label': 'NVM - Floating Gate',
              'key': 'nvm - floating gate',
              'amount': 1,
              'value': false
          }
      ]
  },
  {
      'key': 'dieDate',
      'label': 'Die Date',
      'subTypes': [
          {
              'label': '2002',
              'key': '2002',
              'amount': 1,
              'value': false
          },
          {
              'label': '2009',
              'key': '2009',
              'amount': 1,
              'value': false
          },
          {
              'label': '2010',
              'key': '2010',
              'amount': 1,
              'value': false
          },
          {
              'label': '2011',
              'key': '2011',
              'amount': 3,
              'value': false
          }
      ]
  },
  {
      'key': 'eDataCategory',
      'label': 'Image Category',
      'subTypes': [
          {
              'label': 'Perspective: Box',
              'key': 'perspective: box',
              'amount': 487,
              'value': false
          },
          {
              'label': 'Perspective: External',
              'key': 'perspective: external',
              'amount': 821,
              'value': false
          },
          {
              'label': 'Perspective: Internal',
              'key': 'perspective: internal',
              'amount': 3869,
              'value': false
          },
          {
              'label': 'Pixel Array',
              'key': 'pixel array',
              'amount': 128,
              'value': false
          },
          {
              'label': 'Product Photos',
              'key': 'product photos',
              'amount': 1100,
              'value': false
          }
      ]
  },
  {
      'key': 'reportModuleType',
      'label': 'Report Module Type',
      'subTypes': [
          {
              'label': 'Memory Functional Analysis Repor',
              'key': 'memory functional analysis repor',
              'amount': 1,
              'value': false
          }
      ]
  },
  {
      'key': 'reportModule',
      'label': 'Report Module',
      'subTypes': [
          {
              'label': 'MFR',
              'key': 'mfr',
              'amount': 1,
              'value': false
          }
      ]
  }
];


export const mockSearchReturn: ISearchResponse = {
  response: [
    {
      id: '5f903089e2e6171cd6be9e35',
      thumbnail: 'https://placehold.co/280x210',
      name: 'aute',
      manufacturer: 'minim',
      partNumber: 'NOSTRUD-1989-148',
      deviceType: 'Lorem officia amet',
      contentType: 'report',
      commonName: 'QUIS-4665-210',
      creationDate: new Date('Mon Mar 20 2017 23:37:46 GMT+0000'),
      purchaseDate: new Date('Fri Sep 11 2015 00:52:57 GMT+0000'),
      publishDate: new Date('Thu Feb 07 2019 17:36:07 GMT+0000'),
      features: 'ipsum in',
      perspective: 'consectetur',
      channelIndicators: [
        'dolor est',
        'reprehenderit fugiat'
      ],
      genealogyCode: 'some-gen-code',
      reportCode: 'mollit aute',
      reportCategory: 'fugiat',
      productName: 'Survey Plus Teardown of the Microsoft Lumia 650 RM-1150 Smartphone',
      modelNumber: 'eu',
      country: 'Oman',
      MAD: '6a45f397-4f2d-4255',
      idempotentId: 'mockIdempotentId-4f2d-425',
    },
    {
      id: '5f903089174281ffe42cc681',
      thumbnail: 'https://placehold.co/280x210',
      name: 'ea',
      manufacturer: 'exercitation',
      partNumber: 'ET-2000-952',
      deviceType: 'Lorem enim Lorem',
      contentType: 'product',
      commonName: 'VENIAM-3741-634',
      creationDate: new Date('Tue Jul 17 2018 13:12:54 GMT+0000'),
      purchaseDate: new Date('Sun Feb 07 2016 17:07:43 GMT+0000'),
      publishDate: new Date('Thu Oct 29 2015 07:29:07 GMT+0000'),
      features: 'fugiat voluptate',
      perspective: 'irure',
      channelIndicators: [
        'sit irure',
        'occaecat fugiat',
        'minim ipsum',
        'pariatur minim'
      ],
      reportCode: 'reprehenderit est',
      reportCategory: 'ex',
      modelNumber: 'aliqua',
      country: 'Equatorial Guinea',
      MAD: '0b02b6dc-e23d-4f69',
      idempotentId: 'mockIdempotentId-e23d-4f6',
    },
    {
      id: '5f90308912ad27194c0e4555',
      thumbnail: 'https://placehold.co/280x210',
      name: 'sint',
      manufacturer: 'aute',
      partNumber: 'MINIM-4412-664',
      deviceType: 'adipisicing laboris incididunt',
      contentType: 'schematic',
      commonName: 'ANIM-1204-585',
      creationDate: new Date('Mon Mar 23 2015 12:39:03 GMT+0000'),
      purchaseDate: new Date('Sat Nov 30 2019 10:59:11 GMT+0000'),
      publishDate: new Date('Sat Apr 27 2019 17:09:16 GMT+0000'),
      features: 'tempor anim',
      perspective: 'eiusmod',
      channelIndicators: [
        'consectetur cillum',
        'do dolor',
        'pariatur culpa',
        'non amet'
      ],
      genealogyCode: 'some-schem-gen-code',
      reportCode: 'incididunt veniam',
      reportCategory: 'ipsum',
      modelNumber: 'labore',
      country: 'East Timor',
      MAD: '38405d06-cfed-4055',
      idempotentId: 'mockIdempotentId-cfed-405',
    },
    {
      id: '5f9030892617e2de3a59316d',
      thumbnail: 'https://placehold.co/280x210',
      name: 'adipisicing',
      manufacturer: 'consequat',
      partNumber: 'FUGIAT-4752-649',
      deviceType: 'consequat fugiat ex',
      contentType: 'pcb',
      commonName: 'AUTE-1227-375',
      creationDate: new Date('Thu Nov 24 2016 23:33:59 GMT+0000'),
      purchaseDate: new Date('Thu Jan 03 2019 05:48:42 GMT+0000'),
      publishDate: new Date('Sun Mar 29 2020 15:08:36 GMT+0000'),
      features: 'exercitation aliqua',
      perspective: 'magna',
      channelIndicators: [
        'sit officia',
        'anim elit',
        'sunt ullamco',
        'elit do'
      ],
      reportCode: 'fugiat fugiat',
      reportCategory: 'est',
      modelNumber: 'Lorem',
      country: 'American Samoa',
      MAD: 'd5703463-542e-4da2',
      idempotentId: 'mockIdempotentId-542e-4da',
    },
    {
      id: '5f903089e57cacd30fe93539',
      thumbnail: 'https://placehold.co/280x210',
      name: 'incididunt',
      manufacturer: 'reprehenderit',
      partNumber: 'IN-2566-833',
      deviceType: 'exercitation enim nulla',
      contentType: 'image',
      commonName: 'TEMPOR-2264-697',
      creationDate: new Date('Sat Jan 07 2017 05:39:13 GMT+0000'),
      purchaseDate: new Date('Mon Jul 04 2016 03:32:43 GMT+0000'),
      publishDate: new Date('Fri Jun 06 2014 15:54:00 GMT+0000'),
      features: 'laborum aliquip',
      perspective: 'mollit',
      channelIndicators: [
        'commodo mollit',
        'dolore elit',
        'culpa aliquip'
      ],
      genealogyCode: 'image-gen-code',
      reportCode: 'aliquip consectetur',
      reportCategory: 'fugiat',
      modelNumber: 'pariatur',
      country: 'Martinique',
      MAD: '5108a268-779c-4f1c',
      idempotentId: 'mockIdempotentId-779c-4f1',
    },
    {
      id: '5f903089cbadff8f416120c7',
      thumbnail: 'https://placehold.co/280x210',
      name: 'consectetur',
      manufacturer: 'esse',
      partNumber: 'PARIATUR-1390-693',
      deviceType: 'nulla in irure',
      contentType: 'die',
      commonName: 'EIUSMOD-3168-898',
      creationDate: new Date('Mon May 20 2019 23:44:54 GMT+0000'),
      purchaseDate: new Date('Fri Dec 04 2015 07:17:00 GMT+0000'),
      publishDate: new Date('Sun Oct 26 2014 08:18:32 GMT+0000'),
      features: 'magna ipsum',
      perspective: 'aute',
      channelIndicators: [
        'amet incididunt',
        'esse incididunt'
      ],
      reportCode: 'eiusmod voluptate',
      reportCategory: 'culpa',
      modelNumber: 'eiusmod',
      country: 'San Marino',
      MAD: 'a3471120-db10-47b4',
      idempotentId: 'mockIdempotentId-db10-47b',
    },
    {
      id: '5f9030894d47f66db8cc1164',
      thumbnail: 'https://placehold.co/280x210',
      name: 'Lorem',
      manufacturer: 'enim',
      partNumber: 'ET-1094-863',
      deviceType: 'id eu aliqua',
      contentType: 'image',
      commonName: 'LOREM-3346-952',
      creationDate: new Date('Thu Sep 13 2018 08:07:50 GMT+0000'),
      purchaseDate: new Date('Sat Nov 16 2019 01:19:24 GMT+0000'),
      publishDate: new Date('Tue Jan 14 2014 08:15:19 GMT+0000'),
      features: 'aute in',
      perspective: 'incididunt',
      channelIndicators: [
        'id Lorem'
      ],
      reportCode: 'quis aliqua',
      reportCategory: 'id',
      modelNumber: 'dolore',
      country: 'Italy',
      MAD: 'ab0a6130-4c68-4ab1',
      idempotentId: 'mockIdempotentId-4c68-4ab',
    },
    {
      id: '5f9030892e2e1c55bcadf068',
      thumbnail: 'https://placehold.co/280x210',
      name: 'voluptate',
      manufacturer: 'sunt',
      partNumber: 'NOSTRUD-1904-128',
      deviceType: 'laborum velit ex',
      contentType: 'image',
      commonName: 'LABORIS-3152-556',
      creationDate: new Date('Tue Apr 18 2017 17:54:52 GMT+0000'),
      purchaseDate: new Date('Thu Feb 13 2020 18:53:18 GMT+0000'),
      publishDate: new Date('Thu Sep 13 2018 18:57:23 GMT+0000'),
      features: 'laborum velit',
      perspective: 'id',
      channelIndicators: [
        'velit consectetur',
        'ullamco sit'
      ],
      reportCode: 'dolor ullamco',
      reportCategory: 'ipsum',
      modelNumber: 'eiusmod',
      country: 'Cote D\'Ivoire (Ivory Coast)',
      MAD: 'b96367b1-9dc4-4f3f',
      idempotentId: 'mockIdempotentId-9dc4-4f3',
    },
    {
      id: '5f903089da24b55513422e12',
      thumbnail: 'https://placehold.co/280x210',
      name: 'eiusmod',
      manufacturer: 'adipisicing',
      partNumber: 'MOLLIT-1793-429',
      deviceType: 'Lorem veniam non',
      contentType: 'die',
      commonName: 'INCIDIDUNT-1612-978',
      creationDate: new Date('Wed Apr 08 2015 09:20:33 GMT+0000'),
      purchaseDate: new Date('Sun Apr 30 2017 15:43:35 GMT+0000'),
      publishDate: new Date('Fri Jan 02 2015 15:24:55 GMT+0000'),
      features: 'deserunt pariatur',
      perspective: 'occaecat',
      channelIndicators: [
        'deserunt anim',
        'nostrud dolor',
        'consectetur nulla'
      ],
      reportCode: 'consequat nulla',
      reportCategory: 'eiusmod',
      modelNumber: 'Lorem',
      country: 'Iran',
      MAD: '1fe44d97-ec9a-466a',
      idempotentId: 'mockIdempotentId-ec9a-466',
    },
    {
      id: '5f903089f92277bc4760d5d5',
      thumbnail: 'https://placehold.co/280x210',
      name: 'mollit',
      manufacturer: 'non',
      partNumber: 'QUI-4402-627',
      deviceType: 'do ipsum excepteur',
      contentType: 'die',
      commonName: 'VELIT-3770-536',
      creationDate: new Date('Wed Feb 10 2016 06:22:21 GMT+0000'),
      purchaseDate: new Date('Thu Jul 20 2017 22:28:38 GMT+0000'),
      publishDate: new Date('Fri Jun 16 2017 11:52:21 GMT+0000'),
      features: 'Lorem eu',
      perspective: 'aliquip',
      channelIndicators: [
        'laborum in',
        'occaecat laboris',
        'qui irure',
        'anim nisi'
      ],
      reportCode: 'amet ut',
      reportCategory: 'esse',
      modelNumber: 'eiusmod',
      country: 'Central African Republic',
      MAD: '9c701b9a-bd8b-444f',
      idempotentId: 'mockIdempotentId-bd8b-444',
    },
    {
      id: '5f903089f0617a940c8c3c47',
      thumbnail: 'https://placehold.co/280x210',
      name: 'qui',
      manufacturer: 'esse',
      partNumber: 'EU-3808-636',
      deviceType: 'enim ex mollit',
      contentType: 'report',
      commonName: 'OCCAECAT-4675-569',
      creationDate: new Date('Thu Jun 30 2016 22:25:46 GMT+0000'),
      purchaseDate: new Date('Tue Jan 28 2020 10:07:44 GMT+0000'),
      publishDate: new Date('Tue Feb 05 2019 06:15:25 GMT+0000'),
      features: 'veniam ipsum',
      perspective: 'fugiat',
      channelIndicators: [
        'ipsum amet'
      ],
      reportCode: 'consectetur nisi',
      reportCategory: 'sit',
      modelNumber: 'magna',
      country: 'New Caledonia',
      MAD: '6cbb35a0-a54b-4c4e',
      idempotentId: 'mockIdempotentId-a54b-4c4',
    },
    {
      id: '5f90308927de66e7e9fe7ecc',
      thumbnail: 'https://placehold.co/280x210',
      name: 'do',
      manufacturer: 'tempor',
      partNumber: 'LABORIS-3215-626',
      deviceType: 'tempor reprehenderit voluptate',
      contentType: 'die',
      commonName: 'ALIQUA-4263-465',
      creationDate: new Date('Sun Dec 04 2016 12:37:04 GMT+0000'),
      purchaseDate: new Date('Thu Feb 05 2015 16:07:41 GMT+0000'),
      publishDate: new Date('Sun Jun 28 2020 00:35:22 GMT+0000'),
      features: 'commodo anim',
      perspective: 'consequat',
      channelIndicators: [
        'fugiat reprehenderit',
        'magna exercitation',
        'labore ut',
        'anim do'
      ],
      reportCode: 'ad enim',
      reportCategory: 'esse',
      modelNumber: 'sunt',
      country: 'South Africa',
      MAD: '9bd16013-6608-4f99',
      idempotentId: 'mockIdempotentId-6608-4f9',
    },
    {
      id: '5f903089a4fb1b1758f237c8',
      thumbnail: 'https://placehold.co/280x210',
      name: 'amet',
      manufacturer: 'ipsum',
      partNumber: 'CILLUM-1455-945',
      deviceType: 'aliqua ut dolore',
      contentType: 'product',
      commonName: 'LABORIS-4427-993',
      creationDate: new Date('Wed Jan 13 2016 15:05:34 GMT+0000'),
      purchaseDate: new Date('Mon Mar 02 2020 12:37:47 GMT+0000'),
      publishDate: new Date('Wed May 10 2017 07:40:32 GMT+0000'),
      features: 'excepteur aliqua',
      perspective: 'sit',
      channelIndicators: [
        'cupidatat qui',
        'cupidatat nostrud',
        'exercitation duis',
        'qui incididunt'
      ],
      reportCode: 'velit do',
      reportCategory: 'voluptate',
      modelNumber: 'consectetur',
      country: 'Cambodia',
      MAD: '6c134299-8dbe-4004',
      idempotentId: 'mockIdempotentId-8dbe-400',
    },
    {
      id: '5f9030898dd0a9fa7fc51519',
      thumbnail: 'https://placehold.co/280x210',
      name: 'commodo',
      manufacturer: 'sit',
      partNumber: 'QUI-4833-732',
      deviceType: 'consequat tempor excepteur',
      contentType: 'component',
      commonName: 'VOLUPTATE-3930-151',
      creationDate: new Date('Sat Jul 30 2016 16:53:21 GMT+0000'),
      purchaseDate: new Date('Wed Oct 28 2015 03:15:53 GMT+0000'),
      publishDate: new Date('Sat Dec 23 2017 14:06:36 GMT+0000'),
      features: 'ullamco excepteur',
      perspective: 'quis',
      channelIndicators: [
        'consectetur duis',
        'sint nostrud'
      ],
      reportCode: 'reprehenderit eiusmod',
      reportCategory: 'in',
      modelNumber: 'pariatur',
      country: 'Togo',
      MAD: 'f2b2d4de-4cd9-4456',
      idempotentId: 'mockIdempotentId-4cd9-445',
    },
    {
      id: '5f903089501bfc9905fba445',
      thumbnail: 'https://placehold.co/280x210',
      name: 'amet',
      manufacturer: 'anim',
      partNumber: 'CULPA-1050-744',
      deviceType: 'cillum sint dolor',
      contentType: 'die',
      commonName: 'ADIPISICING-2158-403',
      creationDate: new Date('Mon Mar 12 2018 15:18:39 GMT+0000'),
      purchaseDate: new Date('Wed Aug 06 2014 20:05:20 GMT+0000'),
      publishDate: new Date('Fri Nov 21 2014 00:31:27 GMT+0000'),
      features: 'et ea',
      perspective: 'consequat',
      channelIndicators: [
        'reprehenderit voluptate',
        'sint consectetur',
        'do tempor'
      ],
      reportCode: 'pariatur duis',
      reportCategory: 'sunt',
      modelNumber: 'est',
      country: 'Syria',
      MAD: 'd8b37ea1-0fc0-46a0',
      idempotentId: 'mockIdempotentId-0fc0-46a',
    },
    {
      id: '5f90308964f0eaa86928d30a',
      thumbnail: 'https://placehold.co/280x210',
      name: 'qui',
      manufacturer: 'magna',
      partNumber: 'NOSTRUD-1603-880',
      deviceType: 'do excepteur minim',
      contentType: 'component',
      commonName: 'MOLLIT-3825-678',
      creationDate: new Date('Fri Jan 04 2019 19:46:52 GMT+0000'),
      purchaseDate: new Date('Mon Sep 14 2020 19:43:11 GMT+0000'),
      publishDate: new Date('Tue Feb 25 2020 22:09:23 GMT+0000'),
      features: 'labore qui',
      perspective: 'magna',
      channelIndicators: [
        'consequat ut',
        'consequat tempor',
        'Lorem mollit'
      ],
      reportCode: 'eiusmod exercitation',
      reportCategory: 'aliquip',
      modelNumber: 'in',
      country: 'Northern Mariana Islands',
      MAD: '8aaa1430-34c6-4d99',
      idempotentId: 'mockIdempotentId-34c6-4d9',
    },
    {
      id: '5f903089eb56ec12977619ef',
      thumbnail: 'https://placehold.co/280x210',
      name: 'labore',
      manufacturer: 'do',
      partNumber: 'OCCAECAT-2735-194',
      deviceType: 'ex deserunt elit',
      contentType: 'report',
      commonName: 'VOLUPTATE-1327-299',
      creationDate: new Date('Sun Jul 22 2018 16:30:00 GMT+0000'),
      purchaseDate: new Date('Tue Nov 29 2016 08:05:03 GMT+0000'),
      publishDate: new Date('Fri Nov 20 2015 20:37:48 GMT+0000'),
      features: 'enim laboris',
      perspective: 'esse',
      channelIndicators: [
        'sunt est',
        'reprehenderit cillum',
        'eu incididunt'
      ],
      reportCode: 'laborum velit',
      reportCategory: 'esse',
      modelNumber: 'nostrud',
      country: 'Greece',
      MAD: '43702390-256c-432d',
      idempotentId: 'mockIdempotentId-256c-432',
    },
    {
      id: '5f9030896dec623febe0c693',
      thumbnail: 'https://placehold.co/280x210',
      name: 'do',
      manufacturer: 'occaecat',
      partNumber: 'LABORIS-1251-518',
      deviceType: 'mollit amet cillum',
      contentType: 'die',
      commonName: 'AUTE-3296-917',
      creationDate: new Date('Sat Sep 20 2014 14:18:14 GMT+0000'),
      purchaseDate: new Date('Tue Mar 15 2016 05:15:37 GMT+0000'),
      publishDate: new Date('Wed Dec 30 2015 13:47:21 GMT+0000'),
      features: 'tempor exercitation',
      perspective: 'mollit',
      channelIndicators: [
        'est veniam',
        'ea excepteur',
        'amet magna'
      ],
      reportCode: 'elit et',
      reportCategory: 'nulla',
      modelNumber: 'laboris',
      country: 'Uzbekistan',
      MAD: 'e2caf1ce-a939-4d6f',
      idempotentId: 'mockIdempotentId-a939-4d6',
    },
    {
      id: '5f903089f56671f252480161',
      thumbnail: 'https://placehold.co/280x210',
      name: 'do',
      manufacturer: 'mollit',
      partNumber: 'SUNT-2540-581',
      deviceType: 'laborum culpa velit',
      contentType: 'pcb',
      commonName: 'SIT-1826-406',
      creationDate: new Date('Thu Nov 15 2018 01:26:29 GMT+0000'),
      purchaseDate: new Date('Mon Oct 10 2016 12:49:44 GMT+0000'),
      publishDate: new Date('Sat Feb 15 2020 02:32:19 GMT+0000'),
      features: 'anim adipisicing',
      perspective: 'proident',
      channelIndicators: [],
      reportCode: 'Lorem adipisicing',
      reportCategory: 'exercitation',
      modelNumber: 'ex',
      country: 'Macedonia',
      MAD: 'd3f4d3f5-7bfa-43af',
      idempotentId: 'mockIdempotentId-7bfa-43a',
    },
    {
      id: '5f9030891be1d01724eed61c',
      thumbnail: 'https://placehold.co/280x210',
      name: 'occaecat',
      manufacturer: 'eu',
      partNumber: 'IPSUM-4494-503',
      deviceType: 'officia elit consectetur',
      contentType: 'pcb',
      commonName: 'CILLUM-4119-694',
      creationDate: new Date('Thu Nov 03 2016 11:08:45 GMT+0000'),
      purchaseDate: new Date('Fri Feb 09 2018 22:31:38 GMT+0000'),
      publishDate: new Date('Mon Mar 12 2018 04:30:16 GMT+0000'),
      features: 'amet ut',
      perspective: 'in',
      channelIndicators: [],
      reportCode: 'labore sit',
      reportCategory: 'cupidatat',
      modelNumber: 'deserunt',
      country: 'United States',
      MAD: '821bc750-bbd2-4cb7',
      idempotentId: 'mockIdempotentId-bbd2-4cb',
    },
    {
      id: '5f9030892b72e8996d9ab80d',
      thumbnail: 'https://placehold.co/280x210',
      name: 'non',
      manufacturer: 'minim',
      partNumber: 'ALIQUA-2378-524',
      deviceType: 'adipisicing officia consequat',
      contentType: 'die',
      commonName: 'QUIS-4460-468',
      creationDate: new Date('Sun Jan 01 2017 03:38:50 GMT+0000'),
      purchaseDate: new Date('Tue Aug 19 2014 08:01:26 GMT+0000'),
      publishDate: new Date('Sun Apr 19 2020 12:47:01 GMT+0000'),
      features: 'qui occaecat',
      perspective: 'nostrud',
      channelIndicators: [
        'nulla cillum',
        'proident ex',
        'consectetur veniam'
      ],
      reportCode: 'non duis',
      reportCategory: 'id',
      modelNumber: 'nulla',
      country: 'Argentina',
      MAD: '4766aceb-9966-48e5',
      idempotentId: 'mockIdempotentId-9966-48e',
    },
    {
      id: '5f9030897268a32052b1fb10',
      thumbnail: 'https://placehold.co/280x210',
      name: 'sit',
      manufacturer: 'ullamco',
      partNumber: 'QUIS-2725-665',
      deviceType: 'culpa proident eu',
      contentType: 'die',
      commonName: 'AMET-1687-275',
      creationDate: new Date('Sun Dec 09 2018 20:52:58 GMT+0000'),
      purchaseDate: new Date('Sat Dec 09 2017 03:13:54 GMT+0000'),
      publishDate: new Date('Thu Jun 23 2016 16:05:35 GMT+0000'),
      features: 'ullamco proident',
      perspective: 'occaecat',
      channelIndicators: [
        'nulla in'
      ],
      reportCode: 'enim duis',
      reportCategory: 'minim',
      modelNumber: 'in',
      country: 'Bosnia and Herzegovina',
      MAD: 'c4f65f9a-b5db-4371',
      idempotentId: 'mockIdempotentId-b5db-437',
    },
    {
      id: '5f90308951e6c36166c85d8f',
      thumbnail: 'https://placehold.co/280x210',
      name: 'exercitation',
      manufacturer: 'ex',
      partNumber: 'VENIAM-1655-819',
      deviceType: 'enim adipisicing minim',
      contentType: 'component',
      commonName: 'QUI-2244-172',
      creationDate: new Date('Tue Apr 04 2017 19:07:06 GMT+0000'),
      purchaseDate: new Date('Sat Dec 13 2014 11:40:13 GMT+0000'),
      publishDate: new Date('Thu Mar 05 2020 07:36:32 GMT+0000'),
      features: 'fugiat ipsum',
      perspective: 'reprehenderit',
      channelIndicators: [],
      reportCode: 'consectetur esse',
      reportCategory: 'eiusmod',
      modelNumber: 'mollit',
      country: 'Denmark',
      MAD: '276f9dea-7b4b-47fc',
      idempotentId: 'mockIdempotentId-7b4b-47f',
    },
    {
      id: '5f9030894bd2668210d20b97',
      thumbnail: 'https://placehold.co/280x210',
      name: 'deserunt',
      manufacturer: 'et',
      partNumber: 'ALIQUA-4556-863',
      deviceType: 'ut consectetur ipsum',
      contentType: 'component',
      commonName: 'DESERUNT-3856-136',
      creationDate: new Date('Mon Dec 05 2016 02:00:09 GMT+0000'),
      purchaseDate: new Date('Fri Dec 21 2018 10:25:39 GMT+0000'),
      publishDate: new Date('Sat Sep 08 2018 11:39:49 GMT+0000'),
      features: 'ut dolore',
      perspective: 'aute',
      channelIndicators: [],
      reportCode: 'eu quis',
      reportCategory: 'nostrud',
      modelNumber: 'ea',
      country: 'Montserrat',
      MAD: 'a03af06c-e92c-4c46',
      idempotentId: 'mockIdempotentId-e92c-4c4',
    },
    {
      id: '5f9030893bb7cefdbb3d5af8',
      thumbnail: 'https://placehold.co/280x210',
      name: 'officia',
      manufacturer: 'exercitation',
      partNumber: 'IRURE-1406-584',
      deviceType: 'laboris minim voluptate',
      contentType: 'die',
      commonName: 'DUIS-3286-244',
      creationDate: new Date('Mon Sep 01 2014 05:18:57 GMT+0000'),
      purchaseDate: new Date('Mon Aug 18 2014 10:31:25 GMT+0000'),
      publishDate: new Date('Wed Nov 13 2019 16:14:02 GMT+0000'),
      features: 'id exercitation',
      perspective: 'consequat',
      channelIndicators: [
        'fugiat sunt'
      ],
      reportCode: 'sit aliquip',
      reportCategory: 'sint',
      modelNumber: 'ullamco',
      country: 'Ecuador',
      MAD: 'c7bfabdf-da9e-4708',
      idempotentId: 'mockIdempotentId-da9e-470',
    },
    {
      id: '5f9030893a5cf5825e6a8dd9',
      thumbnail: 'https://placehold.co/280x210',
      name: 'non',
      manufacturer: 'aliquip',
      partNumber: 'SIT-1984-699',
      deviceType: 'occaecat mollit qui',
      contentType: 'component',
      commonName: 'EXERCITATION-1706-360',
      creationDate: new Date('Tue Dec 26 2017 23:39:20 GMT+0000'),
      purchaseDate: new Date('Sat Jun 22 2019 13:57:52 GMT+0000'),
      publishDate: new Date('Mon May 26 2014 11:13:25 GMT+0000'),
      features: 'magna reprehenderit',
      perspective: 'exercitation',
      channelIndicators: [
        'dolor commodo',
        'commodo ex',
        'duis sunt'
      ],
      reportCode: 'tempor cillum',
      reportCategory: 'ad',
      modelNumber: 'commodo',
      country: 'Lebanon',
      MAD: 'c9b54f3b-b8c6-4b28',
      idempotentId: 'mockIdempotentId-b8c6-4b2',
    },
    {
      id: '5f90308911afa549a1edec75',
      thumbnail: 'https://placehold.co/280x210',
      name: 'ullamco',
      manufacturer: 'proident',
      partNumber: 'VELIT-1933-150',
      deviceType: 'aliqua anim esse',
      contentType: 'component',
      commonName: 'ADIPISICING-3841-244',
      creationDate: new Date('Thu Sep 24 2020 05:51:46 GMT+0000'),
      purchaseDate: new Date('Fri Aug 14 2020 02:27:54 GMT+0000'),
      publishDate: new Date('Fri Aug 21 2015 02:22:28 GMT+0000'),
      features: 'culpa minim',
      perspective: 'velit',
      channelIndicators: [],
      reportCode: 'id eu',
      reportCategory: 'anim',
      modelNumber: 'eiusmod',
      country: 'Tajikistan',
      MAD: 'd4e74255-e4f9-4739',
      idempotentId: 'mockIdempotentId-e4f9-473',
    },
    {
      id: '5f90308923ed3b6e0612b20f',
      thumbnail: 'https://placehold.co/280x210',
      name: 'fugiat',
      manufacturer: 'dolor',
      partNumber: 'PARIATUR-1683-435',
      deviceType: 'ut pariatur adipisicing',
      contentType: 'schematic',
      commonName: 'PROIDENT-1167-802',
      creationDate: new Date('Thu Mar 23 2017 13:52:13 GMT+0000'),
      purchaseDate: new Date('Wed Jan 25 2017 05:33:08 GMT+0000'),
      publishDate: new Date('Thu Apr 06 2017 22:27:19 GMT+0000'),
      features: 'occaecat aute',
      perspective: 'mollit',
      channelIndicators: [
        'laboris tempor'
      ],
      reportCode: 'ullamco eiusmod',
      reportCategory: 'velit',
      modelNumber: 'aute',
      country: 'Bouvet Island',
      MAD: '1d16486b-8d71-43ec',
      idempotentId: 'mockIdempotentId-8d71-43e',
    },
    {
      id: '5f9030893536a0939774aa2d',
      thumbnail: 'https://placehold.co/280x210',
      name: 'proident',
      manufacturer: 'tempor',
      partNumber: 'ALIQUA-4437-277',
      deviceType: 'commodo aute enim',
      contentType: 'pcb',
      commonName: 'ADIPISICING-3658-497',
      creationDate: new Date('Wed Feb 06 2019 11:41:09 GMT+0000'),
      purchaseDate: new Date('Fri Jun 19 2015 19:33:06 GMT+0000'),
      publishDate: new Date('Wed Jun 19 2019 21:16:22 GMT+0000'),
      features: 'sunt excepteur',
      perspective: 'ipsum',
      channelIndicators: [
        'cillum nulla'
      ],
      reportCode: 'ipsum velit',
      reportCategory: 'laborum',
      modelNumber: 'consectetur',
      country: 'Armenia',
      MAD: '15fb86fa-2696-46d3',
      idempotentId: 'mockIdempotentId-2696-46d',
    },
    {
      id: '5f90308999730a52d0fefcea',
      thumbnail: 'https://placehold.co/280x210',
      name: 'ad',
      manufacturer: 'culpa',
      partNumber: 'CILLUM-4302-249',
      deviceType: 'do Lorem nostrud',
      contentType: 'pcb',
      commonName: 'MOLLIT-2271-365',
      creationDate: new Date('Wed Oct 18 2017 11:49:22 GMT+0000'),
      purchaseDate: new Date('Tue Nov 05 2019 09:51:46 GMT+0000'),
      publishDate: new Date('Tue Sep 29 2020 23:38:11 GMT+0000'),
      features: 'aliqua consectetur',
      perspective: 'aute',
      channelIndicators: [],
      reportCode: 'laborum occaecat',
      reportCategory: 'esse',
      modelNumber: 'voluptate',
      country: 'French Guiana',
      MAD: '5c3f451d-ca96-4ac8',
      idempotentId: 'mockIdempotentId-ca96-4ac',
    },
    {
      id: '5f903089ae12224a3fa6eeb0',
      thumbnail: 'https://placehold.co/280x210',
      name: 'anim',
      manufacturer: 'sint',
      partNumber: 'IRURE-5000-203',
      deviceType: 'labore labore voluptate',
      contentType: 'report',
      commonName: 'OFFICIA-1180-450',
      creationDate: new Date('Sun Jun 07 2020 17:40:13 GMT+0000'),
      purchaseDate: new Date('Sun May 31 2015 06:22:30 GMT+0000'),
      publishDate: new Date('Wed Aug 21 2019 06:54:25 GMT+0000'),
      features: 'deserunt voluptate',
      perspective: 'adipisicing',
      channelIndicators: [
        'ad officia'
      ],
      reportCode: 'dolore enim',
      reportCategory: 'consectetur',
      modelNumber: 'enim',
      country: 'French Polynesia',
      MAD: '91ab1c2c-21bc-4009',
      idempotentId: 'mockIdempotentId-21bc-400',
    },
    {
      id: '5f90308916e9fc083fdb3cdc',
      thumbnail: 'https://placehold.co/280x210',
      name: 'sit',
      manufacturer: 'minim',
      partNumber: 'EST-3889-422',
      deviceType: 'enim dolore do',
      contentType: 'image',
      commonName: 'QUI-4359-365',
      creationDate: new Date('Sun May 20 2018 00:56:35 GMT+0000'),
      purchaseDate: new Date('Fri Jan 13 2017 20:22:50 GMT+0000'),
      publishDate: new Date('Mon Mar 28 2016 19:09:19 GMT+0000'),
      features: 'ex officia',
      perspective: 'nulla',
      channelIndicators: [],
      reportCode: 'eu et',
      reportCategory: 'anim',
      modelNumber: 'ut',
      country: 'Norfolk Island',
      MAD: '2f1cd7f1-d894-4804',
      idempotentId: 'mockIdempotentId-d894-480',
    },
    {
      id: '5f9030899f4dc851fc969954',
      thumbnail: 'https://placehold.co/280x210',
      name: 'occaecat',
      manufacturer: 'sunt',
      partNumber: 'OFFICIA-3885-448',
      deviceType: 'excepteur culpa et',
      contentType: 'report',
      commonName: 'VENIAM-1316-783',
      creationDate: new Date('Sat Oct 08 2016 08:12:58 GMT+0000'),
      purchaseDate: new Date('Tue Jun 19 2018 23:52:10 GMT+0000'),
      publishDate: new Date('Mon Oct 08 2018 11:51:55 GMT+0000'),
      features: 'officia enim',
      perspective: 'adipisicing',
      channelIndicators: [
        'esse ex',
        'et cillum',
        'qui laborum',
        'reprehenderit elit'
      ],
      reportCode: 'ut dolore',
      reportCategory: 'excepteur',
      modelNumber: 'consectetur',
      country: 'Niger',
      MAD: '51b8cb11-5183-4e24',
      idempotentId: 'mockIdempotentId-5183-4e2',
    },
    {
      id: '5f90308942e583c990771b19',
      thumbnail: 'https://placehold.co/280x210',
      name: 'mollit',
      manufacturer: 'ullamco',
      partNumber: 'CONSEQUAT-3617-918',
      deviceType: 'exercitation ullamco id',
      contentType: 'pcb',
      commonName: 'LOREM-2570-588',
      creationDate: new Date('Sun Dec 18 2016 06:01:46 GMT+0000'),
      purchaseDate: new Date('Sun Sep 27 2020 11:29:37 GMT+0000'),
      publishDate: new Date('Tue May 20 2014 17:08:33 GMT+0000'),
      features: 'excepteur ullamco',
      perspective: 'nostrud',
      channelIndicators: [
        'laborum reprehenderit'
      ],
      reportCode: 'nisi consectetur',
      reportCategory: 'ut',
      modelNumber: 'anim',
      country: 'Cocos (Keeling Islands)',
      MAD: '9525898f-b8d2-49ac',
      idempotentId: 'mockIdempotentId-b8d2-49a',
    },
    {
      id: '5f903089f15396bae3de4bbc',
      thumbnail: 'https://placehold.co/280x210',
      name: 'nostrud',
      manufacturer: 'id',
      partNumber: 'CONSECTETUR-2469-102',
      deviceType: 'amet ad sunt',
      contentType: 'die',
      commonName: 'AUTE-2359-965',
      creationDate: new Date('Wed Dec 05 2018 19:35:20 GMT+0000'),
      purchaseDate: new Date('Sat Jun 27 2015 03:19:30 GMT+0000'),
      publishDate: new Date('Sat Jan 28 2017 02:27:30 GMT+0000'),
      features: 'et non',
      perspective: 'Lorem',
      channelIndicators: [],
      reportCode: 'consequat Lorem',
      reportCategory: 'enim',
      modelNumber: 'eiusmod',
      country: 'Kyrgyzstan',
      MAD: '9cadd994-4cbf-4287',
      idempotentId: 'mockIdempotentId-4cbf-428',
    },
    {
      id: '5f9030899a04b69a7e034f3b',
      thumbnail: 'https://placehold.co/280x210',
      name: 'irure',
      manufacturer: 'qui',
      partNumber: 'EXCEPTEUR-3409-517',
      deviceType: 'exercitation excepteur quis',
      contentType: 'product',
      commonName: 'DO-3665-499',
      creationDate: new Date('Wed Dec 20 2017 12:45:43 GMT+0000'),
      purchaseDate: new Date('Fri Feb 08 2019 04:51:43 GMT+0000'),
      publishDate: new Date('Mon Jun 06 2016 00:44:54 GMT+0000'),
      features: 'incididunt consectetur',
      perspective: 'et',
      channelIndicators: [
        'aliquip do',
        'nulla ad',
        'do irure'
      ],
      reportCode: 'qui eiusmod',
      reportCategory: 'consectetur',
      modelNumber: 'nisi',
      country: 'Czech Republic',
      MAD: '1542f6c2-da85-4e25',
      yearOfPurchase: '2020',
      idempotentId: 'mockIdempotentId-da85-4e2',
    },
    {
      id: '5f903089012cde966925f1ec',
      thumbnail: 'https://placehold.co/280x210',
      name: 'quis',
      manufacturer: 'incididunt',
      partNumber: 'QUI-3543-734',
      deviceType: 'enim et labore',
      contentType: 'pcb',
      commonName: 'NISI-4357-716',
      creationDate: new Date('Wed Oct 15 2014 06:55:41 GMT+0000'),
      purchaseDate: new Date('Mon Sep 02 2019 07:40:54 GMT+0000'),
      publishDate: new Date('Wed Jun 06 2018 05:41:46 GMT+0000'),
      features: 'irure officia',
      perspective: 'cupidatat',
      channelIndicators: [
        'voluptate veniam'
      ],
      reportCode: 'qui magna',
      reportCategory: 'est',
      modelNumber: 'consequat',
      country: 'Canada',
      MAD: 'a59f5ea5-f161-4549',
      idempotentId: 'mockIdempotentId-f161-454',
    },
    {
      id: '5f903089dbf5bb607529b407',
      thumbnail: 'https://placehold.co/280x210',
      name: 'et',
      manufacturer: 'nisi',
      partNumber: 'EXERCITATION-1179-739',
      deviceType: 'irure consectetur cupidatat',
      contentType: 'image',
      commonName: 'TEMPOR-1365-182',
      creationDate: new Date('Fri Sep 25 2020 23:08:58 GMT+0000'),
      purchaseDate: new Date('Fri Oct 27 2017 06:06:35 GMT+0000'),
      publishDate: new Date('Sat Nov 08 2014 20:41:33 GMT+0000'),
      features: 'exercitation cillum',
      perspective: 'labore',
      channelIndicators: [
        'aliquip ipsum',
        'incididunt laborum',
        'sunt commodo',
        'dolore non'
      ],
      reportCode: 'ipsum nostrud',
      reportCategory: 'nostrud',
      modelNumber: 'Lorem',
      country: 'Afghanistan',
      MAD: '99d58332-920a-4af7',
      idempotentId: 'mockIdempotentId-920a-4af',
    },
    {
      id: '5f903089f22dc32d06bf0aa8',
      thumbnail: 'https://placehold.co/280x210',
      name: 'mollit',
      manufacturer: 'officia',
      partNumber: 'PARIATUR-1487-630',
      deviceType: 'dolor culpa reprehenderit',
      contentType: 'schematic',
      commonName: 'IPSUM-4813-803',
      creationDate: new Date('Thu Jan 16 2014 11:26:27 GMT+0000'),
      purchaseDate: new Date('Tue May 01 2018 10:55:31 GMT+0000'),
      publishDate: new Date('Fri Apr 22 2016 19:15:08 GMT+0000'),
      features: 'sit elit',
      perspective: 'in',
      channelIndicators: [
        'aliqua Lorem',
        'cupidatat excepteur'
      ],
      reportCode: 'esse consequat',
      reportCategory: 'tempor',
      modelNumber: 'ex',
      country: 'Nauru',
      MAD: '8d86d11a-a428-48bd',
      idempotentId: 'mockIdempotentId-a428-48b',
    },
    {
      id: '5f9030894d86d559d56762cb',
      thumbnail: 'https://placehold.co/280x210',
      name: 'nulla',
      manufacturer: 'aute',
      partNumber: 'ENIM-3637-117',
      deviceType: 'pariatur tempor ut',
      contentType: 'report',
      commonName: 'IPSUM-3667-326',
      creationDate: new Date('Wed Oct 21 2015 12:19:57 GMT+0000'),
      purchaseDate: new Date('Tue Feb 20 2018 14:44:55 GMT+0000'),
      publishDate: new Date('Sun Apr 29 2018 11:19:13 GMT+0000'),
      features: 'esse occaecat',
      perspective: 'eiusmod',
      channelIndicators: [],
      reportCode: 'dolor officia',
      reportCategory: 'exercitation',
      modelNumber: 'enim',
      country: 'Qatar',
      MAD: '3a859d83-2161-48d4',
      idempotentId: 'mockIdempotentId-2161-48d',
    },
    {
      id: '5f903089f23419d29690c3f9',
      thumbnail: 'https://placehold.co/280x210',
      name: 'id',
      manufacturer: 'culpa',
      partNumber: 'COMMODO-2228-563',
      deviceType: 'irure quis laboris',
      contentType: 'die',
      commonName: 'CILLUM-1730-770',
      creationDate: new Date('Mon Apr 11 2016 10:37:42 GMT+0000'),
      purchaseDate: new Date('Mon Jun 02 2014 17:03:12 GMT+0000'),
      publishDate: new Date('Sun Sep 02 2018 17:22:09 GMT+0000'),
      features: 'reprehenderit sint',
      perspective: 'pariatur',
      channelIndicators: [
        'duis laborum',
        'elit do',
        'est mollit'
      ],
      reportCode: 'ex do',
      reportCategory: 'enim',
      modelNumber: 'dolore',
      country: 'Nicaragua',
      MAD: '09ab2f23-1f82-445f',
      idempotentId: 'mockIdempotentId-1f82-445',
    },
    {
      id: '5f90308952a32de2a83cb122',
      thumbnail: 'https://placehold.co/280x210',
      name: 'ipsum',
      manufacturer: 'adipisicing',
      partNumber: 'IRURE-4550-833',
      deviceType: 'eiusmod irure sint',
      contentType: 'report',
      commonName: 'CONSEQUAT-2296-804',
      creationDate: new Date('Sat Feb 10 2018 11:23:54 GMT+0000'),
      purchaseDate: new Date('Fri Apr 21 2017 03:43:06 GMT+0000'),
      publishDate: new Date('Sat May 31 2014 21:19:15 GMT+0000'),
      features: 'irure irure',
      perspective: 'sit',
      channelIndicators: [
        'pariatur eiusmod'
      ],
      reportCode: 'aliquip sit',
      reportCategory: 'consequat',
      modelNumber: 'ad',
      country: 'Cuba',
      MAD: '294aed3d-12af-4b37',
      idempotentId: 'mockIdempotentId-12af-4b3',
    },
    {
      id: '5f903089917a3a19eb951570',
      thumbnail: 'https://placehold.co/280x210',
      name: 'aute',
      manufacturer: 'elit',
      partNumber: 'AUTE-2578-883',
      deviceType: 'enim labore proident',
      contentType: 'die',
      commonName: 'ADIPISICING-2536-244',
      creationDate: new Date('Tue Aug 11 2020 04:09:52 GMT+0000'),
      purchaseDate: new Date('Sun Mar 29 2015 10:40:37 GMT+0000'),
      publishDate: new Date('Sun May 03 2015 04:03:40 GMT+0000'),
      features: 'aliqua officia',
      perspective: 'nisi',
      channelIndicators: [
        'voluptate deserunt',
        'laborum est',
        'anim dolore'
      ],
      reportCode: 'ex sint',
      reportCategory: 'reprehenderit',
      modelNumber: 'et',
      country: 'Paraguay',
      MAD: 'e06a0cf1-50e6-4763',
      idempotentId: 'mockIdempotentId-50e6-476',
    },
    {
      id: '5f90308905a5d6b54ca30a9e',
      thumbnail: 'https://placehold.co/280x210',
      name: 'esse',
      manufacturer: 'dolor',
      partNumber: 'REPREHENDERIT-1723-460',
      deviceType: 'enim id dolore',
      contentType: 'schematic',
      commonName: 'SUNT-3559-820',
      creationDate: new Date('Fri Sep 13 2019 10:45:10 GMT+0000'),
      purchaseDate: new Date('Tue Feb 09 2016 22:42:42 GMT+0000'),
      publishDate: new Date('Tue Apr 16 2019 05:08:05 GMT+0000'),
      features: 'amet et',
      perspective: 'enim',
      channelIndicators: [
        'ipsum labore'
      ],
      reportCode: 'non in',
      reportCategory: 'culpa',
      modelNumber: 'magna',
      country: 'St. Helena',
      MAD: 'afc20467-f09a-4e82',
      idempotentId: 'mockIdempotentId-f09a-4e8',
    },
    {
      id: '5f903089035e793125b24f54',
      thumbnail: 'https://placehold.co/280x210',
      name: 'aliqua',
      manufacturer: 'nulla',
      partNumber: 'PROIDENT-3957-548',
      deviceType: 'do Lorem sit',
      contentType: 'report',
      commonName: 'CILLUM-1547-312',
      creationDate: new Date('Thu Sep 06 2018 15:28:10 GMT+0000'),
      purchaseDate: new Date('Fri Dec 29 2017 16:49:21 GMT+0000'),
      publishDate: new Date('Thu Sep 01 2016 12:21:51 GMT+0000'),
      features: 'magna voluptate',
      perspective: 'elit',
      channelIndicators: [
        'eiusmod voluptate',
        'nisi id'
      ],
      reportCode: 'irure quis',
      reportCategory: 'do',
      modelNumber: 'mollit',
      country: 'Pakistan',
      MAD: '20ba4f32-1981-41f0',
      idempotentId: 'mockIdempotentId-1981-41f',
    },
    {
      id: '5f9030899246e0668df62a10',
      thumbnail: 'https://placehold.co/280x210',
      name: 'est',
      manufacturer: 'eiusmod',
      partNumber: 'EX-3709-942',
      deviceType: 'magna reprehenderit et',
      contentType: 'pcb',
      commonName: 'DO-1424-621',
      creationDate: new Date('Sat Sep 26 2020 07:32:14 GMT+0000'),
      purchaseDate: new Date('Mon Mar 27 2017 04:12:30 GMT+0000'),
      publishDate: new Date('Sun Apr 08 2018 00:53:39 GMT+0000'),
      features: 'reprehenderit culpa',
      perspective: 'pariatur',
      channelIndicators: [],
      reportCode: 'anim quis',
      reportCategory: 'do',
      modelNumber: 'id',
      country: 'Bulgaria',
      MAD: '23eecf0f-f91b-4834',
      idempotentId: 'mockIdempotentId-f91b-483',
    },
    {
      id: '5f9030895a9149c28cb0b7bb',
      thumbnail: 'https://placehold.co/280x210',
      name: 'exercitation',
      manufacturer: 'proident',
      partNumber: 'CULPA-1008-177',
      deviceType: 'aute nostrud laboris',
      contentType: 'schematic',
      commonName: 'QUI-3380-452',
      creationDate: new Date('Mon Jan 09 2017 23:15:03 GMT+0000'),
      purchaseDate: new Date('Sun Aug 10 2014 09:02:23 GMT+0000'),
      publishDate: new Date('Tue Feb 25 2020 16:35:18 GMT+0000'),
      features: 'veniam ullamco',
      perspective: 'nulla',
      channelIndicators: [
        'irure elit',
        'labore sunt',
        'mollit quis',
        'id minim'
      ],
      reportCode: 'dolore mollit',
      reportCategory: 'quis',
      modelNumber: 'incididunt',
      country: 'Greenland',
      MAD: '079d10ea-8214-4292',
      idempotentId: 'mockIdempotentId-8214-429',
    },
    {
      id: '5f9030892244239fd6457f8c',
      thumbnail: 'https://placehold.co/280x210',
      name: 'adipisicing',
      manufacturer: 'adipisicing',
      partNumber: 'MAGNA-2932-871',
      deviceType: 'cupidatat proident voluptate',
      contentType: 'image',
      commonName: 'ID-2087-322',
      creationDate: new Date('invalid date'),
      purchaseDate: new Date('invalid date'),
      publishDate: new Date('invalid date'),
      features: 'eu nostrud',
      perspective: 'nostrud',
      channelIndicators: [
        'mollit sunt',
        'ad et',
        'ipsum aliqua',
        'occaecat duis'
      ],
      reportCode: 'fugiat ullamco',
      reportCategory: 'nulla',
      modelNumber: 'reprehenderit',
      country: 'Slovak Republic',
      MAD: '8da52792-3ce7-4529',
      idempotentId: 'mockIdempotentId-3ce7-452',
    },
    {
      id: '5f90308946200fa9525ab7bc',
      thumbnail: 'https://placehold.co/280x210',
      name: 'sint',
      manufacturer: 'eiusmod',
      partNumber: 'COMMODO-3025-251',
      deviceType: 'anim ipsum laborum',
      contentType: 'image',
      commonName: 'ALIQUA-2224-872',
      creationDate: new Date('Sun May 03 2015 07:56:02 GMT+0000'),
      purchaseDate: new Date('Sun Feb 12 2017 16:01:56 GMT+0000'),
      publishDate: new Date('Mon Apr 10 2017 18:28:05 GMT+0000'),
      features: 'dolor labore',
      perspective: 'ad',
      channelIndicators: [
        'ut et',
        'deserunt quis'
      ],
      reportCode: 'nisi nostrud',
      reportCategory: 'enim',
      modelNumber: 'commodo',
      country: 'Saint Lucia',
      MAD: 'f66cd97e-28a0-44cd',
      idempotentId: 'mockIdempotentId-28a0-44c',
    },
    {
      id: '5f90308902cf24f5b36d1a5d',
      thumbnail: 'https://placehold.co/280x210',
      name: 'id',
      manufacturer: 'ipsum',
      partNumber: 'CUPIDATAT-3147-373',
      deviceType: 'velit proident officia',
      contentType: 'product',
      commonName: 'EU-1001-864',
      creationDate: new Date('Thu Feb 25 2016 20:24:46 GMT+0000'),
      purchaseDate: new Date('Tue Nov 28 2017 07:48:52 GMT+0000'),
      publishDate: new Date('Fri Feb 14 2014 05:56:13 GMT+0000'),
      features: 'anim sunt',
      perspective: 'ex',
      channelIndicators: [
        'proident reprehenderit',
        'ex aliqua',
        'ut officia',
        'eiusmod duis'
      ],
      reportCode: 'non aliquip',
      reportCategory: 'irure',
      modelNumber: 'exercitation',
      country: 'Thailand',
      MAD: '4a238f79-0177-43d2',
      idempotentId: 'mockIdempotentId-0177-43d',
    },
    {
      id: '5f903089655474b77f9180d2',
      thumbnail: 'https://placehold.co/280x210',
      name: 'esse',
      manufacturer: 'ullamco',
      partNumber: 'AUTE-1954-221',
      deviceType: 'cupidatat culpa laborum',
      contentType: 'pcb',
      commonName: 'NULLA-4777-383',
      creationDate: new Date('Sun Nov 26 2017 21:08:35 GMT+0000'),
      purchaseDate: new Date('Wed Jun 21 2017 05:43:11 GMT+0000'),
      publishDate: new Date('Mon Jun 06 2016 18:58:31 GMT+0000'),
      features: 'in cupidatat',
      perspective: 'dolor',
      channelIndicators: [
        'qui deserunt',
        'ex ut',
        'incididunt voluptate'
      ],
      reportCode: 'voluptate quis',
      reportCategory: 'ea',
      modelNumber: 'quis',
      country: 'Mauritius',
      MAD: 'a7b8e4c7-0cb6-4d1c',
      idempotentId: 'mockIdempotentId-0cb6-4d1',
    },
    {
      id: '5f903089d1ac1636cea91174',
      thumbnail: 'https://placehold.co/280x210',
      name: 'aute',
      manufacturer: 'exercitation',
      partNumber: 'ESSE-1534-809',
      deviceType: 'velit id nulla',
      contentType: 'image',
      commonName: 'REPREHENDERIT-4393-136',
      creationDate: new Date('Fri Aug 30 2019 16:40:11 GMT+0000'),
      purchaseDate: new Date('Thu Jun 14 2018 11:13:55 GMT+0000'),
      publishDate: new Date('Sat Aug 30 2014 16:05:13 GMT+0000'),
      features: 'est deserunt',
      perspective: 'velit',
      channelIndicators: [
        'enim fugiat',
        'voluptate anim',
        'eu qui',
        'cillum est'
      ],
      reportCode: 'veniam in',
      reportCategory: 'voluptate',
      modelNumber: 'duis',
      country: 'Moldova',
      MAD: '75ad6961-2d89-4a97',
      idempotentId: 'mockIdempotentId-2d89-4a9',
    },
    {
      id: '5f903089134114146c147442',
      thumbnail: 'https://placehold.co/280x210',
      name: 'Lorem',
      manufacturer: 'magna',
      partNumber: 'VOLUPTATE-3193-244',
      deviceType: 'tempor anim quis',
      contentType: 'report',
      commonName: 'NON-4257-452',
      creationDate: new Date('Sat Dec 12 2015 18:27:36 GMT+0000'),
      purchaseDate: new Date('Wed Nov 27 2019 09:27:56 GMT+0000'),
      publishDate: new Date('Mon Apr 25 2016 01:10:02 GMT+0000'),
      features: 'aute voluptate',
      perspective: 'esse',
      channelIndicators: [
        'est irure',
        'laborum sit',
        'in occaecat'
      ],
      reportCode: 'consequat incididunt',
      reportCategory: 'anim',
      modelNumber: 'elit',
      country: 'Uruguay',
      MAD: '9afa3d7c-e3cf-4228',
      idempotentId: 'mockIdempotentId-e3cf-422',
    },
    {
      id: '5f9030890bc8c34744043092',
      thumbnail: 'https://placehold.co/280x210',
      name: 'occaecat',
      manufacturer: 'commodo',
      partNumber: 'LABORE-3632-103',
      deviceType: 'ea cillum non',
      contentType: 'schematic',
      commonName: 'NULLA-4220-406',
      creationDate: new Date('invalid date'),
      purchaseDate: new Date('invalid date'),
      publishDate: new Date('invalid date'),
      features: 'eu qui',
      perspective: 'sint',
      channelIndicators: [
        'reprehenderit anim',
        'voluptate tempor',
        'Lorem non'
      ],
      reportCode: 'duis reprehenderit',
      reportCategory: 'Lorem',
      modelNumber: 'adipisicing',
      country: 'Rwanda',
      MAD: 'a52a0d71-18e6-4302',
      idempotentId: 'mockIdempotentId-18e6-430',
    },
    {
      id: '5f903089c0062ab87c9a5e6f',
      thumbnail: 'https://placehold.co/280x210',
      name: 'dolore',
      manufacturer: 'proident',
      partNumber: 'NOSTRUD-2709-411',
      deviceType: 'irure sunt sit',
      contentType: 'image',
      commonName: 'ENIM-1520-413',
      creationDate: new Date('Sun Sep 20 2015 06:25:50 GMT+0000'),
      purchaseDate: new Date('Thu Sep 22 2016 23:49:07 GMT+0000'),
      publishDate: new Date('Tue Jun 02 2015 06:04:29 GMT+0000'),
      features: 'esse est',
      perspective: 'ex',
      channelIndicators: [
        'tempor et',
        'deserunt officia',
        'duis sunt'
      ],
      reportCode: 'aute incididunt',
      reportCategory: 'mollit',
      modelNumber: 'non',
      country: 'Japan',
      MAD: '5fb6e9ca-0a43-4b24',
      idempotentId: 'mockIdempotentId-0a43-4b2',
    },
    {
      id: '5f903089fe18dabd15a7ddb1',
      thumbnail: 'https://placehold.co/280x210',
      name: 'incididunt',
      manufacturer: 'labore',
      partNumber: 'OCCAECAT-3444-114',
      deviceType: 'elit laboris laboris',
      contentType: 'pcb',
      commonName: 'QUIS-2242-259',
      creationDate: new Date('Mon May 27 2019 14:33:25 GMT+0000'),
      purchaseDate: new Date('Sat Apr 26 2014 02:45:17 GMT+0000'),
      publishDate: new Date('Fri Sep 16 2016 10:19:20 GMT+0000'),
      features: 'laboris duis',
      perspective: 'sint',
      channelIndicators: [
        'nulla nisi',
        'consectetur qui',
        'excepteur reprehenderit'
      ],
      reportCode: 'cillum pariatur',
      reportCategory: 'ut',
      modelNumber: 'quis',
      country: 'Gabon',
      MAD: '1d216652-9a9e-4242',
      idempotentId: 'mockIdempotentId-9a9e-424',
    },
    {
      id: '5f9030894a77ac0e33565036',
      thumbnail: 'https://placehold.co/280x210',
      name: 'Lorem',
      manufacturer: 'aliqua',
      partNumber: 'NON-2493-666',
      deviceType: 'mollit dolor excepteur',
      contentType: 'die',
      commonName: 'LOREM-3844-412',
      creationDate: new Date('Mon Mar 16 2020 01:33:42 GMT+0000'),
      purchaseDate: new Date('Sat May 02 2015 16:10:51 GMT+0000'),
      publishDate: new Date('Thu Nov 15 2018 06:37:27 GMT+0000'),
      features: 'laborum tempor',
      perspective: 'ipsum',
      channelIndicators: [
        'eiusmod irure',
        'cillum eu',
        'incididunt deserunt'
      ],
      reportCode: 'sunt qui',
      reportCategory: 'id',
      modelNumber: 'eiusmod',
      country: 'Kazakhstan',
      MAD: '7d33092d-7175-4d08',
      idempotentId: 'mockIdempotentId-7175-4d0',
    },
    {
      id: '5f90308976b4516c6d5a9635',
      thumbnail: 'https://placehold.co/280x210',
      name: 'eu',
      manufacturer: 'nulla',
      partNumber: 'EXCEPTEUR-4192-330',
      deviceType: 'voluptate magna ipsum',
      contentType: 'die',
      commonName: 'QUIS-4859-923',
      creationDate: new Date('Sat Jun 27 2015 02:20:45 GMT+0000'),
      purchaseDate: new Date('Mon Feb 02 2015 23:45:45 GMT+0000'),
      publishDate: new Date('Wed Feb 08 2017 16:00:42 GMT+0000'),
      features: 'minim cillum',
      perspective: 'sit',
      channelIndicators: [],
      reportCode: 'excepteur voluptate',
      reportCategory: 'qui',
      modelNumber: 'ipsum',
      country: 'Norway',
      MAD: '1c0bbdd2-84ac-45c5',
      idempotentId: 'mockIdempotentId-84ac-45c',
    },
    {
      id: '5f903089ad9bbc43edf484f0',
      thumbnail: 'https://placehold.co/280x210',
      name: 'ea',
      manufacturer: 'anim',
      partNumber: 'PROIDENT-3975-623',
      deviceType: 'incididunt excepteur officia',
      contentType: 'die',
      commonName: 'ID-1502-470',
      creationDate: new Date('Thu Sep 03 2015 21:40:26 GMT+0000'),
      purchaseDate: new Date('Fri Oct 19 2018 01:24:47 GMT+0000'),
      publishDate: new Date('Thu Aug 09 2018 15:23:22 GMT+0000'),
      features: 'consectetur labore',
      perspective: 'nostrud',
      channelIndicators: [
        'ullamco ullamco',
        'ipsum adipisicing',
        'sint deserunt',
        'tempor adipisicing'
      ],
      reportCode: 'sit officia',
      reportCategory: 'culpa',
      modelNumber: 'id',
      country: 'Palau',
      MAD: 'eb8880f6-4505-4fdc',
      idempotentId: 'mockIdempotentId-4505-4fd',
    },
    {
      id: '5f90308967d4dfda7071dadf',
      thumbnail: 'https://placehold.co/280x210',
      name: 'ullamco',
      manufacturer: 'exercitation',
      partNumber: 'MAGNA-3301-598',
      deviceType: 'aute quis ullamco',
      contentType: 'image',
      commonName: 'PARIATUR-1043-424',
      creationDate: new Date('Fri Nov 08 2019 23:34:02 GMT+0000'),
      purchaseDate: new Date('Tue Sep 29 2015 16:32:41 GMT+0000'),
      publishDate: new Date('Tue Apr 10 2018 06:42:00 GMT+0000'),
      features: 'et nostrud',
      perspective: 'incididunt',
      channelIndicators: [],
      reportCode: 'anim velit',
      reportCategory: 'dolor',
      modelNumber: 'elit',
      country: 'Guyana',
      MAD: '06ae69a8-56fb-4386',
      idempotentId: 'mockIdempotentId-56fb-438',
    },
    {
      id: '5f903089ddd7259cf7189d04',
      thumbnail: 'https://placehold.co/280x210',
      name: 'consequat',
      manufacturer: 'exercitation',
      partNumber: 'SUNT-2556-719',
      deviceType: 'aliqua reprehenderit officia',
      contentType: 'die',
      commonName: 'DOLORE-4982-965',
      creationDate: new Date('Wed Apr 05 2017 04:06:32 GMT+0000'),
      purchaseDate: new Date('Tue Jul 08 2014 01:32:21 GMT+0000'),
      publishDate: new Date('Tue Apr 17 2018 09:12:29 GMT+0000'),
      features: 'aliqua ad',
      perspective: 'enim',
      channelIndicators: [],
      reportCode: 'deserunt cupidatat',
      reportCategory: 'esse',
      modelNumber: 'sint',
      country: 'Spain',
      MAD: 'aee37274-8a16-44e4',
      idempotentId: 'mockIdempotentId-8a16-44e',
    },
    {
      id: '5f9030895745a26fdf21d662',
      thumbnail: 'https://placehold.co/280x210',
      name: 'irure',
      manufacturer: 'ullamco',
      partNumber: 'OCCAECAT-2221-265',
      deviceType: 'esse anim dolor',
      contentType: 'product',
      commonName: 'IPSUM-3399-682',
      creationDate: new Date('invalid date'),
      purchaseDate: new Date('invalid date'),
      publishDate: new Date('invalid date'),
      features: 'fugiat nisi',
      perspective: 'eu',
      channelIndicators: [
        'ut nulla',
        'labore eu',
        'id tempor',
        'anim sit'
      ],
      reportCode: 'quis nisi',
      reportCategory: 'reprehenderit',
      modelNumber: 'Lorem',
      country: 'Somalia',
      MAD: '71454d7e-0fff-43f9',
      idempotentId: 'mockIdempotentId-0fff-43f',
    },
    {
      id: '5f90308997069124c17cd8b9',
      thumbnail: 'https://placehold.co/280x210',
      name: 'quis',
      manufacturer: 'laboris',
      partNumber: 'MAGNA-2894-512',
      deviceType: 'consectetur duis ea',
      contentType: 'pcb',
      commonName: 'MOLLIT-4695-114',
      creationDate: new Date('invalid date'),
      purchaseDate: new Date('invalid date'),
      publishDate: new Date('invalid date'),
      features: 'eu Lorem',
      perspective: 'veniam',
      channelIndicators: [
        'qui sit',
        'enim aliquip',
        'nisi et'
      ],
      reportCode: 'sint quis',
      reportCategory: 'eiusmod',
      modelNumber: 'dolor',
      country: 'Maldives',
      MAD: 'ca9e0819-7309-44a6',
      idempotentId: 'mockIdempotentId-7309-44a',
    },
    {
      id: '5f903089cab32f6c4e033940',
      thumbnail: 'https://placehold.co/280x210',
      name: 'et',
      manufacturer: 'irure',
      partNumber: 'INCIDIDUNT-1455-114',
      deviceType: 'nisi aliquip occaecat',
      contentType: 'die',
      commonName: 'OFFICIA-3884-142',
      creationDate: new Date('Thu Dec 06 2018 21:42:25 GMT+0000'),
      purchaseDate: new Date('Sun Dec 15 2019 03:16:15 GMT+0000'),
      publishDate: new Date('Mon Apr 30 2018 20:27:06 GMT+0000'),
      features: 'ullamco fugiat',
      perspective: 'et',
      channelIndicators: [
        'enim et'
      ],
      reportCode: 'id nostrud',
      reportCategory: 'consectetur',
      modelNumber: 'in',
      country: 'Sudan',
      MAD: 'bc438fe1-f6ed-4694',
      idempotentId: 'mockIdempotentId-f6ed-469',
    },
    {
      id: '5f9030892149407b39212447',
      thumbnail: 'https://placehold.co/280x210',
      name: 'duis',
      manufacturer: 'ad',
      partNumber: 'ANIM-4250-481',
      deviceType: 'non ad mollit',
      contentType: 'image',
      commonName: 'NISI-1716-148',
      creationDate: new Date('Sat Apr 15 2017 23:03:43 GMT+0000'),
      purchaseDate: new Date('Fri Oct 17 2014 02:17:27 GMT+0000'),
      publishDate: new Date('Mon Mar 02 2020 09:51:34 GMT+0000'),
      features: 'sit dolore',
      perspective: 'excepteur',
      channelIndicators: [
        'culpa fugiat',
        'culpa in'
      ],
      reportCode: 'sunt nostrud',
      reportCategory: 'proident',
      modelNumber: 'elit',
      country: 'Macau',
      MAD: 'a7b16d9f-59a0-4ad3',
      idempotentId: 'mockIdempotentId-59a0-4ad',
    },
    {
      id: '5f9030899e9cf7c3bcad5e4d',
      thumbnail: 'https://placehold.co/280x210',
      name: 'duis',
      manufacturer: 'sint',
      partNumber: 'SIT-4508-564',
      deviceType: 'exercitation officia Lorem',
      contentType: 'pcb',
      commonName: 'COMMODO-3052-136',
      creationDate: new Date('Tue Apr 23 2019 18:02:21 GMT+0000'),
      purchaseDate: new Date('Sun Jun 02 2019 02:24:26 GMT+0000'),
      publishDate: new Date('Sat Aug 16 2014 13:17:39 GMT+0000'),
      features: 'ad enim',
      perspective: 'laborum',
      channelIndicators: [
        'voluptate excepteur',
        'exercitation velit',
        'adipisicing mollit',
        'dolor pariatur'
      ],
      reportCode: 'excepteur ea',
      reportCategory: 'eiusmod',
      modelNumber: 'magna',
      country: 'Botswana',
      MAD: '6918d910-da12-46a3',
      idempotentId: 'mockIdempotentId-da12-46a',
    },
    {
      id: '5f903089294d326742ef9280',
      thumbnail: 'https://placehold.co/280x210',
      name: 'id',
      manufacturer: 'amet',
      partNumber: 'DOLOR-3337-546',
      deviceType: 'aliquip dolor do',
      contentType: 'product',
      commonName: 'AMET-3850-775',
      creationDate: new Date('Mon Jun 23 2014 17:30:38 GMT+0000'),
      purchaseDate: new Date('Fri Nov 08 2019 18:02:27 GMT+0000'),
      publishDate: new Date('Thu Feb 06 2014 13:47:09 GMT+0000'),
      features: 'magna irure',
      perspective: 'ex',
      channelIndicators: [
        'et incididunt',
        'cillum voluptate',
        'id excepteur'
      ],
      reportCode: 'sint cupidatat',
      reportCategory: 'ut',
      modelNumber: 'non',
      country: 'Gibraltar',
      MAD: 'e554f1fb-042c-48be',
      idempotentId: 'mockIdempotentId-042c-48b',
    },
    {
      id: '5f90308933d92a4454af960b',
      thumbnail: 'https://placehold.co/280x210',
      name: 'irure',
      manufacturer: 'tempor',
      partNumber: 'SIT-1903-420',
      deviceType: 'ex occaecat excepteur',
      contentType: 'component',
      commonName: 'VENIAM-1507-521',
      creationDate: new Date('invalid date'),
      purchaseDate: new Date('invalid date'),
      publishDate: new Date('invalid date'),
      features: 'ex incididunt',
      perspective: 'aliquip',
      channelIndicators: [
        'tempor dolor',
        'proident id',
        'cupidatat ea',
        'consequat excepteur'
      ],
      reportCode: 'labore culpa',
      reportCategory: 'nostrud',
      modelNumber: 'do',
      country: 'Marshall Islands',
      MAD: '083de0ef-d40d-4e35',
      idempotentId: 'mockIdempotentId-d40d-4e3',
    },
    {
      id: '5f9030896ef724da22585eeb',
      thumbnail: 'https://placehold.co/280x210',
      name: 'ipsum',
      manufacturer: 'incididunt',
      partNumber: 'NON-1062-951',
      deviceType: 'labore ex nostrud',
      contentType: 'report',
      commonName: 'CUPIDATAT-3483-998',
      creationDate: new Date('invalid date'),
      purchaseDate: new Date('invalid date'),
      publishDate: new Date('invalid date'),
      features: 'esse magna',
      perspective: 'veniam',
      channelIndicators: [
        'aute occaecat',
        'exercitation ex',
        'ea ullamco',
        'voluptate ex'
      ],
      reportCode: 'aliquip adipisicing',
      reportCategory: 'laborum',
      modelNumber: 'sunt',
      country: 'Peru',
      MAD: '1e893b42-519f-48eb',
      idempotentId: 'mockIdempotentId-519f-48e',
    },
    {
      id: '5f903089663b8049c7b7b022',
      thumbnail: 'https://placehold.co/280x210',
      name: 'ea',
      manufacturer: 'minim',
      partNumber: 'CONSECTETUR-3110-844',
      deviceType: 'mollit ullamco pariatur',
      contentType: 'product',
      commonName: 'IRURE-4886-123',
      creationDate: new Date('Thu Nov 28 2019 09:19:33 GMT+0000'),
      purchaseDate: new Date('Wed Jul 13 2016 14:50:03 GMT+0000'),
      publishDate: new Date('Tue Feb 10 2015 00:34:56 GMT+0000'),
      features: 'labore proident',
      perspective: 'non',
      channelIndicators: [
        'quis elit',
        'sit duis',
        'voluptate velit',
        'voluptate nostrud'
      ],
      reportCode: 'voluptate et',
      reportCategory: 'nostrud',
      modelNumber: 'consectetur',
      country: 'Malaysia',
      MAD: '09df5f8e-6c08-479a',
      idempotentId: 'mockIdempotentId-6c08-479',
    },
    {
      id: '5f903089aa9e7d95fcddfa75',
      thumbnail: 'https://placehold.co/280x210',
      name: 'in',
      manufacturer: 'magna',
      partNumber: 'TEMPOR-1597-385',
      deviceType: 'officia velit incididunt',
      contentType: 'pcb',
      commonName: 'IN-4837-395',
      creationDate: new Date('Sun Jan 28 2018 13:27:17 GMT+0000'),
      purchaseDate: new Date('Sun Apr 10 2016 15:01:59 GMT+0000'),
      publishDate: new Date('Wed Jan 10 2018 10:38:55 GMT+0000'),
      features: 'sint cupidatat',
      perspective: 'esse',
      channelIndicators: [],
      reportCode: 'dolor irure',
      reportCategory: 'culpa',
      modelNumber: 'consectetur',
      country: 'Saudi Arabia',
      MAD: '34c8dea0-7820-470a',
      idempotentId: 'mockIdempotentId-7820-470',
    },
    {
      id: '5f903089ea0590e21a0a55b7',
      thumbnail: 'https://placehold.co/280x210',
      name: 'sit',
      manufacturer: 'qui',
      partNumber: 'COMMODO-4583-577',
      deviceType: 'proident reprehenderit magna',
      contentType: 'schematic',
      commonName: 'FUGIAT-1526-739',
      creationDate: new Date('Wed Mar 04 2020 14:11:08 GMT+0000'),
      purchaseDate: new Date('Fri Jan 30 2015 19:45:06 GMT+0000'),
      publishDate: new Date('Tue Aug 13 2019 20:45:52 GMT+0000'),
      features: 'esse ut',
      perspective: 'cillum',
      channelIndicators: [
        'veniam voluptate',
        'sunt consequat'
      ],
      reportCode: 'ut ex',
      reportCategory: 'duis',
      modelNumber: 'tempor',
      country: 'Romania',
      MAD: 'c5ccec90-464f-420f',
      idempotentId: 'mockIdempotentId-464f-420',
    },
    {
      id: '5f903089309394101367f93e',
      thumbnail: 'https://placehold.co/280x210',
      name: 'culpa',
      manufacturer: 'ut',
      partNumber: 'OFFICIA-3650-906',
      deviceType: 'magna proident labore',
      contentType: 'schematic',
      commonName: 'AD-1710-562',
      creationDate: new Date('Sat Apr 27 2019 08:32:15 GMT+0000'),
      purchaseDate: new Date('Fri Sep 11 2020 23:38:30 GMT+0000'),
      publishDate: new Date('Wed Apr 22 2015 00:56:11 GMT+0000'),
      features: 'ea est',
      perspective: 'laboris',
      channelIndicators: [
        'cillum sint'
      ],
      reportCode: 'qui ut',
      reportCategory: 'sit',
      modelNumber: 'sit',
      country: 'Libya',
      MAD: '144411cb-666e-4a92',
      idempotentId: 'mockIdempotentId-666e-4a9',
    },
    {
      id: '5f903089536fc10423cd3acf',
      thumbnail: 'https://placehold.co/280x210',
      name: 'nostrud',
      manufacturer: 'elit',
      partNumber: 'CONSECTETUR-2229-222',
      deviceType: 'laborum velit culpa',
      contentType: 'schematic',
      commonName: 'EST-2954-290',
      creationDate: new Date('Fri Sep 11 2015 12:38:49 GMT+0000'),
      purchaseDate: new Date('Sun Jul 29 2018 02:04:58 GMT+0000'),
      publishDate: new Date('Wed May 24 2017 11:45:20 GMT+0000'),
      features: 'veniam dolor',
      perspective: 'anim',
      channelIndicators: [
        'nulla Lorem',
        'enim eiusmod',
        'incididunt irure',
        'fugiat laborum'
      ],
      reportCode: 'velit laboris',
      reportCategory: 'elit',
      modelNumber: 'anim',
      country: 'Korea (North)',
      MAD: '7b8db5d4-a456-4dcc',
      idempotentId: 'mockIdempotentId-a456-4dc',
    },
    {
      id: '5f903089dd933bfa660c799b',
      thumbnail: 'https://placehold.co/280x210',
      name: 'nulla',
      manufacturer: 'proident',
      partNumber: 'ALIQUA-4182-679',
      deviceType: 'magna excepteur nostrud',
      contentType: 'image',
      commonName: 'ALIQUIP-1306-809',
      creationDate: new Date('Mon Dec 22 2014 13:25:23 GMT+0000'),
      purchaseDate: new Date('Wed Apr 29 2020 21:24:07 GMT+0000'),
      publishDate: new Date('Sun Sep 27 2020 11:22:46 GMT+0000'),
      features: 'magna ea',
      perspective: 'in',
      channelIndicators: [
        'voluptate non',
        'proident veniam'
      ],
      reportCode: 'do quis',
      reportCategory: 'aliqua',
      modelNumber: 'aute',
      country: 'Angola',
      MAD: '27323e15-cc8d-4802',
      idempotentId: 'mockIdempotentId-cc8d-480',
    },
    {
      id: '5f9030893ff572d289e429c7',
      thumbnail: 'https://placehold.co/280x210',
      name: 'minim',
      manufacturer: 'irure',
      partNumber: 'AMET-1866-909',
      deviceType: 'ut nostrud incididunt',
      contentType: 'product',
      commonName: 'NOSTRUD-2521-310',
      creationDate: new Date('Fri Mar 17 2017 07:20:48 GMT+0000'),
      purchaseDate: new Date('Sun Feb 16 2020 01:03:53 GMT+0000'),
      publishDate: new Date('Sun Mar 13 2016 22:53:44 GMT+0000'),
      features: 'cillum magna',
      perspective: 'aliquip',
      channelIndicators: [
        'dolore ex',
        'eu ex',
        'eu reprehenderit',
        'sunt labore'
      ],
      reportCode: 'commodo nisi',
      reportCategory: 'amet',
      modelNumber: 'eu',
      country: 'Poland',
      MAD: '4944ea9e-598a-42c7',
      idempotentId: 'mockIdempotentId-598a-42c',
    },
    {
      id: '5f903089d6c1e963fd3173dc',
      thumbnail: 'https://placehold.co/280x210',
      name: 'id',
      manufacturer: 'non',
      partNumber: 'ULLAMCO-1558-842',
      deviceType: 'ut commodo amet',
      contentType: 'report',
      commonName: 'IPSUM-2559-439',
      creationDate: new Date('Sat Mar 07 2015 18:01:50 GMT+0000'),
      purchaseDate: new Date('Sat Nov 18 2017 20:17:52 GMT+0000'),
      publishDate: new Date('Sat Feb 13 2016 19:42:13 GMT+0000'),
      features: 'ad excepteur',
      perspective: 'nisi',
      channelIndicators: [],
      reportCode: 'nisi ad',
      reportCategory: 'cupidatat',
      modelNumber: 'est',
      country: 'Latvia',
      MAD: 'e5a1b89e-7019-4855',
      idempotentId: 'mockIdempotentId-7019-485',
    },
    {
      id: '5f9030899c9a423d53edf64d',
      thumbnail: 'https://placehold.co/280x210',
      name: 'reprehenderit',
      manufacturer: 'nostrud',
      partNumber: 'EXERCITATION-2370-171',
      deviceType: 'cupidatat non aliquip',
      contentType: 'die',
      commonName: 'IN-3100-374',
      creationDate: new Date('invalid date'),
      purchaseDate: new Date('invalid date'),
      publishDate: new Date('invalid date'),
      features: 'dolore cillum',
      perspective: 'pariatur',
      channelIndicators: [
        'mollit ipsum',
        'duis irure'
      ],
      reportCode: 'duis et',
      reportCategory: 'do',
      modelNumber: 'culpa',
      country: 'Georgia',
      MAD: '81566566-d2c7-42d7',
      idempotentId: 'mockIdempotentId-d2c7-42d',
    },
    {
      id: '5f903089714e001072dd8a79',
      thumbnail: 'https://placehold.co/280x210',
      name: 'commodo',
      manufacturer: 'nisi',
      partNumber: 'NOSTRUD-2744-714',
      deviceType: 'minim qui do',
      contentType: 'die',
      commonName: 'EST-4389-158',
      creationDate: new Date('Mon Jul 25 2016 14:35:06 GMT+0000'),
      purchaseDate: new Date('Wed Oct 08 2014 21:36:48 GMT+0000'),
      publishDate: new Date('Tue Oct 22 2019 04:06:34 GMT+0000'),
      features: 'aliqua dolor',
      perspective: 'ullamco',
      channelIndicators: [],
      reportCode: 'ullamco amet',
      reportCategory: 'laborum',
      modelNumber: 'tempor',
      country: 'Ireland',
      MAD: '0e794f0b-4f71-4120',
      idempotentId: 'mockIdempotentId-4f71-412',
    },
    {
      id: '5f9030894e86837d785d816d',
      thumbnail: 'https://placehold.co/280x210',
      name: 'in',
      manufacturer: 'ex',
      partNumber: 'REPREHENDERIT-3638-985',
      deviceType: 'nostrud fugiat sit',
      contentType: 'die',
      commonName: 'NISI-3838-289',
      creationDate: new Date('Tue Jan 01 2019 17:18:33 GMT+0000'),
      purchaseDate: new Date('Fri Jan 17 2014 17:24:58 GMT+0000'),
      publishDate: new Date('Tue May 28 2019 16:56:14 GMT+0000'),
      features: 'qui velit',
      perspective: 'voluptate',
      channelIndicators: [
        'excepteur mollit',
        'reprehenderit qui'
      ],
      reportCode: 'aliqua in',
      reportCategory: 'proident',
      modelNumber: 'cupidatat',
      country: 'Tokelau',
      MAD: '63fee834-0660-4921',
      idempotentId: 'mockIdempotentId-0660-492',
    },
    {
      id: '5f903089de5a5f53a35a1569',
      thumbnail: 'https://placehold.co/280x210',
      name: 'tempor',
      manufacturer: 'ut',
      partNumber: 'EST-4508-505',
      deviceType: 'aliquip commodo id',
      contentType: 'pcb',
      commonName: 'IPSUM-2670-737',
      creationDate: new Date('Sat Oct 01 2016 17:21:56 GMT+0000'),
      purchaseDate: new Date('Sat Feb 15 2020 04:36:06 GMT+0000'),
      publishDate: new Date('Mon Feb 06 2017 11:25:10 GMT+0000'),
      features: 'consequat ipsum',
      perspective: 'incididunt',
      channelIndicators: [
        'duis adipisicing',
        'do ut'
      ],
      reportCode: 'veniam commodo',
      reportCategory: 'adipisicing',
      modelNumber: 'laboris',
      country: 'Eritrea',
      MAD: 'f675005a-82b0-4a8d',
      idempotentId: 'mockIdempotentId-82b0-4a8',
    },
    {
      id: '5f903089daeb9fa9a472b997',
      thumbnail: 'https://placehold.co/280x210',
      name: 'nostrud',
      manufacturer: 'labore',
      partNumber: 'DESERUNT-4401-794',
      deviceType: 'enim non incididunt',
      contentType: 'pcb',
      commonName: 'OFFICIA-1066-668',
      creationDate: new Date('Mon Jul 22 2019 01:31:10 GMT+0000'),
      purchaseDate: new Date('Sat Jun 20 2015 11:49:54 GMT+0000'),
      publishDate: new Date('Sat Feb 09 2019 19:38:25 GMT+0000'),
      features: 'cupidatat proident',
      perspective: 'non',
      channelIndicators: [
        'non eu',
        'aliquip occaecat'
      ],
      reportCode: 'eiusmod aliqua',
      reportCategory: 'qui',
      modelNumber: 'consectetur',
      country: 'China',
      MAD: '02908fea-5dbc-41b1',
      idempotentId: 'mockIdempotentId-5dbc-41b',
    },
    {
      id: '5f9030891b815d34deca3dd2',
      thumbnail: 'https://placehold.co/280x210',
      name: 'veniam',
      manufacturer: 'cupidatat',
      partNumber: 'SUNT-4272-569',
      deviceType: 'excepteur anim tempor',
      contentType: 'report',
      commonName: 'LABORE-2393-167',
      creationDate: new Date('Tue Apr 10 2018 09:47:52 GMT+0000'),
      purchaseDate: new Date('Wed Jun 17 2015 16:21:03 GMT+0000'),
      publishDate: new Date('Thu Feb 18 2016 00:55:18 GMT+0000'),
      features: 'voluptate esse',
      perspective: 'magna',
      channelIndicators: [
        'id nostrud',
        'aute nostrud',
        'elit ea',
        'aliquip elit'
      ],
      reportCode: 'ullamco nisi',
      reportCategory: 'sit',
      modelNumber: 'eiusmod',
      country: 'Lithuania',
      MAD: '33215a2b-ae38-4c06',
      idempotentId: 'mockIdempotentId-ae38-4c0',
    },
    {
      id: '5f9030892774281ba2e58938',
      thumbnail: 'https://placehold.co/280x210',
      name: 'aliquip',
      manufacturer: 'est',
      partNumber: 'MAGNA-2527-367',
      deviceType: 'tempor nostrud duis',
      contentType: 'report',
      commonName: 'LABORE-3929-425',
      creationDate: new Date('Fri Dec 29 2017 02:53:42 GMT+0000'),
      purchaseDate: new Date('Tue Apr 14 2015 11:41:08 GMT+0000'),
      publishDate: new Date('Thu May 28 2015 22:43:46 GMT+0000'),
      features: 'cillum velit',
      perspective: 'exercitation',
      channelIndicators: [
        'irure do',
        'incididunt cillum'
      ],
      reportCode: 'nulla ex',
      reportCategory: 'commodo',
      modelNumber: 'laborum',
      country: 'Brazil',
      MAD: 'cba806ae-80d3-4eab',
      idempotentId: 'mockIdempotentId-80d3-4ea',
    },
    {
      id: '5f90308948a9ccc18091fb5e',
      thumbnail: 'https://placehold.co/280x210',
      name: 'anim',
      manufacturer: 'id',
      partNumber: 'ID-3180-583',
      deviceType: 'nulla non dolore',
      contentType: 'component',
      commonName: 'LABORIS-3831-611',
      creationDate: new Date('Sun Aug 31 2014 07:33:52 GMT+0000'),
      purchaseDate: new Date('Sat Dec 23 2017 02:30:37 GMT+0000'),
      publishDate: new Date('Sun Feb 02 2014 15:30:52 GMT+0000'),
      features: 'occaecat aute',
      perspective: 'velit',
      channelIndicators: [],
      reportCode: 'commodo cillum',
      reportCategory: 'elit',
      modelNumber: 'aute',
      country: 'Zimbabwe',
      MAD: '258dc696-c4c2-4d9d',
      idempotentId: 'mockIdempotentId-c4c2-4d9',
    },
    {
      id: '5f903089d4e11c2cc36ce631',
      thumbnail: 'https://placehold.co/280x210',
      name: 'ipsum',
      manufacturer: 'excepteur',
      partNumber: 'PARIATUR-2862-858',
      deviceType: 'exercitation laborum excepteur',
      contentType: 'report',
      commonName: 'FUGIAT-3793-876',
      creationDate: new Date('Sat Feb 16 2019 06:56:57 GMT+0000'),
      purchaseDate: new Date('Sun Jan 20 2019 12:32:33 GMT+0000'),
      publishDate: new Date('Sun Jun 01 2014 05:13:49 GMT+0000'),
      features: 'enim ex',
      perspective: 'dolor',
      channelIndicators: [
        'duis cupidatat',
        'sit eu'
      ],
      reportCode: 'aute ut',
      reportCategory: 'excepteur',
      modelNumber: 'aute',
      country: 'Uganda',
      MAD: '38060ba2-c8e0-41dd',
      idempotentId: 'mockIdempotentId-c8e0-41d',
    },
    {
      id: '5f903089c6dcfbb3ac284bfa',
      thumbnail: 'https://placehold.co/280x210',
      name: 'nostrud',
      manufacturer: 'commodo',
      partNumber: 'ENIM-3025-647',
      deviceType: 'ullamco magna esse',
      contentType: 'image',
      commonName: 'LABORIS-3326-198',
      creationDate: new Date('Tue Jun 13 2017 10:07:03 GMT+0000'),
      purchaseDate: new Date('Sat Jul 30 2016 02:24:11 GMT+0000'),
      publishDate: new Date('Thu Feb 27 2020 15:21:17 GMT+0000'),
      features: 'aliqua cupidatat',
      perspective: 'minim',
      channelIndicators: [
        'aute occaecat',
        'mollit veniam'
      ],
      reportCode: 'et veniam',
      reportCategory: 'ad',
      modelNumber: 'proident',
      country: 'Wallis and Futuna Islands',
      MAD: 'b3ddadf0-1c6f-477a',
      idempotentId: 'mockIdempotentId-1c6f-477',
    },
    {
      id: '5f903089ace4c625f147087d',
      thumbnail: 'https://placehold.co/280x210',
      name: 'in',
      manufacturer: 'sit',
      partNumber: 'ELIT-3492-381',
      deviceType: 'Lorem in veniam',
      contentType: 'image',
      commonName: 'ELIT-2574-970',
      creationDate: new Date('Fri Apr 26 2019 10:55:17 GMT+0000'),
      purchaseDate: new Date('Thu Dec 12 2019 03:19:33 GMT+0000'),
      publishDate: new Date('Tue Aug 28 2018 02:24:46 GMT+0000'),
      features: 'incididunt commodo',
      perspective: 'eu',
      channelIndicators: [
        'laborum commodo',
        'elit elit',
        'tempor eiusmod'
      ],
      reportCode: 'qui nostrud',
      reportCategory: 'elit',
      modelNumber: 'esse',
      country: 'Mongolia',
      MAD: '6669c004-0946-472b',
      idempotentId: 'mockIdempotentId-0946-472',
    },
    {
      id: '5f903089dcb3bda92bdca6db',
      thumbnail: 'https://placehold.co/280x210',
      name: 'ex',
      manufacturer: 'magna',
      partNumber: 'ALIQUIP-4593-247',
      deviceType: 'enim magna eu',
      contentType: 'die',
      commonName: 'SUNT-1435-174',
      creationDate: new Date('Mon Feb 03 2014 17:18:13 GMT+0000'),
      purchaseDate: new Date('Sun Jul 21 2019 19:01:32 GMT+0000'),
      publishDate: new Date('Wed Sep 30 2015 00:48:14 GMT+0000'),
      features: 'sint voluptate',
      perspective: 'excepteur',
      channelIndicators: [
        'nulla non',
        'commodo laborum',
        'fugiat non'
      ],
      reportCode: 'irure non',
      reportCategory: 'anim',
      modelNumber: 'cillum',
      country: 'Venezuela',
      MAD: '2c76a9d3-77d7-4c9f',
      idempotentId: 'mockIdempotentId-77d7-4c9',
    },
    {
      id: '5f903089c8e7b6011f66992b',
      thumbnail: 'https://placehold.co/280x210',
      name: 'sunt',
      manufacturer: 'ad',
      partNumber: 'SINT-4972-259',
      deviceType: 'do aliquip nostrud',
      contentType: 'die',
      commonName: 'MOLLIT-4487-758',
      creationDate: new Date('Fri Jan 20 2017 07:51:00 GMT+0000'),
      purchaseDate: new Date('Sun Mar 25 2018 20:51:59 GMT+0000'),
      publishDate: new Date('Wed Oct 23 2019 15:56:20 GMT+0000'),
      features: 'occaecat adipisicing',
      perspective: 'ut',
      channelIndicators: [
        'laboris nostrud',
        'magna aliqua'
      ],
      reportCode: 'nulla esse',
      reportCategory: 'exercitation',
      modelNumber: 'aliquip',
      country: 'Suriname',
      MAD: '4c522639-46a1-4d95',
      idempotentId: 'mockIdempotentId-46a1-4d9',
    },
    {
      id: '5f903089e9ccbf0d55aeb17a',
      thumbnail: 'https://placehold.co/280x210',
      name: 'culpa',
      manufacturer: 'anim',
      partNumber: 'AUTE-4190-124',
      deviceType: 'labore do reprehenderit',
      contentType: 'component',
      commonName: 'SUNT-3844-544',
      creationDate: new Date('Wed Dec 11 2019 11:13:37 GMT+0000'),
      purchaseDate: new Date('Fri May 10 2019 08:07:02 GMT+0000'),
      publishDate: new Date('Fri May 11 2018 05:30:36 GMT+0000'),
      features: 'ex sit',
      perspective: 'tempor',
      channelIndicators: [
        'elit ut',
        'aliquip quis',
        'mollit amet',
        'consectetur ea'
      ],
      reportCode: 'voluptate eiusmod',
      reportCategory: 'nostrud',
      modelNumber: 'laboris',
      country: 'Puerto Rico',
      MAD: '351f6ae1-7f20-426e',
      idempotentId: 'mockIdempotentId-7f20-426',
    },
    {
      id: '5f903089271f336733aa282c',
      thumbnail: 'https://placehold.co/280x210',
      name: 'incididunt',
      manufacturer: 'proident',
      partNumber: 'VOLUPTATE-1657-427',
      deviceType: 'commodo amet sunt',
      contentType: 'report',
      commonName: 'OCCAECAT-4426-255',
      creationDate: new Date('Thu Apr 04 2019 13:28:51 GMT+0000'),
      purchaseDate: new Date('Thu Jul 07 2016 05:29:18 GMT+0000'),
      publishDate: new Date('Wed Feb 27 2019 07:05:50 GMT+0000'),
      features: 'mollit nisi',
      perspective: 'veniam',
      channelIndicators: [
        'adipisicing anim'
      ],
      reportCode: 'esse ut',
      reportCategory: 'qui',
      modelNumber: 'deserunt',
      country: 'Jamaica',
      MAD: '3855b2f5-ef59-410e',
      idempotentId: 'mockIdempotentId-ef59-410',
    },
    {
      id: '5f90308968ff37cb63e9f100',
      thumbnail: 'https://placehold.co/280x210',
      name: 'laborum',
      manufacturer: 'magna',
      partNumber: 'CONSECTETUR-3321-690',
      deviceType: 'irure nostrud ad',
      contentType: 'image',
      commonName: 'NULLA-2431-202',
      creationDate: new Date('Thu Nov 16 2017 09:40:36 GMT+0000'),
      purchaseDate: new Date('Thu Jul 06 2017 22:43:53 GMT+0000'),
      publishDate: new Date('Sat Aug 08 2020 11:28:48 GMT+0000'),
      features: 'cupidatat exercitation',
      perspective: 'dolore',
      channelIndicators: [],
      reportCode: 'do mollit',
      reportCategory: 'tempor',
      modelNumber: 'occaecat',
      country: 'Azerbaijan',
      MAD: '96ddf90c-9391-43c5',
      idempotentId: 'mockIdempotentId-9391-43c',
    },
    {
      id: '5f903089da3372e96d8cb7fd',
      thumbnail: 'https://placehold.co/280x210',
      name: 'nulla',
      manufacturer: 'adipisicing',
      partNumber: 'DOLOR-3435-512',
      deviceType: 'laboris velit nisi',
      contentType: 'product',
      commonName: 'ULLAMCO-1208-911',
      creationDate: new Date('Thu Jul 02 2015 01:29:28 GMT+0000'),
      purchaseDate: new Date('Fri Aug 16 2019 19:16:11 GMT+0000'),
      publishDate: new Date('Sun Dec 25 2016 05:48:16 GMT+0000'),
      features: 'consequat do',
      perspective: 'quis',
      channelIndicators: [
        'sit commodo',
        'mollit dolor',
        'ullamco consequat'
      ],
      reportCode: 'mollit nostrud',
      reportCategory: 'ut',
      modelNumber: 'culpa',
      country: 'Panama',
      MAD: '96ae8952-957b-4822',
      idempotentId: 'mockIdempotentId-957b-482',
    },
    {
      id: '5f903089d71dc053ae9782f5',
      thumbnail: 'https://placehold.co/280x210',
      name: 'laboris',
      manufacturer: 'nisi',
      partNumber: 'ALIQUA-4516-841',
      deviceType: 'veniam et magna',
      contentType: 'product',
      commonName: 'UT-3557-871',
      creationDate: new Date('Tue May 02 2017 06:01:21 GMT+0000'),
      purchaseDate: new Date('Mon Oct 28 2019 21:48:03 GMT+0000'),
      publishDate: new Date('Wed Apr 13 2016 19:00:03 GMT+0000'),
      features: 'ut esse',
      perspective: 'nulla',
      channelIndicators: [],
      reportCode: 'culpa cupidatat',
      reportCategory: 'mollit',
      modelNumber: 'irure',
      country: 'Nigeria',
      MAD: 'd335775e-1273-453a',
      idempotentId: 'mockIdempotentId-1273-453',
    },
    {
      id: '5f903089b4b5744629521bb1',
      thumbnail: 'https://placehold.co/280x210',
      name: 'nostrud',
      manufacturer: 'nisi',
      partNumber: 'EX-1430-899',
      deviceType: 'dolore ex eiusmod',
      contentType: 'schematic',
      commonName: 'SIT-4460-500',
      creationDate: new Date('Sat Apr 02 2016 13:44:39 GMT+0000'),
      purchaseDate: new Date('Fri Feb 03 2017 06:45:36 GMT+0000'),
      publishDate: new Date('Thu Nov 14 2019 16:04:13 GMT+0000'),
      features: 'labore laboris',
      perspective: 'voluptate',
      channelIndicators: [],
      reportCode: 'sit est',
      reportCategory: 'qui',
      modelNumber: 'non',
      country: 'Estonia',
      MAD: 'fa214600-c5ae-4fd3',
      idempotentId: 'mockIdempotentId-c5ae-4fd',
    },
    {
      id: '5f903089fb199e50f51c65c7',
      thumbnail: 'https://placehold.co/280x210',
      name: 'ad',
      manufacturer: 'non',
      partNumber: 'NISI-2266-376',
      deviceType: 'duis amet excepteur',
      contentType: 'image',
      commonName: 'ALIQUA-3503-279',
      creationDate: new Date('Wed Mar 25 2015 12:16:59 GMT+0000'),
      purchaseDate: new Date('Mon Apr 27 2015 19:55:08 GMT+0000'),
      publishDate: new Date('Fri Jan 10 2014 13:53:52 GMT+0000'),
      features: 'pariatur qui',
      perspective: 'aute',
      channelIndicators: [
        'velit commodo',
        'proident adipisicing',
        'esse adipisicing'
      ],
      reportCode: 'cillum fugiat',
      reportCategory: 'aliquip',
      modelNumber: 'dolor',
      country: 'Albania',
      MAD: 'bcfcbb58-c992-4ae2',
      idempotentId: 'mockIdempotentId-c992-4ae',
    },
    {
      id: '5f903089621bb2f90a0d5598',
      thumbnail: 'https://placehold.co/280x210',
      name: 'est',
      manufacturer: 'reprehenderit',
      partNumber: 'MAGNA-4459-569',
      deviceType: 'non qui ea',
      contentType: 'die',
      commonName: 'ULLAMCO-1888-119',
      creationDate: new Date('Thu Apr 23 2020 07:52:38 GMT+0000'),
      purchaseDate: new Date('Tue Aug 07 2018 03:36:31 GMT+0000'),
      publishDate: new Date('Thu Oct 10 2019 13:59:34 GMT+0000'),
      features: 'adipisicing deserunt',
      perspective: 'consequat',
      channelIndicators: [
        'sunt occaecat'
      ],
      reportCode: 'amet est',
      reportCategory: 'velit',
      modelNumber: 'mollit',
      country: 'Morocco',
      MAD: '128b4797-a406-4807',
      idempotentId: 'mockIdempotentId-a406-480',
    },
    {
      id: '5f90308971a6ba2968504c29',
      thumbnail: 'https://placehold.co/280x210',
      name: 'aliqua',
      manufacturer: 'dolore',
      partNumber: 'ENIM-4250-148',
      deviceType: 'amet commodo ut',
      contentType: 'report',
      commonName: 'SIT-3166-601',
      creationDate: new Date('Fri Oct 14 2016 00:48:50 GMT+0000'),
      purchaseDate: new Date('Mon Dec 22 2014 16:43:44 GMT+0000'),
      publishDate: new Date('Thu Nov 15 2018 03:26:16 GMT+0000'),
      features: 'est do',
      perspective: 'enim',
      channelIndicators: [
        'labore quis',
        'magna ipsum',
        'occaecat et'
      ],
      reportCode: 'ut irure',
      reportCategory: 'reprehenderit',
      modelNumber: 'veniam',
      country: 'Chile',
      MAD: 'a2c04599-02ea-4944',
      idempotentId: 'mockIdempotentId-02ea-494',
    },
    {
      id: '5f9030897b3dea4c6ecab5a7',
      thumbnail: 'https://placehold.co/280x210',
      name: 'labore',
      manufacturer: 'aliqua',
      partNumber: 'SIT-4918-840',
      deviceType: 'mollit tempor velit',
      contentType: 'pcb',
      commonName: 'AUTE-3571-996',
      creationDate: new Date('Tue Jan 09 2018 14:31:24 GMT+0000'),
      purchaseDate: new Date('Sat Oct 07 2017 03:18:26 GMT+0000'),
      publishDate: new Date('Sat Feb 10 2018 01:04:54 GMT+0000'),
      features: 'aliquip aute',
      perspective: 'nisi',
      channelIndicators: [
        'proident ut'
      ],
      reportCode: 'eu tempor',
      reportCategory: 'officia',
      modelNumber: 'aute',
      country: 'Guadeloupe',
      MAD: 'ea744f90-24ee-409f',
      idempotentId: 'mockIdempotentId-24ee-409',
    }
  ],
  resultsAmount: {
    components: null,
    dies: 9,
    images: 4,
    pcb: 80,
    products: 10,
    reports: 3,
    schematics: 9,
    all: 118
  },
  newResults: null,
  filterResponse: [
    {
      key: 'manufacturer',
      label: 'manufacturer',
      values: [
        {
          value: 'sony',
          label: 'Sony'
        },
        {
          value: 'samsung',
          label: 'Samsung'
        }
      ]
    }
  ]
};

export const filterSuggestions: any =
  [{
    key: 'deviceManufacturer',
    label: 'Device Manufacturer',
    values: [{
      label: 'Apple',
      value: 'Apple',
      amount: 2
    }]
  },
  {
    key: 'subscriptionId',
    label: 'Subscriptions',
    values: [{
      label: null,
      value: 'a6rf3000000PDdCAAW',
      amount: 2
    }]
  },
  {
    key: 'channelId',
    label: 'Channel',
    values: [{
      label: null,
      value: 'a6p4y000000L6IpAAK',
      amount: 2
    }]
  },
  {
    key: 'yearOfAnalysis',
    label: 'Year of Analysis',
    values: [{
      label: '2017',
      value: '2017',
      amount: 2
    },
    {
      label: '2018',
      value: '2018',
      amount: 2
    },
    {
      label: '2019',
      value: '2019',
      amount: 2
    }]
  },
  ]
