import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { concatMap } from 'rxjs/operators';
import { MetaService } from '@app/@shared/services/meta.service';

@UntilDestroy()
@Component({
  selector: 'app-redirect-to-asset',
  template: '',
  styleUrls: []
})
export class RedirectToAssetComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private meta: MetaService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      untilDestroyed(this),
      concatMap((params) => {
        return this.meta.getAssetByMd5AndReportCode(
          params.get('md5Hash'),
          params.get('reportCode') ?? ''
        )
      })
    ).subscribe(([result]) => {
      const queryParams = {
          page: 1,
          size: 5000,
          ignore: 'none',
          q: result.assetGroupId
      };
      const fragment = `sidebar=true&activeTab=images&imgId=${result.clObjectId}`
      this.router.navigate(['search', 'analysis-view', result.productCode], {
        queryParams,
        fragment
      });
    });
  }

}
