import { Injectable } from '@angular/core';
import { CredentialsService } from 'src/app/auth';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * This class attaches the auth token to any outgoing request.
 * You can except a particular type of request in the intercept() method
 */
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private credentialsService: CredentialsService) {
  }

  intercept(request: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.includes('saml/exchange/')
      && !request.url.includes(environment.contentLakeBucket)
      && !request.url.includes(`${environment.vendorIntegrationServiceBaseUrl}/pardot`)
      && !request.url.includes('amazonaws')
      && !request.url.includes('amazonaws')
      && !request.url.includes('hygraph.com')
      && (!request.url.includes('v1/search') || this.credentialsService.getAccessToken() !== null)) {
      request = this.setAuthorizationHeader(request,
        `Bearer ${this.credentialsService.getAccessToken()}`);
    }

    return next.handle(request);
  }

  private setAuthorizationHeader(request: HttpRequest<any>,
                                 authValue: string) {
    return request.clone(
      {
        setHeaders: {
          Authorization: authValue
        }
      }
    );
  }
}
