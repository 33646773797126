<section class="container">
  <div class="title-footer">
    Available only to {{channelNames}} subscribers
  </div>
  <div class="text-pvo" (click)="onClickPVO()">
    Learn more about our subscription options and benefits.
  </div>
  <div class="text-footer">
    To discuss pricing options please contact our sales team.
  </div>
  <button class="button-primary" (click)="onClickRequest()">
    Request Access
  </button>
</section>
