<section class="tab-section">
  <div class="info">
    <span class="info-text">What can TechInsights help you find today?</span>
  </div>
  <app-user-settings-shortcuts></app-user-settings-shortcuts>
</section>

<section class="generic-search">
  <app-generic-search-bar
    (searchTermsChanged)="onSearchClicked($event)"
    [setRecentSearches]="recentSearchTerms"
  ></app-generic-search-bar>
</section>

<app-static-search-banner [activeTab]="activeTab" *ngIf="isSearchBannerActive"></app-static-search-banner>
