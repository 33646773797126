import {createReducer, on} from '@ngrx/store';
import {
  ApplicationHealthActionTypes,
  dismissNewVersion,
  notifyNewVersion,
  notifyRecoverableError,
  notifyUnrecoverableError,
  setOnlineState
} from '@app/store/Actions/application-health/application-health.actions';
import {IError} from '../../Models/application-health/models';


export const applicationHealthFeatureKey = 'applicationHealth';

export interface ApplicationHealthState {
  online: boolean,
  recoverableErrors: IError[],
  unrecoverableErrors: IError[],
  newVersion: boolean
}

export const initialApplicationHealthState: ApplicationHealthState = {
  online: false,
  recoverableErrors: [],
  unrecoverableErrors: [],
  newVersion: false
};


export const applicationHealthReducer = createReducer(
  initialApplicationHealthState,
  on(setOnlineState, (state, action) => {
    return {
      ...state,
      online: action.online
    }
  }),
  on(notifyRecoverableError, notifyUnrecoverableError, (state, action) => {
    const key = action.type === ApplicationHealthActionTypes.notifyRecoverableError
      ? 'recoverableErrors'
      : 'unrecoverableErrors';

    return {
      ...state,
      // If there's already a notification of the API not working as intended, the state keeps the same
      // If not, the error is added
      [key]: state[key].findIndex(v => v.affectedAPI === action.error.affectedAPI) >= 0
        ? state[key]
        : [...state[key], action.error]
    }
  }),
  on(notifyNewVersion, state => {
    return {
      ...state,
      newVersion: true
    }
  }),
  on(dismissNewVersion, state => {
    return {
      ...state,
      newVersion: false
    }
  }),
);

