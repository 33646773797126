import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IImageFilter } from '@shared/models/reverse-engineering/analysis.models';

export interface ImageFilterEventParam {
  filter: string,
  ignoreFilter: string,
}

@Component({
  selector: 'app-sidebar-image-filter',
  templateUrl: './sidebar-image-filter.component.html',
  styleUrls: [ './sidebar-image-filter.component.scss' ],
})
export class SidebarImageFilterComponent implements OnChanges {

  @Input() filterList: Array<IImageFilter>;
  @Input() defaultFilterKey: string;
  @Input() baseFolderKey: string;

  @Output() filterClicked: EventEmitter<ImageFilterEventParam> = new EventEmitter<ImageFilterEventParam>();

  public sourceImagesFilterList: Array<IImageFilter>;
  public sourceImagesAmount = 0;
  public reportImagesFilter: IImageFilter;
  public selectedFilterKey: string;
  public activeFilterGroupKey: string;

  public ngOnChanges(): void {
    this.sourceImagesFilterList = this.filterList?.filter(
      (item: IImageFilter) => item.label.replace('_', ' ').toLowerCase() !== 'report images'
    );
    this.reportImagesFilter = this.filterList?.find(
      (item: IImageFilter) => item.label.replace('_', ' ').toLowerCase() === 'report images'
    );
    this.sourceImagesAmount = this.sourceImagesFilterList?.reduce(
      (sum: number, current: IImageFilter) => sum + current.amount, 0
    );
    if (this.defaultFilterKey) {
      this.selectedFilterKey = this.defaultFilterKey;
      const baseFolderKey = (this.defaultFilterKey === this.baseFolderKey ? this.baseFolderKey : null);
      this.activeFilterGroupKey = this.reportImagesFilter?.key === this.defaultFilterKey
        ? this.defaultFilterKey : baseFolderKey;
    }
  }

  public isGroupActive(filterGroupKey: string): boolean {
    return filterGroupKey === this.activeFilterGroupKey;
  }

  public isFilterSelected(filterKey: string): boolean {
    return this.selectedFilterKey === filterKey;
  }

  public onClickChevron(filter: IImageFilter): void {
    filter.expanded = !filter.expanded;
  }

  public onChangeFilter(filterKey: string, filterGroupKey: string): void {
    this.defaultFilterKey = null;
    this.selectedFilterKey = filterKey;
    this.activeFilterGroupKey = filterGroupKey;
    let currentFilter = filterKey;
    let currentIgnoreFilter = null;
    if (!filterKey && filterGroupKey === this.baseFolderKey) {
      currentFilter = filterGroupKey;
      currentIgnoreFilter = this.reportImagesFilter.key;
    }
    this.filterClicked.emit({
      filter: currentFilter,
      ignoreFilter: currentIgnoreFilter,
    });
  }

}
