import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AnalysisFilter,
  AnalysisFilterFacet,
  AnalysisFilterType,
  FiltersChangedEvent,
} from '@app/@shared/models/reverse-engineering/analysis.models';

@Component({
  selector: 'app-filter-sidebar',
  templateUrl: './filter-sidebar.component.html',
  styleUrls: ['./filter-sidebar.component.scss'],
  animations: [
    trigger('expandAnimation', [
      state(
        'closed',
        style({
          height: 0,
        })
      ),
      state(
        'open',
        style({
          height: '*',
        })
      ),
      transition('open => closed', animate('.3s ease-in-out')),
      transition('closed => open', animate('.3s ease-in-out')),
    ]),
  ],
})
export class FilterSidebarComponent {
  @Input() filters: AnalysisFilter[];
  @Output() changeFilters = new EventEmitter<FiltersChangedEvent>();

  /**
   * Handles the click event on the filter header
   * @param filter the clicked filter
   */
  onClickFilterGroup(filter: AnalysisFilter) {
    filter.expanded = !filter.expanded;
  }

  /**
   * Emits an event when filters have changed
   * @param facet the changed facet that triggered the changed
   */
  onChangeFilters(facet: AnalysisFilterFacet) {
    this.changeFilters.emit({
      changed: facet,
      applied: this.getAppliedFilters(),
    });
  }

  /**
   * Returns the number of currently applied facets for a given filter
   * @param filter the filter that contains the selected facets
   * @returns number of selected facets
   */
  getSelectedCount(filter: AnalysisFilter) {
    return filter.options.filter((facet) => facet.selected).length;
  }

  /**
   * Clears the selected filters and calls to emit the filters changed event
   */
  clearSelectedFilters() {
    this.filters.forEach((filter) => filter.options.forEach((options) => (options.selected = false)));
    this.filters.forEach((filter) => filter.groups?.forEach((group) =>
      group.options.forEach((option) => option.selected = false)
    ));
    this.onChangeFilters(null);
  }

  /**
   * Gets the filters with selected facets
   * @returns array of filters with selected facets
   */
  private getAppliedFilters() {
    const appliedFilters = this.filters.slice();
    return appliedFilters.map((analysisFilter) => {
        return {
          ...analysisFilter,
          options: analysisFilter.options.filter((option) => option.selected),
          groups: [].concat(analysisFilter.groups?.map((group) => group.options.filter((option)=>option.selected)))
        }
      })
      .filter((analysisFilter) => {
        if(analysisFilter.type === AnalysisFilterType.Group){
          return [].concat.apply([],analysisFilter.groups).length > 0
        }
        return analysisFilter.options.length > 0
      });
  }
}
