import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {PreviousPageService} from '@shared/services/previous-page.service';

@Component({
  selector: 'app-generic-iframe-landing-page',
  templateUrl: './generic-iframe-landing-page.component.html',
  styleUrls: [ './generic-iframe-landing-page.component.scss' ],
})
export class GenericIframeLandingPageComponent implements OnDestroy, OnInit  {
  public showBackButton = false;
  public srcUrl: SafeResourceUrl;
  public isIframeAppLoading = false;
  private iframeAppLoadedMessage: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly sanitizer: DomSanitizer,
    public readonly prevPage: PreviousPageService
  ) {
    this.handleIframeAppLoad = this.handleIframeAppLoad.bind(this);
  }

  ngOnInit(): void {
    const data = this.activatedRoute.snapshot.data as Data;
    this.showBackButton = data.showBackButton ?? false;

      // The iframe source URL can be trusted because it comes from environment config
    this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.srcUrl ?? '');

    if (data.iframeAppLoadedMessage) {
      this.isIframeAppLoading = true;
      this.iframeAppLoadedMessage = data.iframeAppLoadedMessage;
      window.addEventListener('message', this.handleIframeAppLoad);
    }
  }

  ngOnDestroy(): void {
    if (this.iframeAppLoadedMessage) {
      window.removeEventListener('message', this.handleIframeAppLoad);
    }
  }

  onClickBack() {
    this.prevPage.navigateBack();
  }

  private handleIframeAppLoad(event: any): void {
    if (event.data === this.iframeAppLoadedMessage) {
      this.isIframeAppLoading = false;
    }
  }

}
